import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import {useTranslation} from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import {CgClose as CloseIcon} from 'react-icons/cg'

interface ConfirmDialogProps {
	open: boolean
	onClose: () => void
	title?: string
	contentMessage: React.ReactNode
	onConfirm: () => void
	onCancel?: () => void
	confirmText: string
	cancelText?: string
	isLoading?: boolean
}

export const ConfirmDialog = ({
	open,
	onClose,
	title,
	contentMessage,
	onConfirm,
	onCancel,
	confirmText,
	cancelText
}: ConfirmDialogProps) => {
	const {t} = useTranslation()

	const handleConfirm = () => {
		onConfirm()
		onClose()
	}

	const handleCancel = () => {
		if (onCancel) {
			onCancel()
		}
		onClose()
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent
				sx={{
					padding: 2,
					maxWidth: 400
				}}
			>
				{onClose && (
					<IconButton
						sx={{
							position: 'absolute',
							right: 0,
							top: 0,
							color: 'grey.500',
							padding: 1.5,
							'&:hover': {
								cursor: 'pointer',
								color: 'grey.600'
							}
						}}
						size="small"
						onClick={onClose}
						aria-label={t('aria-close-modal')}
					>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				)}
				<DialogContentText>
					{title && (
						<Typography
							variant="h6"
							mb={1}
							color="text.primary"
							lineHeight="1.75rem"
							mr="24px"
						>
							{title}
						</Typography>
					)}
					<Typography
						variant="body1"
						sx={{
							mr: !title ? '24px' : 0
						}}
					>
						{contentMessage}
					</Typography>
					<Box
						sx={{
							alignSelf: 'end',
							display: 'flex',
							flexWrap: 'wrap',
							gap: 1.5,
							justifyContent: 'end',
							mt: 2
						}}
					>
						<Button variant="outlined" onClick={handleCancel}>
							{cancelText || t('cancel')}
						</Button>
						<Button variant="contained" onClick={handleConfirm}>
							{confirmText}
						</Button>
					</Box>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	)
}
