import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import ColorModeProvider from './ColorModeProvider'
import ThemeProvider from './ThemeProvider'

const queryClient = new QueryClient()

const Providers = ({children}: {children?: React.ReactNode}) => {
	return (
		<ColorModeProvider>
			<ThemeProvider>
				<QueryClientProvider client={queryClient}>
					{children}
				</QueryClientProvider>
			</ThemeProvider>
		</ColorModeProvider>
	)
}

export default Providers
