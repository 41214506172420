import {DEFAULT_LANG, SELLER_API} from '../config'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'
import {RawProfile} from './entities/RawProfile'

interface Message {
	message: string
}

interface UpdateProfileResponse {
	success?: {
		message: string
	}
	error?: {
		fields?: {
			name?: Message[]
			nameSlug?: Message[]
			logoLight?: Message[]
			logoDark?: Message[]
			avatar?: Message[]
		}
		message: string
	}
	data?: RawProfile
	responseStatusCode?: number
}

export const updateProfile = async ({
	name,
	nameSlug,
	logoLight,
	logoDark,
	avatar,
	primaryColor
}: {
	name: string
	nameSlug: string
	logoLight: File | null
	logoDark: File | null
	avatar: File | null
	primaryColor: string
}): Promise<UpdateProfileResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const formData = new FormData()
	formData.append('name', name)
	formData.append('nameSlug', nameSlug)
	formData.append('primaryColor', primaryColor)
	if (logoLight) formData.append('logoLight', logoLight)
	if (logoDark) formData.append('logoDark', logoDark)
	if (avatar) formData.append('avatar', avatar)

	const response = await authenticatedFetch(`${SELLER_API}/profile`, {
		method: 'PUT',
		body: formData,
		// credentials: 'include',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		}
	})
	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
