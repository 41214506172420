import {useState} from 'react'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import {Theme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import OutlinedInput from '@mui/material/OutlinedInput'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import {MdOutlineVisibility as Visibility} from 'react-icons/md'
import {MdOutlineVisibilityOff as VisibilityOff} from 'react-icons/md'
import {CgClose as CloseIcon} from 'react-icons/cg'

import useAuthStates from '../../../stores/useAuthStates'
import useLoginSchema from '../../../schemas/useLoginSchema'
import SectionTitle from '../../typography/SectionTitle'
import Link from '../../navigation/Link'
import ButtonLink from '../../navigation/ButtonLink'
import {login} from '../../../api/login'
import useNavigate from '../../../hooks/useNavigate'
import {checkOnboardingCompleted} from '../../../helpers/checkOnboardingCompleted'
import {profileMapper} from '../../../api/mappers/profileMapper'

const FormLogin = () => {
	const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
	const [errorAlert, setErrorAlert] = useState<boolean>(false)
	const [serverError, setServerError] = useState<string>('')
	const {t} = useTranslation()
	const loginState = useAuthStates(state => state.login)
	const navigate = useNavigate()

	const {isLoading, mutate} = useMutation(
		(values: {email: string; password: string}) => login(values),
		{networkMode: 'always'}
	)

	const loginSchema = useLoginSchema()
	const formik = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validationSchema: loginSchema,
		onSubmit: values => {
			mutate(values, {
				onSuccess: async result => {
					if (
						result?.responseStatusCode === 200 &&
						result?.success &&
						result?.data
					) {
						loginState({
							...result.data,
							profile: result.data.profile
								? await profileMapper.toDomain(result.data.profile)
								: null,
							onboardingCompleted: checkOnboardingCompleted(
								result.data.onboarding
							)
						})

						navigate('/')
					} else if (
						result?.responseStatusCode === 400 &&
						result?.error?.fields
					) {
						if (result?.error?.fields?.email) {
							formik.setFieldError(
								'email',
								result.error.fields.email[0].message
							)
							console.error(result.error.fields.email)
						}
						if (result?.error?.fields?.password) {
							formik.setFieldError(
								'password',
								result.error.fields.password[0].message
							)
							console.error(result.error.fields.password)
						}
					} else if (
						result?.responseStatusCode === 401 &&
						result?.error?.message
					) {
						setErrorAlert(true)
						setServerError(t('error-login-invalid'))
						console.error(result?.error?.message)
					} else {
						setErrorAlert(true)
						setServerError(t('error-server-default'))
						console.error(result?.error?.message)
					}
				},
				onError: error => {
					setErrorAlert(true)
					setServerError(t('error-server-default'))
					console.error(JSON.stringify(error))
				}
			})
		}
	})

	return (
		<Box sx={style.formBox}>
			<SectionTitle>{t('log-in-excofy')}</SectionTitle>
			<Collapse in={errorAlert}>
				<Alert
					severity="error"
					action={
						<IconButton
							aria-label={t('aria-close-alert')}
							color="inherit"
							size="small"
							onClick={() => {
								setErrorAlert(false)
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{mt: 3}}
				>
					{serverError}
				</Alert>
			</Collapse>
			<Box
				onSubmit={formik.handleSubmit}
				component="form"
				autoComplete="off"
				sx={{mt: 5}}
			>
				<Stack direction="column" spacing={4}>
					<TextField
						name="email"
						type="email"
						label={t('email')}
						variant="outlined"
						helperText={formik.touched.email && formik.errors.email}
						error={formik.touched.email && Boolean(formik.errors.email)}
						margin="normal"
						value={formik.values.email}
						onChange={formik.handleChange}
					/>
					<FormControl
						variant="outlined"
						error={formik.touched.password && Boolean(formik.errors.password)}
					>
						<InputLabel htmlFor="outlined-adornment-password">
							{t('password')}
						</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password"
							type={passwordVisible ? 'text' : 'password'}
							name="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setPasswordVisible(!passwordVisible)}
										edge="end"
									>
										{passwordVisible ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label={t('password')}
						/>
						{formik.touched.password && formik.errors.password && (
							<FormHelperText>{formik.errors.password}</FormHelperText>
						)}
					</FormControl>
					<Link to="/forgot-password">{t('forgot-your-password')}</Link>
					<Button
						size="large"
						variant="contained"
						type="submit"
						disabled={isLoading}
						sx={{height: '44px'}}
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('to-login')
						)}
					</Button>
					<Divider sx={{pt: 3}}>{t('new-to-excofy')}</Divider>
					<Stack direction="row" justifyContent="center">
						<ButtonLink
							to="/sign-up"
							size="large"
							variant="outlined"
							sx={{width: '70%'}}
						>
							{t('sign-up')}
						</ButtonLink>
					</Stack>
				</Stack>
			</Box>
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			my: 5,
			padding: 5,
			width: '80%',
			maxWidth: '600px',
			[theme.breakpoints.down('md')]: {
				my: 0,
				padding: 3,
				width: '100%'
			}
		}
	}
}

export default FormLogin
