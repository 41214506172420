import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import parseToHTML from 'html-react-parser'
import useSaleStates from '../../../stores/useSaleStates'
import {SaleDetails} from '../../../entities/Sale'
import useRefundSaleSchema from '../../../schemas/useRefundSaleSchema'
import {useMutation} from '@tanstack/react-query'
import {refundSale} from '../../../api/sales'
import {useFormik} from 'formik'
import {price} from '../../../helpers/price'
import {regex} from '../../../helpers/regex'
import {Dialog} from '../../Dialog'
import Box from '@mui/material/Box'
import AlertMessage from '../../AlertMessage'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Icon from '@mui/material/Icon'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {
	MdOutlineVisibility as Visibility,
	MdOutlineVisibilityOff as VisibilityOff
} from 'react-icons/md'
import {FcMoneyTransfer as RefundIcon} from 'react-icons/fc'
import Tag from '../../typography/Tag'

export default function SaleRefundDialog({
	open,
	onClose
}: {
	open: boolean
	onClose: () => void
}) {
	const {t} = useTranslation()
	const {saleDetails, changeSaleStatus, changeSaleRefundUrl} =
		useSaleStates() as {
			saleDetails: SaleDetails
			setSaleDetails: (saleDetails: SaleDetails) => void
			changeSaleStatus: (data: {id: string; status: string}) => void
			changeSaleRefundUrl: (refundUrl: string) => void
		}
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [openProcessingRefundDialog, setOpenProcessingRefundDialog] =
		useState(false)

	const refundSaleSchema = useRefundSaleSchema(saleDetails?.totalAmount)
	const {mutate, isLoading} = useMutation(refundSale, {networkMode: 'always'})

	const formik = useFormik({
		initialValues: {
			value: price.decimal(saleDetails?.totalAmount),
			description: '',
			refundType: 'full' as 'full' | 'partial',
			password: ''
		},
		enableReinitialize: true,
		validationSchema: refundSaleSchema,
		onSubmit: ({password, refundType, value, description}) => {
			setAlertMessage('')
			mutate(
				{
					saleId: saleDetails.id,
					value: Number(regex.removeAllNonDigits(value)),
					password,
					description,
					paymentMethod: saleDetails?.paymentMethod,
					refundType
				},
				{
					onSuccess: ({error, responseStatusCode, data}) => {
						if (responseStatusCode === 200) {
							if (data?.refundUrl) {
								changeSaleRefundUrl(data.refundUrl)
							}

							changeSaleStatus({
								id: saleDetails.id,
								status:
									saleDetails.paymentMethod === 'boleto'
										? 'awaiting-customer-data-for-refund'
										: 'processing-refund'
							})
							onClose()
							setOpenProcessingRefundDialog(true)
						} else if (responseStatusCode === 401 && error?.fields) {
							Object.entries(error.fields).forEach(([key, value]) => {
								value.forEach(({message}) => {
									formik.setFieldError(key, message)
								})
							})
						} else if (responseStatusCode === 400 && error?.fields) {
							Object.entries(error.fields).forEach(([key, value]) => {
								value.forEach(({message}) => {
									formik.setFieldError(key, message)
								})
							})
						} else if (error?.message) {
							setAlertMessage(error.message)
						} else {
							setAlertMessage(t('error-server-default'))
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						console.error(error)
					}
				}
			)
		}
	})

	const handleClose = () => {
		setAlertMessage('')
		formik.resetForm()
		onClose()
	}

	return (
		<>
			<ProcessingRefundDialog
				open={openProcessingRefundDialog}
				onClose={setOpenProcessingRefundDialog.bind(null, false)}
			/>
			<Dialog.Root open={open}>
				<Dialog.Header>
					<Dialog.Title onClose={handleClose}>
						{t('refund-payment')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width={600}>
					<Box
						sx={{
							mx: 'auto',
							py: 1,
							width: '100%'
						}}
						component="form"
						onSubmit={formik.handleSubmit}
					>
						<AlertMessage
							open={Boolean(alertMessage)}
							severity="error"
							message={alertMessage}
							onClose={() => setAlertMessage('')}
							mt={'0px! important'}
							sx={{mb: Boolean(alertMessage) ? 2 : 0}}
						/>
						<Stack direction="column" spacing={4}>
							{saleDetails?.paymentService === 'pagarme' && (
								<FormGroup>
									<FormControl
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											flexDirection: 'column',
											gap: 2
										}}
									>
										<FormLabel>{t('refund-label')}</FormLabel>
										<RadioGroup row name="transferInterval">
											<FormControlLabel
												value="full"
												control={
													<Radio
														onChange={event => {
															formik.setFieldValue(
																'refundType',
																event.target.value
															)
														}}
													/>
												}
												label={
													<Typography variant="body2">
														{t('refund-full')}
													</Typography>
												}
												checked={formik.values.refundType === 'full'}
												sx={[
													theme => ({
														color: theme.palette.grey[600]
													})
												]}
											/>
											<FormControlLabel
												value="partial"
												control={
													<Radio
														onChange={event => {
															formik.setFieldValue(
																'refundType',
																event.target.value
															)
														}}
													/>
												}
												label={
													<Typography variant="body2">
														{t('refund-partial')}
													</Typography>
												}
												checked={formik.values.refundType === 'partial'}
												sx={theme => ({
													color: theme.palette.grey[600]
												})}
											/>
										</RadioGroup>
										{formik.errors.refundType && (
											<FormHelperText error>
												{formik.errors.refundType}
											</FormHelperText>
										)}
									</FormControl>
								</FormGroup>
							)}
							<TextField
								name="value"
								type="text"
								label={t('value')}
								value={formik.values.value}
								disabled={formik.values.refundType === 'full'}
								onChange={event => {
									event.target.value = regex.formatBRLCurrency(
										event.target.value
									)
									formik.handleChange(event)

									const currentValue = Number(
										regex.removeAllNonDigits(event.target.value)
									)

									if (currentValue > saleDetails.totalAmount * 100) {
										formik.setFieldError('value', t('error-price-max-length'))
									}
								}}
								onBlur={formik.handleBlur}
								helperText={formik.touched.value && formik.errors.value}
								error={formik.touched.value && Boolean(formik.errors.value)}
								variant="outlined"
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">{'R$'}</InputAdornment>
									)
								}}
								inputProps={{
									inputMode: 'numeric'
								}}
							/>
							<TextField
								name="description"
								type="text"
								label={t('description')}
								value={formik.values.description}
								placeholder={t('refund-description-placeholder')}
								multiline
								rows={4}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								helperText={
									formik.touched.description &&
									Boolean(formik.errors.description) ? (
										formik.errors.description
									) : (
										<Box>
											<Tag sx={{display: 'inline'}}>{t('optional')}</Tag>
											<Typography variant="caption" sx={{ml: 1}}>
												{t('refund-description-helper-text')}
											</Typography>
										</Box>
									)
								}
								error={
									formik.touched.description &&
									Boolean(formik.errors.description)
								}
								variant="outlined"
								size="small"
							/>
							<TextField
								name="password"
								type={passwordVisible ? 'text' : 'password'}
								label={t('password')}
								variant="outlined"
								margin="normal"
								size="small"
								sx={{mt: 0}}
								helperText={formik.touched.password && formik.errors.password}
								error={
									formik.touched.password && Boolean(formik.errors.password)
								}
								value={formik.values.password}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label={
													passwordVisible
														? t('aria-hide-password')
														: t('aria-show-password')
												}
												onClick={() => setPasswordVisible(!passwordVisible)}
												edge="end"
											>
												{passwordVisible ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
							<Button
								variant="contained"
								type="submit"
								disabled={isLoading}
								sx={{
									alignSelf: 'flex-start',
									minWidth: 200
								}}
							>
								{isLoading ? (
									<CircularProgress color="inherit" size={25} />
								) : (
									t('refund-payment')
								)}
							</Button>
						</Stack>
					</Box>
				</Dialog.Body>
			</Dialog.Root>
		</>
	)
}

function ProcessingRefundDialog({
	open,
	onClose
}: {
	open: boolean
	onClose: () => void
}) {
	const {t} = useTranslation()

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>{t('refund-in-progress')}</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<Box display="flex" gap={2} alignItems="center">
					<Icon
						sx={{
							fontSize: '6rem'
						}}
					>
						<RefundIcon style={{marginBottom: 4}} />
					</Icon>
					<Box>
						<Typography flex={1}>
							{parseToHTML(t('refund-in-progress-info'))}
						</Typography>
					</Box>
				</Box>
			</Dialog.Body>
		</Dialog.Root>
	)
}
