import FormSignUp from '../components/forms/auth/FormSignUp'
import FullSection from '../components/layout/utils/FullSection'
import Footer from '../components/template/Footer'

const SignUp = () => {
	return (
		<>
			<FullSection height="auto" minHeight="calc(100vh - 260px)">
				<FormSignUp />
			</FullSection>
			<Footer />
		</>
	)
}

export default SignUp
