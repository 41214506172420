import MUILink from '@mui/material/Link'
import {SxProps, Theme} from '@mui/material/styles'
import React from 'react'
import {Link as RRDLink} from 'react-router-dom'
import useAppStates from '../../stores/useAppStates'
import {Typography} from '@mui/material'

interface LinkProps {
	children?: React.ReactNode
	disabled?: boolean
	style?: any
	inheritStyle?: boolean
	externalLink?: boolean
	to: string
	title?: string
	state?: object
	target?: React.HTMLAttributeAnchorTarget
	sx?: SxProps<Theme>
}

const Link = ({
	children,
	disabled,
	to,
	style,
	inheritStyle,
	externalLink,
	title,
	state,
	target,
	sx
}: LinkProps) => {
	const currentLang = useAppStates(state => state.currentLang)

	const relativePath = currentLang ? `/${currentLang + to}` : to

	return (
		<>
			{disabled ? (
				<Typography variant="inherit" sx={styles.disabledLink}>
					{children}
				</Typography>
			) : (
				<MUILink
					component={RRDLink}
					style={style}
					to={externalLink ? to : relativePath}
					sx={sx ? sx : inheritStyle ? styles.inheritStyle : undefined}
					aria-label={title || undefined}
					title={title || undefined}
					state={state}
					target={target}
				>
					{children}
				</MUILink>
			)}
		</>
	)
}

const styles = {
	inheritStyle: {
		color: 'inherit',
		textDecoration: 'inherit',
		width: 'inherit'
	},
	disabledLink: (theme: Theme) => ({
		color: theme.palette.text.disabled,
		cursor: 'not-allowed'
	})
}

export default Link
