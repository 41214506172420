import {create} from 'zustand'
import {persist} from 'zustand/middleware'

interface ProductStates {
	listLimit: number
	setListLimit: (saleListLimit: number) => void
	selectList: {
		selected: {
			id: string
			title: string
			cover?: string
			status: string
		} | null
		data: {
			id: string
			title: string
			cover?: string
			status: string
		}[]
		limit: number
		offset: number
		hasMore: boolean
	}
	setSelectList: (selectList: {
		selected?: {
			id: string
			title: string
			cover?: string
			status: string
		} | null
		data?: {
			id: string
			title: string
			cover?: string
			status: string
		}[]
		limit?: number
		offset?: number
		hasMore?: boolean
	}) => void
}

const useProductStates = create<ProductStates>()(
	persist(
		set => ({
			listLimit: 10,
			setListLimit: (listLimit: number) => set({listLimit}),
			selectList: {
				selected: null,
				data: [],
				limit: 10,
				offset: 0,
				hasMore: true
			},
			setSelectList: selectList =>
				set(state => ({
					selectList: {
						...state.selectList,
						...selectList
					}
				}))
		}),
		{
			name: 'products-storage',
			getStorage: () => localStorage,
			partialize: state => ({
				listLimit: state.listLimit
			})
		}
	)
)

export default useProductStates
