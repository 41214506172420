import {DEFAULT_LANG, WEBHOOKS_BASE_API} from '../config'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import {regex} from '../helpers/regex'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'
import {SaleWebhookEvent} from '../stores/useSaleStates'

interface FetchWebhooksResponse {
	data?: {
		webhooks: {
			id: string
			name: string
			url: string
			status: string
			authenticationToken?: string
			events: {
				sales: ('SALE_CREATED' | 'SALE_UPDATED')[]
			}
		}[]
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const fetchWebhooks = async (values: {
	productId: string
}): Promise<FetchWebhooksResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const queries = regex.parseObjectToQueryString(values)

	const response = await authenticatedFetch(`${WEBHOOKS_BASE_API}?${queries}`, {
		method: 'GET',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		}
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CreateWebhookResponse {
	data?: {
		id: string
	}
	error?: {
		message: string
		fields?: {
			name?: Message[]
			authenticationToken?: Message[]
			url?: Message[]
			status?: Message[]
			events?: Message[]
			productId?: Message[]
		}
	}
	responseStatusCode?: number
}

export const createWebhook = async (values: {
	name: string
	url: string
	status: string
	authenticationToken?: string
	events: {
		sales: string[]
	}
	productId: string
}): Promise<CreateWebhookResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${WEBHOOKS_BASE_API}`, {
		method: 'POST',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		},
		body: JSON.stringify(values)
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateWebhookResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			name?: Message[]
			authenticationToken?: Message[]
			url?: Message[]
			status?: Message[]
			events?: Message[]
		}
	}
	responseStatusCode?: number
}

export const updateWebhook = async ({
	id,
	...values
}: {
	id: string
	name: string
	url: string
	status: string
	authenticationToken?: string
	events: {
		sales: string[]
	}
}): Promise<UpdateWebhookResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${WEBHOOKS_BASE_API}/${id}`, {
		method: 'PUT',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		},
		body: JSON.stringify(values)
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteWebhookResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const deleteWebhook = async ({
	id
}: {
	id: string
}): Promise<DeleteWebhookResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${WEBHOOKS_BASE_API}/${id}`, {
		method: 'DELETE',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		}
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface FetchEventsResponse {
	data?: {
		events: {
			id: string
			status: string
			sentAt: string
			eventType: string
			webhookName: string
			requestBody: string
			responseMetadata: string
		}[]
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const fetchEvents = async (values: {
	productId: string
	webhookName?: string
	eventType?: string
	status?: string
	offset: number
	limit: number
}): Promise<FetchEventsResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const queries = regex.parseObjectToQueryString(values)

	const response = await authenticatedFetch(
		`${WEBHOOKS_BASE_API}/events?${queries}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteEventResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const deleteEvent = async ({
	id
}: {
	id: string
}): Promise<DeleteEventResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${WEBHOOKS_BASE_API}/events/${id}`,
		{
			method: 'DELETE',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ResendEventResponse {
	data?: {
		status: string
		sentAt: string
		responseData: string
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const resendEvent = async ({
	id
}: {
	id: string
}): Promise<ResendEventResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${WEBHOOKS_BASE_API}/events/sales/${id}`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DispatchNewEventResponse {
	data?: {
		events: SaleWebhookEvent[]
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const dispatchNewEvent = async (values: {
	saleId: string
	productId: string
	event: 'SALE_CREATED' | 'SALE_UPDATED'
}): Promise<DispatchNewEventResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${WEBHOOKS_BASE_API}/events/sales/dispatch`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
