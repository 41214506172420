import {CHECKOUT_PAGARME_API, DEFAULT_LANG} from '../../config'
import {BankAccount} from '../../entities/BankAccount'
import {CompanyManagingPartner} from '../../entities/Company'
import {CompanyLegalRepresentative} from '../../entities/CompanyLegalRepresentative'
import {PagarmeConfig} from '../../entities/PagarmeConfig'
import {authenticatedFetch} from '../../helpers/authenticatedFetch'
import useAppStates from '../../stores/useAppStates'
import useAuthStates from '../../stores/useAuthStates'

interface Message {
	message: string
}

interface GetPaymentServicePagarmeResponse {
	data: PagarmeConfig | null
	error?: Message
	responseStatusCode?: number
}

export const getPaymentServicePagarme =
	async (): Promise<GetPaymentServicePagarmeResponse> => {
		const currentLang = useAppStates.getState().currentLang
		const accessToken = useAuthStates.getState().accessToken

		const response = await authenticatedFetch(
			`${CHECKOUT_PAGARME_API}/settings`,
			{
				method: 'GET',
				headers: {
					'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
					'Authorization': `Bearer ${accessToken}`
				}
			}
		)

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	}

interface ActivateServicePagarmeResponse {
	data: {
		recipientId: string
		status: 'in-review'
	}
	error?: Message
	responseStatusCode?: number
}

export const activatePaymentServicePagarme =
	async (): Promise<ActivateServicePagarmeResponse> => {
		const currentLang = useAppStates.getState().currentLang
		const accessToken = useAuthStates.getState().accessToken

		const response = await authenticatedFetch(
			`${CHECKOUT_PAGARME_API}/settings/activate`,
			{
				method: 'PATCH',
				headers: {
					'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
					'Authorization': `Bearer ${accessToken}`
				},
				body: JSON.stringify({})
			}
		)

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	}

interface UpdateBankAccountResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			accountNumber?: Message[]
			accountCheckDigit?: Message[]
			accountType?: Message[]
			bankNumber?: Message[]
			branchNumber?: Message[]
			branchCheckDigit?: Message[]
		}
	}
	data?: {
		bankAccount: BankAccount
	}
	responseStatusCode?: number
}

export const updateBankAccount = async (
	values: BankAccount
): Promise<UpdateBankAccountResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/settings/bank-account`,
		{
			method: 'PUT',
			body: JSON.stringify(values),
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface UpdateLegalRepresentativeResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			name?: Message[]
			email?: Message[]
			cpf?: Message[]
			birthdate?: Message[]
			monthlyIncome?: Message[]
			professionalOccupation?: Message[]
			selfDeclaredLegalRepresentative: boolean
			phone?: Message[]
			phoneType?: Message[]
			street?: Message[]
			streetNumber: number
			district?: Message[]
			city?: Message[]
			state?: Message[]
			zipCode?: Message[]
			complementaryAddress?: Message[]
			referencePoint?: Message[]
		}
	}
	data?: CompanyLegalRepresentative
	responseStatusCode?: number
}

export const updateLegalRepresentative = async (
	values: CompanyManagingPartner
): Promise<UpdateLegalRepresentativeResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/settings/legal-representative`,
		{
			method: 'PUT',
			body: JSON.stringify(values),
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface UpdateCompanyAnnualRevenueResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			companyAnnualRevenue?: Message[]
		}
	}
	responseStatusCode?: number
}

export const updateCompanyAnnualRevenue = async (values: {
	companyAnnualRevenue: number
}): Promise<UpdateCompanyAnnualRevenueResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/settings/annual-revenue`,
		{
			method: 'PUT',
			body: JSON.stringify(values),
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface UpdateTransferSettingsResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			transferInterval?: Message[]
		}
	}
	responseStatusCode?: number
}

export const updateTransferSettings = async (values: {
	transferInterval: string
}): Promise<UpdateTransferSettingsResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/settings/transfer-settings`,
		{
			method: 'PUT',
			body: JSON.stringify(values),
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface ChangePagarmeStatusResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const changePagarmeStatus = async (values: {
	status: string
	service: string
}): Promise<ChangePagarmeStatusResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/settings/status`,
		{
			method: 'PATCH',
			headers: {
				'Accept-Language': currentLang,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface CreatePagarmeTransferResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			amount: Message[]
		}
	}
	data: {
		id: string
		amount: number
		feeAmount: number
		accountCheckDigit: string
		accountNumber: string
		accountType: 'checking' | 'savings'
		bankCode: string
		branchCheckDigit?: string
		branchNumber: string
		createdAt: string
		status: string
		statusHistory: {
			status: string
			createdAt: string
			log?: string
		}[]
		balance?: {
			availableAmount: number
			availableAmountToTransfer: number
		}
	}
	responseStatusCode?: number
}

export const createPagarmeTransfer = async (values: {
	amount: number
}): Promise<CreatePagarmeTransferResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/settings/transfers`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface GetPagarmeBalanceResponse {
	data: {
		balance: {
			availableAmount: number
			availableAmountToTransfer: number
			waitingFundsAmount: number
		}
	} | null
	error?: Message
	responseStatusCode?: number
}

export const getPagarmeBalance =
	async (): Promise<GetPagarmeBalanceResponse> => {
		const currentLang = useAppStates.getState().currentLang
		const accessToken = useAuthStates.getState().accessToken

		const response = await authenticatedFetch(
			`${CHECKOUT_PAGARME_API}/settings/balance`,
			{
				method: 'GET',
				headers: {
					'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
					'Authorization': `Bearer ${accessToken}`
				}
			}
		)

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	}

interface ListPagarmeTransfersResponse {
	data: {
		transfers: PagarmeConfig['transfers']
		count: number
	}
	error?: Message
	responseStatusCode?: number
}

export const listPagarmeTransfers = async ({
	limit,
	offset
}: {
	offset: number
	limit: number
}): Promise<ListPagarmeTransfersResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/settings/transfers?offset=${offset}&limit=${limit}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}
