import {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import SectionTitle from '../../../../components/typography/SectionTitle'
import Stack from '@mui/material/Stack'
import AlertMessage from '../../../../components/AlertMessage'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputMask from '../../../../components/InputMask'
import {useFormik} from 'formik'
import useBankAccountSchema from '../../../../schemas/useBankAccountSchema'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {useMutation, useQuery} from '@tanstack/react-query'
import Skeleton from '@mui/material/Skeleton'
import Tag from '../../../../components/typography/Tag'
import BankSelect from '../../../../components/BankSelect'
import usePaymentServicePagarmeStates from '../../../../stores/usePaymentServicePagarmeStates'
import {
	getPaymentServicePagarme,
	updateBankAccount
} from '../../../../api/payment-service/pagarme'
import Container from '@mui/material/Container'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '../../../../components/navigation/Link'
import BackNavigation from '../../../../components/navigation/BackNavigation'
import {DashboardFullSection} from '../../../../components/template/DashboardFullSection'
import {FaHome as HomeIcon} from 'react-icons/fa'
import Footer from '../../../../components/template/Footer'

const FormUpdateBankAccount = () => {
	const {bankAccount, setBankAccount} = usePaymentServicePagarmeStates()
	const [alertErrorMessage, setAlertErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const alertRef = useRef<HTMLDivElement>(null)

	const {t} = useTranslation()
	const bankAccountSchema = useBankAccountSchema()

	const {isLoading, mutate} = useMutation(updateBankAccount, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			accountType: bankAccount?.accountType || 'checking',
			accountNumber: bankAccount?.accountNumber || '',
			accountCheckDigit: bankAccount?.accountCheckDigit || '',
			bankCode: bankAccount?.bankCode || '',
			branchNumber: bankAccount?.branchNumber || '',
			branchCheckDigit: bankAccount?.branchCheckDigit || ''
		},
		enableReinitialize: true,
		validationSchema: bankAccountSchema,
		onSubmit: values => {
			setOpenAlertError(false)
			mutate(values, {
				onSuccess: ({error, responseStatusCode, data}) => {
					if (responseStatusCode === 200) {
						setShowSuccessButton(true)
						setBankAccount(values)
					} else if (responseStatusCode === 400 && error?.fields) {
						Object.entries(error.fields).forEach(([key, value]) => {
							formik.setFieldError(key, value[0].message)
						})
					} else {
						setAlertErrorMessage(error?.message || t('error-server-default'))
						setOpenAlertError(true)
						alertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
							inline: 'start'
						})
					}
				},
				onError: error => {
					setAlertErrorMessage(t('error-server-default'))
					setOpenAlertError(true)
					alertRef.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'start'
					})
					console.error(error)
				}
			})
		}
	})

	const {isFetching: isLoadingBankAccount, isError: isErrorListingBankAccount} =
		useQuery(['payment-service-pagarme'], getPaymentServicePagarme, {
			enabled: !bankAccount,
			refetchOnWindowFocus: false,
			onSuccess: result => {
				if (result.responseStatusCode === 200) {
					setBankAccount(result?.data?.bankAccount || null)
				} else {
					setAlertErrorMessage(t('error-listing-bank-account-alert'))
					setOpenAlertError(true)
					alertRef.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'start'
					})
				}
			},
			onError: error => {
				setAlertErrorMessage(t('error-listing-bank-account-alert'))
				setOpenAlertError(true)
				alertRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'start'
				})
			}
		})

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link
						to="/payment-services"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<span>{t('payment-services')}</span>
					</Link>
					<Link
						to="/payment-services/pagarme"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<span>{t('payment-service-pagarme-settings')}</span>
					</Link>
					<span>{t('managing-partner')}</span>
				</Breadcrumbs>
				<BackNavigation to="/payment-services/pagarme" />
			</Container>
			<DashboardFullSection>
				<Box
					onSubmit={formik.handleSubmit}
					onChange={() => {
						setOpenAlertError(false)
						setShowSuccessButton(false)
					}}
					component="form"
					autoComplete="off"
					sx={styles.formBox}
				>
					<SectionTitle>{t('edit-bank-account')}</SectionTitle>
					{isLoadingBankAccount && (
						<Stack direction="column" spacing={4} sx={{mt: 4}}>
							<Skeleton variant="rounded" height={40} />
							<Skeleton variant="rounded" height={40} />
							<Skeleton variant="rounded" height={40} />
							<Skeleton variant="rounded" height={40} />
							<Skeleton variant="rounded" height={40} />
							<Box>
								<Skeleton variant="rounded" sx={{maxWidth: 180, mb: 2}} />
								<Skeleton variant="rounded" sx={{maxWidth: 350, mb: 1}} />
							</Box>
							<Skeleton
								variant="rectangular"
								height={44}
								sx={{maxWidth: 200}}
							/>
						</Stack>
					)}

					{isErrorListingBankAccount && (
						<Button
							size="large"
							variant="outlined"
							type="button"
							sx={{width: '100%', mt: 4}}
						>
							{isLoadingBankAccount ? (
								<CircularProgress color="inherit" size={25} />
							) : (
								t('to-try-again')
							)}
						</Button>
					)}

					{!isLoadingBankAccount && (
						<Stack direction="column" spacing={4}>
							<AlertMessage
								ref={alertRef}
								severity="error"
								open={openAlertError}
								onClose={() => setOpenAlertError(false)}
								message={alertErrorMessage}
							/>
							<BankSelect
								name="bankCode"
								label={t('bank-code')}
								valueFormat="label"
								code={formik.values.bankCode}
								onChange={formik.handleChange}
								error={
									formik.touched.bankCode && Boolean(formik.errors.bankCode)
								}
								helperText={formik.touched.bankCode && formik.errors.bankCode}
							/>
							<TextField
								sx={styles.inputBranchNumber}
								name="branchNumber"
								type="text"
								InputProps={{
									inputComponent: InputMask as any,
									inputProps: {
										mask: '0000'
									}
								}}
								label={t('branch-number')}
								variant="outlined"
								helperText={
									formik.touched.branchNumber && formik.errors.branchNumber
								}
								error={
									formik.touched.branchNumber &&
									Boolean(formik.errors.branchNumber)
								}
								value={formik.values.branchNumber}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
							/>
							<TextField
								name="branchCheckDigit"
								type="text"
								inputProps={{
									maxLength: 1
								}}
								label={t('branch-check-digit')}
								variant="outlined"
								helperText={
									formik.touched.branchCheckDigit &&
									Boolean(formik.errors.branchCheckDigit) ? (
										formik.errors.branchCheckDigit
									) : (
										<Tag>{t('optional')}</Tag>
									)
								}
								error={
									formik.touched.branchCheckDigit &&
									Boolean(formik.errors.branchCheckDigit)
								}
								value={formik.values.branchCheckDigit}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
							/>
							<TextField
								sx={styles.inputBranchNumber}
								name="accountNumber"
								type="text"
								label={t('account-number')}
								variant="outlined"
								inputProps={{
									maxLength: 13
								}}
								helperText={
									formik.touched.accountNumber && formik.errors.accountNumber
								}
								error={
									formik.touched.accountNumber &&
									Boolean(formik.errors.accountNumber)
								}
								value={formik.values.accountNumber}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
							/>
							<TextField
								name="accountCheckDigit"
								type="text"
								inputProps={{
									maxLength: 2
								}}
								label={t('account-check-digit')}
								variant="outlined"
								helperText={
									formik.touched.accountCheckDigit &&
									formik.errors.accountCheckDigit
								}
								error={
									formik.touched.accountCheckDigit &&
									Boolean(formik.errors.accountCheckDigit)
								}
								value={formik.values.accountCheckDigit}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
							/>
							<FormControl>
								<FormLabel id="select-account-type-label">
									{t('account-type')}
								</FormLabel>
								<RadioGroup
									row
									aria-labelledby="select-account-type-label"
									name="accountType"
									value={formik.values.accountType}
									onChange={formik.handleChange}
								>
									<FormControlLabel
										value="checking"
										control={<Radio />}
										label={t('account-type-checking')}
									/>
									<FormControlLabel
										value="savings"
										control={<Radio />}
										label={t('account-type-savings')}
									/>
								</RadioGroup>
							</FormControl>
							{showSuccessButton ? (
								<Button
									size="large"
									variant="contained"
									type="button"
									sx={styles.successButton}
								>
									{t('saved-changes')}
								</Button>
							) : (
								<Button
									size="large"
									variant="contained"
									type="submit"
									disabled={isLoading}
									sx={styles.formSubmitButton}
								>
									{isLoading ? (
										<CircularProgress color="inherit" size={25} />
									) : (
										t('to-save-changes')
									)}
								</Button>
							)}
						</Stack>
					)}
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

const styles = {
	formBox: (theme: Theme) => ({
		my: 5,
		mt: 0,
		pt: 0,
		width: '100%',
		maxWidth: '520px'
	}),
	formSubmitButton: (theme: Theme) => ({
		height: '44px',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	inputBranchNumber: (theme: Theme) => ({
		flexGrow: 1
	}),
	successButton: (theme: Theme) => ({
		height: '44px',
		backgroundColor: 'success.light',
		color: 'success.contrastText',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		'&:hover': {backgroundColor: 'success.light'}
	})
}

export default FormUpdateBankAccount
