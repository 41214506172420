import {useCallback, useState} from 'react'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import Icon from '@mui/material/Icon'
import {Status} from './Status'
import {useMutation} from '@tanstack/react-query'
import {listExperiencesMainInfo} from '../api/experiences'
import AlertDialog from './AlertDialog'
import {BiSolidPurchaseTag as TagIcon} from 'react-icons/bi'
import CircularProgress from '@mui/material/CircularProgress'
import useProductStates from '../stores/useProductsStates'

interface ExperienceMainInfo {
	id: string
	title: string
	cover?: string
	status: string
}

interface OptionType {
	id: string
	cover?: string
	status: string
	title: string
}

interface SelectProps {
	name: string
	label: string
	selectedProductId?: string | null
	disabledOptions?: {id: string}[]
	onChange: (data: {title: string; id: string} | null) => void
}

export default function ExperiencesSelect({
	name,
	label,
	selectedProductId,
	disabledOptions,
	onChange
}: SelectProps) {
	const {selectList, setSelectList} = useProductStates()
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const {t} = useTranslation()

	const handleChange = (_: any, newValue: OptionType | null) => {
		onChange({
			title: newValue ? newValue?.title : '',
			id: newValue ? newValue?.id : ''
		})
		setSelectList({selected: newValue})
	}

	const getInputLabel = (option: OptionType) => {
		return option.title
	}

	const {isLoading, mutate} = useMutation(listExperiencesMainInfo, {
		networkMode: 'always'
	})

	const fetchExperiences = useCallback(async () => {
		if (!isLoading) {
			mutate(
				{offset: selectList.offset, limit: selectList.limit},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							const newList = result.data.experiences as ExperienceMainInfo[]
							setSelectList({
								data: [...selectList.data, ...newList],
								offset: selectList.offset + selectList.limit,
								hasMore: result.data.hasMore
							})
						} else {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		}
	}, [mutate, t, isLoading, selectList, setSelectList])

	return (
		<>
			<AlertDialog
				open={openAlert}
				severity="error"
				onClose={() => setOpenAlert(false)}
				message={alertMessage}
			/>
			<Autocomplete
				id={'select-' + name}
				fullWidth
				options={selectList.data.map((experience, index) => ({
					id: experience.id,
					index,
					title: experience.title,
					cover: experience.cover,
					status: experience.status
				}))}
				onFocus={() => {
					if (selectList.data.length === 0) {
						fetchExperiences()
					}
				}}
				value={selectedProductId && selectList.selected}
				autoFocus
				autoHighlight
				getOptionLabel={option =>
					typeof option !== 'string' ? getInputLabel(option) : option
				}
				getOptionDisabled={option => {
					if (disabledOptions) {
						return disabledOptions.some(
							disabledOption => disabledOption.id === (option as OptionType).id
						)
					}
					return false
				}}
				onChange={(_: any, newValue: string | OptionType | null) =>
					newValue && typeof newValue !== 'string'
						? handleChange(_, newValue)
						: handleChange(_, null)
				}
				onInputChange={(_, newInputValue) => {
					if (newInputValue === '') {
						onChange(null)
					}
				}}
				sx={{flex: 1}}
				isOptionEqualToValue={(option, value) =>
					typeof option !== 'string' && option?.id === (value as OptionType)?.id
				}
				loading={isLoading}
				ListboxProps={{
					onScroll: event => {
						const target = event.target as HTMLDivElement

						const isEndOfList =
							target.scrollHeight - target.scrollTop === target.clientHeight

						if (isEndOfList && selectList.hasMore) {
							fetchExperiences()
						}
					}
				}}
				clearText={t('to-remove')}
				renderOption={(props, option) => (
					<Box
						{...props}
						key={'experience-select-option-' + (option as OptionType)?.id}
						component="li"
						sx={{
							display: 'flex',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
							p: '0px !important'
						}}
					>
						<Box
							width="60"
							height="60"
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								br: 0.5,
								p: 2,
								position: 'relative'
							}}
						>
							{(option as OptionType).cover ? (
								<Box
									sx={{
										width: 66.7,
										height: 50,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										backgroundColor: theme => theme.palette.grey[100]
									}}
								>
									<img
										src={(option as OptionType).cover}
										alt={(option as OptionType).title}
										width={66.7}
										height={50}
										style={{
											borderRadius: 4,
											margin: '0px !important',
											objectFit: 'cover'
										}}
									/>
								</Box>
							) : (
								<Box
									sx={theme => ({
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: 66.7,
										height: 50,
										backgroundColor: theme.palette.grey[100],
										border: '1px solid',
										borderColor: 'divider',
										borderRadius: '4px'
									})}
								>
									<Icon
										sx={theme => ({
											color: theme.palette.grey[400],
											ml: 0.5,
											mb: 0.5
										})}
										fontSize="large"
									>
										<TagIcon />
									</Icon>
								</Box>
							)}
						</Box>
						<Box>
							<Status.Tag type={(option as OptionType).status} size="small">
								{t('status-tag-' + (option as OptionType).status)}
							</Status.Tag>
							<Typography variant="body2" mt={0.5}>
								{(option as OptionType).title}
							</Typography>
						</Box>
					</Box>
				)}
				renderInput={params => (
					<TextField
						{...params}
						size="small"
						name={name}
						label={label}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{isLoading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</>
							)
						}}
					/>
				)}
			/>
		</>
	)
}
