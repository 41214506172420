import {Box, IconButton, Popover, SxProps} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {SystemStyleObject} from '@mui/system/styleFunctionSx'
import {useEffect, useRef, useState} from 'react'
import {BsInfoCircleFill as InfoIcon} from 'react-icons/bs'

interface InfoPopoverProps {
	children: React.ReactNode
	anchorOrigin?: {
		vertical: 'top' | 'bottom' | number
		horizontal: 'left' | 'right' | number
	}
	transformOrigin?: {
		vertical: 'top' | 'bottom' | number
		horizontal: 'left' | 'right' | number
	}
	sx?: SxProps<Theme>
	iconSx?: SystemStyleObject<Theme>
	open?: boolean
}

const InfoPopover = ({
	children,
	sx,
	iconSx,
	anchorOrigin,
	transformOrigin,
	open
}: InfoPopoverProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const anchorRef = useRef<HTMLButtonElement | null>(null)

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const popoverOpen = Boolean(anchorEl)

	useEffect(() => {
		if (open === true) {
			setAnchorEl(anchorRef.current)
		}

		if (open === false) {
			setAnchorEl(null)
		}
	}, [open, anchorRef])

	return (
		<Box sx={sx}>
			<IconButton
				ref={anchorRef}
				size="small"
				aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
				sx={theme => style.infoIcon(theme, iconSx)}
			>
				<InfoIcon fontSize="inherit" />
			</IconButton>
			<Popover
				id="mouse-over-popover"
				open={popoverOpen}
				anchorEl={anchorEl}
				anchorOrigin={
					anchorOrigin || {
						vertical: 'bottom',
						horizontal: 'right'
					}
				}
				transformOrigin={
					transformOrigin || {
						vertical: 'top',
						horizontal: 'right'
					}
				}
				onClose={handlePopoverClose}
				disableRestoreFocus
				sx={style.popover}
			>
				<Box sx={{p: 1.5}}>{children}</Box>
			</Popover>
		</Box>
	)
}

const style = {
	popoverBox: (direction?: {
		left?: number
		top?: number
		bottom?: number
		right?: number
	}) => ({
		position: 'absolute',
		...direction
	}),
	popover: (theme: Theme) => ({
		pointerEvents: 'none',
		'& .MuiPopover-paper': {
			maxWidth: 400
		},
		[theme.breakpoints.down('sm')]: {
			'& .MuiPopover-paper': {
				maxWidth: '80%'
			}
		}
	}),
	infoIcon: (theme: Theme, sx?: SystemStyleObject<Theme>) => ({
		color: theme.palette.grey[300],
		width: '2rem',
		height: 'fit-content',
		justifyContent: 'end',
		p: 1,
		'&:hover': {color: theme.palette.grey[500]},
		...sx
	})
}

export default InfoPopover
