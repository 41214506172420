import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ChartData,
	BarElement
} from 'chart.js'
import {Line} from 'react-chartjs-2'

import {useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import {listSalesRevenueMetrics} from '../../api/sales'
import {regex} from '../../helpers/regex'
import {makeMetricsFilter} from '../../helpers/factories/metricsFilterFactory'
import AlertDialog from '../../components/AlertDialog'
import useAppStates from '../../stores/useAppStates'
import Skeleton from '@mui/material/Skeleton'
import useSaleStates, {
	SaleMetricsPreferencesFilter
} from '../../stores/useSaleStates'
import {price} from '../../helpers/price'

import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend
)

const SalesMetrics = () => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()
	const theme = useTheme()

	const [firstLoad, setFirstLoad] = useState(true)
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [revenue, setRevenue] = useState<number[]>([])
	const [salesPeriod, setSalesPeriod] = useState('last-12-months')
	const [xAxisLabels, setXAxisLabels] = useState<string[]>([])
	const {saleMetricsPreferences, setSaleMetricsPreferences} = useSaleStates()
	const [graphicData, setGraphicData] = useState<
		ChartData<'line', any[], unknown>
	>({
		labels: xAxisLabels,
		datasets: [
			{
				label: t('total-revenue'),
				data: revenue,
				tension: 0.2
			}
		]
	})

	const graphicConfig: any = {
		responsive: true,
		interaction: {
			mode: 'index',
			intersect: false
		},
		stacked: false,
		plugins: {
			legend: {
				display: true,
				position: 'bottom',
				labels: {
					usePointStyle: true,
					boxWidth: 0,
					boxHeight: 0,
					pointStyle: 'circle'
				}
			},
			title: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: (context: any) => {
						return (
							'R$ ' +
							regex.formatBRLCurrency(
								Number.parseFloat(String(context.raw)).toFixed(2)
							)
						)
					}
				}
			}
		},
		scales: {
			y: {
				ticks: {
					stepSize: 400
				},
				grid: {
					color: theme.palette.divider
				}
			},
			x: {
				grid: {
					color: theme.palette.divider
				}
			}
		}
	}

	const handleChangeSalesPeriod = (event: SelectChangeEvent) => {
		const {fromDate, toDate, xAxisLabels} = makeMetricsFilter(
			event.target.value as SaleMetricsPreferencesFilter
		)
		setSalesPeriod(event.target.value as string)
		filterMetrics({
			fromDate,
			toDate,
			xAxisLabels,
			salePeriod: event.target.value as string
		})
	}

	const getPeriod = (period: string) => {
		if (period === 'today' || period === 'yesterday') {
			return 'hours'
		} else if (period === 'last-7-days' || period === 'last-30-days') {
			return 'days'
		}
		return 'months'
	}

	const {mutate, isLoading} = useMutation(listSalesRevenueMetrics, {
		networkMode: 'always'
	})

	const filterMetrics = useCallback(
		({
			fromDate,
			toDate,
			xAxisLabels,
			salePeriod: currentSalesPeriod
		}: {
			fromDate: string
			toDate: string
			xAxisLabels: string[]
			salePeriod: string
		}) => {
			if (!isLoading) {
				mutate(
					{
						fromDate,
						toDate,
						type: getPeriod(currentSalesPeriod) as 'days' | 'months' | 'hours'
					},
					{
						onSuccess: result => {
							if (result?.responseStatusCode === 200 && result.data) {
								setSaleMetricsPreferences({
									filter: currentSalesPeriod as SaleMetricsPreferencesFilter
								})

								if (
									currentSalesPeriod === 'last-7-days' &&
									result.data.revenueByDays
								) {
									const today = dayjs().endOf('day')

									const last7days = Array.from({length: 7}, (_, i) => {
										const date = dayjs(today).subtract(i, 'day')
										return date
									}).sort((a, b) => a.toDate().getTime() - b.toDate().getTime())

									const newValues: number[] = []
									const newLabels: string[] = []

									last7days.forEach(day => {
										newLabels.push(
											day.toDate().toLocaleDateString(currentLang, {
												day: 'numeric',
												month: 'short'
											})
										)

										const inDay = result.data?.revenueByDays?.find(
											(i: {day: string; revenue: number}) => {
												const revenueDate = dayjs(i.day)
													.startOf('day')
													.add(1, 'day')
												return dayjs(revenueDate).isSame(day, 'day')
											}
										)

										if (inDay) {
											newValues.push(inDay.revenue)
										} else {
											newValues.push(0)
										}
									})

									setGraphicData({
										labels: newLabels,
										datasets: [
											{
												label: `${t('total-revenue')}: ${price.full(
													newValues.reduce((a, b) => a + b, 0)
												)}`,
												data: newValues,
												borderColor: theme.palette.success.light,
												backgroundColor: theme.palette.success.light,
												tension: 0.2
											}
										]
									})
								} else if (
									currentSalesPeriod === 'last-30-days' &&
									result.data.revenueByDays
								) {
									const endDate = dayjs().startOf('day')

									const last30days = Array.from({length: 30}, (_, i) => {
										const date = dayjs(endDate).subtract(i, 'day')
										return date
									}).sort((a, b) => a.toDate().getTime() - b.toDate().getTime())

									const newValues: number[] = []
									const newLabels: string[] = []

									last30days.forEach(day => {
										newLabels.push(
											day.toDate().toLocaleDateString(currentLang, {
												day: 'numeric',
												month: 'short'
											})
										)

										const inDay = result.data?.revenueByDays?.find(
											(i: {day: string; revenue: number}) => {
												const revenueDate = dayjs(i.day)
													.startOf('day')
													.add(1, 'day')
												return dayjs(revenueDate).isSame(day, 'day')
											}
										)

										if (inDay) {
											newValues.push(inDay.revenue)
										} else {
											newValues.push(0)
										}
									}, 0)

									setGraphicData({
										labels: newLabels,
										datasets: [
											{
												label: `${t('total-revenue')}: ${price.full(
													newValues.reduce((a, b) => a + b, 0)
												)}`,
												data: newValues,
												borderColor: theme.palette.success.light,
												backgroundColor: theme.palette.success.light,
												tension: 0.2
											}
										]
									})
								} else if (
									currentSalesPeriod === 'last-12-months' &&
									result.data.revenueByMonths
								) {
									const startMonth = dayjs(fromDate).month() + 3
									const last12Months = Array.from({length: 12}, (_, i) => {
										return (startMonth + i) % 12
									})

									const newValues: number[] = []

									last12Months.forEach(month => {
										const inMonth = result?.data?.revenueByMonths?.find(
											i => i.month === month
										)

										if (inMonth) {
											newValues.push(inMonth.revenue)
										} else {
											newValues.push(0)
										}
									})

									setGraphicData({
										labels: xAxisLabels.map(month =>
											t('month-' + month).slice(0, 3)
										),
										datasets: [
											{
												label: `${t('total-revenue')}: ${price.full(
													result.data.revenueByMonths
														.map(i => i.revenue)
														.reduce((a, b) => a + b, 0)
												)}`,
												data: newValues,
												borderColor: theme.palette.success.light,
												backgroundColor: theme.palette.success.light,
												pointStyle: 'circle',
												tension: 0.2,
												fill: false
											}
										]
									})
								} else if (result.data.revenueByMonths) {
									const startMonth = dayjs(fromDate).month() + 2
									const last12Months = Array.from({length: 12}, (_, i) => {
										return (startMonth + i) % 12
									})
									const newValues: number[] = []

									last12Months.forEach(month => {
										const inMonth = result?.data?.revenueByMonths?.find(
											i => i.month === month
										)

										if (inMonth) {
											newValues.push(inMonth.revenue)
										} else {
											newValues.push(0)
										}
									})

									setGraphicData({
										labels: xAxisLabels.map(month =>
											t('month-' + month).slice(0, 3)
										),
										datasets: [
											{
												label: `${t('total-revenue')}: ${price.full(
													result.data.revenueByMonths
														.map(i => i.revenue)
														.reduce((a, b) => a + b, 0)
												)}`,
												data: newValues,
												borderColor: theme.palette.success.light,
												backgroundColor: theme.palette.success.light,
												pointStyle: 'circle',
												tension: 0.2,
												fill: false
											}
										]
									})
								} else if (result.data.revenueByHours) {
									const last24Hours = Array.from({length: 24}, (_, i) => i)
									const newValues: number[] = []

									last24Hours.forEach(hour => {
										const inHour = result?.data?.revenueByHours?.find(
											(i: {hour: number; revenue: number}) => i.hour === hour
										)

										if (inHour) {
											newValues.push(inHour.revenue)
										} else {
											newValues.push(0)
										}
									})

									setXAxisLabels(xAxisLabels)
									setGraphicData({
										labels: xAxisLabels,
										datasets: [
											{
												label: `${t('total-revenue')}: ${price.full(
													newValues.reduce((a: number, b: number) => a + b)
												)}`,
												data: newValues,
												borderColor: theme.palette.success.light,
												backgroundColor: theme.palette.success.light,
												pointStyle: 'circle',
												tension: 0.2,
												fill: false
											}
										]
									})
								}
							} else {
								setAlertMessage(t('error-server-default'))
								setOpenAlert(true)
							}
						},
						onError: error => {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
							console.error(error)
						}
					}
				)
			}
		},
		[t, mutate, currentLang, setSaleMetricsPreferences, isLoading]
	)

	useEffect(() => {
		if (firstLoad) {
			const {fromDate, toDate, xAxisLabels} = makeMetricsFilter(
				saleMetricsPreferences.filter
			)
			setSalesPeriod(saleMetricsPreferences.filter)
			filterMetrics({
				fromDate,
				toDate,
				xAxisLabels,
				salePeriod: saleMetricsPreferences.filter
			})
			setFirstLoad(false)
		}
	}, [firstLoad, filterMetrics, saleMetricsPreferences.filter])

	return (
		<>
			<AlertDialog
				open={openAlert}
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
			/>
			{firstLoad && isLoading && revenue.length === 0 ? (
				<Skeleton
					variant="rounded"
					height={350}
					sx={theme => ({
						width: 558,
						[theme.breakpoints.down('md')]: {
							width: '100%'
						}
					})}
				/>
			) : (
				<Paper variant="outlined" sx={style.chartBox}>
					<Box sx={style.chartHeader}>
						<Typography variant="h6">{t('sales')}</Typography>
						<FormControl size="small" sx={{minWidth: 110}}>
							<Select
								id="sales-chart-period-select"
								value={salesPeriod}
								onChange={handleChangeSalesPeriod}
								size="small"
							>
								<MenuItem value="today">{t('today')}</MenuItem>
								<MenuItem value="yesterday">{t('yesterday')}</MenuItem>
								<MenuItem value="last-7-days">{t('last-7-days')}</MenuItem>
								<MenuItem value="last-30-days">{t('last-30-days')}</MenuItem>
								<MenuItem value="last-12-months">
									{t('last-12-months')}
								</MenuItem>
								<MenuItem value="current-year">{t('current-year')}</MenuItem>
								<MenuItem value="last-year">{t('last-year')}</MenuItem>
							</Select>
						</FormControl>
					</Box>
					<Line options={graphicConfig} data={graphicData} />
				</Paper>
			)}
		</>
	)
}

const style = {
	chartBox: {
		padding: 2,
		maxWidth: 558,
		width: 'calc(100vw - 3em)'
	},
	chartHeader: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 1,
		justifyContent: 'space-between',
		marginBottom: 1.75
	}
}

export default SalesMetrics
