import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import {PagarmeLogo} from '../../components/logos/PagarmeLogo'
import {AsaasLogo} from '../../components/logos/AsaasLogo'
import {RiShoppingCart2Fill as CartIcon} from 'react-icons/ri'
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import {useCallback, useState} from 'react'
import useAuthStates from '../../stores/useAuthStates'
import Button from '@mui/material/Button'
import {useMutation} from '@tanstack/react-query'
import {updatePaymentServiceActive} from '../../api/payment-service/paymentService'
import AlertMessage from '../../components/AlertMessage'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import useAsaasStates from '../../stores/useAsaasStates'
import usePaymentServicePagarmeStates from '../../stores/usePaymentServicePagarmeStates'

export const ChangeActivePaymentServiceCard = () => {
	const {t} = useTranslation()
	const {paymentService, updateActivePaymentService} = useAuthStates()
	const {asaasConfig} = useAsaasStates()
	const {status: pagarmeStatus} = usePaymentServicePagarmeStates()

	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')
	const [checkoutPaymentService, setCheckoutPaymentService] = useState(
		paymentService?.activeService
	)

	const {mutate, isLoading} = useMutation(updatePaymentServiceActive, {
		networkMode: 'always'
	})

	const changeCheckoutPaymentService = useCallback(async () => {
		if (!checkoutPaymentService) return

		mutate(
			{
				service: checkoutPaymentService
			},
			{
				onSuccess: ({responseStatusCode, error}) => {
					if (responseStatusCode === 200) {
						updateActivePaymentService(checkoutPaymentService)
					} else {
						setOpenAlert(true)
						setAlertErrorMessage(error?.message || t('error-server-default'))
					}
				},
				onError: error => {
					setOpenAlert(true)
					setAlertErrorMessage(t('error-server-default'))
					console.error(error)
				}
			}
		)
	}, [checkoutPaymentService, mutate, t, updateActivePaymentService])

	return (
		<Card
			variant="outlined"
			sx={{
				padding: 2,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				gap: 3
			}}
		>
			<Box>
				<Box sx={{display: 'flex', alignItems: 'center', gap: 1.5, mb: 2}}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '50%',
							padding: 1,
							backgroundColor: 'grey.100'
						}}
					>
						<Icon sx={{display: 'inline-flex', color: 'grey.600'}}>
							<CartIcon />
						</Icon>
					</Box>
					<Typography variant="h6" gutterBottom component="h3" sx={{mb: 0}}>
						{t('checkout-preferences')}
					</Typography>
				</Box>
				<Typography>
					{t('advanced-settings-payment-service-description')}
				</Typography>
				<AlertMessage
					open={openAlert}
					severity="error"
					message={alertErrorMessage}
					onClose={() => setOpenAlert(false)}
					mt={2}
					sx={{mb: 2}}
				/>
				<FormControl size="small">
					<Select
						name="service"
						value={checkoutPaymentService}
						onChange={event =>
							setCheckoutPaymentService(
								event.target.value as 'asaas' | 'pagarme'
							)
						}
						sx={{
							minWidth: 200
						}}
					>
						{asaasConfig && asaasConfig.generalStatus === 'approved' && (
							<MenuItem value="asaas">
								<AsaasLogo height={24} />
							</MenuItem>
						)}
						<MenuItem value="pagarme" disabled={pagarmeStatus !== 'active'}>
							<PagarmeLogo height={24} />
						</MenuItem>
					</Select>
				</FormControl>
			</Box>
			<Button
				variant="contained"
				onClick={changeCheckoutPaymentService}
				disabled={isLoading}
				sx={{minWidth: 200}}
			>
				{isLoading ? (
					<CircularProgress size={24} color="inherit" />
				) : (
					t('to-save-changes')
				)}
			</Button>
		</Card>
	)
}
