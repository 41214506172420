import {RefCallback, forwardRef} from 'react'
import {IMaskInput} from 'react-imask'
import {InputBaseComponentProps} from '@mui/material'

interface CustomOnChangeProps {
	onChange: (event: {target: {name: string; value: string}}) => void
	name: string
	mask: string
}

const InputMask = forwardRef<
	HTMLInputElement,
	Omit<InputBaseComponentProps, 'onChange'> & CustomOnChangeProps
>((props, ref) => {
	const {onChange, mask, ...other} = props
	return (
		<IMaskInput
			{...other}
			mask={mask}
			inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
			onAccept={value =>
				onChange({target: {name: props.name, value: value as string}})
			}
			overwrite
		/>
	)
})

InputMask.displayName = 'InputMask'

export default InputMask
