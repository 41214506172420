import {SELLER_AUTH_API} from '../config'
import {BankAccount} from '../entities/BankAccount'
import {Company} from '../entities/Company'
import {Onboarding} from '../entities/Onboarding'
import {PaymentService} from '../entities/PaymentService'
import {RawProfile} from './entities/RawProfile'
interface RefreshTokenResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	data?: {
		newAccessToken: string
		sellerId: string
		email: string
		onboarding: Onboarding
		profile: RawProfile | null
		company: Company | null
		bankAccount: BankAccount | null
		paymentService: PaymentService | null
	}
	responseStatusCode?: number
}

export const refreshToken = async (
	signal: AbortSignal
): Promise<RefreshTokenResponse> => {
	const response = await fetch(`${SELLER_AUTH_API}/refresh-token`, {
		method: 'POST',
		body: '{}',
		credentials: 'include',
		signal
	})
	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}
