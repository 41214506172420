import {DEFAULT_LANG, SELLER_EXPERIENCES_API} from '../../config'
import {authenticatedFetch} from '../../helpers/authenticatedFetch'
import useAppStates from '../../stores/useAppStates'
import useAuthStates from '../../stores/useAuthStates'

interface Message {
	message: string
}

interface ListOfferGroupResponse {
	success?: Message
	error?: Message
	data?: {
		offerGroups: {
			id: string
			groupName: string
			description?: string
			products: {
				id: string
				publicId: string
				title: string
				cover?: string
				offer: {
					id: string
					title: string
					unitPrice: number
				}
			}[]
		}[]
	}
	responseStatusCode?: number
}

export const listOfferGroupsByProduct = async ({
	productId
}: {
	productId: string
}): Promise<ListOfferGroupResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${productId}/offer-groups`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CreateOfferGroupResponse {
	success?: Message
	error?: Message & {
		fields?: {
			groupName?: Message[]
			products?: Message[]
		}
	}
	data?: {
		offerGroup: {id: string}
	}
	responseStatusCode?: number
}

export const createOfferGroup = async ({
	productId,
	...values
}: {
	productId: string
	groupName: string
	description?: string
	products: {
		productId: string
		offerId: string
	}[]
}): Promise<CreateOfferGroupResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${productId}/offer-groups`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateOfferGroupResponse {
	success?: Message
	error?: Message & {
		fields?: {
			groupName?: Message[]
			products?: Message[]
		}
	}
	data?: {
		offerGroup: {id: string}
	}
	responseStatusCode?: number
}

export const updateOfferGroup = async ({
	offerGroupId,
	productId,
	...values
}: {
	offerGroupId: string
	productId: string
	groupName: string
	description?: string
	products: {
		productId: string
		offerId: string
	}[]
}): Promise<UpdateOfferGroupResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${productId}/offer-groups/${offerGroupId}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteOfferGroupResponse {
	success?: Message
	error?: Message
	responseStatusCode?: number
}

export const deleteOfferGroup = async ({
	productId,
	id
}: {
	productId: string
	id: string
}): Promise<DeleteOfferGroupResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${productId}/offer-groups/${id}`,
		{
			method: 'DELETE',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
