import {useState} from 'react'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {useMutation} from '@tanstack/react-query'

import useNavigate from '../../../hooks/useNavigate'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import {RiMailSendLine as IconEmailSend} from 'react-icons/ri'
import {CgClose as CloseIcon} from 'react-icons/cg'

import {Theme} from '@mui/material/styles'

import useCodeSchema from '../../../schemas/useCodeSchema'
import SectionTitle from '../../typography/SectionTitle'

import ButtonLink from '../../navigation/ButtonLink'

import {codeValidate} from '../../../api/forgotPassword'

const FormForgotPasswordCodeValidate = () => {
	const [errorAlert, setErrorAlert] = useState<boolean>(false)
	const [serverError, setServerError] = useState<string>('')

	const {t} = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()

	const navigationState = location.state as {email: string}

	let email: string | undefined | null = null
	if (navigationState?.email) {
		email = navigationState.email
	} else {
		email = sessionStorage.getItem('forgot-password-email')
	}

	const {isLoading, mutate} = useMutation(
		(values: {code: string; email: string}) => codeValidate(values),
		{networkMode: 'always'}
	)

	const codeSchema = useCodeSchema()
	const formik = useFormik({
		initialValues: {
			code: ''
		},
		validationSchema: codeSchema,
		onSubmit: code => {
			mutate(
				{...code, email: email as string},
				{
					onSuccess: (result, data) => {
						if (
							result?.responseStatusCode === 200 &&
							result?.success &&
							result?.data?.token
						) {
							navigate('/forgot-password/password-reset', {
								state: {email: data.email, token: result.data.token}
							})
						} else if (
							result?.responseStatusCode === 400 &&
							result?.error?.fields?.code
						) {
							formik.setFieldError('code', result.error.fields.code[0].message)
						} else if (result?.responseStatusCode === 401 && result?.error) {
							sessionStorage.removeItem('forgot-password-email')
							navigate('/forgot-password', {
								state: {
									error: {
										alert: true,
										message: t('error-password-reset-attempts-exceeded')
									}
								}
							})
						} else if (result?.responseStatusCode === 403 && result?.error) {
							sessionStorage.removeItem('forgot-password-email')
							navigate('/forgot-password', {
								state: {
									error: {
										alert: true,
										message: t('error-password-reset-code-expired')
									}
								}
							})
						} else {
							setErrorAlert(true)
							setServerError(t('error-server-default'))
							console.error(result?.error?.message)
						}
					},
					onError: error => {
						setErrorAlert(true)
						setServerError(t('error-server-default'))
						console.error(error)
					}
				}
			)
		}
	})

	return (
		<Box sx={style.formBox}>
			{email ? (
				<>
					<SectionTitle>{t('verify-your-email')}</SectionTitle>
					<Stack
						direction="row"
						sx={{
							alignItems: 'center',
							justifyContent: 'flex-start',
							flexWrap: 'wrap',
							margin: '10px 10px 10px 0'
						}}
					>
						<IconEmailSend
							style={{
								width: '50px',
								height: '50px',
								margin: '10px 10px 10px 0'
							}}
						/>
						<div style={{width: 'calc(100% - 60px)', minWidth: '210px'}}>
							<Typography>
								{t('verify-your-email-info')} <b>{email}</b>
							</Typography>
						</div>
					</Stack>
					<Typography>{t('verify-your-email-info2')}</Typography>
					<Collapse in={errorAlert}>
						<Alert
							severity="error"
							action={
								<IconButton
									aria-label={t('aria-close-alert')}
									color="inherit"
									size="small"
									onClick={() => {
										setErrorAlert(false)
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{mt: 3}}
						>
							{serverError}
						</Alert>
					</Collapse>
					<Box
						onSubmit={formik.handleSubmit}
						component="form"
						autoComplete="off"
						sx={{mt: 3}}
					>
						<Stack direction="column" spacing={4}>
							<TextField
								name="code"
								type="text"
								inputProps={{
									inputMode: 'numeric',
									maxLength: 6
								}}
								label={t('verification-code')}
								variant="outlined"
								helperText={
									formik.touched.code && Boolean(formik.errors.code)
										? formik.errors.code
										: t('helper-input-code')
								}
								error={formik.touched.code && Boolean(formik.errors.code)}
								margin="normal"
								value={formik.values.code}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								autoFocus
							/>
							<Button
								size="large"
								variant="contained"
								type="submit"
								disabled={isLoading}
								sx={{height: '44px'}}
							>
								{isLoading ? (
									<CircularProgress color="inherit" size={25} />
								) : (
									t('confirm')
								)}
							</Button>
						</Stack>
					</Box>
				</>
			) : (
				<>
					<SectionTitle>{t('registration-not-found')}</SectionTitle>
					<Alert severity="error" sx={{mt: 5}}>
						{t('error-password-reset-code-expired')}
					</Alert>
					<ButtonLink
						to="/forgot-password"
						size="large"
						variant="outlined"
						sx={{mt: 5, width: '100%'}}
					>
						{t('reset-password')}
					</ButtonLink>
				</>
			)}
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			my: 5,
			padding: 5,
			width: '80%',
			maxWidth: '600px',
			[theme.breakpoints.down('md')]: {
				my: 0,
				padding: 3,
				width: '100%'
			}
		}
	}
}

export default FormForgotPasswordCodeValidate
