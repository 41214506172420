import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import LogoType from './LogoType'
import Link from '../navigation/Link'
import LanguageMenu from './LanguageMenu'

const Header = () => {
	return (
		<AppBar>
			<Toolbar variant="dense">
				<Container disableGutters sx={style.containerHeader}>
					<Link to="/" style={style.linkLogo}>
						<LogoType logoSize="2.8rem" typographyVariant="h5" />
					</Link>
					<Nav>
						<LanguageMenu />
					</Nav>
				</Container>
			</Toolbar>
		</AppBar>
	)
}

interface NavProps {
	children: React.ReactNode
}

const Nav = (props: NavProps) => {
	return (
		<Box ml="auto" component="nav" sx={{display: 'flex'}}>
			{props.children}
		</Box>
	)
}

const style = {
	containerHeader: {
		alignItems: 'center',
		display: 'flex',
		minHeight: '68px'
	},
	linkLogo: {
		textDecoration: 'none',
		width: 'max-content'
	}
}

export default Header
