import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {INPUT} from '../config'

const useExperienceSchema = () => {
	const {t} = useTranslation()

	const experienceSchema = yup.object({
		title: yup
			.string()
			.required(t('error-title-required'))
			.min(
				INPUT.EXPERIENCE.title.min,
				t('error-title-min-length', {min: INPUT.EXPERIENCE.title.min})
			)
			.max(
				INPUT.EXPERIENCE.title.max,
				t('error-title-max-length', {max: INPUT.EXPERIENCE.title.max})
			),
		slug: yup
			.string()
			.required(t('error-slug-required'))
			.matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t('error-slug-invalid'))
			.min(INPUT.EXPERIENCE.slug.min, t('error-slug-min-length'))
			.max(INPUT.EXPERIENCE.slug.max, t('error-slug-max-length')),
		description: yup
			.string()
			.required(t('error-description-required'))
			.min(
				INPUT.EXPERIENCE.description.min,
				t('error-description-min-length', {
					minLength: INPUT.EXPERIENCE.description.min
				})
			)
			.max(
				INPUT.EXPERIENCE.description.max,
				t('error-description-max-length', {
					maxLength: INPUT.EXPERIENCE.description.max
				})
			)
	})

	return experienceSchema
}

export default useExperienceSchema
