import {SalePage} from '../../entities/SalePage'
import {makeImageURL} from '../../helpers/factories/imageURLFactory'
import {image} from '../../helpers/image'
import {RawSalePage} from '../entities/RawSalePage'

export const makePhoto = async (photo: {
	id: string
	basePath: string
	imageName: string
	imagePath: string
}) => {
	const url = makeImageURL({
		basePath: photo.basePath,
		imagePath: photo.imagePath
	})

	const {height, width} = await image.getDimensions(url)

	return {
		id: photo.id,
		url,
		preview: url,
		name: photo.imageName,
		height,
		width,
		uploaded: true,
		progress: 100
	}
}

const getPhotos = async (
	rawSalePage: RawSalePage
): Promise<SalePage['photos']> => {
	if (!rawSalePage.photos) {
		return []
	}

	const photos = rawSalePage.photos.map(async photo => makePhoto(photo))

	return await Promise.all(photos)
}

export const salePageMapper = {
	toDomain: async (rawSalePage: RawSalePage): Promise<SalePage> => {
		const sections = rawSalePage.sections
			? rawSalePage.sections.map(section => {
					return {
						...section,
						component: {
							...section.component,
							image: section.component.image
								? {
										...section.component.image,
										url: makeImageURL({
											basePath: section.component.image.basePath,
											imagePath: section.component.image.imagePath
										})
								  }
								: undefined
						}
					}
			  })
			: []

		const photos = await getPhotos(rawSalePage)

		return {
			_id: rawSalePage._id,
			lastModifiedAt: rawSalePage.lastModifiedAt,
			createdAt: rawSalePage.createdAt,
			cover: rawSalePage.cover
				? makeImageURL({
						basePath: rawSalePage.cover.basePath,
						imagePath: rawSalePage.cover.imagePath
				  })
				: undefined,
			photos,
			photosSorting: rawSalePage?.photosSorting || [],
			published: rawSalePage.published,
			disablePublish: rawSalePage.disablePublish,
			sectionsSorting: rawSalePage.sectionsSorting,
			sectionsCount: rawSalePage.sectionsCount || 0,
			sections,
			experience: {
				_id: rawSalePage.experience._id,
				title: rawSalePage.experience.title,
				description: rawSalePage.experience.description,
				slug: rawSalePage.experience.slug,
				status: rawSalePage.experience.status
			},
			editions: rawSalePage.editions,
			owner: {
				name: rawSalePage.owner.name,
				nameSlug: rawSalePage.owner.nameSlug,
				avatar: makeImageURL({
					basePath: rawSalePage.owner.images.basePath,
					imagePath: rawSalePage.owner.images.avatarPath
				}),
				logoDark: makeImageURL({
					basePath: rawSalePage.owner.images.basePath,
					imagePath: rawSalePage.owner.images.logoDarkPath
				}),
				logoLight: makeImageURL({
					basePath: rawSalePage.owner.images.basePath,
					imagePath: rawSalePage.owner.images.logoLightPath
				})
			}
		}
	}
}
