import {useState, useMemo} from 'react'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Skeleton from '@mui/material/Skeleton'
import {useTranslation} from 'react-i18next'
import {FaHome as HomeIcon} from 'react-icons/fa'
import {useQuery} from '@tanstack/react-query'

import Footer from '../../components/template/Footer'
import Link from '../../components/navigation/Link'
import SectionTitle from '../../components/typography/SectionTitle'
import {PagarmeCard} from './pagarme/cards/PagarmeCard'
import {getPaymentService} from '../../api/payment-service/paymentService'
import usePaymentServicePagarmeStates from '../../stores/usePaymentServicePagarmeStates'
import AlertMessage from '../../components/AlertMessage'
import {getPaymentServicePagarme} from '../../api/payment-service/pagarme'
import useAuthStates from '../../stores/useAuthStates'
import {DashboardFullSection} from '../../components/template/DashboardFullSection'
import {AsaasCard} from './asaas/AsaasCard'
import {getAsaasSubAccount} from '../../api/payment-service/asaas'
import useAsaasStates from '../../stores/useAsaasStates'
import {ChangeActivePaymentServiceCard} from './ChangeActivePaymentServiceCard'

const PaymentServices = () => {
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')
	const {t} = useTranslation()

	const {setPaymentService} = useAuthStates()
	const {asaasConfig, setAsaasConfig, setAsaasCardAlertError} = useAsaasStates()

	const {
		status: pagarmeStatus,
		setStatus,
		setBankAccount,
		setCompanyAnnualRevenue,
		setCompanyLegalRepresentative,
		setTransferSettings,
		setRecipientStatusHistory,
		setTransfers
	} = usePaymentServicePagarmeStates()

	const anyPaymentService = useMemo(() => {
		return Boolean(asaasConfig !== null || pagarmeStatus !== 'pending-setup')
	}, [asaasConfig, pagarmeStatus])

	useQuery(['payment-service'], getPaymentService, {
		networkMode: 'always',
		refetchOnWindowFocus: false,
		onSuccess: ({data, responseStatusCode, error}) => {
			if (responseStatusCode === 200) {
				setPaymentService({
					activeService: data?.activeService
				})
			} else {
				setOpenAlert(true)
				setAlertErrorMessage(error?.message || t('error-server-default'))
			}
		},
		onError: error => {
			setOpenAlert(true)
			setAlertErrorMessage(t('error-server-default'))
			console.error(error)
		}
	})

	const {isFetching} = useQuery(
		['payment-service-pagarme'],
		getPaymentServicePagarme,
		{
			refetchOnWindowFocus: false,
			networkMode: 'always',
			onSuccess: ({data, responseStatusCode, error}) => {
				if (responseStatusCode === 200 && data) {
					const {
						status,
						bankAccount,
						companyAnnualRevenue,
						companyLegalRepresentative,
						transferSettings,
						activationRecipientStatusHistory,
						transfers
					} = data
					setStatus(status)
					setBankAccount(bankAccount)
					setCompanyAnnualRevenue(companyAnnualRevenue || null)
					setCompanyLegalRepresentative(companyLegalRepresentative || null)
					setTransferSettings(transferSettings)
					setRecipientStatusHistory(activationRecipientStatusHistory)
					setTransfers(transfers)
				} else if (responseStatusCode !== 200) {
					setOpenAlert(true)
					setAlertErrorMessage(error?.message || t('error-server-default'))
				}
			},
			onError: error => {
				setOpenAlert(true)
				setAlertErrorMessage(t('error-server-default'))
				console.error(error)
			}
		}
	)

	const {isFetching: isFetchingAsaas} = useQuery(
		['ps-asaas-sub-account'],
		getAsaasSubAccount,
		{
			refetchOnWindowFocus: false,
			networkMode: 'always',
			enabled: !asaasConfig,
			onSuccess: ({data, responseStatusCode, error}) => {
				if (responseStatusCode === 200 && data) {
					setAsaasConfig(data)
				} else if (responseStatusCode !== 200) {
					setAsaasCardAlertError({
						open: true,
						message: error?.message || t('error-server-default')
					})
				}
			},
			onError: error => {
				setAsaasCardAlertError({
					open: true,
					message: t('error-server-default')
				})
				console.error(error)
			}
		}
	)

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<span>{t('payment-services')}</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<Box sx={styles.main}>
					<SectionTitle sx={{mb: 3}}>{t('payment-services')}</SectionTitle>
					{openAlert && (
						<AlertMessage
							severity="error"
							open={openAlert}
							message={alertErrorMessage}
							onClose={() => setOpenAlert(false)}
							sx={{
								mb: 3
							}}
						/>
					)}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 4
						}}
					>
						{anyPaymentService && <ChangeActivePaymentServiceCard />}
						{isFetchingAsaas ? (
							<Skeleton variant="rounded" width="100%" height="84.5px" />
						) : (
							<AsaasCard />
						)}
						{isFetching ? (
							<Skeleton variant="rounded" width="100%" height="84.5px" />
						) : (
							<PagarmeCard />
						)}
					</Box>
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

const styles = {
	main: {
		mx: 'auto',
		mt: 0,
		width: '100%',
		p: 0
	}
}

export default PaymentServices
