import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import {date} from '../../../helpers/date'
import {price} from '../../../helpers/price'
import InfoPopover from '../../../components/InfoPopover'
import {Status} from '../../../components/Status'
import {banksCode} from '../../../components/BankSelect'
import {useCallback, useEffect, useState} from 'react'
import {PagarmeTransfer} from '../../../stores/usePaymentServicePagarmeStates'
import useAppStates from '../../../stores/useAppStates'
import {useMutation} from '@tanstack/react-query'
import {listPagarmeTransfers} from '../../../api/payment-service/pagarme'
import Button from '@mui/material/Button'
import AlertMessage from '../../../components/AlertMessage'
import TablePagination from '@mui/material/TablePagination'
import Container from '@mui/material/Container'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '../../../components/navigation/Link'
import {FaHome as HomeIcon} from 'react-icons/fa'
import SectionTitle from '../../../components/typography/SectionTitle'
import Skeleton from '@mui/material/Skeleton'
import Footer from '../../../components/template/Footer'
import {useSearchParams} from 'react-router-dom'
import {DashboardFullSection} from '../../../components/template/DashboardFullSection'

const TableRowSkeleton = () => {
	return (
		<TableRow>
			<TableCell sx={{pl: 3}}>
				<Typography variant="body2">
					<Skeleton width={80} />
				</Typography>
				<Typography variant="caption" color="grey.700">
					<Skeleton width={32} />
				</Typography>
			</TableCell>
			<TableCell>
				<Typography variant="body2">
					<Skeleton width={50} />
				</Typography>

				<Typography variant="caption" color="grey.700">
					<Skeleton width={90} />
				</Typography>
			</TableCell>
			<TableCell>
				<Typography variant="body2">
					<Skeleton width={130} />
				</Typography>
				<Typography variant="caption" color="grey.700">
					<Skeleton width={112} />
				</Typography>
			</TableCell>
			<TableCell>
				<Skeleton variant="rounded" width={93} height={22} />
			</TableCell>
		</TableRow>
	)
}

export const PagarmeTransfers = () => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()
	const [searchParams] = useSearchParams()

	const [openAlert, setOpenAlert] = useState(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')
	const [offset, setOffset] = useState<number>(0)
	const [limit, setLimit] = useState<number>(20)
	const [page, setPage] = useState(0)
	const [transfers, setTransfers] = useState<PagarmeTransfer[]>([])
	const [total, setTotal] = useState<number>(0)

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
		setOffset(newPage * limit)
		handleChangeURL(newPage)
	}

	const handleChangeURL = (page: number) => {
		window.history.replaceState(
			undefined,
			'Dashboard | Excofy',
			`/${currentLang}/payment-services/pagarme/transfers?page=${page}`
		)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(+event.target.value)
		setOffset(0)
		setPage(0)
	}

	const {mutate, isLoading} = useMutation(listPagarmeTransfers, {
		networkMode: 'always'
	})

	const fetchTransfers = useCallback(() => {
		mutate(
			{offset, limit},
			{
				onSuccess: ({responseStatusCode, data, error}) => {
					if (responseStatusCode === 200 && data) {
						setTransfers(data.transfers)
						setTotal(data.count)
					} else {
						setOpenAlert(true)
						setAlertErrorMessage(error?.message || t('error-server-default'))
					}
				},
				onError: error => {
					setOpenAlert(true)
					setAlertErrorMessage(t('error-server-default'))
					console.error(error)
				}
			}
		)
	}, [mutate, t, offset, limit])

	useEffect(() => {
		fetchTransfers()
	}, [fetchTransfers])

	useEffect(() => {
		const currentPage = searchParams.get('page')
		if (currentPage) {
			setOffset(+currentPage * limit)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link
						to="/payment-services"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<span>{t('payment-services')}</span>
					</Link>
					<span>{t('transfers-history')}</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<Box>
					<SectionTitle sx={{mb: 3}}>{t('transfers-history')}</SectionTitle>
					<Box display="grid">
						<AlertMessage
							open={openAlert}
							onClose={() => setOpenAlert(false)}
							severity="error"
							message={alertErrorMessage}
						/>
						{openAlert && (
							<Button onClick={fetchTransfers}>{t('to-try-again')}</Button>
						)}
						<TableContainer>
							<Table sx={{minWidth: '600px'}}>
								<TableHead>
									<TableRow>
										<TableCell sx={{pl: 3}}>{t('date')}</TableCell>
										<TableCell>{t('requested-amount')}</TableCell>
										<TableCell>{t('beneficiary')}</TableCell>
										<TableCell sx={{pr: 3}}>{t('status')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{isLoading && (
										<>
											<TableRowSkeleton />
											<TableRowSkeleton />
											<TableRowSkeleton />
											<TableRowSkeleton />
											<TableRowSkeleton />
										</>
									)}
									{!isLoading &&
										transfers.map(transfer => (
											<TableRow key={transfer.id}>
												<TableCell sx={{pl: 3}}>
													<Typography variant="body2">
														{date.convertISOToLocation(
															transfer.createdAt,
															currentLang
														)}
													</Typography>
													<Typography variant="caption" color="grey.700">
														{date.getTime(transfer.createdAt, currentLang)}
													</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="body2">
														{price.full(transfer.amount)}
													</Typography>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center'
														}}
													>
														<Typography variant="caption" color="grey.700">
															{t('fee')}: {price.full(transfer.feeAmount)}
														</Typography>
														<InfoPopover
															sx={{
																'>button': {
																	p: 0,
																	justifyContent: 'flex-start',
																	ml: 0.5,
																	mt: '-2px',
																	width: '12px'
																}
															}}
															transformOrigin={{
																vertical: 'top',
																horizontal: 'left'
															}}
															anchorOrigin={{
																vertical: 20,
																horizontal: 'left'
															}}
														>
															{t('info-ted-transfer-fee')}
														</InfoPopover>
													</Box>
												</TableCell>
												<TableCell>
													<Typography variant="body2">
														{
															banksCode.find(
																bankCode => transfer.bankCode === bankCode.code
															)?.label
														}
													</Typography>
													<Typography variant="caption" color="grey.700">
														{transfer.branchNumber}
														{transfer.branchCheckDigit
															? `-${transfer.branchCheckDigit}`
															: ''}{' '}
														/ {transfer.accountNumber}-
														{transfer.accountCheckDigit}
													</Typography>
												</TableCell>
												<TableCell>
													<Status.Tag type={transfer.status}>
														<Status.Label
															label={t('status-tag-' + transfer.status)}
															maxWidth="auto"
															showTooltip={false}
														/>
													</Status.Tag>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						{transfers.length > 0 && (
							<TablePagination
								rowsPerPageOptions={[20, 50, 100]}
								component="div"
								count={total}
								rowsPerPage={limit}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage={t('items-per-page')}
								labelDisplayedRows={({from, to, count}) =>
									t('list-filter-info', {from, to, count})
								}
								sx={{borderTop: 1, borderColor: 'divider'}}
							/>
						)}
					</Box>
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}
