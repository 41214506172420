import {useTranslation} from 'react-i18next'
import {Dialog} from '../../../../components/Dialog'
import {
	Box,
	Button,
	CircularProgress,
	InputAdornment,
	Stack,
	TextField,
	Typography
} from '@mui/material'

import * as yup from 'yup'
import {useFormik} from 'formik'
import {regex} from '../../../../helpers/regex'
import {useCallback, useMemo, useState} from 'react'
import {createPagarmeTransfer} from '../../../../api/payment-service/pagarme'
import {useMutation} from '@tanstack/react-query'
import usePaymentServicePagarmeStates from '../../../../stores/usePaymentServicePagarmeStates'
import AlertMessage from '../../../../components/AlertMessage'
import {price} from '../../../../helpers/price'

interface FormTransferProps {
	open: boolean
	onClose: () => void
	onSuccess: () => void
}

export const FormTransfer = ({open, onClose, onSuccess}: FormTransferProps) => {
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)
	const {t} = useTranslation()
	const {balance, addTransfer, setBalance, bankAccount} =
		usePaymentServicePagarmeStates()

	const {isLoading, mutate} = useMutation(createPagarmeTransfer, {
		networkMode: 'always'
	})

	const validationSchema = useMemo(
		() =>
			yup.object({
				amount: yup
					.string()
					.required(t('error-transfer-amount-required'))
					.test(
						'amount-min',
						t('error-transfer-amount-min', {fee: price.full(10)}),
						value => {
							if (value) {
								const parsedValue = regex.parseBRLCurrencyToFloat(value)

								return parsedValue >= 10
							}
							return true
						}
					)
					.test(
						'amount-max',
						t('error-transfer-amount-max', {
							fee: price.full(balance?.availableAmountToTransfer || 0)
						}),
						value => {
							if (value && balance) {
								const parsedValue = regex.parseBRLCurrencyToFloat(value)

								return balance.availableAmountToTransfer >= parsedValue
							}
							return true
						}
					)
			}),
		[balance, t]
	)

	const formik = useFormik({
		initialValues: {
			amount: balance ? price.decimal(balance.availableAmountToTransfer) : ''
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			setOpenAlertError(false)
			mutate(
				{
					amount: regex.parseBRLCurrencyToFloat(formik.values?.amount || '0')
				},
				{
					onSuccess: ({error, responseStatusCode, data}) => {
						if (responseStatusCode === 200 && data) {
							addTransfer({
								...data,
								amount: regex.parseBRLCurrencyToFloat(
									formik.values?.amount || '0'
								),
								...(bankAccount ? bankAccount : {})
							})
							setShowSuccessButton(true)
							if (data.balance) {
								setBalance(data.balance)
							}
							onSuccess()
						} else if (responseStatusCode === 400 && error?.fields) {
							formik.setFieldError('amount', error?.fields.amount[0].message)
						} else {
							setAlertErrorMessage(error?.message || t('error-server-default'))
							setOpenAlertError(true)
						}
					},
					onError: error => {
						setAlertErrorMessage(t('error-server-default'))
						setOpenAlertError(true)
						console.error(error)
					}
				}
			)
		}
	})

	const handleClose = useCallback(() => {
		formik.resetForm()
		setShowSuccessButton(false)
		onClose()
	}, [formik, onClose])

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={handleClose}>
					{t('transfer-balance')}
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<Stack spacing={4} flex={1} py={1}>
					{openAlertError ? (
						<AlertMessage
							message={alertErrorMessage}
							open={openAlertError}
							onClose={() => setOpenAlertError(false)}
							severity="error"
							mt={0}
						/>
					) : null}
					<Box>
						<Typography variant="body1" sx={{mb: 2}}>
							{t('info-ted-transfer')}
						</Typography>
						<Typography variant="body1">
							{t('balance')}
							{': '}
							<b>{price.full(balance?.availableAmount || 0)}</b>
						</Typography>
						<Typography variant="body1">
							{t('available-balance-to-transfer')}
							{': '}
							<b>{price.full(balance?.availableAmountToTransfer || 0)}</b>
						</Typography>
					</Box>
					<TextField
						name="amount"
						type="text"
						label={t('amount')}
						variant="outlined"
						helperText={
							formik.touched.amount && Boolean(formik.errors.amount)
								? formik.errors.amount
								: t('helper-transfer-amount')
						}
						error={formik.touched.amount && Boolean(formik.errors.amount)}
						margin="normal"
						value={formik.values.amount}
						onChange={event => {
							event.target.value = regex.formatBRLCurrency(event.target.value)
							formik.handleChange(event)
							setShowSuccessButton(false)
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">{'R$'}</InputAdornment>
							)
						}}
						onBlur={formik.handleBlur}
						size="small"
					/>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={theme => ({
								height: '44px',
								backgroundColor: 'success.light',
								color: 'success.contrastText',
								'&:hover': {backgroundColor: 'success.light'}
							})}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							size="large"
							variant="contained"
							type="submit"
							disabled={isLoading}
							sx={{height: '44px'}}
							onClick={formik.submitForm}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={25} />
							) : (
								t('to-transfer-now')
							)}
						</Button>
					)}
				</Stack>
			</Dialog.Body>
		</Dialog.Root>
	)
}
