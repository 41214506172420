import {useCallback, useEffect, useRef, useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import {useFormik} from 'formik'
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Collapse,
	FormControl,
	FormGroup,
	FormHelperText,
	IconButton,
	Skeleton,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {CgClose as CloseIcon} from 'react-icons/cg'
import {ValidationError} from 'yup'

import useProfileSchema from '../../../schemas/useUpdateProfileSchema'
import useProfileImageSchema from '../../../schemas/useProfileImageSchema'
import ImageFileUpload from '../../ImageFileUpload'
import SectionTitle from '../../typography/SectionTitle'
import useAuthStates from '../../../stores/useAuthStates'
import {profileMapper} from '../../../api/mappers/profileMapper'
import {updateProfile} from '../../../api/updateProfile'
import {image} from '../../../helpers/image'
import {regex} from '../../../helpers/regex'
import Tag from '../../typography/Tag'
import './style.css'

interface Profile {
	name: string
	nameSlug: string
	primaryColor: string
	avatar: File
	logoLight: File
	logoDark: File
}

const FormUpdateProfile = () => {
	const [alert, setAlert] = useState<{
		type?: 'success' | 'error'
		message?: string
		show: boolean
	}>({
		type: 'success',
		message: '',
		show: false
	})
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const [profileInitialValues, setProfileInitialValues] =
		useState<Profile | null>(null)

	const {updateProfile: updateProfileState, profile} = useAuthStates()

	const alertRef = useRef<HTMLDivElement>(null)
	const nameRef = useRef<HTMLInputElement>(null)
	const nameSlugRef = useRef<HTMLInputElement>(null)
	const avatarRef = useRef<HTMLDivElement>(null)
	const logoLightRef = useRef<HTMLDivElement>(null)
	const logoDarkRef = useRef<HTMLDivElement>(null)

	const {t} = useTranslation()
	const profileSchema = useProfileSchema()
	const profileImageSchema = useProfileImageSchema()

	const handleChangeImage = async (
		field: 'avatar' | 'logoLight' | 'logoDark',
		file: File
	) => {
		handleOnChangeProfile()

		const errorMessage = await profileImageSchema[field]
			.validate(file)
			.then(() => {
				formik.setFieldValue(field, file)
			})
			.catch((err: ValidationError) => {
				formik.setFieldError(field, err.errors[0])
				return err.errors[0]
			})

		if (errorMessage) {
			return errorMessage
		}
	}

	const {isLoading, mutate} = useMutation(
		(values: {
			name: string
			nameSlug: string
			logoLight: File | null
			logoDark: File | null
			avatar: File | null
			primaryColor: string
		}) => updateProfile(values),
		{networkMode: 'always'}
	)

	const formik = useFormik({
		initialValues: {
			name: profileInitialValues?.name || '',
			nameSlug: profileInitialValues?.nameSlug || '',
			logoLight: profileInitialValues?.logoLight || null,
			logoDark: profileInitialValues?.logoDark || null,
			avatar: profileInitialValues?.avatar || null,
			primaryColor: profileInitialValues?.primaryColor || '#F1BB0C'
		},
		enableReinitialize: true,
		validationSchema: profileSchema,
		onSubmit: values => {
			setAlert({show: false})
			mutate(
				{
					...values,
					logoLight:
						values.logoLight?.name !== profileInitialValues?.logoLight.name
							? values.logoLight
							: null,
					logoDark:
						values.logoDark?.name !== profileInitialValues?.logoDark.name
							? values.logoDark
							: null,
					avatar:
						values.avatar?.name !== profileInitialValues?.avatar.name
							? values.avatar
							: null
				},
				{
					onSuccess: async (result, data) => {
						if (result.responseStatusCode === 200 && result.data) {
							const profile = await profileMapper.toDomain(result.data)
							updateProfileState(profile)
							setShowSuccessButton(true)
						}

						if (result.responseStatusCode === 400) {
							if (result.error?.fields) {
								Object.entries(result.error.fields).forEach(([key, value]) => {
									formik.setFieldError(key, value[0].message)
								})

								const firstFieldWithError = Object.keys(
									result.error.fields
								).find(key => key)

								if (firstFieldWithError) {
									focusField(firstFieldWithError)
								}
							}
						}

						if (
							result.responseStatusCode === 403 ||
							result.responseStatusCode === 500
						) {
							setAlert({
								type: 'error',
								message: t('error-server-default'),
								show: true
							})
							alertRef.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'center'
							})
						}
					},
					onError: error => {
						setAlert({
							type: 'error',
							message: t('error-server-default'),
							show: true
						})
						alertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
						console.error(error)
					}
				}
			)
		}
	})

	const focusField = (field: string) => {
		switch (field) {
			case 'name':
				nameRef.current?.focus()
				break
			case 'nameSlug':
				nameSlugRef.current?.focus()
				break
			case 'logoLight':
				logoLightRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'end'
				})
				break
			case 'logoDark':
				logoDarkRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				})
				break
			case 'avatar':
				avatarRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				})
				break
			default:
				break
		}
	}

	const handleFocusOnFirstFieldWithError = () => {
		const firstFieldWithError = Object.keys(formik.errors).find(
			(key: string) => key
		)

		if (firstFieldWithError) {
			focusField(firstFieldWithError)
		}
	}

	const handleOnChangeProfile = () => {
		setShowSuccessButton(false)
		setAlert({show: false})
	}

	const loadProfileInitialValues = useCallback(async () => {
		if (profile) {
			const {avatar, logoDark, logoLight, name, nameSlug, primaryColor} =
				profile

			const avatarFile = await image.createFileFromURL(avatar.url, 'avatar')
			const logoDarkFile = await image.createFileFromURL(
				logoDark.url,
				'logoDark'
			)
			const logoLightFile = await image.createFileFromURL(
				logoLight.url,
				'logoLight'
			)

			setProfileInitialValues({
				avatar: avatarFile,
				logoDark: logoDarkFile,
				logoLight: logoLightFile,
				primaryColor: primaryColor || '#F1BB0C',
				name,
				nameSlug
			})
		}
	}, [profile])

	useEffect(() => {
		loadProfileInitialValues()
	}, [loadProfileInitialValues, profile])

	return (
		<Box
			onSubmit={formik.handleSubmit}
			onChange={handleOnChangeProfile}
			component="form"
			autoComplete="off"
			sx={style.formBox}
		>
			<SectionTitle>{t('edit-profile')}</SectionTitle>
			{!profileInitialValues ? (
				<Stack direction="column" spacing={4} sx={{mt: 4}}>
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton
						variant="rounded"
						height={12}
						sx={{
							ml: '14px !important',
							mr: '14px !important',
							mt: '10px !important'
						}}
					/>
					<Box>
						<Typography variant="subtitle1" sx={{mb: 1}}>
							<Skeleton width={150} />
						</Typography>

						<Skeleton
							variant="rounded"
							height={50}
							width={120}
							sx={{mt: '20px !important'}}
						/>

						<Skeleton
							variant="rounded"
							height={12}
							sx={{mt: '10px !important'}}
						/>
						<Skeleton
							variant="rounded"
							height={12}
							width="90%"
							sx={{mt: '10px !important'}}
						/>
					</Box>
					<Box>
						<Typography variant="subtitle1" sx={{mb: 1}}>
							<Skeleton width={150} />
						</Typography>
						<Skeleton
							variant="rounded"
							height={50}
							width={120}
							sx={{mt: '20px !important'}}
						/>
						<Skeleton
							variant="rounded"
							height={12}
							sx={{mt: '10px !important'}}
						/>
						<Skeleton
							variant="rounded"
							height={12}
							width="90%"
							sx={{mt: '10px !important'}}
						/>
					</Box>
					<Box>
						<Typography variant="subtitle1" sx={{mb: 1}}>
							<Skeleton width={220} />
						</Typography>
						<Skeleton
							variant="circular"
							height={100}
							width={100}
							sx={{mt: '20px !important'}}
						/>
						<Skeleton
							variant="rounded"
							height={12}
							width="95%"
							sx={{mt: '10px !important'}}
						/>
						<Skeleton
							variant="rounded"
							height={12}
							sx={{mt: '10px !important'}}
						/>
						<Skeleton
							variant="rounded"
							height={12}
							width={50}
							sx={{mt: '10px !important'}}
						/>
					</Box>

					<Skeleton variant="rectangular" height={40} />
				</Stack>
			) : (
				<Stack direction="column" spacing={4}>
					<Collapse ref={alertRef} in={alert.show}>
						<Alert
							severity={alert.type}
							action={
								<IconButton
									aria-label={t('aria-close-alert')}
									color="inherit"
									size="small"
									onClick={() => {
										setAlert({show: false})
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{mt: 3}}
						>
							{alert.message}
						</Alert>
					</Collapse>
					<TextField
						name="name"
						type="text"
						inputRef={nameRef}
						autoFocus
						label={t('name')}
						variant="outlined"
						helperText={formik.touched.name && formik.errors.name}
						error={formik.touched.name && Boolean(formik.errors.name)}
						margin="normal"
						value={formik.values.name}
						onChange={event => {
							const currentNameSlug = regex.formatStringToSlug(
								event.target.value
							)
							formik.setFieldValue('nameSlug', currentNameSlug)
							formik.handleChange(event)
						}}
						onBlur={formik.handleBlur}
						size="small"
						sx={{mb: 0}}
					/>
					<TextField
						name="nameSlug"
						type="text"
						inputRef={nameSlugRef}
						label={t('name-slug')}
						variant="outlined"
						helperText={
							formik.touched.nameSlug && Boolean(formik.errors.nameSlug)
								? formik.errors.nameSlug
								: t('helper-input-name-slug')
						}
						error={formik.touched.nameSlug && Boolean(formik.errors.nameSlug)}
						margin="normal"
						value={formik.values.nameSlug}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						size="small"
					/>
					<FormControl
						ref={logoLightRef}
						sx={style.formControlImage}
						margin="normal"
					>
						<Typography variant="subtitle1" sx={{mb: 2}}>
							{t('label-image-upload-light-logo')}
						</Typography>
						<ImageFileUpload
							name="logoLight"
							label={t('label-image-upload-light-logo')}
							url={profile?.logoLight.url}
							dimensions={profile?.logoLight.dimensions}
							onChangeImage={handleChangeImage}
							acceptImages={{'image/png': []}}
							mode="light"
						/>
						{formik.errors.logoLight ? (
							<FormHelperText error sx={style.helperText}>
								{formik.errors.logoLight}
							</FormHelperText>
						) : (
							<FormHelperText sx={style.helperText}>
								{t('helper-image-upload-logo')}
							</FormHelperText>
						)}
					</FormControl>
					<FormControl ref={logoDarkRef} sx={style.formControlImage}>
						<Typography variant="subtitle1" sx={{mb: 2}}>
							{t('label-image-upload-dark-logo')}
						</Typography>
						<ImageFileUpload
							name="logoDark"
							label={t('label-image-upload-dark-logo')}
							url={profile?.logoDark.url}
							dimensions={profile?.logoDark.dimensions}
							onChangeImage={handleChangeImage}
							acceptImages={{'image/png': []}}
							mode="dark"
						/>
						{formik.errors.logoDark ? (
							<FormHelperText error sx={style.helperText}>
								{formik.errors.logoDark}
							</FormHelperText>
						) : (
							<FormHelperText sx={style.helperText}>
								{t('helper-image-upload-logo')}
							</FormHelperText>
						)}
					</FormControl>
					<FormControl ref={logoLightRef} sx={style.formControlImage}>
						<Typography variant="subtitle1" sx={{mb: 2}}>
							{t('label-image-upload-avatar')}
						</Typography>
						<ImageFileUpload
							name="avatar"
							label={t('label-image-upload-avatar')}
							url={profile?.avatar.url}
							dimensions={profile?.avatar.dimensions}
							onChangeImage={handleChangeImage}
							acceptImages={{
								'image/png': [],
								'image/jpeg': [],
								'image/jpg': []
							}}
							circle
						/>
						{formik.errors.avatar ? (
							<FormHelperText error sx={style.helperText}>
								{formik.errors.avatar}
							</FormHelperText>
						) : (
							<FormHelperText sx={style.helperText}>
								{t('helper-image-upload-avatar')}
							</FormHelperText>
						)}
					</FormControl>
					<FormControl>
						<FormGroup
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 1
							}}
						>
							<TextField
								name="primaryColor"
								type="text"
								label={t('primary-color')}
								size="small"
								value={formik.values.primaryColor}
								onChange={formik.handleChange}
								InputProps={{
									endAdornment: (
										<input
											className="color-picker-input"
											type="color"
											value={formik.values.primaryColor}
											onChange={e => {
												formik.setFieldValue('primaryColor', e.target.value)
											}}
										/>
									)
								}}
								sx={theme => ({
									'& .MuiOutlinedInput-root': {
										paddingRight: '4px !important'
									},
									[theme.breakpoints.down('sm')]: {
										width: '100%'
									}
								})}
							/>
						</FormGroup>
						{formik.errors.primaryColor ? (
							<FormHelperText error sx={style.helperText}>
								{formik.errors.primaryColor}
							</FormHelperText>
						) : (
							<FormHelperText sx={style.helperText}>
								<Tag>{t('optional')}</Tag>
							</FormHelperText>
						)}
					</FormControl>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={style.successButton}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							size="large"
							variant="contained"
							type="submit"
							disabled={isLoading}
							onClick={handleFocusOnFirstFieldWithError}
							sx={style.formSubmitButton}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={25} />
							) : (
								t('to-save-changes')
							)}
						</Button>
					)}
				</Stack>
			)}
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => ({
		my: 5,
		mt: 0,
		pt: 0,
		width: '100%',
		maxWidth: '520px'
	}),
	formControlImage: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formSubmitButton: (theme: Theme) => ({
		height: '44px',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	helperText: {
		margin: 0,
		marginTop: 1
	},
	successButton: (theme: Theme) => ({
		height: '44px',
		backgroundColor: 'success.light',
		color: 'success.contrastText',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		'&:hover': {backgroundColor: 'success.light'}
	})
}

export default FormUpdateProfile
