import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import {FaChevronDown as ExpandMoreIcon} from 'react-icons/fa'
import InfoPopover from './InfoPopover'
import {PagarmeLogo} from './logos/PagarmeLogo'
import LogoType from './template/LogoType'

interface FeesDetailsProps {
	pagarme?: {
		head: React.ReactNode
		body: React.ReactNode
	}[]
	excofy?: {
		head: React.ReactNode
		body: React.ReactNode
	}[]
	totalFeesAmount: string
}

const FeesDetails = ({excofy, pagarme, totalFeesAmount}: FeesDetailsProps) => {
	const {t} = useTranslation()

	return (
		<TableRow>
			<TableCell colSpan={4}>
				<Typography variant="h6" mb={2}>
					{t('fees-details')}
				</Typography>
				<Card variant="outlined">
					<Table
						sx={{
							width: '100%'
						}}
					>
						{pagarme ? (
							<>
								<TableRow>
									<TableCell
										rowSpan={pagarme.length + 1}
										sx={theme => ({
											borderWidth: '0 1px 1px 0',
											borderStyle: 'solid',
											borderColor: theme.palette.divider,
											width: 120
										})}
									>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between'
											}}
										>
											<PagarmeLogo />
										</Box>
									</TableCell>
								</TableRow>

								{pagarme.map(({head, body}, index) => (
									<TableRow key={'pagarme-fees-' + index}>
										<TableCell
											variant="head"
											sx={theme => ({
												borderWidth: '0 1px 1px 0',
												borderStyle: 'solid',
												borderColor: theme.palette.divider
											})}
										>
											{head}
										</TableCell>
										<TableCell
											sx={{
												borderWidth: '0 1px 1px 0',
												minWidth: 150
											}}
										>
											{body}
										</TableCell>
									</TableRow>
								))}
							</>
						) : null}

						{excofy ? (
							<>
								<TableRow>
									<TableCell
										rowSpan={excofy.length + 1}
										sx={theme => ({
											borderWidth: '0 1px 1px 0',
											borderStyle: 'solid',
											borderColor: theme.palette.divider,
											width: 120
										})}
									>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between'
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center'
												}}
											>
												<LogoType />
											</Box>
										</Box>
									</TableCell>
								</TableRow>

								{excofy.map(({head, body}, index) => (
									<TableRow key={'excofy-fees-' + index}>
										<TableCell
											variant="head"
											sx={theme => ({
												borderWidth: '0 1px 1px 0',
												borderStyle: 'solid',
												borderColor: theme.palette.divider
											})}
										>
											{head}
										</TableCell>
										<TableCell sx={{minWidth: 150}}>{body}</TableCell>
									</TableRow>
								))}
							</>
						) : null}

						<TableRow>
							<TableCell
								variant="head"
								sx={theme => ({
									borderWidth: '0 0 0 0',
									borderStyle: 'solid',
									borderColor: theme.palette.divider
								})}
								colSpan={2}
							>
								{t('total')}
							</TableCell>
							<TableCell
								sx={{
									borderBottom: 'none'
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<Typography variant="body2">{totalFeesAmount}</Typography>
								</Box>
							</TableCell>
						</TableRow>
					</Table>
				</Card>
			</TableCell>
		</TableRow>
	)
}

interface TableFeesProps {
	feesForBuyer: {
		grossAmount: string
		netAmount: string
		pagarme: FeesDetailsProps['pagarme']
		excofy: FeesDetailsProps['excofy']
		totalFeesAmount: string
	}
	feesForSeller: {
		grossAmount: string
		netAmount: string
		pagarme: FeesDetailsProps['pagarme']
		excofy: FeesDetailsProps['excofy']
		totalFeesAmount: string
	}
}

const TableFees = ({feesForBuyer, feesForSeller}: TableFeesProps) => {
	const {t} = useTranslation()
	const [openedFeesDetailsForBuyer, setOpenedFeesDetailsForBuyer] =
		useState<boolean>(false)
	const [openedFeesDetailsForSeller, setOpenedFeesDetailsForSeller] =
		useState<boolean>(false)

	return (
		<TableContainer>
			<Table sx={{borderBottom: 'none'}} aria-label="simple table">
				<TableHead
					sx={theme => ({
						backgroundColor:
							theme.palette.mode === 'light' ? 'grey.100' : 'grey.50'
					})}
				>
					<TableRow>
						<TableCell sx={{minWidth: 200}}>{t('description')}</TableCell>
						<TableCell sx={{minWidth: 200}}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
									textAlign: 'right',
									backgroundColor: theme =>
										theme.palette.grey[100] + '!important'
								}}
							>
								{t('amount-to-charge')}
								<InfoPopover
									sx={{
										backgroundColor: theme =>
											theme.palette.mode === 'light'
												? theme.palette.grey[100] + '!important'
												: theme.palette.grey[50] + '!important'
									}}
								>
									<Typography variant="body2">
										{t('amount-to-charge-info')}
									</Typography>
								</InfoPopover>
							</Box>
						</TableCell>
						<TableCell sx={{minWidth: 200}}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
									textAlign: 'right',
									minWidth: 180,
									backgroundColor: theme =>
										theme.palette.grey[100] + '!important'
								}}
							>
								{t('amount-to-receive')}
								<InfoPopover
									sx={{
										backgroundColor: theme =>
											theme.palette.mode === 'light'
												? theme.palette.grey[100] + '!important'
												: theme.palette.grey[50] + '!important'
									}}
								>
									<Typography variant="body2">
										{t('amount-to-receive-info')}
									</Typography>
								</InfoPopover>
							</Box>
						</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component="th" scope="row">
							<Typography variant="body2">{t('fees-for-seller')}</Typography>
						</TableCell>
						<TableCell align="right">
							<Typography marginRight={4} variant="body2">
								{feesForSeller.grossAmount}
							</Typography>
						</TableCell>
						<TableCell align="right">
							<Typography marginRight={4} variant="body2">
								{feesForSeller.netAmount}
							</Typography>
						</TableCell>
						<TableCell align="right">
							<Tooltip title={t('view-details')} placement="bottom" arrow>
								<IconButton
									className={openedFeesDetailsForSeller ? 'rotated' : ''}
									onClick={() =>
										setOpenedFeesDetailsForSeller(!openedFeesDetailsForSeller)
									}
									size="small"
								>
									<ExpandMoreIcon size={16} />
								</IconButton>
							</Tooltip>
						</TableCell>
					</TableRow>

					{openedFeesDetailsForSeller ? (
						<FeesDetails
							totalFeesAmount={feesForSeller.totalFeesAmount}
							pagarme={feesForSeller.pagarme}
							excofy={feesForSeller.excofy}
						/>
					) : null}

					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component="th" scope="row">
							{t('fees-for-buyer')}
						</TableCell>
						<TableCell align="right">
							<Typography marginRight={4} variant="body2">
								{feesForBuyer.grossAmount}
							</Typography>
						</TableCell>
						<TableCell align="right">
							<Typography marginRight={4} variant="body2">
								{feesForBuyer.netAmount}
							</Typography>
						</TableCell>
						<TableCell align="right">
							<Tooltip title={t('view-details')} placement="bottom" arrow>
								<IconButton
									className={openedFeesDetailsForBuyer ? 'rotated' : ''}
									onClick={() =>
										setOpenedFeesDetailsForBuyer(!openedFeesDetailsForBuyer)
									}
								>
									<ExpandMoreIcon size={16} />
								</IconButton>
							</Tooltip>
						</TableCell>
					</TableRow>

					{openedFeesDetailsForBuyer ? (
						<FeesDetails
							totalFeesAmount={feesForBuyer.totalFeesAmount}
							pagarme={feesForBuyer.pagarme}
							excofy={feesForBuyer.excofy}
						/>
					) : null}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default TableFees
