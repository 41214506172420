import Button from '@mui/material/Button'
import {SxProps, Theme} from '@mui/material/styles'
import {Link} from 'react-router-dom'
import useAppStates from '../../stores/useAppStates'

interface LinkProps {
	children?: React.ReactNode
	to: string
	size?: 'small' | 'large' | 'medium'
	variant?: 'text' | 'outlined' | 'contained'
	state?: object
	target?: string
	sx?: SxProps<Theme>
}

const ButtonLink = ({children, to, state, target, ...rest}: LinkProps) => {
	const currentLang = useAppStates(state => state.currentLang)
	return (
		<Button
			component={Link}
			size="large"
			to={currentLang ? `/${currentLang + to}` : to}
			target={target}
			state={state}
			{...rest}
		>
			{children}
		</Button>
	)
}

export default ButtonLink
