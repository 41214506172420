import {Box, Button, Card as MuiCard, Typography} from '@mui/material'
import {SxProps, Theme, useTheme} from '@mui/material/styles'

interface CardRootProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
	variant?: 'outlined' | 'elevation'
	elevation?: number
	sx?: SxProps<Theme>
}

const CardRoot = ({children, sx, variant, elevation}: CardRootProps) => {
	return (
		<MuiCard sx={sx} variant={variant || 'outlined'} elevation={elevation}>
			{children}
		</MuiCard>
	)
}

interface CardBodyProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
	cursor?: string
	columns?: number
	flex?: boolean
	grid?: boolean
	sx?: SxProps<Theme>
}

const CardBody = ({
	children,
	columns,
	cursor,
	flex,
	grid,
	sx,
	...rest
}: CardBodyProps) => {
	const theme = useTheme()

	let styleBody = sx
	if (flex) styleBody = style.cardBodyFlex({theme, columns, cursor})
	else if (grid) styleBody = style.cardBodyGrid({theme, columns, cursor})

	return (
		<Box sx={styleBody} {...rest}>
			{children}
		</Box>
	)
}

interface CardInfoProps {
	children: React.ReactNode
	sx?: SxProps<Theme>
}

const CardInfo = ({sx, children}: CardInfoProps) => {
	return <Box sx={sx || style.cardInfo}>{children}</Box>
}
interface CardTitleProps {
	children: React.ReactNode
	variant?:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'subtitle1'
		| 'subtitle2'
		| undefined
}

const CardTitle = ({children, variant}: CardTitleProps) => {
	return (
		<Typography variant={variant || 'h6'} fontWeight={500}>
			{children}
		</Typography>
	)
}

interface CardDescribeProps {
	children: React.ReactNode
	sx?: SxProps<Theme>
}

const CardDescribe = ({children, sx}: CardDescribeProps) => {
	return <Box sx={sx}>{children}</Box>
}

interface CardButtonProps {
	children: React.ReactNode
	withBorder?: boolean
	onClick: () => void
}

const CardButton = ({children, withBorder, onClick}: CardButtonProps) => {
	return (
		<Box
			sx={[Boolean(withBorder) && style.cardButtonWithBorder, style.cardButton]}
		>
			<Button variant="contained" onClick={onClick}>
				{children}
			</Button>
		</Box>
	)
}

interface CardFooterProps {
	children: React.ReactNode
}

const CardFooter = ({children}: CardFooterProps) => {
	return <Box sx={style.cardFooter}>{children}</Box>
}

const style = {
	cardBodyGrid: ({
		theme,
		columns,
		cursor
	}: {
		theme: Theme
		columns?: number
		cursor?: string
	}) => ({
		display: 'grid',
		alignItems: 'stretch',
		flexWrap: 'wrap',
		cursor: cursor ? cursor : 'default',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr'
		},
		[theme.breakpoints.up('md')]: {
			gridTemplateColumns: columns === 1 ? '1fr' : '60% 40%'
		}
	}),
	cardBodyFlex: ({
		theme,
		columns,
		cursor
	}: {
		theme: Theme
		columns?: number
		cursor?: string
	}) => ({
		display: 'flex',
		alignItems: 'stretch',
		cursor: cursor ? cursor : 'default',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		}
	}),
	cardInfo: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		p: 2
	},
	cardButton: (theme: Theme) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		p: 4
	}),
	cardButtonWithBorder: (theme: Theme) => ({
		backgroundColor: theme.palette.grey[50],
		[theme.breakpoints.up('md')]: {
			borderLeft: `1px solid ${theme.palette.divider}`
		},
		[theme.breakpoints.down('md')]: {
			borderTop: `1px solid ${theme.palette.divider}`
		}
	}),
	cardFooter: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column',
		borderTop: `1px solid ${theme.palette.divider}`,
		width: '100%',
		px: 2,
		py: 1
	})
}

export const Card = {
	Root: CardRoot,
	Body: CardBody,
	Info: CardInfo,
	Title: CardTitle,
	Describe: CardDescribe,
	Button: CardButton,
	Footer: CardFooter
}
