import {
	Box,
	Card,
	Table,
	TableBody,
	TableContainer,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Stack,
	Button,
	CircularProgress
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import useAppStates from '../../../stores/useAppStates'
import useAsaasStates from '../../../stores/useAsaasStates'
import {date} from '../../../helpers/date'
import {price} from '../../../helpers/price'
import {banksCode} from '../../../components/BankSelect'
import {Status} from '../../../components/Status'
import {useCallback, useEffect, useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {fetchTransferAsaas} from '../../../api/payment-service/asaas'
import {TransferActionCard} from './TransferActionCard'
import ButtonIcon from '../../../components/ButtonIcon'
import {MdModeEdit as EditIcon} from 'react-icons/md'
import {TabValue} from './AsaasSettings'
import useAuthStates from '../../../stores/useAuthStates'
import {regex} from '../../../helpers/regex'

export const TransferList = ({
	onChange
}: {
	onChange: (value: TabValue) => void
}) => {
	const {currentLang} = useAppStates()
	const {
		asaasTransfers,
		asaasBankAccount,
		setAsaasTransfers,
		addAsaasTransfers
	} = useAsaasStates()
	const limit = 20
	const {t} = useTranslation()
	const {company} = useAuthStates()

	const [offset, setOffset] = useState<number>(0)
	const [hasMore, setHasMore] = useState<boolean>(false)
	const [firstLoad, setFirstLoad] = useState(true)

	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')

	const {mutate, isLoading} = useMutation(fetchTransferAsaas, {
		networkMode: 'always'
	})

	const handleLoadMore = useCallback(() => {
		setOffset(offset + limit)
	}, [offset, limit])

	const fetchGetTransfers = useCallback(async () => {
		setOpenAlert(false)
		mutate(
			{offset, limit},
			{
				onSuccess: ({data, responseStatusCode, error}) => {
					if (responseStatusCode === 200 && data) {
						if (offset === 0) {
							setAsaasTransfers(data.transfers)
						} else {
							addAsaasTransfers(data.transfers)
						}
						setHasMore(data.hasMore)
					} else if (responseStatusCode !== 200) {
						setOpenAlert(true)
						setAlertErrorMessage(error?.message || t('error-server-default'))
					}
				},
				onError: error => {
					setOpenAlert(true)
					setAlertErrorMessage(t('error-server-default'))
					console.error(error)
				}
			}
		)
	}, [mutate, t, offset, limit, setAsaasTransfers, addAsaasTransfers])

	useEffect(() => {
		if (firstLoad) {
			fetchGetTransfers()
			setFirstLoad(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstLoad])

	return (
		<Stack spacing={4}>
			<Card
				variant="outlined"
				sx={theme => ({
					display: 'flex',
					flexDirection: 'row',
					p: 2,
					gap: 2,
					[theme.breakpoints.down('md')]: {
						flexDirection: 'column'
					}
				})}
			>
				<TransferActionCard borderWidth={0} padding={0} />
				<Box
					sx={theme => ({
						borderLeftWidth: 1,
						borderLeftColor: 'divider',
						borderLeftStyle: 'solid',
						paddingLeft: 2,
						flex: 1,
						[theme.breakpoints.down('md')]: {
							borderLeftWidth: 0,
							borderTopWidth: 1,
							borderTopColor: 'divider',
							borderTopStyle: 'solid',
							paddingTop: 2,
							paddingLeft: 0
						}
					})}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 0.5,
							flexWrap: 'wrap',
							mb: 1
						}}
					>
						<Typography variant="h6" fontWeight={500}>
							{t('bank-account')}
						</Typography>
						<ButtonIcon
							size="medium"
							title={t('to-edit')}
							icon={<EditIcon />}
							onClick={() => onChange('bank-account')}
						/>
					</Box>
					{asaasBankAccount ? (
						<Box>
							{company?.cnpj && (
								<Typography>
									{t('cnpj')}: {regex.formatCNPJ(company.cnpj)}
								</Typography>
							)}
							<Typography>
								{t('bank-code')}:{' '}
								{
									banksCode.find(
										bankCode => asaasBankAccount?.bankCode === bankCode.code
									)?.label
								}
							</Typography>
							<Typography>
								{t('account')}: {asaasBankAccount?.branchNumber}
								{asaasBankAccount?.branchCheckDigit
									? `-${asaasBankAccount?.branchCheckDigit}`
									: ''}{' '}
								/ {asaasBankAccount?.accountNumber}-
								{asaasBankAccount?.accountCheckDigit}
							</Typography>
						</Box>
					) : (
						<Typography>{t('no-bank-account')}</Typography>
					)}
				</Box>
			</Card>
			<Box>
				<Typography variant="h5" component="h2">
					{t('transfer-history')}
				</Typography>
				<Box display="grid" sx={{mt: 2}}>
					<TableContainer component={Paper} variant="outlined">
						<Table sx={{minWidth: '600px'}}>
							<TableHead>
								<TableRow>
									<TableCell sx={{pl: 3}}>{t('date')}</TableCell>
									<TableCell>{t('requested-amount')}</TableCell>
									<TableCell>{t('beneficiary')}</TableCell>
									<TableCell sx={{pr: 3}}>{t('status')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody
								sx={{
									'& tr:last-child td': {
										borderBottomWidth: 0
									}
								}}
							>
								{asaasTransfers.length === 0 && (
									<TableRow>
										<TableCell colSpan={4} sx={{borderBottomWidth: 0}}>
											<Typography variant="body2" align="center">
												{t('no-transfers')}
											</Typography>
										</TableCell>
									</TableRow>
								)}
								{asaasTransfers.map(transfer => (
									<TableRow key={transfer.id}>
										<TableCell sx={{pl: 3}}>
											<Typography variant="body2">
												{date.convertISOToLocation(
													transfer.createdAt,
													currentLang
												)}
											</Typography>
											<Typography variant="caption" color="grey.700">
												{date.getTime(transfer.createdAt, currentLang)}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body2">
												{price.full(transfer.amount)}
											</Typography>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center'
												}}
											>
												<Typography variant="caption" color="grey.700">
													{t('fee')} {transfer.operationType}:{' '}
													{price.full(transfer.feeAmount)}
												</Typography>
											</Box>
										</TableCell>
										<TableCell>
											<Typography variant="body2">
												{
													banksCode.find(
														bankCode =>
															transfer?.bankAccount?.bankCode === bankCode.code
													)?.label
												}
											</Typography>
											<Typography variant="caption" color="grey.700">
												{transfer?.bankAccount?.branchNumber} /{' '}
												{transfer?.bankAccount?.accountNumber}-
												{transfer?.bankAccount?.accountCheckDigit}
											</Typography>
										</TableCell>
										<TableCell>
											<Status.Tag type={transfer.status}>
												<Status.Label
													label={t('status-tag-' + transfer.status)}
													maxWidth="auto"
													showTooltip={false}
												/>
											</Status.Tag>
										</TableCell>
									</TableRow>
								))}
								{hasMore && (
									<TableRow>
										<TableCell
											colSpan={4}
											sx={{
												padding: '0px !important'
											}}
										>
											<Button
												onClick={handleLoadMore}
												sx={{width: '100%'}}
												disabled={isLoading}
											>
												{isLoading ? (
													<CircularProgress color="inherit" size={24} />
												) : (
													t('load-more')
												)}
											</Button>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</Stack>
	)
}
