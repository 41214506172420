import {create} from 'zustand'
import {DEFAULT_LANG} from '../config'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {persist} from 'zustand/middleware'

dayjs.extend(utc)
dayjs.extend(timezone)

interface AppStates {
	firstLoad: boolean
	currentLang: string
	timezoneOffset: string
	setFirstLoad: (firstLoad: boolean) => void
	setLang: (lang: string) => void
	setTimeZoneOffset: (timeZone: string) => void
}

const useAppStates = create<AppStates>()(
	persist(
		set => ({
			timezoneOffset: dayjs().tz(dayjs.tz.guess()).format('Z'),
			firstLoad: true,
			currentLang: DEFAULT_LANG,
			setFirstLoad: firstLoad => set({firstLoad}),
			setLang: lang => set({currentLang: lang}),
			setTimeZoneOffset: timezoneOffset => set({timezoneOffset})
		}),
		{
			name: 'app-storage',
			getStorage: () => localStorage,
			partialize: state => ({
				timezoneOffset: state.timezoneOffset,
				currentLang: state.currentLang
			})
		}
	)
)

export default useAppStates
