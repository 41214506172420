import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FullSection from '../layout/utils/FullSection'
import useAuthStates from '../../stores/useAuthStates'
import useColorMode from '../../hooks/useColorMode'
import {useEffect, useState} from 'react'
import {image} from '../../helpers/image'
import Skeleton from '@mui/material/Skeleton'

const SalePageFooter = () => {
	const [logo, setLogo] = useState<string | undefined>(undefined)
	const salePage = useAuthStates(state => state.salePage)
	const colorMode = useColorMode()

	useEffect(() => {
		if (salePage?.owner) {
			const loadLogo = async () => {
				if (colorMode === 'dark') {
					const logoDark = await image.loadImage(salePage.owner.logoDark)
					setLogo(logoDark.src)
				}

				if (colorMode === 'light') {
					const logoLight = await image.loadImage(salePage.owner.logoLight)
					setLogo(logoLight.src)
				}
			}
			loadLogo()
		}
	}, [colorMode, salePage])

	return (
		<FullSection grey={50} height="auto">
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center',
					gap: '2rem',
					textAlign: 'center'
				}}
			>
				<Box>
					{logo ? (
						<img src={logo} alt="Logo of the company" style={style.logo} />
					) : (
						<Skeleton variant="rounded" height="2.8rem" width={110} />
					)}
					<Box mt={1}>
						<Typography
							variant="body2"
							sx={theme => ({
								color: theme.palette.grey[500]
							})}
						>
							{salePage?.owner.name}
						</Typography>
						<Typography
							variant="caption"
							sx={theme => ({
								color: theme.palette.grey[500]
							})}
						>
							Copyright © 2023.
						</Typography>
					</Box>
				</Box>
			</Box>
		</FullSection>
	)
}

const style = {
	logo: {
		maxHeight: '2.8rem',
		maxWidth: '190px',
		filter: 'grayscale(100%)'
	}
}

export default SalePageFooter
