import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import {Theme} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import LogoType from './template/LogoType'

const FullPageLoading = ({label}: {label?: string}) => {
	return (
		<Box sx={style.fullScreen}>
			<LogoType logoSize="4.5rem" typographyVariant="h4" />
			<CircularProgress size={40} sx={{color: '#F1BB0C'}} />
			{label !== undefined ? (
				<Typography variant="body1">{label}</Typography>
			) : null}
		</Box>
	)
}

const style = {
	fullScreen: (theme: Theme) => {
		return {
			background: theme.palette.background.paper,
			color: theme.palette.common.black,
			position: 'fixed',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			gap: '30px'
		}
	}
}

export default FullPageLoading
