import {DEFAULT_LANG, SELLER_AUTH_API} from '../config'
import useAppStates from '../stores/useAppStates'
interface Message {
	message: string
}
interface LogoutResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			refreshToken?: Message[]
			sellerId?: Message[]
		}
	}
	responseStatusCode?: number
}

export const logout = async (): Promise<LogoutResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const response = await fetch(`${SELLER_AUTH_API}/logout`, {
		method: 'POST',
		body: '{}',
		credentials: 'include',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG
		}
	})
	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}
