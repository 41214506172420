import {useTranslation} from 'react-i18next'
import SectionTitle from '../../../components/typography/SectionTitle'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {useEffect, useState} from 'react'
import {FormBankAccount} from './FormBankAccount'
import {TransferList} from './TransferList'
import {useLocation} from 'react-router-dom'
import {AsaasFees} from './AsaasFees'

export type TabValue = 'transfers' | 'bank-account' | 'fees'

export const AsaasSettings = () => {
	const {t} = useTranslation()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const tab = queryParams.get('tab') as TabValue

	const [value, setValue] = useState<TabValue>(tab || 'transfers')

	const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
		setValue(newValue)
		window.history.pushState({}, '', `?tab=${newValue}`)
	}

	useEffect(() => {
		if (tab) {
			setValue(tab)
		}
	}, [tab])

	const pages = {
		'transfers': {
			component: (
				<TransferList
					onChange={(value: TabValue) => {
						setValue(value)
						window.history.pushState({}, '', `?tab=${value}`)
					}}
				/>
			)
		},
		'bank-account': {
			component: <FormBankAccount />
		},
		'fees': {
			component: <AsaasFees />
		}
	}

	return (
		<Box>
			<SectionTitle>{t('ps-settings-asaas')}</SectionTitle>
			<Box>
				<Box sx={{marginTop: 2, borderBottom: 1, borderColor: 'divider'}}>
					<Tabs
						value={value}
						onChange={handleChange}
						textColor="primary"
						indicatorColor="primary"
					>
						<Tab value="transfers" label={t('transfers')} />
						<Tab value="bank-account" label={t('bank-account')} />
						<Tab value="fees" label={t('fees-and-terms')} />
					</Tabs>
				</Box>
			</Box>
			<Box
				sx={{
					mt: 4
				}}
			>
				{pages[value].component}
			</Box>
		</Box>
	)
}
