import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

const useBankAccountSchema = () => {
	const {t} = useTranslation()

	const bankAccountSchema = yup.object({
		bankCode: yup
			.string()
			.matches(/^[0-9]*$/, t('error-bank-code-invalid'))
			.length(3, t('error-bank-code-length'))
			.required(t('error-bank-code-required')),
		branchNumber: yup
			.string()
			.matches(/^[0-9]*$/, t('error-branch-number-invalid'))
			.length(4, t('error-branch-number-length'))
			.required(t('error-branch-number-required')),
		branchCheckDigit: yup
			.string()
			.matches(/^[a-zA-Z0-9]*$/, t('error-branch-check-digit-invalid'))
			.length(1, t('error-branch-check-digit-length')),
		accountNumber: yup
			.string()
			.matches(/^[0-9]*$/, t('error-account-number-invalid'))
			.max(13, t('error-account-number-max-length'))
			.required(t('error-account-number-required')),
		accountCheckDigit: yup
			.string()
			.matches(/^[a-zA-Z0-9]*$/, t('error-account-check-digit-invalid'))
			.max(2, t('error-account-check-digit-max-length'))
			.required(t('error-account-check-digit-required')),
		accountType: yup
			.string()
			.matches(/^(checking|savings)$/, t('error-account-type-invalid'))
			.required(t('error-account-type-required'))
	})

	return bankAccountSchema
}

export default useBankAccountSchema
