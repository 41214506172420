import {useTranslation} from 'react-i18next'
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography
} from '@mui/material'

import * as yup from 'yup'
import {useFormik} from 'formik'
import {useCallback, useMemo, useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {price} from '../../../helpers/price'
import {regex} from '../../../helpers/regex'
import useAsaasStates from '../../../stores/useAsaasStates'
import {Dialog} from '../../../components/Dialog'
import AlertMessage from '../../../components/AlertMessage'
import {createTransferAsaas} from '../../../api/payment-service/asaas'
import {banksCode} from '../../../components/BankSelect'
import useAuthStates from '../../../stores/useAuthStates'

interface FormTransferProps {
	open: boolean
	onClose: () => void
	onSuccess: () => void
	setTransferType: (transferType: 'PIX' | 'TED') => void
}

export const FormTransfer = ({
	open,
	onClose,
	onSuccess,
	setTransferType
}: FormTransferProps) => {
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)
	const {t} = useTranslation()
	const {asaasBalance, setAsaasBalance, asaasBankAccount, addAsaasTransfer} =
		useAsaasStates()
	const {company} = useAuthStates()

	const {isLoading, mutate} = useMutation(createTransferAsaas, {
		networkMode: 'always'
	})

	const validationSchema = useMemo(
		() =>
			yup.object({
				amount: yup
					.string()
					.required(t('error-transfer-amount-required'))
					.test(
						'amount-min',
						t('error-transfer-amount-min', {fee: price.full(10)}),
						value => {
							if (value) {
								const parsedValue = regex.parseBRLCurrencyToFloat(value)

								return parsedValue >= 10
							}
							return true
						}
					)
					.test(
						'amount-max',
						t('error-transfer-amount-max', {
							fee: price.full(asaasBalance.availableBalance)
						}),
						value => {
							if (value && asaasBalance) {
								const parsedValue = regex.parseBRLCurrencyToFloat(value)

								return asaasBalance.availableBalance >= parsedValue
							}
							return true
						}
					)
			}),
		[asaasBalance, t]
	)

	const formik = useFormik({
		initialValues: {
			operationType: 'PIX',
			amount: price.decimal(asaasBalance.availableBalance)
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			setOpenAlertError(false)
			mutate(
				{
					amount: regex.parseBRLCurrencyToFloat(values.amount),
					operationType: values.operationType
				},
				{
					onSuccess: ({error, responseStatusCode, data}) => {
						if (responseStatusCode === 200 && data) {
							addAsaasTransfer({
								id: data.id,
								amount: data.amount,
								createdAt: new Date().toISOString(),
								operationType: values.operationType,
								status: data.status,
								feeAmount: data.feeAmount,
								bankAccount: data.bankAccount
							})
							setShowSuccessButton(true)
							if (data.balance) {
								setAsaasBalance({
									availableBalance: data.balance
								})
							}
							onSuccess()
						} else if (responseStatusCode === 400 && error?.fields) {
							formik.setFieldError('amount', error?.fields.amount[0].message)
						} else {
							setAlertErrorMessage(error?.message || t('error-server-default'))
							setOpenAlertError(true)
						}
					},
					onError: error => {
						setAlertErrorMessage(t('error-server-default'))
						setOpenAlertError(true)
						console.error(error)
					}
				}
			)
		}
	})

	const handleClose = useCallback(() => {
		formik.resetForm()
		setShowSuccessButton(false)
		onClose()
	}, [formik, onClose])

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={handleClose}>
					{t('transfer-balance')}
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<Box sx={{mb: 3}}>
					<Box sx={{mb: 2}}>
						<Typography variant="subtitle1" fontWeight={600}>
							{t('beneficiary')}
						</Typography>
						<Box>
							{company?.cnpj && (
								<Typography>
									{t('cnpj')}: {regex.formatCNPJ(company.cnpj)}
								</Typography>
							)}
							<Typography>
								{t('bank-code')}:{' '}
								{
									banksCode.find(
										bankCode => asaasBankAccount?.bankCode === bankCode.code
									)?.label
								}
							</Typography>
							<Typography>
								{t('account')}: {asaasBankAccount?.branchNumber}
								{asaasBankAccount?.branchCheckDigit
									? `-${asaasBankAccount?.branchCheckDigit}`
									: ''}{' '}
								/ {asaasBankAccount?.accountNumber}-
								{asaasBankAccount?.accountCheckDigit}
							</Typography>
						</Box>
					</Box>
					<Box>
						<Typography variant="subtitle1" fontWeight={600}>
							{t('available-balance-to-transfer')}
						</Typography>
						<Typography variant="body1">
							{price.full(asaasBalance.availableBalance)}
						</Typography>
					</Box>
				</Box>
				<Stack spacing={4} flex={1} py={1}>
					{openAlertError ? (
						<AlertMessage
							message={alertErrorMessage}
							open={openAlertError}
							onClose={() => setOpenAlertError(false)}
							severity="error"
							mt={0}
						/>
					) : null}

					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">
							{t('operation-type')}
						</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							name="operationType"
							id="demo-simple-select"
							value={formik.values.operationType}
							label={t('operation-type')}
							onChange={event => {
								formik.handleChange(event)
								setTransferType(event.target.value as 'PIX' | 'TED')
							}}
						>
							<MenuItem value="PIX">{t('pix')}</MenuItem>
							<MenuItem value="TED">{t('ted')}</MenuItem>
						</Select>
					</FormControl>
					<TextField
						name="amount"
						type="text"
						label={t('amount')}
						variant="outlined"
						helperText={
							formik.touched.amount && Boolean(formik.errors.amount)
								? formik.errors.amount
								: formik.values.operationType === 'PIX'
								? t('helper-transfer-fee-asaas-pix')
								: t('helper-transfer-fee-asaas-ted')
						}
						error={formik.touched.amount && Boolean(formik.errors.amount)}
						margin="normal"
						value={formik.values.amount}
						onChange={event => {
							event.target.value = regex.formatBRLCurrency(event.target.value)
							formik.handleChange(event)
							setShowSuccessButton(false)
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">{'R$'}</InputAdornment>
							)
						}}
						onBlur={formik.handleBlur}
						size="small"
					/>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={theme => ({
								height: '44px',
								backgroundColor: 'success.light',
								color: 'success.contrastText',
								'&:hover': {backgroundColor: 'success.light'}
							})}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							size="large"
							variant="contained"
							type="submit"
							disabled={isLoading}
							sx={{height: '44px'}}
							onClick={formik.submitForm}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={25} />
							) : (
								t('to-transfer-now')
							)}
						</Button>
					)}
				</Stack>
			</Dialog.Body>
		</Dialog.Root>
	)
}
