import {forwardRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Fade from '@mui/material/Fade'
import {TransitionProps} from '@mui/material/transitions'
import {Theme} from '@mui/material/styles'
import Cropper, {Area} from 'react-easy-crop'
import {Box} from '@mui/system'
import {getCroppedImg} from './utils/cropImage'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import {MdOutlineRotate90DegreesCcw as RotateRightIcon} from 'react-icons/md'
import ThemeProvider from '../../providers/ThemeProvider'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Fade ref={ref} {...props} />
})

interface CropEasyProps {
	file: File
	url: string
	open: boolean
	aspectRatio?: number
	onClose: () => void
	onCrop: (file: File) => void
}

const CropImageFullScreen = ({
	file,
	url,
	open,
	aspectRatio,
	onCrop,
	onClose
}: CropEasyProps) => {
	const [zoom, setZoom] = useState(1)
	const [rotation, setRotation] = useState(0)
	const [crop, setCrop] = useState({x: 0, y: 0})
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)

	const {t} = useTranslation()

	const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels) // croppedAreaPixels
	}

	const handleCropImage = async () => {
		try {
			const croppedImage = await getCroppedImg({
				imageSrc: url,
				imageType: file.type as string,
				pixelCrop: croppedAreaPixels as Area,
				rotation
			})

			const croppedImageFile = new File([croppedImage], croppedImage.name, {
				type: croppedImage.type,
				lastModified: Date.now()
			})

			onCrop(croppedImageFile)
		} catch (error) {
			console.log(error)
		}
	}

	const handleChangeRotation = () => {
		setRotation(rotation => (rotation === 270 ? 0 : rotation + 90))
	}

	const handleReset = () => {
		setZoom(1)
		setRotation(0)
		setCrop({x: 0, y: 0})
	}

	const handleClose = () => {
		setTimeout(() => {
			handleReset()
		}, 300)
		onClose()
	}

	return (
		<ThemeProvider defaultTheme="dark">
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				sx={styles.Dialog}
			>
				<DialogContent
					dividers
					sx={{
						backgroundColor: '#333',
						position: 'relative',
						height: 400,
						width: 'auto',
						minWidth: {sm: 500}
					}}
				>
					<Cropper
						image={url}
						crop={crop}
						zoom={zoom}
						rotation={rotation}
						style={{
							containerStyle: {
								width: '100%'
							}
						}}
						showGrid={false}
						aspect={aspectRatio}
						zoomWithScroll={true}
						onZoomChange={setZoom}
						onRotationChange={setRotation}
						onCropChange={setCrop}
						onCropComplete={handleCropComplete}
					/>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						mx: 3,
						my: 2
					}}
				>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
							gap: 2
						}}
					>
						<Button
							variant="outlined"
							sx={{minWidth: 'max-content', p: 1}}
							onClick={handleChangeRotation}
						>
							<RotateRightIcon size={24} />
						</Button>
						<Box
							sx={{
								display: 'flex',
								gap: 4,
								flexWrap: 'wrap',
								alignSelf: 'center',
								justifyContent: 'center'
							}}
						>
							<Button variant="outlined" onClick={handleClose}>
								{t('cancel')}
							</Button>
							<Button
								variant="contained"
								onClick={() => {
									handleCropImage()
									handleClose()
								}}
							>
								{t('to-confirm')}
							</Button>
						</Box>
					</Box>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	)
}

const styles = {
	Dialog: (theme: Theme) => ({
		'& .MuiDialog-paper': {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				margin: 1
			}
		}
	}),
	DialogTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 2
	},
	closeIcon: (theme: Theme) => ({
		color: theme.palette.grey[500],
		padding: 0,
		'&:hover': {
			cursor: 'pointer',
			color: theme.palette.grey[600]
		}
	})
}

export default CropImageFullScreen
