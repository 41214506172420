import {useTranslation} from 'react-i18next'
import {Dispatch, SetStateAction, useCallback, useState} from 'react'
import {useMutation, useQuery} from '@tanstack/react-query'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'
import AlertMessage from '../../../AlertMessage'
import {BiSolidPurchaseTag as TagIcon} from 'react-icons/bi'
import {CgClose as RemoveIcon} from 'react-icons/cg'
import {listOfferGroupsByProduct} from '../../../../api/products/offerGroups'
import {
	createOrderBump,
	deleteOrderBump
} from '../../../../api/offers/orderBump'
import {price} from '../../../../helpers/price'
import {Dialog} from '../../../Dialog'
import {ExperienceIntegration} from '../../../../entities/Integration'
import {ConfirmDialog} from '../../../dialogs/ConfirmDialog'
import {PreviewOrderBump} from '../../../integrations/PreviewOrderBump'
import {DialogFormCreateOfferGroup} from '../../../integrations/forms/DialogFormCreateOfferGroup'

type OfferGroup = {
	id: string
	groupName: string
	description?: string
	active: boolean
	products: {
		id: string
		title: string
		cover?: string
		offer: {
			id: string
			title: string
			unitPrice: number
		}
	}[]
}

type OrderBump = {
	id: string
	offerGroup: OfferGroup
}

export const OrderBumpCard = ({
	ref,
	offerId,
	productId,
	orderBump: initialOrderBump,
	orderBumpIntegration
}: {
	offerId: string
	productId: string
	ref?: React.RefObject<HTMLDivElement>
	orderBump?: OrderBump
	orderBumpIntegration: ExperienceIntegration
}) => {
	const {t} = useTranslation()
	const [openOfferGroupsDialog, setOpenOfferGroupsDialog] = useState(false)
	const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false)
	const [openPreviewOrderBump, setOpenPreviewOrderBump] = useState(false)
	const [offerGroups, setOfferGroups] = useState<OfferGroup[]>([])
	const [orderBump, setOrderBump] = useState(initialOrderBump)
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertMessage, setAlertMessage] = useState<string>('')

	const addOrderBump = useCallback(
		(offerGroupId: string) => {
			const newOfferGroups = offerGroups.map(offerGroup => {
				return {
					...offerGroup,
					active: offerGroup.id === offerGroupId ? true : false
				}
			})

			const newOfferGroup = offerGroups.find(
				offerGroup => offerGroup.id === offerGroupId
			)

			if (newOfferGroup) {
				setOrderBump({
					id: '',
					offerGroup: newOfferGroup
				})
			}

			setOfferGroups(newOfferGroups)
		},
		[offerGroups, setOfferGroups]
	)

	const removeOrderBump = useCallback(
		(id: string) => {
			const newOfferGroups = offerGroups.map(offerGroup => ({
				...offerGroup,
				active: false
			}))
			setOfferGroups(newOfferGroups)
			setOrderBump(undefined)
		},
		[offerGroups]
	)

	const {mutate: mutateAddOrderBump, isLoading: isAddingOrderBump} =
		useMutation(createOrderBump, {
			networkMode: 'always'
		})

	const handleAddOrderBump = useCallback(
		(offerGroupId: string) => {
			addOrderBump(offerGroupId)
			setOpenOfferGroupsDialog(false)
			setOpenAlert(false)
			mutateAddOrderBump(
				{offerId, offerGroupId},
				{
					onSuccess: ({data, responseStatusCode, error}) => {
						if (responseStatusCode === 201 && data) {
							setOrderBump(prev => {
								if (prev) {
									return {
										...prev,
										id: data.orderBump.id
									}
								} else {
									return prev
								}
							})
						}
						if (responseStatusCode !== 201) {
							removeOrderBump(offerGroupId)
							setAlertMessage(error?.message || t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						removeOrderBump(offerGroupId)
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		},
		[addOrderBump, offerId, mutateAddOrderBump, removeOrderBump, t]
	)

	const {mutate: mutateRemoveOrderBump} = useMutation(deleteOrderBump, {
		networkMode: 'always'
	})

	const handleRemoveOrderBump = useCallback(
		(id: string) => {
			removeOrderBump(id)
			setOpenAlert(false)
			mutateRemoveOrderBump(
				{id, offerId},
				{
					onSuccess: ({responseStatusCode, error}) => {
						if (responseStatusCode !== 200) {
							addOrderBump(id)
							setAlertMessage(error?.message || t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						addOrderBump(id)
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		},
		[removeOrderBump, t, addOrderBump, mutateRemoveOrderBump, offerId]
	)

	useQuery(
		['offer-groups-by-product'],
		() => listOfferGroupsByProduct({productId}),
		{
			enabled: openOfferGroupsDialog && !offerGroups.length,
			onSuccess: ({data, responseStatusCode, error}) => {
				if (responseStatusCode === 200 && data) {
					setOfferGroups(
						data.offerGroups.map(offerGroup => ({
							...offerGroup,
							active: orderBump?.offerGroup?.id === offerGroup.id
						}))
					)
				} else {
					setAlertMessage(error?.message || t('error-server-default'))
					setOpenAlert(true)
				}
			},
			onError: error => {
				setAlertMessage(t('error-server-default'))
				setOpenAlert(true)
				console.error(error)
			}
		}
	)

	return (
		<>
			<DialogOfferGroups
				productId={productId}
				open={openOfferGroupsDialog}
				setOpen={setOpenOfferGroupsDialog}
				offerGroups={offerGroups}
				setOfferGroups={setOfferGroups}
				onAddOrderBump={handleAddOrderBump}
			/>
			{orderBump && (
				<DialogPreviewOrderBump
					open={openPreviewOrderBump}
					onClose={() => setOpenPreviewOrderBump(false)}
					title={orderBump.offerGroup.groupName}
					description={orderBump.offerGroup.description}
					orderBump={orderBump.offerGroup.products}
				/>
			)}
			{orderBump && (
				<ConfirmDialog
					open={openConfirmRemoveDialog}
					onClose={setOpenConfirmRemoveDialog.bind(null, false)}
					contentMessage={t('order-bump-confirm-delete-message')}
					confirmText={t('order-bump-confirm-delete-button')}
					onConfirm={() => handleRemoveOrderBump(orderBump.id)}
				/>
			)}
			<Card ref={ref} sx={{maxWidth: 600, p: 3}} variant="outlined">
				<Box sx={{display: 'flex', alignItems: 'center', gap: 1.5}}>
					<Box
						sx={{
							width: 50,
							height: 50,
							backgroundColor: '#EFF3FF',
							backgroundImage: `url("${orderBumpIntegration.integration.icon}")`,
							backgroundSize: '60%',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							borderRadius: '50%'
						}}
					/>
					<Typography variant="h6">{t('integration-order-bump')}</Typography>
				</Box>
				<Typography variant="body1" color="textSecondary" sx={{mt: 1}}>
					{t('integration-order-bump-description')}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						gap: 2,
						flexWrap: 'wrap',
						alignItems: 'flex-start',
						mt: 2
					}}
				>
					<Button
						variant="contained"
						onClick={setOpenOfferGroupsDialog.bind(null, true)}
					>
						{t('to-select-offer-groups')}
					</Button>
					{orderBump && (
						<Button
							variant="outlined"
							onClick={() => setOpenPreviewOrderBump(true)}
						>
							{t('to-preview')}
						</Button>
					)}
				</Box>
				<AlertMessage
					severity="error"
					open={openAlert}
					message={alertMessage}
					onClose={setOpenAlert.bind(null, false)}
				/>
				{orderBump && (
					<Card
						variant="outlined"
						sx={{
							width: '100%',
							mt: 3,
							'&:not(:last-child)': {
								borderBottom: '1px solid',
								borderColor: 'divider'
							}
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								gap: 2,
								py: 1,
								px: 1.5,
								backgroundColor: 'grey.100',
								borderBottom: '1px solid',
								borderColor: 'divider'
							}}
						>
							<Typography variant="subtitle1">
								{orderBump.offerGroup.groupName}
							</Typography>
							<Button
								variant="outlined"
								onClick={setOpenConfirmRemoveDialog.bind(null, true)}
								disabled={isAddingOrderBump}
								startIcon={<RemoveIcon />}
							>
								{t('to-remove')}
							</Button>
						</Box>
						{orderBump.offerGroup.products?.map(product => (
							<Box
								key={product.id}
								sx={{
									display: 'flex',
									flexDirection: 'row',
									gap: 2,
									alignItems: 'center',
									mx: 1.5,
									my: 2
								}}
							>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										borderRadius: '4px',
										height: 50,
										width: 66.7,
										aspectRatio: 4 / 3,
										borderWidth: 1,
										borderStyle: 'solid',
										borderColor: 'divider'
									}}
								>
									{product?.cover ? (
										<img
											src={product.cover}
											height={50}
											width={66.7}
											alt={product.title}
											style={{borderRadius: '4px', aspectRatio: 4 / 3}}
										/>
									) : (
										<Icon
											sx={theme => ({
												color: theme.palette.grey[400],
												ml: 0.5,
												mb: 0.5
											})}
										>
											<TagIcon />
										</Icon>
									)}
								</Box>
								<Box>
									<Typography variant="subtitle1">{product.title}</Typography>
									<Typography
										variant="subtitle2"
										color="textSecondary"
										fontWeight={400}
									>
										{product.offer.title}{' '}
										<span style={{fontWeight: 600}}>
											{price.full(product.offer.unitPrice)}
										</span>
									</Typography>
								</Box>
							</Box>
						))}
					</Card>
				)}
			</Card>
		</>
	)
}

const DialogOfferGroups = ({
	productId,
	offerGroups,
	setOfferGroups,
	open,
	setOpen,
	onAddOrderBump
}: {
	productId: string
	open: boolean
	offerGroups: OfferGroup[]
	setOfferGroups: Dispatch<SetStateAction<OfferGroup[]>>
	setOpen: (open: boolean) => void
	onAddOrderBump: (id: string) => void
}) => {
	const [openDialogFormGroup, setOpenDialogFormGroup] = useState(false)
	const {t} = useTranslation()

	const handleAddGroup = (newGroup: {
		id: string
		groupName: string
		products: {
			id: string
			title: string
			cover?: string
			offer: {
				id: string
				title: string
				unitPrice: number
			}
		}[]
	}) => {
		setOfferGroups(prev => [...prev, {...newGroup, active: false}])
	}

	return (
		<>
			<DialogFormCreateOfferGroup
				open={openDialogFormGroup}
				onClose={() => setOpenDialogFormGroup(false)}
				onAddGroup={handleAddGroup}
				productId={productId}
			/>
			<Dialog.Root open={open}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpen(false)}>
						{t('offer-groups')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body>
					<Stack spacing={4} direction="column" width="100%">
						{offerGroups.length === 0 && (
							<Box>
								<Typography color="textSecondary">
									{t('order-bump-empty-state')}
								</Typography>
								<Button
									variant="contained"
									onClick={() => setOpenDialogFormGroup(true)}
									sx={{mt: 3}}
								>
									{t('to-create-new-group')}
								</Button>
							</Box>
						)}
						{offerGroups?.map(({id, groupName, products, active}) => (
							<Card
								key={id}
								variant="outlined"
								sx={{
									width: '100%',
									'&:not(:last-child)': {
										borderBottom: '1px solid',
										borderColor: 'divider'
									}
								}}
							>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
										gap: 2,
										py: 1,
										px: 1.5,
										backgroundColor: 'grey.100',
										borderBottom: '1px solid',
										borderColor: 'divider'
									}}
								>
									<Typography variant="subtitle1">{groupName}</Typography>
									{active ? (
										<Button variant="contained" disabled>
											{t('added')}
										</Button>
									) : (
										<Button
											variant="contained"
											onClick={() => onAddOrderBump(id)}
										>
											{t('to-add')}
										</Button>
									)}
								</Box>
								{products?.map(product => (
									<Box
										key={product.id}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											gap: 2,
											alignItems: 'center',
											mx: 1.5,
											my: 2
										}}
									>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
												borderRadius: '4px',
												height: 50,
												width: 66.7,
												borderWidth: 1,
												borderStyle: 'solid',
												borderColor: 'divider'
											}}
										>
											{product?.cover ? (
												<img
													src={product.cover}
													height={50}
													width={66.7}
													alt={product.title}
													style={{borderRadius: '4px'}}
												/>
											) : (
												<Icon
													sx={theme => ({
														color: theme.palette.grey[400]
													})}
												>
													<TagIcon />
												</Icon>
											)}
										</Box>
										<Box>
											<Typography variant="subtitle1">
												{product.title}
											</Typography>
											<Typography
												variant="subtitle2"
												color="textSecondary"
												fontWeight={400}
											>
												{product.offer.title}{' '}
												<span style={{fontWeight: 600}}>
													{price.full(product.offer.unitPrice)}
												</span>
											</Typography>
										</Box>
									</Box>
								))}
							</Card>
						))}
					</Stack>
				</Dialog.Body>
			</Dialog.Root>
		</>
	)
}

const DialogPreviewOrderBump = ({
	open,
	onClose,
	orderBump,
	title,
	description
}: {
	open: boolean
	onClose: () => void
	title?: string
	description?: string
	orderBump: {
		id: string
		title: string
		cover?: string
		offer: {
			id: string
			title: string
			unitPrice: number
		}
	}[]
}) => {
	const {t} = useTranslation()

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>{t('preview')}</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<PreviewOrderBump
					title={title}
					description={description}
					orderBump={orderBump}
				/>
			</Dialog.Body>
		</Dialog.Root>
	)
}
