import {useTranslation} from 'react-i18next'
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Skeleton,
	Typography
} from '@mui/material'

import {Edition} from '../../../entities/Edition'
import EditionCard from '../../../components/EditionCard'
import InfoPopover from '../../../components/InfoPopover'
import AlertMessage from '../../../components/AlertMessage'

interface EditionsProps {
	editions?: Edition[]
	experienceSlug: string
	isLoading?: boolean
	alertMessage?: string
	openAlert?: boolean
	status?: 'error' | 'idle' | 'loading' | 'success'
	onCloseAlert?: () => void
	handleClickCreateEdition: () => void
	handleLoadMore?: () => void
	handleListEditions?: () => void
}

const Editions = ({
	editions,
	handleClickCreateEdition,
	experienceSlug,
	isLoading,
	status,
	handleLoadMore,
	alertMessage,
	openAlert = false,
	onCloseAlert,
	handleListEditions
}: EditionsProps) => {
	const {t} = useTranslation()

	return (
		<Box
			sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				[theme.breakpoints.down('sm')]: {
					gap: 2
				}
			})}
		>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					alignItems: 'center',
					columnGap: 4,
					pl: 2,
					justifyContent: 'space-between'
				}}
			>
				<Typography
					variant="subtitle1"
					sx={theme => ({
						display: 'flex',
						alignItems: 'center',
						fontWeight: 500,
						py: 1.5,
						[theme.breakpoints.down('sm')]: {
							px: 0
						}
					})}
				>
					{t('editions')}
					<InfoPopover
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left'
						}}
						sx={{
							width: '1.75rem'
						}}
					>
						<Typography variant="body2">{t('info-editions')}</Typography>
					</InfoPopover>
				</Typography>
				<Box
					sx={theme => ({
						alignItems: 'center',
						display: 'flex',
						gap: 2,
						flexWrap: 'wrap',
						[theme.breakpoints.down('sm')]: {width: '100%'}
					})}
				>
					{!isLoading && editions && editions.length === 0 ? (
						<Typography variant="body2" color="text.secondary">
							{t('info-editions-empty-state')}
						</Typography>
					) : null}
					<Button
						variant="contained"
						onClick={handleClickCreateEdition}
						sx={theme => ({
							width: 212,
							[theme.breakpoints.down('sm')]: {width: '100%'}
						})}
					>
						{t('to-add-edition')}
					</Button>
				</Box>
			</Box>
			{status === 'error' ? (
				<>
					<AlertMessage
						open={openAlert}
						severity="error"
						message={alertMessage || ''}
						onClose={onCloseAlert}
						sx={{mb: openAlert ? 2 : 0}}
					/>
					<Button variant="outlined" onClick={handleListEditions}>
						{t('to-list-editions')}
					</Button>
				</>
			) : null}
			{editions && editions.length > 0 && (
				<Box>
					{editions.map((edition, index) => (
						<Box>
							<EditionCard
								key={edition._id}
								edition={{
									_id: edition._id,
									slug: edition.slug,
									title: edition.title,
									status: edition.status,
									price: edition.price,
									vacancies: edition.vacancies,
									remainingVacancies: edition.remainingVacancies,
									sales: {
										revenue: edition.sales?.revenue || 0,
										spotsSold: edition.sales?.spotsSold || 0
									},
									experience: {
										slug: experienceSlug,
										status: edition.experience.status
									}
								}}
								url={`/products/${experienceSlug}/${edition.slug}`}
							/>
							{index < editions.length - 1 && <Divider />}
						</Box>
					))}
					{handleLoadMore && (
						<Button
							variant="text"
							onClick={handleLoadMore}
							disabled={isLoading}
							fullWidth
							sx={{mt: 1}}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={24} />
							) : (
								t('see-more')
							)}
						</Button>
					)}
				</Box>
			)}
			{!editions && isLoading ? (
				<Box>
					<EditionSkeleton />
					<EditionSkeleton />
					<EditionSkeleton />
				</Box>
			) : null}
		</Box>
	)
}

const EditionSkeleton = () => (
	<Box
		sx={theme => ({
			[theme.breakpoints.up('md')]: {
				display: 'grid'
			}
		})}
	>
		<Box
			className="hidden-scrollbar"
			sx={theme => ({
				display: 'flex',
				justifyContent: 'space-between',
				rowGap: 1,
				columnGap: 2,
				pt: 1,
				px: 2,
				minHeight: 60,
				[theme.breakpoints.up('md')]: {
					overflowX: 'auto',
					maxWidth: '100%',
					scrollSnapType: 'x mandatory'
				},
				[theme.breakpoints.down('md')]: {
					flexWrap: 'wrap'
				}
			})}
		>
			<Typography
				variant="body1"
				sx={theme => ({
					mt: 1.5,
					minWidth: 150,
					[theme.breakpoints.down('md')]: {
						mt: 0
					}
				})}
			>
				<Skeleton width={80} />
			</Typography>
			<Box
				display={{
					display: 'grid'
				}}
			>
				<Box
					className="hidden-scrollbar"
					sx={theme => ({
						display: 'flex',
						gap: 4,
						alignItems: 'center',
						[theme.breakpoints.down('md')]: {
							overflowX: 'auto',
							maxWidth: '100%',
							pb: 1.5,
							scrollSnapType: 'x mandatory'
						}
					})}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							minWidth: 120,
							justifyContent: 'center'
						}}
					>
						<Typography variant="body2" flex={1}>
							<Skeleton width={40} />
						</Typography>
						<Typography variant="body2" flex={1}>
							<Skeleton width={60} />
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							minWidth: 120,
							justifyContent: 'center'
						}}
					>
						<Typography variant="body2" flex={1}>
							<Skeleton width={50} />
						</Typography>
						<Typography variant="body2" flex={1}>
							<Skeleton width={30} />
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							minWidth: 120,
							justifyContent: 'center'
						}}
					>
						<Typography variant="body2" flex={1}>
							<Skeleton width={80} />
						</Typography>
						<Typography variant="body2" flex={1}>
							<Skeleton width={70} />
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center'
						}}
					>
						<Skeleton variant="rounded" width={72} height={23} />
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							p: '5px',
							width: 28
						}}
					>
						<Skeleton variant="rounded" width={18} height={8} />
					</Box>
				</Box>
			</Box>
		</Box>
		<Divider />
	</Box>
)

export default Editions
