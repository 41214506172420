import {useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Alert, Button, Typography} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import useAuthStates from '../../stores/useAuthStates'
import FormUpdatePassword from '../../components/forms/account/FormUpdatePassword'
import FormCodeValidate from '../../components/forms/account/FormUpdatePasswordCodeValidate'
import {Dialog} from '../../components/Dialog'
import {Card} from '../../components/Card'

interface DialogUpdatePasswordProps {
	alertMessage: string | null
	onClose: () => void
	handleOnSuccess: () => void
}

const DialogUpdatePassword = ({
	alertMessage,
	onClose,
	handleOnSuccess
}: DialogUpdatePasswordProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const refSendButton = useRef<HTMLButtonElement>(null)
	const {t} = useTranslation()

	const handleIsLoading = (isLoading: boolean) => {
		setIsLoading(isLoading)
	}

	const handleClickSubmitButton = () => {
		refSendButton.current?.click()
	}

	return (
		<>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>{t('to-change-password')}</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<FormUpdatePassword
					initialMessage={alertMessage}
					handleOnSuccess={handleOnSuccess}
					handleIsLoading={handleIsLoading}
					SubmitButton={() => (
						<Button
							type="submit"
							ref={refSendButton}
							sx={{display: 'none'}}
						></Button>
					)}
				/>
			</Dialog.Body>
			<Dialog.Footer>
				<Button variant="outlined" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleClickSubmitButton}
					disabled={isLoading}
					sx={{width: 185}}
				>
					{isLoading ? (
						<CircularProgress color="inherit" size={24.5} />
					) : (
						t('to-save-and-continue')
					)}
				</Button>
			</Dialog.Footer>
		</>
	)
}

interface DialogCodeValidateProps {
	email: string
	onBack: (alertMessage: string) => void
	onClose: () => void
	onCancel: () => void
	onSuccess: () => void
}

const DialogCodeValidate = ({
	email,
	onBack,
	onClose,
	onCancel,
	onSuccess
}: DialogCodeValidateProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const refSendButton = useRef<HTMLButtonElement>(null)
	const {t} = useTranslation()

	const handleIsLoading = (isLoading: boolean) => {
		setIsLoading(isLoading)
	}

	const handleClickSubmitButton = () => {
		refSendButton.current?.click()
	}

	return (
		<>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>{t('to-change-password')}</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<FormCodeValidate
					email={email}
					handleGoBack={onBack}
					handleOnSuccess={onSuccess}
					handleIsLoading={handleIsLoading}
					SubmitButton={() => (
						<Button
							type="submit"
							ref={refSendButton}
							sx={{display: 'none'}}
						></Button>
					)}
				/>
			</Dialog.Body>
			<Dialog.Footer>
				<Button variant="outlined" onClick={onCancel}>
					{t('cancel')}
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleClickSubmitButton}
					disabled={isLoading}
					sx={{width: 116}}
				>
					{isLoading ? (
						<CircularProgress color="inherit" size={24} />
					) : (
						t('to-confirm')
					)}
				</Button>
			</Dialog.Footer>
		</>
	)
}

interface DialogSuccessMessageProps {
	onClose: () => void
}

const DialogSuccessMessage = ({onClose}: DialogSuccessMessageProps) => {
	const {t} = useTranslation()

	return (
		<>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>{t('to-change-password')}</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<div>
					<Alert severity="success" sx={{my: 3, mx: 'auto'}}>
						{t('alert-success-successfully-updated-password')}
					</Alert>
				</div>
			</Dialog.Body>
			<Dialog.Footer>
				<Button variant="contained" color="primary" onClick={onClose}>
					{'ok'}
				</Button>
			</Dialog.Footer>
		</>
	)
}

const AccountUpdatePassword = () => {
	const [activeStep, setActiveStep] = useState(0)
	const [openModal, setOpenModal] = useState(false)
	const [alertMessage, setAlertMessage] = useState<string | null>(null)

	const email = useAuthStates.getState().email
	const {t} = useTranslation()

	const handleOpenModal = () => {
		setOpenModal(true)
	}

	const steps = useMemo(() => {
		const handleNext = () => {
			setActiveStep(prevActiveStep => prevActiveStep + 1)
		}

		const handleReset = (close: boolean = true) => {
			if (close) handleCloseModal()
			setActiveStep(0)
		}

		const handleGoBack = (alertMessage: string) => {
			setAlertMessage(alertMessage)
			setActiveStep(prevActiveStep => prevActiveStep - 1)
		}

		const handleCloseModal = () => {
			setOpenModal(false)
		}

		return [
			{
				step: 1,
				dialogContent: (
					<DialogUpdatePassword
						alertMessage={alertMessage}
						onClose={handleCloseModal}
						handleOnSuccess={handleNext}
					/>
				)
			},
			{
				step: 2,
				dialogContent: (
					<DialogCodeValidate
						email={email}
						onBack={handleGoBack}
						onClose={handleCloseModal}
						onCancel={handleReset}
						onSuccess={handleNext}
					/>
				)
			},
			{
				step: 3,
				dialogContent: <DialogSuccessMessage onClose={handleReset} />
			}
		]
	}, [email, alertMessage])

	return (
		<>
			<Card.Root>
				<Card.Body grid>
					<Card.Info>
						<Card.Title>{t('password')}</Card.Title>
						<Card.Describe>
							<Typography variant="body1">
								{t('info-change-password')}
							</Typography>
						</Card.Describe>
					</Card.Info>
					<Card.Button onClick={handleOpenModal} withBorder>
						{t('to-change-password')}
					</Card.Button>
				</Card.Body>
				{openModal && (
					<Dialog.Root open={openModal}>
						{steps[activeStep].dialogContent}
					</Dialog.Root>
				)}
			</Card.Root>
		</>
	)
}

export default AccountUpdatePassword
