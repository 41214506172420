import Box from '@mui/material/Box'
import {useMutation} from '@tanstack/react-query'
import {useCallback, useEffect, useState} from 'react'
import {
	AsaasSubAccountDocument,
	getAsaasSubAccountDocuments,
	completeAsaasOnboarding
} from '../../../api/payment-service/asaas'
import {useTranslation} from 'react-i18next'
import Typography from '@mui/material/Typography'
import {LoadingDialog} from '../../../components/LoadingDialog'
import AlertMessage from '../../../components/AlertMessage'
import useAsaasStates, {DocumentKey} from '../../../stores/useAsaasStates'
import {AsaasSendDocument} from './AsaasSendDocument'

export const AsaasSendingDocumentsStep = ({
	onSuccess
}: {
	onSuccess: () => void
}) => {
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')

	const [documents, setDocuments] = useState<{
		'identification-and-selfie'?: AsaasSubAccountDocument
		'social-contract'?: AsaasSubAccountDocument
		'entrepreneur-requirement'?: AsaasSubAccountDocument
		'minutes-of-election'?: AsaasSubAccountDocument
		custom?: AsaasSubAccountDocument
	} | null>(null)
	const [counter, setCounter] = useState(0)
	const [counterCycles, setCounterCycles] = useState(0)

	const {asaasConfig, updateAsaasSubAccountStatus, updateDocumentStatus} =
		useAsaasStates()
	const {t} = useTranslation()

	const {isLoading, mutate} = useMutation(getAsaasSubAccountDocuments, {
		networkMode: 'always'
	})

	const handleGetDocuments = useCallback(async () => {
		mutate(undefined, {
			onSuccess: ({data, error, responseStatusCode}) => {
				if (responseStatusCode === 200 && data) {
					setDocuments(data)
				} else if (error?.message) {
					setCounter(15)
					setAlertMessage(error.message)
					setOpenAlert(true)
				} else {
					setCounter(15)
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			},
			onError: error => {
				setCounter(15)
				setAlertMessage(t('error-server-default'))
				setOpenAlert(true)
				console.error(error)
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const {isLoading: isCheckingStatus, mutate: checkStatusMutate} = useMutation(
		completeAsaasOnboarding,
		{
			networkMode: 'always'
		}
	)

	const handleUpdateDocument = useCallback(
		(newDocument: {
			id: string
			key: DocumentKey
			newStatus: AsaasSubAccountDocument['status']
		}) => {
			updateDocumentStatus(newDocument.key, newDocument.newStatus)

			let someDocumentNotSent: boolean = false

			if (
				newDocument.newStatus === 'not-sent' ||
				newDocument.newStatus === 'rejected'
			) {
				someDocumentNotSent = true
			} else {
				someDocumentNotSent = Boolean(
					asaasConfig?.documents &&
						Object.entries(asaasConfig?.documents).some(
							([key, document]) =>
								(document.status === 'not-sent' ||
									document.status === 'rejected') &&
								key !== newDocument.key
						)
				)
			}

			if (someDocumentNotSent) {
				return
			}

			checkStatusMutate(undefined, {
				onSuccess: ({data, error, responseStatusCode}) => {
					if (responseStatusCode === 200 && data) {
						updateAsaasSubAccountStatus({
							commercialStatus: data.commercialStatus,
							documentStatus: data.documentStatus,
							general: data.generalStatus
						})
						onSuccess()
					} else {
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			})
		},
		[
			checkStatusMutate,
			onSuccess,
			t,
			asaasConfig?.documents,
			updateAsaasSubAccountStatus,
			updateDocumentStatus
		]
	)

	useEffect(() => {
		if (asaasConfig?.documents) {
			setDocuments(asaasConfig.documents)
		}

		if (!documents && counter > 0) {
			const timer = setTimeout(() => {
				setCounter(prev => prev - 1)
			}, 1000)
			return () => clearTimeout(timer)
		}

		if (!documents && counter === 0) {
			setCounterCycles(prev => prev + 1)
			handleGetDocuments()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counter, documents])

	return (
		<Box>
			<LoadingDialog
				open={isCheckingStatus}
				message={t('checking-asaas-account-sending-documents')}
			/>

			{!documents && (
				<Box sx={{my: 3}}>
					<Typography variant="caption" color="text.secondary">
						{isLoading ? (
							<span>
								{t('create-asaas-account-send-documents-step-loading-1')}
							</span>
						) : (
							<>
								<span>
									{counterCycles > 0
										? t('create-asaas-account-send-documents-step-loading-2')
										: t('create-asaas-account-send-documents-step-loading-3')}
								</span>
								<br />
								<span>
									{t('create-asaas-account-send-documents-step-counter', {
										counter
									})}
								</span>
							</>
						)}
					</Typography>
				</Box>
			)}

			{documents && (
				<Box
					sx={{
						my: 3
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 3
						}}
					>
						{Object.entries(documents).map(([key, document], i) => (
							<AsaasSendDocument
								key={`asaas-sub-account-document-${document.id}`}
								index={i + 1}
								documentKey={key as DocumentKey}
								data={document}
								onUpdateDocument={handleUpdateDocument}
							/>
						))}
					</Box>
					<AlertMessage
						open={openAlert}
						message={alertMessage}
						severity="error"
						onClose={() => setOpenAlert(false)}
					/>
				</Box>
			)}
		</Box>
	)
}
