import {Profile} from '../../entities/Profile'
import {RawProfile} from '../entities/RawProfile'
import {makeImage} from '../../helpers/factories/imageFactory'

export const profileMapper = {
	toDomain: async (rawProfile: RawProfile): Promise<Profile> => {
		const basePath = rawProfile.images.basePath

		return {
			name: rawProfile.name,
			nameSlug: rawProfile.nameSlug,
			avatar: await makeImage({
				basePath,
				imagePath: rawProfile.images.avatarPath
			}),
			logoDark: await makeImage({
				basePath,
				imagePath: rawProfile.images.logoDarkPath
			}),
			logoLight: await makeImage({
				basePath,
				imagePath: rawProfile.images.logoLightPath
			}),
			primaryColor: rawProfile.primaryColor
		}
	}
}
