import {Button, Tab, Tabs, Typography, Skeleton} from '@mui/material'
import Box from '@mui/material/Box'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Dialog} from '../Dialog'
import {IntegrationCard} from './IntegrationCard'
import {ExperienceIntegrationCard} from './ExperienceIntegrationCard'
import {
	ExperienceIntegration,
	PlatformIntegration,
	IntegrationCategoryType
} from '../../entities/Integration'
import {useQuery} from '@tanstack/react-query'
import {
	listExperienceIntegrations,
	listPlatformIntegrations
} from '../../api/integrations'
import AlertDialog from '../AlertDialog'
import AlertMessage from '../AlertMessage'

interface IntegrationsModalProps {
	experienceId: string
	open: boolean
	onClose: () => void
	onAdd: (experienceIntegration: ExperienceIntegration) => void
}

const IntegrationsModal = ({
	experienceId,
	open,
	onClose,
	onAdd
}: IntegrationsModalProps) => {
	const {t} = useTranslation()
	const [category, setCategory] = useState<IntegrationCategoryType>('all')
	const [initialPlatformIntegrations, setInitialPlatformIntegrations] =
		useState<PlatformIntegration[]>([])
	const [filteredIntegrations, setFilteredIntegrations] = useState<
		PlatformIntegration[]
	>([])
	const [openAlertError, setOpenAlertError] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>('')

	const handleChangeTabMenu = (
		_: React.SyntheticEvent,
		newCategory: IntegrationCategoryType
	) => {
		setCategory(newCategory)

		if (newCategory === 'all') {
			setFilteredIntegrations(initialPlatformIntegrations)
		} else {
			setFilteredIntegrations(
				initialPlatformIntegrations.filter(
					integration => integration.category === newCategory
				)
			)
		}
	}

	const {isFetching} = useQuery(
		['platform-integrations'],
		() => listPlatformIntegrations({experienceId}),
		{
			networkMode: 'always',
			refetchOnWindowFocus: false,
			onSuccess: async ({responseStatusCode, data, error}) => {
				if (responseStatusCode === 200 && data) {
					setInitialPlatformIntegrations(data)
					setFilteredIntegrations(data)
				} else if (error) {
					setErrorMessage(error.message)
					setOpenAlertError(true)
				} else {
					setErrorMessage(t('error-server-default'))
					setOpenAlertError(true)
				}
			},
			onError: error => {
				setErrorMessage(t('error-server-default'))
				setOpenAlertError(true)
				console.error(error)
			}
		}
	)

	return (
		<>
			{openAlertError ? (
				<AlertDialog
					open={openAlertError}
					message={errorMessage}
					onClose={() => setOpenAlertError(false)}
				/>
			) : null}
			<Dialog.Root open={open} fullScreen>
				<Dialog.Header>
					<Dialog.Title onClose={onClose}>{t('integrations')}</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width="100%" sx={{pt: '0px !important', border: 0}}>
					<Box
						sx={{
							width: '100%'
						}}
					>
						<Box
							sx={{
								borderBottom: 1,
								borderColor: 'divider',
								width: '100%',
								mb: 2,
								backgroundColor: 'background.paper',
								position: 'fixed',
								px: 3
							}}
						>
							<Tabs
								value={category}
								onChange={handleChangeTabMenu}
								aria-label="basic tabs example"
								sx={{
									'& .MuiTabs-scroller': {
										overflowX: 'auto'
									}
								}}
							>
								<Tab label={t('all')} value="all" />
								<Tab label={t('delivery')} value="delivery" />
								<Tab label={t('digital-marketing')} value="digital-marketing" />
								<Tab label={t('sales')} value="sales" />
								<Tab label={t('crm')} value="crm" />
								<Tab label={t('others')} value="others" />
							</Tabs>
						</Box>
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
								alignItems: 'stretch',
								gap: 2,
								p: 3,
								mt: '50px'
							}}
						>
							{isFetching
								? new Array(6).fill(0).map((_, index) => (
										<Box
											key={`integration-skeleton-${index}`}
											sx={{
												height: 420,
												width: '100%',
												borderRadius: 1,
												backgroundColor: theme => theme.palette.grey[100]
											}}
										></Box>
								  ))
								: filteredIntegrations?.map(
										({
											id,
											name,
											cover,
											icon,
											category,
											description,
											summary,
											isAdded
										}) => (
											<IntegrationCard
												key={'platform-integration-' + id}
												id={id}
												experienceId={experienceId}
												name={name}
												cover={cover}
												icon={icon}
												category={category}
												description={description}
												summary={summary}
												isAdded={isAdded}
												onClick={onAdd}
											/>
										)
								  )}
						</Box>
					</Box>
				</Dialog.Body>
			</Dialog.Root>
		</>
	)
}

export const Integrations = ({experienceId}: {experienceId: string}) => {
	const {t} = useTranslation()

	const [errorMessage, setErrorMessage] = useState<string>('')
	const [openAlertError, setOpenAlertError] = useState<boolean>(false)
	const [openIntegrationsModal, setOpenIntegrationsModal] = useState(false)
	const [experienceIntegrations, setExperienceIntegrations] = useState<
		ExperienceIntegration[]
	>([])

	const handleOpenIntegrationsModal = () => {
		setOpenIntegrationsModal(true)
	}

	const handleCloseIntegrationsModal = () => {
		setOpenIntegrationsModal(false)
	}

	const handleAddIntegration = ({
		id,
		integration,
		experienceId,
		status
	}: ExperienceIntegration) => {
		setExperienceIntegrations(prev => [
			...prev,
			{
				id,
				status,
				experienceId,
				integration
			}
		])
		handleCloseIntegrationsModal()
	}

	const {isFetching} = useQuery(
		['experience-integrations'],
		() => listExperienceIntegrations({experienceId}),
		{
			networkMode: 'always',
			refetchOnWindowFocus: false,
			onSuccess: async ({responseStatusCode, data, error}) => {
				if (responseStatusCode === 200 && data) {
					setExperienceIntegrations(data)
				} else if (error) {
					setErrorMessage(error.message)
					setOpenAlertError(true)
				} else {
					setErrorMessage(t('error-server-default'))
					setOpenAlertError(true)
				}
			},
			onError: error => {
				setErrorMessage(t('error-server-default'))
				setOpenAlertError(true)
				console.error(error)
			}
		}
	)

	return (
		<Box
			sx={theme => ({
				p: 2,
				mt: 5,
				border: '1px solid ' + theme.palette.divider,
				borderRadius: 1
			})}
		>
			{openIntegrationsModal ? (
				<IntegrationsModal
					experienceId={experienceId}
					open={openIntegrationsModal}
					onClose={handleCloseIntegrationsModal}
					onAdd={handleAddIntegration}
				/>
			) : null}
			<Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						gap: 2
					}}
				>
					<Typography variant="subtitle1" fontWeight={500}>
						{t('integrations')}
					</Typography>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							gap: 2
						}}
					>
						<Typography variant="body2" color="text.secondary">
							{t('integrations-description')}
						</Typography>
						<Button
							variant="outlined"
							onClick={handleOpenIntegrationsModal}
							sx={theme => ({
								minWidth: '212px',
								[theme.breakpoints.down('sm')]: {width: '100%'}
							})}
						>
							{t('to-show-integrations')}
						</Button>
					</Box>
				</Box>
				{openAlertError ? (
					<AlertMessage
						message={errorMessage}
						open={openAlertError}
						severity="error"
						onClose={() => setOpenAlertError(false)}
					/>
				) : null}
				{isFetching ? (
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
							alignItems: 'stretch',
							gap: 2,
							mt: 2
						}}
					>
						<Skeleton variant="rounded" height={232} />
						<Skeleton variant="rounded" height={232} />
						<Skeleton variant="rounded" height={232} />
					</Box>
				) : null}
				{experienceIntegrations.length === 0 ? null : (
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns:
								experienceIntegrations.length === 0
									? '1fr'
									: 'repeat(auto-fill, minmax(240px, 1fr))',
							alignItems: 'stretch',
							gap: 2,
							mt: 2
						}}
					>
						{experienceIntegrations.map(
							({id, status, metadata, integration}) => (
								<ExperienceIntegrationCard
									key={'experience-integration-' + id}
									id={id}
									status={status}
									metadata={metadata}
									name={integration.name}
									summary={integration.summary}
									icon={integration.icon}
									category={integration.category}
									experienceId={experienceId}
								/>
							)
						)}
					</Box>
				)}
			</Box>
		</Box>
	)
}
