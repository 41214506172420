import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Tooltip from '@mui/material/Tooltip'
import {Theme} from '@mui/material/styles'
import {HiDotsHorizontal as DotsHorizontalIcon} from 'react-icons/hi'

import {ExperienceListItem} from '../entities/Experience'
import {regex} from '../helpers/regex'
import EditionCard from './EditionCard'
import Link from './navigation/Link'
import SalePageManager from './SalePageManager'
import {Status} from './Status'
import {useState} from 'react'
import MenuList from './MenuList'
import useNavigate from '../hooks/useNavigate'
import TabletUp from './layout/responsive/TabletUp'
import TabletDown from './layout/responsive/TabletDown'
import MobileUp from './layout/responsive/MobileUp'
import Mobile from './layout/responsive/Mobile'
import Tablet from './layout/responsive/Tablet'

interface ExperienceCardProps {
	experience: ExperienceListItem
	onChange: (experience: ExperienceListItem) => void
}

const ExperienceCard = ({experience, onChange}: ExperienceCardProps) => {
	const {t} = useTranslation()
	const navigate = useNavigate()

	const handleNavigateToCreateEdition = () => {
		navigate(`/products/${experience.slug}/create-edition`)
	}

	return (
		<Card
			variant="outlined"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				p: 2
			}}
		>
			<Mobile>
				<OptionsAndStatus experience={experience} />
			</Mobile>
			<Box
				sx={theme => ({
					display: 'flex',
					alignItems: 'flex-start',
					columnGap: 3,
					rowGap: 1.5,
					[theme.breakpoints.down(500)]: {
						flexDirection: experience.title.length > 70 ? 'column' : 'row'
					},
					[theme.breakpoints.down(350)]: {
						flexDirection: 'column'
					}
				})}
			>
				{experience.cover ? (
					<Box
						sx={theme => ({
							width: 180,
							height: 135,
							borderRadius: 1,
							backgroundColor: theme.palette.grey[200],
							maxWidth: '100%',
							[theme.breakpoints.down(350)]: {
								width: '100%'
							}
						})}
					>
						<Box
							component="img"
							src={experience.cover}
							alt={experience.title}
							sx={{
								width: '100%',
								height: 135,
								objectFit: 'cover',
								borderRadius: 1
							}}
						/>
					</Box>
				) : null}
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						flex: 1,
						flexDirection: 'column',
						gap: 2,
						width: '100%'
					}}
				>
					<Box
						sx={(theme: Theme) => ({
							display: 'grid',
							gridTemplateColumns: '1fr 120px',
							width: '100%',
							[theme.breakpoints.down('md')]: {
								display: 'flex',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								rowGap: 1,
								columnGap: 2
							}
						})}
					>
						<Tooltip
							title={
								<>
									<Typography variant="body2" mb={0.75}>
										{experience.title}
									</Typography>
									{experience.description}
								</>
							}
							placement="bottom-start"
						>
							<Typography variant="h6" component="h2" sx={styles.truncateText}>
								<Link
									to={`/products/${experience.slug}`}
									sx={[
										(theme: Theme) => ({
											color: theme.palette.common.black,
											'&:hover': {
												color: theme.palette.primary.dark
											}
										})
									]}
									state={{experience}}
								>
									<Tablet>
										{experience.title.length > 70
											? experience.title.slice(0, 70) + '...'
											: experience.title}
									</Tablet>
									<Mobile>{experience.title}</Mobile>
									<TabletUp>{experience.title}</TabletUp>
								</Link>
							</Typography>
						</Tooltip>
						<MobileUp>
							<OptionsAndStatus experience={experience} />
						</MobileUp>
					</Box>
					<TabletUp>
						<MetricsCards experience={experience} />
					</TabletUp>
				</Box>
			</Box>
			<TabletDown>
				<MetricsCards experience={experience} />
			</TabletDown>

			<Box>
				<SalePageManager
					data={{
						experience: {
							_id: experience._id,
							slug: experience.slug,
							status: experience.status
						},
						salePage: experience.salePage
							? {
									_id: experience.salePage?._id,
									disablePublish:
										experience.status !== 'active' &&
										!experience.salePage?.sectionsCount,
									published: experience.salePage?.published || false,
									sectionsCount: experience.salePage?.sectionsCount || 0
							  }
							: undefined
					}}
				/>
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						gap: 1,
						flexDirection: experience.editions.length > 0 ? 'column' : 'row',
						py: 2.5,
						mt: experience.editions.length > 0 ? 2 : 0
					}}
				>
					<Box
						sx={theme => ({
							display: 'flex',
							flex: 1,
							alignItems: 'center',
							justifyContent: 'space-between',
							pl: 2,
							[theme.breakpoints.down('sm')]: {
								pl: 0,
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: 2,
								justifyContent: 'flex-start'
							}
						})}
					>
						<Typography
							variant="subtitle1"
							fontWeight={500}
							sx={theme => ({
								pr: (experience.editions.length || 0) > 0 ? 2 : 0,
								[theme.breakpoints.down('sm')]: {
									px: 0,
									fontSize: '1.25rem'
								}
							})}
						>
							{t('editions')}
						</Typography>
						{experience.editions.length > 0 ? (
							<Button
								variant="contained"
								onClick={handleNavigateToCreateEdition}
								sx={theme => ({
									width: 212,
									[theme.breakpoints.down('sm')]: {
										width: '100%'
									}
								})}
							>
								{t('to-add-edition')}
							</Button>
						) : null}
					</Box>
					{experience.editions.length > 0 ? (
						<Box>
							{experience.editions.map((edition, index) => (
								<>
									<EditionCard
										edition={{
											_id: edition._id,
											status: edition.status,
											slug: edition.slug,
											price: edition.price,
											title: edition.title,
											remainingVacancies: edition.remainingVacancies,
											vacancies: edition.vacancies,
											experience: {
												slug: experience.slug,
												status: experience.status
											},
											sales: {
												spotsSold: edition.sales?.spotsSold || 0,
												revenue: edition.sales?.revenue || 0
											}
										}}
										url={`/products/${experience.slug}/${edition.slug}`}
										key={edition._id}
									/>
									{index < (experience.editions.length || 0) - 1 && <Divider />}
								</>
							))}
						</Box>
					) : (
						<Box
							sx={theme => ({
								alignItems: 'center',
								display: 'flex',
								gap: 2,
								flexWrap: 'wrap',
								pl: 2,
								[theme.breakpoints.down('sm')]: {
									pl: 0
								}
							})}
						>
							<Typography variant="body2" color="text.secondary">
								{t('info-editions-empty-state')}
							</Typography>
							<Button
								variant="contained"
								onClick={handleNavigateToCreateEdition}
								sx={theme => ({
									width: 212,
									[theme.breakpoints.down('sm')]: {width: '100%'}
								})}
							>
								{t('to-add-edition')}
							</Button>
						</Box>
					)}
				</Box>
			</Box>
		</Card>
	)
}

const MetricsCards = ({experience}: {experience: ExperienceListItem}) => {
	const {t} = useTranslation()

	return (
		<Box
			sx={{
				display: 'grid'
			}}
		>
			<Box
				className="hidden-scrollbar"
				sx={(theme: Theme) => ({
					display: 'flex',
					alignItems: 'flex-start',
					gap: 2,
					[theme.breakpoints.down('sm')]: {
						overflowX: 'auto',
						maxWidth: '100%',
						scrollSnapType: 'x mandatory'
					}
				})}
			>
				<Card
					variant="elevation"
					elevation={0}
					sx={(theme: Theme) => ({
						border: `1px solid ${theme.palette.grey[300]}`,
						display: 'flex',
						flexDirection: 'column',
						height: 80,
						minWidth: 170,
						py: 1,
						px: 1.75 // 14px
					})}
				>
					<Typography variant="subtitle2">{t('total-revenue')}</Typography>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							flexGrow: 1,
							alignItems: 'center'
						}}
					>
						<Typography
							variant="h6"
							align="center"
							color="success.main"
							sx={{whiteSpace: 'nowrap', p: 1}}
						>
							{'R$ ' +
								regex.formatBRLCurrency(
									Number.parseFloat(String(experience.sales?.revenue)).toFixed(
										2
									)
								)}
						</Typography>
					</Box>
				</Card>
				<Card
					variant="elevation"
					elevation={0}
					sx={(theme: Theme) => ({
						border: `1px solid ${theme.palette.grey[300]}`,
						display: 'flex',
						flexDirection: 'column',
						height: 80,
						minWidth: 192,
						py: 1,
						px: 1.75 // 14px
					})}
				>
					<Typography variant="subtitle2">{t('total-spots-sold')}</Typography>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							flexGrow: 1,
							alignItems: 'center'
						}}
					>
						<Typography
							variant="h6"
							align="center"
							sx={{whiteSpace: 'nowrap', p: 1}}
						>
							{experience.sales?.spotsSold}
						</Typography>
					</Box>
				</Card>
			</Box>
		</Box>
	)
}

const OptionsAndStatus = ({experience}: {experience: ExperienceListItem}) => {
	const {t} = useTranslation()
	const navigate = useNavigate()

	const [anchorElOptions, setAnchorElOptions] = useState<null | HTMLElement>(
		null
	)
	const openOptions = Boolean(anchorElOptions)

	const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElOptions(event.currentTarget)
	}

	const handleCloseOptions = () => {
		setAnchorElOptions(null)
	}

	const handleNavigateToEdit = () => {
		navigate(`/products/${experience.slug}/edit`)
	}

	const handleNavigateToExperience = () => {
		navigate(`/products/${experience.slug}`)
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'center',
				alignSelf: 'flex-start',
				justifySelf: 'end',
				gap: 1,
				mt: '3px'
			}}
		>
			<Status.Root>
				{
					<Status.Tag type={experience.status}>
						<Status.Label label={t('status-tag-' + experience.status)} />
					</Status.Tag>
				}
			</Status.Root>
			<Box>
				<Tooltip title={t('options')} placement="bottom" arrow>
					<IconButton
						onClick={handleOpenOptions}
						size="small"
						aria-controls={openOptions ? t('options') : undefined}
						aria-haspopup="true"
						aria-expanded={openOptions ? 'true' : undefined}
					>
						<DotsHorizontalIcon color="inherit" />
					</IconButton>
				</Tooltip>
				<MenuList
					open={openOptions}
					anchorEl={anchorElOptions}
					onClose={handleCloseOptions}
					list={[
						{
							label: 'to-edit',
							onClick: handleNavigateToEdit
						},
						{
							label: 'to-open',
							onClick: handleNavigateToExperience
						}
					]}
				/>
			</Box>
		</Box>
	)
}

const styles = {
	truncateText: (theme: Theme) => ({
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '100%',
		[theme.breakpoints.down('md')]: {
			whiteSpace: 'normal',
			overflow: 'visible',
			textOverflow: 'unset'
		}
	})
}

export default ExperienceCard
