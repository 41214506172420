export const integrationMapper = {
	cademi: (metadata?: string) => {
		if (!metadata) return {tokenAPI: '', productId: '', subdomain: ''}
		return JSON.parse(metadata) as {
			tokenAPI?: string
			productId?: string
			subdomain?: string
		}
	},
	theMembers: (metadata?: string) => {
		if (!metadata) return {tokenAPI: '', productId: ''}
		return JSON.parse(metadata) as {tokenAPI?: string; productId?: string}
	},
	facebookPixel: (metadata?: string) => {
		if (!metadata) return {pixelId: ''}
		return JSON.parse(metadata) as {pixelId?: string}
	},
	upsell: (metadata?: string) => {
		if (!metadata)
			return {redirectFromInstantPayment: '', redirectFromPostPayment: ''}

		return JSON.parse(metadata) as {
			redirectFromInstantPayment?: string
			redirectFromPostPayment?: string
		}
	},
	whatsAppCheckout: (metadata?: string) => {
		if (!metadata)
			return {
				phoneCountryCode: '',
				phoneNumber: '',
				phoneAreaCode: '',
				phoneType: 'mobile'
			}
		return JSON.parse(metadata) as {
			phoneCountryCode?: string
			phoneNumber?: string
			phoneAreaCode?: string
			phoneType?: 'landline' | 'mobile'
		}
	}
}
