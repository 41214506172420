import {Experience} from '../../entities/Experience'
import {makeImageURL} from '../../helpers/factories/imageURLFactory'
import {RawExperience} from '../entities/RawExperience'
import {salePageMapper} from './salePageMapper'

export const experienceMapper = {
	toDomain: async (experience: RawExperience): Promise<Experience> => ({
		_id: experience._id,
		cover: experience?.cover
			? makeImageURL({
					basePath: experience.cover.basePath,
					imagePath: experience.cover.imagePath
			  })
			: null,
		title: experience.title,
		slug: experience.slug,
		description: experience.description,
		archived: experience.archived,
		status: experience.status,
		editions: experience.editions,
		sales: experience.sales,
		salePage: experience.salePage
			? await salePageMapper.toDomain(experience.salePage)
			: null,
		integrations: experience.integrations
	})
}
