import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import {SxProps, Theme} from '@mui/material/styles'

interface MetricsCardRootProps {
	minWidth?: number
	children: React.ReactNode
}

const MetricsCardRoot = ({children, minWidth}: MetricsCardRootProps) => {
	return (
		<Card variant="elevation" elevation={0} sx={[style.card, {minWidth}]}>
			{children}
		</Card>
	)
}

interface MetricsCardTitleProps {
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2'
	children: React.ReactNode
	sx?: SxProps<Theme>
}

const MetricsCardTitle = ({children, variant, sx}: MetricsCardTitleProps) => {
	return (
		<Typography sx={sx} variant={variant || 'subtitle2'}>
			{children}
		</Typography>
	)
}

interface MetricsCardValueProps {
	children: React.ReactNode
	sx?: SxProps<Theme>
}

const MetricsCardBody = ({children, sx}: MetricsCardValueProps) => {
	return <Box sx={sx}>{children}</Box>
}

interface MetricsCardSimpleProps {
	children?: React.ReactNode
	title: string
	minWidth?: number
	height?: number
}

export const MetricsCardSimple = ({
	title,
	children,
	minWidth,
	height
}: MetricsCardSimpleProps) => {
	return (
		<Card
			variant="elevation"
			elevation={0}
			sx={[style.card, {minWidth, height}]}
		>
			<Typography variant="subtitle2">{title}</Typography>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexGrow: 1,
					alignItems: 'center'
				}}
			>
				{children}
			</Box>
		</Card>
	)
}

const style = {
	card: (theme: Theme) => ({
		border: `1px solid ${theme.palette.grey[300]}`,
		display: 'flex',
		flexDirection: 'column',
		py: 1,
		px: 1.75, // 14px
		width: '100%',
		minWidth: 170
	})
}

export const MetricsCard = {
	Root: MetricsCardRoot,
	Title: MetricsCardTitle,
	Body: MetricsCardBody
}
