import {useState} from 'react'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {Theme} from '@mui/material/styles'
import {useLocation} from 'react-router-dom'
import {useMutation} from '@tanstack/react-query'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import {RiMailSendLine as IconEmailSend} from 'react-icons/ri'
import {CgClose as CloseIcon} from 'react-icons/cg'

import useCodeSchema from '../../../schemas/useCodeSchema'
import SectionTitle from '../../typography/SectionTitle'
import ButtonLink from '../../navigation/ButtonLink'
import {codeValidate, sendCodeValidate} from '../../../api/signUp'
import useAuthStates from '../../../stores/useAuthStates'
import Divider from '@mui/material/Divider'
import Countdown from '../../CountDown'

const FormSignUpCodeValidate = () => {
	const [signUpCompleted, setSignUpCompleted] = useState<boolean>(false)
	const [errorAlert, setErrorAlert] = useState<boolean>(false)
	const [serverError, setServerError] = useState<string>('')
	const [initialTime, setInitialTime] = useState(new Date().getTime())
	const [expiredCountdown, setExpiredCountdown] = useState(false)
	const expirationTimeInSeconds = 60 // 1 minute
	const login = useAuthStates(state => state.login)
	const {t} = useTranslation()
	const location = useLocation()
	const navigationState = location.state as {email: string}

	let email: string | undefined | null = null
	if (navigationState?.email) {
		email = navigationState.email
	} else {
		email = sessionStorage.getItem('signup-code-validate-email')
	}

	const {isLoading, mutate} = useMutation(
		(values: {code: string; email: string}) => codeValidate(values),
		{networkMode: 'always'}
	)

	const codeSchema = useCodeSchema()
	const formik = useFormik({
		initialValues: {
			code: ''
		},
		validationSchema: codeSchema,
		onSubmit: code => {
			mutate(
				{...code, email: email as string},
				{
					onSuccess: result => {
						if (
							result?.responseStatusCode === 200 &&
							result?.success &&
							result?.data
						) {
							login({
								...result.data,
								onboardingCompleted: false
							})
							setSignUpCompleted(true)
							sessionStorage.removeItem('signup-code-validate-email')
						} else if (
							result?.responseStatusCode === 400 &&
							result?.error?.fields?.code
						) {
							formik.setFieldError('code', result.error.fields.code[0].message)
						} else {
							setErrorAlert(true)
							setServerError(t('error-server-default'))
							console.error(result?.error?.message)
						}
					},
					onError: error => {
						setErrorAlert(true)
						setServerError(t('error-server-default'))
						console.error(error)
					}
				}
			)
		}
	})

	const {isLoading: isSendingCode, mutate: mutateSendCodeValidate} =
		useMutation(sendCodeValidate, {networkMode: 'always'})

	const handleSendCode = async () => {
		setErrorAlert(false)
		mutateSendCodeValidate(
			{email: email as string},
			{
				onSuccess: ({error, responseStatusCode}) => {
					if (responseStatusCode === 200) {
						setInitialTime(new Date().getTime())
						setExpiredCountdown(false)
					} else if (error) {
						setServerError(error.message)
						setErrorAlert(true)
					} else {
						setServerError(t('error-server-default'))
						setErrorAlert(true)
					}
				},
				onError: error => {
					setServerError(t('error-server-default'))
					setErrorAlert(true)
					console.error(error)
				}
			}
		)
	}

	const handleExpired = () => {
		setExpiredCountdown(true)
	}

	return signUpCompleted ? (
		<Box sx={style.formBox}>
			<SectionTitle>{t('welcome-to-excofy')}</SectionTitle>
			<Alert severity="success" sx={{mt: 3}}>
				{t('welcome-to-excofy-success')}
			</Alert>
			<ButtonLink
				to="/"
				size="large"
				variant="outlined"
				sx={{mt: 5, width: '100%'}}
			>
				{t('enter')}
			</ButtonLink>
		</Box>
	) : (
		<Box sx={style.formBox}>
			{email ? (
				<>
					<SectionTitle>{t('verify-your-email')}</SectionTitle>
					<Stack
						direction="row"
						sx={{
							alignItems: 'center',
							justifyContent: 'flex-start',
							flexWrap: 'wrap',
							margin: '10px 10px 10px 0'
						}}
					>
						<IconEmailSend
							style={{
								width: '50px',
								height: '50px',
								margin: '10px 10px 10px 0'
							}}
						/>
						<div style={{width: 'calc(100% - 60px)', minWidth: '210px'}}>
							<Typography>
								{t('verify-your-email-info')} <b>{email}</b>
							</Typography>
						</div>
					</Stack>
					<Typography>{t('verify-your-email-info2')}</Typography>
					<Collapse in={errorAlert}>
						<Alert
							severity="error"
							action={
								<IconButton
									aria-label={t('aria-close-alert')}
									color="inherit"
									size="small"
									onClick={() => {
										setErrorAlert(false)
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{mt: 3}}
						>
							{serverError}
						</Alert>
					</Collapse>
					<Box
						onSubmit={formik.handleSubmit}
						component="form"
						autoComplete="off"
						sx={{mt: 3}}
					>
						<Stack direction="column" spacing={4}>
							<TextField
								name="code"
								type="text"
								inputProps={{
									inputMode: 'numeric',
									maxLength: 6
								}}
								label={t('verification-code')}
								variant="outlined"
								helperText={
									formik.touched.code && Boolean(formik.errors.code)
										? formik.errors.code
										: t('helper-input-code')
								}
								error={formik.touched.code && Boolean(formik.errors.code)}
								margin="normal"
								value={formik.values.code}
								onChange={formik.handleChange}
								autoFocus
							/>
							<Button
								size="large"
								variant="contained"
								type="submit"
								disabled={isLoading}
								sx={{height: '44px'}}
							>
								{isLoading ? (
									<CircularProgress color="inherit" size={25} />
								) : (
									t('confirm')
								)}
							</Button>
							<Divider sx={{pt: 3}}>{t('didnt-receive-code')}</Divider>
							<Button
								size="large"
								variant="outlined"
								onClick={handleSendCode}
								sx={{
									height: '44px'
								}}
								disabled={isSendingCode || !expiredCountdown}
							>
								{isSendingCode ? (
									<CircularProgress color="inherit" size={25} />
								) : (
									<Typography variant="body2" color="text.secondary">
										{t('to-resend-code')}
										{!expiredCountdown ? (
											<>
												{' '}
												<Countdown
													type="minutes"
													initialTime={initialTime}
													expirationTimeInSeconds={expirationTimeInSeconds}
													onExpired={handleExpired}
												/>
											</>
										) : (
											''
										)}
									</Typography>
								)}
							</Button>
						</Stack>
					</Box>
				</>
			) : (
				<>
					<SectionTitle>{t('registration-not-found')}</SectionTitle>
					<Alert severity="error" sx={{mt: 5}}>
						{t('error-code-not-found')}
					</Alert>
					<ButtonLink
						to="/sign-up"
						size="large"
						variant="outlined"
						sx={{mt: 5, width: '100%'}}
					>
						{t('sign-up')}
					</ButtonLink>
				</>
			)}
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			padding: 5,
			width: '80%',
			maxWidth: '600px',
			[theme.breakpoints.down('md')]: {
				my: 0,
				padding: 3,
				width: '100%'
			}
		}
	}
}

export default FormSignUpCodeValidate
