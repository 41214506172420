import {useEffect} from 'react'
import {useMutation} from '@tanstack/react-query'
import FullPageLoading from './components/FullPageLoading'
import useAuthStates from './stores/useAuthStates'
import useAppStates from './stores/useAppStates'
import {refreshToken} from './api/refreshToken'
import {checkOnboardingCompleted} from './helpers/checkOnboardingCompleted'
import {profileMapper} from './api/mappers/profileMapper'

const Initialization = ({children}: {children?: React.ReactNode}) => {
	const {firstLoad, setFirstLoad} = useAppStates(state => state)
	const {login} = useAuthStates(state => state)

	const {mutate} = useMutation((signal: AbortSignal) => refreshToken(signal), {
		networkMode: 'always',
		retry: 3
	})

	useEffect(
		() => {
			const controller = new AbortController()
			const getSession = () => {
				mutate(controller.signal, {
					onSuccess: async result => {
						if (
							result?.responseStatusCode === 200 &&
							result?.success &&
							result?.data
						) {
							login({
								...result.data,
								accessToken: result.data.newAccessToken,
								profile: result.data.profile
									? await profileMapper.toDomain(result.data.profile)
									: null,
								onboardingCompleted: checkOnboardingCompleted(
									result.data.onboarding
								)
							})
						} else if (result?.error?.message) {
							console.error(result.error.message)
						}
						setFirstLoad(false)
					},
					onError: error => {
						setFirstLoad(false)
						console.error('Initialization Error: ' + JSON.stringify(error))
					}
				})
			}
			if (firstLoad) {
				getSession()
			}
			return () => {
				controller.abort()
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)
	return <>{firstLoad ? <FullPageLoading /> : children}</>
}

export default Initialization
