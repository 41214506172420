import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {
	Box,
	Button,
	CircularProgress,
	FormHelperText,
	IconButton,
	InputAdornment,
	Skeleton,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {MdOutlineCheck as CheckIcon} from 'react-icons/md'

import {INPUT} from '../../../config'
import useAuthStates from '../../../stores/useAuthStates'
import useExperienceSchema from '../../../schemas/useExperienceSchema'
import {checkExperienceSlug, updateExperience} from '../../../api/experiences'
import SectionTitle from '../../typography/SectionTitle'
import AlertMessage from '../../AlertMessage'
import {regex} from '../../../helpers/regex'
import InfoPopover from '../../InfoPopover'

interface FormUpdateExperienceProps {
	experience: {
		_id: string
		title: string
		slug: string
		description: string
	}
	handleChangeExperience: (experience: {
		_id: string
		title: string
		slug: string
		description: string
	}) => void
}

const FormUpdateExperience = ({
	experience,
	handleChangeExperience
}: FormUpdateExperienceProps) => {
	const {t} = useTranslation()

	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const [lastSlugChecked, setLastSlugChecked] = useState<string>('')
	const [slugAvailable, setSlugAvailable] = useState<boolean>(false)
	const [slugHelper, setSlugHelper] = useState<string>(t('helper-slug'))

	const sellerId = useAuthStates(state => state.sellerId)
	const experienceSchema = useExperienceSchema()
	const queryClient = useQueryClient()
	const {isLoading, mutate} = useMutation(
		(values: {
			experienceId: string
			title: string
			slug: string
			description: string
		}) => updateExperience(values),
		{networkMode: 'always'}
	)

	const formik = useFormik({
		initialValues: {
			title: experience?.title || '',
			slug: experience?.slug || '',
			description: experience?.description || ''
		},
		enableReinitialize: true,
		validationSchema: experienceSchema,
		onSubmit: values => {
			mutate(
				{experienceId: experience?._id as string, ...values},
				{
					onSuccess: (result, data) => {
						if (result.responseStatusCode === 200 && result.data) {
							setShowSuccessButton(true)
							handleChangeExperience({
								_id: result.data._id,
								title: result.data.title,
								description: result.data.description,
								slug: result.data.slug
							})
							queryClient.invalidateQueries([
								`experiences-${sellerId}`,
								`experience-${result.data.slug}`
							])
							window.history.replaceState(
								result.data,
								'Dashboard | Excofy',
								`/products/${result.data.slug}/edit`
							)
						}

						if (result.responseStatusCode === 400 && result.error?.fields) {
							Object.entries(result.error.fields).forEach(([key, value]) => {
								formik.setFieldError(key, value[0].message)
							})
						}

						if (result.responseStatusCode === 500) {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		}
	})

	const handleOnChangeForm = () => {
		setShowSuccessButton(false)
		setOpenAlert(false)
	}

	const {isLoading: isCheckingSlug, mutate: mutateCheckSlug} = useMutation(
		checkExperienceSlug,
		{
			networkMode: 'always'
		}
	)

	const checkAvailableSlug = (slug: string) => {
		if (
			!formik.errors.slug &&
			formik.values.slug !== '' &&
			lastSlugChecked !== formik.values.slug &&
			formik.values.slug !== experience?.slug
		) {
			setSlugAvailable(false)
			mutateCheckSlug(
				{slug: formik.values.slug},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							setSlugAvailable(true)
							if (formik.values.slug !== result.data.slug) {
								setSlugHelper(t('helper-slug-already-in-use'))
							}
							formik.setFieldValue('slug', result.data.slug)
							setLastSlugChecked(result.data.slug)
						}
						if (
							result?.responseStatusCode === 400 &&
							result?.error?.fields?.slug
						) {
							formik.setFieldError('slug', result.error.fields.slug[0].message)
						}
					}
				}
			)
		}
	}

	return (
		<Box
			onSubmit={formik.handleSubmit}
			onChange={handleOnChangeForm}
			component="form"
			autoComplete="off"
			sx={style.formBox}
		>
			<SectionTitle>{t('edit-experience')}</SectionTitle>
			{!experience ? (
				<Stack direction="column" spacing={4}>
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<FormHelperText sx={{my: '8px !important', px: 1}}>
						<Skeleton variant="rounded" height={12} />
					</FormHelperText>
					<Skeleton variant="rounded" height={125} />
					<Skeleton variant="rectangular" height={44} />
				</Stack>
			) : (
				<>
					<Stack direction="column" spacing={4} pr={3}>
						<AlertMessage
							open={openAlert}
							severity="error"
							message={alertMessage}
							onClose={() => setOpenAlert(false)}
						/>
						<TextField
							name="title"
							type="text"
							label={t('title')}
							value={formik.values.title}
							onChange={event => {
								const currentSlug = regex.formatStringToSlug(event.target.value)
								formik.setFieldValue('slug', currentSlug)
								formik.handleChange(event)
							}}
							onBlur={event => {
								formik.handleBlur(event)
								checkAvailableSlug(event.target.value)
							}}
							helperText={formik.touched.title && formik.errors.title}
							error={formik.touched.title && Boolean(formik.errors.title)}
							margin="normal"
							variant="outlined"
							size="small"
							inputProps={{maxLength: INPUT.EXPERIENCE.title.max}}
							InputProps={{
								endAdornment: (
									<InfoPopover sx={{position: 'absolute', right: -34}}>
										<Typography variant="body2">
											{t('info-title-product-or-service')}
										</Typography>
									</InfoPopover>
								)
							}}
						/>
						<TextField
							name="slug"
							type="text"
							label={t('slug')}
							value={formik.values.slug}
							onChange={event => {
								formik.handleChange(event)
								setSlugHelper(t('helper-slug'))
							}}
							onBlur={event => {
								formik.handleBlur(event)
								checkAvailableSlug(event.target.value)
							}}
							helperText={
								formik.touched.slug && Boolean(formik.errors.slug)
									? formik.errors.slug
									: slugHelper
							}
							error={formik.touched.slug && Boolean(formik.errors.slug)}
							variant="outlined"
							size="small"
							inputProps={{maxLength: INPUT.EXPERIENCE.slug.max}}
							InputProps={{
								endAdornment: (
									<>
										<InputAdornment position="end">
											{isCheckingSlug && (
												<CircularProgress color="primary" size={20} />
											)}
											{!isCheckingSlug &&
												slugAvailable &&
												(lastSlugChecked === formik.values.slug ||
													formik.values.slug === experience?.slug) && (
													<IconButton sx={style.checkIcon}>
														<CheckIcon size={20} />
													</IconButton>
												)}
										</InputAdornment>
										<InfoPopover sx={{position: 'absolute', right: -34}}>
											<Typography variant="body2">
												{t('info-experience-slug')}
											</Typography>
										</InfoPopover>
									</>
								)
							}}
						/>
						<TextField
							name="description"
							type="text"
							label={t('description')}
							multiline
							rows={4}
							value={formik.values.description}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							helperText={
								formik.touched.description && formik.errors.description
							}
							error={
								formik.touched.description && Boolean(formik.errors.description)
							}
							inputProps={{maxLength: INPUT.EXPERIENCE.description.max}}
							variant="outlined"
							size="small"
							InputProps={{
								endAdornment: (
									<InfoPopover sx={{position: 'absolute', right: -34}}>
										<Typography variant="body2">
											{t('info-experience-description')}
										</Typography>
									</InfoPopover>
								)
							}}
						/>
					</Stack>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={style.successButton}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							size="large"
							variant="contained"
							type="submit"
							disabled={isLoading}
							sx={style.formSubmitButton}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={25} />
							) : (
								t('to-save-changes')
							)}
						</Button>
					)}
				</>
			)}
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => ({
		my: 5,
		mt: 0,
		mb: 5,
		width: '100%',
		maxWidth: '520px'
	}),
	successButton: (theme: Theme) => ({
		mt: 4,
		height: '44px',
		backgroundColor: 'success.light',
		color: 'success.contrastText',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		'&:hover': {backgroundColor: 'success.light'}
	}),
	formSubmitButton: (theme: Theme) => ({
		mt: 4,
		height: '44px',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	checkIcon: (theme: Theme) => ({
		color: theme.palette.success.main,
		p: 0
	}),
	boxInfoPopover: {
		position: 'absolute',
		right: -34
	}
}

export default FormUpdateExperience
