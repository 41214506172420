import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {CreditCardFeesCalculatorData} from '../../api/feesCalculator'
import InfoPopover from '../InfoPopover'
import TableFees from '../TableFees'
import {Status} from '../Status'

const CreditCardFees = ({fees}: {fees: CreditCardFeesCalculatorData}) => {
	const {t} = useTranslation()

	return (
		<TableFees
			feesForBuyer={{
				grossAmount: fees.feesForBuyer.grossAmount,
				netAmount: fees.feesForBuyer.netAmount,
				totalFeesAmount: fees.feesForBuyer.totalFeesAmount,
				pagarme: [
					{
						head: t('mdr-fees'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForBuyer.mdrFees.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{t('mdr-fees-percentage', {
											percentage: fees.feesForBuyer.mdrFees.percentage
										})}
									</Typography>
								</InfoPopover>
							</Box>
						)
					},
					{
						head: t('service-fees'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForBuyer.platformFees.fixedFee.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{t('processing-fee')}:{' '}
										{fees.feesForBuyer.platformFees.fixedFee.processingFee}
									</Typography>
								</InfoPopover>
							</Box>
						)
					}
				],
				excofy: [
					{
						head: t('sales-commission-fee'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForBuyer.platformFees.variableFee.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{t('sales-commission-fee-percentage', {
											percentage:
												fees.feesForBuyer.platformFees.variableFee.percentage
										})}
									</Typography>
								</InfoPopover>
							</Box>
						)
					}
				]
			}}
			feesForSeller={{
				grossAmount: fees.feesForRecipient.grossAmount,
				netAmount: fees.feesForRecipient.netAmount,
				totalFeesAmount: fees.feesForRecipient.totalFeesAmount,
				pagarme: [
					{
						head: t('mdr-fees'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForRecipient.mdrFees.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{t('mdr-fees-percentage', {
											percentage: fees.feesForRecipient.mdrFees.percentage
										})}
									</Typography>
								</InfoPopover>
							</Box>
						)
					},
					{
						head: t('service-fees'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForRecipient.platformFees.fixedFee.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{t('processing-fee')}:{' '}
										{fees.feesForRecipient.platformFees.fixedFee.processingFee}
									</Typography>
								</InfoPopover>
							</Box>
						)
					}
				],
				excofy: [
					{
						head: t('sales-commission-fee'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForRecipient.platformFees.variableFee.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{t('sales-commission-fee-percentage', {
											percentage:
												fees.feesForRecipient.platformFees.variableFee
													.percentage
										})}
									</Typography>
								</InfoPopover>
							</Box>
						)
					}
				]
			}}
		/>
	)
}

export default CreditCardFees
