import Card from '@mui/material/Card'
import usePaymentServicePagarmeStates from '../../../../stores/usePaymentServicePagarmeStates'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {useTranslation} from 'react-i18next'
import {price} from '../../../../helpers/price'
import {date} from '../../../../helpers/date'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import {useCallback, useEffect, useMemo, useState} from 'react'
import useAppStates from '../../../../stores/useAppStates'
import {FormTransfer} from '../forms/FormTransfer'
import {Dialog} from '../../../../components/Dialog'
import Icon from '@mui/material/Icon'
import {FcMoneyTransfer as RefundIcon} from 'react-icons/fc'
import parseToHTML from 'html-react-parser'
import {useMutation} from '@tanstack/react-query'
import {getPagarmeBalance} from '../../../../api/payment-service/pagarme'
import {Alert, CircularProgress, Skeleton} from '@mui/material'
import ButtonIcon from '../../../../components/ButtonIcon'
import {MdOutlineRefresh as RefreshIcon} from 'react-icons/md'

const ProcessingTransferDialog = ({
	open,
	onClose
}: {
	open: boolean
	onClose: () => void
}) => {
	const {t} = useTranslation()

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>
					{t('transfer-in-progress')}
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<Box display="flex" gap={2} alignItems="center">
					<Icon
						sx={{
							fontSize: '6rem'
						}}
					>
						<RefundIcon style={{marginBottom: 4}} />
					</Icon>
					<Typography flex={1}>
						{parseToHTML(t('transfer-in-progress-info'))}
					</Typography>
				</Box>
			</Dialog.Body>
		</Dialog.Root>
	)
}

export const TransferActionCard = () => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()
	const {status, transferSettings, balance, setBalance} =
		usePaymentServicePagarmeStates(state => state)
	const [openTransferDialog, setOpenTransferDialog] = useState(false)
	const [openProcessingTransferDialog, setOpenProcessingTransferDialog] =
		useState(false)
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')

	const nextAutomaticWithdrawal = useMemo(() => {
		if (transferSettings?.transferInterval === 'Monthly') {
			const date = new Date()
			date.setMonth(date.getMonth() + 1)
			date.setDate(transferSettings?.transferMonthDay || 1)

			return date
		} else if (transferSettings?.transferInterval === 'Weekly') {
			const date = new Date()
			let dayOfWeek = transferSettings?.transferWeekDay || 1

			const diff = (dayOfWeek - date.getDay() + 7) % 7

			date.setDate(date.getDate() + diff)

			return date
		}

		const date = new Date()
		date.setDate(date.getDate() + 1)

		return date
	}, [transferSettings])

	const handleSuccessTransfer = () => {
		setOpenTransferDialog(false)
		setOpenProcessingTransferDialog(true)
	}

	const {mutate, isLoading} = useMutation(getPagarmeBalance, {
		networkMode: 'always'
	})

	const fetchGetBalance = useCallback(async () => {
		setOpenAlert(false)
		mutate(undefined, {
			onSuccess: ({data, responseStatusCode, error}) => {
				if (responseStatusCode === 200 && data) {
					setBalance({
						availableAmount: data.balance.availableAmount,
						availableAmountToTransfer: data.balance.availableAmountToTransfer,
						waitingFundsAmount: data.balance.waitingFundsAmount
					})
				} else if (responseStatusCode !== 200) {
					setOpenAlert(true)
					setAlertErrorMessage(error?.message || t('error-server-default'))
				}
			},
			onError: error => {
				setOpenAlert(true)
				setAlertErrorMessage(t('error-server-default'))
				console.error(error)
			}
		})
	}, [mutate, setBalance, t, setOpenAlert, setAlertErrorMessage])

	const disabledTransferButton = useMemo(() => {
		if (
			!balance ||
			(balance && balance.availableAmount < 13.67) ||
			isLoading ||
			status !== 'active'
		) {
			return true
		} else {
			return false
		}
	}, [balance, isLoading, status])

	const disabledButtonTooltipTitle = useMemo(() => {
		if (status !== 'active') {
			return t('info-recipent-not-active')
		} else {
			return t('info-pagarme-transfer-min-value')
		}
	}, [status, t])

	useEffect(() => {
		if (process.env.REACT_APP_ENVIRONMENT === 'production') {
			fetchGetBalance()
		} else {
			setBalance({
				availableAmount: 714872.25,
				availableAmountToTransfer: 714872.25,
				waitingFundsAmount: 0
			})
		}
	}, [fetchGetBalance, setBalance])

	return (
		<>
			<ProcessingTransferDialog
				open={openProcessingTransferDialog}
				onClose={() => setOpenProcessingTransferDialog(false)}
			/>
			<FormTransfer
				open={openTransferDialog}
				onClose={() => setOpenTransferDialog(false)}
				onSuccess={handleSuccessTransfer}
			/>
			<Card
				variant="outlined"
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					p: 1.75, // 14px
					width: 'max-content',
					minWidth: 200,
					gap: 1
				}}
			>
				<Typography variant="subtitle2">{t('balance')}</Typography>
				<Box>
					<Typography
						variant="h5"
						color="success.main"
						width="fit-content"
						sx={{
							whiteSpace: 'nowrap',
							color: 'success.main',
							fontWeight: 500,
							display: 'flex',
							alignItems: 'center'
						}}
					>
						{isLoading && <Skeleton width={184} sx={{mr: 1}} />}
						{!isLoading && !openAlert && price.full(balance?.availableAmount)}
						{(isLoading || openAlert) && (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								{openAlert &&
									process.env.REACT_APP_ENVIRONMENT === 'production' && (
										<Alert
											severity="error"
											sx={{
												py: 0,
												px: 1,
												mr: 1,
												lineHeight: '1rem',
												minWidth: '184px',
												'& .MuiAlert-icon': {
													alignItems: 'center',
													fontSize: '1rem',
													p: 0
												}
											}}
										>
											{alertErrorMessage}
										</Alert>
									)}
								{openAlert &&
									process.env.REACT_APP_ENVIRONMENT === 'production' && (
										<ButtonIcon
											icon={
												isLoading ? (
													<CircularProgress size={16} />
												) : (
													<RefreshIcon />
												)
											}
											title={t('to-try-again')}
											disabled={isLoading}
											onClick={fetchGetBalance}
										/>
									)}
							</Box>
						)}
					</Typography>
					<Typography variant="body2" sx={{mt: 1, color: 'grey.600'}}>
						{t('receivable-balance')}{' '}
						<b>{price.full(balance?.waitingFundsAmount)}</b>
					</Typography>
					{transferSettings?.automaticTransferEnabled && (
						<Typography variant="body2" sx={{color: 'grey.600'}}>
							{t('next-automatic-transfer')}{' '}
							<b>
								{date.convertISOToLocation(
									nextAutomaticWithdrawal.toISOString(),
									currentLang
								)}
							</b>
						</Typography>
					)}
				</Box>
				<Tooltip
					title={disabledButtonTooltipTitle}
					disableHoverListener={
						!disabledTransferButton || isLoading || !balance
					}
					arrow
				>
					<span>
						<Button
							variant="contained"
							sx={{mt: 1}}
							disabled={disabledTransferButton}
							onClick={() => setOpenTransferDialog(true)}
						>
							{t('to-transfer-now')}
						</Button>
					</span>
				</Tooltip>
			</Card>
		</>
	)
}
