import {useState} from 'react'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {useMutation} from '@tanstack/react-query'

import useNavigate from '../../../hooks/useNavigate'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'

import {Theme} from '@mui/material/styles'

import SectionTitle from '../../typography/SectionTitle'

import ButtonLink from '../../navigation/ButtonLink'

import {CgClose as CloseIcon} from 'react-icons/cg'
import {
	MdOutlineVisibility as Visibility,
	MdOutlineVisibilityOff as VisibilityOff
} from 'react-icons/md'

import usePasswordResetSchema from '../../../schemas/usePasswordResetSchema'
import {passwordReset} from '../../../api/forgotPassword'

const FormForgotPasswordPasswordReset = () => {
	const [passwordResetCompleted, setPasswordResetCompleted] =
		useState<boolean>(false)
	const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false)
	const [confirmPasswordVisible, setConfirmPasswordVisible] =
		useState<boolean>(false)
	const [errorAlert, setErrorAlert] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>('')

	const {t} = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()

	const navigationState = location.state as {email: string; token: string}

	let email: string | undefined | null = null
	if (navigationState?.email) {
		email = navigationState.email
	} else {
		email = sessionStorage.getItem('forgot-password-email')
	}

	let token: string | undefined | null = null
	if (navigationState?.token) {
		token = navigationState.token
	}

	if (!token || !email) {
		navigate('/forgot-password/code-validate')
	}

	const {isLoading, mutate} = useMutation(
		(values: {email: string; newPassword: string; token: string}) =>
			passwordReset(values),
		{networkMode: 'always'}
	)

	const passwordResetSchema = usePasswordResetSchema()
	const formik = useFormik({
		initialValues: {
			newPassword: '',
			confirmPassword: ''
		},
		validationSchema: passwordResetSchema,
		onSubmit: ({newPassword}) => {
			mutate(
				{email: email as string, newPassword, token: token as string},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.success) {
							setPasswordResetCompleted(true)
							sessionStorage.removeItem('forgot-password-email')
						} else if (
							result?.responseStatusCode === 400 &&
							result?.error &&
							result?.error?.fields
						) {
							if (result.error.fields.token || result.error.fields.email) {
								navigate('/forgot-password', {
									state: {
										error: {
											alert: true,
											message: t('error-password-reset-code-expired')
										}
									}
								})
							}
							if (result.error.fields.newPassword) {
								formik.setFieldError(
									'newPassword',
									result.error.fields.newPassword[0].message
								)
							}
						} else if (result?.responseStatusCode === 401 && result?.error) {
							sessionStorage.removeItem('forgot-password-email')
							navigate('/forgot-password', {
								state: {
									error: {
										alert: true,
										message: t('error-password-reset-attempts-exceeded')
									}
								}
							})
						} else if (result?.responseStatusCode === 403 && result?.error) {
							sessionStorage.removeItem('forgot-password-email')
							navigate('/forgot-password', {
								state: {
									error: {
										alert: true,
										message: t('error-password-reset-code-expired')
									}
								}
							})
						} else {
							setErrorAlert(true)
							setErrorMessage(t('error-server-default'))
							console.error(result?.error?.message)
						}
					},
					onError: error => {
						setErrorAlert(true)
						setErrorMessage(t('error-server-default'))
						console.error(error)
					}
				}
			)
		}
	})

	return passwordResetCompleted ? (
		<Box sx={style.formBox}>
			<SectionTitle>{t('password-recovered')}</SectionTitle>
			<Alert severity="success" sx={{mt: 3}}>
				{t('password-recovered-success')}
			</Alert>
			<ButtonLink
				to="/login"
				size="large"
				variant="outlined"
				sx={{mt: 5, width: '100%'}}
			>
				{t('to-login')}
			</ButtonLink>
		</Box>
	) : (
		<Box sx={style.formBox}>
			<SectionTitle>{t('reset-your-password')}</SectionTitle>
			<Collapse in={errorAlert}>
				<Alert
					severity="error"
					action={
						<IconButton
							aria-label={t('aria-close-alert')}
							color="inherit"
							size="small"
							onClick={() => {
								setErrorAlert(false)
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{mt: 3}}
				>
					{errorMessage}
				</Alert>
			</Collapse>
			<Box
				onSubmit={formik.handleSubmit}
				component="form"
				autoComplete="off"
				sx={{mt: 3}}
			>
				<Stack direction="column" spacing={4}>
					<FormControl
						variant="outlined"
						error={
							formik.touched.newPassword && Boolean(formik.errors.newPassword)
						}
					>
						<InputLabel htmlFor="outlined-new-password">
							{t('new-password')}
						</InputLabel>
						<OutlinedInput
							id="outlined-new-password"
							type={newPasswordVisible ? 'text' : 'password'}
							name="newPassword"
							value={formik.values.newPassword}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label={
											newPasswordVisible
												? t('aria-hide-password')
												: t('aria-show-password')
										}
										onClick={() => setNewPasswordVisible(!newPasswordVisible)}
										edge="end"
									>
										{newPasswordVisible ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label={t('new-password')}
						/>
						<FormHelperText>
							{formik.touched.newPassword && formik.errors.newPassword
								? formik.errors.newPassword
								: t('error-password-invalid')}
						</FormHelperText>
					</FormControl>
					<FormControl
						variant="outlined"
						error={
							formik.touched.confirmPassword &&
							Boolean(formik.errors.confirmPassword)
						}
					>
						<InputLabel htmlFor="outlined-confirm-password">
							{t('confirm-new-password')}
						</InputLabel>
						<OutlinedInput
							id="outlined-confirm-password"
							type={confirmPasswordVisible ? 'text' : 'password'}
							name="confirmPassword"
							value={formik.values.confirmPassword}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label={
											confirmPasswordVisible
												? t('aria-hide-password')
												: t('aria-show-password')
										}
										onClick={() =>
											setConfirmPasswordVisible(!confirmPasswordVisible)
										}
										edge="end"
									>
										{confirmPasswordVisible ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							}
							label={t('confirm-new-password')}
						/>
						<FormHelperText>
							{formik.touched.confirmPassword && formik.errors.confirmPassword
								? formik.errors.confirmPassword
								: t('helper-password-confirm')}
						</FormHelperText>
					</FormControl>
					<Button
						size="large"
						variant="contained"
						type="submit"
						disabled={isLoading}
						sx={{height: '44px'}}
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('reset')
						)}
					</Button>
				</Stack>
			</Box>
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			my: 5,
			padding: 5,
			width: '80%',
			maxWidth: '600px',
			[theme.breakpoints.down('md')]: {
				my: 0,
				padding: 3,
				width: '100%'
			}
		}
	}
}

export default FormForgotPasswordPasswordReset
