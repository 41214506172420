import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Link from '../../../components/navigation/Link'
import {FaHome as HomeIcon} from 'react-icons/fa'
import {useTranslation} from 'react-i18next'
import BackNavigation from '../../../components/navigation/BackNavigation'
import {DashboardFullSection} from '../../../components/template/DashboardFullSection'
import {CreateAsaasAccountForm} from './CreateAsaasAccountForm'
import {useQuery} from '@tanstack/react-query'
import {getAsaasSubAccount} from '../../../api/payment-service/asaas'
import useAsaasStates from '../../../stores/useAsaasStates'
import Footer from '../../../components/template/Footer'
import {AsaasSettings} from './AsaasSettings'

export default function AsaasAccountPage() {
	const {t} = useTranslation()
	const {
		asaasConfig,
		setAsaasConfig,
		setAsaasCardAlertError,
		onboardingCompleted
	} = useAsaasStates()

	const {isFetching} = useQuery(['ps-asaas-sub-account'], getAsaasSubAccount, {
		refetchOnWindowFocus: false,
		networkMode: 'always',
		enabled: !asaasConfig,
		onSuccess: ({data, responseStatusCode, error}) => {
			if (responseStatusCode === 200 && data) {
				setAsaasConfig(data)
			} else if (responseStatusCode !== 200) {
				setAsaasCardAlertError({
					open: true,
					message: error?.message || t('error-server-default')
				})
			}
		},
		onError: error => {
			setAsaasCardAlertError({
				open: true,
				message: t('error-server-default')
			})
			console.error(error)
		}
	})

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link
						to="/payment-services"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<span>{t('payment-services')}</span>
					</Link>
					<span>{t('ps-asaas')}</span>
				</Breadcrumbs>
				<BackNavigation to="/payment-services" />
			</Container>
			<DashboardFullSection>
				{!isFetching && (
					<>
						{onboardingCompleted ? (
							<AsaasSettings />
						) : (
							<CreateAsaasAccountForm />
						)}
					</>
				)}
			</DashboardFullSection>
			<Footer />
		</>
	)
}
