import {Box, Button, Card, Icon, Skeleton, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {StatusTagManager} from '../../StatusTagManager'
import {useCallback, useState} from 'react'
import {useMutation, useQuery} from '@tanstack/react-query'
import {price as priceHelper} from '../../../helpers/price'
import {FaTrash as TrashIcon} from 'react-icons/fa'
import ButtonIcon from '../../ButtonIcon'
import {MdModeEdit as EditIcon} from 'react-icons/md'
import {BiSolidPurchaseTag as TagIcon} from 'react-icons/bi'
import AlertDialog from '../../AlertDialog'
import {ConfirmDialog} from '../../dialogs/ConfirmDialog'
import {
	deleteOfferGroup,
	listOfferGroupsByProduct
} from '../../../api/products/offerGroups'
import AlertMessage from '../../AlertMessage'
import {LoadingDialog} from '../../LoadingDialog'
import {DialogFormCreateOfferGroup} from './DialogFormCreateOfferGroup'
import {DialogFormUpdateOfferGroup} from './DialogFormUpdateOfferGroup'

type OfferGroup = {
	id: string
	groupName: string
	products: {
		id: string
		title: string
		cover?: string
		offer: {
			id: string
			title: string
			unitPrice: number
		}
	}[]
}

export const OrderBumpForm = ({
	status,
	onChangeStatus,
	productId
}: {
	status: string
	onChangeStatus: (status: string) => void
	productId: string
}) => {
	const {t} = useTranslation()

	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [open, setOpen] = useState(false)
	const [openAlert, setOpenAlert] = useState(false)
	const [openEdit, setOpenEdit] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [offerGroups, setOfferGroups] = useState<OfferGroup[]>([])
	const [idToDelete, setIdToDelete] = useState<string>('')
	const [offerGroup, setOfferGroup] = useState<OfferGroup | null>(null)

	const handleOpenCreateNewGroup = () => {
		setOpen(true)
	}

	const handleCloseCreateNewGroup = () => {
		setOpen(false)
	}

	const handleOpenDeleteModal = (id: string) => {
		setOpenDeleteModal(true)
		setIdToDelete(id)
	}

	const {mutate, isLoading} = useMutation(deleteOfferGroup, {
		networkMode: 'always'
	})

	const handleDeleteGroup = useCallback(() => {
		mutate(
			{id: idToDelete, productId},
			{
				onSuccess: ({responseStatusCode, error}) => {
					if (responseStatusCode === 200) {
						setOfferGroups(offerGroups.filter(group => group.id !== idToDelete))
						setIdToDelete('')
						setOpenDeleteModal(false)
					} else {
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}, [idToDelete, offerGroups, productId, mutate, t])

	const handleAddGroup = (newGroup: OfferGroup) => {
		setOfferGroups(prev => [...prev, newGroup])
	}

	const handleUpdateGroup = (newGroup: OfferGroup) => {
		setOfferGroups(prev =>
			prev.map(group => (group.id === newGroup.id ? newGroup : group))
		)
	}

	const {isFetching} = useQuery(
		['offer-groups-by-product'],
		() => listOfferGroupsByProduct({productId}),
		{
			refetchOnWindowFocus: false,
			onSuccess: ({data, responseStatusCode, error}) => {
				if (responseStatusCode === 200 && data) {
					setOfferGroups(data.offerGroups)
				} else {
					setAlertMessage(error?.message || t('error-server-default'))
					setOpenAlert(true)
				}
			},
			onError: error => {
				setAlertMessage(t('error-server-default'))
				setOpenAlert(true)
				console.error(error)
			}
		}
	)

	return (
		<>
			<LoadingDialog open={isLoading} message={t('deleting-offer-group')} />
			<AlertDialog
				message={t('delete-group-message')}
				open={openDeleteModal}
				onClose={setOpenDeleteModal.bind(null, false)}
			/>
			<ConfirmDialog
				open={openDeleteModal}
				onClose={setOpenDeleteModal.bind(null, false)}
				contentMessage={t('delete-group-message')}
				confirmText={t('to-delete-group')}
				onConfirm={handleDeleteGroup}
			/>
			<DialogFormCreateOfferGroup
				open={open}
				onClose={handleCloseCreateNewGroup}
				onAddGroup={handleAddGroup}
				productId={productId}
			/>
			{offerGroup && (
				<DialogFormUpdateOfferGroup
					open={openEdit}
					onClose={() => {
						setOfferGroup(null)
						setOpenEdit(false)
					}}
					productId={productId}
					offerGroup={offerGroup}
					onUpdateGroup={handleUpdateGroup}
				/>
			)}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 3,
					alignItems: 'flex-start'
				}}
			>
				<StatusTagManager onChangeStatus={onChangeStatus} status={status} />
				{openAlert && (
					<AlertMessage
						open={openAlert}
						onClose={setOpenAlert.bind(null, false)}
						message={alertMessage}
						severity="error"
					/>
				)}
				{isFetching && (
					<Box sx={{width: '100%'}}>
						<Typography
							variant="subtitle1"
							fontWeight={500}
							sx={{
								lineHeight: '36.5px'
							}}
						>
							{t('offer-groups')}
						</Typography>
						<Skeleton
							variant="rounded"
							sx={{width: '100%', height: 133, mt: 2}}
						/>
					</Box>
				)}
				{!isFetching && offerGroups.length === 0 && (
					<EmptyState onClick={handleOpenCreateNewGroup} />
				)}
				{!isFetching && offerGroups.length > 0 && (
					<Box sx={{width: '100%'}}>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: 2,
								alignItems: 'center',
								justifyContent: 'space-between',
								mb: 2
							}}
						>
							<Typography variant="subtitle1" fontWeight={500}>
								{t('offer-groups')}
							</Typography>
							<Button variant="contained" onClick={handleOpenCreateNewGroup}>
								{t('to-create-new-group')}
							</Button>
						</Box>
						<Card variant="outlined">
							{offerGroups?.map(({id, groupName, products}) => (
								<Box
									key={id}
									sx={{
										'&:not(:last-child)': {
											borderBottom: '1px solid',
											borderColor: 'divider'
										}
									}}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'center',
											gap: 2,
											py: 1,
											px: 1.5,
											backgroundColor: 'grey.100',
											borderBottom: '1px solid',
											borderColor: 'divider'
										}}
									>
										<Typography variant="subtitle1">{groupName}</Typography>
										<Box
											sx={{
												display: 'flex',
												gap: 1
											}}
										>
											<ButtonIcon
												title={t('to-edit')}
												icon={<EditIcon size={20} />}
												onClick={() => {
													setOfferGroup(
														offerGroups.find(group => group.id === id) || null
													)
													setOpenEdit(true)
												}}
											/>
											<ButtonIcon
												title={t('to-delete-group')}
												icon={<TrashIcon size={16} />}
												onClick={handleOpenDeleteModal.bind(null, id)}
											/>
										</Box>
									</Box>
									{products?.map(product => (
										<Box
											key={product.id}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												gap: 2,
												alignItems: 'center',
												mx: 1.5,
												my: 2
											}}
										>
											{product?.cover ? (
												<img
													src={product.cover}
													height={50}
													width={66.7}
													alt={product.title}
													style={{borderRadius: '4px'}}
												/>
											) : (
												<Box
													sx={theme => ({
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														width: 66.7,
														height: 50,
														backgroundColor: 'grey.100',
														border: '1px solid',
														borderColor: 'divider',
														borderRadius: '4px'
													})}
												>
													<Icon
														sx={theme => ({
															color: theme.palette.grey[400],
															ml: 0.5,
															mb: 0.5
														})}
														fontSize="large"
													>
														<TagIcon />
													</Icon>
												</Box>
											)}
											<Box>
												<Typography variant="subtitle1">
													{product.title}
												</Typography>
												<Typography
													variant="subtitle2"
													color="textSecondary"
													fontWeight={400}
												>
													{product.offer.title}{' '}
													<span style={{fontWeight: 600}}>
														{priceHelper.full(product.offer.unitPrice)}
													</span>
												</Typography>
											</Box>
										</Box>
									))}
								</Box>
							))}
						</Card>
					</Box>
				)}
			</Box>
		</>
	)
}

const EmptyState = ({onClick}: {onClick: () => void}) => {
	const {t} = useTranslation()

	return (
		<Box>
			<Typography variant="subtitle1" fontWeight={500}>
				{t('offer-groups')}
			</Typography>
			<Typography variant="body1" color="textSecondary" mt={1}>
				{t('offer-group-description')}
			</Typography>
			<Button variant="contained" sx={{mt: 3}} onClick={onClick}>
				{t('to-create-new-group')}
			</Button>
		</Box>
	)
}
