import {profileMapper} from '../api/mappers/profileMapper'
import {refreshToken} from '../api/refreshToken'
import useAuthStates from '../stores/useAuthStates'
import {checkOnboardingCompleted} from './checkOnboardingCompleted'

export const authenticatedFetch = async (
	input: RequestInfo | URL,
	init?: RequestInit | undefined
) => {
	const response = await fetch(input, init)

	if (response.status === 401) {
		const controller = new AbortController()
		const login = useAuthStates.getState().login

		const result = await refreshToken(controller.signal)

		if (result?.responseStatusCode === 200 && result?.success && result?.data) {
			login({
				...result.data,
				accessToken: result.data.newAccessToken,
				profile: result.data.profile
					? await profileMapper.toDomain(result.data.profile)
					: null,
				onboardingCompleted: checkOnboardingCompleted(result.data.onboarding)
			})

			const accessTokenFromState = useAuthStates.getState().accessToken

			return await fetch(input, {
				...init,
				headers: {
					...init?.headers,
					Authorization: `Bearer ${accessTokenFromState}`
				}
			})
		}
	}

	return response
}
