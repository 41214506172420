import {useTranslation} from 'react-i18next'
import parseToHTML from 'html-react-parser'
import {Dialog} from '../../../../components/Dialog'
import {
	Button,
	CircularProgress,
	InputAdornment,
	Stack,
	TextField
} from '@mui/material'

import * as yup from 'yup'
import {useFormik} from 'formik'
import {regex} from '../../../../helpers/regex'
import {useCallback, useState} from 'react'
import {updateCompanyAnnualRevenue} from '../../../../api/payment-service/pagarme'
import {useMutation} from '@tanstack/react-query'
import usePaymentServicePagarmeStates from '../../../../stores/usePaymentServicePagarmeStates'
import AlertMessage from '../../../../components/AlertMessage'

interface FormAnnualRevenueProps {
	open: boolean
	onClose: () => void
}

export const FormAnnualRevenue = ({open, onClose}: FormAnnualRevenueProps) => {
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)
	const {t} = useTranslation()
	const {companyAnnualRevenue, setCompanyAnnualRevenue} =
		usePaymentServicePagarmeStates()

	const {isLoading, mutate} = useMutation(updateCompanyAnnualRevenue, {
		networkMode: 'always'
	})

	const validationSchema = yup.object({
		companyAnnualRevenue: yup
			.string()
			.required(t('error-annual-revenue-required'))
	})

	const formik = useFormik({
		initialValues: {
			companyAnnualRevenue: companyAnnualRevenue
				? regex.formatBRLCurrency(String(companyAnnualRevenue * 100))
				: ''
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			setOpenAlertError(false)
			mutate(
				{
					companyAnnualRevenue: regex.parseBRLCurrencyToFloat(
						formik.values?.companyAnnualRevenue || '0'
					)
				},
				{
					onSuccess: ({error, responseStatusCode}) => {
						if (responseStatusCode === 200) {
							setShowSuccessButton(true)
							setCompanyAnnualRevenue(
								regex.parseBRLCurrencyToFloat(
									formik.values?.companyAnnualRevenue || '0'
								)
							)
							handleClose()
						} else if (responseStatusCode === 400 && error?.fields) {
							Object.entries(error.fields).forEach(([key, value]) => {
								formik.setFieldError(key, value[0].message)
							})
						} else {
							setAlertErrorMessage(error?.message || t('error-server-default'))
							setOpenAlertError(true)
						}
					},
					onError: error => {
						setAlertErrorMessage(t('error-server-default'))
						setOpenAlertError(true)
						console.error(error)
					}
				}
			)
		}
	})

	const handleClose = useCallback(() => {
		formik.resetForm()
		setShowSuccessButton(false)
		onClose()
	}, [formik, onClose])

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={handleClose}>{t('annual-revenue')}</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<Stack spacing={3} flex={1} py={1}>
					{openAlertError ? (
						<AlertMessage
							message={alertErrorMessage}
							open={openAlertError}
							onClose={() => setOpenAlertError(false)}
							severity="error"
							mt={0}
						/>
					) : null}
					<TextField
						name="companyAnnualRevenue"
						type="text"
						label={t('annual-revenue')}
						variant="outlined"
						helperText={
							formik.touched.companyAnnualRevenue &&
							Boolean(formik.errors.companyAnnualRevenue)
								? formik.errors.companyAnnualRevenue
								: parseToHTML(t('alert-info-annual-revenue-2'))
						}
						error={
							formik.touched.companyAnnualRevenue &&
							Boolean(formik.errors.companyAnnualRevenue)
						}
						margin="normal"
						value={formik.values.companyAnnualRevenue}
						onChange={event => {
							event.target.value = regex.formatBRLCurrency(event.target.value)
							formik.handleChange(event)
							setShowSuccessButton(false)
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">{'R$'}</InputAdornment>
							)
						}}
						onBlur={formik.handleBlur}
						size="small"
					/>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={theme => ({
								height: '44px',
								backgroundColor: 'success.light',
								color: 'success.contrastText',
								'&:hover': {backgroundColor: 'success.light'}
							})}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							size="large"
							variant="contained"
							type="submit"
							disabled={isLoading}
							sx={{height: '44px'}}
							onClick={formik.submitForm}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={25} />
							) : (
								t('to-save-changes')
							)}
						</Button>
					)}
				</Stack>
			</Dialog.Body>
		</Dialog.Root>
	)
}
