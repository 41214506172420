import {useTranslation} from 'react-i18next'
import {Dialog} from '../../Dialog'
import {Box, Tab, Tabs} from '@mui/material'
import {useState} from 'react'

const saleEventsTypes = {
	SALE_CREATED: {
		'event': 'SALE_CREATED',
		'sale': {
			'id': 'string',
			'status': 'open',
			'statusHistory': [
				{
					'status': 'open',
					'date': 'string',
					'log': 'any'
				}
			],
			'totalAmount': 'number',
			'customer': {
				'name': 'string',
				'email': 'string',
				'phone': 'string'
			},
			'payment': {
				'paidAt': 'null',
				'paymentMethod': 'null',
				'paymentService': 'null'
			},
			'refund': {
				'amount': 'null',
				'type': 'null',
				'status': 'null'
			},
			'products': [
				{
					'id': 'string',
					'slug': 'string',
					'title': 'string',
					'description': 'string',
					'quantity': 'number',
					'unitPrice': 'number',
					'isOrderBump': 'false',
					'offer': {
						'title': 'string',
						'slug': 'string'
					}
				}
			]
		}
	},
	SALE_UPDATED: {
		'event': 'SALE_UPDATED',
		'sale': {
			'id': 'string',
			'language': 'pt-BR | en-US',
			'tags': 'string | null',
			'status':
				'open | pending | paid | overdue | failed | processing-refund | refunded | chargeback',
			'statusHistory': [
				{
					'status':
						'open | pending | paid | overdue | failed | processing-refund | refunded | chargeback',
					'date': 'string',
					'log': 'any'
				}
			],
			'totalAmount': 'number',
			'customer': {
				'name': 'string',
				'email': 'string',
				'phone': 'string',
				'documentType': 'cpf | cnpj',
				'document': 'string'
			},
			'payment': {
				'paidAt': 'string',
				'paymentMethod': 'pix | credit_card | boleto',
				'paymentService': 'pagarme | asaas'
			},
			'refund': {
				'amount': 'number | null',
				'type': 'full | partial | null',
				'status': 'refunded | null'
			},
			'products': [
				{
					'id': 'string',
					'slug': 'string',
					'title': 'string',
					'description': 'string',
					'quantity': 'number',
					'unitPrice': 'number',
					'isOrderBump': 'boolean',
					'offer': {
						'title': 'string',
						'slug': 'string'
					}
				}
			]
		}
	}
}

export const SaleEventsDoc = ({
	open,
	onClose
}: {
	open: boolean
	onClose: () => void
}) => {
	const {t} = useTranslation()

	const [currentTab, setCurrentTab] = useState<'SALE_CREATED' | 'SALE_UPDATED'>(
		'SALE_CREATED'
	)

	return (
		<Dialog.Root open={open} maxWidth={600}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>
					{t('webhook-sale-events')}
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body sx={{pt: '8px !important'}}>
				<Box sx={{maxWidth: 536}}>
					<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
						<Tabs
							value={currentTab}
							onChange={(event, newValue) => setCurrentTab(newValue)}
							aria-label="simple tabs example"
						>
							<Tab label={t('SALE_CREATED')} value="SALE_CREATED" />
							<Tab label={t('SALE_UPDATED')} value="SALE_UPDATED" />
						</Tabs>
					</Box>
					<Box
						sx={{
							backgroundColor: 'grey.100',
							borderBottomLeftRadius: 8,
							borderBottomRightRadius: 8,
							px: 2,
							overflow: 'auto'
						}}
					>
						<pre>{JSON.stringify(saleEventsTypes[currentTab], null, 2)}</pre>
					</Box>
				</Box>
			</Dialog.Body>
		</Dialog.Root>
	)
}
