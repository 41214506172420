import Box from '@mui/material/Box'
import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Button from '@mui/material/Button'
import {
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField
} from '@mui/material'
import {CgClose as ClearIcon} from 'react-icons/cg'

interface CustomerResearchFilterProps {
	filters: {[key: string]: string | undefined}
	onChange: (filters: {[key: string]: string | undefined}) => void
}

type SearchFieldType = 'name' | 'email' | 'document'

export default function CustomerResearchFilters({
	filters,
	onChange
}: CustomerResearchFilterProps) {
	const {t} = useTranslation()

	const [placeholder, setPlaceholder] = useState<string>(t('search-name'))
	const [searchField, setSearchField] = useState<SearchFieldType>('name')
	const [query, setQuery] = useState<string | null>(null)

	const handleApplyFilters = useCallback(() => {
		onChange({
			name: searchField === 'name' && query ? query : undefined,
			email: searchField === 'email' && query ? query : undefined,
			document: searchField === 'document' && query ? query : undefined
		})
	}, [query, searchField, onChange])

	const handleChangeSearchField = (event: SelectChangeEvent) => {
		setSearchField(event.target.value as SearchFieldType)
	}

	useEffect(() => {
		setPlaceholder(t('search-' + searchField))
	}, [searchField, t])

	useEffect(() => {
		if (filters[searchField] !== query) {
			setQuery(filters[searchField] || '')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	return (
		<Box
			sx={theme => ({
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
				gap: 2,
				width: '100%',
				[theme.breakpoints.down('md')]: {
					flexDirection: 'column',
					alignItems: 'flex-start'
				}
			})}
		>
			<Box
				sx={theme => ({
					flex: 1,
					display: 'flex',
					gap: 2,
					width: '100%',
					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',
						alignItems: 'flex-start'
					}
				})}
			>
				<TextField
					name="query"
					label={placeholder}
					placeholder={placeholder + '...'}
					value={query}
					onChange={e => setQuery(e.target.value)}
					size="small"
					sx={theme => ({
						minWidth: 300,
						flex: 1,
						[theme.breakpoints.down('sm')]: {
							minWidth: '100%',
							width: '100%'
						}
					})}
					InputProps={{
						endAdornment: (
							<IconButton
								size="small"
								title={t('clear')}
								onClick={() => {
									setQuery('')
									onChange({
										[searchField]: undefined
									})
								}}
							>
								<ClearIcon />
							</IconButton>
						)
					}}
				/>
				<FormControl
					sx={theme => ({
						minWidth: 180,
						flex: 1,
						[theme.breakpoints.down('sm')]: {
							minWidth: '100%',
							width: '100%'
						}
					})}
				>
					<InputLabel id="select-research">{t('search-field')}</InputLabel>
					<Select
						labelId="select-research"
						label={t('search-field')}
						value={searchField}
						onChange={handleChangeSearchField}
						size="small"
						sx={theme => ({
							minWidth: 200,
							flex: 1,
							[theme.breakpoints.down(1000)]: {
								minWidth: '100%',
								width: '100%',
								flex: 'none'
							}
						})}
					>
						<MenuItem value="name">{t('name')}</MenuItem>
						<MenuItem value="email">{t('email')}</MenuItem>
						<MenuItem value="document">{t('document')}</MenuItem>
					</Select>
				</FormControl>
			</Box>
			<Button
				variant="contained"
				sx={theme => ({
					width: '112px',
					[theme.breakpoints.down('sm')]: {
						width: '100%'
					}
				})}
				onClick={handleApplyFilters}
			>
				{t('to-search')}
			</Button>
		</Box>
	)
}
