import {useState} from 'react'
import {useQuery} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import dayjs from 'dayjs'
import {Theme} from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Skeleton from '@mui/material/Skeleton'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import {FaHome as HomeIcon} from 'react-icons/fa'

import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'
import {listSales, listSalesStatistics} from '../api/sales'
import AlertDialog from '../components/AlertDialog'
import Footer from '../components/template/Footer'
import Link from '../components/navigation/Link'
import {MetricsCard, MetricsCardSimple} from '../components/MetricsCard'
import SectionTitle from '../components/typography/SectionTitle'
import {Status} from '../components/Status'
import ButtonLink from '../components/navigation/ButtonLink'
import SalesMetrics from './metrics/SalesMetrics'
import {Card} from '../components/Card'
import useNavigate from '../hooks/useNavigate'
import {Stack} from '@mui/material'
import Price from '../components/Price'
import {SaleListItem} from '../stores/useSaleStates'
import {price} from '../helpers/price'
import {DashboardFullSection} from '../components/template/DashboardFullSection'

const convertDateToLocale = (locale: string, date: string) => {
	if (locale === 'pt-BR' || locale === 'pt-br' || locale === 'pt') {
		dayjs.locale('pt-br')
		return dayjs(date).format('DD/MM/YYYY')
	}

	dayjs.locale('en')
	return dayjs(date).format('MM/DD/YYYY')
}

const headCells: {
	id: string
	align: 'left' | 'right'
	label: string
	width?: number | string
	minWidth?: number
}[] = [
	{
		id: 'customer',
		align: 'left',
		label: 'customer',
		width: '100%',
		minWidth: 182
	},
	{id: 'experience', align: 'left', label: 'experience', minWidth: 185},
	{id: 'amount', align: 'left', label: 'amount', minWidth: 100},
	{id: 'status', align: 'left', label: 'status', minWidth: 78}
]

const TableRowsSkeleton = ({length}: {length: number}) => {
	const tableRows = []

	for (let i = 0; i < length; i++) {
		tableRows.push(
			<TableRow key={i}>
				<TableCell align="left">
					<Typography variant="body2">
						<Skeleton width={100} />
					</Typography>
					<Typography variant="caption" sx={style.headCell}>
						<Skeleton width={100} />
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography variant="body2">
						<Skeleton width={100} />
					</Typography>
					<Typography variant="caption" sx={style.headCell}>
						<Skeleton width={80} />
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography variant="body2">
						<Skeleton width={40} />
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Status.Root>
						<Skeleton width={80} height={32} />
					</Status.Root>
				</TableCell>
			</TableRow>
		)
	}

	return <>{tableRows}</>
}

interface TableRowEmptyProps {
	message: string
}

const TableRowEmpty = ({message}: TableRowEmptyProps) => {
	return (
		<TableRow>
			<TableCell colSpan={6} align="center">
				<Typography variant="body2" sx={{color: 'text.secondary'}}>
					{message}
				</Typography>
			</TableCell>
		</TableRow>
	)
}

const SaleMetrics = () => {
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [metrics, setMetrics] = useState<{
		revenue?: {
			allRevenue: number
			currentMonth: number
			lastMonth: number
		}
	}>()
	const [sales, setSales] = useState<SaleListItem[]>([])
	const {t} = useTranslation()
	const {sellerId} = useAuthStates(state => state)
	const currentLanguage = useAppStates(state => state.currentLang)

	useQuery(
		['sales-metrics-' + sellerId],
		() =>
			listSalesStatistics({
				fromDate: dayjs().subtract(1, 'year').toISOString(),
				toDate: dayjs().toISOString()
			}),
		{
			networkMode: 'always',
			onSuccess: result => {
				if (result?.responseStatusCode === 200 && result.data) {
					const currentMonth = result.data.revenueByMonth.find(
						item => item.month === dayjs().month() + 1
					)
					const lastMonth = result.data.revenueByMonth.find(
						item => item.month === dayjs().subtract(1, 'month').month() + 1
					)
					setMetrics({
						revenue: {
							allRevenue: result.data.totalRevenue,
							currentMonth: currentMonth?.revenue || 0,
							lastMonth: lastMonth?.revenue || 0
						}
					})
				} else {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
				}
			},
			onError: error => {
				setAlertMessage(t('error-server-default'))
				setOpenAlert(true)
				console.error(error)
			}
		}
	)

	const {isLoading} = useQuery(
		['last-5-sales' + sellerId],
		() =>
			listSales({
				offset: 0,
				limit: 5
			}),
		{
			networkMode: 'always',
			onSuccess: result => {
				if (result?.responseStatusCode === 200 && result?.data) {
					setSales(result.data.sales)
				} else {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
				}
			},
			onError: error => {
				setAlertMessage(t('error-server-default'))
				setOpenAlert(true)
				console.error(error)
			}
		}
	)

	return (
		<>
			<AlertDialog
				open={openAlert}
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
			/>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<span>Home</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<SectionTitle sx={{mb: 3}}>{t('dashboard')}</SectionTitle>
				<Box sx={style.container}>
					<Box sx={style.boxMetrics}>
						<Box className="hidden-scrollbar" sx={style.boxMetricsCards}>
							<MetricsCard.Root>
								<MetricsCard.Title>{t('total-revenue')}</MetricsCard.Title>
								<MetricsCard.Body sx={style.metricsCardBody.box}>
									<Box sx={style.metricsCardBody.revenueBox}>
										{metrics?.revenue ? (
											<>
												<Typography
													lineHeight="30px"
													color="success.main"
													mr={0.5}
												>
													R$
												</Typography>
												<Price
													value={metrics?.revenue?.allRevenue || 0}
													hiddenCurrencySymbol
													color="success.main"
													sx={{
														fontSize: '1.5rem',
														fontWeight: 500
													}}
												/>
											</>
										) : (
											<Skeleton width={120} height={34} />
										)}
									</Box>
								</MetricsCard.Body>
							</MetricsCard.Root>
							<MetricsCardSimple title={t('current-month')}>
								{metrics?.revenue ? (
									<Price
										variant="h6"
										value={metrics?.revenue?.currentMonth || 0}
										sx={{whiteSpace: 'nowrap', p: 1, textAlign: 'center'}}
									/>
								) : (
									<Skeleton width={120} height="1.5rem" />
								)}
							</MetricsCardSimple>
							<MetricsCardSimple title={t('last-month')}>
								{metrics?.revenue ? (
									<Price
										variant="h6"
										value={metrics?.revenue?.lastMonth || 0}
										sx={{whiteSpace: 'nowrap', p: 1, textAlign: 'center'}}
									/>
								) : (
									<Skeleton width={120} height="1.5rem" />
								)}
							</MetricsCardSimple>
						</Box>
						<SalesMetrics />
					</Box>
					<Paper variant="outlined" sx={{width: '100%', maxWidth: 558}}>
						<Typography variant="subtitle2" sx={{textAlign: 'center', p: 1}}>
							{t('last-sales')}
						</Typography>
						<Divider />
						<Box sx={{display: 'grid', flex: 1}}>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											{headCells.map(headCell => (
												<TableCell
													key={headCell.id}
													align={headCell.align}
													width={headCell.width}
													sx={{minWidth: headCell.minWidth}}
												>
													<Box sx={{display: 'flex', alignItems: 'center'}}>
														<Typography
															variant="body2"
															sx={[{fontWeight: 500}]}
														>
															{t(headCell.label)}
														</Typography>
													</Box>
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{isLoading && <TableRowsSkeleton length={5} />}
										{sales.length === 0 && !isLoading && (
											<TableRowEmpty message={t('no-sales-yet')} />
										)}
										{!isLoading &&
											sales.length > 0 &&
											sales.map(row => (
												<TableRow key={row.id}>
													<TableCell align="left">
														<Tooltip
															title={
																<>
																	{row?.buyer?.name}
																	<br />
																	{row?.buyer?.email}
																</>
															}
														>
															<Typography
																variant="body2"
																sx={style.truncateText}
															>
																{row?.buyer?.name}
															</Typography>
														</Tooltip>
														<Tooltip
															title={
																<>
																	{row?.buyer?.name}
																	<br />
																	{row?.buyer?.email}
																</>
															}
														>
															<Typography
																variant="caption"
																component="p"
																sx={style.headCell}
															>
																{convertDateToLocale(
																	currentLanguage,
																	row.createdAt
																)}
															</Typography>
														</Tooltip>
													</TableCell>
													<TableCell align="left">
														<Tooltip title={row.edition.experience.title}>
															<Typography
																variant="body2"
																sx={style.truncateText}
															>
																{row.edition.experience.title}
															</Typography>
														</Tooltip>
														<Tooltip title={row.edition.title}>
															<Typography
																variant="caption"
																component="p"
																sx={[style.headCell, style.truncateText]}
															>
																{isLoading ? (
																	<Skeleton width={230} />
																) : (
																	row.edition.title
																)}
															</Typography>
														</Tooltip>
													</TableCell>
													<TableCell align="left">
														<Typography variant="body2">
															{price.full(row.totalAmount)}
														</Typography>
													</TableCell>
													<TableCell align="center">
														<Tooltip
															title={t('status-tag-' + row.status)}
															placement="bottom"
															arrow
														>
															<Typography>
																<Status.Circle type={row.status} />
															</Typography>
														</Tooltip>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
						<Box>
							<ButtonLink to="/sales" sx={{width: '100%'}}>
								{t('show-more-sales')}
							</ButtonLink>
						</Box>
					</Paper>
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

const EmptyStateSection = () => {
	const {t} = useTranslation()

	const {onboarding} = useAuthStates(state => state)

	const navigate = useNavigate()

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<span>Home</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<SectionTitle sx={{mb: 3}}>{t('for-start-selling')}</SectionTitle>
				<Stack direction="column" spacing={2}>
					<Card.Root>
						<Card.Body grid>
							<Card.Info>
								<Card.Title variant="h6">
									{t('activate-a-payment-service')}
								</Card.Title>
								<Card.Describe>
									<Typography variant="body2" sx={{mt: 1}}>
										{t('info-activate-payment-service')}
									</Typography>
								</Card.Describe>
							</Card.Info>
							<Card.Button
								onClick={() => navigate('/payment-services')}
								withBorder
							>
								{t('to-activate-payment-service')}
							</Card.Button>
						</Card.Body>
					</Card.Root>
					{onboarding.experiences ? null : (
						<Card.Root>
							<Card.Body grid>
								<Card.Info>
									<Card.Title variant="h6">
										{t('create-your-first-product-or-service')}
									</Card.Title>
									<Card.Describe>
										<Typography variant="body2" sx={{mt: 1}}>
											{t('info-product-and-services')}
										</Typography>
									</Card.Describe>
								</Card.Info>
								<Card.Button
									onClick={() => navigate('/products/create')}
									withBorder
								>
									{t('to-create-product-or-service')}
								</Card.Button>
							</Card.Body>
						</Card.Root>
					)}
				</Stack>
			</DashboardFullSection>
		</>
	)
}

export default function Index() {
	const {paymentService} = useAuthStates(state => state)

	return paymentService?.activeService ? <SaleMetrics /> : <EmptyStateSection />
}

const style = {
	boxMetrics: {
		display: 'grid'
	},
	boxMetricsCards: {
		display: 'flex',
		gap: 3,
		mb: 4,
		overflowX: 'auto',
		maxWidth: '100%',
		scrollSnapType: 'x mandatory',
		'& > *': {
			scrollSnapAlign: 'center'
		},
		'::-webkit-scrollbar': {display: 'none'}
	},
	container: (theme: Theme) => ({
		alignItems: 'start',
		display: 'flex',
		gap: 4,
		width: '100%',
		[theme.breakpoints.down('lg')]: {
			flexDirection: 'column'
		}
	}),
	headCell: (theme: Theme) => ({
		color: theme.palette.grey[600]
	}),
	metricsCardBody: {
		box: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			gap: 1,
			mt: 0.5,
			height: '44px'
		},
		revenueBox: {display: 'flex', alignItems: 'flex-end'},
		revenueValue: {height: 34, lineHeight: 'normal'},
		tagBox: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			gap: 2
		}
	},
	truncateText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: 150
	}
}
