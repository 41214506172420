import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {regex} from '../helpers/regex'

const useUpdateCompanySchema = () => {
	const {t} = useTranslation()

	const updateCompanySchema = yup.object({
		cnpj: yup
			.string()
			.transform(value => regex.removeAllNonDigits(value))
			.matches(/^\d{14}$/, t('error-cnpj-invalid')),
		legalName: yup
			.string()
			.min(3, t('error-legal-name-min-length'))
			.max(60, t('error-legal-name-max-length')),
		fantasyName: yup
			.string()
			.min(3, t('error-fantasy-name-min-length'))
			.max(60, t('error-fantasy-name-max-length')),
		email: yup
			.string()
			.matches(
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
				t('error-email-invalid')
			)
			.min(5, t('error-email-min-length'))
			.max(200, t('error-email-max-length')),
		phone: yup
			.string()
			.transform(value => regex.removeAllNonDigits(value))
			.matches(/^\d{10,11}$/, t('error-phone-invalid')),
		address: yup
			.string()
			.min(3, t('error-address-min-length'))
			.max(255, t('error-address-max-length')),
		streetNumber: yup.string().required(t('error-street-number-required')),
		complementaryAddress: yup
			.string()
			.notRequired()
			.max(
				255,
				t('error-complementary-address-max-length', {
					maxLength: 255
				})
			),
		referencePoint: yup
			.string()
			.notRequired()
			.max(
				255,
				t('error-complementary-address-max-length', {
					maxLength: 255
				})
			),
		district: yup
			.string()
			.min(3, t('error-district-min-length'))
			.max(60, t('error-district-max-length')),
		city: yup
			.string()
			.min(3, t('error-city-min-length'))
			.max(60, t('error-city-max-length')),
		state: yup
			.string()
			.required(t('error-state-required'))
			.matches(/^[A-Z]{2,2}$/, t('error-state-invalid')),
		zipCode: yup
			.string()
			.transform(value => regex.removeAllNonDigits(value))
			.matches(/^\d{8}$/, t('error-zip-code-invalid'))
	})
	return updateCompanySchema
}

export default useUpdateCompanySchema
