import {useTranslation} from 'react-i18next'
import {Theme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import {Typography} from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import {FaHome as HomeIcon} from 'react-icons/fa'

import Footer from '../components/template/Footer'
import Link from '../components/navigation/Link'
import SectionTitle from '../components/typography/SectionTitle'
import PagarmeFeesCalculator from '../components/PagarmeFeesCalculator/PagarmeFeesCalculator'
import {DashboardFullSection} from '../components/template/DashboardFullSection'

const FeesCalculator = () => {
	const {t} = useTranslation()

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<span>{t('fees-calculator')}</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<Box sx={styles.main}>
					<SectionTitle sx={{mb: 2}}>{t('fees-calculator')}</SectionTitle>
					<Typography sx={{mb: 4}}>
						{t('fees-calculator-description')}
					</Typography>
					<PagarmeFeesCalculator />
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

const styles = {
	main: (theme: Theme) => ({
		mx: 'auto',
		mt: 0,
		width: '100%',
		p: 0
	})
}

export default FeesCalculator
