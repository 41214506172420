import {Dialog} from '../../Dialog'
import {useTranslation} from 'react-i18next'
import SwitchControl from '../../SwitchControl'
import {useState} from 'react'
import {
	Box,
	Button,
	Card,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	IconButton,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import Tag from '../../typography/Tag'
import {MdOutlineVisibility as Visibility} from 'react-icons/md'
import {MdOutlineVisibilityOff as VisibilityOff} from 'react-icons/md'
import {useMutation} from '@tanstack/react-query'
import {createWebhook} from '../../../api/webhooks'
import {useFormik} from 'formik'
import * as yup from 'yup'
import AlertMessage from '../../AlertMessage'
import {SaleEventsDoc} from './SaleEventsDoc'

type Webhook = {
	id: string
	name: string
	url: string
	status: string
	authenticationToken?: string
	events: {
		sales: ('SALE_CREATED' | 'SALE_UPDATED')[]
	}
}

export const AddWebhookForm = ({
	productId,
	open,
	onAdd,
	onClose
}: {
	productId: string
	open: boolean
	onClose: () => void
	onAdd: (webhook: Webhook) => void
}) => {
	const {t} = useTranslation()

	const [tokenVisible, setTokenVisible] = useState<boolean>(false)
	const [webhookActivated, setWebhookActivated] = useState(true)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [openAlert, setOpenAlert] = useState(false)
	const [openSaleEventsDoc, setOpenSaleEventsDoc] = useState(false)

	const {isLoading, mutate} = useMutation(createWebhook, {
		networkMode: 'always'
	})

	const validationSchema = yup.object({
		name: yup
			.string()
			.required(t('error-webhook-name-required'))
			.max(60, t('error-webhook-name-max-length'))
			.min(3, t('error-webhook-name-min-length')),
		url: yup
			.string()
			.url(t('error-url-invalid'))
			.required(t('error-url-required')),
		authenticationToken: yup.string().optional(),
		events: yup
			.object({
				SALE_CREATED: yup.boolean(),
				SALE_UPDATED: yup.boolean()
			})
			.test('at-least-one-event', t('error-webhook-events-required'), value => {
				return !!value.SALE_CREATED || !!value.SALE_UPDATED
			})
	})

	const formik = useFormik({
		initialValues: {
			name: '',
			url: '',
			authenticationToken: '',
			events: {
				SALE_CREATED: false,
				SALE_UPDATED: false
			}
		},
		validationSchema,
		onSubmit: async values => {
			mutate(
				{
					productId,
					name: values.name,
					url: values.url,
					status: webhookActivated ? 'on' : 'off',
					authenticationToken: values.authenticationToken,
					events: {
						sales: Object.entries(values.events)
							.filter(([key, value]) => key.startsWith('SALE') && value)
							.map(([key]) => key)
					}
				},
				{
					onSuccess: ({data, responseStatusCode, error}) => {
						if (responseStatusCode === 201 && data) {
							onAdd({
								id: data.id,
								name: values.name,
								url: values.url,
								events: {
									sales: Object.entries(values.events)
										.filter(([key, enabled]) => enabled)
										.map(([key]) => key as 'SALE_CREATED' | 'SALE_UPDATED')
								},
								status: webhookActivated ? 'on' : 'off',
								authenticationToken: values.authenticationToken
							})
							handleClose()
						} else {
							setAlertMessage(error?.message || t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		}
	})

	const handleClose = () => {
		formik.resetForm()
		onClose()
	}

	return (
		<>
			<SaleEventsDoc
				open={openSaleEventsDoc}
				onClose={() => setOpenSaleEventsDoc(false)}
			/>
			<Dialog.Root open={open}>
				<Dialog.Header>
					<Dialog.Title onClose={onClose}>{t('add-webhook')}</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body>
					<Stack spacing={3} pb={2}>
						{openAlert && (
							<AlertMessage
								message={alertMessage}
								severity="error"
								open={openAlert}
								onClose={() => setOpenAlert(false)}
								mt={0}
							/>
						)}
						<SwitchControl
							label={
								webhookActivated
									? t('webhook-activated')
									: t('webhook-deactivated')
							}
							checked={webhookActivated}
							onChange={() => setWebhookActivated(!webhookActivated)}
							size="medium"
						/>
						<TextField
							name="name"
							label={t('webhook-name')}
							size="small"
							sx={{mb: '8px !important'}}
							value={formik.values.name}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<TextField
							name="url"
							label={t('webhook-url')}
							size="small"
							value={formik.values.url}
							error={formik.touched.url && Boolean(formik.errors.url)}
							helperText={
								formik.touched.url && Boolean(formik.errors.url)
									? formik.errors.url
									: t('webhook-url-helper-text')
							}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<TextField
							name="authenticationToken"
							label={t('webhook-authentication-token')}
							size="small"
							type={tokenVisible ? 'text' : 'password'}
							value={formik.values.authenticationToken}
							error={
								formik.touched.authenticationToken &&
								Boolean(formik.errors.authenticationToken)
							}
							helperText={
								formik.touched.authenticationToken &&
								Boolean(formik.errors.authenticationToken) ? (
									formik.errors.authenticationToken
								) : (
									<>
										<Tag sx={{display: 'inline'}}>{t('optional')}</Tag>{' '}
										{t('webhook-authentication-token-helper-text')}{' '}
										{/* <TextButton variant="caption">{t('learn-more')}</TextButton> */}
									</>
								)
							}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							InputProps={{
								endAdornment: (
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setTokenVisible(prev => !prev)}
										edge="end"
									>
										{tokenVisible ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								)
							}}
						/>
						<Box>
							<Typography variant="h6" component="h2">
								{t('add-events')}
							</Typography>
							<Card variant="outlined" sx={{mt: 1}}>
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										alignItems: 'center',
										justifyContent: 'space-between',
										gap: 2,
										py: 1,
										px: 2,
										borderBottom: '1px solid',
										borderColor: 'divider'
									}}
								>
									<Typography variant="subtitle1">{t('sales')}</Typography>
									<FormControlLabel
										sx={{marginRight: 0}}
										control={
											<Checkbox
												sx={theme => ({
													color: theme.palette.grey[500],
													py: 0
												})}
												name="saleEventsAllSelected"
												checked={Object.values(formik.values.events).every(
													Boolean
												)}
												onChange={event => {
													formik.setFieldValue('events', {
														SALE_CREATED: event.target.checked,
														SALE_UPDATED: event.target.checked
													})
												}}
											/>
										}
										label={<Typography>{t('select-all')}</Typography>}
									/>
								</Box>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 2,
										p: 2
									}}
								>
									<Typography>
										{t('webhook-events-description')}{' '}
										<TextButton onClick={() => setOpenSaleEventsDoc(true)}>
											{t('webhook-sale-events')}
										</TextButton>
									</Typography>
									<FormControl>
										<FormControlLabel
											control={
												<Checkbox
													sx={theme => ({
														color: theme.palette.grey[500],
														py: 0
													})}
													name="saleEventNew"
													checked={formik.values.events.SALE_CREATED}
													onChange={event => {
														formik.setFieldValue('events', {
															...formik.values.events,
															SALE_CREATED: event.target.checked
														})
													}}
												/>
											}
											label={
												<Typography>{t('webhook-sale-event-new')}</Typography>
											}
										/>
										<FormHelperText sx={{marginLeft: '30px'}}>
											{t('webhook-sale-event-new-helper-text')}
										</FormHelperText>
									</FormControl>
									<FormControl>
										<FormControlLabel
											control={
												<Checkbox
													sx={theme => ({
														color: theme.palette.grey[500],
														py: 0
													})}
													name="saleEventUpdate"
													checked={formik.values.events.SALE_UPDATED}
													onChange={event => {
														formik.setFieldValue('events', {
															...formik.values.events,
															SALE_UPDATED: event.target.checked
														})
													}}
												/>
											}
											label={
												<Typography>
													{t('webhook-sale-event-update')}
												</Typography>
											}
										/>
										<FormHelperText sx={{marginLeft: '30px'}}>
											{t('webhook-sale-event-update-helper-text')}
										</FormHelperText>
									</FormControl>
								</Box>
							</Card>
						</Box>
					</Stack>
				</Dialog.Body>
				<Dialog.Footer>
					<Button onClick={onClose}>{t('cancel')}</Button>
					<Button
						variant="contained"
						onClick={formik.submitForm}
						disabled={isLoading}
						sx={{
							width: 190
						}}
					>
						{isLoading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							t('to-add-webhook')
						)}
					</Button>
				</Dialog.Footer>
			</Dialog.Root>
		</>
	)
}

const TextButton = ({
	children,
	variant,
	...props
}: {
	children: React.ReactNode
	variant?: 'h6' | 'body1' | 'body2' | 'subtitle1' | 'subtitle2' | 'caption'
	onClick?: () => void
}) => (
	<Typography
		variant={variant}
		fontWeight="bold"
		color="primary"
		onClick={() => console.log('clicked')} // TODO: Add onClick handler
		sx={{
			cursor: 'pointer',
			display: 'inline',
			'&:hover': {color: 'primary.dark'}
		}}
		{...props}
	>
		{children}
	</Typography>
)
