import useAppStates from '../stores/useAppStates'
import {DEFAULT_LANG, SELLER_AUTH_API} from '../config'
import {Onboarding} from '../entities/Onboarding'
import {Company} from '../entities/Company'
interface Message {
	message: string
}
interface SignUpResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		required?: string[]
		fields?: {
			password?: Message[]
			email?: Message[]
		}
	}
	responseStatusCode?: number
}

export const signUp = async (values: {
	email: string
	password: string
}): Promise<SignUpResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const response = await fetch(`${SELLER_AUTH_API}/signup`, {
		method: 'POST',
		body: JSON.stringify(values),
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Content-Type': 'application/json'
		}
	})
	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface CheckEmailResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			email: Message[]
		}
	}
	responseStatusCode?: number
}

export const checkEmail = async (value: {
	email: string
}): Promise<CheckEmailResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const response = await fetch(`${SELLER_AUTH_API}/check-email`, {
		method: 'POST',
		body: JSON.stringify(value),
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Content-Type': 'application/json'
		}
	})
	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface CodeValidateResponse {
	success?: {
		message: string
	}
	data?: {
		accessToken: string
		refreshToken: string
		sellerId: string
		email: string
		onboarding: Onboarding
		profile: null
		company: Company | null
		bankAccount: null
		paymentService: null
	}
	error?: {
		message: string
		fields?: {
			email?: Message[]
			code?: Message[]
		}
	}
	responseStatusCode?: number
}
export const codeValidate = async (value: {
	code: string
	email: string
}): Promise<CodeValidateResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const response = await fetch(`${SELLER_AUTH_API}/signup/code-validate`, {
		method: 'POST',
		body: JSON.stringify(value),
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG
		},
		credentials: 'include'
	})
	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface SendCodeValidateResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const sendCodeValidate = async (values: {
	email: string
}): Promise<SendCodeValidateResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const response = await fetch(`${SELLER_AUTH_API}/signup/code-validate/send`, {
		method: 'POST',
		body: JSON.stringify(values),
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG
		}
	})
	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}
