import {useMemo} from 'react'
import {ReactComponent as Logo} from '../../assets/images/pagarme-logo.svg'
import {styled} from '@mui/system'

const LogoComponent = styled(Logo)``

export const PagarmeLogo = ({height = 37}: {height?: string | number}) => {
	const width = useMemo(() => {
		if (height) {
			return (125 * Number(height)) / 37
		}

		return 125
	}, [height])

	return (
		<LogoComponent
			sx={theme => ({
				height,
				width,
				'& path.typo': {
					fill:
						theme.palette.mode === 'light'
							? '#000 !important'
							: '#fff !important'
				},
				'& g rect, g path': {
					fill: 'inherit'
				}
			})}
		/>
	)
}
