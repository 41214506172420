import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'
import {Company, CompanyManagingPartner} from '../entities/Company'
import {DEFAULT_LANG, SELLER_API} from '../config'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import {regex} from '../helpers/regex'
import {
	RapidApiCompanyResponse,
	WSCompanyResponse,
	companyMapper
} from './mappers/companyMapper'

interface Message {
	message: string
}

interface UpdateCompanyManagingPartnerResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	data?: CompanyManagingPartner
	responseStatusCode?: number
}

export const updateCompanyManagingPartner = async (values: {
	name: string
	email: string
	cpf: string
	birthdate: string
	monthlyIncome: number
	professionalOccupation: string
	selfDeclaredLegalRepresentative: boolean
	phone: string
	phoneType: string
	street: string
	streetNumber: number
	district: string
	city: string
	state: string
	zipCode: string
	complementaryAddress: string
	referencePoint: string
}): Promise<UpdateCompanyManagingPartnerResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_API}/company/managing-partner`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface ListCompanyResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	data?: Company
	responseStatusCode?: number
}

export const listCompany = async (): Promise<ListCompanyResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${SELLER_API}/company`, {
		method: 'GET',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		}
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface SearchCompanyResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			email: Message[]
		}
	}
	data?: Company
	responseStatusCode?: number
}

export const searchCompany = async (value: {
	cnpj: string
}): Promise<SearchCompanyResponse> => {
	const cnpj = regex.removeAllNonDigits(value.cnpj)

	let response = await searchCompanyOnWS(cnpj)
	if (
		response.responseStatusCode !== 200 && // Company found
		response.responseStatusCode !== 400 && // CNPJ not found
		response.responseStatusCode !== 401 // Company is not active
	) {
		response = await searchCompanyOnRapidAPI(cnpj)
	}

	return response
}

const searchCompanyOnWS = async (
	cnpj: string
): Promise<ListCompanyResponse> => {
	const controller = new AbortController()
	const timeout = setTimeout(() => {
		controller.abort()
	}, 3000) // Abort after 3 seconds

	try {
		const response = await fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`, {
			method: 'GET'
		})
		clearTimeout(timeout) // Clear the timeout if the request completes successfully

		if (response.status === 200) {
			const data: WSCompanyResponse = await response.json()

			const status = data.estabelecimento.situacao_cadastral.toLowerCase()

			if (status !== 'ativa') {
				return {
					error: {
						message: 'Company is not active'
					},
					responseStatusCode: 401
				}
			}

			const company = companyMapper.wsToDomain(data)

			return {
				success: {
					message: 'Company found'
				},
				data: company,
				responseStatusCode: 200
			}
		}

		if (response.status === 400) {
			return {
				error: {
					message: 'CNPJ not found'
				},
				responseStatusCode: 400
			}
		}

		if (response.status === 429) {
			return {
				error: {
					message: 'Too many requests'
				},
				responseStatusCode: 429
			}
		}

		return {
			error: {
				message: 'Internal server error'
			},
			responseStatusCode: 500
		}
	} catch (error) {
		const response = {
			error: {
				message: 'Internal server error'
			},
			responseStatusCode: 500
		}
		return response
	}
}

const searchCompanyOnRapidAPI = async (
	cnpj: string
): Promise<ListCompanyResponse> => {
	const controller = new AbortController()
	const timeout = setTimeout(() => {
		controller.abort()
	}, 3000) // Abort after 3 seconds

	try {
		const response = await fetch(
			`https://consulta-cnpj-gratis.p.rapidapi.com/office/${cnpj}?simples=true`,
			{
				method: 'GET',
				signal: controller.signal,
				headers: {
					'X-RapidAPI-Key': '967bc6afebmsh1d4ca42f1c0adbbp1e9b9ajsn13c9671cab4d'
				}
			}
		)
		clearTimeout(timeout) // Clear the timeout if the request completes successfully

		const data: RapidApiCompanyResponse = await response.json()

		if (response.status === 200) {
			const status = data.status.text.toLowerCase()

			if (status !== 'ativa') {
				const response = {
					error: {
						message: 'Company is not active'
					},
					responseStatusCode: 401
				}

				return response
			}

			const company = companyMapper.rapidApiToDomain(data)

			const response = {
				success: {
					message: 'Company found'
				},
				data: company,
				responseStatusCode: 200
			}

			return response
		}

		if (response.status === 400) {
			const response = {
				error: {
					message: 'CNPJ not found'
				},
				responseStatusCode: 400
			}

			return response
		}

		if (response.status === 429) {
			const response = {
				error: {
					message: 'Too many requests'
				},
				responseStatusCode: 429
			}

			return response
		}

		return {
			error: {
				message: 'Internal server error'
			},
			responseStatusCode: 500
		}
	} catch (error) {
		const response = {
			error: {
				message: 'Internal server error'
			},
			responseStatusCode: 500
		}
		return response
	}
}

interface UpdateCompanyResponse {
	data: Company
	error?: {
		message: string
		fields?: {
			cnpj?: Message[]
			legalName?: Message[]
			fantasyName?: Message[]
			email?: Message[]
			phone?: Message[]
			address?: Message[]
			district?: Message[]
			city?: Message[]
			state?: Message[]
			zipCode?: Message[]
		}
	}
	responseStatusCode?: number
}

export const updateCompany = async (rawValues: {
	cnpj: string
	legalName: string
	fantasyName: string
	email: string
	phone: string
	address: string
	district: string
	streetNumber: number
	complementaryAddress: string
	city: string
	state: string
	zipCode: string
	referencePoint: string
}): Promise<UpdateCompanyResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const values = companyMapper.domainToApi(rawValues)

	const response = await authenticatedFetch(`${SELLER_API}/company`, {
		method: 'PUT',
		body: JSON.stringify(values),
		// credentials: 'include',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		}
	})

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}
