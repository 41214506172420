import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import {FaHome as HomeIcon} from 'react-icons/fa'
import {FaFilter as FilterIcon} from 'react-icons/fa6'

import {Box, Breadcrumbs, Button, Container} from '@mui/material'

import {listSales} from '../api/sales'
import {regex} from '../helpers/regex'
import AlertDialog from '../components/AlertDialog'
import Footer from '../components/template/Footer'
import Link from '../components/navigation/Link'
import SectionTitle from '../components/typography/SectionTitle'
import SalesList from '../components/lists/sales/SalesList'
import useSaleStates from '../stores/useSaleStates'
import {DashboardFullSection} from '../components/template/DashboardFullSection'
import {useSearchParams} from 'react-router-dom'
import {FilterDialog} from '../components/lists/sales/FilterDialog'

export type SaleFilters = {
	productId?: string
	status?: string
	paymentService?: {
		pagarme?: boolean
		asaas?: boolean
	}
	paymentMethod?: {
		creditCard?: boolean
		bankSlip?: boolean
		pix?: boolean
	}
	fromDate?: string
	toDate?: string
}

const Sales = () => {
	const [searchParams] = useSearchParams()
	const [openAlert, setOpenAlert] = useState(false)
	const [firstLoad, setFirstLoad] = useState(true)
	const [alertMessage, setAlertMessage] = useState('')
	const [page, setPage] = useState(0)
	const [offset, setOffset] = useState(0)
	const {listLimit: limit, setListLimit: setLimit} = useSaleStates()
	const [filters, setFilters] = useState<SaleFilters>()
	const {t} = useTranslation()
	const {setSalesList, setTotal} = useSaleStates()
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const openFilters = Boolean(anchorEl)

	const handleOpenFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseFilters = () => {
		setAnchorEl(null)
	}

	const {isLoading, mutate} = useMutation(listSales, {
		networkMode: 'always'
	})

	const handleChangeURL = (page: number) => {
		const urlParams = new URLSearchParams(window.location.search)
		urlParams.set('page', page.toString())
		const newURL = `${window.location.pathname}?${urlParams.toString()}`
		window.history.replaceState(undefined, 'Dashboard | Excofy', newURL)
	}

	const fetchSales = useCallback(
		async () =>
			mutate(
				{
					offset,
					limit,
					filters: filters ? regex.parseObjectToQueryString(filters) : undefined
				},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							if (firstLoad) {
								setFirstLoad(false)
							}

							setTotal(
								offset === 0
									? result.data.sales.length
									: result.data.sales.length + offset
							)

							if (offset !== 0 && result.data.sales.length === 0) {
								const newOffset = offset - limit
								setOffset(newOffset)
								setPage(newOffset / limit)
							} else {
								setSalesList(result.data.sales)
								setPage(offset / limit)
								handleChangeURL(offset / limit)
							}
						} else {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[offset, limit, filters]
	)

	const countFilers = useMemo(() => {
		return Object.entries(filters || {}).filter(([key, value]) => {
			if (
				key === 'offset' ||
				key === 'limit' ||
				value === undefined ||
				value === ''
			) {
				return false
			}

			if (typeof value === 'object') {
				return Object.values(value).some(v => v)
			}

			return true
		}).length
	}, [filters])

	useEffect(() => {
		const currentPage = searchParams.get('page')
		const paymentService = searchParams.get('payment_service')
		const paymentMethod = searchParams.get('payment_method')
		const status = searchParams.get('status')
		const fromDate = searchParams.get('from_date')
		const toDate = searchParams.get('to_date')

		if (currentPage) {
			setOffset(+currentPage * limit)
		}

		if (paymentService) {
			setFilters(prev => ({
				...prev,
				paymentService: {
					pagarme: paymentService.includes('pagarme'),
					asaas: paymentService.includes('asaas')
				}
			}))
		}

		if (paymentMethod) {
			setFilters(prev => ({
				...prev,
				paymentMethod: {
					creditCard: paymentMethod.includes('creditCard'),
					bankSlip: paymentMethod.includes('bankSlip'),
					pix: paymentMethod.includes('pix')
				}
			}))
		}

		if (status) {
			setFilters(prev => ({
				...prev,
				status
			}))
		}

		if (fromDate) {
			setFilters(prev => ({...prev, fromDate}))
		}

		if (toDate) {
			setFilters(prev => ({...prev, toDate}))
		}
	}, [searchParams, limit])

	useEffect(() => {
		setFirstLoad(false)
	}, [])

	useEffect(() => {
		if (!firstLoad) {
			fetchSales()
		}
	}, [fetchSales, firstLoad])

	useEffect(() => {}, [filters])

	return (
		<>
			<FilterDialog
				open={openFilters}
				anchorEl={anchorEl}
				filters={filters}
				onClose={handleCloseFilters}
				onApplyFilters={setFilters}
			/>
			<AlertDialog
				open={openAlert}
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
			/>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<span>{t('sales')}</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: 2,
							flexWrap: 'wrap',
							width: '100%',
							mb: 3
						}}
					>
						<SectionTitle>{t('sales')}</SectionTitle>
						<Button
							aria-describedby={'sales-filters-popover'}
							variant="outlined"
							startIcon={<FilterIcon />}
							size="small"
							onClick={handleOpenFilters}
						>
							{countFilers > 0 ? `(${countFilers}) ` : ''}
							{t('filters')}
						</Button>
					</Box>
					<SalesList
						offset={offset}
						limit={limit}
						isLoading={isLoading}
						firstLoad={firstLoad}
						page={page}
						filters={filters}
						setOffset={setOffset}
						setLimit={setLimit}
						setFilters={setFilters}
						setPage={setPage}
						handleChangeURL={handleChangeURL}
					/>
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

export default Sales
