import * as yup from 'yup'

import {useTranslation} from 'react-i18next'
import {image} from '../helpers/image'
import {IMAGE} from '../config'

const useProfileImageSchema = () => {
	const {t} = useTranslation()

	const profileImageSchema = {
		logoLight: yup
			.mixed()
			.test(
				'fileType',
				t('error-image-type-logo'),
				file => file && image.validate.logoType({file})
			)
			.test(
				'fileSize',
				t('error-image-max-size'),
				file => file && image.validate.size({file})
			)
			.test(
				'fileMinDimensions',
				t('error-image-min-dimensions', {
					minHeight: IMAGE.dimensions.min.height,
					minWidth: IMAGE.dimensions.min.width
				}),
				file => file && image.validate.minDimensions({file})
			)
			.test(
				'fileMaxDimensions',
				t('error-image-max-dimensions', {
					maxHeight: IMAGE.dimensions.max.height,
					maxWidth: IMAGE.dimensions.max.width
				}),
				file => file && image.validate.maxDimensions({file})
			)
			.test(
				'aspectRatio',
				t('error-image-aspect-ratio', {
					aspectRatio: IMAGE.aspectRatio
				}),
				file => file && image.validate.aspectRatio({file})
			),
		logoDark: yup
			.mixed()
			.test('fileFormat', t('error-image-type-logo'), value => {
				return !value || (value && ['image/png'].includes(value.type))
			})
			.test(
				'fileType',
				t('error-image-type-logo'),
				file => file && image.validate.logoType({file})
			)
			.test(
				'fileSize',
				t('error-image-max-size'),
				file => file && image.validate.size({file})
			)
			.test(
				'fileMinDimensions',
				t('error-image-min-dimensions', {
					minHeight: IMAGE.dimensions.min.height,
					minWidth: IMAGE.dimensions.min.width
				}),
				file => file && image.validate.minDimensions({file})
			)
			.test(
				'fileMaxDimensions',
				t('error-image-max-dimensions', {
					maxHeight: IMAGE.dimensions.max.height,
					maxWidth: IMAGE.dimensions.max.width
				}),
				file => file && image.validate.maxDimensions({file})
			)
			.test(
				'aspectRatio',
				t('error-image-aspect-ratio', {
					aspectRatio: IMAGE.aspectRatio
				}),
				file => file && image.validate.aspectRatio({file})
			),
		avatar: yup
			.mixed()
			.test('fileType', t('error-image-type-avatar'), file => {
				return file && image.validate.avatarType({file})
			})
			.test(
				'fileSize',
				t('error-image-max-size'),
				file => file && image.validate.size({file})
			)
			.test(
				'fileMinDimensions',
				t('error-image-min-dimensions', {
					minHeight: IMAGE.dimensions.min.height,
					minWidth: IMAGE.dimensions.min.width
				}),
				file => file && image.validate.minDimensions({file})
			)
			.test(
				'fileMaxDimensions',
				t('error-image-max-dimensions', {
					maxHeight: IMAGE.dimensions.max.height,
					maxWidth: IMAGE.dimensions.max.width
				}),
				file => file && image.validate.maxDimensions({file})
			)
			.test(
				'aspectRatio',
				t('error-image-aspect-ratio', {
					aspectRatio: IMAGE.aspectRatio
				}),
				file => file && image.validate.aspectRatio({file})
			)
	}

	return profileImageSchema
}

export default useProfileImageSchema
