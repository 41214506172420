import {Box, Button, Card, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {Status} from '../Status'
import {FacebookPixelForm} from './forms/FacebookPixelForm'
import {useState} from 'react'
import {Dialog} from '../Dialog'
import {RiArrowDownSFill as ArrowDropDownIcon} from 'react-icons/ri'
import {useMutation} from '@tanstack/react-query'
import {changeIntegrationStatus} from '../../api/integrations'
import AlertDialog from '../AlertDialog'
import {CademiForm} from './forms/CademiForm'
import {UpsellForm} from './forms/UpsellForm'
import {OrderBumpForm} from './forms/OrderBumpForm'
import {TheMembersForm} from './forms/TheMembersForm'
import {WhatsAppCheckoutForm} from './forms/WhatsAppCheckoutForm'
import {WebhooksForm} from './webhooks/WebhooksForm'

interface IntegrationCardProps {
	id: string
	metadata?: string
	name: string
	summary: string
	icon: string
	category: string
	status: string
	experienceId: string
}

export const ExperienceIntegrationCard = ({
	id,
	metadata: initialMetadata,
	name,
	summary,
	icon,
	category,
	status: initialStatus,
	experienceId
}: IntegrationCardProps) => {
	const {t} = useTranslation()

	const [openStatusList, setOpenStatusList] = useState(false)
	const [anchorElStatusList, setAnchorElStatusList] =
		useState<null | HTMLElement>(null)
	const [errorMessage, setErrorMessage] = useState<string>('')
	const [openAlertError, setOpenAlertError] = useState<boolean>(false)
	const [openSettings, setOpenSettings] = useState(false)
	const [status, setStatus] = useState(initialStatus)
	const [metadata, setMetadata] = useState(initialMetadata)

	const handleCloseSettingsModal = () => {
		setOpenSettings(false)
	}

	const handleOpenSettingsModal = () => {
		setOpenSettings(true)
	}

	const handleUpdate = (data: {
		id: string
		status: string
		metadata?: string
	}) => {
		setStatus(data.status)
		setMetadata(data.metadata)
	}

	const {mutate} = useMutation(changeIntegrationStatus, {
		networkMode: 'always'
	})

	const handleClickStatusChange = (newStatus: string) => {
		setStatus(newStatus)
		mutate(
			{id, status: newStatus},
			{
				onSuccess: ({error, responseStatusCode}) => {
					if (responseStatusCode !== 200) {
						setStatus(status)
					} else if (error) {
						setErrorMessage(t('error-server-default'))
						setOpenAlertError(true)
					}
				},
				onError: () => {
					setErrorMessage(t('error-server-default'))
					setOpenAlertError(true)
				}
			}
		)
	}

	const formComponent = {
		'meta-pixel': (
			<FacebookPixelForm id={id} onUpdate={handleUpdate} metadata={metadata} />
		),
		'cademi': (
			<CademiForm id={id} onUpdate={handleUpdate} metadata={metadata} />
		),
		'the-members': (
			<TheMembersForm id={id} onUpdate={handleUpdate} metadata={metadata} />
		),
		'upsell': (
			<UpsellForm id={id} onUpdate={handleUpdate} metadata={metadata} />
		),
		'order-bump': (
			<OrderBumpForm
				onChangeStatus={handleClickStatusChange}
				status={status}
				productId={experienceId}
			/>
		),
		'whatsapp-checkout': (
			<WhatsAppCheckoutForm
				id={id}
				onUpdate={handleUpdate}
				metadata={metadata}
			/>
		),
		'webhooks': (
			<WebhooksForm
				id={id}
				productId={experienceId}
				status={status}
				metadata={metadata}
				onChangeStatus={handleClickStatusChange}
				onUpdate={handleUpdate}
			/>
		)
	}

	return (
		<Box>
			<AlertDialog
				open={openAlertError}
				severity="error"
				message={errorMessage}
				onClose={() => setOpenAlertError(false)}
			/>
			<Dialog.Root open={openSettings}>
				<Dialog.Header>
					<Dialog.Title onClose={handleCloseSettingsModal}>
						{t('settings')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body>
					<Box
						sx={{
							width: '100%'
						}}
					>
						{formComponent[name as keyof typeof formComponent]}
					</Box>
				</Dialog.Body>
			</Dialog.Root>
			<Card
				variant="outlined"
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					p: 2,
					height: '100%'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						width: '100%',
						height: '100%'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: 1.5
						}}
					>
						{status === 'pending-setup' ? (
							<Status.Tag type={status} size="large">
								<Status.Label
									label={t('status-tag-' + status)}
									maxWidth="auto"
								/>
							</Status.Tag>
						) : (
							<Status.Root
								isButton
								open={openStatusList}
								setAnchorEl={setAnchorElStatusList}
								onClick={() => setOpenStatusList(!openStatusList)}
							>
								<Status.Tag type={status}>
									<Status.Label
										label={t('status-tag-' + status)}
										showTooltip={false}
									/>
									<Status.Icon>
										<ArrowDropDownIcon />
									</Status.Icon>
									<Status.List
										anchorEl={anchorElStatusList}
										open={openStatusList}
										acceptedStatus={[
											{label: t('to-activate'), key: 'active'},
											{label: t('to-off'), key: 'off'}
										].filter(currentStatus => currentStatus.key !== status)}
										handleClick={handleClickStatusChange}
										handleClose={() => setAnchorElStatusList(null)}
									/>
								</Status.Tag>
							</Status.Root>
						)}
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							gap: 3,
							height: '100%'
						}}
					>
						<Box>
							<Box
								sx={{
									display: 'flex',
									gap: 1,
									alignItems: 'center'
								}}
							>
								<Box
									sx={{
										height: 40,
										width: 40
									}}
								>
									<img
										src={icon}
										alt={name}
										height={40}
										width={40}
										style={{
											objectFit: 'contain',
											borderRadius: '8px'
										}}
									/>
								</Box>
								<Typography variant="h6">{t('integration-' + name)}</Typography>
							</Box>
							<Typography mt={1} variant="body2">
								{summary}
							</Typography>
						</Box>
						<Button
							variant="outlined"
							fullWidth
							onClick={handleOpenSettingsModal}
						>
							{t('to-configure')}
						</Button>
					</Box>
				</Box>
			</Card>
		</Box>
	)
}
