import {DEFAULT_LANG, SELLER_SALES_API} from '../config'
import {SaleDetails} from '../entities/Sale'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import {regex} from '../helpers/regex'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'
import {
	SaleIntegration,
	SaleListItem,
	SaleWebhookEvent
} from '../stores/useSaleStates'

interface Message {
	message: string
}

interface GetSaleByIdResponse {
	success?: {
		message: string
	}
	error?: Message
	data?: {
		sale: SaleDetails
		items: {
			id: string
			offerId: string
			title: string
			cover?: string
			quantity: number
			unitPrice: number
			remainingVacancies?: number
		}[]
	}
	responseStatusCode?: number
}

export const getSaleById = async ({
	saleId
}: {
	saleId: string
}): Promise<GetSaleByIdResponse> => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${SELLER_SALES_API}/${saleId}`, {
		method: 'GET',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'X-Timezone-Offset': timezoneOffset,
			Authorization: `Bearer ${accessToken}`
		}
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
interface GetSaleIntegrationsByIdResponse {
	success?: {
		message: string
	}
	error?: Message
	data?: {
		cademi: SaleIntegration[]
		'the-members': SaleIntegration[]
	}
	responseStatusCode?: number
}

export const getSaleIntegrationsById = async ({
	saleId
}: {
	saleId: string
}): Promise<GetSaleIntegrationsByIdResponse> => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_SALES_API}/${saleId}/integrations`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'X-Timezone-Offset': timezoneOffset,
				Authorization: `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface GetSaleWebhookEventsResponse {
	success?: {
		message: string
	}
	error?: Message
	data?: {
		events: SaleWebhookEvent[]
		hasMore: boolean
	}
	responseStatusCode?: number
}

export const getSaleWebhookEvents = async ({
	saleId,
	offset,
	limit
}: {
	saleId: string
	offset: number
	limit: number
}): Promise<GetSaleWebhookEventsResponse> => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_SALES_API}/${saleId}/webhook-events?offset=${offset}&limit=${limit}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'X-Timezone-Offset': timezoneOffset,
				Authorization: `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ListSalesResponse {
	success?: {
		message: string
	}
	error?: Message
	data?: {sales: SaleListItem[]; hasMore: boolean}
	responseStatusCode?: number
}

export const listSales = async ({
	offset,
	limit,
	filters
}: {
	offset: number
	limit: number
	filters?: string
}): Promise<ListSalesResponse> => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_SALES_API}/?offset=${offset}&limit=${limit}${
			filters ? `&${filters}` : ''
		}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'X-Timezone-Offset': timezoneOffset,
				Authorization: `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ListSalesStatisticsResponse {
	error?: Message
	data?: {
		revenueByMonth: {
			month: number
			revenue: number
		}[]
		totalRevenue: number
	}
	responseStatusCode?: number
}

export const listSalesStatistics = async ({
	fromDate,
	toDate
}: {
	fromDate: string
	toDate: String
}): Promise<ListSalesStatisticsResponse> => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_SALES_API}/statistics?fromDate=${fromDate}&toDate=${toDate}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'X-Timezone-Offset': timezoneOffset,
				Authorization: `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ListSalesRevenueMetricsResponse {
	error?: Message
	data?: {
		productId?: string
		revenueByMonths?: {
			month: number
			revenue: number
		}[]
		revenueByDays?: {
			day: string
			revenue: number
		}[]
		revenueByHours?: {
			hour: number
			revenue: number
		}[]
	}
	responseStatusCode?: number
}

export const listSalesRevenueMetrics = async (values: {
	fromDate: string
	toDate: string
	type: 'days' | 'months' | 'hours'
}): Promise<ListSalesRevenueMetricsResponse> => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken
	const queryParams = regex.parseObjectToQueryString(values)

	const response = await authenticatedFetch(
		`${SELLER_SALES_API}/metrics/revenue?${queryParams}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'X-Timezone-Offset': timezoneOffset,
				Authorization: `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

export const updateSale = async ({
	saleId,
	...values
}: {
	saleId: string
	observations?: string
	paymentMethod?: string
	paidAt?: string
	quantity?: number
	status?: string
	statusHistory?: string
	unitPrice?: number
	updatedAt?: string
	createdAt?: string
}) => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${SELLER_SALES_API}/${saleId}`, {
		method: 'PUT',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'X-Timezone-Offset': timezoneOffset,
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(values)
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface RefundSaleResponse {
	success?: {
		message: string
	}
	data?: {
		refundUrl: string
	}
	error?: {
		message: string
		fields?: {
			value?: Message[]
			refundType?: Message[]
			password?: Message[]
		}
	}
	responseStatusCode?: number
}

export const refundSale = async (values: {
	saleId: string
	value: number
	paymentMethod: string
	description?: string
	refundType: 'full' | 'partial'
	password: string
}): Promise<RefundSaleResponse> => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${SELLER_SALES_API}/refund`, {
		method: 'POST',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'X-Timezone-Offset': timezoneOffset,
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(values)
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ResendSaleCademiIntegrationResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			value?: Message[]
			refundType?: Message[]
			password?: Message[]
		}
	}
	responseStatusCode?: number
	data?: {
		id: string
		status: string
		log: string
	}
}

export const resendDeliveryIntegration = async ({
	integrationName,
	saleId,
	saleItemIntegrationsId
}: {
	integrationName: 'cademi' | 'the-members'
	saleId: string
	saleItemIntegrationsId: string
}): Promise<ResendSaleCademiIntegrationResponse> => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_SALES_API}/integrations/${integrationName}/delivery`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'X-Timezone-Offset': timezoneOffset,
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({saleId, saleItemIntegrationsId})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ResendIntegrationsResponse {
	success?: {
		message: string
	}
	data?: {
		integrations: {
			cademi: SaleIntegration[]
			'the-members': SaleIntegration[]
		}
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const resendIntegrations = async ({
	saleId
}: {
	saleId: string
}): Promise<ResendIntegrationsResponse> => {
	const {currentLang} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_SALES_API}/integrations/resend`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({saleId})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteSaleResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	responseStatusCode?: number
}

export const deleteSale = async ({
	id
}: {
	id: string
}): Promise<DeleteSaleResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${SELLER_SALES_API}/${id}`, {
		method: 'DELETE',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			Authorization: `Bearer ${accessToken}`
		}
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface FetchSaleListResponse {
	success?: {
		message: string
	}
	error?: Message
	data?: {
		sales: {
			date: string
			product: string
			orderBumps?: string
			status: string
			name?: string
			email: string
			phone?: string
			documentType?: string
			document?: string
			paymentMethod?: string
			paymentService?: string
			totalAmount?: number
		}[]
	}
	responseStatusCode?: number
}

export const fetchSaleList = async ({
	offset,
	limit,
	filters
}: {
	offset?: number
	limit?: number
	filters?: string
}): Promise<FetchSaleListResponse> => {
	const {currentLang, timezoneOffset} = useAppStates.getState()
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_SALES_API}/export?offset=${offset}&limit=${limit}${
			filters ? `&${filters}` : ''
		}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'X-Timezone-Offset': timezoneOffset,
				Authorization: `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
