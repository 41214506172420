import {ChangeEvent, useEffect, useMemo} from 'react'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {SxProps, Theme} from '@mui/material/styles'
import useAppStates from '../stores/useAppStates'

interface CountrySelectProps {
	name: string
	label?: string
	codeNumber: string
	helperText?: React.ReactNode
	error?: boolean
	showPhone?: boolean
	valueFormat: 'code' | 'label' | 'phone'
	sx?: SxProps<Theme>
	onChange: (e: ChangeEvent<any>) => void
}

export default function CountrySelect({
	name,
	label,
	codeNumber,
	helperText,
	error,
	onChange,
	showPhone,
	valueFormat,
	sx
}: CountrySelectProps) {
	const {currentLang} = useAppStates()

	let lang: 'pt' | 'en' = useMemo(() => {
		switch (currentLang) {
			case 'pt':
				return 'pt'
			case 'pt-BR':
				return 'pt'
			case 'en':
				return 'en'
			case 'en-US':
				return 'en'
			default:
				return 'pt'
		}
	}, [currentLang])

	const defaultCountry = useMemo(() => {
		switch (lang) {
			case 'en':
				return countries.en.find(country => country.code === 'US')
			default:
				return countries.pt.find(country => country.code === 'BR')
		}
	}, [lang])

	const country = countries[lang].find(country => country.phone === codeNumber)

	const handleChange = (_: any, newValue: CountryType | null) => {
		if (newValue) {
			onChange({
				target: {
					name,
					value: newValue[valueFormat]
				}
			} as React.ChangeEvent<any>)
		}
	}

	const getInputLabel = (option: CountryType) => {
		return `${option.label} +${option.phone}`
	}

	const getOptionLabel = (option: CountryType) => {
		if (showPhone) {
			return `${option.label} (${option.code}) +${option.phone}`
		}
		return `${option.label} (${option.code})`
	}

	useEffect(() => {
		if (!country && defaultCountry) {
			onChange({
				target: {
					name,
					value: defaultCountry[valueFormat]
				}
			} as React.ChangeEvent<any>)
		}
	}, [country, defaultCountry, name, onChange, valueFormat])

	return (
		<Autocomplete
			id="country-select"
			disableClearable
			fullWidth
			defaultValue={country || defaultCountry}
			options={countries[lang]}
			autoHighlight
			getOptionLabel={option => getInputLabel(option)}
			onChange={handleChange}
			ListboxProps={{
				sx: {
					width: '300px',
					position: 'absolute',
					backgroundColor: 'background.paper',
					zIndex: 1,
					boxShadow: 5,
					borderRadius: '4px'
				}
			}}
			renderOption={(props, option) => (
				<Box
					{...props}
					key={'box' + lang + option.code}
					component="li"
					sx={{'& > img': {mr: 2, flexShrink: 0}}}
				>
					<img
						key={'img' + lang + option.code}
						loading="lazy"
						width="20"
						height="12"
						src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
						srcSet={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png 2x`}
						alt=""
					/>
					{getOptionLabel(option)}
				</Box>
			)}
			renderInput={params => (
				<TextField
					{...params}
					size="small"
					name={name}
					label={label}
					helperText={helperText}
					error={error}
					sx={sx}
				/>
			)}
		/>
	)
}

interface CountryType {
	code: string
	label: string
	phone: string
	suggested?: boolean
}

const countries: Record<'en' | 'pt', readonly CountryType[]> = {
	en: [
		{code: 'AD', label: 'Andorra', phone: '376'},
		{
			code: 'AE',
			label: 'United Arab Emirates',
			phone: '971'
		},
		{code: 'AF', label: 'Afghanistan', phone: '93'},
		{
			code: 'AG',
			label: 'Antigua and Barbuda',
			phone: '1-268'
		},
		{code: 'AI', label: 'Anguilla', phone: '1-264'},
		{code: 'AL', label: 'Albania', phone: '355'},
		{code: 'AM', label: 'Armenia', phone: '374'},
		{code: 'AO', label: 'Angola', phone: '244'},
		{code: 'AQ', label: 'Antarctica', phone: '672'},
		{code: 'AR', label: 'Argentina', phone: '54'},
		{code: 'AS', label: 'American Samoa', phone: '1-684'},
		{code: 'AT', label: 'Austria', phone: '43'},
		{
			code: 'AU',
			label: 'Australia',
			phone: '61'
		},
		{code: 'AW', label: 'Aruba', phone: '297'},
		{code: 'AX', label: 'Alland Islands', phone: '358'},
		{code: 'AZ', label: 'Azerbaijan', phone: '994'},
		{
			code: 'BA',
			label: 'Bosnia and Herzegovina',
			phone: '387'
		},
		{code: 'BB', label: 'Barbados', phone: '1-246'},
		{code: 'BD', label: 'Bangladesh', phone: '880'},
		{code: 'BE', label: 'Belgium', phone: '32'},
		{code: 'BF', label: 'Burkina Faso', phone: '226'},
		{code: 'BG', label: 'Bulgaria', phone: '359'},
		{code: 'BH', label: 'Bahrain', phone: '973'},
		{code: 'BI', label: 'Burundi', phone: '257'},
		{code: 'BJ', label: 'Benin', phone: '229'},
		{code: 'BL', label: 'Saint Barthelemy', phone: '590'},
		{code: 'BM', label: 'Bermuda', phone: '1-441'},
		{code: 'BN', label: 'Brunei Darussalam', phone: '673'},
		{code: 'BO', label: 'Bolivia', phone: '591'},
		{code: 'BR', label: 'Brazil', phone: '55', suggested: true},
		{code: 'BS', label: 'Bahamas', phone: '1-242'},
		{code: 'BT', label: 'Bhutan', phone: '975'},
		{code: 'BV', label: 'Bouvet Island', phone: '47'},
		{code: 'BW', label: 'Botswana', phone: '267'},
		{code: 'BY', label: 'Belarus', phone: '375'},
		{code: 'BZ', label: 'Belize', phone: '501'},
		{
			code: 'CA',
			label: 'Canada',
			phone: '1',
			suggested: true
		},
		{
			code: 'CC',
			label: 'Cocos (Keeling) Islands',
			phone: '61'
		},
		{
			code: 'CD',
			label: 'Congo, Democratic Republic of the',
			phone: '243'
		},
		{
			code: 'CF',
			label: 'Central African Republic',
			phone: '236'
		},
		{
			code: 'CG',
			label: 'Congo, Republic of the',
			phone: '242'
		},
		{code: 'CH', label: 'Switzerland', phone: '41'},
		{code: 'CI', label: "Cote d'Ivoire", phone: '225'},
		{code: 'CK', label: 'Cook Islands', phone: '682'},
		{code: 'CL', label: 'Chile', phone: '56'},
		{code: 'CM', label: 'Cameroon', phone: '237'},
		{code: 'CN', label: 'China', phone: '86'},
		{code: 'CO', label: 'Colombia', phone: '57'},
		{code: 'CR', label: 'Costa Rica', phone: '506'},
		{code: 'CU', label: 'Cuba', phone: '53'},
		{code: 'CV', label: 'Cape Verde', phone: '238'},
		{code: 'CW', label: 'Curacao', phone: '599'},
		{code: 'CX', label: 'Christmas Island', phone: '61'},
		{code: 'CY', label: 'Cyprus', phone: '357'},
		{code: 'CZ', label: 'Czech Republic', phone: '420'},
		{
			code: 'DE',
			label: 'Germany',
			phone: '49',
			suggested: true
		},
		{code: 'DJ', label: 'Djibouti', phone: '253'},
		{code: 'DK', label: 'Denmark', phone: '45'},
		{code: 'DM', label: 'Dominica', phone: '1-767'},
		{
			code: 'DO',
			label: 'Dominican Republic',
			phone: '1-809'
		},
		{code: 'DZ', label: 'Algeria', phone: '213'},
		{code: 'EC', label: 'Ecuador', phone: '593'},
		{code: 'EE', label: 'Estonia', phone: '372'},
		{code: 'EG', label: 'Egypt', phone: '20'},
		{code: 'EH', label: 'Western Sahara', phone: '212'},
		{code: 'ER', label: 'Eritrea', phone: '291'},
		{code: 'ES', label: 'Spain', phone: '34'},
		{code: 'ET', label: 'Ethiopia', phone: '251'},
		{code: 'FI', label: 'Finland', phone: '358'},
		{code: 'FJ', label: 'Fiji', phone: '679'},
		{
			code: 'FK',
			label: 'Falkland Islands (Malvinas)',
			phone: '500'
		},
		{
			code: 'FM',
			label: 'Micronesia, Federated States of',
			phone: '691'
		},
		{code: 'FO', label: 'Faroe Islands', phone: '298'},
		{
			code: 'FR',
			label: 'France',
			phone: '33',
			suggested: true
		},
		{code: 'GA', label: 'Gabon', phone: '241'},
		{code: 'GB', label: 'United Kingdom', phone: '44'},
		{code: 'GD', label: 'Grenada', phone: '1-473'},
		{code: 'GE', label: 'Georgia', phone: '995'},
		{code: 'GF', label: 'French Guiana', phone: '594'},
		{code: 'GG', label: 'Guernsey', phone: '44'},
		{code: 'GH', label: 'Ghana', phone: '233'},
		{code: 'GI', label: 'Gibraltar', phone: '350'},
		{code: 'GL', label: 'Greenland', phone: '299'},
		{code: 'GM', label: 'Gambia', phone: '220'},
		{code: 'GN', label: 'Guinea', phone: '224'},
		{code: 'GP', label: 'Guadeloupe', phone: '590'},
		{code: 'GQ', label: 'Equatorial Guinea', phone: '240'},
		{code: 'GR', label: 'Greece', phone: '30'},
		{
			code: 'GS',
			label: 'South Georgia and the South Sandwich Islands',
			phone: '500'
		},
		{code: 'GT', label: 'Guatemala', phone: '502'},
		{code: 'GU', label: 'Guam', phone: '1-671'},
		{code: 'GW', label: 'Guinea-Bissau', phone: '245'},
		{code: 'GY', label: 'Guyana', phone: '592'},
		{code: 'HK', label: 'Hong Kong', phone: '852'},
		{
			code: 'HM',
			label: 'Heard Island and McDonald Islands',
			phone: '672'
		},
		{code: 'HN', label: 'Honduras', phone: '504'},
		{code: 'HR', label: 'Croatia', phone: '385'},
		{code: 'HT', label: 'Haiti', phone: '509'},
		{code: 'HU', label: 'Hungary', phone: '36'},
		{code: 'ID', label: 'Indonesia', phone: '62'},
		{code: 'IE', label: 'Ireland', phone: '353'},
		{code: 'IL', label: 'Israel', phone: '972'},
		{code: 'IM', label: 'Isle of Man', phone: '44'},
		{code: 'IN', label: 'India', phone: '91'},
		{
			code: 'IO',
			label: 'British Indian Ocean Territory',
			phone: '246'
		},
		{code: 'IQ', label: 'Iraq', phone: '964'},
		{
			code: 'IR',
			label: 'Iran, Islamic Republic of',
			phone: '98'
		},
		{code: 'IS', label: 'Iceland', phone: '354'},
		{code: 'IT', label: 'Italy', phone: '39'},
		{code: 'JE', label: 'Jersey', phone: '44'},
		{code: 'JM', label: 'Jamaica', phone: '1-876'},
		{code: 'JO', label: 'Jordan', phone: '962'},
		{
			code: 'JP',
			label: 'Japan',
			phone: '81',
			suggested: true
		},
		{code: 'KE', label: 'Kenya', phone: '254'},
		{code: 'KG', label: 'Kyrgyzstan', phone: '996'},
		{code: 'KH', label: 'Cambodia', phone: '855'},
		{code: 'KI', label: 'Kiribati', phone: '686'},
		{code: 'KM', label: 'Comoros', phone: '269'},
		{
			code: 'KN',
			label: 'Saint Kitts and Nevis',
			phone: '1-869'
		},
		{
			code: 'KP',
			label: "Korea, Democratic People's Republic of",
			phone: '850'
		},
		{code: 'KR', label: 'Korea, Republic of', phone: '82'},
		{code: 'KW', label: 'Kuwait', phone: '965'},
		{code: 'KY', label: 'Cayman Islands', phone: '1-345'},
		{code: 'KZ', label: 'Kazakhstan', phone: '7'},
		{
			code: 'LA',
			label: "Lao People's Democratic Republic",
			phone: '856'
		},
		{code: 'LB', label: 'Lebanon', phone: '961'},
		{code: 'LC', label: 'Saint Lucia', phone: '1-758'},
		{code: 'LI', label: 'Liechtenstein', phone: '423'},
		{code: 'LK', label: 'Sri Lanka', phone: '94'},
		{code: 'LR', label: 'Liberia', phone: '231'},
		{code: 'LS', label: 'Lesotho', phone: '266'},
		{code: 'LT', label: 'Lithuania', phone: '370'},
		{code: 'LU', label: 'Luxembourg', phone: '352'},
		{code: 'LV', label: 'Latvia', phone: '371'},
		{code: 'LY', label: 'Libya', phone: '218'},
		{code: 'MA', label: 'Morocco', phone: '212'},
		{code: 'MC', label: 'Monaco', phone: '377'},
		{
			code: 'MD',
			label: 'Moldova, Republic of',
			phone: '373'
		},
		{code: 'ME', label: 'Montenegro', phone: '382'},
		{
			code: 'MF',
			label: 'Saint Martin (French part)',
			phone: '590'
		},
		{code: 'MG', label: 'Madagascar', phone: '261'},
		{code: 'MH', label: 'Marshall Islands', phone: '692'},
		{
			code: 'MK',
			label: 'Macedonia, the Former Yugoslav Republic of',
			phone: '389'
		},
		{code: 'ML', label: 'Mali', phone: '223'},
		{code: 'MM', label: 'Myanmar', phone: '95'},
		{code: 'MN', label: 'Mongolia', phone: '976'},
		{code: 'MO', label: 'Macao', phone: '853'},
		{
			code: 'MP',
			label: 'Northern Mariana Islands',
			phone: '1-670'
		},
		{code: 'MQ', label: 'Martinique', phone: '596'},
		{code: 'MR', label: 'Mauritania', phone: '222'},
		{code: 'MS', label: 'Montserrat', phone: '1-664'},
		{code: 'MT', label: 'Malta', phone: '356'},
		{code: 'MU', label: 'Mauritius', phone: '230'},
		{code: 'MV', label: 'Maldives', phone: '960'},
		{code: 'MW', label: 'Malawi', phone: '265'},
		{code: 'MX', label: 'Mexico', phone: '52'},
		{code: 'MY', label: 'Malaysia', phone: '60'},
		{code: 'MZ', label: 'Mozambique', phone: '258'},
		{code: 'NA', label: 'Namibia', phone: '264'},
		{code: 'NC', label: 'New Caledonia', phone: '687'},
		{code: 'NE', label: 'Niger', phone: '227'},
		{code: 'NF', label: 'Norfolk Island', phone: '672'},
		{code: 'NG', label: 'Nigeria', phone: '234'},
		{code: 'NI', label: 'Nicaragua', phone: '505'},
		{code: 'NL', label: 'Netherlands', phone: '31'},
		{code: 'NO', label: 'Norway', phone: '47'},
		{code: 'NP', label: 'Nepal', phone: '977'},
		{code: 'NR', label: 'Nauru', phone: '674'},
		{code: 'NU', label: 'Niue', phone: '683'},
		{code: 'NZ', label: 'New Zealand', phone: '64'},
		{code: 'OM', label: 'Oman', phone: '968'},
		{code: 'PA', label: 'Panama', phone: '507'},
		{code: 'PE', label: 'Peru', phone: '51'},
		{code: 'PF', label: 'French Polynesia', phone: '689'},
		{code: 'PG', label: 'Papua New Guinea', phone: '675'},
		{code: 'PH', label: 'Philippines', phone: '63'},
		{code: 'PK', label: 'Pakistan', phone: '92'},
		{code: 'PL', label: 'Poland', phone: '48'},
		{
			code: 'PM',
			label: 'Saint Pierre and Miquelon',
			phone: '508'
		},
		{code: 'PN', label: 'Pitcairn', phone: '870'},
		{code: 'PR', label: 'Puerto Rico', phone: '1'},
		{
			code: 'PS',
			label: 'Palestine, State of',
			phone: '970'
		},
		{code: 'PT', label: 'Portugal', phone: '351'},
		{code: 'PW', label: 'Palau', phone: '680'},
		{code: 'PY', label: 'Paraguay', phone: '595'},
		{code: 'QA', label: 'Qatar', phone: '974'},
		{code: 'RE', label: 'Reunion', phone: '262'},
		{code: 'RO', label: 'Romania', phone: '40'},
		{code: 'RS', label: 'Serbia', phone: '381'},
		{code: 'RU', label: 'Russian Federation', phone: '7'},
		{code: 'RW', label: 'Rwanda', phone: '250'},
		{code: 'SA', label: 'Saudi Arabia', phone: '966'},
		{code: 'SB', label: 'Solomon Islands', phone: '677'},
		{code: 'SC', label: 'Seychelles', phone: '248'},
		{code: 'SD', label: 'Sudan', phone: '249'},
		{code: 'SE', label: 'Sweden', phone: '46'},
		{code: 'SG', label: 'Singapore', phone: '65'},
		{code: 'SH', label: 'Saint Helena', phone: '290'},
		{code: 'SI', label: 'Slovenia', phone: '386'},
		{
			code: 'SJ',
			label: 'Svalbard and Jan Mayen',
			phone: '47'
		},
		{code: 'SK', label: 'Slovakia', phone: '421'},
		{code: 'SL', label: 'Sierra Leone', phone: '232'},
		{code: 'SM', label: 'San Marino', phone: '378'},
		{code: 'SN', label: 'Senegal', phone: '221'},
		{code: 'SO', label: 'Somalia', phone: '252'},
		{code: 'SR', label: 'Suriname', phone: '597'},
		{code: 'SS', label: 'South Sudan', phone: '211'},
		{
			code: 'ST',
			label: 'Sao Tome and Principe',
			phone: '239'
		},
		{code: 'SV', label: 'El Salvador', phone: '503'},
		{
			code: 'SX',
			label: 'Sint Maarten (Dutch part)',
			phone: '1-721'
		},
		{
			code: 'SY',
			label: 'Syrian Arab Republic',
			phone: '963'
		},
		{code: 'SZ', label: 'Swaziland', phone: '268'},
		{
			code: 'TC',
			label: 'Turks and Caicos Islands',
			phone: '1-649'
		},
		{code: 'TD', label: 'Chad', phone: '235'},
		{
			code: 'TF',
			label: 'French Southern Territories',
			phone: '262'
		},
		{code: 'TG', label: 'Togo', phone: '228'},
		{code: 'TH', label: 'Thailand', phone: '66'},
		{code: 'TJ', label: 'Tajikistan', phone: '992'},
		{code: 'TK', label: 'Tokelau', phone: '690'},
		{code: 'TL', label: 'Timor-Leste', phone: '670'},
		{code: 'TM', label: 'Turkmenistan', phone: '993'},
		{code: 'TN', label: 'Tunisia', phone: '216'},
		{code: 'TO', label: 'Tonga', phone: '676'},
		{code: 'TR', label: 'Turkey', phone: '90'},
		{
			code: 'TT',
			label: 'Trinidad and Tobago',
			phone: '1-868'
		},
		{code: 'TV', label: 'Tuvalu', phone: '688'},
		{
			code: 'TW',
			label: 'Taiwan, Republic of China',
			phone: '886'
		},
		{
			code: 'TZ',
			label: 'United Republic of Tanzania',
			phone: '255'
		},
		{code: 'UA', label: 'Ukraine', phone: '380'},
		{code: 'UG', label: 'Uganda', phone: '256'},
		{
			code: 'US',
			label: 'United States',
			phone: '1',
			suggested: true
		},
		{code: 'UY', label: 'Uruguay', phone: '598'},
		{code: 'UZ', label: 'Uzbekistan', phone: '998'},
		{
			code: 'VA',
			label: 'Holy See (Vatican City State)',
			phone: '379'
		},
		{
			code: 'VC',
			label: 'Saint Vincent and the Grenadines',
			phone: '1-784'
		},
		{code: 'VE', label: 'Venezuela', phone: '58'},
		{
			code: 'VG',
			label: 'British Virgin Islands',
			phone: '1-284'
		},
		{
			code: 'VI',
			label: 'US Virgin Islands',
			phone: '1-340'
		},
		{code: 'VN', label: 'Vietnam', phone: '84'},
		{code: 'VU', label: 'Vanuatu', phone: '678'},
		{code: 'WF', label: 'Wallis and Futuna', phone: '681'},
		{code: 'WS', label: 'Samoa', phone: '685'},
		{code: 'XK', label: 'Kosovo', phone: '383'},
		{code: 'YE', label: 'Yemen', phone: '967'},
		{code: 'YT', label: 'Mayotte', phone: '262'},
		{code: 'ZA', label: 'South Africa', phone: '27'},
		{code: 'ZM', label: 'Zambia', phone: '260'},
		{code: 'ZW', label: 'Zimbabwe', phone: '263'}
	],
	pt: [
		{code: 'AD', label: 'Andorra', phone: '376'},
		{
			code: 'AE',
			label: 'Emirados Árabes Unidos',
			phone: '971'
		},
		{code: 'AF', label: 'Afeganistão', phone: '93'},
		{
			code: 'AG',
			label: 'Antígua e Barbuda',
			phone: '1-268'
		},
		{code: 'AI', label: 'Anguilla', phone: '1-264'},
		{code: 'AL', label: 'Albânia', phone: '355'},
		{code: 'AM', label: 'Armênia', phone: '374'},
		{code: 'AO', label: 'Angola', phone: '244'},
		{code: 'AQ', label: 'Antártica', phone: '672'},
		{code: 'AR', label: 'Argentina', phone: '54'},
		{code: 'AS', label: 'Samoa Americana', phone: '1-684'},
		{code: 'AT', label: 'Áustria', phone: '43'},
		{
			code: 'AU',
			label: 'Austrália',
			phone: '61',
			suggested: true
		},
		{code: 'AW', label: 'Aruba', phone: '297'},
		{code: 'AX', label: 'Ilhas Aland', phone: '358'},
		{code: 'AZ', label: 'Azerbaijão', phone: '994'},
		{
			code: 'BA',
			label: 'Bósnia e Herzegovina',
			phone: '387'
		},
		{code: 'BB', label: 'Barbados', phone: '1-246'},
		{code: 'BD', label: 'Bangladesh', phone: '880'},
		{code: 'BE', label: 'Bélgica', phone: '32'},
		{code: 'BF', label: 'Burkina Faso', phone: '226'},
		{code: 'BG', label: 'Bulgária', phone: '359'},
		{code: 'BH', label: 'Bahrein', phone: '973'},
		{code: 'BI', label: 'Burundi', phone: '257'},
		{code: 'BJ', label: 'Benin', phone: '229'},
		{code: 'BL', label: 'São Bartolomeu', phone: '590'},
		{code: 'BM', label: 'Bermudas', phone: '1-441'},
		{code: 'BN', label: 'Brunei Darussalam', phone: '673'},
		{code: 'BO', label: 'Bolívia', phone: '591'},
		{code: 'BR', label: 'Brasil', phone: '55'},
		{code: 'BS', label: 'Bahamas', phone: '1-242'},
		{code: 'BT', label: 'Butão', phone: '975'},
		{code: 'BV', label: 'Ilha Bouvet', phone: '47'},
		{code: 'BW', label: 'Botsuana', phone: '267'},
		{code: 'BY', label: 'Bielorrússia', phone: '375'},
		{code: 'BZ', label: 'Belize', phone: '501'},
		{
			code: 'CA',
			label: 'Canadá',
			phone: '1',
			suggested: true
		},
		{
			code: 'CC',
			label: 'Ilhas Cocos (Keeling)',
			phone: '61'
		},
		{
			code: 'CD',
			label: 'República Democrática do Congo',
			phone: '243'
		},
		{
			code: 'CF',
			label: 'República Centro-Africana',
			phone: '236'
		},
		{
			code: 'CG',
			label: 'República do Congo',
			phone: '242'
		},
		{code: 'CH', label: 'Suíça', phone: '41'},
		{code: 'CI', label: 'Costa do Marfim', phone: '225'},
		{code: 'CK', label: 'Ilhas Cook', phone: '682'},
		{code: 'CL', label: 'Chile', phone: '56'},
		{code: 'CM', label: 'Camarões', phone: '237'},
		{code: 'CN', label: 'China', phone: '86'},
		{code: 'CO', label: 'Colômbia', phone: '57'},
		{code: 'CR', label: 'Costa Rica', phone: '506'},
		{code: 'CU', label: 'Cuba', phone: '53'},
		{code: 'CV', label: 'Cabo Verde', phone: '238'},
		{code: 'CW', label: 'Curaçao', phone: '599'},
		{code: 'CX', label: 'Ilha Christmas', phone: '61'},
		{code: 'CY', label: 'Chipre', phone: '357'},
		{code: 'CZ', label: 'República Tcheca', phone: '420'},
		{
			code: 'DE',
			label: 'Alemanha',
			phone: '49',
			suggested: true
		},
		{code: 'DJ', label: 'Djibuti', phone: '253'},
		{code: 'DK', label: 'Dinamarca', phone: '45'},
		{code: 'DM', label: 'Dominica', phone: '1-767'},
		{
			code: 'DO',
			label: 'República Dominicana',
			phone: '1-809'
		},
		{code: 'DZ', label: 'Argélia', phone: '213'},
		{code: 'EC', label: 'Equador', phone: '593'},
		{code: 'EE', label: 'Estônia', phone: '372'},
		{code: 'EG', label: 'Egito', phone: '20'},
		{code: 'EH', label: 'Saara Ocidental', phone: '212'},
		{code: 'ER', label: 'Eritreia', phone: '291'},
		{code: 'ES', label: 'Espanha', phone: '34'},
		{code: 'ET', label: 'Etiópia', phone: '251'},
		{code: 'FI', label: 'Finlândia', phone: '358'},
		{code: 'FJ', label: 'Fiji', phone: '679'},
		{code: 'FK', label: 'Ilhas Malvinas (Falkland Islands)', phone: '500'},
		{code: 'FM', label: 'Micronésia, Estados Federados da', phone: '691'},
		{code: 'FO', label: 'Ilhas Faroe', phone: '298'},
		{code: 'FR', label: 'França', phone: '33'},
		{code: 'GA', label: 'Gabão', phone: '241'},
		{code: 'GB', label: 'Reino Unido', phone: '44'},
		{code: 'GD', label: 'Granada', phone: '1-473'},
		{code: 'GE', label: 'Geórgia', phone: '995'},
		{code: 'GF', label: 'Guiana Francesa', phone: '594'},
		{code: 'GG', label: 'Guernsey', phone: '44'},
		{code: 'GH', label: 'Gana', phone: '233'},
		{code: 'GI', label: 'Gibraltar', phone: '350'},
		{code: 'GL', label: 'Groenlândia', phone: '299'},
		{code: 'GM', label: 'Gâmbia', phone: '220'},
		{code: 'GN', label: 'Guiné', phone: '224'},
		{code: 'GP', label: 'Guadalupe', phone: '590'},
		{code: 'GQ', label: 'Guiné Equatorial', phone: '240'},
		{code: 'GR', label: 'Grécia', phone: '30'},
		{
			code: 'GS',
			label: 'Geórgia do Sul e Ilhas Sandwich do Sul',
			phone: '500'
		},
		{code: 'GT', label: 'Guatemala', phone: '502'},
		{code: 'GU', label: 'Guam', phone: '1-671'},
		{code: 'GW', label: 'Guiné-Bissau', phone: '245'},
		{code: 'GY', label: 'Guiana', phone: '592'},
		{code: 'HK', label: 'Hong Kong', phone: '852'},
		{code: 'HM', label: 'Ilha Heard e Ilhas McDonald', phone: '672'},
		{code: 'HN', label: 'Honduras', phone: '504'},
		{code: 'HR', label: 'Croácia', phone: '385'},
		{code: 'HT', label: 'Haiti', phone: '509'},
		{code: 'HU', label: 'Hungria', phone: '36'},
		{code: 'ID', label: 'Indonésia', phone: '62'},
		{code: 'IE', label: 'Irlanda', phone: '353'},
		{code: 'IL', label: 'Israel', phone: '972'},
		{code: 'IM', label: 'Ilha de Man', phone: '44'},
		{code: 'IN', label: 'Índia', phone: '91'},
		{
			code: 'IO',
			label: 'Território Britânico do Oceano Índico',
			phone: '246'
		},
		{code: 'IQ', label: 'Iraque', phone: '964'},
		{code: 'IR', label: 'Irã, República Islâmica do', phone: '98'},
		{code: 'IS', label: 'Islândia', phone: '354'},
		{code: 'IT', label: 'Itália', phone: '39'},
		{code: 'JE', label: 'Jersey', phone: '44'},
		{code: 'JM', label: 'Jamaica', phone: '1-876'},
		{code: 'JO', label: 'Jordânia', phone: '962'},
		{code: 'JP', label: 'Japão', phone: '81'},
		{code: 'KE', label: 'Quênia', phone: '254'},
		{code: 'KG', label: 'Quirguistão', phone: '996'},
		{code: 'KH', label: 'Camboja', phone: '855'},
		{code: 'KI', label: 'Kiribati', phone: '686'},
		{code: 'KM', label: 'Comores', phone: '269'},
		{code: 'KN', label: 'São Cristóvão e Nevis', phone: '1-869'},
		{
			code: 'KP',
			label: 'Coreia, República Popular Democrática da',
			phone: '850'
		},
		{code: 'KR', label: 'Coreia, República da', phone: '82'},
		{code: 'KW', label: 'Kuwait', phone: '965'},
		{code: 'KY', label: 'Ilhas Cayman', phone: '1-345'},
		{code: 'KZ', label: 'Cazaquistão', phone: '7'},
		{
			code: 'LA',
			label: 'República Democrática Popular do Laos',
			phone: '856'
		},
		{code: 'LB', label: 'Líbano', phone: '961'},
		{code: 'LC', label: 'Santa Lúcia', phone: '1-758'},
		{code: 'LI', label: 'Liechtenstein', phone: '423'},
		{code: 'LK', label: 'Sri Lanka', phone: '94'},
		{code: 'LR', label: 'Libéria', phone: '231'},
		{code: 'LS', label: 'Lesoto', phone: '266'},
		{code: 'LT', label: 'Lituânia', phone: '370'},
		{code: 'LU', label: 'Luxemburgo', phone: '352'},
		{code: 'LV', label: 'Letônia', phone: '371'},
		{code: 'LY', label: 'Líbia', phone: '218'},
		{code: 'MA', label: 'Marrocos', phone: '212'},
		{code: 'MC', label: 'Mônaco', phone: '377'},
		{code: 'MD', label: 'Moldávia, República da', phone: '373'},
		{code: 'ME', label: 'Montenegro', phone: '382'},
		{code: 'MF', label: 'São Martinho (parte francesa)', phone: '590'},
		{code: 'MG', label: 'Madagáscar', phone: '261'},
		{code: 'MH', label: 'Ilhas Marshall', phone: '692'},
		{
			code: 'MK',
			label: 'Macedônia, Antiga República Iugoslava da',
			phone: '389'
		},
		{code: 'ML', label: 'Mali', phone: '223'},
		{code: 'MM', label: 'Mianmar (Birmânia)', phone: '95'},
		{code: 'MN', label: 'Mongólia', phone: '976'},
		{code: 'MO', label: 'Macau', phone: '853'},
		{code: 'MP', label: 'Ilhas Marianas do Norte', phone: '1-670'},
		{code: 'MQ', label: 'Martinica', phone: '596'},
		{code: 'MR', label: 'Mauritânia', phone: '222'},
		{code: 'MS', label: 'Montserrat', phone: '1-664'},
		{code: 'MT', label: 'Malta', phone: '356'},
		{code: 'MU', label: 'Maurícia', phone: '230'},
		{code: 'MV', label: 'Maldivas', phone: '960'},
		{code: 'MW', label: 'Malawi', phone: '265'},
		{code: 'MX', label: 'México', phone: '52'},
		{code: 'MY', label: 'Malásia', phone: '60'},
		{code: 'MZ', label: 'Moçambique', phone: '258'},
		{code: 'NA', label: 'Namíbia', phone: '264'},
		{code: 'NC', label: 'Nova Caledônia', phone: '687'},
		{code: 'NE', label: 'Níger', phone: '227'},
		{code: 'NF', label: 'Ilha Norfolk', phone: '672'},
		{code: 'NG', label: 'Nigéria', phone: '234'},
		{code: 'NI', label: 'Nicarágua', phone: '505'},
		{code: 'NL', label: 'Países Baixos', phone: '31'},
		{code: 'NO', label: 'Noruega', phone: '47'},
		{code: 'NP', label: 'Nepal', phone: '977'},
		{code: 'NR', label: 'Nauru', phone: '674'},
		{code: 'NU', label: 'Niue', phone: '683'},
		{code: 'NZ', label: 'Nova Zelândia', phone: '64'},
		{code: 'OM', label: 'Omã', phone: '968'},
		{code: 'PA', label: 'Panamá', phone: '507'},
		{code: 'PE', label: 'Peru', phone: '51'},
		{code: 'PF', label: 'Polinésia Francesa', phone: '689'},
		{code: 'PG', label: 'Papua-Nova Guiné', phone: '675'},
		{code: 'PH', label: 'Filipinas', phone: '63'},
		{code: 'PK', label: 'Paquistão', phone: '92'},
		{code: 'PL', label: 'Polônia', phone: '48'},
		{code: 'PM', label: 'Saint Pierre e Miquelon', phone: '508'},
		{code: 'PN', label: 'Pitcairn', phone: '870'},
		{code: 'PR', label: 'Porto Rico', phone: '1'},
		{code: 'PS', label: 'Palestina, Estado da', phone: '970'},
		{code: 'PT', label: 'Portugal', phone: '351'},
		{code: 'PW', label: 'Palau', phone: '680'},
		{code: 'PY', label: 'Paraguai', phone: '595'},
		{code: 'QA', label: 'Catar', phone: '974'},
		{code: 'RE', label: 'Reunião', phone: '262'},
		{code: 'RO', label: 'Romênia', phone: '40'},
		{code: 'RS', label: 'Sérvia', phone: '381'},
		{code: 'RU', label: 'Federação Russa', phone: '7'},
		{code: 'RW', label: 'Ruanda', phone: '250'},
		{code: 'SA', label: 'Arábia Saudita', phone: '966'},
		{code: 'SB', label: 'Ilhas Salomão', phone: '677'},
		{code: 'SC', label: 'Seicheles', phone: '248'},
		{code: 'SD', label: 'Sudão', phone: '249'},
		{code: 'SE', label: 'Suécia', phone: '46'},
		{code: 'SG', label: 'Singapura', phone: '65'},
		{code: 'SH', label: 'Santa Helena', phone: '290'},
		{code: 'SI', label: 'Eslovênia', phone: '386'},
		{code: 'SJ', label: 'Svalbard e Jan Mayen', phone: '47'},
		{code: 'SK', label: 'Eslováquia', phone: '421'},
		{code: 'SL', label: 'Serra Leoa', phone: '232'},
		{code: 'SM', label: 'San Marino', phone: '378'},
		{code: 'SN', label: 'Senegal', phone: '221'},
		{code: 'SO', label: 'Somália', phone: '252'},
		{code: 'SR', label: 'Suriname', phone: '597'},
		{code: 'SS', label: 'Sudão do Sul', phone: '211'},
		{code: 'ST', label: 'São Tomé e Príncipe', phone: '239'},
		{code: 'SV', label: 'El Salvador', phone: '503'},
		{code: 'SX', label: 'São Martinho (parte holandesa)', phone: '1-721'},
		{code: 'SY', label: 'República Árabe Síria', phone: '963'},
		{code: 'SZ', label: 'Suazilândia', phone: '268'},
		{code: 'TC', label: 'Ilhas Turks e Caicos', phone: '1-649'},
		{code: 'TD', label: 'Chade', phone: '235'},
		{code: 'TF', label: 'Territórios Franceses do Sul', phone: '262'},
		{code: 'TG', label: 'Togo', phone: '228'},
		{code: 'TH', label: 'Tailândia', phone: '66'},
		{code: 'TJ', label: 'Tajiquistão', phone: '992'},
		{code: 'TK', label: 'Tokelau', phone: '690'},
		{code: 'TL', label: 'Timor-Leste', phone: '670'},
		{code: 'TM', label: 'Turcomenistão', phone: '993'},
		{code: 'TN', label: 'Tunísia', phone: '216'},
		{code: 'TO', label: 'Tonga', phone: '676'},
		{code: 'TR', label: 'Turquia', phone: '90'},
		{code: 'TT', label: 'Trinidad e Tobago', phone: '1-868'},
		{code: 'TV', label: 'Tuvalu', phone: '688'},
		{code: 'TW', label: 'Taiwan, República da China', phone: '886'},
		{code: 'TZ', label: 'República Unida da Tanzânia', phone: '255'},
		{code: 'UA', label: 'Ucrânia', phone: '380'},
		{code: 'UG', label: 'Uganda', phone: '256'},
		{code: 'US', label: 'Estados Unidos', phone: '1', suggested: true},
		{code: 'UY', label: 'Uruguai', phone: '598'},
		{code: 'UZ', label: 'Uzbequistão', phone: '998'},
		{code: 'VA', label: 'Santa Sé (Cidade do Vaticano)', phone: '379'},
		{code: 'VC', label: 'São Vicente e Granadinas', phone: '1-784'},
		{code: 'VE', label: 'Venezuela', phone: '58'},
		{code: 'VG', label: 'Ilhas Virgens Britânicas', phone: '1-284'},
		{code: 'VI', label: 'Ilhas Virgens dos EUA', phone: '1-340'},
		{code: 'VN', label: 'Vietnã', phone: '84'},
		{code: 'VU', label: 'Vanuatu', phone: '678'},
		{code: 'WF', label: 'Wallis e Futuna', phone: '681'},
		{code: 'WS', label: 'Samoa', phone: '685'},
		{code: 'XK', label: 'Kosovo', phone: '383'},
		{code: 'YE', label: 'Iêmen', phone: '967'},
		{code: 'YT', label: 'Mayotte', phone: '262'},
		{code: 'ZA', label: 'África do Sul', phone: '27'},
		{code: 'ZM', label: 'Zâmbia', phone: '260'},
		{code: 'ZW', label: 'Zimbábue', phone: '263'}
	]
}
