import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

const useCheckEmailSchema = () => {
	const {t} = useTranslation()
	const checkEmailSchema = yup.object({
		email: yup
			.string()
			.matches(
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
				t('error-email-invalid')
			)
			.min(5, t('error-email-min-length'))
			.max(200, t('error-email-max-length'))
			.required(t('error-email-required'))
	})

	return checkEmailSchema
}

export default useCheckEmailSchema
