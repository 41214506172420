import {DEFAULT_LANG, SELLER_API} from '../config'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'

interface Message {
	message: string
}

interface UpdateEmailResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			password?: Message[]
			newEmail?: Message[]
		}
	}
	responseStatusCode: number
}

export const updateEmail = async (values: {
	newEmail: string
	password: string
}): Promise<UpdateEmailResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${SELLER_API}/update-email`, {
		method: 'POST',
		body: JSON.stringify(values),
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		}
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CodeValidateResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			code?: Message[]
		}
	}
	responseStatusCode?: number
}

export const codeValidate = async (values: {
	code: string
}): Promise<CodeValidateResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_API}/update-email/code-validate`,
		{
			method: 'POST',
			body: JSON.stringify(values),
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
