import {IMAGE} from '../config'

export const image = {
	createHTMLElement: (url: string) => {
		return new Promise<HTMLImageElement>((resolve, reject) => {
			const image = new Image()
			image.onload = () => resolve(image)
			image.onerror = error => reject(error)
			image.src = url
		})
	},
	createFileFromURL: async (url: string, filename: string) => {
		const response = await fetch(url)
		const blob = await response.blob()
		const file = new File([blob], filename, {type: blob.type})
		return file
	},
	getURL: (file: File) => URL.createObjectURL(file),
	getDimensions: async (imageURL: string) => {
		const imageElement = await image.createHTMLElement(imageURL)
		const {width, height} = imageElement

		return {width, height}
	},
	loadImage: async (imageURL: string) => {
		return new Promise<HTMLImageElement>((resolve, reject) => {
			const image = new Image()
			image.src = imageURL
			image.onload = () => resolve(image)
			image.onerror = reject
		})
	},
	validate: {
		avatarType: ({
			file,
			types = IMAGE.avatarTypes
		}: {
			file: File
			types?: string[]
		}) => types.includes(file.type),
		logoType: ({
			file,
			types = IMAGE.logoTypes
		}: {
			file: File
			types?: string[]
		}) => types.includes(file.type),
		coverType: ({
			file,
			types = IMAGE.coverTypes
		}: {
			file: File
			types?: string[]
		}) => types.includes(file.type),
		sectionImageType: ({
			file,
			types = IMAGE.sectionImageTypes
		}: {
			file: File
			types?: string[]
		}) => types.includes(file.type),
		size: ({
			file,
			maxSize = IMAGE.max_size
		}: {
			file: File
			maxSize?: number
		}) => {
			return file.size <= maxSize
		},
		minDimensions: async ({
			file,
			dimensions = {
				minHeight: IMAGE.dimensions.min.height,
				minWidth: IMAGE.dimensions.min.width
			}
		}: {
			file: File
			dimensions?: {minHeight: number; minWidth: number}
		}) => {
			const imageURL = image.getURL(file)
			const {height, width} = await image.getDimensions(imageURL)
			return width >= dimensions.minWidth && height >= dimensions.minHeight
		},
		maxDimensions: async ({
			file,
			dimensions = {
				maxHeight: IMAGE.dimensions.max.height,
				maxWidth: IMAGE.dimensions.max.width
			}
		}: {
			file: File
			dimensions?: {maxHeight: number; maxWidth: number}
		}) => {
			const imageURL = image.getURL(file)
			const {height, width} = await image.getDimensions(imageURL)
			return width <= dimensions.maxWidth && height <= dimensions.maxHeight
		},
		aspectRatio: async ({
			file,
			aspectRatio = IMAGE.aspectRatio
		}: {
			file: File
			aspectRatio?: number
		}) => {
			const imageURL = image.getURL(file)
			const {height, width} = await image.getDimensions(imageURL)
			return width / height <= aspectRatio
		}
	}
}
