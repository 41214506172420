import * as yup from 'yup'
import {useState} from 'react'
import {useFormik} from 'formik'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {CircularProgress} from '@mui/material'
import {updateTheMembersIntegration} from '../../../api/integrations'
import AlertMessage from '../../AlertMessage'
import {integrationMapper} from './utils/integrationMapper'

export const TheMembersForm = ({
	metadata,
	id,
	onUpdate
}: {
	metadata?: string
	id: string
	onUpdate: (data: {id: string; status: string; metadata?: string}) => void
}) => {
	const {t} = useTranslation()
	const [errorMessage, setErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)
	const [showSuccessButton, setShowSuccessButton] = useState(false)

	const {tokenAPI, productId} = integrationMapper.theMembers(metadata)

	const handleChangeForm = () => {
		setOpenAlertError(false)
		setShowSuccessButton(false)
	}

	const {mutate, isLoading} = useMutation(updateTheMembersIntegration, {
		networkMode: 'always'
	})

	const validationSchema = yup.object({
		tokenAPI: yup.string().required(t('error-token-api-required')),
		productId: yup.string().required(t('error-product-id-required'))
	})

	const formik = useFormik({
		initialValues: {
			tokenAPI,
			productId
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			mutate(
				{
					id,
					tokenAPI: values.tokenAPI,
					productId: values.productId
				},
				{
					onSuccess: ({data, error, responseStatusCode}) => {
						if (responseStatusCode === 200 && data) {
							setShowSuccessButton(true)
							onUpdate(data)
						} else if (error) {
							setErrorMessage(error.message)
							setOpenAlertError(true)
						} else {
							setErrorMessage(t('error-server-default'))
							setOpenAlertError(true)
						}
					},
					onError: error => {
						setErrorMessage(t('error-server-default'))
						setOpenAlertError(true)
					}
				}
			)
		}
	})

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: 2
			}}
		>
			{openAlertError ?? (
				<AlertMessage
					severity="error"
					message={errorMessage}
					open={openAlertError}
					onClose={() => setOpenAlertError(false)}
				/>
			)}

			<Box
				onSubmit={formik.handleSubmit}
				onChange={handleChangeForm}
				component="form"
				autoComplete="off"
				sx={theme => ({
					mt: 0,
					pt: 0,
					width: '100%'
				})}
			>
				<Stack direction="column" spacing={4}>
					<TextField
						name="tokenAPI"
						type="text"
						label={t('token-api')}
						variant="outlined"
						helperText={formik.touched.tokenAPI && formik.errors.tokenAPI}
						error={formik.touched.tokenAPI && Boolean(formik.errors.tokenAPI)}
						value={formik.values.tokenAPI}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						size="small"
						fullWidth
					/>
					<TextField
						name="productId"
						type="text"
						label={t('product-id')}
						variant="outlined"
						helperText={formik.touched.productId && formik.errors.productId}
						error={formik.touched.productId && Boolean(formik.errors.productId)}
						value={formik.values.productId}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						size="small"
						fullWidth
					/>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={{
								height: '36.5px',
								backgroundColor: 'success.light',
								color: 'success.contrastText',
								'&:hover': {backgroundColor: 'success.light'}
							}}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							type="submit"
							variant="contained"
							sx={{
								height: '36.5px'
							}}
							disabled={isLoading}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={24} />
							) : (
								t('to-save')
							)}
						</Button>
					)}
				</Stack>
			</Box>
		</Box>
	)
}
