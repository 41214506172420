import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

const useUpdateEmailSchema = () => {
	const {t} = useTranslation()

	const updateEmailSchema = yup.object({
		newEmail: yup
			.string()
			.matches(
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
				t('error-email-invalid')
			)
			.min(5, t('error-email-min-length'))
			.max(200, t('error-email-max-length'))
			.required(t('error-email-required')),
		confirmEmail: yup
			.string()
			.required(t('error-confirm-email-required'))
			.oneOf([yup.ref('newEmail'), null], t('error-emails-not-match')),
		password: yup
			.string()
			.matches(
				/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{1,}$/,
				t('error-password-invalid')
			)
			.min(7, t('error-password-min-length'))
			.max(500, t('error-password-max-length'))
			.required(t('error-enter-password'))
	})

	return updateEmailSchema
}

export default useUpdateEmailSchema
