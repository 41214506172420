import {Navigate, Outlet, useNavigate, useParams} from 'react-router-dom'
import {useEffect} from 'react'
import i18n from 'i18next'
import CssBaseline from '@mui/material/CssBaseline'
import Header from '../template/Header'
import useAppStates from '../../stores/useAppStates'
import {VALID_LANG} from '../../config'
import useAuthStates from '../../stores/useAuthStates'

const NotFoundLayout = () => {
	const {lang} = useParams()
	const navigate = useNavigate()
	const setLang = useAppStates(state => state.setLang)
	const {isLogged} = useAuthStates()

	window.scrollTo(0, 0)

	useEffect(() => {
		const setCurrentLang = async () => {
			if (lang) {
				if (lang in VALID_LANG) {
					await i18n.changeLanguage(VALID_LANG[lang])
					setLang(lang)
				} else {
					navigate('/not-found')
				}
			}
		}
		setCurrentLang()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return isLogged ? (
		<Navigate to="/" />
	) : (
		<>
			<Header />
			<CssBaseline />
			<Outlet />
		</>
	)
}

export default NotFoundLayout
