import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Box, IconButton, InputAdornment, Stack, TextField} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {
	MdOutlineVisibility as Visibility,
	MdOutlineVisibilityOff as VisibilityOff
} from 'react-icons/md'
import {useMutation} from '@tanstack/react-query'
import usePasswordResetSchema from '../../../schemas/usePasswordResetSchema'
import {updatePassword} from '../../../api/updatePassword'
import {useFormik} from 'formik'
import AlertMessage from '../../AlertMessage'

interface FormUpdatePasswordProps {
	initialMessage?: string | null
	handleOnSuccess: () => void
	handleIsLoading: (isLoading: boolean) => void
	SubmitButton: () => JSX.Element
}

const FormUpdatePassword = ({
	initialMessage,
	handleOnSuccess,
	handleIsLoading,
	SubmitButton
}: FormUpdatePasswordProps) => {
	const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false)
	const [confirmPasswordVisible, setConfirmPasswordVisible] =
		useState<boolean>(false)
	const [errorAlert, setErrorAlert] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>('')

	const {t} = useTranslation()

	const updatePasswordSchema = usePasswordResetSchema()

	const {mutate} = useMutation(
		(values: {password: string}) => updatePassword(values),
		{
			networkMode: 'always'
		}
	)

	const formik = useFormik({
		initialValues: {
			newPassword: '',
			confirmPassword: ''
		},
		validationSchema: updatePasswordSchema,
		onSubmit: ({newPassword}) => {
			setErrorAlert(false)
			handleIsLoading(true)
			mutate(
				{password: newPassword},
				{
					onSuccess: result => {
						handleIsLoading(false)
						if (result.responseStatusCode === 200) {
							handleOnSuccess()
						}

						if (result.responseStatusCode === 400) {
							if (result.error?.fields) {
								Object.entries(result.error.fields).forEach(([key, value]) => {
									formik.setFieldError(key, value[0].message)
								})
							}
						}

						if (result.responseStatusCode === 500) {
							setErrorAlert(true)
							setErrorMessage(t('error-server-default'))
						}
					},
					onError: error => {
						handleIsLoading(false)
						setErrorAlert(true)
						setErrorMessage(t('error-server-default'))
						console.error(error)
					}
				}
			)
		}
	})

	useEffect(() => {
		if (initialMessage) {
			setErrorMessage(initialMessage)
			setErrorAlert(true)
		}
	}, [initialMessage])

	return (
		<Box
			component="form"
			autoComplete="off"
			onSubmit={formik.handleSubmit}
			sx={style.formBox}
		>
			<AlertMessage
				open={errorAlert}
				severity="error"
				message={errorMessage}
				onClose={() => setErrorAlert(false)}
				mt={'0px! important'}
				sx={{mb: errorAlert ? 4 : 0}}
			/>
			<Stack direction="column" spacing={4}>
				<TextField
					name="newPassword"
					type={newPasswordVisible ? 'text' : 'password'}
					label={t('new-password')}
					variant="outlined"
					margin="normal"
					size="small"
					sx={{mt: 0}}
					helperText={
						formik.touched.newPassword && formik.errors.newPassword
							? formik.errors.newPassword
							: t('error-password-invalid')
					}
					error={
						formik.touched.newPassword && Boolean(formik.errors.newPassword)
					}
					value={formik.values.newPassword}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label={
										newPasswordVisible
											? t('aria-hide-password')
											: t('aria-show-password')
									}
									onClick={() => setNewPasswordVisible(!newPasswordVisible)}
									edge="end"
								>
									{newPasswordVisible ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
				<TextField
					name="confirmPassword"
					type={confirmPasswordVisible ? 'text' : 'password'}
					label={t('confirm-new-password')}
					variant="outlined"
					margin="normal"
					size="small"
					helperText={
						formik.touched.confirmPassword && formik.errors.confirmPassword
							? formik.errors.confirmPassword
							: t('helper-password-confirm')
					}
					error={
						formik.touched.confirmPassword &&
						Boolean(formik.errors.confirmPassword)
					}
					value={formik.values.confirmPassword}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label={
										confirmPasswordVisible
											? t('aria-hide-password')
											: t('aria-show-password')
									}
									onClick={() =>
										setConfirmPasswordVisible(!confirmPasswordVisible)
									}
									edge="end"
								>
									{confirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
				<SubmitButton />
			</Stack>
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			p: 5,
			width: '100%',
			maxWidth: 600,
			[theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
				p: 3
			},
			[theme.breakpoints.down('sm')]: {
				p: 2
			}
		}
	}
}

export default FormUpdatePassword
