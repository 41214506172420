import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {regex} from '../helpers/regex'

const useFeesCalculatorSchema = () => {
	const {t} = useTranslation()

	const feesCalculatorSchema = yup.object({
		amount: yup
			.string()
			.transform(value => String(regex.parseBRLCurrencyToFloat(value)))
			.required(t('error-amount-required')),
		paymentMethod: yup
			.string()
			.oneOf(
				['credit-card', 'boleto', 'pix'],
				t('error-payment-method-required')
			)
			.required(t('error-payment-method-required')),
		installments: yup
			.string()
			.matches(/^\d{1,2}$/, t('error-installments-invalid'))
	})

	return feesCalculatorSchema
}

export default useFeesCalculatorSchema
