import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useFormik} from 'formik'
import dayjs, {Dayjs} from 'dayjs'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {Theme} from '@mui/material/styles'
import {MdOutlineCheck as CheckIcon} from 'react-icons/md'
import {FaCalculator as CalculatorIcon} from 'react-icons/fa'
import {RiArrowDownSFill as ArrowDropDownIcon} from 'react-icons/ri'
import {CgClose as ClearIcon} from 'react-icons/cg'

import {INPUT} from '../../../config'
import useAuthStates from '../../../stores/useAuthStates'
import useAppStates from '../../../stores/useAppStates'
import useEditionSchema from '../../../schemas/useEditionSchema'
import {checkEditionSlug, createEdition} from '../../../api/editions'
import {regex} from '../../../helpers/regex'
import SectionTitle from '../../typography/SectionTitle'
import AlertMessage from '../../AlertMessage'
import Tag from '../../typography/Tag'
import InfoPopover from '../../InfoPopover'
import ButtonIcon from '../../ButtonIcon'
import {
	BoletoFeesCalculatorData,
	CreditCardFeesCalculatorData,
	PixFeesCalculatorData
} from '../../../api/feesCalculator'
import {Dialog} from '../../Dialog'
import FormFeesCalculator from '../../FormFeesCalculator'
import CreditCardFees from '../../PagarmeFeesCalculator/CreditCardFees'
import BoletoFees from '../../PagarmeFeesCalculator/BoletoFees'
import PixFees from '../../PagarmeFeesCalculator/PixFees'
import Autocomplete from '@mui/material/Autocomplete'
import {ExperienceIntegration} from '../../../entities/Integration'
import {OrderBumpCard} from './integrations/OrderBump'
import useNavigate from '../../../hooks/useNavigate'
import {useParams} from 'react-router-dom'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

const convertLocaleToDayjs = (locale: string) => {
	if (locale === 'pt-BR' || locale === 'pt-br' || locale === 'pt') {
		return 'pt-br'
	}

	return 'en'
}

interface FormCreateEditionProps {
	experienceId: string
	integrations?: {orderBump: ExperienceIntegration}
}

const FormCreateEdition = ({
	experienceId,
	integrations
}: FormCreateEditionProps) => {
	const {t} = useTranslation()

	const {experience_slug} = useParams<{experience_slug: string}>()
	const currentLanguage = useAppStates(state => state.currentLang)
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [lastSlugChecked, setLastSlugChecked] = useState<string>('')
	const [slugAvailable, setSlugAvailable] = useState<boolean>(false)
	const [slugHelper, setSlugHelper] = useState<string>(t('helper-slug'))
	const [openFeesCalculator, setOpenFeesCalculator] = useState<boolean>(false)
	const [openFeesDetails, setOpenFeesDetails] = useState<boolean>(false)
	const [paymentMethod, setPaymentMethod] = useState<
		'credit-card' | 'boleto' | 'pix'
	>('credit-card')
	const [creditCardFees, setCreditCardFees] =
		useState<CreditCardFeesCalculatorData>()
	const [boletoFees, setBoletoFees] = useState<BoletoFeesCalculatorData>()
	const [pixFees, setPixFees] = useState<PixFeesCalculatorData>()
	const [checkoutType, setCheckoutType] = useState<
		'one-page' | 'multiple-page'
	>('one-page')

	const [openDatePickerLimitDate, setOpenDatePickerLimitDate] = useState(false)
	const [openDatePickerStartDate, setOpenDatePickerStartDate] = useState(false)

	const sellerId = useAuthStates(state => state.sellerId)
	const editionSchema = useEditionSchema()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const {isLoading, mutate} = useMutation(createEdition, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			title: '',
			slug: '',
			description: '',
			price: undefined,
			pricingUnit: undefined,
			vacancies: undefined,
			startDate: null,
			limitDateToSell: null,
			passCreditCardFeesToCustomer: false,
			allowMultipleItems: false
		} as {
			title: string
			slug: string
			description: string
			price: string | undefined
			pricingUnit: string | undefined
			vacancies: string | undefined
			startDate: Dayjs | null
			limitDateToSell: Dayjs | null
			passCreditCardFeesToCustomer: boolean
			allowMultipleItems: boolean
		},
		validationSchema: editionSchema,
		onSubmit: values => {
			setOpenAlert(false)
			mutate(
				{
					...values,
					price: regex.parseBRLCurrencyToFloat(formik.values.price || '0'),
					startDate: formik.values.startDate
						? formik.values.startDate.toISOString()
						: '',
					limitDateToSell: formik.values.limitDateToSell
						? formik.values.limitDateToSell.toISOString()
						: '',
					vacancies: formik.values.vacancies
						? Number(formik.values.vacancies)
						: undefined,
					experienceId,
					checkoutType
				},
				{
					onSuccess: (result, data) => {
						if (result.responseStatusCode === 201 && result.data) {
							queryClient.invalidateQueries([
								`experiences-${sellerId}`,
								`editions-${experience_slug}`
							])
							navigate(`/products/${experience_slug}`)
							// setOfferId(result.data._id)
						}

						if (result.responseStatusCode === 400 && result.error?.fields) {
							Object.entries(result.error.fields).forEach(([key, value]) => {
								formik.setFieldError(key, value[0].message)
							})
						}

						if (result.responseStatusCode === 500) {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		}
	})

	const {isLoading: isCheckingSlug, mutate: mutateCheckSlug} = useMutation(
		checkEditionSlug,
		{
			networkMode: 'always'
		}
	)

	const checkAvailableSlug = (slug: string) => {
		if (
			!formik.errors.slug &&
			formik.values.slug !== '' &&
			lastSlugChecked !== formik.values.slug
		) {
			setSlugAvailable(false)
			mutateCheckSlug(
				{slug: formik.values.slug, experienceId},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							setSlugAvailable(true)
							if (formik.values.slug !== result.data.slug) {
								setSlugHelper(t('helper-slug-already-in-use'))
							}
							formik.setFieldValue('slug', result.data.slug)
							setLastSlugChecked(result.data.slug)
						}
						if (
							result?.responseStatusCode === 400 &&
							result?.error?.fields?.slug
						) {
							formik.setFieldError('slug', result.error.fields.slug[0].message)
						}
					}
				}
			)
		}
	}

	return (
		<Box
			component="form"
			autoComplete="off"
			onSubmit={formik.handleSubmit}
			sx={style.formBox}
		>
			<Dialog.Root open={openFeesCalculator} maxWidth={900}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenFeesCalculator(false)}>
						{t('fees-calculator')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width="100%">
					<FormFeesCalculator
						amount={regex.parseBRLCurrencyToFloat(
							String(formik.values.price || 0)
						)}
						installments={2}
						paymentMethod={paymentMethod}
						setPaymentMethod={setPaymentMethod}
						onChange={() => {}}
						onSuccess={data => {
							if (paymentMethod === 'credit-card') {
								setCreditCardFees(data as CreditCardFeesCalculatorData)
							} else if (paymentMethod === 'pix') {
								setPixFees(data as PixFeesCalculatorData)
							} else {
								setBoletoFees(data as BoletoFeesCalculatorData)
							}
							setOpenFeesDetails(true)
						}}
					/>
				</Dialog.Body>
			</Dialog.Root>
			<Dialog.Root open={openFeesDetails} maxWidth={900}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenFeesDetails(false)}>
						{t('fees-details')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width="100%">
					<Card variant="outlined">
						<Box>
							{creditCardFees && paymentMethod === 'credit-card' ? (
								<CreditCardFees fees={creditCardFees} />
							) : null}

							{boletoFees && paymentMethod === 'boleto' ? (
								<BoletoFees fees={boletoFees} />
							) : null}

							{pixFees && paymentMethod === 'pix' ? (
								<PixFees fees={pixFees} />
							) : null}
						</Box>
					</Card>
				</Dialog.Body>
			</Dialog.Root>
			<SectionTitle sx={{mb: 3}}>{t('create-edition')}</SectionTitle>
			<Stack spacing={4}>
				<Card
					variant="outlined"
					sx={{
						p: 3
					}}
				>
					<Stack direction="column" spacing={4} pr={3}>
						<Typography variant="h5" sx={{mb: 2}}>
							{t('general-information')}
						</Typography>
						{openAlert && (
							<AlertMessage
								open={openAlert}
								severity="error"
								message={alertMessage}
								onClose={() => setOpenAlert(false)}
							/>
						)}
						<TextField
							name="title"
							type="text"
							label={t('title')}
							value={formik.values.title}
							onChange={event => {
								const currentSlug = regex.formatStringToSlug(event.target.value)
								formik.setFieldValue('slug', currentSlug)
								formik.handleChange(event)
							}}
							onBlur={event => {
								formik.handleBlur(event)
								checkAvailableSlug(event.target.value)
							}}
							helperText={formik.touched.title && formik.errors.title}
							error={formik.touched.title && Boolean(formik.errors.title)}
							variant="outlined"
							size="small"
							inputProps={{maxLength: INPUT.EDITION.title.max}}
							InputProps={{
								endAdornment: (
									<InfoPopover sx={{position: 'absolute', right: -34}}>
										<Typography variant="body2">
											{t('info-title-offer')}
										</Typography>
									</InfoPopover>
								)
							}}
						/>
						<TextField
							name="slug"
							type="text"
							label={t('slug')}
							value={formik.values.slug}
							onChange={event => {
								formik.handleChange(event)
								setSlugHelper(t('helper-slug'))
							}}
							onBlur={event => {
								formik.handleBlur(event)
								checkAvailableSlug(event.target.value)
							}}
							helperText={
								formik.touched.slug && Boolean(formik.errors.slug)
									? formik.errors.slug
									: slugHelper
							}
							error={formik.touched.slug && Boolean(formik.errors.slug)}
							variant="outlined"
							size="small"
							inputProps={{maxLength: INPUT.EDITION.slug.max}}
							sx={style.formInput}
							InputProps={{
								endAdornment: (
									<>
										<InputAdornment position="end">
											{isCheckingSlug && (
												<CircularProgress color="primary" size={20} />
											)}
											{!isCheckingSlug &&
												slugAvailable &&
												lastSlugChecked === formik.values.slug && (
													<IconButton sx={style.checkIcon}>
														<CheckIcon size={20} />
													</IconButton>
												)}
										</InputAdornment>
										<InfoPopover sx={{position: 'absolute', right: -34}}>
											<Typography variant="body2">
												{t('info-edition-slug')}
											</Typography>
										</InfoPopover>
									</>
								)
							}}
						/>
						<TextField
							name="description"
							type="text"
							label={t('description')}
							multiline
							rows={4}
							value={formik.values.description}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							helperText={
								formik.touched.description && formik.errors.description
							}
							error={
								formik.touched.description && Boolean(formik.errors.description)
							}
							variant="outlined"
							size="small"
							inputProps={{maxLength: INPUT.EDITION.description.max}}
							InputProps={{
								endAdornment: (
									<InfoPopover sx={{position: 'absolute', right: -34}}>
										<Typography variant="body2">
											{t('info-edition-description')}
										</Typography>
									</InfoPopover>
								)
							}}
						/>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								width: '100%',
								gap: 7.25
							}}
						>
							<TextField
								name="price"
								label={t('price')}
								value={formik.values.price}
								onChange={event => {
									event.target.value = regex.formatBRLCurrency(
										event.target.value
									)
									formik.handleChange(event)
								}}
								onBlur={formik.handleBlur}
								helperText={formik.touched.price && formik.errors.price}
								error={formik.touched.price && Boolean(formik.errors.price)}
								variant="outlined"
								size="small"
								InputProps={{
									startAdornment: (
										<>
											<InputAdornment position="start">{'R$'}</InputAdornment>
											<InfoPopover sx={{position: 'absolute', right: -34}}>
												<Typography variant="body2">
													{t('info-edition-price')}
												</Typography>
											</InfoPopover>
										</>
									)
								}}
								sx={{width: '50%'}}
							/>
							<Autocomplete
								freeSolo
								id="free-solo-demo"
								options={[
									t('unit-item'),
									t('unit-person'),
									t('unit-service'),
									t('unit-spot')
								].map(option => option)}
								sx={{
									width: '50%'
								}}
								onChange={(event, value) => {
									formik.setFieldValue('pricingUnit', value)
								}}
								renderInput={params => (
									<TextField
										{...params}
										name="pricingUnit"
										label={t('unit')}
										value={formik.values.pricingUnit}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										helperText={
											formik.touched.pricingUnit &&
											Boolean(formik.errors.pricingUnit) ? (
												formik.errors.pricingUnit
											) : (
												<Tag>{t('optional')}</Tag>
											)
										}
										error={
											formik.touched.pricingUnit &&
											Boolean(formik.errors.pricingUnit)
										}
										variant="outlined"
										size="small"
									/>
								)}
							/>
						</Box>
						<TextField
							name="vacancies"
							type="text"
							label={t('vacancies')}
							value={formik.values.vacancies}
							onChange={event => {
								event.target.value = regex.formatStringToNumber(
									event.target.value
								)
								formik.handleChange(event)
							}}
							onBlur={formik.handleBlur}
							helperText={
								formik.touched.vacancies && Boolean(formik.errors.vacancies) ? (
									formik.errors.vacancies
								) : (
									<>
										<Tag>{t('optional')}</Tag>
									</>
								)
							}
							error={
								formik.touched.vacancies && Boolean(formik.errors.vacancies)
							}
							variant="outlined"
							size="small"
							InputProps={{
								endAdornment: (
									<InfoPopover sx={{position: 'absolute', right: -34}}>
										<Typography variant="body2">
											{t('info-edition-vacancies')}
										</Typography>
									</InfoPopover>
								)
							}}
						/>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={convertLocaleToDayjs(currentLanguage)}
						>
							<FormControl sx={[style.formControl, style.formControlDate]}>
								<Box
									sx={theme => ({
										display: 'flex',
										position: 'relative',
										width: '50%',
										[theme.breakpoints.down('sm')]: {
											width: '100%'
										}
									})}
								>
									<DatePicker
										label={t('limit-date-to-sell')}
										value={formik.values.limitDateToSell}
										minDate={dayjs(Date.now())}
										dayOfWeekFormatter={day => `${day}`}
										onChange={newValue => {
											formik.setFieldValue('limitDateToSell', newValue)
										}}
										open={openDatePickerLimitDate}
										onClose={() => {
											setOpenDatePickerLimitDate(false)
										}}
										slotProps={{
											textField: {
												name: 'limitDateToSell',
												size: 'small',
												value: formik.values.limitDateToSell,
												type: 'text',
												variant: 'outlined',
												helperText:
													formik.touched.limitDateToSell &&
													Boolean(formik.errors.limitDateToSell) ? (
														formik.errors.limitDateToSell
													) : (
														<Tag>{t('optional')}</Tag>
													),
												error: Boolean(formik.errors.limitDateToSell),
												sx: {
													flexGrow: 1
												},
												onClick: () => {
													setOpenDatePickerLimitDate(true)
												},
												InputProps: {
													endAdornment: null
												}
											}
										}}
										slots={<TextField />}
									/>
									{formik.values?.limitDateToSell && (
										<Tooltip title={t('to-remove')} arrow>
											<IconButton
												size="small"
												title={t('to-remove')}
												onClick={() =>
													formik.setFieldValue('limitDateToSell', null)
												}
												sx={{
													position: 'absolute',
													right: '8px',
													top: '6px'
												}}
											>
												<ClearIcon />
											</IconButton>
										</Tooltip>
									)}
									<Box sx={style.boxInfoPopover}>
										<InfoPopover sx={{position: 'absolute', right: -34}}>
											<Typography variant="body2">
												{t('info-edition-limit-date-to-sell')}
											</Typography>
										</InfoPopover>
									</Box>
								</Box>
								<Box
									sx={theme => ({
										display: 'flex',
										position: 'relative',
										width: '50%',
										[theme.breakpoints.down('sm')]: {
											width: '100%'
										}
									})}
								>
									<DatePicker
										label={t('start-date')}
										value={formik.values.startDate}
										minDate={dayjs(Date.now())}
										dayOfWeekFormatter={day => `${day}`}
										onChange={newValue => {
											formik.setFieldValue('startDate', newValue)
										}}
										open={openDatePickerStartDate}
										onClose={() => {
											setOpenDatePickerStartDate(false)
										}}
										slotProps={{
											textField: {
												size: 'small',
												name: 'startDate',
												type: 'text',
												value: formik.values.startDate,
												variant: 'outlined',
												helperText:
													formik.touched.startDate &&
													Boolean(formik.errors.startDate) ? (
														formik.errors.startDate
													) : (
														<Tag>{t('optional')}</Tag>
													),
												error: Boolean(formik.errors.startDate),
												sx: {
													flexGrow: 1
												},
												onClick: () => {
													setOpenDatePickerStartDate(true)
												},
												InputProps: {
													endAdornment: null
												}
											}
										}}
										slots={<TextField />}
									/>
									{formik.values?.startDate && (
										<Tooltip title={t('to-remove')} arrow>
											<IconButton
												size="small"
												title={t('to-remove')}
												onClick={() => formik.setFieldValue('startDate', null)}
												sx={{
													position: 'absolute',
													right: '8px',
													top: '6px'
												}}
											>
												<ClearIcon />
											</IconButton>
										</Tooltip>
									)}
									<Box sx={style.boxInfoPopover}>
										<InfoPopover sx={{position: 'absolute', right: -34}}>
											<Typography variant="body2">
												{t('info-edition-start-date')}
											</Typography>
										</InfoPopover>
									</Box>
								</Box>
							</FormControl>
						</LocalizationProvider>
						<Accordion
							disableGutters
							square
							variant="outlined"
							sx={{
								borderRadius: '4px',
								'&.Mui-expanded': {
									mt: 4
								},
								'&.MuiAccordion-root::before': {
									display: 'none'
								}
							}}
						>
							<AccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="advanced-options-content"
								id="advanced-options-header"
								sx={{
									backgroundColor: 'grey.100',
									borderTopLeftRadius: '4px',
									borderTopRightRadius: '4px',
									'&.MuiAccordionSummary-content': {
										my: '12px !important'
									}
								}}
							>
								<Typography variant="subtitle2" fontWeight={500}>
									{t('advanced-settings')}
								</Typography>
							</AccordionSummary>
							<AccordionDetails
								sx={{
									borderTop: '1px solid',
									borderColor: 'divider',
									p: 2
								}}
							>
								<Stack spacing={4}>
									<FormControl sx={{gap: 1.5}}>
										<Typography variant="subtitle1" fontWeight={500}>
											{t('checkout-type')}
										</Typography>
										<FormControl>
											<RadioGroup
												aria-labelledby="checkout-type-label"
												name="checkoutType"
												onChange={event =>
													setCheckoutType(
														event.target.value as 'one-page' | 'multiple-page'
													)
												}
											>
												<FormControlLabel
													value="one-page"
													checked={checkoutType === 'one-page'}
													control={<Radio sx={{fontSize: '0.875rem'}} />}
													label={t('one-page-checkout')}
													sx={{color: 'text.secondary'}}
												/>
												<FormControlLabel
													value="multiple-page"
													checked={checkoutType === 'multiple-page'}
													control={<Radio />}
													label={t('multiple-page-checkout')}
													sx={{color: 'text.secondary'}}
												/>
											</RadioGroup>
										</FormControl>
									</FormControl>
									<FormControl sx={{gap: 1.5}}>
										<Typography variant="subtitle1" fontWeight={500}>
											{t('allow-multiple-items')}
										</Typography>
										<FormControlLabel
											control={
												<Checkbox
													sx={(theme: Theme) => ({
														color: theme.palette.grey[500],
														py: 0
													})}
													name="allowMultipleItems"
													checked={formik.values.allowMultipleItems}
													onChange={formik.handleChange}
												/>
											}
											label={
												<Typography sx={{color: 'text.secondary'}}>
													{t('allow-multiple-items-label')}
												</Typography>
											}
											sx={{alignItems: 'flex-start'}}
										/>
									</FormControl>
									<FormControl sx={{gap: 1.5}}>
										<Typography variant="subtitle1" fontWeight={500}>
											{t('pass-on-fees')}{' '}
											{/* <ButtonIcon
												icon={<CalculatorIcon size={16} />}
												title={t('simulate-in-the-rate-calculator')}
												onClick={() => setOpenFeesCalculator(true)}
											/> */}
										</Typography>
										<FormControlLabel
											control={
												<Checkbox
													sx={(theme: Theme) => ({
														color: theme.palette.grey[500],
														py: 0
													})}
													name="passCreditCardFeesToCustomer"
													checked={formik.values.passCreditCardFeesToCustomer}
													onChange={formik.handleChange}
												/>
											}
											label={
												<Typography sx={{color: 'text.secondary'}}>
													{t('label-pass-credit-card-fees-to-customer')}
												</Typography>
											}
										/>
									</FormControl>
								</Stack>
							</AccordionDetails>
						</Accordion>
					</Stack>
					<Button
						size="large"
						variant="contained"
						type="submit"
						disabled={isLoading}
						sx={style.formSubmitButton}
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('to-create-edition')
						)}
					</Button>
				</Card>
				{/* {integrations && (
					<IntegrationSection
						orderBump={integrations.orderBump}
						productId={experienceId}
						offerId={offerId}
					/>
				)} */}
			</Stack>
		</Box>
	)
}

const IntegrationSection = ({
	orderBump,
	productId,
	offerId
}: {
	offerId?: string
	productId: string
	orderBump?: ExperienceIntegration
}) => {
	const orderBumpRef = useRef<HTMLDivElement>(null)
	const {t} = useTranslation()

	useEffect(() => {
		if (offerId && orderBumpRef.current) {
			orderBumpRef.current.scrollIntoView({behavior: 'smooth'})
		}
	}, [orderBumpRef, offerId])

	return (
		<Box>
			<Typography variant="h5" mb={3}>
				{t('integrations')}
			</Typography>

			{orderBump && offerId && (
				<OrderBumpCard
					ref={orderBumpRef}
					offerId={offerId}
					productId={productId}
					orderBumpIntegration={orderBump}
				/>
			)}
			{orderBump && !offerId && <OrderBumpEmptyState orderBump={orderBump} />}
		</Box>
	)
}

const OrderBumpEmptyState = ({
	orderBump
}: {
	orderBump: ExperienceIntegration
}) => {
	const {t} = useTranslation()
	const [openPopover, setOpenPopover] = useState<boolean>(false)

	return (
		<Card
			sx={{maxWidth: 600, p: 3, opacity: 0.5, position: 'relative'}}
			variant="outlined"
			onMouseEnter={() => setOpenPopover(true)}
			onMouseLeave={() => setOpenPopover(false)}
		>
			<Box sx={{display: 'flex', alignItems: 'center', gap: 1.5}}>
				<Box
					sx={{
						width: 50,
						height: 50,
						backgroundColor: '#EFF3FF',
						backgroundImage: `url("${orderBump.integration.icon}")`,
						backgroundSize: '60%',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						borderRadius: '50%'
					}}
				/>
				<Typography variant="h6">{t('integration-order-bump')}</Typography>
			</Box>
			<Typography variant="body1" color="textSecondary" sx={{mt: 1}}>
				{t('integration-order-bump-description')}
			</Typography>
			<Button variant="contained" sx={{mt: 2}} disabled>
				{t('to-view-offer-groups')}
			</Button>
			<Box sx={{position: 'absolute', top: 0, right: 0}}>
				<InfoPopover open={openPopover}>
					<Typography sx={{maxWidth: 250}}>
						{t('add-offer-first-to-add-order-bump')}
					</Typography>
				</InfoPopover>
			</Box>
		</Card>
	)
}

const style = {
	formBox: (theme: Theme) => ({
		my: 5,
		mt: 0,
		mb: 5,
		pt: 0,
		width: '100%',
		maxWidth: '600px'
	}),
	formControl: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'row',
		width: '100%'
	}),
	formControlDate: (theme: Theme) => ({
		gap: 7.25,
		[theme.breakpoints.down('sm')]: {
			gap: 4,
			flexDirection: 'column'
		}
	}),
	formInput: (theme: Theme) => ({
		flexGrow: 1
	}),
	formSubmitButton: (theme: Theme) => ({
		alignSelf: 'flex-start',
		mt: 4,
		height: '44px',
		minWidth: '165px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	checkIcon: (theme: Theme) => ({
		color: theme.palette.success.main,
		p: 0
	}),
	boxInfoPopover: {
		position: 'relative',
		mt: 0.375
	}
}

export default FormCreateEdition
