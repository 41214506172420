import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import AlertMessage from '../../../components/AlertMessage'
import {useTranslation} from 'react-i18next'
import Tag from '../../../components/typography/Tag'
import BankSelect from '../../../components/BankSelect'
import {useRef, useState} from 'react'
import useBankAccountSchema from '../../../schemas/useBankAccountSchema'
import {useFormik} from 'formik'
import useAsaasStates from '../../../stores/useAsaasStates'
import {useMutation} from '@tanstack/react-query'
import {updateBankAccountAsaas} from '../../../api/payment-service/asaas'
import InputMask from '../../../components/InputMask'
import {Theme} from '@mui/material/styles'
import useAuthStates from '../../../stores/useAuthStates'
import {regex} from '../../../helpers/regex'

export const FormBankAccount = () => {
	const [alertErrorMessage, setAlertErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)

	const alertRef = useRef<HTMLDivElement>(null)
	const {t} = useTranslation()
	const bankAccountSchema = useBankAccountSchema()
	const {asaasBankAccount: bankAccount, setAsaasBankAccount: setBankAccount} =
		useAsaasStates()
	const {company} = useAuthStates()

	const {mutate, isLoading} = useMutation(updateBankAccountAsaas, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			accountType: bankAccount?.accountType || 'checking',
			accountNumber: bankAccount?.accountNumber || '',
			accountCheckDigit: bankAccount?.accountCheckDigit || '',
			bankCode: bankAccount?.bankCode || '',
			branchNumber: bankAccount?.branchNumber || '',
			branchCheckDigit: bankAccount?.branchCheckDigit || ''
		},
		enableReinitialize: true,
		validationSchema: bankAccountSchema,
		onSubmit: values => {
			setOpenAlertError(false)
			mutate(values, {
				onSuccess: ({error, responseStatusCode}) => {
					if (responseStatusCode === 200) {
						setShowSuccessButton(true)
						setBankAccount(values)
					} else if (responseStatusCode === 400 && error?.fields) {
						Object.entries(error.fields).forEach(([key, value]) => {
							formik.setFieldError(key, value[0].message)
						})
					} else {
						setAlertErrorMessage(error?.message || t('error-server-default'))
						setOpenAlertError(true)
						alertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
							inline: 'start'
						})
					}
				},
				onError: error => {
					setAlertErrorMessage(t('error-server-default'))
					setOpenAlertError(true)
					alertRef.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'start'
					})
					console.error(error)
				}
			})
		}
	})

	return (
		<Box
			onSubmit={formik.handleSubmit}
			onChange={() => {
				setOpenAlertError(false)
				setShowSuccessButton(false)
			}}
			component="form"
			autoComplete="off"
			sx={styles.formBox}
		>
			<Typography variant="h5" component="h2">
				{t('edit-bank-account')}
			</Typography>
			<Typography sx={{mt: 1}}>
				{t('edit-bank-account-description-asaas')}
			</Typography>
			<Stack direction="column" spacing={4}>
				<AlertMessage
					ref={alertRef}
					severity="error"
					open={openAlertError}
					onClose={() => setOpenAlertError(false)}
					message={alertErrorMessage}
				/>
				<TextField
					label={t('cnpj')}
					value={company?.cnpj && regex.formatCNPJ(company?.cnpj)}
					disabled
					size="small"
				/>
				<TextField
					label={t('legal-name')}
					value={company?.legalName && regex.formatCNPJ(company?.legalName)}
					disabled
					size="small"
				/>
				<BankSelect
					name="bankCode"
					label={t('bank-code')}
					valueFormat="label"
					code={formik.values.bankCode}
					onChange={formik.handleChange}
					error={formik.touched.bankCode && Boolean(formik.errors.bankCode)}
					helperText={formik.touched.bankCode && formik.errors.bankCode}
				/>
				<TextField
					sx={styles.inputBranchNumber}
					name="branchNumber"
					type="text"
					InputProps={{
						inputComponent: InputMask as any,
						inputProps: {
							mask: '0000'
						}
					}}
					label={t('branch-number')}
					variant="outlined"
					helperText={formik.touched.branchNumber && formik.errors.branchNumber}
					error={
						formik.touched.branchNumber && Boolean(formik.errors.branchNumber)
					}
					value={formik.values.branchNumber}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					size="small"
				/>
				<TextField
					name="branchCheckDigit"
					type="text"
					inputProps={{
						maxLength: 1
					}}
					label={t('branch-check-digit')}
					variant="outlined"
					helperText={
						formik.touched.branchCheckDigit &&
						Boolean(formik.errors.branchCheckDigit) ? (
							formik.errors.branchCheckDigit
						) : (
							<Tag>{t('optional')}</Tag>
						)
					}
					error={
						formik.touched.branchCheckDigit &&
						Boolean(formik.errors.branchCheckDigit)
					}
					value={formik.values.branchCheckDigit}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					size="small"
				/>
				<TextField
					sx={styles.inputBranchNumber}
					name="accountNumber"
					type="text"
					label={t('account-number')}
					variant="outlined"
					inputProps={{
						maxLength: 13
					}}
					helperText={
						formik.touched.accountNumber && formik.errors.accountNumber
					}
					error={
						formik.touched.accountNumber && Boolean(formik.errors.accountNumber)
					}
					value={formik.values.accountNumber}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					size="small"
				/>
				<TextField
					name="accountCheckDigit"
					type="text"
					inputProps={{
						maxLength: 2
					}}
					label={t('account-check-digit')}
					variant="outlined"
					helperText={
						formik.touched.accountCheckDigit && formik.errors.accountCheckDigit
					}
					error={
						formik.touched.accountCheckDigit &&
						Boolean(formik.errors.accountCheckDigit)
					}
					value={formik.values.accountCheckDigit}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					size="small"
				/>
				<FormControl>
					<FormLabel id="select-account-type-label">
						{t('account-type')}
					</FormLabel>
					<RadioGroup
						row
						aria-labelledby="select-account-type-label"
						name="accountType"
						value={formik.values.accountType}
						onChange={formik.handleChange}
					>
						<FormControlLabel
							value="checking"
							control={<Radio />}
							label={t('account-type-checking')}
						/>
						<FormControlLabel
							value="savings"
							control={<Radio />}
							label={t('account-type-savings')}
						/>
					</RadioGroup>
				</FormControl>
				{showSuccessButton ? (
					<Button
						size="large"
						variant="contained"
						type="button"
						sx={styles.successButton}
					>
						{t('saved-changes')}
					</Button>
				) : (
					<Button
						size="large"
						variant="contained"
						type="submit"
						disabled={isLoading}
						sx={styles.formSubmitButton}
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('to-save-changes')
						)}
					</Button>
				)}
			</Stack>
		</Box>
	)
}

const styles = {
	formBox: (theme: Theme) => ({
		my: 5,
		mt: 0,
		pt: 0,
		width: '100%',
		maxWidth: '520px'
	}),
	formSubmitButton: (theme: Theme) => ({
		height: '44px',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	inputBranchNumber: (theme: Theme) => ({
		flexGrow: 1
	}),
	successButton: (theme: Theme) => ({
		height: '44px',
		backgroundColor: 'success.light',
		color: 'success.contrastText',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		'&:hover': {backgroundColor: 'success.light'}
	})
}
