import {Breadcrumbs, Container} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {FaHome as HomeIcon} from 'react-icons/fa'

import Footer from '../../components/template/Footer'
import Link from '../../components/navigation/Link'
import FormCreateExperience from '../../components/forms/experiences/FormCreateExperience'
import BackNavigation from '../../components/navigation/BackNavigation'
import {DashboardFullSection} from '../../components/template/DashboardFullSection'

const CreateExperience = () => {
	const {t} = useTranslation()

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link to="/products">
						<span>{t('experiences')}</span>
					</Link>
					<span>{t('create-experience')}</span>
				</Breadcrumbs>
				<BackNavigation to="/products" />
			</Container>
			<DashboardFullSection>
				<FormCreateExperience />
			</DashboardFullSection>
			<Footer />
		</>
	)
}

export default CreateExperience
