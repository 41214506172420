import {create} from 'zustand'
import {Profile} from '../entities/Profile'
import {Onboarding} from '../entities/Onboarding'
import {SalePage} from '../entities/SalePage'
import {PaymentService} from '../entities/PaymentService'
import {Company} from '../entities/Company'
import {Sale} from '../entities/Sale'

interface authStates {
	isLogged: boolean
	accessToken: string
	sellerId: string
	email: string
	profile: Profile | null
	company: Company | null
	paymentService: PaymentService | null
	salePage: SalePage | null
	sales: Sale[]
	salesLength: number
	onboarding: Onboarding
	onboardingCompleted: Boolean
	login: (values: {
		accessToken: string
		sellerId: string
		email: string
		onboarding: Onboarding
		onboardingCompleted: Boolean
		profile: Profile | null
		company: Company | null
		paymentService: PaymentService | null
	}) => void
	logout: () => void
	updateProfile: (profile: Profile) => void
	setCompany: (company: Company) => void
	setOnboarding: (onboarding: Onboarding) => void
	setPaymentService: (paymentService: Partial<PaymentService>) => void
	removePaymentService: () => void
	setSales: (sales: {data: Sale[]; total: number}) => void
	setSalesLength: (salesLength: number) => void
	updateEmail: (email: string) => void
	updateSalePage: (salePage: SalePage | null) => void
	updateActivePaymentService: (activeService: 'pagarme' | 'asaas') => void
}

const useAuthStates = create<authStates>()(set => ({
	isLogged: false,
	accessToken: '',
	sellerId: '',
	email: '',
	profile: null,
	company: null,
	paymentService: null,
	salePage: null,
	sales: [],
	salesLength: 0,
	onboarding: {
		company: false,
		profile: false,
		paymentService: false,
		experiences: false
	},
	onboardingCompleted: false,
	login: data =>
		set({
			isLogged: true,
			...data
		}),
	logout: () =>
		set({
			isLogged: false,
			accessToken: '',
			sellerId: '',
			email: '',
			profile: null,
			company: null,
			paymentService: null,
			salePage: null,
			onboarding: {
				company: false,
				profile: false,
				paymentService: false,
				experiences: false
			},
			onboardingCompleted: false
		}),
	updateProfile: profile => set({profile}),
	setCompany: company => set({company}),
	setOnboarding: onboarding => set({onboarding}),
	setPaymentService: paymentService =>
		set(state => ({
			paymentService: {...state.paymentService, ...paymentService}
		})),
	removePaymentService: () => set({paymentService: null}),
	setSales: sales =>
		set({
			sales: sales.data,
			salesLength: sales.total
		}),
	setSalesLength: salesLength => set({salesLength}),
	updateEmail: email => set({email}),
	updateSalePage: salePage => set({salePage}),
	updateActivePaymentService: activeService =>
		set(state => {
			if (!state.paymentService) return state

			return {
				paymentService: {
					...state.paymentService,
					activeService
				}
			}
		})
}))

export default useAuthStates
