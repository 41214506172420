import OnboardingSteps from './OnboardingSteps'
import FullSection from '../../components/layout/utils/FullSection'
import Footer from '../../components/template/Footer'

const Onboarding = () => {
	return (
		<>
			<FullSection height="auto" minHeight="calc(100vh - 330px)">
				<OnboardingSteps />
			</FullSection>
			<Footer />
		</>
	)
}

export default Onboarding
