import {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {MdOutlineCheck as CheckIcon} from 'react-icons/md'

import {INPUT} from '../../../config'
import useAuthStates from '../../../stores/useAuthStates'
import useNavigate from '../../../hooks/useNavigate'
import useExperienceSchema from '../../../schemas/useExperienceSchema'
import {checkExperienceSlug, createExperience} from '../../../api/experiences'
import SectionTitle from '../../typography/SectionTitle'
import AlertMessage from '../../AlertMessage'
import {regex} from '../../../helpers/regex'
import InfoPopover from '../../InfoPopover'
import useExperienceCoverSchema from '../../../schemas/useExperienceCoverSchema'
import {ValidationError} from 'yup'
import CoverUpload from '../../CoverUpload'

const FormCreateExperience = () => {
	const {t} = useTranslation()

	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [lastSlugChecked, setLastSlugChecked] = useState<string>('')
	const [slugAvailable, setSlugAvailable] = useState<boolean>(false)
	const [slugHelper, setSlugHelper] = useState<string>(t('helper-slug'))
	const [previewImage, setPreviewImage] = useState<string | null>(null)
	const errorAlertRef = useRef<HTMLDivElement>(null)

	const sellerId = useAuthStates(state => state.sellerId)
	const {onboarding, setOnboarding} = useAuthStates(state => state)
	const navigate = useNavigate()
	const experienceSchema = useExperienceSchema()

	const queryClient = useQueryClient()
	const {isLoading, mutate} = useMutation(createExperience, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			title: '',
			slug: '',
			description: '',
			cover: null
		},
		validationSchema: experienceSchema,
		onSubmit: values => {
			mutate(values, {
				onSuccess: (result, data) => {
					if (result.responseStatusCode === 201 && result.data) {
						if (!onboarding.experiences) {
							setOnboarding({...onboarding, experiences: true})
						}

						const experience = result.data
						navigate(`/products/${experience.slug}`, {
							state: {experience}
						})
						queryClient.invalidateQueries([`experiences-${sellerId}`])
					}

					if (result.responseStatusCode === 400 && result.error?.fields) {
						Object.entries(result.error.fields).forEach(([key, value]) => {
							formik.setFieldError(key, value[0].message)
						})
					}

					if (result.responseStatusCode === 500) {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						errorAlertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					errorAlertRef.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					})
					console.error(error)
				}
			})
		}
	})

	const {isLoading: isCheckingSlug, mutate: mutateCheckSlug} = useMutation(
		checkExperienceSlug,
		{
			networkMode: 'always'
		}
	)

	const checkAvailableSlug = (slug: string) => {
		if (
			!formik.errors.slug &&
			formik.values.slug !== '' &&
			lastSlugChecked !== formik.values.slug
		) {
			setSlugAvailable(false)
			mutateCheckSlug(
				{slug: formik.values.slug},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							setSlugAvailable(true)
							if (formik.values.slug !== result.data.slug) {
								setSlugHelper(t('helper-slug-already-in-use'))
							}
							formik.setFieldValue('slug', result.data.slug)
							setLastSlugChecked(result.data.slug)
						}
						if (
							result?.responseStatusCode === 400 &&
							result?.error?.fields?.slug
						) {
							formik.setFieldError('slug', result.error.fields.slug[0].message)
						}
					}
				}
			)
		}
	}

	const handleDeleteCover = () => {
		setPreviewImage(null)
		formik.setFieldValue('cover', null)
	}

	const coverSchema = useExperienceCoverSchema()

	const handleUploadCover = async (file: File) => {
		const errorMessage = await coverSchema['cover']
			.validate(file)
			.then(() => {
				setPreviewImage(URL.createObjectURL(file))
				formik.setFieldValue('cover', file)
			})
			.catch((err: ValidationError) => {
				setPreviewImage(null)
				formik.setFieldError('cover', err.errors[0])
				return err.errors[0]
			})

		if (errorMessage) {
			return errorMessage
		}
	}

	return (
		<Box
			sx={styles.formBox}
			component="form"
			autoComplete="off"
			onSubmit={formik.handleSubmit}
		>
			<SectionTitle sx={{mb: openAlert ? 0 : 4}}>
				{t('create-experience')}
			</SectionTitle>
			<AlertMessage
				ref={errorAlertRef}
				open={openAlert}
				severity="error"
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
				sx={{mb: openAlert ? 2 : 0}}
			/>
			<Stack direction="column" spacing={4} mr={3}>
				<FormControl sx={styles.formControlImage}>
					<Typography variant="subtitle1" sx={{mb: 2}}>
						{t('upload-cover')}
					</Typography>
					<CoverUpload
						url={previewImage}
						label={t('label-upload-experience-cover')}
						previewImage={previewImage}
						setPreviewImage={setPreviewImage}
						onUpload={handleUploadCover}
						onDelete={handleDeleteCover}
					/>
					{formik.errors.cover ? (
						<FormHelperText error sx={styles.helperText}>
							{formik.errors.cover}
						</FormHelperText>
					) : (
						<FormHelperText sx={styles.helperText}>
							{t('helper-image-upload-cover')}
						</FormHelperText>
					)}
				</FormControl>
				<TextField
					name="title"
					type="text"
					label={t('title')}
					value={formik.values.title}
					onChange={event => {
						const currentSlug = regex.formatStringToSlug(event.target.value)
						formik.setFieldValue('slug', currentSlug)
						formik.handleChange(event)
					}}
					onBlur={event => {
						formik.handleBlur(event)
						checkAvailableSlug(event.target.value)
					}}
					helperText={formik.touched.title && formik.errors.title}
					error={formik.touched.title && Boolean(formik.errors.title)}
					variant="outlined"
					size="small"
					margin="normal"
					inputProps={{maxLength: INPUT.EXPERIENCE.title.max}}
					InputProps={{
						endAdornment: (
							<InfoPopover sx={{position: 'absolute', right: -34}}>
								<Typography variant="body2">
									{t('info-title-product-or-service')}
								</Typography>
							</InfoPopover>
						)
					}}
				/>
				<TextField
					name="slug"
					type="text"
					label={t('slug')}
					value={formik.values.slug}
					onChange={event => {
						formik.handleChange(event)
						setSlugHelper(t('helper-slug'))
					}}
					onBlur={event => {
						formik.handleBlur(event)
						checkAvailableSlug(event.target.value)
					}}
					helperText={
						formik.touched.slug && Boolean(formik.errors.slug)
							? formik.errors.slug
							: slugHelper
					}
					error={formik.touched.slug && Boolean(formik.errors.slug)}
					variant="outlined"
					size="small"
					margin="normal"
					inputProps={{maxLength: INPUT.EXPERIENCE.slug.max}}
					InputProps={{
						endAdornment: (
							<>
								<InputAdornment position="end">
									{isCheckingSlug && (
										<CircularProgress color="primary" size={20} />
									)}
									{!isCheckingSlug &&
										slugAvailable &&
										lastSlugChecked === formik.values.slug && (
											<IconButton sx={styles.checkIcon}>
												<CheckIcon size={20} />
											</IconButton>
										)}
								</InputAdornment>
								<InfoPopover sx={{position: 'absolute', right: -34}}>
									<Typography variant="body2">
										{t('info-experience-slug')}
									</Typography>
								</InfoPopover>
							</>
						)
					}}
				/>
				<TextField
					name="description"
					type="text"
					label={t('description')}
					multiline
					rows={4}
					value={formik.values.description}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					helperText={formik.touched.description && formik.errors.description}
					error={
						formik.touched.description && Boolean(formik.errors.description)
					}
					inputProps={{maxLength: INPUT.EXPERIENCE.description.max}}
					variant="outlined"
					size="small"
					margin="normal"
					InputProps={{
						endAdornment: (
							<InfoPopover sx={{position: 'absolute', right: -34}}>
								<Typography variant="body2">
									{t('info-experience-description')}
								</Typography>
							</InfoPopover>
						)
					}}
				/>
			</Stack>
			<Button
				size="large"
				variant="contained"
				type="submit"
				disabled={isLoading}
				sx={styles.formSubmitButton}
			>
				{isLoading ? (
					<CircularProgress color="inherit" size={25} />
				) : (
					t('to-create')
				)}
			</Button>
		</Box>
	)
}

const styles = {
	checkIcon: (theme: Theme) => ({
		color: theme.palette.success.main,
		p: 0
	}),
	formBox: (theme: Theme) => ({
		my: 5,
		mt: 0,
		mb: 5,
		pt: 0,
		width: '100%',
		maxWidth: '520px'
	}),
	formControlImage: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formSubmitButton: (theme: Theme) => ({
		alignSelf: 'flex-start',
		height: '44px',
		minWidth: '180px',
		mt: 4,
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	helperText: {
		margin: 0,
		marginTop: 1
	}
}

export default FormCreateExperience
