import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {image} from '../helpers/image'
import {IMAGE} from '../config'

const useExperienceCoverSchema = () => {
	const {t} = useTranslation()

	const experienceCoverSchema = {
		cover: yup
			.mixed()
			.test(
				'fileType',
				t('error-image-type-cover'),
				file => file && image.validate.coverType({file})
			)
			.test(
				'fileSize',
				t('error-image-max-size'),
				file =>
					file &&
					image.validate.size({file, maxSize: IMAGE.experienceCover.max_size})
			)
			.test(
				'fileMinDimensions',
				t('error-image-min-dimensions', {
					minHeight: IMAGE.experienceCover.dimensions.min.height,
					minWidth: IMAGE.experienceCover.dimensions.min.width
				}),
				file => file && image.validate.minDimensions({file})
			)
			.test(
				'fileMaxDimensions',
				t('error-image-max-dimensions', {
					maxHeight: IMAGE.experienceCover.dimensions.max.height,
					maxWidth: IMAGE.experienceCover.dimensions.max.width
				}),
				file => file && image.validate.maxDimensions({file})
			)
	}

	return experienceCoverSchema
}

export default useExperienceCoverSchema
