import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {PiFileCsv as CsvIcon} from 'react-icons/pi'
import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Customer} from '../../../entities/Customer'
import {Dialog} from '../../../components/Dialog'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import {LoadingDialog} from '../../../components/LoadingDialog'
import CircularProgress from '@mui/material/CircularProgress'
import InfoPopover from '../../../components/InfoPopover'
import {Typography} from '@mui/material'

interface ExportCustomersToCSVProps {
	customers: Customer[]
}

export const ExportCustomersToCSV = ({
	customers
}: ExportCustomersToCSVProps) => {
	const {t} = useTranslation()

	const [openDialog, setOpenDialog] = useState(false)
	const [isExporting, setIsExporting] = useState(false)

	const [headers, setHeaders] = useState({
		name: true,
		email: true,
		phone: true,
		type: false,
		documentType: false,
		document: false,
		newsletter: false
	})

	const {name, email, phone, type, documentType, document, newsletter} = headers

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHeaders({
			...headers,
			[event.target.name]: event.target.checked
		})
	}

	const handleOpenDialog = () => {
		setOpenDialog(true)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	const handleExport = useCallback(() => {
		setIsExporting(true)

		const headerMapping = {
			name: t('name'),
			email: t('email'),
			phone: t('phone'),
			type: t('customer-type'),
			documentType: t('document-type'),
			document: t('document-number'),
			newsletter: t('newsletter')
		}

		Object.entries(headers).forEach(([key, value]) => {
			if (!value) {
				delete headerMapping[key as keyof typeof headerMapping]
			}
		})

		exportListToCSV({
			title: t('customers'),
			list: customers,
			headerMapping,
			onCompleted: () => setIsExporting(false)
		})
	}, [headers, customers, t])

	return (
		<>
			<LoadingDialog open={isExporting} message={t('exporting')} />
			<Dialog.Root open={openDialog}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenDialog(false)}>
						{t('export-customers-sheet')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width={600}>
					<Box>
						<FormControl component="fieldset" variant="standard">
							<FormLabel>{t('choose-fields-to-export')}</FormLabel>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={name}
											onChange={handleChange}
											name="name"
										/>
									}
									label={t('name')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={email}
											onChange={handleChange}
											name="email"
										/>
									}
									label={t('email')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={phone}
											onChange={handleChange}
											name="phone"
										/>
									}
									label={t('phone')}
								/>
								<FormGroup
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center'
									}}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={type}
												onChange={handleChange}
												name="type"
											/>
										}
										label={t('customer-type')}
										sx={{
											mr: 0
										}}
									/>
									<InfoPopover
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left'
										}}
									>
										<Typography>{t('customer-type-info')}</Typography>
									</InfoPopover>
								</FormGroup>
								<FormGroup
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center'
									}}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={documentType}
												onChange={handleChange}
												name="documentType"
											/>
										}
										label={t('document-type')}
										sx={{
											mr: 0
										}}
									/>
									<InfoPopover
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left'
										}}
									>
										<Typography>{t('document-type-info')}</Typography>
									</InfoPopover>
								</FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={document}
											onChange={handleChange}
											name="document"
										/>
									}
									label={t('document-number')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={newsletter}
											onChange={handleChange}
											name="newsletter"
										/>
									}
									label={t('newsletter')}
								/>
							</FormGroup>
						</FormControl>
					</Box>
				</Dialog.Body>
				<Dialog.Footer>
					<Button variant="outlined" onClick={handleCloseDialog}>
						{t('cancel')}
					</Button>
					<Button
						variant="contained"
						onClick={handleExport}
						disabled={isExporting}
						sx={{
							width: 110
						}}
					>
						{isExporting ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('to-export')
						)}
					</Button>
				</Dialog.Footer>
			</Dialog.Root>
			<Button
				variant="outlined"
				endIcon={<CsvIcon />}
				onClick={handleOpenDialog}
			>
				{t('to-export')}
			</Button>
		</>
	)
}

const exportListToCSV = ({
	title,
	list,
	headerMapping,
	onCompleted
}: {
	title: string
	list: any[]
	headerMapping: {[key: string]: string}
	onCompleted: () => void
}) => {
	const headers = Object.values(headerMapping).toString()

	const main = list.map(item => {
		const newObj = Object.keys(headerMapping).reduce(
			(obj: any, key: string) => {
				if (item[key]) {
					obj[key] = item[key]
				}

				return obj
			},
			{}
		)

		return Object.values(newObj).toString()
	})

	const csv = [headers, ...main].join('\n')

	const blob = new Blob([csv], {type: 'application/csv'})
	const url = window.URL.createObjectURL(blob)
	const a = document.createElement('a')
	const unique = new Date().getTime()

	a.download = `${title}-${unique}.csv`
	a.href = url
	a.style.display = 'none'
	document.body.appendChild(a)
	a.click()
	a.remove()
	window.URL.revokeObjectURL(url)

	onCompleted()
}
