export const colorConvert = {
	hexToRgb: (hex: string) => {
		const hexValue = hex.replace('#', '')
		const r = parseInt(hexValue.substring(0, 2), 16)
		const g = parseInt(hexValue.substring(2, 4), 16)
		const b = parseInt(hexValue.substring(4, 6), 16)
		return [r, g, b]
	},
	rgbToHex: (rgb: number[]) => {
		const r = rgb[0].toString(16).padStart(2, '0')
		const g = rgb[1].toString(16).padStart(2, '0')
		const b = rgb[2].toString(16).padStart(2, '0')
		return `#${r}${g}${b}`
	},
	isDark: (hex: string) => {
		const [r, g, b] = colorConvert.hexToRgb(hex)
		return r + g + b < 400
	}
}
