import {useEffect, useState} from 'react'

const differenceInSeconds = (now: Date, start: Date) => {
	const diff = Math.abs(now.getTime() - start.getTime())
	return Math.floor(diff / 1000)
}

interface CountDownProps {
	initialTime: number
	expirationTimeInSeconds: number
	type?: 'hours' | 'minutes' | 'seconds'
	onExpired: () => void
}

const Countdown = ({
	initialTime,
	expirationTimeInSeconds,
	type,
	onExpired
}: CountDownProps) => {
	const [secondsPassed, setSecondsPassed] = useState(0)

	const totalSeconds = expirationTimeInSeconds

	useEffect(() => {
		let interval: NodeJS.Timeout

		interval = setInterval(() => {
			const now = new Date()
			const startTime = new Date(initialTime)
			const secondsDifference = differenceInSeconds(now, startTime)

			if (secondsDifference >= totalSeconds) {
				onExpired()
				setSecondsPassed(totalSeconds)
				clearInterval(interval)
			} else {
				setSecondsPassed(secondsDifference)
			}
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}, [totalSeconds, initialTime, onExpired])

	const currentSeconds = totalSeconds - secondsPassed

	const hoursAmount = Math.floor(currentSeconds / 3600)
	const minutesAmount = Math.floor((currentSeconds % 3600) / 60)
	const secondsAmount = currentSeconds % 60

	const hours = String(hoursAmount).padStart(2, '0')
	const minutes = String(minutesAmount).padStart(2, '0')
	const seconds = String(secondsAmount).padStart(2, '0')

	switch (type) {
		case 'minutes':
			return (
				<span
					style={{width: 37, display: 'inline-block', textAlign: 'left'}}
				>{`${minutes[0]}${minutes[1]}:${seconds[0]}${seconds[1]}`}</span>
			)
		case 'seconds':
			return (
				<span
					style={{display: 'inline-block', textAlign: 'left'}}
				>{`${seconds[0]}${seconds[1]}`}</span>
			)
		default:
			return (
				<span
					style={{width: 62, display: 'inline-block', textAlign: 'left'}}
				>{`${hours[0]}${hours[1]}:${minutes[0]}${minutes[1]}:${seconds[0]}${seconds[1]}`}</span>
			)
	}
}

export default Countdown
