import {Box, Button, Card, CardContent, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {IoArrowForward as ArrowRightIcon} from 'react-icons/io5'

interface CardStartSellingProps {
	onFinish: () => void
}

const CardStartSelling = ({onFinish}: CardStartSellingProps) => {
	const {t} = useTranslation()

	return (
		<Box sx={{maxWidth: 400, margin: '0 auto'}}>
			<Card variant="outlined">
				<CardContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 2
					}}
				>
					<Typography variant="h3" textAlign="center">
						🎉
					</Typography>
					<Typography variant="h5" textAlign="center">
						{t('start-selling-title')}
					</Typography>
					<Typography variant="body1" textAlign="center">
						{t('start-selling-info')}
					</Typography>
					<Button
						onClick={onFinish}
						variant="contained"
						endIcon={<ArrowRightIcon size={16} />}
						sx={{mt: 2}}
					>
						{t('go-to-dashboard')}
					</Button>
				</CardContent>
			</Card>
		</Box>
	)
}

export default CardStartSelling
