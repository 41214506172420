import * as yup from 'yup'

import {useTranslation} from 'react-i18next'
import {image} from '../helpers/image'

const useCreateProfileSchema = () => {
	const {t} = useTranslation()

	const createProfileSchema = yup.object({
		name: yup
			.string()
			.min(3, t('error-name-min-length'))
			.max(60, t('error-name-max-length'))
			.required(t('error-name-required')),
		nameSlug: yup
			.string()
			.matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t('error-slug-invalid'))
			.min(3, t('error-name-slug-min-length'))
			.max(60, t('error-name-slug-max-length'))
			.required(t('error-name-slug-required')),
		logoLight: yup
			.mixed()
			.required(t('error-image-required'))
			.test('fileType', t('error-image-type-logo'), file => {
				return file && image.validate.logoType({file})
			})
			.test(
				'fileSize',
				t('error-image-max-size'),
				file => file && image.validate.size({file})
			),
		logoDark: yup
			.mixed()
			.required(t('error-image-required'))
			.test('fileType', t('error-image-type-logo'), file => {
				return file && image.validate.logoType({file})
			})
			.test(
				'fileSize',
				t('error-image-max-size'),
				file => file && image.validate.size({file})
			),
		avatar: yup
			.mixed()
			.required(t('error-image-required'))
			.test('fileType', t('error-image-type-avatar'), file => {
				return file && image.validate.avatarType({file})
			})
			.test(
				'fileSize',
				t('error-image-max-size'),
				file => file && image.validate.size({file})
			)
	})

	return createProfileSchema
}

export default useCreateProfileSchema
