import Dropzone, {DropEvent, FileRejection} from 'react-dropzone'
import {useTranslation} from 'react-i18next'

import {Box, FormHelperText} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {useCallback, useEffect, useState} from 'react'
import {MdOutlineCrop as CropIcon} from 'react-icons/md'
import CropEasy from './crop/CropEasy'
import {image} from '../helpers/image'
import ButtonIcon from './ButtonIcon'

type ImageField = 'logoLight' | 'logoDark' | 'avatar'

interface ImageFileUploadProps {
	name: ImageField
	label: string
	url?: string | null
	dimensions?: {width: number; height: number}
	acceptImages: {
		[key: string]: string[]
	}
	circle?: boolean
	mode?: string
	aspectRatio?: number
	onChangeImage: (field: ImageField, file: File) => Promise<string | void>
}

const ImageFileUpload = ({
	name,
	label,
	url,
	dimensions,
	acceptImages,
	circle,
	mode,
	aspectRatio,
	onChangeImage
}: ImageFileUploadProps) => {
	const [isInvalidImage, setIsInvalidImage] = useState<boolean>(false)
	const [previewImage, setPreviewImage] = useState<string | null | undefined>(
		null
	)
	const [previewImageAspect, setPreviewImageAspect] = useState<number>(
		aspectRatio || 1
	)
	const [openCropDialog, setOpenCropDialog] = useState<boolean>(false)

	const [imageCrop, setImageCrop] = useState<{
		src: string
		field: string
		file: File
	} | null>(null)

	const {t} = useTranslation()

	const handleHelperMessage = (
		isDragActive: boolean,
		isDragReject: boolean,
		isImageUploadedInvalid: boolean
	) => {
		if (isDragActive && !isDragReject) {
			return (
				<FormHelperText sx={[styles.helperTextDrag, styles.textColorActive]}>
					{t('helper-image-upload-drop-here')}
				</FormHelperText>
			)
		}

		if (isDragActive && isDragReject) {
			return (
				<FormHelperText error sx={styles.helperTextDrag}>
					{t('helper-image-upload-file-type-not-accepted')}
				</FormHelperText>
			)
		}

		if (isImageUploadedInvalid) {
			return (
				<FormHelperText error sx={styles.helperTextDrag}>
					{t('error-image-invalid')}
				</FormHelperText>
			)
		}

		return (
			<FormHelperText sx={styles.helperTextDrag}>
				{t('helper-image-upload-drag-and-drop')}
			</FormHelperText>
		)
	}

	const handleImageUpload = async (file: File) => {
		setIsInvalidImage(false)
		const errorMessage = await handleChangeImageValue(file)

		if (!errorMessage) {
			handleChangeImagePreview(file)
			handleOpenCropDialog(name, file)
		}

		if (errorMessage) {
			setIsInvalidImage(true)
		}
	}

	const handleChangeImageValue = async (file: File) => {
		return await onChangeImage(name, file)
	}

	const handleChangeImagePreview = async (file: File) => {
		const imageURL = image.getURL(file)
		const {height, width} = await image.getDimensions(imageURL)

		setPreviewImageAspect(width / height)
		setPreviewImage(imageURL)
	}

	const handleOpenCropDialog = (field: string, file: File) => {
		setImageCrop({
			src: URL.createObjectURL(file),
			field,
			file
		})
		setOpenCropDialog(true)
	}

	const handleCloseCropDialog = () => {
		setOpenCropDialog(false)
	}

	const setInitialCropImage = useCallback(async () => {
		if (url) {
			const imageElement = await image.getDimensions(url)

			setPreviewImageAspect(imageElement.width / imageElement.height)
			setPreviewImage(url)

			const file = await image.createFileFromURL(url, name)

			const imageCrop = {
				src: url,
				field: name,
				file
			}

			setImageCrop(imageCrop)
		}
	}, [name, url])

	useEffect(() => {
		setInitialCropImage()
	}, [setInitialCropImage])

	return (
		<Box
			sx={{
				position: 'relative',
				maxWidth: '100%',
				width: circle ? 100 : previewImageAspect * 50,
				height: circle ? 100 : 50
			}}
		>
			<Dropzone
				multiple={false}
				accept={acceptImages}
				maxSize={10000000} // 10MB
				onDropAccepted={async (files: File[], event: DropEvent) => {
					await handleImageUpload(files[0])
				}}
				onDropRejected={async (
					fileRejections: FileRejection[],
					event: DropEvent
				) => {
					await handleImageUpload(fileRejections[0].file)
				}}
			>
				{({getRootProps, getInputProps, isDragActive, isDragReject}) => {
					const borderColorStyle =
						(isDragActive && !isDragReject && styles.borderColorActive) ||
						(isDragActive && isDragReject && styles.borderColorReject) ||
						(Boolean(isInvalidImage) && styles.borderColorReject)

					return (
						<Box
							{...getRootProps()}
							sx={[
								styles.dragContainer,
								borderColorStyle,
								circle ? styles.circleContainer : null,
								{
									aspectRatio: circle
										? 1 + '!important'
										: previewImageAspect + '!important',
									height: circle ? 100 : 50
								}
							]}
						>
							<input
								{...getInputProps()}
								name={name}
								aria-label={label}
								type="file"
							/>
							{previewImage &&
							!isDragActive &&
							!isDragReject &&
							!isInvalidImage ? (
								<Box
									sx={[
										styles.previewImageContainer,
										circle ? styles.circleContainer : null,
										mode === 'dark' ? styles.dark : null,
										mode === 'light' ? styles.light : null,

										{
											backgroundImage: `url(${previewImage})`
										}
									]}
								></Box>
							) : (
								handleHelperMessage(isDragActive, isDragReject, isInvalidImage)
							)}
						</Box>
					)
				}}
			</Dropzone>
			{previewImage && !isInvalidImage && imageCrop && (
				<>
					<Box
						sx={{
							position: 'absolute',
							bottom: 1,
							right: Boolean(circle) ? -34 : -42
						}}
					>
						<ButtonIcon
							icon={<CropIcon size={16} />}
							title={t('label-edit-image')}
							onClick={() => handleOpenCropDialog(name, imageCrop.file)}
						/>
					</Box>
					<CropEasy
						mode={mode}
						circle={circle}
						imageCrop={imageCrop}
						openCrop={openCropDialog}
						handleChangeImageValue={handleChangeImageValue}
						handleChangeImagePreview={handleChangeImagePreview}
						handleCloseCrop={handleCloseCropDialog}
					/>
				</>
			)}
		</Box>
	)
}

const styles = {
	dragContainer: (theme: Theme) => ({
		display: 'flex',
		border: '1px dashed ' + theme.palette.grey[300],
		boxSizing: 'content-box',
		borderRadius: 1,
		cursor: 'pointer',
		maxWidth: '100%',
		transition: 'height 0.2s ease'
	}),
	circleContainer: {
		borderRadius: '50%',
		height: 100
	},
	previewImageContainer: (theme: Theme) => ({
		borderRadius: 1,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: '50% 50%',
		height: 'inherit',
		width: '100%'
	}),
	borderColorActive: (theme: Theme) => ({
		borderColor: theme.palette.primary.main
	}),
	borderColorReject: {
		borderColor: '#e57878'
	},
	textColorActive: (theme: Theme) => ({
		color: theme.palette.primary.main
	}),
	helperTextDrag: {
		textAlign: 'center',
		alignSelf: 'center',
		margin: '0 auto',
		padding: 1
	},
	dark: {
		backgroundColor: '#212121'
	},
	light: (theme: Theme) => ({
		backgroundColor: '#fff'
	})
}

export default ImageFileUpload
