import {Fragment, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import {Theme} from '@mui/material/styles'

import useNavigate from '../../hooks/useNavigate'
import useAuthStates from '../../stores/useAuthStates'
import SectionTitle from '../../components/typography/SectionTitle'
import CardStartSelling from '../../components/CardStartSelling'
import FormCreateCompany from '../../components/forms/company/FormCreateCompany'
import FormCreateProfile from '../../components/forms/profile/FormCreateProfile'

export interface OnboardingStepsFormProps {
	handleNext: () => void
}

const OnboardingSteps = () => {
	const [activeStep, setActiveStep] = useState(0)

	const onboardingCompleted = useAuthStates.getState().onboardingCompleted
	const onboarding = useAuthStates.getState().onboarding

	const {t} = useTranslation()
	const navigate = useNavigate()

	const steps = useMemo(() => {
		const handleNext = (stepName: string) => {
			useAuthStates.setState({
				onboarding: {...onboarding, [stepName]: true}
			})
			setActiveStep(prevActiveStep => prevActiveStep + 1)
		}

		const handleFinishOnboarding = () => {
			useAuthStates.setState({onboardingCompleted: true})
			navigate('/')
		}

		return [
			{
				step: 1,
				label: t('company'),
				title: t('company-registration-title'),
				info: t('company-registration-info'),
				completed: onboarding.company,
				component: (
					<FormCreateCompany handleNext={() => handleNext('company')} />
				)
			},
			{
				step: 2,
				label: t('profile'),
				title: t('profile-creation-title'),
				info: t('profile-creation-info'),
				completed: onboarding.profile,
				component: (
					<FormCreateProfile handleNext={() => handleNext('profile')} />
				)
			},
			{
				step: 3,
				label: t('start-selling'),
				completed: onboardingCompleted,
				component: <CardStartSelling onFinish={handleFinishOnboarding} />
			}
		]
	}, [t, onboarding, navigate, onboardingCompleted])

	useEffect(() => {
		if (onboardingCompleted) {
			navigate('/')
		}

		const stepToActive = steps.find(step => step.completed === false)?.step
		if (stepToActive) {
			setActiveStep(stepToActive - 1)
		}
	}, [steps, onboardingCompleted, navigate, onboarding])

	return (
		<>
			<Stepper
				activeStep={activeStep}
				alternativeLabel
				sx={theme => ({
					maxWidth: '600px',
					margin: '0 auto 40px auto'
				})}
			>
				{steps.map((step, index) => {
					const stepProps: {completed?: boolean} = {}
					const labelProps: {
						optional?: React.ReactNode
					} = {}
					return (
						<Step
							key={step.label}
							{...stepProps}
							sx={theme => ({
								'& .MuiStepLabel-root': {
									[theme.breakpoints.down('sm')]: {
										flexDirection: 'column'
									}
								}
							})}
						>
							<StepLabel {...labelProps}>{step.label}</StepLabel>
						</Step>
					)
				})}
			</Stepper>
			<Box sx={style.formBox}>
				<Box sx={{width: '100%'}}>
					<Fragment>
						{!(activeStep === steps.length - 1) && (
							<Box>
								<SectionTitle>{steps[activeStep].title}</SectionTitle>
								<Typography sx={{mt: 2, mb: 1}}>
									{steps[activeStep].info}
								</Typography>
							</Box>
						)}
						<Box>{steps[activeStep].component}</Box>
					</Fragment>
				</Box>
			</Box>
		</>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			p: 5,
			pt: 0,
			width: '80%',
			maxWidth: '600px',
			[theme.breakpoints.down('md')]: {
				my: 0,
				padding: 3,
				width: '100%'
			},
			[theme.breakpoints.down('sm')]: {
				padding: 1
			}
		}
	}
}

export default OnboardingSteps
