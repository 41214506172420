import {
	To,
	NavigateOptions,
	useNavigate as useRRDNavigate
} from 'react-router-dom'
import useAppStates from '../stores/useAppStates'

const useNavigate = () => {
	const navigate = useRRDNavigate()
	const currentLang = useAppStates(state => state.currentLang)
	const langNavigate = (route: To, options?: NavigateOptions) => {
		navigate(currentLang ? `/${currentLang}` + route : route, options)
	}
	return langNavigate
}

export default useNavigate
