import {regex} from '../../helpers/regex'
import {Company} from '../../entities/Company'

export interface WSCompanyResponse {
	cnpj_raiz: number
	razao_social: string
	capital_social: string
	responsavel_federativo: string
	atualizado_em: string
	porte: {
		id: string
		descricao: string
	}
	natureza_juridica: {
		id: string
		descricao: string
	}
	qualificacao_do_responsavel: {
		id: number
		descricao: string
	}
	socios:
		| [
				{
					cpf_cnpj_socio: string
					nome: string
					tipo: string
					data_entrada: string
					cpf_representante_legal: string
					nome_representante: string | null
					faixa_etaria: string
					atualizado_em: string
					pais_id: string
					qualificacao_socio: {
						id: string
						descricao: string
					}
					qualificacao_representante: null
				}
		  ]
		| []
	simples: {
		simples: string
		data_opcao_simples: string
		data_exclusao_simples: string | null
		mei: string
		data_opcao_mei: string | null
		data_exclusao_mei: string | null
		atualizado_em: string
	} | null
	estabelecimento: {
		cnpj: string
		atividades_secundarias:
			| [
					{
						id: string
						secao: string
						divisao: string
						grupo: string
						classe: string
						subclasse: string
						descricao: string
					}
			  ]
			| []
		cnpj_raiz: string
		cnpj_ordem: string
		cnpj_digito_verificador: string
		tipo: string
		nome_fantasia: string | null
		situacao_cadastral: string
		data_situacao_cadastral: string
		data_inicio_atividade: string
		nome_cidade_exterior: string | null
		tipo_logradouro: string | null
		logradouro: string | null
		numero: number
		complemento: string | null
		bairro: string | null
		cep: string | null
		ddd1: string | null
		telefone1: string | null
		ddd2: string
		telefone2: string
		ddd_fax: string | null
		fax: string | null
		email: string | null
		situacao_especial: string | null
		data_situacao_especial: string | null
		atualizado_em?: string
		atividade_principal: {
			id: string
			secao: string
			divisao: string
			grupo: string
			classe: string
			subclasse: string
			descricao: string
		}
		pais: {
			id: string
			iso2: string
			iso3: string
			nome: string
			comex_id: string
		}
		estado: {
			id: number
			nome: string
			sigla: string
			ibge_id: number
		}
		cidade: {
			id: number
			nome: string
			ibge_id: number
			siafi_id: string
		}
		motivo_situacao_cadastral: string | null
		inscricoes_estaduais:
			| [
					{
						inscricao_estadual: string
						ativo: Boolean
						atualizado_em: string
						estado: {
							id: number
							nome: string
							sigla: string
							ibge_id: number
						}
					}
			  ]
			| []
	}
}
export interface RapidApiCompanyResponse {
	updated: string
	taxId: string
	alias: string
	founded: string
	head: Boolean
	statusDate: string
	status: {
		id: number
		text: string
	}
	mainActivity: {
		id: number
		text: string
	}
	company: {
		id: number
		name: string
		equity: number
		nature: {
			id: number
			text: string
		}
		size: {
			id: number
			acronym: string
			text: string
		}
		members: [
			{
				since: string
				role: {
					id: number
					text: string
				}
				person: {
					id: string
					name: string
					type: string
					taxId: string
					age: string
				}
			}
		]
	}
	address: {
		municipality: number
		street: string
		number: string
		details: null
		district: string
		city: string
		state: string
		zip: string
		country: {
			id: number
			name: string
		}
	}
	phones: [
		{
			area: string
			number: string
		}
	]
	emails: [
		{
			address: string
			domain: string
		}
	]
	sideActivities: [
		{
			id: number
			text: string
		}
	]
}

export const companyMapper = {
	rapidApiToDomain: (rapidApiCompany: RapidApiCompanyResponse): Company => {
		return {
			cnpj: rapidApiCompany.taxId,
			legalName: rapidApiCompany.company?.name || '',
			fantasyName: rapidApiCompany?.alias || '',
			email: rapidApiCompany.emails[0]?.address || '',
			phone:
				rapidApiCompany.phones[0]?.area + rapidApiCompany.phones[0]?.number ||
				'',
			address: rapidApiCompany.address?.street || '',
			district: rapidApiCompany.address?.district || '',
			city: rapidApiCompany.address?.city || '',
			state: rapidApiCompany.address?.state || '',
			zipCode: rapidApiCompany.address?.zip || ''
		}
	},
	wsToDomain: (wsCompany: WSCompanyResponse): Company => {
		return {
			cnpj: wsCompany.estabelecimento.cnpj,
			legalName: wsCompany.razao_social || '',
			fantasyName: wsCompany.estabelecimento?.nome_fantasia || '',
			email: wsCompany.estabelecimento?.email || '',
			phone:
				(wsCompany.estabelecimento?.ddd1 || '') +
					wsCompany.estabelecimento.telefone1 || '',
			address:
				wsCompany.estabelecimento?.tipo_logradouro +
					' ' +
					wsCompany.estabelecimento?.logradouro || '',
			district: wsCompany.estabelecimento?.bairro || '',
			city: wsCompany.estabelecimento.cidade.nome || '',
			state: wsCompany.estabelecimento.estado.sigla || '',
			zipCode: wsCompany.estabelecimento?.cep || ''
		}
	},
	domainToApi: (rawValues: Company): Company => {
		return {
			cnpj: regex.removeAllNonDigits(rawValues.cnpj),
			legalName: rawValues.legalName,
			fantasyName: rawValues.fantasyName,
			email: rawValues.email,
			phone: regex.removeAllNonDigits(rawValues.phone),
			annualRevenue: rawValues.annualRevenue,
			address: rawValues.address,
			streetNumber: rawValues.streetNumber,
			complementaryAddress: rawValues.complementaryAddress,
			referencePoint: rawValues.referencePoint,
			district: rawValues.district,
			city: rawValues.city,
			state: rawValues.state,
			zipCode: regex.removeAllNonDigits(rawValues.zipCode)
		}
	}
}
