import * as yup from 'yup'

import {useTranslation} from 'react-i18next'
import {image} from '../helpers/image'

const useUpdateProfileSchema = () => {
	const {t} = useTranslation()

	const updateProfileSchema = yup.object({
		name: yup
			.string()
			.min(3, t('error-name-min-length'))
			.max(60, t('error-name-max-length')),
		nameSlug: yup
			.string()
			.matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t('error-slug-invalid'))
			.min(3, t('error-name-slug-min-length'))
			.max(60, t('error-name-slug-max-length')),
		logoLight: yup
			.mixed()
			.test(
				'fileType',
				t('error-image-type-logo'),
				file => file && image.validate.logoType({file})
			)
			.test(
				'fileSize',
				t('error-image-max-size'),
				file => file && image.validate.size({file})
			),
		logoDark: yup
			.mixed()
			.test('fileFormat', t('error-image-type-logo'), value => {
				return !value || (value && ['image/png'].includes(value.type))
			})
			.test(
				'fileType',
				t('error-image-type-logo'),
				file => file && image.validate.logoType({file})
			)
			.test(
				'fileSize',
				t('error-image-max-size'),
				file => file && image.validate.size({file})
			),
		avatar: yup
			.mixed()
			.test('fileType', t('error-image-type-avatar'), file => {
				return file && image.validate.avatarType({file})
			})
			.test(
				'fileSize',
				t('error-image-max-size'),
				file => file && image.validate.size({file})
			),
		primaryColor: yup
			.string()
			.matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t('error-color-invalid'))
	})

	return updateProfileSchema
}

export default useUpdateProfileSchema
