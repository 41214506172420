import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
	HiChevronDoubleRight as DoubleArrowRightIcon,
	HiChevronDoubleLeft as DoubleArrowLeftIcon
} from 'react-icons/hi'

import {Box, Divider, IconButton, Typography} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import {Theme} from '@mui/material/styles'

import MobileUp from '../layout/responsive/MobileUp'
import MainMenu from './MainMenu'
import Tablet from '../layout/responsive/Tablet'

const SideBarNav = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const {t} = useTranslation()

	const handleOpenMenu = () => setIsMenuOpen(!isMenuOpen)

	return (
		<MobileUp>
			<Drawer
				variant="permanent"
				anchor="left"
				open={isMenuOpen}
				sx={isMenuOpen ? style.drawerOpen : style.drawer}
			>
				<Toolbar />
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
						overflow: 'hidden'
					}}
				>
					<MainMenu menuOpened={isMenuOpen} />
					<Tablet>
						<Box sx={{width: 'max-content'}}>
							<Divider />
							<Box
								color="inherit"
								sx={{
									display: 'flex',
									alignItems: 'center',
									p: 2,
									minWidth: 'auto',
									width: 'auto',
									overflow: 'hidden',
									cursor: 'pointer',
									minHeight: 56
								}}
								onClick={handleOpenMenu}
							>
								{isMenuOpen
									? [
											<IconButton
												key="arrow-left-icon"
												sx={{p: 0 + '!important'}}
												aria-label={t('collapse-menu')}
											>
												<DoubleArrowLeftIcon
													fontSize={18}
													style={{
														marginLeft: '4px',
														marginRight: '16px'
													}}
												/>
											</IconButton>,
											<Typography
												key="arrow-left-label"
												sx={{
													width: 170,
													textAlign: 'left',
													textTransform: 'capitalize'
												}}
											>
												{t('collapse-menu')}
											</Typography>
									  ]
									: [
											<IconButton
												key="arrow-right-icon"
												sx={{p: 0 + '!important'}}
												aria-label={t('expand-menu')}
											>
												<DoubleArrowRightIcon
													fontSize={18}
													style={{
														marginLeft: '4px',
														marginRight: '16px'
													}}
												/>
											</IconButton>
									  ]}
							</Box>
						</Box>
					</Tablet>
				</Box>
			</Drawer>
		</MobileUp>
	)
}

const style = {
	drawer: (theme: Theme) => ({
		flexShrink: 0,
		zIndex: theme.zIndex.appBar - 1,
		overflowX: 'hidden',
		boxSizing: 'border-box',
		[`& .MuiDrawer-paper`]: {
			overflowX: 'hidden',
			[theme.breakpoints.up('md')]: {
				width: 240
			},
			[theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
				width: 56
			}
		},
		[theme.breakpoints.up('md')]: {
			width: 240
		},
		[theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
			width: 56
		}
	}),
	drawerOpen: (theme: Theme) => ({
		flexShrink: 0,
		zIndex: theme.zIndex.appBar - 1,
		overflowX: 'hidden',
		[theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
			width: 200
		},
		[theme.breakpoints.up('md')]: {
			width: 240
		},
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		[`& .MuiDrawer-paper`]: {
			overflowX: 'hidden',
			[theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
				width: 200
			},
			[theme.breakpoints.up('md')]: {
				width: 240
			}
		}
	})
}

export default SideBarNav
