export const sheetsHelper = {
	exportToCSV: ({
		title,
		list,
		headerMapping,
		onCompleted
	}: {
		title: string
		list: any[]
		headerMapping: {[key: string]: string}
		onCompleted: () => void
	}) => {
		const headers = Object.values(headerMapping).toString()

		const main = list.map(item => {
			const newObj = Object.keys(headerMapping).reduce(
				(obj: any, key: string) => {
					if (item[key]) {
						obj[key] = item[key]
					}

					return obj
				},
				{}
			)

			return Object.values(newObj).toString()
		})

		const csv = [headers, ...main].join('\n')

		const blob = new Blob([csv], {type: 'application/csv'})
		const url = window.URL.createObjectURL(blob)
		const a = document.createElement('a')
		const unique = new Date().getTime()

		a.download = `${title}-${unique}.csv`
		a.href = url
		a.style.display = 'none'
		document.body.appendChild(a)
		a.click()
		a.remove()
		window.URL.revokeObjectURL(url)

		onCompleted()
	}
}
