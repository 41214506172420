import {useTranslation} from 'react-i18next'
import FullSection from '../components/layout/utils/FullSection'
import Footer from '../components/template/Footer'
import SectionTitle from '../components/typography/SectionTitle'
import {Box, Typography} from '@mui/material'
import Link from '../components/navigation/Link'

const NotFound = () => {
	const {t} = useTranslation()

	return (
		<Box sx={{mt: 10}}>
			<FullSection height="auto" minHeight="calc(100vh - 260px)">
				<SectionTitle sx={{mb: 3}}>{t('page-not-found-title')}</SectionTitle>
				<Typography>
					{t('page-not-found-content')}
					<Link to="/">{' ' + t('home-page')}.</Link>
				</Typography>
			</FullSection>
			<Footer />
		</Box>
	)
}

export default NotFound
