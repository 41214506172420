import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import {Status} from '../../../components/Status'
import AlertMessage from '../../../components/AlertMessage'
import useAsaasStates from '../../../stores/useAsaasStates'
import Countdown from '../../../components/CountDown'
import {useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {getAsaasSubAccount} from '../../../api/payment-service/asaas'

export const AsaasDocumentationAnalysisStep = () => {
	const {t} = useTranslation()
	const {asaasConfig, setAsaasConfig} = useAsaasStates()

	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [initialTime, setInitialTime] = useState(new Date().getTime())
	const expirationTimeInSeconds = 15 * 60 // 15 minutes

	const {mutate, isLoading} = useMutation(getAsaasSubAccount, {
		networkMode: 'always'
	})

	const handleCheckAccountStatus = () => {
		mutate(undefined, {
			onSuccess: ({data, error, responseStatusCode}) => {
				if (responseStatusCode === 200 && data) {
					setAsaasConfig(data)
				} else {
					setOpenAlert(true)
					setAlertMessage(t('error-checking-asaas-account-status'))
					console.error('Error on get account status', {error})
				}
			},
			onError: error => {
				setOpenAlert(true)
				setAlertMessage(t('error-checking-asaas-account-status'))
				console.error('Error on get account status', {error})
			}
		})
		setInitialTime(new Date().getTime())
	}

	return (
		<Box>
			{asaasConfig?.generalStatus === 'rejected' ? (
				<AlertMessage
					open
					severity="warning"
					message={t('asaas-account-rejected-info')}
				/>
			) : (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						py: 3,
						gap: 4
					}}
				>
					<Typography>{t('create-asaas-account-last-step-content')}</Typography>
					<Box
						sx={{
							display: 'flex',
							gap: '4px',
							flexDirection: 'column'
						}}
					>
						<Typography variant="subtitle2">
							{t('sending-documents')}
						</Typography>
						<Status.Tag
							type={asaasConfig?.documentStatus as string}
							size="small"
						>
							{t('status-analysis-asaas-' + asaasConfig?.documentStatus)}
						</Status.Tag>
					</Box>
				</Box>
			)}

			<AlertMessage
				open={openAlert}
				severity="error"
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
			/>

			{/* Countdown to refresh general status page after 15 minutes */}
			<div style={{display: 'none'}}>
				<Countdown
					type="minutes"
					initialTime={initialTime}
					expirationTimeInSeconds={expirationTimeInSeconds}
					onExpired={handleCheckAccountStatus}
				/>
			</div>
		</Box>
	)
}
