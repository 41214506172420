import {FaHome as HomeIcon} from 'react-icons/fa'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Footer from '../../../../components/template/Footer'
import Link from '../../../../components/navigation/Link'
import {useTranslation} from 'react-i18next'
import {useFormik} from 'formik'
import useUpdateCompanyManagingPartnerSchema from '../../../../schemas/useUpdateCompanyManagingPartner'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import {Theme} from '@mui/material/styles'
import SectionTitle from '../../../../components/typography/SectionTitle'
import InputAdornment from '@mui/material/InputAdornment'
import {useRef, useState} from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import {useMutation, useQuery} from '@tanstack/react-query'
import {findAddressByZipCode} from '../../../../api/zipCode'
import {regex} from '../../../../helpers/regex'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import dayjs, {Dayjs} from 'dayjs'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import useAppStates from '../../../../stores/useAppStates'
import InputMask from '../../../../components/InputMask'
import Skeleton from '@mui/material/Skeleton'
import AlertMessage from '../../../../components/AlertMessage'
import {AlertColor, FormGroup, Typography} from '@mui/material'
import BackNavigation from '../../../../components/navigation/BackNavigation'
import usePaymentServicePagarmeStates from '../../../../stores/usePaymentServicePagarmeStates'
import {
	getPaymentServicePagarme,
	updateLegalRepresentative
} from '../../../../api/payment-service/pagarme'
import Tag from '../../../../components/typography/Tag'
import {DashboardFullSection} from '../../../../components/template/DashboardFullSection'

type PhoneType = 'mobile' | 'landline'

const convertLocaleToDayjs = (locale: string) => {
	if (locale === 'pt-BR' || locale === 'pt-br' || locale === 'pt') {
		return 'pt-br'
	}

	return 'en'
}

export default function FormLegalRepresentative() {
	const {t} = useTranslation()

	const {companyLegalRepresentative, setCompanyLegalRepresentative} =
		usePaymentServicePagarmeStates()
	const alertRef = useRef<HTMLDivElement>(null)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error')
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const [lastValidZipCode, setLastValidZipCode] = useState<string>('')
	const [phoneType, setPhoneType] = useState<PhoneType>('mobile')
	const managingPartnerSchema = useUpdateCompanyManagingPartnerSchema()
	const currentLanguage = useAppStates(state => state.currentLang)

	const {isLoading, mutate} = useMutation(updateLegalRepresentative, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			name: companyLegalRepresentative?.name || '',
			email: companyLegalRepresentative?.email || '',
			birthdate: companyLegalRepresentative?.birthdate
				? dayjs(companyLegalRepresentative?.birthdate)
				: null,
			cpf: companyLegalRepresentative?.cpf || '',
			monthlyIncome: companyLegalRepresentative?.monthlyIncome
				? String(companyLegalRepresentative?.monthlyIncome)
				: '',
			phoneType: companyLegalRepresentative?.phoneType || '',
			phone: companyLegalRepresentative?.phone || '',
			professionalOccupation:
				companyLegalRepresentative?.professionalOccupation || '',
			street: companyLegalRepresentative?.street || '',
			streetNumber: companyLegalRepresentative?.streetNumber || '',
			complementaryAddress:
				companyLegalRepresentative?.complementaryAddress || '',
			referencePoint: companyLegalRepresentative?.referencePoint || '',
			district: companyLegalRepresentative?.district || '',
			city: companyLegalRepresentative?.city || '',
			state: companyLegalRepresentative?.state || '',
			zipCode: companyLegalRepresentative?.zipCode || ''
		},
		enableReinitialize: true,
		validationSchema: managingPartnerSchema,
		onSubmit: values => {
			setOpenAlert(false)
			mutate(
				{
					...values,
					streetNumber: Number(values.streetNumber),
					selfDeclaredLegalRepresentative: true,
					zipCode: regex.removeAllNonDigits(values.zipCode),
					phone: regex.removeAllNonDigits(values.phone),
					cpf: regex.removeAllNonDigits(values.cpf),
					monthlyIncome: regex.parseBRLCurrencyToFloat(
						formik.values.monthlyIncome || '0'
					),
					birthdate: dayjs(values.birthdate).toISOString()
				},
				{
					onSuccess: ({responseStatusCode}) => {
						if (responseStatusCode === 200) {
							setShowSuccessButton(true)
							setCompanyLegalRepresentative({
								...values,
								streetNumber: Number(values.streetNumber),
								zipCode: regex.removeAllNonDigits(values.zipCode),
								phone: regex.removeAllNonDigits(values.phone),
								cpf: regex.removeAllNonDigits(values.cpf),
								monthlyIncome: regex.parseBRLCurrencyToFloat(
									formik.values.monthlyIncome || '0'
								),
								birthdate: dayjs(values.birthdate).toISOString()
							})
							setAlertSeverity('success')
							setAlertMessage(t('saved-changes'))
							setOpenAlert(true)
						} else {
							setAlertSeverity('error')
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
							alertRef.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'center'
							})
						}
					},
					onError: error => {
						console.error(error)
						setAlertSeverity('error')
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						alertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
					}
				}
			)
		}
	})

	const {isLoading: isSearchingAddress, mutate: mutateSearchAddress} =
		useMutation(findAddressByZipCode, {networkMode: 'always'})

	const searchAddressByZipCode = () => {
		if (
			!formik.errors.zipCode &&
			!isSearchingAddress &&
			lastValidZipCode !== regex.removeAllNonDigits(formik.values.zipCode)
		) {
			mutateSearchAddress(regex.removeAllNonDigits(formik.values.zipCode), {
				onSuccess: ({responseStatusCode, data}) => {
					if (responseStatusCode === 200 && data) {
						setLastValidZipCode(regex.removeAllNonDigits(formik.values.zipCode))
						formik.setFieldValue('street', data.street)
						formik.setFieldValue('district', data.neighborhood)
						formik.setFieldValue('city', data.city)
						formik.setFieldValue('state', data.state)
					}
				},
				onError: error => {
					console.error(error)
				}
			})
		}
	}

	const {isFetching} = useQuery(
		['payment-service-pagarme'],
		getPaymentServicePagarme,
		{
			refetchOnWindowFocus: false,
			enabled: !companyLegalRepresentative,
			onSuccess: ({data, responseStatusCode}) => {
				if (responseStatusCode === 200 && data?.companyLegalRepresentative) {
					const {
						name,
						email,
						birthdate,
						cpf,
						monthlyIncome,
						phoneType,
						phone,
						professionalOccupation,
						street,
						streetNumber,
						complementaryAddress,
						referencePoint,
						district,
						city,
						state,
						zipCode
					} = data.companyLegalRepresentative
					formik.setValues({
						name,
						email,
						birthdate: dayjs(birthdate),
						cpf,
						monthlyIncome:
							regex.formatBRLCurrency(
								Number.parseFloat(String(monthlyIncome)).toFixed(2) // add 2 decimal places to price from database
							) || '',
						phoneType,
						phone,
						professionalOccupation,
						street,
						streetNumber: String(streetNumber),
						complementaryAddress,
						referencePoint,
						district,
						city,
						state,
						zipCode
					})
				} else if (
					responseStatusCode === 200 &&
					!data?.companyLegalRepresentative
				) {
					return
				} else {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					alertRef.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					})
				}
			},
			onError: error => {
				setAlertMessage(t('error-server-default'))
				setOpenAlert(true)
				alertRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				})
				console.error(error)
			}
		}
	)

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link
						to="/payment-services"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<span>{t('payment-services')}</span>
					</Link>
					<Link
						to="/payment-services/pagarme"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<span>{t('payment-service-pagarme-settings')}</span>
					</Link>
					<span>{t('managing-partner')}</span>
				</Breadcrumbs>
				<BackNavigation to="/payment-services/pagarme" />
			</Container>
			<DashboardFullSection>
				<Box
					onSubmit={formik.handleSubmit}
					component="form"
					autoComplete="off"
					sx={style.formBox}
					onChange={() => {
						setShowSuccessButton(false)
						setOpenAlert(false)
					}}
				>
					<Stack direction="column" spacing={4}>
						<SectionTitle>{t('legal-officer')}</SectionTitle>
						{openAlert ? (
							<AlertMessage
								ref={alertRef}
								message={alertMessage}
								open={openAlert}
								onClose={() => setOpenAlert(false)}
								severity={alertSeverity}
								mt={0}
							/>
						) : null}

						{isFetching ? (
							<>
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
							</>
						) : (
							<>
								<FormGroup
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1
									}}
								>
									<Typography variant="h6">{t('basic-info')}</Typography>
									<TextField
										name="name"
										type="text"
										label={t('full-name')}
										variant="outlined"
										helperText={formik.touched.name && formik.errors.name}
										error={formik.touched.name && Boolean(formik.errors.name)}
										margin="normal"
										value={formik.values.name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										size="small"
									/>
									<TextField
										name="email"
										type="text"
										label={t('email')}
										variant="outlined"
										helperText={formik.touched.email && formik.errors.email}
										error={formik.touched.email && Boolean(formik.errors.email)}
										margin="normal"
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										size="small"
									/>
									<TextField
										name="cpf"
										type="text"
										label={t('cpf')}
										variant="outlined"
										helperText={formik.touched.cpf && formik.errors.cpf}
										error={formik.touched.cpf && Boolean(formik.errors.cpf)}
										margin="normal"
										value={formik.values.cpf}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										size="small"
										InputProps={{
											inputComponent: InputMask as any,
											inputProps: {
												mask: '000.000.000-00'
											}
										}}
									/>
									<FormControl
										sx={{
											mt: 2,
											mb: 1
										}}
									>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
											adapterLocale={convertLocaleToDayjs(currentLanguage)}
										>
											<DatePicker
												label={t('birth-date')}
												value={formik.values.birthdate}
												maxDate={dayjs(Date.now())}
												dayOfWeekFormatter={day => `${day}`}
												onChange={newValue => {
													formik.setFieldValue('birthdate', newValue)
													setShowSuccessButton(false)
												}}
												slotProps={{
													textField: {
														size: 'small'
													}
												}}
												slots={
													<TextField
														name="birthdate"
														type="text"
														value={formik.values.birthdate}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														helperText={
															formik.touched.birthdate &&
															Boolean(formik.errors.birthdate)
														}
														error={
															formik.touched.birthdate &&
															Boolean(formik.errors.birthdate)
														}
														variant="outlined"
														sx={{
															'& .MuiInputBase-input': {
																py: '8.5px'
															}
														}}
													/>
												}
											/>
										</LocalizationProvider>
									</FormControl>
									<Box
										sx={theme => ({
											display: 'flex',
											flexDirection: 'row',
											gap: 2,
											[theme.breakpoints.down('sm')]: {
												flexDirection: 'column',
												gap: 1
											}
										})}
									>
										<TextField
											name="professionalOccupation"
											type="text"
											label={t('professional-occupation')}
											variant="outlined"
											helperText={
												formik.touched.professionalOccupation &&
												formik.errors.professionalOccupation
											}
											error={
												formik.touched.professionalOccupation &&
												Boolean(formik.errors.professionalOccupation)
											}
											margin="normal"
											value={formik.values.professionalOccupation}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size="small"
											fullWidth
										/>
										<TextField
											name="monthlyIncome"
											type="text"
											label={t('monthly-income')}
											variant="outlined"
											helperText={
												formik.touched.monthlyIncome &&
												formik.errors.monthlyIncome
											}
											error={
												formik.touched.monthlyIncome &&
												Boolean(formik.errors.monthlyIncome)
											}
											margin="normal"
											value={formik.values.monthlyIncome}
											onChange={event => {
												event.target.value = regex.formatBRLCurrency(
													event.target.value
												)
												formik.handleChange(event)
											}}
											onBlur={formik.handleBlur}
											size="small"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{'R$'}
													</InputAdornment>
												)
											}}
											sx={{
												minWidth: '200px'
											}}
										/>
									</Box>
									<Box
										sx={theme => ({
											display: 'flex',
											flexDirection: 'row',
											gap: 2,
											[theme.breakpoints.down('sm')]: {
												flexDirection: 'column',
												gap: 1
											}
										})}
									>
										<FormControl
											sx={{
												mt: 2,
												mb: 1
											}}
											size="small"
											fullWidth
										>
											<InputLabel id="select-document-type-label">
												{t('phone-type')}
											</InputLabel>
											<Select
												id="select-phone-type"
												labelId="select-phone-type-label"
												label={t('phone-type')}
												name="phoneType"
												variant="outlined"
												error={
													formik.touched.phoneType &&
													Boolean(formik.errors.phoneType)
												}
												value={formik.values.phoneType}
												onChange={e => {
													setPhoneType(e.target.value as PhoneType)
													formik.setFieldValue('phone', '')
													formik.handleChange(e)
												}}
											>
												<MenuItem value="mobile">{t('mobile')}</MenuItem>
												<MenuItem value="landline">{t('landline')}</MenuItem>
											</Select>
										</FormControl>
										<TextField
											name="phone"
											type="text"
											label={t('phone')}
											variant="outlined"
											helperText={formik.touched.phone && formik.errors.phone}
											error={
												formik.touched.phone && Boolean(formik.errors.phone)
											}
											margin="normal"
											value={formik.values.phone}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size="small"
											InputProps={{
												inputComponent: InputMask as any,
												inputProps: {
													mask:
														phoneType === 'mobile'
															? '(00) 00000-0000'
															: '(00) 0000-0000'
												}
											}}
											sx={{
												minWidth: '200px'
											}}
										/>
									</Box>
								</FormGroup>
								<FormGroup
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1
									}}
								>
									<Typography variant="h6">{t('address')}</Typography>
									<TextField
										name="zipCode"
										type="text"
										InputProps={{
											inputComponent: InputMask as any,
											inputProps: {
												mask: '00000-000'
											},
											endAdornment: (
												<InputAdornment position="end">
													{isSearchingAddress && (
														<CircularProgress color="primary" size={20} />
													)}
												</InputAdornment>
											)
										}}
										label={t('zip-code')}
										variant="outlined"
										helperText={formik.touched.zipCode && formik.errors.zipCode}
										error={
											formik.touched.zipCode && Boolean(formik.errors.zipCode)
										}
										margin="normal"
										value={formik.values.zipCode}
										onChange={formik.handleChange}
										onBlur={event => {
											formik.handleBlur(event)
											searchAddressByZipCode()
										}}
										size="small"
									/>
									<Box
										sx={theme => ({
											display: 'flex',
											flexDirection: 'row',
											gap: 2,
											[theme.breakpoints.down('sm')]: {
												flexDirection: 'column',
												gap: 1
											}
										})}
									>
										<TextField
											name="street"
											type="text"
											label={t('street')}
											variant="outlined"
											helperText={formik.touched.street && formik.errors.street}
											error={
												formik.touched.street && Boolean(formik.errors.street)
											}
											margin="normal"
											value={formik.values.street}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size="small"
											fullWidth
										/>
										<TextField
											name="streetNumber"
											type="text"
											label={t('street-number')}
											variant="outlined"
											helperText={
												formik.touched.streetNumber &&
												formik.errors.streetNumber
											}
											error={
												formik.touched.streetNumber &&
												Boolean(formik.errors.streetNumber)
											}
											margin="normal"
											value={formik.values.streetNumber}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size="small"
											sx={{
												minWidth: '200px'
											}}
										/>
									</Box>
									<TextField
										name="complementaryAddress"
										type="text"
										label={t('complementary-address')}
										variant="outlined"
										helperText={
											formik.touched.complementaryAddress &&
											Boolean(formik.errors.complementaryAddress) ? (
												formik.errors.complementaryAddress
											) : (
												<Tag>{t('optional')}</Tag>
											)
										}
										error={
											formik.touched.complementaryAddress &&
											Boolean(formik.errors.complementaryAddress)
										}
										margin="normal"
										value={formik.values.complementaryAddress}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										size="small"
									/>
									<TextField
										name="referencePoint"
										type="text"
										label={t('reference-point')}
										variant="outlined"
										helperText={
											formik.touched.referencePoint &&
											Boolean(formik.errors.referencePoint) ? (
												formik.errors.referencePoint
											) : (
												<Tag>{t('optional')}</Tag>
											)
										}
										error={
											formik.touched.referencePoint &&
											Boolean(formik.errors.referencePoint)
										}
										margin="normal"
										value={formik.values.referencePoint}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										size="small"
									/>
									<TextField
										name="district"
										type="text"
										label={t('district')}
										variant="outlined"
										helperText={
											formik.touched.district && formik.errors.district
										}
										error={
											formik.touched.district && Boolean(formik.errors.district)
										}
										margin="normal"
										value={formik.values.district}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										size="small"
									/>
									<Box
										sx={theme => ({
											display: 'flex',
											flexDirection: 'row',
											gap: 2,
											[theme.breakpoints.down('sm')]: {
												flexDirection: 'column',
												gap: 1
											}
										})}
									>
										<TextField
											name="city"
											type="text"
											label={t('city')}
											variant="outlined"
											helperText={formik.touched.city && formik.errors.city}
											error={formik.touched.city && Boolean(formik.errors.city)}
											margin="normal"
											value={formik.values.city}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size="small"
											fullWidth
										/>
										<TextField
											name="state"
											type="text"
											label={t('state')}
											variant="outlined"
											helperText={formik.touched.state && formik.errors.state}
											error={
												formik.touched.state && Boolean(formik.errors.state)
											}
											margin="normal"
											value={formik.values.state}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size="small"
											sx={{
												minWidth: '200px'
											}}
										/>
									</Box>
								</FormGroup>
								{showSuccessButton ? (
									<Button
										size="large"
										variant="contained"
										type="button"
										sx={theme => ({
											height: '44px',
											backgroundColor: 'success.light',
											color: 'success.contrastText',
											width: '200px',
											[theme.breakpoints.down('sm')]: {
												width: '100%'
											},
											'&:hover': {backgroundColor: 'success.light'}
										})}
									>
										{t('saved-changes')}
									</Button>
								) : (
									<Button
										size="large"
										variant="contained"
										type="submit"
										disabled={isLoading}
										sx={(theme: Theme) => ({
											height: '44px',
											width: '200px',
											[theme.breakpoints.down('sm')]: {
												width: '100%'
											}
										})}
									>
										{isLoading ? (
											<CircularProgress color="inherit" size={25} />
										) : (
											t('to-save-changes')
										)}
									</Button>
								)}
							</>
						)}
					</Stack>
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

const style = {
	formBox: (theme: Theme) => ({
		my: 5,
		mt: 0,
		pt: 0,
		width: '100%',
		maxWidth: '520px'
	}),
	formSubmitButton: (theme: Theme) => ({
		height: '44px',
		width: '110px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	})
}
