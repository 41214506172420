import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme, SxProps} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {HiDotsHorizontal as DotsHorizontalIcon} from 'react-icons/hi'

import Link from './navigation/Link'
import {Status} from './Status'
import {CHECKOUT_URL} from '../config'
import useAuthStates from '../stores/useAuthStates'
import IconButton from '@mui/material/IconButton'
import MenuList from './MenuList'
import useNavigate from '../hooks/useNavigate'
import Price from './Price'
import TabletUp from './layout/responsive/TabletUp'
import TabletDown from './layout/responsive/TabletDown'
import {price} from '../helpers/price'

interface EditionCardProps {
	edition: {
		_id: string
		title: string
		slug: string
		price: number
		vacancies?: number
		remainingVacancies?: number
		status: string
		sales: {
			spotsSold: number
			revenue: number
		}
		experience: {
			slug: string
			status: string
		}
	}
	url: string
}

const EditionCard = ({edition, url}: EditionCardProps) => {
	const {t} = useTranslation()

	return (
		<Box
			sx={theme => ({
				[theme.breakpoints.up('lg')]: {
					display: 'grid'
				}
			})}
		>
			<Box
				className="hidden-scrollbar"
				sx={theme => ({
					display: 'flex',
					rowGap: 1,
					columnGap: 5,
					flex: 1,
					justifyContent: 'space-between',
					pt: 1,
					px: 2,
					minHeight: 60,
					[theme.breakpoints.up('lg')]: {
						overflowX: 'auto',
						maxWidth: '100%',
						scrollSnapType: 'x mandatory'
					},
					[theme.breakpoints.down('lg')]: {
						flexWrap: 'wrap'
					},
					[theme.breakpoints.down('sm')]: {
						px: 0
					}
				})}
			>
				<Typography
					variant="body1"
					sx={theme => ({
						display: 'flex',
						flexWrap: 'wrap',
						gap: 1,
						minWidth: 150,
						justifyContent: 'space-between',
						[theme.breakpoints.down('md')]: {
							alignItems: 'center',
							minWidth: 'auto'
						}
					})}
				>
					<Link to={url} sx={style.title}>
						{edition.title}
					</Link>
					<TabletDown>
						<Options edition={edition} sx={{mt: '4px'}} />
					</TabletDown>
				</Typography>
				<Box
					sx={theme => ({
						[theme.breakpoints.down('lg')]: {
							display: 'grid',
							width: '100%'
						}
					})}
				>
					<Box
						className="hidden-scrollbar"
						sx={theme => ({
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: 4,
							overflowX: 'auto',
							width: '100%',
							scrollSnapType: 'x mandatory',
							[theme.breakpoints.down('lg')]: {
								pb: 1.5
							}
						})}
					>
						<Box sx={style.cardDescribeItem} width={120}>
							<Typography variant="body2">{t('price')}</Typography>
							<Typography variant="body2">
								{price.full(edition.price)}
							</Typography>
						</Box>
						<Box sx={style.cardDescribeItem} width={120}>
							<Typography variant="body2">{t('vacancies')}</Typography>
							<Box>
								{edition.vacancies ? (
									<Typography variant="body2">
										{t('remaining') + ': ' + edition.remainingVacancies}
									</Typography>
								) : (
									<Typography variant="body2">{t('unlimited')}</Typography>
								)}
							</Box>
						</Box>
						<Box sx={style.cardDescribeItem} width={120}>
							<Typography variant="body2">{t('sales')}</Typography>
							<Typography variant="body2">
								{edition.sales?.spotsSold || 0}
							</Typography>
						</Box>
						<Box sx={style.cardDescribeItem} width={120}>
							<Typography variant="body2">{t('revenue')}</Typography>
							<Price value={edition?.sales?.revenue} variant="body2" />
						</Box>
						<Box sx={style.cardDescribeItem} flex="none" width={90}>
							<Status.Tag type={edition.status}>
								<Status.Label label={t(edition.status)} />
							</Status.Tag>
						</Box>
						<TabletUp>
							<Options edition={edition} />
						</TabletUp>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

const Options = ({
	edition,
	sx
}: {
	edition: {
		title: string
		slug: string
		status: string
		experience: {
			slug: string
			status: string
		}
	}
	sx?: SxProps<Theme>
}) => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {profile, paymentService} = useAuthStates(state => state)

	const [anchorElOptions, setAnchorElOptions] = useState<null | HTMLElement>(
		null
	)
	const openOptions = Boolean(anchorElOptions)

	const [copyLinkTooltip, setCopyLinkTooltip] = useState<string>(
		t('copy-checkout-link')
	)

	const checkoutUrl = `${CHECKOUT_URL}/${profile?.nameSlug}/${edition.experience.slug}/${edition.slug}`

	const handleCopyCheckoutLink = () => {
		navigator.clipboard.writeText(checkoutUrl).then(() => {
			setCopyLinkTooltip(t('copied-link'))
		})
	}

	const checkoutDisabled =
		!paymentService?.activeService ||
		edition.status !== 'active' ||
		edition.experience.status !== 'active'

	const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElOptions(event.currentTarget)
	}

	const handleCloseOptions = () => {
		setAnchorElOptions(null)
	}

	const handleNavigateToEdition = () => {
		navigate(`/products/${edition.experience.slug}/${edition.slug}`)
	}

	const handleNavigateToEdit = () => {
		navigate(`/products/${edition.experience.slug}/${edition.slug}/edit`)
	}

	return (
		<Box sx={sx}>
			<Box>
				<Tooltip title={t('options')} placement="bottom" arrow>
					<IconButton
						onClick={handleOpenOptions}
						size="small"
						aria-controls={openOptions ? t('options') : undefined}
						aria-haspopup="true"
						aria-expanded={openOptions ? 'true' : undefined}
					>
						<DotsHorizontalIcon color="inherit" />
					</IconButton>
				</Tooltip>
			</Box>
			<MenuList
				open={openOptions}
				anchorEl={anchorElOptions}
				onClose={handleCloseOptions}
				list={[
					{
						label: 'to-edit',
						onClick: handleNavigateToEdit
					},
					{
						label: 'to-open-edition',
						onClick: handleNavigateToEdition
					},
					{
						label: 'open-checkout',
						onClick: () => {
							window.open(checkoutUrl, '_blank')
						}
					},
					{
						label: 'copy-checkout-link',
						onClick: handleCopyCheckoutLink
					}
				].filter(
					item =>
						(item.label !== 'open-checkout' || !checkoutDisabled) &&
						(item.label !== 'copy-checkout-link' || !checkoutDisabled)
				)}
			/>
		</Box>
	)
}

const style = {
	card: (theme: Theme) => ({
		backgroundColor: theme.palette.grey[100],
		my: 1
	}),
	cardDescribeItem: {
		display: 'flex',
		flexDirection: 'column'
	},
	link: (theme: Theme) => ({
		color: theme.palette.text.primary,
		'&:hover': {
			color: theme.palette.primary.dark
		}
	}),
	title: (theme: Theme) => ({
		mt: 0.5,
		color: theme.palette.common.black,
		lineHeight: 'normal',
		'&:hover': {
			color: theme.palette.primary.dark
		},
		[theme.breakpoints.down('md')]: {
			mt: 0
		}
	})
}

export default EditionCard
