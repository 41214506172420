import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useFormik} from 'formik'
import {useMutation} from '@tanstack/react-query'
import {Box, Stack, TextField, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import SectionTitle from '../../typography/SectionTitle'

import {RiMailSendLine as EmailSendIcon} from 'react-icons/ri'

import useCodeSchema from '../../../schemas/useCodeSchema'
import {codeValidate} from '../../../api/updatePassword'
import AlertMessage from '../../AlertMessage'

interface FormUpdatePasswordCodeValidateProps {
	email: string
	handleGoBack: (alertMessage: string) => void
	handleOnSuccess: () => void
	handleIsLoading: (loading: boolean) => void
	SubmitButton: () => JSX.Element
}

const FormUpdatePasswordCodeValidate = ({
	email,
	handleGoBack,
	handleOnSuccess,
	handleIsLoading,
	SubmitButton
}: FormUpdatePasswordCodeValidateProps) => {
	const [openAlert, setOpenAlert] = useState<boolean>(false)

	const {t} = useTranslation()
	const codeSchema = useCodeSchema()

	const {mutate} = useMutation(
		(values: {code: string}) => codeValidate(values),
		{
			networkMode: 'always'
		}
	)

	const formik = useFormik({
		initialValues: {
			code: ''
		},
		validationSchema: codeSchema,
		onSubmit: values => {
			setOpenAlert(false)
			handleIsLoading(true)
			mutate(values, {
				onSuccess: result => {
					handleIsLoading(false)

					if (result.responseStatusCode === 200) {
						handleOnSuccess()
					}

					if (result.responseStatusCode === 400) {
						if (result.error?.fields) {
							Object.entries(result.error.fields).forEach(([key, value]) => {
								formik.setFieldError(key, value[0].message)
							})
						}
					}

					if (result.responseStatusCode === 403) {
						handleGoBack(t('error-code-not-found-on-update-password'))
					}

					if (result.responseStatusCode === 500) {
						setOpenAlert(true)
					}
				},
				onError: error => {
					handleIsLoading(false)
					setOpenAlert(true)
					console.error(error)
				}
			})
		}
	})

	return (
		<Box
			component="form"
			autoComplete="off"
			onSubmit={formik.handleSubmit}
			sx={style.formBox}
		>
			<SectionTitle>{t('verify-your-email')}</SectionTitle>
			<AlertMessage
				open={openAlert}
				severity="error"
				message={t('error-server-default')}
				onClose={() => setOpenAlert(false)}
				mt={'0px! important'}
				sx={{mt: openAlert ? 2 : 0}}
			/>
			<Stack
				direction="row"
				sx={{
					alignItems: 'center',
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
					margin: '10px 10px 10px 0'
				}}
			>
				<EmailSendIcon
					style={{
						width: '50px',
						height: '50px',
						margin: '10px 10px 10px 0'
					}}
				/>
				<div style={{width: 'calc(100% - 60px)', minWidth: '210px'}}>
					<Typography>
						{t('verify-your-email-info')} <b>{email}</b>
					</Typography>
				</div>
			</Stack>
			<Stack direction="column" spacing={4}>
				<TextField
					name="code"
					type="text"
					inputProps={{
						inputMode: 'numeric',
						maxLength: 6
					}}
					label={t('verification-code')}
					variant="outlined"
					helperText={
						formik.touched.code && Boolean(formik.errors.code)
							? formik.errors.code
							: t('helper-input-code')
					}
					error={formik.touched.code && Boolean(formik.errors.code)}
					margin="normal"
					size="small"
					value={formik.values.code}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
				<SubmitButton />
			</Stack>
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			p: 5,
			width: '100%',
			maxWidth: 500,
			[theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
				p: 3
			},
			[theme.breakpoints.down('sm')]: {
				p: 2
			}
		}
	}
}

export default FormUpdatePasswordCodeValidate
