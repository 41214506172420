import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {useMutation} from '@tanstack/react-query'

import useNavigate from '../../../hooks/useNavigate'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import {CgClose as CloseIcon} from 'react-icons/cg'

import {Theme} from '@mui/material/styles'

import SectionTitle from '../../typography/SectionTitle'
import Link from '../../navigation/Link'

import useCheckEmailSchema from '../../../schemas/useCheckEmailSchema'
import {forgotPassword} from '../../../api/forgotPassword'

const FormForgotPassword = () => {
	const [errorAlert, setErrorAlert] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>('')

	const {t} = useTranslation()
	const checkEmailSchema = useCheckEmailSchema()
	const navigate = useNavigate()
	const location = useLocation()

	const navigationState = location.state as {
		email?: string
		error?: {alert: boolean; message: string}
	}

	let email: string | undefined | null = null
	if (navigationState?.email) {
		email = navigationState.email
	} else {
		email = sessionStorage.getItem('forgot-password-email')
	}

	useEffect(() => {
		if (navigationState?.error) {
			setErrorAlert(true)
			setErrorMessage(navigationState.error.message)
		}
	}, [navigationState?.error])

	const {isLoading, mutate} = useMutation(
		(values: {email: string}) => forgotPassword(values),
		{networkMode: 'always'}
	)

	const formik = useFormik({
		initialValues: {
			email: ''
		},
		validationSchema: checkEmailSchema,
		onSubmit: values => {
			mutate(values, {
				onSuccess: (result, data) => {
					if (result?.responseStatusCode === 200 && result?.success) {
						sessionStorage.setItem('forgot-password-email', data.email)
						navigate('/forgot-password/code-validate', {
							state: {email: data.email}
						})
					}
					if (result?.responseStatusCode === 400 && result?.error) {
						if (result?.error?.fields) {
							if (result?.error?.fields?.email) {
								formik.setFieldError(
									'email',
									result.error.fields.email[0].message
								)
								console.error(result.error.fields.email)
							}
						} else {
							setErrorAlert(true)
							setErrorMessage(t('error-server-default'))
							console.error(result.error.message)
						}
					} else if (result?.responseStatusCode === 403 && result?.error) {
						setErrorAlert(true)
						setErrorMessage(result?.error?.message)
					} else {
						setErrorAlert(true)
						setErrorMessage(t('error-server-default'))
						console.error(result?.error?.message)
					}
				},
				onError: error => {
					setErrorAlert(true)
					setErrorMessage(t('error-server-default'))
					console.error(error)
				}
			})
		}
	})

	return (
		<Box sx={style.formBox}>
			<SectionTitle>{t('forgot-your-password')}</SectionTitle>
			<Typography>{t('enter-your-email')}</Typography>
			<Collapse in={errorAlert}>
				<Alert
					severity="error"
					action={
						<IconButton
							aria-label={t('aria-close-alert')}
							color="inherit"
							size="small"
							onClick={() => {
								setErrorAlert(false)
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{mt: 3}}
				>
					{errorMessage}
				</Alert>
			</Collapse>
			<Box
				onSubmit={formik.handleSubmit}
				component="form"
				autoComplete="off"
				sx={{mt: 3}}
			>
				<Stack direction="column" spacing={4}>
					<TextField
						name="email"
						type="email"
						label={t('email')}
						variant="outlined"
						helperText={formik.touched.email && formik.errors.email}
						error={formik.touched.email && Boolean(formik.errors.email)}
						margin="normal"
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={event => {
							formik.handleBlur(event)
						}}
					/>
					{email ? (
						<Link to="/forgot-password/code-validate">
							{t('already-have-a-code')}
						</Link>
					) : null}
					<Button
						size="large"
						variant="contained"
						type="submit"
						disabled={isLoading}
						sx={{height: '44px'}}
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('send')
						)}
					</Button>
				</Stack>
			</Box>
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			my: 5,
			padding: 5,
			width: '80%',
			maxWidth: '600px',
			[theme.breakpoints.down('md')]: {
				my: 0,
				padding: 3,
				width: '100%'
			}
		}
	}
}

export default FormForgotPassword
