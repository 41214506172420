import {DEFAULT_LANG, SELLER_EXPERIENCES_API} from '../../config'
import {authenticatedFetch} from '../../helpers/authenticatedFetch'
import useAppStates from '../../stores/useAppStates'
import useAuthStates from '../../stores/useAuthStates'

interface Message {
	message: string
}

interface GetOrderBumpByOfferResponse {
	success?: Message
	error?: Message
	data?: {
		orderBump: {
			id: string
			groupName: string
			products: {
				id: string
				publicId: string
				title: string
				cover?: string
				offer: {
					id: string
					title: string
					unitPrice: number
				}
			}[]
		}
	}
	responseStatusCode?: number
}

export const getOrderBumpByOffer = async ({
	offerId
}: {
	offerId: string
}): Promise<GetOrderBumpByOfferResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/offers/${offerId}/order-bump`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CreateOrderBumpResponse {
	success?: Message
	error?: Message
	data?: {
		orderBump: {id: string}
	}
	responseStatusCode?: number
}

export const createOrderBump = async ({
	offerId,
	...values
}: {
	offerId: string
	offerGroupId: string
}): Promise<CreateOrderBumpResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/offers/${offerId}/order-bump`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteOrderBumpResponse {
	success?: Message
	error?: Message
	responseStatusCode?: number
}

export const deleteOrderBump = async ({
	id,
	offerId
}: {
	id: string
	offerId: string
}): Promise<DeleteOrderBumpResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/offers/${offerId}/order-bump`,
		{
			method: 'DELETE',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({id})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
