import {DEFAULT_LANG, SELLER_AUTH_API} from '../config'
import useAppStates from '../stores/useAppStates'

interface Message {
	message: string
}

interface ForgotPasswordResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			email?: Message[]
		}
	}
	responseStatusCode?: number
}

export const forgotPassword = async (values: {
	email: string
}): Promise<ForgotPasswordResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const response = await fetch(`${SELLER_AUTH_API}/forgot-password`, {
		method: 'POST',
		body: JSON.stringify(values),
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG
		}
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CodeValidateResponse {
	success?: {
		message: string
	}
	data?: {
		token: string
	}
	error?: {
		message: string
		fields?: {
			code?: Message[]
			email?: Message[]
		}
	}
	responseStatusCode?: number
}

export const codeValidate = async (values: {
	code: string
	email: string
}): Promise<CodeValidateResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const response = await fetch(
		`${SELLER_AUTH_API}/forgot-password/code-validate`,
		{
			method: 'POST',
			body: JSON.stringify(values),
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface PasswordResetResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			token?: Message[]
			email?: Message[]
			newPassword?: Message[]
		}
	}
	responseStatusCode?: number
}

export const passwordReset = async (values: {
	email: string
	newPassword: string
	token: string
}): Promise<PasswordResetResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const response = await fetch(`${SELLER_AUTH_API}/password-reset`, {
		method: 'PUT',
		body: JSON.stringify(values),
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG
		}
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
