import {Box, Breadcrumbs, Container} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {useTranslation} from 'react-i18next'
import {FaHome as HomeIcon} from 'react-icons/fa'

import Footer from '../../components/template/Footer'
import Link from '../../components/navigation/Link'
import SectionTitle from '../../components/typography/SectionTitle'
import AccountUpdateEmail from './AccountUpdateEmail'
import AccountUpdatePassword from './AccountUpdatePassword'
import {DashboardFullSection} from '../../components/template/DashboardFullSection'

const Account = () => {
	const {t} = useTranslation()

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<span>{t('account')}</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<Box sx={style.accountBox}>
					<SectionTitle sx={{mb: 5}}>{t('account')}</SectionTitle>
					<Box sx={style.accountOptions}>
						<AccountUpdateEmail />
						<AccountUpdatePassword />
						{/* <AccountDelete /> */}
					</Box>
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

const style = {
	accountBox: (theme: Theme) => ({
		mx: 'auto',
		mt: 0,
		width: '100%',
		p: 0
	}),
	accountOptions: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridGap: 32
	}
}

export default Account
