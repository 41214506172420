import {useTranslation} from 'react-i18next'
import {Theme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import {useLocation} from 'react-router-dom'

import {
	FaUserCog as AccountIcon,
	FaUserCircle as ProfileIcon,
	FaStoreAlt as CompanyIcon,
	FaHome as HomeIcon,
	FaCartArrowDown as SalesIcon,
	FaHandHoldingUsd as PaymentServiceIcon,
	FaCalculator as CalculatorIcon
} from 'react-icons/fa'
import {IoTicket as TicketIcon} from 'react-icons/io5'
import {FaUserGroup as CustomersIcon} from 'react-icons/fa6'
import Link from '../navigation/Link'

interface MainMenuProps {
	menuOpened?: boolean
	onClose?: () => void
}

const MainMenu = ({menuOpened, onClose}: MainMenuProps) => {
	const {t} = useTranslation()
	const {pathname: fullPathName} = useLocation()
	const pathnameWithLang = fullPathName.split('?')[0]
	const pathname = '/' + pathnameWithLang.split('/').pop()

	return (
		<Box sx={style.boxMenu}>
			<List>
				{[
					{
						label: t('home'),
						icon: <HomeIcon style={style.iconMenu} />,
						link: '/'
					},
					{
						label: t('sales'),
						icon: <SalesIcon style={style.iconMenu} />,
						link: '/sales'
					},
					{
						label: t('experiences'),
						icon: <TicketIcon style={style.iconMenu} />,
						link: '/products'
					},
					{
						label: t('customers'),
						icon: <CustomersIcon style={style.iconMenu} />,
						link: '/customers'
					}
				].map(item => (
					<ListItem key={item.label} disablePadding onClick={onClose}>
						<Link to={item.link} inheritStyle>
							<ListItemButton
								sx={[
									pathname === '/' && item.link === '/'
										? style.active
										: item.link !== '/' && pathname.startsWith(item.link)
										? style.active
										: null,
									{
										minHeight: 48
									}
								]}
							>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText
									primary={item.label}
									sx={(theme: Theme) => ({
										[theme.breakpoints.between('sm', 'md')]: {
											display: menuOpened ? 'block' : 'none'
										}
									})}
								/>
							</ListItemButton>
						</Link>
					</ListItem>
				))}
			</List>
			<Divider />
			<List
				subheader={
					<ListSubheader
						component="div"
						id="nested-list-subheader"
						sx={menuOpened ? null : style.tablet.listSubheader}
					>
						{t('settings')}
					</ListSubheader>
				}
			>
				{[
					{
						label: t('profile'),
						icon: <ProfileIcon style={style.iconMenu} />,
						link: '/profile'
					},
					{
						label: t('company'),
						icon: <CompanyIcon style={style.iconMenu} />,
						link: '/company'
					},
					{
						label: t('payment-services'),
						icon: <PaymentServiceIcon style={style.iconMenu} />,
						link: '/payment-services'
					},
					{
						label: t('account'),
						icon: <AccountIcon style={style.iconMenu} />,
						link: '/account'
					}
				].map(item => (
					<ListItem key={item.label} disablePadding onClick={onClose}>
						<Link to={item.link} inheritStyle>
							<ListItemButton
								sx={[
									pathname === '/' && item.link === '/'
										? style.active
										: item.link !== '/' && pathname.startsWith(item.link)
										? style.active
										: null,
									{
										minHeight: 48
									}
								]}
							>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText
									primary={item.label}
									sx={(theme: Theme) => ({
										[theme.breakpoints.between('sm', 'md')]: {
											display: menuOpened ? 'block' : 'none'
										}
									})}
								/>
							</ListItemButton>
						</Link>
					</ListItem>
				))}
			</List>
		</Box>
	)
}

const style = {
	active: {
		bgcolor: 'grey.50',
		borderRight: 3,
		borderRightColor: 'primary.main'
	},
	boxMenu: (theme: Theme) => ({
		overflow: 'auto',
		overflowX: 'hidden',
		[theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
			height: 'calc(100% - 56px)'
		}
	}),
	iconMenu: {width: '18px', height: '18px'},
	tablet: {
		listSubheader: (theme: Theme) => ({
			[theme.breakpoints.up('sm') && theme.breakpoints.down('md')]: {
				background: 'inherit',
				display: 'inline-flex',
				height: 36,
				mt: 1.5,
				mx: 1.5,
				p: 0,
				minHeight: 48
			},
			[theme.breakpoints.between('sm', 'md')]: {
				display: 'none'
			}
		}),
		settingsIconBox: (theme: Theme) => ({
			width: 32,
			height: 32,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: '50%',
			marginRight: 1.5
		})
	}
}

export default MainMenu
