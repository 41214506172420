import {useTranslation} from 'react-i18next'
import {RiArrowDownSFill as ArrowDropDownIcon} from 'react-icons/ri'
import {Status} from './Status'
import {useCallback, useState} from 'react'

interface StatusTagManagerProps {
	status: string
	onChangeStatus: (status: string) => void
}

export const StatusTagManager = ({
	status,
	onChangeStatus
}: StatusTagManagerProps) => {
	const {t} = useTranslation()

	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const handleClose = () => {
		setAnchorEl(null)
		setOpen(false)
	}

	const handleToggle = useCallback(() => {
		setOpen(!open)
	}, [open, setOpen])

	const handleChangeStatus = useCallback(
		(newStatus: string) => {
			onChangeStatus(newStatus)
		},
		[onChangeStatus]
	)

	return (
		<Status.Root
			isButton
			open={open}
			setAnchorEl={setAnchorEl}
			onClick={handleToggle}
		>
			<Status.Tag type={status}>
				<Status.Label
					label={t('status-tag-' + status)}
					showTooltip={false}
					maxWidth="none"
				/>
				<Status.Icon>
					<ArrowDropDownIcon />
				</Status.Icon>
				<Status.List
					anchorEl={anchorEl}
					open={open}
					acceptedStatus={[
						{label: t('to-activate'), key: 'active'},
						{label: t('to-off'), key: 'off'}
					].filter(currentStatus => currentStatus.key !== status)}
					handleClick={newStatus => handleChangeStatus(newStatus)}
					handleClose={handleClose}
				/>
			</Status.Tag>
		</Status.Root>
	)
}
