import {Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {SystemStyleObject} from '@mui/system/styleFunctionSx'

interface TagProps {
	color?: string
	minWidth?: number
	children: React.ReactNode
	sx?: SystemStyleObject<Theme>
}

const Tag = ({children, color, minWidth, sx}: TagProps) => {
	return (
		<Typography
			variant="caption"
			sx={[theme => style.tag(theme, color), {minWidth}, {...sx}]}
		>
			{children}
		</Typography>
	)
}

const style = {
	tag: (theme: Theme, color?: string) => ({
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: color ? color + '20' : theme.palette.grey[200],
		color: color ? color : theme.palette.grey[600],
		borderRadius: 2,
		py: 0.5,
		px: 0.75,
		width: 'fit-content'
	})
}

export default Tag
