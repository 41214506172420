import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import Button from '@mui/material/Button'
import QRCode from 'react-qr-code'
import {
	AsaasSubAccountDocument,
	markSubAccountDocumentStatusAsDone,
	sendAsaasSubAccountDocument
} from '../../../api/payment-service/asaas'
import {Dialog} from '../../../components/Dialog'
import {ChangeEvent, useCallback, useState} from 'react'
import {FaRegClipboard as ClipboardIcon} from 'react-icons/fa6'
import {HiOutlineExternalLink as ExternalLinkIcon} from 'react-icons/hi'
import {MdOutlineFileUpload as FileUploadIcon} from 'react-icons/md'
import {CgClose as CloseIcon} from 'react-icons/cg'
import {MdCheck as CheckIcon} from 'react-icons/md'
import {styled} from '@mui/material/styles'
import {Status} from '../../../components/Status'
import Tooltip from '@mui/material/Tooltip'
import {CircularProgress, Icon, IconButton} from '@mui/material'
import {useMutation} from '@tanstack/react-query'
import AlertMessage from '../../../components/AlertMessage'
import MobileUp from '../../../components/layout/responsive/MobileUp'
import Mobile from '../../../components/layout/responsive/Mobile'
import {DocumentKey} from '../../../stores/useAsaasStates'

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
})

export const AsaasSendDocument = ({
	index,
	documentKey,
	data,
	onUpdateDocument
}: {
	index: number
	documentKey: DocumentKey
	data: AsaasSubAccountDocument
	onUpdateDocument: (params: {
		id: string
		key: DocumentKey
		newStatus: AsaasSubAccountDocument['status']
	}) => void
}) => {
	const [open, setOpen] = useState(false)
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [showFeedbackCopy, setShowFeedbackCopy] = useState(false)
	const [documentFile, setDocumentFile] = useState<File | null>(null)
	const [status, setStatus] = useState(data.status)
	const {t} = useTranslation()

	const handleCopyOnboardingUrl = useCallback((url: string) => {
		navigator.clipboard.writeText(url)
		setShowFeedbackCopy(true)
		setTimeout(() => {
			setShowFeedbackCopy(false)
		}, 2000) // 2 seconds
	}, [])

	const handleChangeDocumentFile = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const file = event.target?.files?.item(0) || null
			setDocumentFile(file)
		},
		[]
	)

	const handleRemoveFile = () => {
		setDocumentFile(null)
	}

	const {isLoading, mutate} = useMutation(sendAsaasSubAccountDocument, {
		networkMode: 'always'
	})

	const sendDocument = useCallback(() => {
		if (!documentFile) {
			setAlertMessage(t('error-file-required'))
			setOpenAlert(true)
			return
		}

		mutate(
			{
				id: data.id,
				type: data.type,
				file: documentFile
			},
			{
				onSuccess: ({data: responseData, error, responseStatusCode}) => {
					if (responseStatusCode === 200 && responseData) {
						setStatus(responseData.status)
						onUpdateDocument({
							id: data.id,
							key: documentKey,
							newStatus: responseData.status
						})
						setOpen(false)
					} else if (
						responseStatusCode === 400 &&
						error?.fields?.documentFile
					) {
						setAlertMessage(error?.fields?.documentFile[0].message)
						setOpenAlert(true)
					} else {
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}, [documentFile, data, t, mutate, onUpdateDocument, documentKey])

	const {isLoading: isMarkingAsDone, mutate: mutateAsDone} = useMutation(
		markSubAccountDocumentStatusAsDone,
		{
			networkMode: 'always'
		}
	)

	const markDocumentAsDone = useCallback(() => {
		mutateAsDone(
			{id: data.id, type: data.type},
			{
				onSuccess: ({data: responseData, error, responseStatusCode}) => {
					if (responseStatusCode === 200 && responseData) {
						setStatus(responseData.status)
						onUpdateDocument({
							id: data.id,
							key: documentKey,
							newStatus: responseData.status
						})

						if (responseData.status === 'not-sent') {
							setAlertMessage(t('error-document-not-sent-yet'))
							setOpenAlert(true)
						} else if (responseData.status === 'rejected') {
							setAlertMessage(t('error-document-rejected'))
							setOpenAlert(true)
						} else {
							setOpen(false)
						}
					} else {
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}, [data, mutateAsDone, t, onUpdateDocument, documentKey])

	return (
		<>
			<Dialog.Root open={open} maxWidth={500}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpen(false)}>
						{t(
							`create-asaas-account-send-documents-step-document-${documentKey}`
						)}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width={500}>
					<Box>
						{data.url === null ? (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 2,
									alignItems: 'flex-start'
								}}
							>
								<Typography>{data.description}</Typography>
								{documentFile ? (
									<Box>
										<Box
											sx={{
												display: 'inline-flex',
												alignItems: 'center',
												gap: 1,
												backgroundColor: 'grey.100',
												borderRadius: '12px',
												pl: 1.5
											}}
										>
											<Typography variant="body2" color="text.primary">
												{documentFile.name}
											</Typography>
											<Tooltip title={t('to-remove')} arrow>
												<IconButton
													sx={{
														color: 'text.secondary',
														fontSize: '1rem'
													}}
													onClick={handleRemoveFile}
												>
													<CloseIcon />
												</IconButton>
											</Tooltip>
										</Box>
										<AlertMessage
											open={openAlert}
											severity="error"
											message={alertMessage}
											onClose={() => setOpenAlert(false)}
										/>
										<Button
											variant="contained"
											onClick={sendDocument}
											disabled={isLoading}
											sx={{mt: 3, minWidth: '180px', minHeight: '36.5px'}}
										>
											{isLoading ? (
												<CircularProgress color="inherit" size={24} />
											) : (
												t('to-send-document')
											)}
										</Button>
									</Box>
								) : (
									<Button
										variant="outlined"
										component="label"
										role={undefined}
										tabIndex={-1}
										startIcon={<FileUploadIcon />}
									>
										{t('to-add-document')}
										<VisuallyHiddenInput
											type="file"
											onChange={handleChangeDocumentFile}
										/>
									</Button>
								)}
							</Box>
						) : (
							<Box
								sx={{
									display: 'flex',
									gap: 2,
									alignItems: 'center',
									flexWrap: 'wrap'
								}}
							>
								<MobileUp>
									<Card variant="outlined" sx={{p: 1, width: 150, height: 150}}>
										<QRCode
											value={data.url}
											style={{width: '100%', height: '100%'}}
										/>
									</Card>
								</MobileUp>
								<Mobile>
									<Typography>
										{t('sending-documents-describe-mobile')}
									</Typography>
									<Button
										component="a"
										target="_blank"
										href={data.url}
										variant="outlined"
										startIcon={<ExternalLinkIcon />}
									>
										{t('to-send-documents')}
									</Button>
								</Mobile>
								<MobileUp>
									<Box
										sx={{
											maxWidth: 'calc(100% - 174px)'
										}}
									>
										<Typography mb={3}>
											{t('sending-documents-describe-desktop')}
										</Typography>
										<Tooltip
											title={t('copied-link')}
											arrow
											open={showFeedbackCopy}
										>
											<Button
												variant="outlined"
												startIcon={<ClipboardIcon />}
												onClick={handleCopyOnboardingUrl.bind(null, data.url)}
											>
												{t('copy-link')}
											</Button>
										</Tooltip>
									</Box>
								</MobileUp>
								<Box>
									<Typography>
										{t('sending-documents-qr-code-confirm')}
									</Typography>
									<AlertMessage
										open={openAlert}
										severity="error"
										message={alertMessage}
										onClose={() => setOpenAlert(false)}
									/>
									<Button
										variant="contained"
										onClick={markDocumentAsDone}
										disabled={isMarkingAsDone}
										sx={{mt: 2, height: '36.5px', width: '80px'}}
									>
										{isMarkingAsDone ? (
											<CircularProgress color="inherit" size={24} />
										) : (
											`${t('done')}`
										)}
									</Button>
								</Box>
							</Box>
						)}
					</Box>
				</Dialog.Body>
			</Dialog.Root>
			<Card
				variant="outlined"
				sx={{
					p: 2
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						gap: '4px'
					}}
				>
					<Typography variant="subtitle1" sx={{flex: 1, fontWeight: 500}}>
						{index}.{' '}
						{t(
							`create-asaas-account-send-documents-step-document-${documentKey}`
						)}
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						gap: 3,
						mt: 2
					}}
				>
					{status === 'not-sent' || status === 'rejected' ? (
						<Button variant="outlined" onClick={() => setOpen(true)}>
							{status === 'not-sent' ? t('send') : t('send-again')}
						</Button>
					) : (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'unset',
								gap: '4px'
							}}
						>
							<Icon color="success" fontSize="inherit">
								<CheckIcon />
							</Icon>
							<Typography variant="body2" color="text.secondary">
								{t('document-sent')}
							</Typography>
						</Box>
					)}
					<Status.Tag type={status} size="small">
						{t('status-analysis-asaas-' + status)}
					</Status.Tag>
				</Box>
			</Card>
		</>
	)
}
