import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import {SaleDetails} from '../entities/Sale'

export interface SaleListItem {
	id: string
	createdAt: string
	status: string
	totalAmount: number
	totalItems: number
	edition: {
		title: string
		experience: {
			title: string
			cover: string
		}
	}
	buyer: {
		name?: string
		email: string
	}
}

export type SaleIntegration = {
	id: string
	name: 'cademi' | 'the-members'
	status: string
	statusHistory: {status: string; log?: string; date: string}[]
	product: {publicId: string; title: string; cover: string}
}

export type SaleMetricsPreferencesFilter =
	| 'last-year'
	| 'last-12-months'
	| 'current-year'
	| 'last-7-days'
	| 'last-30-days'
	| 'today'
	| 'yesterday'

export type SaleWebhookEvent = {
	id: string
	webhookName: string
	eventType: string
	status: string
	requestBody: string
	responseMetadata: string
	sentAt: string
}

interface SaleStates {
	salesList: SaleListItem[]
	setSalesList: (salesList: SaleListItem[]) => void
	saleMetricsPreferences: {
		filter: SaleMetricsPreferencesFilter
	}
	setSaleMetricsPreferences: (saleMetricsPreferences: {
		filter: SaleMetricsPreferencesFilter
	}) => void
	listLimit: number
	setListLimit: (saleListLimit: number) => void
	total: number
	setTotal: (total: number) => void
	saleDetails: SaleDetails | null
	setSaleDetails: (saleDetails: SaleDetails | null) => void
	saleIntegrations: {
		cademi: SaleIntegration[]
		'the-members': SaleIntegration[]
	}
	setSaleIntegrations: (saleIntegrations: {
		cademi: SaleIntegration[]
		'the-members': SaleIntegration[]
	}) => void
	webhookEvents: SaleWebhookEvent[]
	setWebhookEvents: (webhookEvents: SaleWebhookEvent[]) => void
	updateSaleIntegrationStatus: (data: {
		id: string
		status: string
		name: 'cademi' | 'the-members'
		log?: string
	}) => void
	updateSaleIntegrationsSend: (send: true | null) => void
	changeSaleStatus: (data: {id: string; status: string}) => void
	changeSaleRefundUrl: (refundUrl: string) => void
	deleteSale: (saleId: string) => void
}

const useSaleStates = create<SaleStates>()(
	persist(
		set => ({
			salesList: [],
			setSalesList: (salesList: SaleListItem[]) => set({salesList}),
			listLimit: 10,
			setListLimit: (listLimit: number) => set({listLimit}),
			saleMetricsPreferences: {
				filter: 'last-12-months'
			},
			setSaleMetricsPreferences: saleMetricsPreferences =>
				set({saleMetricsPreferences}),
			total: 0,
			setTotal: (total: number) => set({total}),
			saleDetails: null,
			setSaleDetails: saleDetails => set({saleDetails}),
			saleIntegrations: {
				cademi: [],
				'the-members': []
			},
			setSaleIntegrations: saleIntegrations => set({saleIntegrations}),
			updateSaleIntegrationStatus: ({id, status, log, name}) =>
				set(state => ({
					saleIntegrations: {
						...state.saleIntegrations,
						[name]: state.saleIntegrations[name].map(saleIntegration =>
							saleIntegration.id === id
								? {
										...saleIntegration,
										status,
										statusHistory: [
											...saleIntegration.statusHistory,
											{status, log, date: new Date().toISOString()}
										]
								  }
								: saleIntegration
						)
					}
				})),
			webhookEvents: [],
			setWebhookEvents: (webhookEvents: SaleWebhookEvent[]) =>
				set({webhookEvents}),
			updateSaleIntegrationsSend: (send: true | null) =>
				set(state => ({
					saleDetails: state.saleDetails
						? {
								...state.saleDetails,
								sendIntegrationsFailed: send
						  }
						: state.saleDetails
				})),
			changeSaleStatus: ({id, status}) =>
				set(state => ({
					salesList: state.salesList.map(sale =>
						sale.id === id ? {...sale, status} : sale
					),
					saleDetails: state.saleDetails
						? {
								...state.saleDetails,
								status,
								statusHistory: [
									...state.saleDetails.statusHistory,
									{status, date: new Date().toISOString()}
								]
						  }
						: state.saleDetails
				})),
			changeSaleRefundUrl: refundUrl =>
				set(state => ({
					saleDetails: state.saleDetails
						? {
								...state.saleDetails,
								refund: {
									...state?.saleDetails?.refund,
									refundUrl: refundUrl
								}
						  }
						: state.saleDetails
				})),
			deleteSale: saleId =>
				set(state => ({
					salesList: state.salesList.filter(sale => sale.id !== saleId)
				}))
		}),
		{
			name: 'sales-storage',
			getStorage: () => localStorage,
			partialize: state => ({
				saleMetricsPreferences: state.saleMetricsPreferences,
				listLimit: state.listLimit
			})
		}
	)
)

export default useSaleStates
