import {DEFAULT_LANG, SELLER_EXPERIENCES_API} from '../config'
import {
	PlatformIntegration,
	ExperienceIntegration
} from '../entities/Integration'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'

interface Message {
	message: string
}

interface ListIntegrationsResponse {
	error?: Message
	data?: PlatformIntegration[]
	responseStatusCode?: number
}

export const listPlatformIntegrations = async ({
	experienceId
}: {
	experienceId: string
}): Promise<ListIntegrationsResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/integrations/?experience_id=${experienceId}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ListExperienceIntegrationsResponse {
	error?: Message
	data?: ExperienceIntegration[]
	responseStatusCode?: number
}

export const listExperienceIntegrations = async ({
	experienceId
}: {
	experienceId: string
}): Promise<ListExperienceIntegrationsResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/integrations/experience-integrations/${experienceId}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ChangeIntegrationStatusResponse {
	error?: Message
	responseStatusCode?: number
}

interface CreateExperienceIntegrationResponse {
	error?: Message
	data?: {
		id: string
		status: string
	}
	responseStatusCode?: number
}

export const createExperienceIntegration = async (values: {
	experienceId: string
	integrationId: string
	initialStatus?: string
}): Promise<CreateExperienceIntegrationResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/integrations/`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

export const changeIntegrationStatus = async ({
	id,
	status
}: {
	id: string
	status: string
}): Promise<ChangeIntegrationStatusResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/integrations/${id}/status`,
		{
			method: 'PATCH',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({status})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateFacebookPixelIntegrationResponse {
	error?: Message
	data?: {
		id: string
		status: string
		metadata?: string
	}
	responseStatusCode?: number
}

export const updateFacebookPixelIntegration = async ({
	id,
	pixelId
}: {
	id: string
	pixelId?: string
}): Promise<UpdateFacebookPixelIntegrationResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/integrations/meta-pixel/${id}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({pixelId})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateCademiIntegrationResponse {
	error?: {
		message: string
		fields?: {
			productId?: Message[]
			tokenAPI?: Message[]
			subdomain?: Message[]
		}
	}
	data?: {
		id: string
		status: string
		metadata: string
	}
	responseStatusCode?: number
}

export const updateCademiIntegration = async ({
	id,
	...values
}: {
	id: string
	tokenAPI?: string
	productId?: string
	subdomain?: string
}): Promise<UpdateCademiIntegrationResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/integrations/cademi/${id}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateTheMembersIntegrationResponse {
	error?: Message
	data?: {
		id: string
		status: string
		metadata: string
	}
	responseStatusCode?: number
}

export const updateTheMembersIntegration = async ({
	id,
	tokenAPI,
	productId
}: {
	id: string
	tokenAPI?: string
	productId?: string
}): Promise<UpdateTheMembersIntegrationResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/integrations/the-members/${id}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({tokenAPI, productId})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateUpsellIntegrationResponse {
	error?: Message
	data?: {
		id: string
		status: string
		metadata?: string
	}
	responseStatusCode?: number
}

export const updateUpsellIntegration = async ({
	id,
	redirectFromInstantPayment,
	redirectFromPostPayment
}: {
	id: string
	redirectFromInstantPayment?: string
	redirectFromPostPayment?: string
}): Promise<UpdateUpsellIntegrationResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/integrations/upsell/${id}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				redirectFromInstantPayment,
				redirectFromPostPayment
			})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateWhatsAppCheckoutIntegrationResponse {
	error?: Message
	data?: {
		id: string
		status: string
		metadata: string
	}
	responseStatusCode?: number
}

export const updateWhatsAppCheckoutIntegration = async ({
	id,
	phoneType,
	phoneCountryCode,
	phoneAreaCode,
	phoneNumber
}: {
	id: string
	phoneType?: 'landline' | 'mobile'
	phoneCountryCode?: string
	phoneAreaCode?: string
	phoneNumber?: string
}): Promise<UpdateWhatsAppCheckoutIntegrationResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/integrations/whatsapp-checkout/${id}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				phoneType,
				phoneCountryCode,
				phoneAreaCode,
				phoneNumber
			})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
