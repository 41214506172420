import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {price} from '../helpers/price'
import {SxProps, Theme} from '@mui/material/styles'

interface PriceProps {
	value?: number
	format?: 'BRL' | 'USD'
	variant?:
		| 'body1'
		| 'body2'
		| 'caption'
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
	sx?: SxProps<Theme>
	hiddenCurrencySymbol?: boolean
	color?: string
	full?: boolean
}

const Price = ({
	value = 0,
	format = 'BRL',
	variant,
	color,
	full,
	sx,
	hiddenCurrencySymbol = false
}: PriceProps) => {
	if (full) {
		return (
			<Typography variant={variant} color={color} width="fit-content" sx={sx}>
				{price.full(value, format, hiddenCurrencySymbol)}
			</Typography>
		)
	}

	return (
		<Tooltip title={price.full(value, format)} arrow>
			<Typography variant={variant} color={color} width="fit-content" sx={sx}>
				{price.abbreviate(value, format, hiddenCurrencySymbol)}
			</Typography>
		</Tooltip>
	)
}

export default Price
