import {DEFAULT_LANG, SELLER_EXPERIENCES_API} from '../config'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import {RawSalePage} from './entities/RawSalePage'
import {SalePageSection} from '../entities/SalePageSection'

interface Message {
	message: string
}

interface ListSagePageResponse {
	error?: Message
	data?: RawSalePage
	responseStatusCode?: number
}

export const listSalePage = async ({
	experienceSlug
}: {
	experienceSlug: string
}): Promise<ListSagePageResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceSlug}/sale-page`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CreateSagePageResponse {
	error?: Message
	data?: RawSalePage
	responseStatusCode?: number
}

export const createSalePage = async ({
	experienceSlug
}: {
	experienceSlug: string
}): Promise<CreateSagePageResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceSlug}/sale-page`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ChangePublishSagePageResponse {
	success?: Message
	error?: {
		message: string
		fields?: {
			publish: Message[]
		}
	}
	responseStatusCode?: number
}

export const changeSalePagePublishStatus = async ({
	experienceId,
	salePageId,
	...values
}: {
	experienceId: string
	salePageId: string
	publish?: boolean
}): Promise<ChangePublishSagePageResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/publish`,
		{
			method: 'PATCH',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteSagePageResponse {
	success?: Message
	error?: Message
	responseStatusCode?: number
}

export const deleteSalePage = async ({
	experienceId,
	salePageId
}: {
	experienceId: string
	salePageId: string
}): Promise<DeleteSagePageResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}`,
		{
			method: 'DELETE',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UploadSagePageCoverResponse {
	success?: Message
	error?: Message
	data?: {
		basePath: string
		imagePath: string
	}
	responseStatusCode?: number
}

export const uploadSalePageCover = async ({
	experienceId,
	salePageId,
	newCover
}: {
	experienceId: string
	salePageId: string
	newCover: File | null
}): Promise<UploadSagePageCoverResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const formData = new FormData()
	if (newCover) formData.append('newCover', newCover)

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/cover`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: formData
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteSagePageCoverResponse {
	success?: Message
	error?: Message
	responseStatusCode?: number
}

export const deleteSalePageCover = async ({
	experienceId,
	salePageId
}: {
	experienceId: string
	salePageId: string
}): Promise<DeleteSagePageCoverResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/cover`,
		{
			method: 'DELETE',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UploadSalePageGalleryResponse {
	success?: Message
	error?: {
		message: string
		fields?: {
			photo: Message[]
		}
	}
	data?: {
		id: string
		basePath: string
		imagePath: string
		imageName: string
		sorting: string[]
	}
	responseStatusCode?: number
}

export const uploadSalePageGallery = async ({
	experienceId,
	salePageId,
	file
}: {
	experienceId: string
	salePageId: string
	file: File | null
}): Promise<UploadSalePageGalleryResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const formData = new FormData()
	if (file) formData.append('file', file)

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/gallery/photo`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: formData
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateSalePageGalleryResponse {
	success?: Message
	error?: {
		message: string
		fields?: {
			photo: Message[]
		}
	}
	data?: {
		id: string
		basePath: string
		imagePath: string
		imageName: string
		sorting: string[]
	}
	responseStatusCode?: number
}

export const updateSalePageGallery = async ({
	experienceId,
	salePageId,
	file,
	photoId
}: {
	experienceId: string
	salePageId: string
	file: File | null
	photoId: string
}): Promise<UpdateSalePageGalleryResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const formData = new FormData()
	if (file) formData.append('file', file)

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/gallery/photo/${photoId}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: formData
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateSalePageGallerySortingResponse {
	success?: Message
	error?: {
		message: string
		fields?: {
			oldIndex: Message[]
			newIndex: Message[]
		}
	}
	data?: {
		sorting: string[]
	}
	responseStatusCode?: number
}

export const updateSalePageGallerySorting = async ({
	experienceId,
	salePageId,
	...values
}: {
	experienceId: string
	salePageId: string
	oldIndex: number
	newIndex: number
}): Promise<UpdateSalePageGallerySortingResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/gallery/sorting`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteSalePagePhotoResponse {
	success?: Message
	error?: Message
	responseStatusCode?: number
}

export const deleteSalePagePhoto = async ({
	experienceId,
	salePageId,
	photoId
}: {
	experienceId: string
	salePageId: string
	photoId: string
}): Promise<DeleteSalePagePhotoResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/gallery/photo/${photoId}`,
		{
			method: 'DELETE',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteSalePageGalleryResponse {
	success?: Message
	error?: Message
	responseStatusCode?: number
}

export const deleteSalePageGallery = async ({
	experienceId,
	salePageId
}: {
	experienceId: string
	salePageId: string
}): Promise<DeleteSalePageGalleryResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/gallery`,
		{
			method: 'DELETE',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateSalePageSectionsSortingResponse {
	success?: Message
	error?: {
		message: string
		fields?: {
			oldIndex: Message[]
			newIndex: Message[]
		}
	}
	data: {
		sorting: string[]
	}
	responseStatusCode?: number
}

export const updateSalePageSectionsSorting = async ({
	experienceId,
	salePageId,
	...values
}: {
	experienceId: string
	salePageId: string
	oldIndex: number
	newIndex: number
}): Promise<UpdateSalePageSectionsSortingResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/sections-sorting`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface SaveSalePageSectionResponse {
	success?: Message
	error?: {
		message: string
		fields?: {
			type: Message[]
			component: Message[]
			title: Message[]
			content: Message[]
		}
	}
	data: SalePageSection & {sorting: string[]}
	responseStatusCode?: number
}

export const saveSalePageSection = async ({
	experienceId,
	salePageId,
	sectionId,
	...values
}: {
	experienceId: string
	salePageId: string
	sectionId?: string
	type: string
	component: {
		content: string
		title?: string
	}
}): Promise<SaveSalePageSectionResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	let response: Response

	if (!sectionId) {
		response = await authenticatedFetch(
			`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/section`,
			{
				method: 'POST',
				headers: {
					'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
					'Authorization': `Bearer ${accessToken}`
				},
				body: JSON.stringify(values)
			}
		)
	} else {
		response = await authenticatedFetch(
			`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/section/${sectionId}`,
			{
				method: 'PUT',
				headers: {
					'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
					'Authorization': `Bearer ${accessToken}`
				},
				body: JSON.stringify(values)
			}
		)
	}

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CreateSalePageSectionResponse {
	success?: Message
	error?: {
		message: string
		fields?: {
			type?: Message[]
			component?: Message[]
			title?: Message[]
			content?: Message[]
			videoUrl?: Message[]
		}
	}
	data: SalePageSection & {sorting: string[]}
	responseStatusCode?: number
}

export const createSection = async ({
	experienceId,
	salePageId,
	...values
}: {
	experienceId: string
	salePageId: string
	type: string
	component: {
		title?: string
		content?: string
		videoUrl?: string
		videoOrigin?: string
	}
}): Promise<CreateSalePageSectionResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/section`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateSalePageSectionResponse {
	success?: Message
	error?: {
		message: string
		fields?: {
			type?: Message[]
			component?: Message[]
			title?: Message[]
			content?: Message[]
			videoUrl?: Message[]
		}
	}
	data: SalePageSection & {sorting: string[]}
	responseStatusCode?: number
}

export const updateSection = async ({
	experienceId,
	salePageId,
	sectionId,
	...values
}: {
	experienceId: string
	salePageId: string
	sectionId: string
	type: string
	component: {
		title?: string
		content?: string
		videoUrl?: string
		videoOrigin?: string
	}
}): Promise<UpdateSalePageSectionResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/section/${sectionId}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteSalePageSectionResponse {
	success?: Message
	error?: Message
	responseStatusCode?: number
}

export const deleteSalePageSection = async ({
	experienceId,
	salePageId,
	sectionId
}: {
	experienceId: string
	salePageId: string
	sectionId: string
}): Promise<DeleteSalePageSectionResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/sale-page/${salePageId}/section/${sectionId}`,
		{
			method: 'DELETE',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
