import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import {Button, Typography} from '@mui/material'

import {listSales} from '../../../api/sales'
import {regex} from '../../../helpers/regex'
import SalesList from '../../../components/lists/sales/SalesList'
import {Edition} from '../../../entities/Edition'
import AlertMessage from '../../../components/AlertMessage'
import useSaleStates from '../../../stores/useSaleStates'

interface EditionSalesProps {
	edition: Edition
}

const EditionSales = ({edition}: EditionSalesProps) => {
	const [firstLoad, setFirstLoad] = useState(true)
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const {setSalesList, setTotal} = useSaleStates()
	const [page, setPage] = useState(0)
	const [offset, setOffset] = useState(0)
	const [limit, setLimit] = useState(10)
	const [filters, setFilters] = useState<{status?: string}>()
	const [status, setStatus] = useState<
		'error' | 'idle' | 'loading' | 'success'
	>('idle')

	const {t} = useTranslation()

	const {isLoading, mutate} = useMutation(listSales, {
		networkMode: 'always'
	})

	const handleChangeURL = (page: number) => {
		window.history.replaceState(
			undefined,
			'Dashboard | Excofy',
			`/products/${edition.experience.slug}/${edition.slug}?page=${page}`
		)
	}

	const fetchSales = useCallback(
		async () => {
			setOpenAlert(false)
			setStatus('loading')
			mutate(
				{
					offset,
					limit,
					filters: regex.parseObjectToQueryString({
						offerId: edition._id,
						...filters
					})
				},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							setStatus('success')
							if (firstLoad) {
								setFirstLoad(false)
							}

							setTotal(
								offset === 0
									? result.data.sales.length
									: result.data.sales.length + offset
							)

							if (offset !== 0 && result.data.sales.length === 0) {
								const newOffset = offset - limit
								setOffset(newOffset)
								setPage(newOffset / limit)
							} else {
								setSalesList(result.data.sales)
								setPage(offset / limit)
								handleChangeURL(offset / limit)
							}
						} else {
							setStatus('error')
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setStatus('error')
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[offset, limit, edition, filters]
	)

	useEffect(() => {
		fetchSales()
	}, [fetchSales, filters])

	return (
		<>
			<Typography variant="h6" sx={{mb: 1, mt: 3}}>
				{t('sales')}
			</Typography>
			{status === 'error' ? (
				<>
					<AlertMessage
						severity="error"
						open={openAlert}
						message={alertMessage}
						onClose={() => setOpenAlert(false)}
						sx={{mb: openAlert ? 2 : 0}}
					/>
					<Button variant="outlined" onClick={fetchSales}>
						{t('to-list-sales')}
					</Button>
				</>
			) : (
				<SalesList
					limit={limit}
					isLoading={isLoading}
					firstLoad={firstLoad}
					page={page}
					setFilters={setFilters}
					setLimit={setLimit}
					setOffset={setOffset}
					setPage={setPage}
					handleChangeURL={handleChangeURL}
				/>
			)}
		</>
	)
}

export default EditionSales
