import {useTranslation} from 'react-i18next'
import {Status} from '../../Status'
import {useState} from 'react'
import {RiArrowDownSFill as ArrowDropDownIcon} from 'react-icons/ri'
import {HiOutlineDotsHorizontal as DotsIcon} from 'react-icons/hi'
import {
	Button,
	Box,
	Typography,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
	IconButton,
	Skeleton
} from '@mui/material'
import {AddWebhookForm} from './AddWebhookForm'
import {useMutation, useQuery} from '@tanstack/react-query'
import {deleteWebhook, fetchWebhooks} from '../../../api/webhooks'
import MenuList from '../../MenuList'
import AlertMessage from '../../AlertMessage'
import {EditWebhookForm} from './EditWebhookForm'
import {WebhooksLogs} from './WebhooksLogs'
import {ConfirmDialog} from '../../dialogs/ConfirmDialog'

type Webhook = {
	id: string
	name: string
	url: string
	status: string
	authenticationToken?: string
	events: {
		sales: ('SALE_CREATED' | 'SALE_UPDATED')[]
	}
}

export const WebhooksForm = ({
	id,
	productId,
	metadata,
	status,
	onChangeStatus,
	onUpdate
}: {
	id: string
	productId: string
	metadata?: string
	status: string
	onChangeStatus: (status: string) => void
	onUpdate: (data: {id: string; status: string; metadata?: string}) => void
}) => {
	const {t} = useTranslation()

	const [webhooks, setWebhooks] = useState<Webhook[]>([])
	const [openAddWebhook, setOpenAddWebhook] = useState(false)
	const [openStatusList, setOpenStatusList] = useState(false)
	const [anchorElStatusList, setAnchorElStatusList] =
		useState<null | HTMLElement>(null)
	const [anchorElOptions, setAnchorElOptions] = useState<null | HTMLElement>(
		null
	)
	const openOptions = Boolean(anchorElOptions)
	const [alertMessage, setAlertMessage] = useState('')
	const [openAlert, setOpenAlert] = useState(false)
	const [openEditWebhook, setOpenEditWebhook] = useState(false)
	const [editWebhookValues, setEditWebhookValues] = useState<Webhook | null>(
		null
	)
	const [openLogs, setOpenLogs] = useState(false)
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

	const handleAddWebhook = (webhook: Webhook) => {
		setWebhooks([...webhooks, webhook])
	}

	const handleUpdateWebhook = (webhook: Webhook) => {
		const updatedWebhooks = webhooks.map(currentWebhook =>
			currentWebhook.id === webhook.id ? webhook : currentWebhook
		)

		setWebhooks(updatedWebhooks)
	}

	const {mutate: mutateDeleteWebhook} = useMutation(deleteWebhook, {
		networkMode: 'always'
	})

	const handleDeleteWebhook = (webhookId: string) => {
		setWebhooks(webhooks.filter(webhook => webhook.id !== webhookId))
		mutateDeleteWebhook(
			{id: webhookId},
			{
				onSuccess: ({responseStatusCode, error}) => {
					if (responseStatusCode !== 200) {
						setWebhooks([...webhooks])
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}

	const {isFetching} = useQuery([], () => fetchWebhooks({productId}), {
		refetchOnWindowFocus: false,
		onSuccess: ({data, error}) => {
			if (data?.webhooks) {
				setWebhooks(data.webhooks)
			} else {
				setAlertMessage(error?.message || t('error-server-default'))
				setOpenAlert(true)
			}
		},
		onError: error => {
			setAlertMessage(t('error-server-default'))
			setOpenAlert(true)
			console.error(error)
		}
	})

	return (
		<>
			<AddWebhookForm
				productId={productId}
				open={openAddWebhook}
				onClose={() => {
					setOpenAddWebhook(false)
				}}
				onAdd={handleAddWebhook}
			/>
			<WebhooksLogs
				productId={productId}
				open={openLogs}
				onClose={() => setOpenLogs(false)}
			/>
			<ConfirmDialog
				open={openDeleteDialog}
				title={t('delete-webhook-message-1')}
				contentMessage={t('delete-webhook-message-2')}
				confirmText={t('to-delete')}
				onConfirm={() => handleDeleteWebhook(editWebhookValues?.id || '')}
				onClose={() => setOpenDeleteDialog(false)}
				onCancel={() => setOpenDeleteDialog(false)}
			/>
			{editWebhookValues && openEditWebhook && (
				<EditWebhookForm
					open={openEditWebhook}
					id={editWebhookValues.id}
					initialValues={{
						status: editWebhookValues.status,
						name: editWebhookValues.name,
						url: editWebhookValues.url,
						authenticationToken: editWebhookValues.authenticationToken,
						events: editWebhookValues.events
					}}
					onClose={() => {
						setOpenEditWebhook(false)
						setEditWebhookValues(null)
					}}
					onUpdate={handleUpdateWebhook}
				/>
			)}
			<MenuList
				open={openOptions}
				anchorEl={anchorElOptions}
				list={[
					{
						label: t('edit'),
						onClick: () => {
							setOpenEditWebhook(true)
						}
					},
					{
						label: t('to-delete'),
						onClick: () => {
							setOpenDeleteDialog(true)
						}
					}
				]}
				onClose={() => setAnchorElOptions(null)}
			/>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: 3
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'center',
						gap: 1.5
					}}
				>
					<Typography variant="subtitle1">
						{t('integration-status')}:
					</Typography>
					{status === 'pending-setup' ? (
						<Status.Tag type={status} size="large">
							<Status.Label label={t('status-tag-' + status)} maxWidth="auto" />
						</Status.Tag>
					) : (
						<Status.Root
							isButton
							open={openStatusList}
							setAnchorEl={setAnchorElStatusList}
							onClick={() => setOpenStatusList(!openStatusList)}
						>
							<Status.Tag type={status}>
								<Status.Label
									label={t('status-tag-' + status)}
									showTooltip={false}
								/>
								<Status.Icon>
									<ArrowDropDownIcon />
								</Status.Icon>
								<Status.List
									anchorEl={anchorElStatusList}
									open={openStatusList}
									acceptedStatus={[
										{label: t('to-activate'), key: 'active'},
										{label: t('to-off'), key: 'off'}
									].filter(currentStatus => currentStatus.key !== status)}
									handleClick={onChangeStatus}
									handleClose={() => setAnchorElStatusList(null)}
								/>
							</Status.Tag>
						</Status.Root>
					)}
				</Box>
				<Box>
					<Typography variant="subtitle1">{t('webhooks')}</Typography>
					<Typography mt={1}>{t('webhooks-description')}</Typography>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 1,
							flexWrap: 'wrap',
							mt: 2
						}}
					>
						<Button variant="contained" onClick={() => setOpenAddWebhook(true)}>
							{t('to-add-webhook')}
						</Button>
						{webhooks.length !== 0 && (
							<Button variant="outlined" onClick={() => setOpenLogs(true)}>
								{t('to-view-logs')}
							</Button>
						)}
					</Box>
				</Box>
				{openAlert && (
					<AlertMessage
						severity="error"
						open={openAlert}
						message={alertMessage}
						onClose={() => setOpenAlert(false)}
						mt={0}
					/>
				)}
				{isFetching && (
					<Box display="grid">
						<TableContainer component={Paper} variant="outlined">
							<Table sx={{minWidth: 400}} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>{t('name')}</TableCell>
										<TableCell sx={{width: 140}}>{t('status')}</TableCell>
										<TableCell align="center" sx={{width: 80}}>
											{t('actions')}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow
										sx={{'&:last-child td, &:last-child th': {border: 0}}}
									>
										<TableCell component="th" scope="row">
											<Skeleton variant="text" />
										</TableCell>
										<TableCell align="right" sx={{height: 60.5}}>
											<Skeleton variant="text" />
										</TableCell>
										<TableCell align="center">
											<Skeleton variant="text" />
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				)}
				{webhooks.length !== 0 && !isFetching && (
					<Box display="grid">
						<TableContainer component={Paper} variant="outlined">
							<Table sx={{minWidth: 400}} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>{t('name')}</TableCell>
										<TableCell sx={{width: 140}}>{t('status')}</TableCell>
										<TableCell align="center" sx={{width: 80}}>
											{t('actions')}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{webhooks.map(row => (
										<TableRow
											key={'webhook-' + row.id}
											sx={{'&:last-child td, &:last-child th': {border: 0}}}
										>
											<TableCell component="th" scope="row">
												{row.name}
											</TableCell>
											<TableCell align="right">
												<Status.Tag type={row.status}>
													<Status.Label label={t('status-tag-' + row.status)} />
												</Status.Tag>
											</TableCell>
											<TableCell align="center">
												<Tooltip
													title={t('view-actions')}
													placement="bottom"
													arrow
												>
													<IconButton
														onClick={(event: React.MouseEvent<HTMLElement>) => {
															setAnchorElOptions(event.currentTarget)
															setEditWebhookValues(row)
														}}
														size="small"
														aria-controls={
															openOptions ? t('actions') : undefined
														}
														aria-haspopup="true"
														aria-expanded={openOptions ? 'true' : undefined}
													>
														<DotsIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				)}
			</Box>
		</>
	)
}
