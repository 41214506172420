import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

interface TableRowEmptyProps {
	message: string
}

export const TableRowEmpty = ({message}: TableRowEmptyProps) => {
	return (
		<TableRow>
			<TableCell colSpan={6} align="center" sx={{borderBottom: 'none'}}>
				<Typography variant="body2" sx={{color: 'text.secondary'}}>
					{message}
				</Typography>
			</TableCell>
		</TableRow>
	)
}
