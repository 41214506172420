import {useTranslation} from 'react-i18next'
import {Dialog} from '../../Dialog'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Status} from '../../Status'
import {date} from '../../../helpers/date'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import useAppStates from '../../../stores/useAppStates'
import {useState} from 'react'
import {CircularProgress} from '@mui/material'

type WebhookEvent = {
	id: string
	sentAt: string
	status: string
	eventType: string
	webhookName: string
	requestBody: string
	responseMetadata: string
}

export const WebhooksLogsDetails = ({
	event,
	open,
	onClose,
	onResend,
	isResending
}: {
	event: WebhookEvent
	open: boolean
	onClose: () => void
	onResend: (id: string) => void
	isResending: boolean
}) => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()

	const [value, setValue] = useState('request')

	const handleResend = () => {
		onResend(event.id)
	}

	return (
		<Dialog.Root open={open} maxWidth={600}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>{t('event-details')}</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
				width={600}
			>
				<Box width="100%">
					<Box
						sx={theme => ({
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'flex-start',
							justifyContent: 'space-between',
							gap: 4,

							[theme.breakpoints.down('sm')]: {
								flexDirection: 'column',
								gap: 2
							}
						})}
					>
						<Box>
							<Typography variant="subtitle1">{t('status')}:</Typography>
							<Status.Tag type={event.status}>
								<Status.Label label={t('status-tag-' + event.status)} />
							</Status.Tag>
						</Box>
						<Box>
							<Typography variant="subtitle1">{t('event')}</Typography>
							<Typography>{t(event.eventType)}</Typography>
						</Box>
						<Box>
							<Typography variant="subtitle1">{t('sent-at')}</Typography>
							<Typography>
								{date.convertISOToLocation(event.sentAt, currentLang, true)}
							</Typography>
						</Box>
						<Box>
							<Button
								variant="contained"
								onClick={handleResend}
								disabled={isResending}
								sx={{width: 102}}
							>
								{isResending ? (
									<CircularProgress size={24} color="inherit" />
								) : (
									t('to-resend')
								)}
							</Button>
						</Box>
					</Box>
					<Box width="100%" mt={2}>
						<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
							<Tabs
								value={value}
								onChange={(event, newValue) => setValue(newValue)}
								aria-label="simple tabs example"
							>
								<Tab label={t('request')} value="request" />
								<Tab label={t('response')} value="response" />
							</Tabs>
						</Box>
						<Box
							sx={{
								backgroundColor: 'grey.100',
								borderBottomLeftRadius: 8,
								borderBottomRightRadius: 8,
								px: 2,
								overflow: 'auto'
							}}
						>
							<pre>
								{value === 'request'
									? event.requestBody
									: event.responseMetadata}
							</pre>
						</Box>
					</Box>
				</Box>
			</Dialog.Body>
		</Dialog.Root>
	)
}
