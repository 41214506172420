import {useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import {Theme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Skeleton from '@mui/material/Skeleton'
import {HiOutlineExternalLink as ExternalLinkIcon} from 'react-icons/hi'
import {HiDotsHorizontal as DotsHorizontalIcon} from 'react-icons/hi'
import {FaRegClipboard as ClipboardIcon} from 'react-icons/fa6'
import Link from './navigation/Link'
import {PUBLIC_SITE_URL} from '../config'
import {createSalePage, deleteSalePage} from '../api/salePage'
import useNavigate from '../hooks/useNavigate'
import {LoadingDialog} from './LoadingDialog'
import {SimpleDialog} from './Dialog'
import AlertDialog from './AlertDialog'
import MenuList from './MenuList'
import SalePageSwitchPublished from './SalePageSwitchPublished'
import useAuthStates from '../stores/useAuthStates'
import TabletUp from './layout/responsive/TabletUp'
import TabletDown from './layout/responsive/TabletDown'

const SalePageManager = ({
	data
}: {
	data: {
		salePage?: {
			_id: string
			published: boolean
			disablePublish: boolean
			sectionsCount: number
		}
		experience: {
			_id: string
			slug: string
			status: string
		}
	}
}) => {
	const {t} = useTranslation()
	const {profile} = useAuthStates()

	const [salePage, setSalePage] = useState<{
		_id: string
		published: boolean
		disablePublish: boolean
		sectionsCount: number
	} | null>(data.salePage || null)
	const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false)
	const [alertDialogMessage, setAlertDialogMessage] = useState<string>('')
	const [openLoadingDialog, setOpenLoadingDialog] = useState<boolean>(false)
	const [loadingDialogMessage, setLoadingDialogMessage] = useState<string>('')
	const [anchorElOptions, setAnchorElOptions] = useState<null | HTMLElement>(
		null
	)
	const [openDeleteSalePageDialog, setOpenDeleteSalePageDialog] =
		useState<boolean>(false)

	const [copyLinkTooltip, setCopyLinkTooltip] = useState<string>(
		t('copy-sale-page-link')
	)

	const openOptions = Boolean(anchorElOptions)
	const navigate = useNavigate()

	const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElOptions(event.currentTarget)
	}

	const handleCloseOptions = () => {
		setAnchorElOptions(null)
	}

	const {mutate: mutateCreateSalePage} = useMutation(createSalePage, {
		networkMode: 'always'
	})

	const handleCreateSalePage = () => {
		setOpenLoadingDialog(true)
		setLoadingDialogMessage(t('creating-sale-page'))
		mutateCreateSalePage(
			{
				experienceSlug: data.experience.slug
			},
			{
				onSuccess: async ({responseStatusCode, data}) => {
					if (responseStatusCode === 201 && data) {
						setSalePage({
							_id: data._id,
							published: false,
							disablePublish: true,
							sectionsCount: 0
						})
						navigate(`/products/${data.experience.slug}/edit-sale-page`)
					} else {
						setAlertDialogMessage(t('error-server-default'))
						setOpenAlertDialog(true)
					}
					setOpenLoadingDialog(false)
				},
				onError: error => {
					setAlertDialogMessage(t('error-server-default'))
					setOpenAlertDialog(true)
					setOpenLoadingDialog(false)
					console.log(t('error-server-default'))
					console.error(error)
				}
			}
		)
	}

	const handleOpenDeleteSalePageDialog = () => {
		setOpenDeleteSalePageDialog(true)
	}

	const {mutate: mutateDeleteSalePage} = useMutation(deleteSalePage, {
		networkMode: 'always'
	})

	const handleDeleteSalePage = () => {
		setOpenDeleteSalePageDialog(false)
		setOpenLoadingDialog(true)
		setLoadingDialogMessage(t('deleting-sale-page'))
		mutateDeleteSalePage(
			{
				experienceId: data.experience._id,
				salePageId: data.salePage?._id as string
			},
			{
				onSuccess: result => {
					if (result.responseStatusCode === 200) {
						setSalePage(null)
					} else {
						setAlertDialogMessage(t('error-server-default'))
						setOpenAlertDialog(true)
					}
					setOpenLoadingDialog(false)
				},
				onError: error => {
					setOpenLoadingDialog(false)
					setAlertDialogMessage(t('error-server-default'))
					setOpenAlertDialog(true)
					console.log(t('error-server-default'))
					console.error(error)
				}
			}
		)
	}

	const handleCopyLink = () => {
		navigator.clipboard
			.writeText(
				`${PUBLIC_SITE_URL}/${profile?.nameSlug}/${data.experience.slug}`
			)
			.then(() => {
				setCopyLinkTooltip(t('copied-link'))
			})
	}

	const handleChangePublish = () => {
		setSalePage(prev => {
			if (prev) {
				return {
					...prev,
					published: !prev.published
				}
			}

			return null
		})
	}

	const handleNavigateToEditSalePage = () => {
		navigate(`/products/${data.experience.slug}/edit-sale-page`)
	}

	const menuList = [
		{
			key: 'edit-sale-page',
			label: 'to-edit-sale-page',
			onClick: handleNavigateToEditSalePage
		},
		{
			key: 'copy-link',
			label: 'copy-link',
			onClick: handleCopyLink
		},
		{
			key: 'delete-sale-page',
			label: 'to-delete-sale-page',
			onClick: handleOpenDeleteSalePageDialog
		}
	].filter(
		item => item.key !== 'copy-link' || Boolean(data?.salePage?.published)
	)

	return (
		<Box
			sx={theme => ({
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				columnGap: 5,
				rowGap: 1,
				backgroundColor: data.salePage
					? theme.palette.mode === 'light'
						? theme.palette.grey[100]
						: theme.palette.grey[50]
					: 'transparent',
				borderBottom: data.salePage
					? 'none'
					: `1px solid ${theme.palette.divider}`,
				pr: data.salePage ? 2 : 0,
				pl: 2,
				pt: 1.5,
				[theme.breakpoints.down('sm')]: {
					flexDirection: 'column',
					alignItems: 'flex-start'
				},
				[theme.breakpoints.down('sm')]: {
					pl: data.salePage ? 2 : 0
				}
			})}
		>
			<Box
				sx={{
					display: 'inline-flex',
					gap: 1,
					alignItems: 'center'
				}}
			>
				<Typography variant="subtitle1" fontWeight={500} mt={0.5}>
					{t('sales-page')}
				</Typography>
				<TabletDown>
					<Tooltip title={t('options')} placement="bottom" arrow>
						<IconButton
							onClick={handleOpenOptions}
							size="small"
							aria-controls={openOptions ? t('options') : undefined}
							aria-haspopup="true"
							aria-expanded={openOptions ? 'true' : undefined}
							sx={{mt: '4px'}}
						>
							<DotsHorizontalIcon color="inherit" />
						</IconButton>
					</Tooltip>
				</TabletDown>
			</Box>

			{!data.experience ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						minHeight: 48.5,
						pb: 1.5,
						flex: 1
					}}
				>
					<Skeleton
						width="80%"
						sx={{
							maxWidth: 300,
							height: 20
						}}
					/>
				</Box>
			) : (
				<Box
					sx={theme => ({
						display: 'grid',
						minHeight: 48.5,
						[theme.breakpoints.down('sm')]: {
							width: '100%'
						}
					})}
				>
					<LoadingDialog
						open={openLoadingDialog}
						message={loadingDialogMessage}
					/>
					<AlertDialog
						open={openAlertDialog}
						message={alertDialogMessage}
						onClose={() => setOpenAlertDialog(false)}
					/>
					<SimpleDialog
						open={openDeleteSalePageDialog}
						onClose={() => setOpenDeleteSalePageDialog(false)}
					>
						<Typography variant="body1">
							{t('delete-sale-page-dialog-message')}
						</Typography>
						<Box sx={style.simpleDialogActionsBox}>
							<Button
								variant="outlined"
								onClick={() => setOpenDeleteSalePageDialog(false)}
							>
								{t('cancel')}
							</Button>
							<Button
								variant="contained"
								onClick={handleDeleteSalePage}
								sx={{ml: 1}}
							>
								{t('to-delete')}
							</Button>
						</Box>
					</SimpleDialog>
					<MenuList
						open={openOptions}
						anchorEl={anchorElOptions}
						onClose={handleCloseOptions}
						list={menuList}
					/>
					{data.salePage ? (
						<Box
							className="hidden-scrollbar"
							sx={{
								alignItems: 'center',
								display: 'flex',
								rowGap: 2,
								columnGap: 5,
								overflowX: 'auto',
								maxWidth: '100%',
								pb: 1.5,
								flex: 1,
								scrollSnapType: 'x mandatory'
							}}
						>
							<Button
								variant="outlined"
								onClick={handleNavigateToEditSalePage}
								sx={{minWidth: 82}}
							>
								{t('to-edit')}
							</Button>
							{salePage && (
								<SalePageSwitchPublished
									data={{
										...data,
										salePage
									}}
									onChangePublish={handleChangePublish}
								/>
							)}
							{data.salePage.published && (
								<>
									<Box>
										<Link
											to={`${PUBLIC_SITE_URL}/${profile?.nameSlug}/${data.experience.slug}`}
											externalLink
											target="_blank"
											sx={[
												style.link,
												{
													alignItems: 'center',
													display: 'flex',
													gap: 0.5,
													width: 'max-content'
												}
											]}
										>
											<Tooltip title={t('visit-page')} placement="bottom" arrow>
												<Typography
													variant="body1"
													sx={{
														alignItems: 'center',
														cursor: 'pointer',
														display: 'flex',
														gap: 0.3
													}}
												>
													<ExternalLinkIcon fontSize={20} />
													{t('visit-page')}
												</Typography>
											</Tooltip>
										</Link>
									</Box>
									<Box>
										<Tooltip
											title={copyLinkTooltip}
											placement="bottom"
											arrow
											onClose={() =>
												setCopyLinkTooltip(t('copy-sale-page-link'))
											}
										>
											<Typography
												variant="body1"
												sx={{
													alignItems: 'center',
													cursor: 'pointer',
													display: 'flex',
													gap: 0.5,
													width: 'max-content'
												}}
												onClick={handleCopyLink}
											>
												<ClipboardIcon fontSize={17} />
												<span>{t('copy-link')}</span>
											</Typography>
										</Tooltip>
									</Box>
								</>
							)}
							<TabletUp>
								<Tooltip title={t('options')} placement="bottom" arrow>
									<IconButton
										onClick={handleOpenOptions}
										size="small"
										aria-controls={openOptions ? t('options') : undefined}
										aria-haspopup="true"
										aria-expanded={openOptions ? 'true' : undefined}
									>
										<DotsHorizontalIcon color="inherit" />
									</IconButton>
								</Tooltip>
							</TabletUp>
						</Box>
					) : (
						<Box
							sx={theme => ({
								alignItems: 'center',
								display: 'flex',
								gap: 2,
								pb: 1.5,
								flexWrap: 'wrap',
								[theme.breakpoints.down('md')]: {
									flexDirection: 'column',
									alignItems: 'flex-start'
								}
							})}
						>
							<Typography variant="body2" color="text.secondary">
								{t('create-sale-page-tip')}
							</Typography>
							<Button
								variant="outlined"
								onClick={handleCreateSalePage}
								sx={theme => ({
									width: 212,
									[theme.breakpoints.down('sm')]: {width: '100%'}
								})}
							>
								{t('create-sale-page')}
							</Button>
						</Box>
					)}
				</Box>
			)}
		</Box>
	)
}

const style = {
	link: (theme: Theme) => ({
		color: theme.palette.text.primary,
		'&:hover': {
			color: theme.palette.primary.main
		}
	}),
	simpleDialogActionsBox: (theme: Theme) => ({
		alignSelf: 'end',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		justifyContent: 'end',
		mt: 2
	})
}

export default SalePageManager
