import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Dialog} from '../../Dialog'
import {CustomerPurchase} from '../../../entities/Customer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {regex} from '../../../helpers/regex'
import {useQuery} from '@tanstack/react-query'
import {listCustomerPurchases} from '../../../api/customers'
import AlertMessage from '../../AlertMessage'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Icon from '@mui/material/Icon'
import {FaImage as ImageIcon} from 'react-icons/fa'
import {HiOutlineDotsHorizontal as DotsIcon} from 'react-icons/hi'

import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import {date} from '../../../helpers/date'
import useAppStates from '../../../stores/useAppStates'
import {Status} from '../../Status'
import Skeleton from '@mui/material/Skeleton'
import Tag from '../../typography/Tag'
import {Customer} from '../../../stores/useCustomersStates'
import SaleDetailsDialog from '../sales/DetailsDialog'
import MenuList from '../../MenuList'

interface CustomerDetailsModalProps {
	open: boolean
	customer: Customer
	onClose: () => void
}

export default function CustomerDetailsDialog({
	customer,
	open,
	onClose
}: CustomerDetailsModalProps) {
	const {t} = useTranslation()
	const {currentLang} = useAppStates(state => state)

	const [openAlertError, setOpenAlertError] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>('')
	const [purchases, setPurchases] = useState<CustomerPurchase[]>([])
	const [saleId, setSaleId] = useState<string | null>(null)
	const [openSaleDetails, setOpenSaleDetails] = useState<boolean>(false)
	const [anchorElActionList, setAnchorElActionList] =
		useState<null | HTMLElement>(null)
	const openActionList = Boolean(anchorElActionList)

	const getDocument = useCallback(() => {
		const document = customer.document as string

		switch (customer.documentType) {
			case 'cpf':
				return regex.formatCPF(document)
			case 'cnpj':
				return regex.formatCNPJ(document)
			default:
				return customer.document
		}
	}, [customer])

	const {isFetching} = useQuery(
		['customer-details'],
		() => listCustomerPurchases({email: customer.email}),
		{
			networkMode: 'always',
			refetchOnWindowFocus: false,
			enabled: open,
			onSuccess: ({responseStatusCode, data, error}) => {
				if (responseStatusCode === 200 && data) {
					setPurchases(data.purchases)
				} else if (error) {
					setErrorMessage(error.message)
					setOpenAlertError(true)
				} else {
					setErrorMessage(t('error-server-default'))
					setOpenAlertError(true)
				}
			},
			onError: error => {
				console.log(error)
				setErrorMessage(t('error-server-default'))
				setOpenAlertError(true)
			}
		}
	)

	return (
		<>
			<MenuList
				anchorEl={anchorElActionList}
				open={openActionList}
				onClose={() => setAnchorElActionList(null)}
				list={[
					{
						key: 'view-details',
						label: t('view-details'),
						onClick: () => setOpenSaleDetails(true)
					}
				]}
			/>
			{saleId ? (
				<SaleDetailsDialog
					id={saleId}
					open={openSaleDetails}
					onClose={() => setOpenSaleDetails(false)}
				/>
			) : null}
			<Dialog.Root open={open}>
				<Dialog.Header>
					<Dialog.Title onClose={onClose}>{t('customer-details')}</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width={600}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							rowGap: 3
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								columnGap: 4,
								rowGap: 2,
								mt: 0.5
							}}
						>
							<Box>
								<Typography
									variant="subtitle1"
									sx={{
										fontWeight: 500
									}}
								>
									{customer.name}
								</Typography>
								<Typography variant="body2">{customer.email}</Typography>
							</Box>
							{customer.phone && (
								<Box>
									<Typography
										variant="subtitle1"
										sx={{
											fontWeight: 500
										}}
									>
										{t('phone')}
									</Typography>
									<Typography variant="body2">{customer.phone}</Typography>
								</Box>
							)}
							{customer.type && (
								<Box>
									<Typography
										variant="subtitle1"
										sx={{
											fontWeight: 500
										}}
									>
										{t('customer-type')}
									</Typography>
									<Typography variant="body2">
										{t(
											customer.type === 'individual'
												? 'natural-person'
												: 'legal-person'
										)}
									</Typography>
								</Box>
							)}
							{customer.documentType && (
								<Box>
									<Typography
										variant="subtitle1"
										sx={{
											fontWeight: 500
										}}
									>
										{t(customer.documentType)}
									</Typography>
									<Typography variant="body2">{getDocument()}</Typography>
								</Box>
							)}
							<Box>
								<Typography
									variant="subtitle1"
									sx={{
										fontWeight: 500
									}}
								>
									{t('newsletter')}
								</Typography>
								<Typography variant="body2">
									{customer.newsletter ? t('subscribed') : t('not-subscribed')}
								</Typography>
							</Box>
						</Box>
						<Box
							sx={theme => ({
								display: 'grid',
								borderWidth: 1,
								borderStyle: 'solid',
								borderColor: theme.palette.divider,
								borderRadius: '4px'
							})}
						>
							<AlertMessage
								open={openAlertError}
								onClose={() => setOpenAlertError(false)}
								severity="error"
								message={errorMessage}
							/>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell
												colSpan={2}
												sx={{
													borderBottom: 0
												}}
											>
												{t('purchases')}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody
										sx={{
											'&>div>tr>td': {
												borderTopWidth: '1px',
												borderTopStyle: 'solid',
												borderTopColor: theme => theme.palette.divider,
												borderBottomWidth: 0
											}
										}}
									>
										{isFetching ? (
											<Box>
												<PurchaseSkeletonRow />
												<PurchaseSkeletonRow />
											</Box>
										) : (
											purchases.map(({product, items, ...rest}) => (
												<Box key={rest.id}>
													<TableRow>
														<TableCell
															sx={{
																width: '100%'
															}}
														>
															<Box
																sx={{
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	flexWrap: 'wrap',
																	gap: 1
																}}
															>
																{product.cover ? (
																	<img
																		src={product.cover}
																		alt={product.title}
																		width="66.7px"
																		height="50px"
																		style={{
																			borderRadius: 4
																		}}
																	/>
																) : (
																	<Box
																		sx={theme => ({
																			display: 'flex',
																			flexDirection: 'column',
																			alignItems: 'center',
																			justifyContent: 'center',
																			width: '66.7px',
																			height: '50px',
																			backgroundColor:
																				theme.palette.mode === 'light'
																					? 'grey.100'
																					: 'grey.50',
																			borderRadius: '4px',
																			border:
																				'1px solid ' + theme.palette.divider,
																			boxSizing: 'border-box'
																		})}
																	>
																		<Icon
																			sx={theme => ({
																				color: theme.palette.grey[400],
																				width: '100%',
																				height: '100%'
																			})}
																		>
																			<ImageIcon
																				style={{
																					width: '100%',
																					height: '100%'
																				}}
																			/>
																		</Icon>
																	</Box>
																)}
																<Box
																	sx={{
																		flex: 1,
																		display: 'flex',
																		flexDirection: 'column',
																		gap: 0.5
																	}}
																>
																	<Box>
																		<Typography
																			variant="subtitle2"
																			sx={{
																				flex: 1
																			}}
																		>
																			{product.title}
																			{rest.totalItems > 1 && (
																				<Tag
																					sx={{
																						display: 'inline',
																						ml: '4px',
																						borderRadius: '8px',
																						py: 0.25
																					}}
																				>
																					{rest.totalItems === 2
																						? t('total-items-unique', {
																								total: rest.totalItems - 1
																						  })
																						: t('total-items', {
																								total: rest.totalItems - 1
																						  })}
																				</Tag>
																			)}
																		</Typography>
																	</Box>
																	<Box
																		sx={{
																			display: 'flex',
																			flexDirection: 'row',
																			alignItems: 'center',
																			gap: 1,
																			mb: 0.5
																		}}
																	>
																		<Typography variant="caption">
																			{date.convertISOToLocation(
																				rest.date,
																				currentLang
																			)}
																		</Typography>
																		<Status.Tag type={rest.status} size="small">
																			{t('status-tag-' + rest.status)}
																		</Status.Tag>
																	</Box>
																</Box>
															</Box>
														</TableCell>
														<TableCell align="right">
															<Tooltip
																title={t('view-actions')}
																placement="bottom"
																arrow
															>
																<IconButton
																	onClick={(
																		event: React.MouseEvent<HTMLElement>
																	) => {
																		setSaleId(rest.id)
																		setAnchorElActionList(event.currentTarget)
																	}}
																	size="small"
																	aria-controls={
																		openActionList ? t('actions') : undefined
																	}
																	aria-haspopup="true"
																	aria-expanded={
																		openActionList ? 'true' : undefined
																	}
																>
																	<DotsIcon />
																</IconButton>
															</Tooltip>
														</TableCell>
													</TableRow>
												</Box>
											))
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Box>
				</Dialog.Body>
			</Dialog.Root>
		</>
	)
}

const PurchaseSkeletonRow = () => (
	<TableRow>
		<TableCell sx={{width: '100%'}}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 1
				}}
			>
				<Skeleton variant="rounded" width="66.7px" height="50px" />
				<Box sx={{flex: 1}}>
					<Typography
						variant="subtitle2"
						sx={{
							flex: 1
						}}
					>
						<Skeleton variant="text" width="50%" />
					</Typography>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: 1,
							mb: 0.5
						}}
					>
						<Typography variant="caption">
							<Skeleton variant="text" width="66px" />
						</Typography>
						<Skeleton
							variant="rounded"
							width="50px"
							height="16px"
							sx={{
								borderRadius: '8px'
							}}
						/>
					</Box>
				</Box>
			</Box>
		</TableCell>
		<TableCell align="right">
			<IconButton size="small">
				<DotsIcon></DotsIcon>
			</IconButton>
		</TableCell>
	</TableRow>
)
