import Providers from './providers/Providers'
import Router from './routes/Router'
import Initialization from './Initialization'

import './global.css'

function App() {
	return (
		<Providers>
			<Initialization>
				<Router />
			</Initialization>
		</Providers>
	)
}

export default App
