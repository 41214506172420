import {useCallback, useEffect, useState} from 'react'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Slider,
	Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import Cropper, {Area} from 'react-easy-crop'
import {Box} from '@mui/system'
import {MdOutlineCrop as CropIcon} from 'react-icons/md'
import {CgClose as CloseIcon} from 'react-icons/cg'
import {createImage, getCroppedImg} from './utils/cropImage'
import {useTranslation} from 'react-i18next'

interface CropEasyProps {
	mode?: string
	circle?: boolean
	openCrop: boolean
	imageCrop: {
		src: string
		file: File
		field: string
	} | null
	handleCloseCrop: () => void
	handleChangeImageValue: (file: File) => void
	handleChangeImagePreview: (file: File) => void
}

const CropEasy = ({
	mode,
	circle,
	imageCrop,
	openCrop,
	handleCloseCrop,
	handleChangeImageValue,
	handleChangeImagePreview
}: CropEasyProps) => {
	const [zoom, setZoom] = useState(1)
	const [crop, setCrop] = useState({x: 0, y: 0})
	const [aspectRatio, setAspectRatio] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)

	const {t} = useTranslation()

	const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels) // croppedAreaPixels
	}

	const handleCropImage = async () => {
		try {
			const croppedImage = await getCroppedImg({
				imageSrc: imageCrop?.src as string,
				imageType: imageCrop?.file.type as string,
				pixelCrop: croppedAreaPixels as Area
			})

			const file = new File([croppedImage], croppedImage.name, {
				type: croppedImage.type,
				lastModified: Date.now()
			})

			handleChangeImagePreview(file)
			handleChangeImageValue(file)
			handleCloseCrop()
		} catch (error) {
			console.log(error)
		}
	}

	const getDimensions = useCallback(async () => {
		const imageURL = imageCrop?.src as string
		const imageElement = await createImage(imageURL)

		setAspectRatio(imageElement.naturalWidth / imageElement.naturalHeight)
	}, [imageCrop?.src])

	useEffect(() => {
		getDimensions()
	}, [getDimensions])

	const getBackgroundColor = () => {
		if (mode === 'dark') {
			return '#212121'
		}

		if (mode === 'light') {
			return '#fff'
		}

		return 'initial'
	}

	return (
		<Dialog open={openCrop} onClose={handleCloseCrop} sx={styles.Dialog}>
			<DialogTitle sx={styles.DialogTitle}>
				<Typography variant="body1">{t('crop-your-image')}</Typography>
				<IconButton
					sx={styles.closeIcon}
					size="small"
					onClick={handleCloseCrop}
					aria-label={t('aria-close-modal')}
				>
					<CloseIcon fontSize="inherit" />
				</IconButton>
			</DialogTitle>
			<DialogContent
				dividers
				sx={{
					backgroundColor: '#333',
					position: 'relative',
					height: 400,
					width: 'auto',
					minWidth: {sm: 500}
				}}
			>
				<Cropper
					image={imageCrop?.src}
					crop={crop}
					zoom={zoom}
					style={{
						containerStyle: {
							backgroundColor: getBackgroundColor(),
							width: '100%'
						}
					}}
					aspect={circle ? 1 : aspectRatio}
					onZoomChange={setZoom}
					onCropChange={setCrop}
					onCropComplete={handleCropComplete}
					cropShape={circle ? 'round' : 'rect'}
				/>
			</DialogContent>
			<DialogActions
				sx={{display: 'flex', flexDirection: 'column', mx: 3, my: 2}}
			>
				<Box sx={{width: '100%', mb: 1}}>
					<Box>
						<Typography variant="body2" sx={{mb: 1}}>
							Zoom: {zoomPercent(zoom)}
						</Typography>
						<Slider
							valueLabelDisplay="auto"
							valueLabelFormat={zoomPercent}
							min={1}
							max={3}
							step={0.1}
							value={zoom}
							onChange={(_, zoom) => setZoom(zoom as number)}
						/>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						gap: 2,
						flexWrap: 'wrap',
						alignSelf: 'end',
						justifyContent: 'end'
					}}
				>
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCloseCrop}
					>
						{t('cancel')}
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleCropImage}
						startIcon={<CropIcon />}
					>
						{t('crop')}
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	)
}

const styles = {
	Dialog: (theme: Theme) => ({
		'& .MuiDialog-paper': {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				margin: 1
			}
		}
	}),
	DialogTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 2
	},
	closeIcon: (theme: Theme) => ({
		color: theme.palette.grey[500],
		padding: 0,
		'&:hover': {
			cursor: 'pointer',
			color: theme.palette.grey[600]
		}
	})
}

const zoomPercent = (zoom: number) => Math.round(zoom * 100) + '%'

export default CropEasy
