import {useEffect, useState} from 'react'
import Card from '@mui/material/Card'
import {useTranslation} from 'react-i18next'
import {Status} from '../../../../components/Status'
import {Box, Button, Divider, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import useNavigate from '../../../../hooks/useNavigate'
import {PagarmeFeesDialog} from './PagarmeFeesCard'
import usePaymentServicePagarmeStates from '../../../../stores/usePaymentServicePagarmeStates'
import {TransferHistoryCard} from './TransferHistoryCard'
import {TransferActionCard} from './TransferActionCard'
import {PagarmeLogo} from '../../../../components/logos/PagarmeLogo'
import {MdSettings as SettingsIcon} from 'react-icons/md'
import InfoPopover from '../../../../components/InfoPopover'
import useAuthStates from '../../../../stores/useAuthStates'

export const PagarmeCard = () => {
	const [openFeesDialog, setOpenFeesDialog] = useState(false)
	const {t} = useTranslation()
	const navigate = useNavigate()

	const {paymentService} = useAuthStates()

	const {
		status,
		setStatus,
		bankAccount,
		recipientStatusHistory,
		companyAnnualRevenue,
		companyLegalRepresentative,
		transferSettings
	} = usePaymentServicePagarmeStates(state => state)

	const lastRecipientStatus =
		recipientStatusHistory.length > 0
			? (recipientStatusHistory[recipientStatusHistory.length - 1]
					?.status as string)
			: 'pending-setup'

	const handleNavigateToSettings = () => {
		navigate(`/payment-services/pagarme/`)
	}

	useEffect(() => {
		if (
			bankAccount &&
			companyAnnualRevenue &&
			companyLegalRepresentative &&
			transferSettings &&
			status === 'pending-setup'
		) {
			setStatus('pending-activation')
		}
		// eslint-disable-next-line
	}, [
		setStatus,
		bankAccount,
		companyAnnualRevenue,
		companyLegalRepresentative,
		transferSettings
	])

	return (
		<>
			<PagarmeFeesDialog
				open={openFeesDialog}
				onClose={() => setOpenFeesDialog(false)}
			/>
			<Card variant="outlined">
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							p: 3,
							gap: 3
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 2
							}}
						>
							<PagarmeLogo />
							{status &&
								status !== 'active' &&
								status !== 'pending-activation' &&
								paymentService?.activeService !== 'pagarme' && (
									<Status.Tag type={status}>
										<Status.Label
											label={t('status-tag-' + status)}
											maxWidth="auto"
											showTooltip={false}
										/>
									</Status.Tag>
								)}

							{paymentService?.activeService === 'pagarme' && (
								<Status.Tag type="active">
									<Status.Label
										label={t('active-on-checkout')}
										maxWidth="auto"
										showTooltip={false}
									/>
								</Status.Tag>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								gap: 2,
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<Button
								variant="outlined"
								onClick={() => setOpenFeesDialog(true)}
							>
								{t('fees-and-terms')}
							</Button>
							<Button
								variant="contained"
								onClick={handleNavigateToSettings}
								startIcon={
									status === 'pending-activation' ? undefined : <SettingsIcon />
								}
								sx={{width: '148px'}}
							>
								{status === 'pending-activation'
									? t('to-activate-service')
									: t('to-configure')}
							</Button>
						</Box>
					</Box>
					{(status === 'active' || status === 'deactivated') && (
						<>
							<Divider />
							<Box
								sx={{
									display: 'flex',
									alignItems: 'flex-start',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									p: 3,
									gap: 4
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'stretch',
										gap: 4,
										flexWrap: 'wrap'
									}}
								>
									<TransferActionCard />
									<TransferHistoryCard />
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 4,
										flexWrap: 'wrap'
									}}
								>
									<Box>
										<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
											<Typography
												variant="subtitle2"
												sx={styles.detailsSubtitle}
											>
												{t('ps-account-status')}
											</Typography>
											<InfoPopover
												sx={{
													'>button': {
														justifyContent: 'flex-start',
														p: 0,
														mt: '-8px'
													}
												}}
												transformOrigin={{
													vertical: 'top',
													horizontal: 'left'
												}}
												anchorOrigin={{
													vertical: 24,
													horizontal: 'left'
												}}
											>
												{t('info-recipient')}
											</InfoPopover>
										</Box>
										<Typography
											variant="body2"
											sx={[
												styles.detailsText,
												{
													fontWeight: 500,
													color: () => {
														if (lastRecipientStatus === 'approved') {
															return 'success.main'
														}

														if (lastRecipientStatus === 'rejected') {
															return 'error.main'
														}

														return '#b99905'
													}
												}
											]}
										>
											{t('status-tag-' + lastRecipientStatus)}
										</Typography>
									</Box>
								</Box>
							</Box>
						</>
					)}
				</Box>
			</Card>
		</>
	)
}

const styles = {
	detailsSubtitle: (theme: Theme) => ({
		mb: 0.5
	}),
	detailsText: (theme: Theme) => ({
		color: theme.palette.grey[600]
	})
}
