import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

const useCodeSchema = () => {
	const {t} = useTranslation()
	const codeSchema = yup.object({
		code: yup
			.string()
			.matches(/^[0-9]*$/, t('error-code-invalid'))
			.min(6, t('error-code-min-length'))
			.max(6, t('error-code-max-length'))
			.required(t('error-code-required'))
	})
	return codeSchema
}

export default useCodeSchema
