import dayjs from 'dayjs'
import {DEFAULT_LANG} from '../config'

export const date = {
	convertISOToLocation: (
		date: string,
		currentLang: string,
		withHours?: boolean
	) => {
		const language = currentLang || DEFAULT_LANG

		return (
			new Date(date).toLocaleDateString(language) +
			(withHours
				? `, ${new Date(date).toLocaleTimeString(language, {
						hour: '2-digit',
						minute: '2-digit'
				  })}`
				: '')
		)
	},
	getTime: (date: string, currentLang: string) => {
		const language = currentLang || DEFAULT_LANG

		return new Date(date).toLocaleTimeString(language, {
			hour: '2-digit',
			minute: '2-digit'
		})
	},
	isValid: (date: string) => {
		return new Date(date).toString() !== 'Invalid Date'
	},
	isFuture: (date: string) => {
		return new Date(date) > new Date(Date.now() - 1000 * 60 * 60 * 24)
	},
	abbreviateFormat: (date: string, currentLang: string) => {
		try {
			const data = dayjs(date, {locale: currentLang || DEFAULT_LANG})

			const weekDay = data.format('ddd')
			const day = data.format('DD')
			const month = data.format('MMM')

			switch (currentLang) {
				case 'en':
					return `${weekDay}, ${month} ${day}`
				default:
					return `${weekDay}., ${day} de ${month}.`
			}
		} catch (error) {
			const data = new Date(date)

			switch (currentLang) {
				case 'en': {
					const weekDay = data.toLocaleDateString('en', {
						weekday: 'short'
					})
					const day = data.toLocaleDateString('en', {
						day: '2-digit'
					})
					const month = data.toLocaleDateString('en', {
						month: 'short'
					})
					return `${weekDay.toLowerCase()}, ${month.toLowerCase()} ${day}`
				}
				default: {
					const weekDay = data.toLocaleDateString('pt-BR', {
						weekday: 'short'
					})
					const day = data.toLocaleDateString('pt-BR', {
						day: '2-digit'
					})
					const month = data.toLocaleDateString('pt-BR', {
						month: 'long'
					})
					return `${weekDay}, ${day} de ${month}`
				}
			}
		}
	},
	sortAsc: (a: string, b: string) => {
		return new Date(a) > new Date(b) ? 1 : -1
	},
	getWeekday: (weekDay: number, currentLang: string) => {
		const date = new Date()
		date.setDate(weekDay)

		let dayOfWeek = date.toLocaleDateString(currentLang, {
			weekday: 'long'
		})

		dayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.substring(1)

		return dayOfWeek
	}
}
