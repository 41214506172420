import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

const usePasswordResetSchema = () => {
	const {t} = useTranslation()
	const passwordResetSchema = yup.object({
		newPassword: yup
			.string()
			.matches(
				/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{1,}$/,
				t('error-password-invalid')
			)
			.min(7, t('error-password-min-length'))
			.max(500, t('error-password-max-length'))
			.required(t('error-password-required')),
		confirmPassword: yup
			.string()
			.required(t('error-confirm-password-required'))
			.oneOf([yup.ref('newPassword'), null], t('error-passwords-not-match'))
	})

	return passwordResetSchema
}

export default usePasswordResetSchema
