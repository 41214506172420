export const validateCPF = (cpf?: string) => {
	if (!cpf) {
		return false
	}

	let multiplicador1 = [10, 9, 8, 7, 6, 5, 4, 3, 2]
	let multiplicador2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2]
	let tempCpf = cpf.slice(0, 9)
	let soma = 0

	for (let i = 0; i < 9; i++) {
		soma += parseInt(tempCpf[i]) * multiplicador1[i]
	}

	let resto = soma % 11
	resto = resto < 2 ? 0 : 11 - resto

	let digito = resto.toString()
	tempCpf += digito
	soma = 0

	for (let i = 0; i < 10; i++) {
		soma += parseInt(tempCpf[i]) * multiplicador2[i]
	}

	resto = soma % 11
	resto = resto < 2 ? 0 : 11 - resto
	digito += resto

	return cpf.endsWith(digito)
}
