import {useTranslation} from 'react-i18next'

import {
	Alert,
	Dialog,
	DialogContent,
	DialogContentText,
	IconButton
} from '@mui/material'
import {Theme} from '@mui/material/styles'

import {CgClose as CloseIcon} from 'react-icons/cg'

interface MuiAlertProps {
	severity: 'error' | 'warning' | 'info' | 'success'
	message: string
}

const MuiAlert = ({severity, message}: MuiAlertProps) => {
	return <Alert severity={severity}>{message}</Alert>
}

interface AlertDialogProps {
	open: boolean
	message: string
	severity?: 'error' | 'warning' | 'info' | 'success'
	onClose: () => void
}

const AlertDialog = ({open, message, onClose, severity}: AlertDialogProps) => {
	const {t} = useTranslation()

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent
				sx={theme => ({
					...styles.dialogContent
				})}
			>
				<DialogContentText>
					{severity ? (
						<MuiAlert severity={severity} message={message} />
					) : (
						message
					)}
				</DialogContentText>
				<IconButton
					sx={styles.closeIcon}
					size="small"
					onClick={onClose}
					aria-label={t('aria-close-modal')}
				>
					<CloseIcon fontSize="inherit" />
				</IconButton>
			</DialogContent>
		</Dialog>
	)
}

const styles = {
	dialogContent: {
		display: 'flex',
		gap: 1,
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		padding: 2,
		maxWidth: 400
	},
	closeIcon: (theme: Theme) => ({
		color: theme.palette.grey[500],
		padding: 0,
		'&:hover': {
			cursor: 'pointer',
			color: theme.palette.grey[600]
		}
	})
}

export default AlertDialog
