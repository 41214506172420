import {DEFAULT_LANG, SELLER_API} from '../config'
import {CustomerPurchase} from '../entities/Customer'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'
import {RawCustomer} from './entities/RawCustomer'

interface Message {
	message: string
}

interface ListCustomersResponse {
	success?: {
		message: string
	}
	error?: Message
	data?: {
		customers: RawCustomer[]
	}
	responseStatusCode?: number
}

export const listCustomers = async ({
	offset,
	limit,
	filters
}: {
	offset: number
	limit: number
	filters?: string
}): Promise<ListCustomersResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_API}/converted-customers/?offset=${offset}&limit=${limit}${
			filters ? `&${filters}` : ''
		}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ListCustomerPurchasesResponse {
	success?: {
		message: string
	}
	error?: Message
	data?: {
		purchases: CustomerPurchase[]
	}
	responseStatusCode?: number
}

export const listCustomerPurchases = async ({
	email
}: {
	email: string
}): Promise<ListCustomerPurchasesResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_API}/converted-customers/purchases?email=${email}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
