import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {useTranslation} from 'react-i18next'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import {useCallback, useEffect, useMemo, useState} from 'react'
import Icon from '@mui/material/Icon'
import {FcMoneyTransfer as RefundIcon} from 'react-icons/fc'
import parseToHTML from 'html-react-parser'
import {useMutation} from '@tanstack/react-query'
import {Alert, CircularProgress, Skeleton} from '@mui/material'
import {MdOutlineRefresh as RefreshIcon} from 'react-icons/md'
import {Dialog} from '../../../components/Dialog'
import useAsaasStates from '../../../stores/useAsaasStates'
import {getBalanceAsaas} from '../../../api/payment-service/asaas'
import {FormTransfer} from './FormTransfer'
import ButtonIcon from '../../../components/ButtonIcon'
import {price} from '../../../helpers/price'
import {PS_EXCOFY_RULES} from '../../../config'

const ProcessingTransferDialog = ({
	transferType,
	open,
	onClose
}: {
	transferType: 'PIX' | 'TED'
	open: boolean
	onClose: () => void
}) => {
	const {t} = useTranslation()

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>
					{t('transfer-in-progress')}
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<Box display="flex" gap={2} alignItems="center">
					<Icon
						sx={{
							fontSize: '6rem'
						}}
					>
						<RefundIcon style={{marginBottom: 4}} />
					</Icon>
					<Typography flex={1}>
						{parseToHTML(
							transferType === 'TED'
								? t('ps-asaas-transfer-in-progress-info-ted')
								: t('ps-asaas-transfer-in-progress-info-pix')
						)}
					</Typography>
				</Box>
			</Dialog.Body>
		</Dialog.Root>
	)
}

export const TransferActionCard = ({
	borderWidth = 1,
	padding = 1.75
}: {
	padding?: number
	borderWidth?: number
}) => {
	const {t} = useTranslation()
	const {asaasBalance, setAsaasBalance, asaasBankAccount} = useAsaasStates()
	const [openTransferDialog, setOpenTransferDialog] = useState(false)
	const [openProcessingTransferDialog, setOpenProcessingTransferDialog] =
		useState(false)
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')
	const [firstLoad, setFirstLoad] = useState(true)
	const [transferType, setTransferType] = useState<'PIX' | 'TED'>('PIX')

	const handleSuccessTransfer = () => {
		setOpenTransferDialog(false)
		setOpenProcessingTransferDialog(true)
	}

	const {mutate, isLoading} = useMutation(getBalanceAsaas, {
		networkMode: 'always'
	})

	const fetchGetBalance = useCallback(async () => {
		setOpenAlert(false)
		mutate(undefined, {
			onSuccess: ({data, responseStatusCode, error}) => {
				if (responseStatusCode === 200 && data) {
					setAsaasBalance({
						availableBalance: data.balance,
						receivableBalance: data.receivableBalance
					})
				} else if (responseStatusCode !== 200) {
					setOpenAlert(true)
					setAlertErrorMessage(error?.message || t('error-server-default'))
				}
			},
			onError: error => {
				setOpenAlert(true)
				setAlertErrorMessage(t('error-server-default'))
				console.error(error)
			}
		})
	}, [mutate, setAsaasBalance, t, setOpenAlert, setAlertErrorMessage])

	const disabledTransferButton = useMemo(() => {
		if (
			!asaasBankAccount ||
			!asaasBalance ||
			asaasBalance.availableBalance <
				PS_EXCOFY_RULES.minimumValueToTransfer.asaas ||
			isLoading
		) {
			return true
		} else {
			return false
		}
	}, [asaasBalance, asaasBankAccount, isLoading])

	useEffect(() => {
		if (firstLoad) {
			fetchGetBalance()
			setFirstLoad(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstLoad])

	return (
		<>
			<ProcessingTransferDialog
				transferType={transferType}
				open={openProcessingTransferDialog}
				onClose={() => setOpenProcessingTransferDialog(false)}
			/>
			<FormTransfer
				open={openTransferDialog}
				onClose={() => setOpenTransferDialog(false)}
				onSuccess={handleSuccessTransfer}
				setTransferType={setTransferType}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					padding,
					width: 'max-content',
					gap: 1,
					borderWidth,
					borderColor: 'divider',
					borderRadius: 1,
					borderStyle: 'solid'
				}}
			>
				<Typography variant="subtitle2">{t('balance')}</Typography>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
						flex: 1,
						justifyContent: 'space-between',
						maxWidth: 300
					}}
				>
					<Box>
						<Typography
							variant="h5"
							color="success.main"
							width="fit-content"
							sx={{
								whiteSpace: 'nowrap',
								color: 'success.main',
								fontWeight: 500,
								display: 'flex',
								alignItems: 'center'
							}}
						>
							{isLoading && <Skeleton width={120} sx={{mr: 1}} />}
							{!isLoading &&
								!openAlert &&
								price.full(asaasBalance.availableBalance)}
						</Typography>
						<Typography variant="body2" sx={{mt: 1, color: 'grey.600'}}>
							{t('receivable-balance')}{' '}
							<b>{price.full(asaasBalance.receivableBalance)}</b>
						</Typography>
						{(isLoading || openAlert) && (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								{openAlert &&
									process.env.REACT_APP_ENVIRONMENT === 'production' && (
										<Alert
											severity="error"
											sx={{
												py: 0,
												px: 1,
												mr: 1,
												lineHeight: '1rem',
												minWidth: '184px',
												'& .MuiAlert-icon': {
													alignItems: 'center',
													fontSize: '1rem',
													p: 0
												}
											}}
										>
											{alertErrorMessage}
										</Alert>
									)}
								{openAlert && (
									<ButtonIcon
										icon={
											isLoading ? (
												<CircularProgress size={16} />
											) : (
												<RefreshIcon />
											)
										}
										title={t('to-try-again')}
										disabled={isLoading}
										onClick={fetchGetBalance}
									/>
								)}
							</Box>
						)}
					</Box>
					<Tooltip
						title={
							asaasBankAccount
								? t('info-transfer-asaas-min-value', {
										minValue: price.full(
											PS_EXCOFY_RULES.minimumValueToTransfer.asaas
										)
								  })
								: t('info-transfer-asaas-no-bank-account')
						}
						disableHoverListener={!disabledTransferButton}
						arrow
					>
						<span>
							<Button
								variant="contained"
								sx={{mt: 1}}
								disabled={disabledTransferButton}
								onClick={() => setOpenTransferDialog(true)}
							>
								{t('to-transfer-now')}
							</Button>
						</span>
					</Tooltip>
				</Box>
			</Box>
		</>
	)
}
