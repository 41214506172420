import {useNavigate} from 'react-router-dom'
import useAuthStates from '../stores/useAuthStates'
import useAppStates from '../stores/useAppStates'

const useProtectedRoute = () => {
	const currentLang = useAppStates(state => state.currentLang)
	const isLogged = useAuthStates(state => state.isLogged)
	const navigate = useNavigate()

	// TODO add "from" to redirect automatically after login
	return () => {
		if (!isLogged) navigate(currentLang ? `/${currentLang}/login` : '/login')
	}
}

export default useProtectedRoute
