import {Box, Button, Card, CircularProgress, Typography} from '@mui/material'
import Tag from '../typography/Tag'
import {useTranslation} from 'react-i18next'
import {
	IntegrationCategoryType,
	ExperienceIntegration
} from '../../entities/Integration'
import {useMutation} from '@tanstack/react-query'
import {createExperienceIntegration} from '../../api/integrations'
import {useState} from 'react'
import AlertDialog from '../AlertDialog'

interface IntegrationCardProps {
	id: string
	experienceId: string
	name: string
	description: string
	summary: string
	cover: string
	icon: string
	category: IntegrationCategoryType
	isAdded: boolean
	onClick: (integration: ExperienceIntegration) => void
}

export const IntegrationCard = ({
	id,
	experienceId,
	name,
	description,
	summary,
	cover,
	icon,
	category,
	isAdded: initialIsAdded,
	onClick
}: IntegrationCardProps) => {
	const {t} = useTranslation()

	const [isAdded, setIsAdded] = useState(initialIsAdded)
	const [errorMessage, setErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)

	const {mutate, isLoading} = useMutation(createExperienceIntegration, {
		networkMode: 'always'
	})

	const handleAddIntegration = () => {
		mutate(
			{
				experienceId,
				integrationId: id,
				initialStatus:
					name === 'order-bump' || name === 'webhooks' ? 'active' : undefined
			},
			{
				onSuccess: ({data, responseStatusCode, error}) => {
					if (responseStatusCode === 201 && data) {
						setIsAdded(true)
						onClick({
							id: data.id,
							experienceId,
							status: data.status,
							integration: {
								id: data.id,
								name,
								description,
								summary,
								cover,
								icon,
								category,
								isAdded: true
							}
						})
					} else if (error) {
						setErrorMessage(error.message)
						setOpenAlertError(true)
					} else {
						setErrorMessage(t('error-server-default'))
						setOpenAlertError(true)
					}
				},
				onError: error => {
					setErrorMessage(t('error-server-default'))
					setOpenAlertError(true)
					console.error(error)
				}
			}
		)
	}

	return (
		<>
			{openAlertError ? (
				<AlertDialog
					open={openAlertError}
					message={errorMessage}
					onClose={() => setOpenAlertError(false)}
				/>
			) : null}
			<Card
				variant="outlined"
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					px: 3,
					py: 2,
					maxWidth: 350
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1.5
					}}
				>
					<Typography variant="h6">{t('integration-' + name)}</Typography>
					<Box
						sx={{
							width: '100%',
							height: 135,
							borderRadius: 1
						}}
					>
						<img
							src={cover}
							alt={name}
							height={135}
							width="100%"
							style={{
								objectFit: 'cover',
								borderRadius: '8px'
							}}
						/>
					</Box>
					<Tag>{t(category)}</Tag>
					<Typography>{description}</Typography>
				</Box>
				{isAdded ? (
					<Button variant="outlined" disabled fullWidth>
						{t('added')}
					</Button>
				) : (
					<Button
						variant="contained"
						fullWidth
						onClick={handleAddIntegration}
						disabled={isLoading}
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('to-add')
						)}
					</Button>
				)}
			</Card>
		</>
	)
}
