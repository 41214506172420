import {Route, Routes} from 'react-router-dom'
import PaymentServices from '../pages/PaymentServices/PaymentServices'
import PaymentServicePagarmeSettings from '../pages/PaymentServices/pagarme/PaymentServicePagarmeSettings'
import FormPagarmeLegalRepresentative from '../pages/PaymentServices/pagarme/forms/FormLegalRepresentative'
import FormUpdateBankAccount from '../pages/PaymentServices/pagarme/forms/FormUpdateBankAccount'
import {PagarmeTransfers} from '../pages/PaymentServices/pagarme/PagarmeTransfers'
import AsaasAccountPage from '../pages/PaymentServices/asaas/AsaasAcountPage'
import NotFound from '../pages/NotFound'

export const PaymentServicesRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<PaymentServices />} />
			<Route path="pagarme" element={<PaymentServicePagarmeSettings />} />
			<Route path="pagarme-transfers" element={<PagarmeTransfers />} />
			<Route
				path="pagarme/managing-partner"
				element={<FormPagarmeLegalRepresentative />}
			/>
			<Route path="pagarme/bank-account" element={<FormUpdateBankAccount />} />
			<Route path="asaas" element={<AsaasAccountPage />} />

			<Route path="not-found" element={<NotFound />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}
