import {useCallback, useState} from 'react'
import Table from '@mui/material/Table'
import {useTranslation} from 'react-i18next'
import {FaCheck as CheckIcon} from 'react-icons/fa6'
import {HiOutlineDotsHorizontal as DotsIcon} from 'react-icons/hi'
import {MdOutlineFilterList as FilterListIcon} from 'react-icons/md'
import {FaWhatsapp as WhatsappIcon} from 'react-icons/fa'

import {
	Box,
	Icon,
	IconButton,
	Link,
	Paper,
	Skeleton,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography
} from '@mui/material'
import {regex} from '../../../helpers/regex'
import {ExportCustomersToCSV} from './ExportCustomersToCSV'
import FilterTagManager from './FilterTagManager'
import CustomerResearchFilters from './ResearchFilters'
import useAppStates from '../../../stores/useAppStates'
import useCustomersStates, {Customer} from '../../../stores/useCustomersStates'
import CustomerDetailsDialog from './DetailsDialog'
import CustomerMainFilters from './MainFilters'
import MenuList from '../../MenuList'
import {TableRowEmpty} from '../../table/TableRowEmpty'

export default function CustomersList({isLoading}: {isLoading: boolean}) {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()
	const {
		customers,
		limit,
		setLimit,
		setOffset,
		page,
		setPage,
		total,
		filters,
		addFilter
	} = useCustomersStates()

	const [openDetails, setOpenDetails] = useState(false)
	const [anchorElFilterTypeList, setAnchorFilterTypeList] =
		useState<null | HTMLElement>(null)
	const openFilterTypeList = Boolean(anchorElFilterTypeList)
	const [anchorElFilterNewsletterList, setAnchorFilterNewsletterList] =
		useState<null | HTMLElement>(null)
	const openFilterNewsletterList = Boolean(anchorElFilterNewsletterList)
	const [focusedCustomer, setFocusedCustomer] = useState<Customer | null>(null)
	const [anchorElActions, setAnchorElActions] = useState<null | HTMLElement>(
		null
	)
	const openActions = Boolean(anchorElActions)

	const handleOpenDetails = () => setOpenDetails(true)
	const handleCloseActions = () => setAnchorElActions(null)

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage)
		setOffset(newPage * limit)
		handleChangeURL(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(+event.target.value)
		setPage(0)
	}

	const handleChangeURL = (page: number) => {
		window.history.replaceState(
			undefined,
			'Dashboard | Excofy',
			`/${currentLang}/customers?page=${page}`
		)
	}

	const handleChangeFilter = useCallback(
		(newFilter: {[key: string]: string | undefined}) => {
			addFilter(newFilter)
			setAnchorFilterNewsletterList(null)
			setAnchorFilterTypeList(null)
		},
		[addFilter]
	)

	const handleOpenActions = useCallback(
		(event: React.MouseEvent<HTMLElement>, customerId: string) => {
			setAnchorElActions(event.currentTarget)
			const customer =
				customers.find(customer => customer.id === customerId) || null
			setFocusedCustomer(customer)
		},
		[customers]
	)

	return (
		<>
			{focusedCustomer && (
				<CustomerDetailsDialog
					open={openDetails}
					customer={focusedCustomer}
					onClose={() => setOpenDetails(false)}
				/>
			)}
			<MenuList
				open={openActions}
				anchorEl={anchorElActions}
				onClose={handleCloseActions}
				list={[
					{
						label: 'view-details',
						onClick: handleOpenDetails
					}
				]}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					gap: 2,
					mb: 3,
					width: '100%',
					mt: 3
				}}
			>
				<Box
					sx={theme => ({
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						gap: 2,
						[theme.breakpoints.down('sm')]: {
							width: '100%'
						}
					})}
				>
					<Typography>{t('research')}:</Typography>
					<CustomerResearchFilters
						filters={filters}
						onChange={handleChangeFilter}
					/>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							justifyContent: 'flex-start',
							gap: 1
						}}
					>
						{[
							{
								key: 'type',
								label: t('name'),
								value: filters?.name,
								active: filters?.name,
								onRemove: () => handleChangeFilter({name: undefined})
							},
							{
								key: 'email',
								label: t('email'),
								value: filters?.email,
								active: filters?.email,
								onRemove: () => handleChangeFilter({email: undefined})
							},
							{
								key: 'document',
								label: t('document'),
								value: filters?.document,
								active: filters?.document,
								onRemove: () => handleChangeFilter({document: undefined})
							}
						]
							.filter(c => c.active)
							.map(({label, value, onRemove}) => (
								<FilterTagManager
									label={label}
									value={value}
									onRemove={onRemove}
								/>
							))}
					</Box>
				</Box>
				<Box
					sx={theme => ({
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						gap: 2,
						[theme.breakpoints.down('sm')]: {
							width: '100%'
						}
					})}
				>
					<Typography>{t('filters')}:</Typography>
					<CustomerMainFilters onChange={handleChangeFilter} />
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							justifyContent: 'flex-start',
							gap: 1
						}}
					>
						{[
							{
								key: 'type',
								label: t('type'),
								value:
									filters?.type === 'individual'
										? t('natural-person')
										: filters?.type === 'company'
										? t('legal-entity')
										: t('all'),
								active: filters?.type,
								onRemove: () => handleChangeFilter({type: undefined})
							},
							{
								key: 'newsletter',
								label: t('newsletter'),
								value:
									filters?.newsletter === 'true'
										? t('subscribers')
										: filters?.newsletter === 'false'
										? t('non-subscribers')
										: t('all'),
								active: filters?.newsletter,
								onRemove: () => handleChangeFilter({newsletter: undefined})
							}
						]
							.filter(c => c.active)
							.map(({label, value, onRemove}) => (
								<FilterTagManager
									label={label}
									value={value}
									onRemove={onRemove}
								/>
							))}
					</Box>
				</Box>
			</Box>
			<Paper variant="outlined" sx={{display: 'grid'}}>
				<TableContainer>
					<Table
						sx={{
							width: '100%',
							minWidth: 800
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell width="100%">
									<Typography variant="subtitle1">{t('customer')}</Typography>
								</TableCell>
								<TableCell
									sx={{
										minWidth: '180px'
									}}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<Typography
											variant="subtitle2"
											sx={{
												color: filters?.type ? 'primary.main' : 'text.primary'
											}}
										>
											{t('type')}
										</Typography>
										<Box>
											<Tooltip
												title={t('filter-list')}
												placement="bottom"
												arrow
											>
												<IconButton
													onClick={(e: React.MouseEvent<HTMLElement>) =>
														setAnchorFilterTypeList(e.currentTarget)
													}
													size="small"
													sx={{
														ml: 1,
														color: filters?.type
															? 'primary.main'
															: 'text.secondary'
													}}
													aria-controls={
														openFilterTypeList ? t('filter-list') : undefined
													}
													aria-haspopup="true"
													aria-expanded={
														openFilterTypeList ? 'true' : undefined
													}
												>
													<FilterListIcon fontSize={16} />
												</IconButton>
											</Tooltip>
											<MenuList
												open={openFilterTypeList}
												anchorEl={anchorElFilterTypeList}
												onClose={() => setAnchorFilterTypeList(null)}
												list={[
													{
														key: 'all',
														label: t('all'),
														onClick: () => handleChangeFilter({type: undefined})
													},
													{
														key: 'individual',
														label: t('natural-persons'),
														active: filters?.type === 'individual',
														onClick: () =>
															handleChangeFilter({type: 'individual'})
													},
													{
														key: 'company',
														label: t('legal-entities'),
														active: filters?.type === 'company',
														onClick: () => handleChangeFilter({type: 'company'})
													}
												]}
											/>
										</Box>
									</Box>
								</TableCell>
								<TableCell
									sx={{
										minWidth: '216px'
									}}
								>
									<Typography variant="subtitle2">{t('phone')}</Typography>
								</TableCell>
								<TableCell
									sx={{
										minWidth: '180px'
									}}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										<Typography
											variant="subtitle2"
											sx={{
												color: filters?.newsletter
													? 'primary.main'
													: 'text.primary'
											}}
										>
											{t('newsletter')}
										</Typography>
										<Box>
											<Tooltip
												title={t('filter-list')}
												placement="bottom"
												arrow
											>
												<IconButton
													onClick={(e: React.MouseEvent<HTMLElement>) =>
														setAnchorFilterNewsletterList(e.currentTarget)
													}
													size="small"
													sx={{
														ml: 1,
														color: filters?.newsletter
															? 'primary.main'
															: 'text.secondary'
													}}
													aria-controls={
														openFilterNewsletterList
															? t('filter-list')
															: undefined
													}
													aria-haspopup="true"
													aria-expanded={
														openFilterNewsletterList ? 'true' : undefined
													}
												>
													<FilterListIcon fontSize={16} />
												</IconButton>
											</Tooltip>
											<MenuList
												open={openFilterNewsletterList}
												anchorEl={anchorElFilterNewsletterList}
												onClose={() => setAnchorFilterNewsletterList(null)}
												list={[
													{
														label: t('all'),
														onClick: () =>
															handleChangeFilter({newsletter: undefined})
													},
													{
														label: t('subscribers'),
														active: filters?.newsletter === 'true',
														onClick: () =>
															handleChangeFilter({newsletter: 'true'})
													},
													{
														label: t('non-subscribers'),
														active: filters?.newsletter === 'false',
														onClick: () =>
															handleChangeFilter({newsletter: 'false'})
													}
												]}
											/>
										</Box>
									</Box>
								</TableCell>
								<TableCell
									sx={{
										minWidth: '100px'
									}}
								>
									<Typography variant="subtitle2">{t('actions')}</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading && <TableRowsSkeleton length={limit} />}
							{customers.length === 0 && !isLoading && (
								<TableRowEmpty
									message={
										Object.keys(filters).length === 0
											? t('no-customers-yet')
											: t('no-customers-results-with-filter')
									}
								/>
							)}
							{!isLoading &&
								customers
									.map(customer => (
										<TableRow
											key={customer.id}
											sx={{
												'&:last-child td, &:last-child th': {
													borderBottomWidth: customers.length > 0 ? '1px' : 0
												}
											}}
										>
											<TableCell>
												<Typography variant="body2">{customer.name}</Typography>
												<Typography
													variant="caption"
													sx={theme => ({
														color: theme.palette.grey[600]
													})}
												>
													{customer.email}
												</Typography>
											</TableCell>
											<TableCell>
												{customer.type &&
													customer.type === 'individual' &&
													t('natural-person')}
												{customer.type &&
													customer.type === 'legal-person' &&
													t('legal-entity')}
												{!customer.type && '-'}
											</TableCell>
											<TableCell>
												{customer.phone ? (
													<Link
														href={`https://wa.me/${regex.removeAllNonDigits(
															customer.phone
														)}`}
														target="_blank"
														sx={{
															cursor: 'pointer',
															color: 'inherit',
															transition: 'color 0.2s',
															'&:hover': {
																color: '#4dc247'
															}
														}}
													>
														<Box
															sx={{
																display: 'inline-flex',
																alignItems: 'center'
															}}
														>
															<IconButton
																sx={{
																	color: '#4dc247',
																	p: '4px',
																	fontSize: '1.25rem'
																}}
															>
																<WhatsappIcon />
															</IconButton>
															<Typography variant="body2">
																{customer.phone}
															</Typography>
														</Box>
													</Link>
												) : (
													'-'
												)}
											</TableCell>
											<TableCell>
												{customer.newsletter ? (
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center'
														}}
													>
														<Icon
															color="success"
															sx={{lineHeight: '24px', mr: '31px'}}
														>
															<CheckIcon size={20} />
														</Icon>
													</Box>
												) : null}
											</TableCell>
											<TableCell>
												<Tooltip
													title={t('view-actions')}
													placement="bottom"
													arrow
												>
													<IconButton
														onClick={e => handleOpenActions(e, customer.id)}
														size="small"
														aria-controls={
															openActions ? t('actios') : undefined
														}
														aria-haspopup="true"
														aria-expanded={openActions ? 'true' : undefined}
													>
														<DotsIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									))
									.slice(0, limit)}
						</TableBody>
					</Table>
				</TableContainer>
				{customers.length > 0 && (
					<TableFooter
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							alignItems: 'center',
							justifyContent: 'space-between',
							pl: 2,
							py: 1,
							columnGap: 2,
							rowGap: 1
						}}
					>
						<ExportCustomersToCSV
							customers={customers.map(item => ({
								...item,
								name: item.name || '-',
								document: item.document || '-',
								phone: item.phone ? regex.removeAllNonDigits(item.phone) : '-',
								newsletter: item.newsletter ? t('yes') : t('not'),
								documentType: item?.documentType ? t(item.documentType) : '-',
								type: item.type
									? t(
											item.type === 'individual'
												? 'natural-person'
												: 'legal-entity'
									  )
									: '-'
							}))}
						/>
						<TablePagination
							rowsPerPageOptions={[25, 50, 100]}
							component="div"
							count={total}
							rowsPerPage={limit}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={t('items-per-page')}
							labelDisplayedRows={({from, to, count}) =>
								t('list-filter-info', {from, to, count})
							}
							sx={{
								'& .MuiTablePagination-toolbar': {
									pl: 0,
									minHeight: 'auto'
								},
								'& .MuiTablePagination-selectLabel': {
									margin: 0
								},
								'& .MuiTablePagination-displayedRows': {
									margin: 0
								}
							}}
						/>
					</TableFooter>
				)}
			</Paper>
		</>
	)
}

const TableRowsSkeleton = ({length}: {length: number}) => {
	const tableRows = []

	for (let i = 0; i < length; i++) {
		tableRows.push(
			<TableRow key={'sale-row-skeleton-' + i}>
				<TableCell component="th" scope="row">
					<Typography variant="body2">
						<Skeleton width={180} />
					</Typography>
					<Typography
						variant="caption"
						sx={theme => ({
							color: theme.palette.grey[600]
						})}
					>
						<Skeleton width={200} />
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography variant="body2">
						<Skeleton width={90} />
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography variant="body2">
						<Skeleton width={100} />
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography variant="body2">
						<Skeleton width={40} />
					</Typography>
				</TableCell>
				<TableCell align="left">
					<IconButton size="small">
						<DotsIcon></DotsIcon>
					</IconButton>
				</TableCell>
			</TableRow>
		)
	}

	return <>{tableRows}</>
}
