import {useState} from 'react'
import Popover from '@mui/material/Popover'
import useAppStates from '../../../stores/useAppStates'
import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {useFormik} from 'formik'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import {CgClose as ClearIcon} from 'react-icons/cg'
import {FaCircle as CircleIcon} from 'react-icons/fa'

import dayjs, {Dayjs} from 'dayjs'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import {PagarmeLogo} from '../../logos/PagarmeLogo'
import {AsaasLogo} from '../../logos/AsaasLogo'
import {SaleFilters} from '../../../pages/Sales'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {getStatusProps} from '../../Status'
import {useTheme} from '@mui/material/styles'
import ExperiencesSelect from '../../ExperiencesSelect'

const convertLocaleToDayjs = (locale: string) => {
	if (locale === 'pt-BR' || locale === 'pt-br' || locale === 'pt') {
		return 'pt-br'
	}

	return 'en'
}

export const FilterDialog = ({
	open,
	anchorEl,
	filters,
	onClose,
	onApplyFilters
}: {
	open: boolean
	anchorEl: HTMLButtonElement | null
	filters?: SaleFilters
	onClose: () => void
	onApplyFilters: (filters: SaleFilters) => void
}) => {
	const {currentLang} = useAppStates()
	const {t} = useTranslation()
	const theme = useTheme()

	const [openDatePickerToDate, setOpenDatePickerToDate] = useState(false)
	const [openDatePickerFromDate, setOpenDatePickerFromDate] = useState(false)

	const formik = useFormik({
		initialValues: {
			productId: filters?.productId || null,
			fromDate: filters?.fromDate ? dayjs(filters?.fromDate) : null,
			toDate: filters?.toDate ? dayjs(filters?.toDate) : null,
			paymentService: filters?.paymentService || {
				pagarme: false,
				asaas: false
			},
			paymentMethod: filters?.paymentMethod || {
				creditCard: false,
				bankSlip: false,
				pix: false
			},
			status: 'all'
		} as {
			productId: string | null
			fromDate: Dayjs | null
			toDate: Dayjs | null
			paymentService: {
				pagarme: boolean
				asaas: boolean
			}
			paymentMethod: {
				creditCard: boolean
				bankSlip: boolean
				pix: boolean
			}
			status: string
		},
		onSubmit: values => {
			onClose()

			const fromDate = values?.fromDate
				? values?.fromDate.format('YYYY-MM-DD')
				: undefined
			const toDate = values?.toDate
				? values?.toDate.add(1, 'day').format('YYYY-MM-DD')
				: undefined

			onApplyFilters({
				fromDate,
				toDate,
				productId: values.productId || undefined,
				paymentService: values.paymentService,
				paymentMethod: values.paymentMethod,
				status: values.status === 'all' ? undefined : values.status
			})
		}
	})

	const handleClearFilters = () => {
		onClose()
		onApplyFilters({
			fromDate: undefined,
			toDate: undefined,
			status: undefined,
			productId: undefined,
			paymentService: undefined,
			paymentMethod: undefined
		})
		formik.resetForm()
	}

	return (
		<Popover
			id={'sales-filters-popover'}
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					p: 1.5
				}}
			>
				<Typography variant="h6">{t('filters')}</Typography>
				<IconButton onClick={onClose} size="small">
					<ClearIcon />
				</IconButton>
			</Box>
			<Divider />
			<Stack
				spacing={3}
				px={2}
				py={3}
				minWidth={300}
				sx={theme => ({
					overflowY: 'auto',
					[theme.breakpoints.down('md')]: {
						maxHeight: 'calc(100vh - 160px)'
					}
				})}
			>
				<ExperiencesSelect
					label={t('product-or-service')}
					name="productId"
					selectedProductId={formik.values.productId}
					onChange={(
						data: {
							title: string
							id: string
						} | null
					) => {
						formik.setFieldValue('productId', data?.id || null)
					}}
				/>
				<LocalizationProvider
					dateAdapter={AdapterDayjs}
					adapterLocale={convertLocaleToDayjs(currentLang)}
				>
					<Box>
						<FormLabel sx={{mb: 1, display: 'block'}}>
							<Typography variant="subtitle1">{t('date-range')}</Typography>
						</FormLabel>
						<Box
							sx={{
								display: 'flex',
								gap: 2
							}}
						>
							<Box
								sx={theme => ({
									display: 'flex',
									position: 'relative'
								})}
							>
								<DatePicker
									value={formik.values.fromDate}
									dayOfWeekFormatter={day => `${day}`}
									onChange={newValue => {
										formik.setFieldValue('fromDate', newValue)
									}}
									open={openDatePickerFromDate}
									onClose={() => {
										setOpenDatePickerFromDate(false)
									}}
									slots={<TextField />}
									slotProps={{
										textField: {
											name: 'fromDate',
											type: 'text',
											label: t('from-date'),
											value: formik.values.fromDate,
											helperText:
												formik.touched.fromDate && formik.errors.fromDate,
											error: Boolean(formik.errors.fromDate),
											variant: 'outlined',
											size: 'small',
											sx: {
												flexGrow: 1
											},
											onClick: () => {
												setOpenDatePickerFromDate(true)
											},
											InputProps: {
												endAdornment: null
											}
										}
									}}
								/>
								{formik.values?.fromDate && (
									<Tooltip title={t('to-remove')} arrow>
										<IconButton
											size="small"
											title={t('to-remove')}
											onClick={() => formik.setFieldValue('fromDate', null)}
											sx={{
												position: 'absolute',
												right: '8px',
												top: '6px'
											}}
										>
											<ClearIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
							<Box
								sx={theme => ({
									display: 'flex',
									position: 'relative'
								})}
							>
								<DatePicker
									value={formik.values.toDate}
									maxDate={dayjs(Date.now())}
									dayOfWeekFormatter={day => `${day}`}
									onChange={newValue => {
										formik.setFieldValue('toDate', newValue)
									}}
									open={openDatePickerToDate}
									onClose={() => {
										setOpenDatePickerToDate(false)
									}}
									slots={<TextField />}
									slotProps={{
										textField: {
											name: 'toDate',
											type: 'text',
											label: t('to-date'),
											value: formik.values.toDate,
											helperText: formik.touched.toDate && formik.errors.toDate,
											error: Boolean(formik.errors.toDate),
											variant: 'outlined',
											size: 'small',
											sx: {
												flexGrow: 1
											},
											onClick: () => {
												setOpenDatePickerToDate(true)
											},
											InputProps: {
												endAdornment: null
											}
										}
									}}
								/>
								{formik.values?.toDate && (
									<Tooltip title={t('to-remove')} arrow>
										<IconButton
											size="small"
											title={t('to-remove')}
											onClick={() => formik.setFieldValue('toDate', null)}
											sx={{
												position: 'absolute',
												right: '8px',
												top: '6px'
											}}
										>
											<ClearIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						</Box>
					</Box>
				</LocalizationProvider>
				<Box>
					<FormControl
						component="fieldset"
						variant="standard"
						sx={{
							width: '100%',
							flex: 1
						}}
					>
						<FormLabel
							sx={{
								mb: 0.5
							}}
						>
							<Typography variant="subtitle1">{t('status')}</Typography>
						</FormLabel>
						<FormControl size="small">
							<Select
								name="status"
								id="sales-chart-period-select"
								value={formik.values.status}
								onChange={e => {
									formik.setFieldValue('status', e.target.value)
								}}
								fullWidth
							>
								{[
									{
										key: 'all'
									},
									{
										key: 'open'
									},
									{
										key: 'paid'
									},
									{
										key: 'pending'
									},
									{
										key: 'failed'
									},
									{
										key: 'overdue'
									},
									{
										key: 'refunded'
									},
									{
										key: 'partial-refunded'
									},
									{
										key: 'processing-refund'
									},
									{
										key: 'awaiting-customer-data-for-refund'
									}
								].map(item => {
									const {color} = getStatusProps(item.key, theme)

									return (
										<MenuItem
											key={'sale-list-filter-status-' + item.key}
											onClick={() => {
												formik.setFieldValue('status', item.key)
											}}
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 0.5,
												backgroundColor:
													item.key === formik.values.status
														? 'rgba(5, 3, 3, 0.03)'
														: 'transparent'
											}}
											value={item.key}
										>
											<CircleIcon
												color={color}
												style={{marginRight: '4px'}}
												fontSize={10}
											/>
											<Typography variant="body2" display="inline">
												{t('status-tag-' + item.key)}
											</Typography>
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</FormControl>
				</Box>
				<Box>
					<FormControl component="fieldset" variant="standard">
						<FormLabel
							sx={{
								mb: 0.5
							}}
						>
							<Typography variant="subtitle1">{t('payment-method')}</Typography>
						</FormLabel>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={formik.values.paymentMethod.creditCard}
										onChange={() => {
											formik.setFieldValue('paymentMethod', {
												...formik.values.paymentMethod,
												creditCard: !formik.values.paymentMethod.creditCard
											})
										}}
										name="creditCard"
									/>
								}
								label={t('credit-card')}
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={formik.values.paymentMethod.pix}
										onChange={() => {
											formik.setFieldValue('paymentMethod', {
												...formik.values.paymentMethod,
												pix: !formik.values.paymentMethod.pix
											})
										}}
										name="pix"
									/>
								}
								label={t('pix')}
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={formik.values.paymentMethod.bankSlip}
										onChange={() => {
											formik.setFieldValue('paymentMethod', {
												...formik.values.paymentMethod,
												bankSlip: !formik.values.paymentMethod.bankSlip
											})
										}}
										name="bankSlip"
									/>
								}
								label={t('boleto')}
							/>
						</FormGroup>
					</FormControl>
				</Box>
				<Box>
					<FormControl component="fieldset" variant="standard">
						<FormLabel
							sx={{
								mb: 0.5
							}}
						>
							<Typography variant="subtitle1">
								{t('payment-service')}
							</Typography>
						</FormLabel>
						<FormGroup
							sx={{
								display: 'flex',
								gap: 2,
								flexDirection: 'row'
							}}
						>
							<FormControlLabel
								control={
									<Checkbox
										checked={formik.values.paymentService.pagarme}
										onChange={() => {
											formik.setFieldValue('paymentService', {
												...formik.values.paymentService,
												pagarme: !formik.values.paymentService.pagarme
											})
										}}
										name="pagarme"
									/>
								}
								label={<PagarmeLogo height={24} />}
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={formik.values.paymentService.asaas}
										onChange={() => {
											formik.setFieldValue('paymentService', {
												...formik.values.paymentService,
												asaas: !formik.values.paymentService.asaas
											})
										}}
										name="asaas"
									/>
								}
								label={<AsaasLogo height={20} />}
							/>
						</FormGroup>
					</FormControl>
				</Box>
			</Stack>
			<Divider />
			<Box
				sx={{
					p: 2,
					gap: 1.5,
					display: 'flex',
					justifyContent: 'flex-end'
				}}
			>
				<Button variant="outlined" onClick={handleClearFilters}>
					{t('clear-filters')}
				</Button>
				<Button variant="contained" onClick={formik.submitForm}>
					{t('to-apply-filters')}
				</Button>
			</Box>
		</Popover>
	)
}
