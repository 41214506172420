import {Breadcrumbs, Container} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {FaHome as HomeIcon} from 'react-icons/fa'

import Footer from '../components/template/Footer'
import FormUpdateProfile from '../components/forms/profile/FormUpdateProfile'
import Link from '../components/navigation/Link'
import {DashboardFullSection} from '../components/template/DashboardFullSection'

const Profile = () => {
	const {t} = useTranslation()

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<span>{t('profile')}</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<FormUpdateProfile />
			</DashboardFullSection>
			<Footer />
		</>
	)
}

export default Profile
