import {useCallback, useEffect, useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonIcon from '../../ButtonIcon'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Skeleton from '@mui/material/Skeleton'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {Theme} from '@mui/material/styles'
import {regex} from '../../../helpers/regex'
import AlertDialog from '../../AlertDialog'
import MenuList from '../../MenuList'
import {Dialog, SimpleDialog} from '../../Dialog'
import {Status} from '../../Status'
import {
	getSaleById,
	getSaleIntegrationsById,
	getSaleWebhookEvents,
	resendDeliveryIntegration,
	resendIntegrations
} from '../../../api/sales'
import useSaleStates, {SaleWebhookEvent} from '../../../stores/useSaleStates'
import {HiArrowUturnRight as ArrowRightCurved} from 'react-icons/hi2'
import {HiOutlineDotsHorizontal as DotsIcon} from 'react-icons/hi'
import {BiSolidPurchaseTag as TagIcon} from 'react-icons/bi'
import {MdModeEdit as EditIcon} from 'react-icons/md'
import {FaWhatsapp as WhatsappIcon} from 'react-icons/fa'
import {FaRegClipboard as ClipboardIcon} from 'react-icons/fa6'
import {CgClose as CloseIcon} from 'react-icons/cg'
import TabletUp from '../../layout/responsive/TabletUp'
import TabletDown from '../../layout/responsive/TabletDown'
import SaleRefundDialog from './RefundDialog'
import AlertMessage from '../../AlertMessage'
import useAppStates from '../../../stores/useAppStates'
import {LoadingDialog} from '../../LoadingDialog'
import {date} from '../../../helpers/date'
import Tag from '../../typography/Tag'
import {price} from '../../../helpers/price'
import InfoPopover from '../../InfoPopover'
import Link from '@mui/material/Link'
import {AsaasLogo} from '../../logos/AsaasLogo'
import {PagarmeLogo} from '../../logos/PagarmeLogo'
import {CHECKOUT_URL} from '../../../config'
import useAuthStates from '../../../stores/useAuthStates'
import {LogDialog} from './LogDialog'
import Paper from '@mui/material/Paper'
import {WebhooksLogsDetails} from '../../integrations/webhooks/WebhooksLogsDetails'
import {deleteEvent, dispatchNewEvent, resendEvent} from '../../../api/webhooks'
import {ConfirmDialog} from '../../dialogs/ConfirmDialog'
import {
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack
} from '@mui/material'
import {SaleDetails} from '../../../entities/Sale'

export default function SaleDetailsModal({
	id,
	open,
	onClose
}: {
	id: string
	open: boolean
	onClose: () => void
}) {
	const {t} = useTranslation()
	const {profile} = useAuthStates()
	const {
		saleDetails,
		setSaleDetails,
		setSaleIntegrations,
		updateSaleIntegrationsSend
	} = useSaleStates()
	const [openAlertDialog, setOpenAlertDialog] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [copyLinkTooltip, setCopyLinkTooltip] = useState<string>(
		t('copy-sale-link')
	)
	const getFormattedDocument = (
		documentType: string,
		documentNumber: string
	) => {
		switch (documentType) {
			case 'cpf':
				return regex.formatCPF(documentNumber)
			case 'cnpj':
				return regex.formatCNPJ(documentNumber)
			default:
				return documentNumber
		}
	}

	const handleClose = () => {
		setSaleDetails(null)
		onClose()
	}

	const handleCopyLink = () => {
		const saleLink = `${CHECKOUT_URL}/${profile?.nameSlug}/${saleDetails?.offer.product.slug}/${saleDetails?.offer.slug}?key=${saleDetails?.publicId}`
		navigator.clipboard.writeText(saleLink).then(() => {
			setCopyLinkTooltip(t('copied-link'))
		})
	}

	const {mutate, isLoading} = useMutation(getSaleById, {
		networkMode: 'always'
	})

	const fetchSaleById = useCallback(() => {
		setAlertMessage('')
		setOpenAlertDialog(false)
		mutate(
			{saleId: id},
			{
				onSuccess: ({data, responseStatusCode}) => {
					if (responseStatusCode === 200 && data) {
						setSaleDetails({
							...data.sale,
							buyer: {
								...data.sale.buyer,
								...(data.sale.buyer?.documentType && data.sale.buyer?.document
									? {
											document: getFormattedDocument(
												data.sale.buyer.documentType,
												data.sale.buyer.document
											)
									  }
									: {})
							},
							items: data.items
						})
					} else {
						setAlertMessage(t('error-loading-sale-details'))
						setOpenAlertDialog(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-loading-sale-details'))
					setOpenAlertDialog(true)
					console.error(error)
				}
			}
		)
	}, [id, mutate, setSaleDetails, t])

	const {mutate: mutateSaleIntegrationsById} = useMutation(
		getSaleIntegrationsById,
		{
			networkMode: 'always'
		}
	)

	const fetchSaleIntegrationsById = useCallback(() => {
		setAlertMessage('')
		setOpenAlertDialog(false)
		mutateSaleIntegrationsById(
			{saleId: id},
			{
				onSuccess: ({data, responseStatusCode}) => {
					if (responseStatusCode === 200 && data) {
						setSaleIntegrations(data)
						updateSaleIntegrationsSend(
							data?.cademi?.some(
								integration => integration.status === 'failed'
							) ||
								data['the-members']?.some(
									integration => integration.status === 'failed'
								)
								? true
								: null
						)
					} else {
						setAlertMessage(t('error-loading-sale-integrations'))
						setOpenAlertDialog(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-loading-sale-integrations'))
					setOpenAlertDialog(true)
					console.error(error)
				}
			}
		)
	}, [
		id,
		mutateSaleIntegrationsById,
		setSaleIntegrations,
		updateSaleIntegrationsSend,
		t
	])

	useEffect(() => {
		if (open) {
			fetchSaleById()
			fetchSaleIntegrationsById()
		}
	}, [open, fetchSaleById, fetchSaleIntegrationsById])

	return (
		<>
			<Dialog.Root open={open} fullScreen>
				<Dialog.Header>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 0.5
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<Box
								sx={theme => ({
									display: 'flex',
									alignItems: 'center',
									columnGap: 1,
									flexDirection: 'row',
									flexWrap: 'wrap'
								})}
							>
								<Typography
									component="h1"
									variant="h6"
									sx={{
										color: 'common.black'
									}}
								>
									{t('sale-details')}
								</Typography>
								{saleDetails && (
									<ButtonIcon
										icon={<ClipboardIcon size={16} />}
										size="medium"
										title={copyLinkTooltip}
										onClick={handleCopyLink}
										onMouseLeave={() => setCopyLinkTooltip(t('copy-sale-link'))}
									/>
								)}
							</Box>
							<IconButton
								sx={{
									color: 'grey.500',
									padding: 0,
									'&:hover': {
										cursor: 'pointer',
										color: 'grey.600'
									}
								}}
								size="small"
								onClick={handleClose}
								aria-label={t('aria-close-modal')}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						</Box>
					</Box>
				</Dialog.Header>
				<Dialog.Body width="100%" sx={{m: 0}}>
					<Box
						sx={theme => ({
							mb: 4,
							[theme.breakpoints.down('sm')]: {
								p: 2
							}
						})}
					>
						<Box
							sx={theme => ({
								display: 'flex',
								gap: 2,
								alignItems: 'flex-start',
								maxWidth: 1200,
								margin: '0 auto',
								width: '100%',
								[theme.breakpoints.down('md')]: {
									flexDirection: 'column'
								}
							})}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'stretch',
									flex: 1,
									gap: 2,
									width: '100%'
								}}
							>
								{alertMessage !== '' && !saleDetails && (
									<Box>
										<AlertMessage
											severity="error"
											open={alertMessage !== ''}
											onClose={setAlertMessage.bind(null, '')}
											message={alertMessage}
										/>
										<Button variant="contained" onClick={fetchSaleById}>
											{t('to-list-sale')}
										</Button>
									</Box>
								)}
								<Box
									sx={theme => ({
										display: 'flex',
										gap: 2,
										flexWrap: 'wrap',
										alignItems: 'stretch',
										[theme.breakpoints.down('md')]: {
											flexDirection: 'column'
										}
									})}
								>
									{isLoading && !saleDetails && (
										<>
											<Skeleton variant="rounded" height={190} width="100%" />
											<Skeleton variant="rounded" height={220} width="100%" />
										</>
									)}
									<SaleDetailsBuyer />
									<SaleDetailsPayment />
									<TabletDown>
										<SaleDetailsActivities />
									</TabletDown>
								</Box>
								{isLoading && !saleDetails && (
									<Skeleton variant="rounded" height={250} sx={{flex: 1}} />
								)}
								<SaleDetailsProducts />
								<SaleIntegrations />
								<SaleWebhookEvents saleId={id} />
								{saleDetails?.publicId && (
									<Typography variant="caption" color="grey.500">
										{t('ref') + ': ' + saleDetails?.publicId}
									</Typography>
								)}
							</Box>
							<Box
								sx={theme => ({
									display: 'flex',
									flexDirection: 'column',
									gap: 2,
									maxWidth: 300,
									[theme.breakpoints.down('md')]: {
										flexDirection: 'row',
										alignItems: 'stretch',
										width: '100%',
										maxWidth: '100%'
									},
									[theme.breakpoints.down('sm')]: {
										flexDirection: 'column',
										alignItems: 'flex-start'
									}
								})}
							>
								{/* <SaleDetailsNotes /> */}
								<TabletUp>
									<SaleDetailsActivities />
									{isLoading && !saleDetails && (
										<Skeleton
											variant="rounded"
											height={250}
											sx={{width: 300}}
										/>
									)}
								</TabletUp>
							</Box>
						</Box>
					</Box>
				</Dialog.Body>
			</Dialog.Root>
		</>
	)
}

function SaleDetailsActivities() {
	const {t} = useTranslation()
	const {saleDetails} = useSaleStates()
	const {currentLang} = useAppStates()

	const [openLogDialog, setOpenLogDialog] = useState(false)
	const [saleLog, setSaleLog] = useState<any | null>(null)

	return (
		<>
			{saleLog && (
				<LogDialog
					open={openLogDialog}
					onClose={setOpenLogDialog.bind(null, false)}
					log={saleLog}
				/>
			)}
			{saleDetails && (
				<Card
					variant="outlined"
					sx={theme => ({
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						flex: 1,
						[theme.breakpoints.down('sm')]: {
							width: '100%'
						}
					})}
				>
					<Typography
						variant="subtitle2"
						sx={theme => ({
							minWidth: 300,
							[theme.breakpoints.down('sm')]: {
								minWidth: '100%'
							}
						})}
					>
						{t('activities')}
					</Typography>
					<Box
						sx={theme => ({
							[theme.breakpoints.up('md')]: {
								maxHeight: 'calc(100vh - 220px)',
								overflowY: 'auto',
								'&::-webkit-scrollbar': {
									width: 8
								},
								'&::-webkit-scrollbar-thumb': {
									backgroundColor: 'grey.300',
									borderRadius: 4
								}
							}
						})}
					>
						{saleDetails?.statusHistory.map((statusHistory, index) => (
							<Box
								key={'sale-status-history-' + index + '-' + statusHistory.date}
								sx={{
									display: 'flex',
									alignItems: 'stretch'
								}}
							>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center'
									}}
								>
									<Status.Circle
										type={statusHistory.status}
										fontSize={16}
										style={{zIndex: 1, margin: 0}}
									/>
									{index !== saleDetails.statusHistory.length - 1 && (
										<Box
											sx={{
												flex: 1,
												borderRightWidth: 1,
												borderRightStyle: 'dashed',
												borderColor: 'divider'
											}}
										/>
									)}
								</Box>
								<Box
									sx={{
										pb: 2,
										pl: 1,
										mt: '-4px'
									}}
								>
									<Typography fontWeight={500}>
										{t('status-tag-' + statusHistory.status)}
									</Typography>
									<Typography variant="caption" color="text.secondary">
										{date.convertISOToLocation(
											statusHistory.date,
											currentLang,
											true
										)}
									</Typography>
									{statusHistory.log && (
										<Button
											variant="outlined"
											onClick={() => {
												setSaleLog(statusHistory.log)
												setOpenLogDialog(true)
											}}
											size="small"
											sx={{
												display: 'block',
												mt: 1
											}}
										>
											{t('to-view-error-log')}
										</Button>
									)}
								</Box>
							</Box>
						))}
					</Box>
				</Card>
			)}
		</>
	)
}

function SaleDetailsBuyer() {
	const {t} = useTranslation()
	const {saleDetails} = useSaleStates()

	return (
		<>
			{saleDetails?.buyer && (
				<Card
					variant="outlined"
					sx={theme => ({
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						flex: 1,
						minWidth: 350,
						[theme.breakpoints.down('md')]: {
							flex: 'none',
							minWidth: '100%'
						}
					})}
				>
					<Typography variant="subtitle2">{t('customer')}</Typography>
					<Box>
						{saleDetails.buyer.name && (
							<Typography variant="h6" component="p" mb={1}>
								{saleDetails.buyer.name}
							</Typography>
						)}
						<Box
							sx={(theme: Theme) => ({
								display: 'flex',
								flexDirection: 'column',
								rowGap: 0.5,
								[theme.breakpoints.down('sm')]: {
									rowGap: 2
								}
							})}
						>
							<Box sx={styles.infoLine}>
								<Typography>{t('email')}</Typography>
								<Typography color="textSecondary">
									{saleDetails.buyer.email}
								</Typography>
							</Box>
							{saleDetails.buyer.phoneNumber && (
								<Box sx={styles.infoLine}>
									<Typography>{t('phone')}</Typography>
									<Link
										href={`https://wa.me/${regex.removeAllNonDigits(
											saleDetails.buyer.phoneCountryCode +
												saleDetails.buyer.phoneAreaCode +
												saleDetails.buyer.phoneNumber
										)}`}
										target="_blank"
										sx={{
											cursor: 'pointer',
											color: 'inherit',
											transition: 'color 0.2s',
											'&:hover': {
												color: '#4dc247'
											}
										}}
									>
										<Box
											sx={{
												display: 'inline-flex',
												alignItems: 'center'
											}}
										>
											<IconButton
												sx={{
													color: '#4dc247',
													p: '4px',
													fontSize: '1.25rem'
												}}
											>
												<WhatsappIcon />
											</IconButton>
											<Typography
												sx={{
													color: 'text.secondary',
													'&:hover': {
														color: '#4dc247'
													}
												}}
											>
												{saleDetails.buyer.phoneCountryCode === '55' ? (
													<>
														+{saleDetails.buyer.phoneCountryCode}{' '}
														{regex.formatPhone(
															saleDetails.buyer.phoneAreaCode +
																saleDetails.buyer.phoneNumber
														)}
													</>
												) : (
													<>
														+
														{saleDetails.buyer.phoneCountryCode.length >= 4
															? saleDetails.buyer.phoneCountryCode.slice(0, 1) +
															  '-' +
															  saleDetails.buyer.phoneCountryCode.slice(1)
															: saleDetails.buyer.phoneCountryCode}{' '}
														{saleDetails.buyer.phoneAreaCode +
															saleDetails.buyer.phoneNumber}
													</>
												)}
											</Typography>
										</Box>
									</Link>
								</Box>
							)}
							{saleDetails.buyer.document && (
								<Box sx={styles.infoLine}>
									<Typography>{t('cpf')}</Typography>
									<Typography color="textSecondary">
										{regex.formatCPF(saleDetails.buyer.document)}
									</Typography>
								</Box>
							)}
						</Box>
					</Box>
				</Card>
			)}
		</>
	)
}

function SaleIntegrations() {
	const {t} = useTranslation()
	const {saleIntegrations, saleDetails, setSaleDetails, setSaleIntegrations} =
		useSaleStates()

	const [openAlertDialog, setOpenAlertDialog] = useState(false)
	const [openSuccessResendDialog, setOpenSuccessResendDialog] = useState(false)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const {mutate, isLoading} = useMutation(resendIntegrations, {
		networkMode: 'always'
	})

	const handleResendIntegrations = () => {
		mutate(
			{
				saleId: saleDetails?.id as string
			},
			{
				onSuccess: ({responseStatusCode, error, data}) => {
					if (responseStatusCode === 200 && data) {
						setSaleDetails({
							...(saleDetails as SaleDetails),
							sendIntegrationsFailed: null
						})
						setSaleIntegrations(data.integrations)
						setOpenSuccessResendDialog(true)
					} else {
						setAlertMessage(error?.message || t('error-resending-deliveries'))
						setOpenAlertDialog(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-resending-deliveries'))
					setOpenAlertDialog(true)
				}
			}
		)
	}

	if (
		Object.entries(saleIntegrations).every(([_, i]) => i.length === 0) &&
		saleDetails?.sendIntegrationsFailed === null
	) {
		return null
	}

	return (
		<>
			<SimpleDialog
				open={openSuccessResendDialog}
				onClose={() => setOpenSuccessResendDialog(false)}
			>
				<Typography variant="h3" textAlign="center" color="text.primary">
					🎉
				</Typography>
				<Typography variant="h6" textAlign="center" color="text.primary" mt={2}>
					{t('deliveries-successfully-resend')}
				</Typography>
			</SimpleDialog>
			<LoadingDialog open={isLoading} message={t('resending-deliveries')} />
			<Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: 2,
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
				>
					<Typography variant="h6" component="h2" mb={2} mt={3}>
						{t('integrations')}
					</Typography>
					<Button
						variant="outlined"
						disabled={isLoading}
						onClick={handleResendIntegrations}
						sx={{width: 180}}
					>
						{isLoading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							t('to-resend-deliveries')
						)}
					</Button>
				</Box>
				{saleDetails?.sendIntegrationsFailed && (
					<Box sx={{mb: 2}}>
						<AlertMessage
							open={true}
							severity="error"
							message={t('error-sending-deliveries')}
							mt={0}
						/>
					</Box>
				)}
				<Stack spacing={3}>
					{Object.entries(saleIntegrations).map(([key, integration]) => (
						<Box key={'sale-details-table-integration-' + key}>
							{integration.length > 0 && (
								<>
									<Card variant="outlined">
										<Typography variant="subtitle2" p={2}>
											{t('integration-' + key)}
										</Typography>
										<TableContainer>
											<Table>
												<TableHead sx={styles.tableHead}>
													<TableCell
														sx={[
															styles.tableCell,
															{
																width: '100%'
															}
														]}
													>
														{t('experience')}
													</TableCell>
													<TableCell
														sx={[
															styles.tableCell,
															{
																minWidth: 180
															}
														]}
													>
														{t('status')}
													</TableCell>
													<TableCell
														sx={[
															styles.tableCell,
															{
																minWidth: 80
															}
														]}
													>
														{t('actions')}
													</TableCell>
												</TableHead>
												<TableBody>
													{integration.map(data => (
														<SaleIntegrationRow {...data} />
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Card>
								</>
							)}
						</Box>
					))}
				</Stack>
			</Box>
		</>
	)
}

function SaleIntegrationRow({
	id,
	name,
	product,
	status,
	statusHistory
}: {
	id: string
	name: 'cademi' | 'the-members'
	product: {
		publicId: string
		title: string
		cover: string
	}
	status: string
	statusHistory: {
		status: string
		log?: string
		date: string
	}[]
}) {
	const {t} = useTranslation()
	const [anchorElActionList, setAnchorElActionList] =
		useState<null | HTMLElement>(null)
	const openActionList = Boolean(anchorElActionList)
	const {
		saleDetails,
		setSaleDetails,
		saleIntegrations,
		updateSaleIntegrationStatus
	} = useSaleStates()
	const [openAlertDialog, setOpenAlertDialog] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [openLogDialog, setOpenLogDialog] = useState(false)
	const [logStatusHistory, setLogStatusHistory] = useState<
		{
			date: string
			status: string
			log?: string | undefined
		}[]
	>([])

	const handleCloseActionList = () => setAnchorElActionList(null)
	const handleOpenActionList = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElActionList(event.currentTarget)
	}

	const handleOpenLogDialog = (
		statusHistory: {status: string; date: string; log?: string}[]
	) => {
		setLogStatusHistory(statusHistory)
		setOpenLogDialog(true)
	}

	const {mutate, isLoading} = useMutation(resendDeliveryIntegration, {
		networkMode: 'online'
	})

	const resendIntegration = (id: string) => {
		handleCloseActionList()
		mutate(
			{
				saleId: saleDetails?.id as string,
				saleItemIntegrationsId: id,
				integrationName: name
			},
			{
				onSuccess: ({responseStatusCode, data}) => {
					if (responseStatusCode === 200) {
						updateSaleIntegrationStatus({
							id,
							status: 'sent',
							name: name
						})
						setSaleDetails({
							...(saleDetails as SaleDetails),
							sendIntegrationsFailed:
								saleIntegrations?.cademi?.some(
									integration => integration.status === 'failed'
								) ||
								saleIntegrations?.['the-members']?.some(
									integration => integration.status === 'failed'
								)
									? true
									: null
						})
						setSuccessMessage(t('delivery-successfully-resend'))
						setOpenSuccessDialog(true)
					} else if (data?.log) {
						updateSaleIntegrationStatus({
							id,
							status: data.status,
							log: data.log,
							name: name
						})
						setAlertMessage(t('error-resending-delivery'))
						setOpenAlertDialog(true)
					} else {
						setAlertMessage(t('error-server-default'))
						setOpenAlertDialog(true)
					}
				},
				onError: _ => {
					setAlertMessage(t('error-server-default'))
					setOpenAlertDialog(true)
				}
			}
		)
	}

	return (
		<>
			<LoadingDialog open={isLoading} message={t('resending-delivery')} />
			<AlertDialog
				severity="error"
				open={openAlertDialog}
				message={alertMessage}
				onClose={() => setOpenAlertDialog(false)}
			/>
			<SimpleDialog
				open={openSuccessDialog}
				onClose={() => setOpenSuccessDialog(false)}
			>
				<Typography variant="h3" textAlign="center" color="text.primary">
					🎉
				</Typography>
				<Typography variant="h6" textAlign="center" color="text.primary" mt={2}>
					{successMessage}
				</Typography>
			</SimpleDialog>
			<SaleIntegrationStatusHistory
				open={openLogDialog}
				onClose={() => setOpenLogDialog(false)}
				name={name}
				statusHistory={logStatusHistory}
			/>
			<TableRow
				key={'sale-integration-' + id}
				sx={{
					'&:last-child td, &:last-child th': {
						borderBottom: 0
					}
				}}
			>
				<TableCell>
					<Box
						sx={theme => ({
							display: 'flex',
							gap: 2,
							alignItems: 'center',
							[theme.breakpoints.down('sm')]: {
								flexDirection: 'column',
								alignItems: 'flex-start'
							}
						})}
					>
						{product?.cover ? (
							<Box
								sx={{
									height: 50,
									width: 66.7,
									backgroundColor: 'grey.100',
									...(product.cover
										? {
												backgroundImage: `url(${product.cover})`
										  }
										: {}),
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat',
									borderRadius: '4px'
								}}
							/>
						) : (
							<Box
								sx={{
									height: 50,
									width: 66.7,
									backgroundColor: 'grey.100',
									borderRadius: '4px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								<Icon fontSize="large" sx={{color: 'grey.300', mb: 1, ml: 0.5}}>
									<TagIcon />
								</Icon>
							</Box>
						)}
						<Typography flex={1}>{product.title}</Typography>
					</Box>
				</TableCell>
				<TableCell>
					<Status.Root>
						<Status.Tag type={status}>
							<Status.Label label={t(`status-tag-${status}`)} maxWidth="auto" />
						</Status.Tag>
					</Status.Root>
				</TableCell>
				<TableCell align="center">
					<Tooltip title={t('view-actions')} placement="bottom" arrow>
						<IconButton
							onClick={handleOpenActionList}
							size="small"
							aria-controls={openActionList ? t('actions') : undefined}
							aria-haspopup="true"
							aria-expanded={openActionList ? 'true' : undefined}
						>
							<DotsIcon />
						</IconButton>
					</Tooltip>
					<MenuList
						open={openActionList}
						anchorEl={anchorElActionList}
						onClose={handleCloseActionList}
						list={[
							{
								label: 'to-resend',
								onClick: () => resendIntegration(id)
							},
							{
								label: 'to-view-error-logs',
								onClick: () => handleOpenLogDialog(statusHistory)
							}
						]}
					/>
				</TableCell>
			</TableRow>
		</>
	)
}

const SaleIntegrationStatusHistory = ({
	open,
	onClose,
	name,
	statusHistory
}: {
	open: boolean
	onClose: () => void
	name: string
	statusHistory: {
		date: string
		status: string
		log?: string | undefined
	}[]
}) => {
	const {currentLang} = useAppStates()
	const {t} = useTranslation()

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>
					{t('integration-' + name) + ': ' + t('error-log')}
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body width={600}>
				{statusHistory.filter(statusHistory => statusHistory.log).length ===
				0 ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							margin: '0 auto',
							minHeight: '100px'
						}}
					>
						<Typography
							variant="body1"
							color="text.secondary"
							sx={{
								width: '100%',
								textAlign: 'center'
							}}
						>
							{t('no-error-logs')}
						</Typography>
					</Box>
				) : (
					<Box
						sx={{
							borderWidth: 1,
							borderStyle: 'solid',
							borderColor: 'divider',
							borderRadius: 1,
							maxWidth: '100%'
						}}
					>
						<TableContainer>
							<Table>
								<TableHead
									sx={theme => ({
										'&.MuiTableHead-root': {
											backgroundColor: theme.palette.grey[100]
										}
									})}
								>
									<TableRow>
										<TableCell>{t('date')}</TableCell>
										<TableCell>{t('log')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{statusHistory
										.filter(statusHistory => statusHistory.log)
										.map(({date: statusDate, log}) => (
											<TableRow
												key={statusDate}
												sx={{
													'&:last-child td, &:last-child th': {
														borderBottom: 0
													}
												}}
											>
												<TableCell sx={{verticalAlign: 'initial'}}>
													<Typography variant="body2" lineHeight="1.625rem">
														{date.convertISOToLocation(statusDate, currentLang)}
													</Typography>
													<Typography
														variant="caption"
														lineHeight="1.625rem"
														sx={{
															color: 'grey.600'
														}}
													>
														{date.getTime(statusDate, currentLang)}
													</Typography>
												</TableCell>
												<TableCell sx={{verticalAlign: 'initial'}}>
													<Box
														sx={theme => ({
															backgroundColor: theme.palette.grey[100],
															p: 1,
															borderRadius: '8px'
														})}
													>
														<pre style={{margin: 0}}>
															{JSON.stringify(JSON.parse(log || '{}'), null, 2)}
														</pre>
													</Box>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				)}
			</Dialog.Body>
		</Dialog.Root>
	)
}

function SaleDetailsPayment() {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()
	const {saleDetails} = useSaleStates()
	const [openSaleRefundDialog, setOpenSaleRefundDialog] = useState(false)
	const [copyLinkTooltip, setCopyLinkTooltip] = useState<string>(t('copy-link'))

	const handleCopyLink = useCallback(() => {
		if (saleDetails?.refund?.refundUrl) {
			navigator.clipboard.writeText(saleDetails.refund.refundUrl).then(() => {
				setCopyLinkTooltip(t('copied-link'))
			})
		}
	}, [saleDetails, t])

	return (
		<>
			<SaleRefundDialog
				open={openSaleRefundDialog}
				onClose={setOpenSaleRefundDialog.bind(null, false)}
			/>
			{saleDetails?.paymentMethod && (
				<Card
					variant="outlined"
					sx={theme => ({
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						flex: 1,
						minWidth: 350,
						[theme.breakpoints.down('md')]: {
							flex: 'none',
							minWidth: '100%'
						}
					})}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							gap: 2,
							justifyContent: 'space-between',
							alignItems: 'flex-start'
						}}
					>
						<Box>
							<Typography variant="subtitle2" sx={{mb: 0.5}}>
								{t('payment')}
							</Typography>
							<Status.Tag type={saleDetails.status} size="medium">
								<Status.Label
									label={t('status-tag-' + saleDetails.status)}
									maxWidth={130}
								/>
							</Status.Tag>
						</Box>
						{saleDetails?.status === 'paid' && (
							<Button
								variant="outlined"
								startIcon={<ArrowRightCurved />}
								onClick={setOpenSaleRefundDialog.bind(null, true)}
								size="small"
							>
								{t('to-refund')}
							</Button>
						)}
						{saleDetails?.status === 'awaiting-customer-data-for-refund' &&
							saleDetails?.refund?.refundUrl && (
								<Tooltip
									title={copyLinkTooltip}
									placement="bottom"
									arrow
									onClose={() => setCopyLinkTooltip(t('copy-link'))}
								>
									<Button
										variant="outlined"
										size="small"
										startIcon={<ClipboardIcon />}
										onClick={handleCopyLink}
									>
										{t('refund-url')}
									</Button>
								</Tooltip>
							)}
					</Box>
					<Box
						sx={(theme: Theme) => ({
							display: 'flex',
							flexDirection: 'column',
							rowGap: 0.5,
							[theme.breakpoints.down('sm')]: {
								rowGap: 2
							}
						})}
					>
						{saleDetails.paidAt && (
							<Box sx={styles.infoLine}>
								<Typography>{t('paid-at')}</Typography>
								<Typography color="textSecondary">
									{date.convertISOToLocation(
										saleDetails.paidAt,
										currentLang,
										true
									)}
								</Typography>
							</Box>
						)}
						<Box sx={styles.infoLine}>
							<Typography>{t('payment-method')}</Typography>
							<Typography color="textSecondary" sx={{textAlign: 'right'}}>
								{t(saleDetails.paymentMethod)}
								{saleDetails.paymentData?.installments && (
									<span> {saleDetails.paymentData.installments}x</span>
								)}
							</Typography>
						</Box>
						{saleDetails?.paymentService && (
							<Box sx={styles.infoLine}>
								<Typography>{t('payment-service')}</Typography>
								{saleDetails.paymentService === 'pagarme' ? (
									<PagarmeLogo height={24} />
								) : (
									<AsaasLogo height={20} />
								)}
							</Box>
						)}
					</Box>
				</Card>
			)}
		</>
	)
}

function SaleDetailsProducts() {
	const {t} = useTranslation()
	const {saleDetails} = useSaleStates()

	return (
		<>
			{saleDetails && (
				<Card variant="outlined" sx={{display: 'grid'}}>
					<Typography variant="subtitle2" p={2}>
						{t('experiences')}
					</Typography>
					<TableContainer component={Paper}>
						<Table sx={{minWidth: 500}}>
							<TableHead
								sx={theme => ({
									bgcolor:
										theme.palette.mode === 'light' ? 'grey.100' : 'grey.50',
									borderTop: '1px solid',
									borderTopColor: 'divider'
								})}
							>
								<TableRow>
									<TableCell
										sx={{
											textTransform: 'uppercase',
											color: 'text.secondary'
										}}
									>
										{t('experience')}
									</TableCell>
									<TableCell
										sx={{
											textTransform: 'uppercase',
											color: 'text.secondary'
										}}
									>
										{t('qtd')}
									</TableCell>
									<TableCell
										sx={{
											textTransform: 'uppercase',
											color: 'text.secondary'
										}}
									>
										{t('price')}
									</TableCell>
									<TableCell
										sx={{
											textTransform: 'uppercase',
											color: 'text.secondary'
										}}
									>
										{t('total')}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody
								sx={{
									'tr:last-child>td': {
										border: 0
									}
								}}
							>
								{saleDetails &&
									saleDetails?.items?.map((item, index) => (
										<TableRow key={'sale-details-product-' + item.id}>
											<TableCell>
												<Box
													sx={theme => ({
														display: 'flex',
														gap: 2,
														alignItems: 'center',
														[theme.breakpoints.down('sm')]: {
															flexDirection: 'column',
															alignItems: 'flex-start'
														}
													})}
												>
													{item?.cover ? (
														<Box
															sx={{
																height: 50,
																width: 66.7,
																backgroundColor: 'grey.100',
																...(item.cover
																	? {
																			backgroundImage: `url(${item.cover})`
																	  }
																	: {}),
																backgroundSize: 'cover',
																backgroundPosition: 'center',
																backgroundRepeat: 'no-repeat',
																borderRadius: '4px'
															}}
														/>
													) : (
														<Box
															sx={{
																height: 50,
																width: 66.7,
																backgroundColor: 'grey.100',
																borderRadius: '4px',
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center'
															}}
														>
															<Icon
																fontSize="large"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	color: 'grey.300'
																}}
															>
																<TagIcon fontSize={24} />
															</Icon>
														</Box>
													)}
													<Box>
														{index !== 0 && (
															<Tag
																sx={{
																	py: 0,
																	borderRadius: '8px'
																}}
															>
																{t('order-bump')}
															</Tag>
														)}
														<Typography flex={1}>{item.title}</Typography>
													</Box>
												</Box>
											</TableCell>
											<TableCell>
												<Typography>{item.quantity}</Typography>
											</TableCell>
											<TableCell>
												<Typography>{price.full(item.unitPrice)}</Typography>
											</TableCell>
											<TableCell>
												<Typography>
													{price.full(item.quantity * item.unitPrice)}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								{saleDetails?.items && saleDetails?.items?.length > 0 && (
									<>
										{saleDetails?.items?.length > 1 && (
											<TableRow>
												<TableCell colSpan={2} />
												<TableCell>
													<Typography>{t('subtotal')}</Typography>
												</TableCell>
												<TableCell>
													<Typography>
														{price.full(
															saleDetails?.items?.reduce(
																(acc, item) =>
																	acc + item.unitPrice * item.quantity,
																0
															)
														)}
													</Typography>
												</TableCell>
											</TableRow>
										)}
										{saleDetails.feesAmount && (
											<TableRow>
												<TableCell colSpan={2} />
												<TableCell>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center'
														}}
													>
														<Typography>{t('fees')}</Typography>
														<InfoPopover
															anchorOrigin={{
																vertical: 'top',
																horizontal: 'left'
															}}
															transformOrigin={{
																horizontal: 'left',
																vertical: 'bottom'
															}}
														>
															{saleDetails.paymentMethod === 'credit_card' &&
																saleDetails.paymentData
																	?.installmentFeePercentage && (
																	<Typography variant="body2">
																		{t('credit-card-fees-description')}
																	</Typography>
																)}
														</InfoPopover>
													</Box>
												</TableCell>
												<TableCell>
													<Typography>
														{price.full(saleDetails.feesAmount)}
													</Typography>
												</TableCell>
											</TableRow>
										)}
										<TableRow>
											<TableCell colSpan={2} />
											<TableCell>
												<Typography fontWeight={500}>{t('total')}</Typography>
											</TableCell>
											<TableCell>
												<Typography fontWeight={500}>
													{price.full(saleDetails.totalAmount)}
												</Typography>
											</TableCell>
										</TableRow>
									</>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Card>
			)}
		</>
	)
}

function SaleDetailsNotes() {
	const {saleDetails} = useSaleStates()
	const {t} = useTranslation()
	return (
		<Card
			variant="outlined"
			sx={theme => ({
				p: 2,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				gap: 2,
				flex: 1,
				[theme.breakpoints.down('sm')]: {
					width: '100%'
				}
			})}
		>
			<Box
				sx={{
					display: 'flex',
					gap: 1,
					alignItems: 'center',
					justifyContent: 'space-between',
					width: '100%'
				}}
			>
				<Typography variant="subtitle2">{t('notes')}</Typography>
				<ButtonIcon
					icon={<EditIcon />}
					onClick={() => {}}
					title={t('to-edit')}
				/>
			</Box>
			{saleDetails?.observations ? (
				<Typography>{saleDetails.observations}</Typography>
			) : (
				<Typography color="text.secondary">
					{t('notes-sale-placeholder')}
				</Typography>
			)}
		</Card>
	)
}

function SaleWebhookEvents({saleId}: {saleId: string}) {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()
	const {webhookEvents, setWebhookEvents, saleDetails} = useSaleStates()

	const [eventValues, setEventValues] = useState<SaleWebhookEvent | null>(null)
	const [offset, setOffset] = useState(0)
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [anchorElOptions, setAnchorElOptions] = useState<HTMLElement | null>(
		null
	)
	const openOptions = Boolean(anchorElOptions)
	const [openNewEventDialog, setOpenNewEventDialog] = useState(false)
	const [newEvent, setNewEvent] = useState<string>('SALE_UPDATED')
	const [openDetails, setOpenDetails] = useState(false)
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
	const [hasMore, setHasMore] = useState(false)
	const limit = 5

	const {mutate: mutateWebhookEvents} = useMutation(getSaleWebhookEvents, {
		networkMode: 'always'
	})

	const fetchWebhookEvents = useCallback(() => {
		setAlertMessage('')
		setOpenAlert(false)
		mutateWebhookEvents(
			{saleId, offset, limit},
			{
				onSuccess: ({data, responseStatusCode}) => {
					if (responseStatusCode === 200 && data) {
						if (offset === 0) {
							setWebhookEvents(data.events)
						} else {
							setWebhookEvents([...webhookEvents, ...data.events])
						}
						setOffset(offset + limit)
						setHasMore(data.hasMore)
					} else {
						setAlertMessage(t('error-loading-webhook-events'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-loading-webhook-events'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}, [
		mutateWebhookEvents,
		webhookEvents,
		setWebhookEvents,
		t,
		limit,
		offset,
		saleId
	])

	const {mutate: mutateDeleteEvent} = useMutation(deleteEvent, {
		networkMode: 'always'
	})

	const handleDeleteEvent = (eventId: string) => {
		setWebhookEvents(webhookEvents.filter(event => event.id !== eventId))
		mutateDeleteEvent(
			{id: eventId},
			{
				onSuccess: ({responseStatusCode, error}) => {
					if (responseStatusCode !== 200) {
						setWebhookEvents([...webhookEvents])
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}

	const {mutate: mutateResend, isLoading: isResending} = useMutation(
		resendEvent,
		{
			networkMode: 'always'
		}
	)

	const handleResendEvent = (eventId: string) => {
		mutateResend(
			{id: eventId},
			{
				onSuccess: ({responseStatusCode, error, data}) => {
					if (responseStatusCode === 200 && data) {
						setWebhookEvents(
							webhookEvents.map(event =>
								event.id === eventId ? {...event, ...data} : event
							)
						)
						setEventValues(prev => {
							if (prev) {
								return {...prev, ...data}
							}
							return prev
						})
					} else {
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}

	const {mutate: mutateDispatchEvent, isLoading: isSendingEvent} = useMutation(
		dispatchNewEvent,
		{
			networkMode: 'always'
		}
	)

	const handleNewEvent = useCallback(() => {
		mutateDispatchEvent(
			{
				saleId,
				productId: saleDetails?.offer.product.id as string,
				event: newEvent as 'SALE_CREATED' | 'SALE_UPDATED'
			},
			{
				onSuccess: ({responseStatusCode, error, data}) => {
					if (responseStatusCode === 200 && data) {
						setWebhookEvents([...webhookEvents, ...data.events])
						setOpenNewEventDialog(false)
					} else {
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}, [
		newEvent,
		saleDetails,
		saleId,
		setWebhookEvents,
		mutateDispatchEvent,
		webhookEvents,
		t
	])

	useEffect(() => {
		fetchWebhookEvents()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (webhookEvents.length === 0) {
		return null
	}

	return (
		<>
			<AlertDialog
				severity="error"
				open={openAlert}
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
			/>
			<LoadingDialog open={isResending} message={t('resending-event')} />
			<ConfirmDialog
				open={openDeleteDialog}
				contentMessage={t('delete-event-message')}
				confirmText={t('to-delete')}
				onConfirm={() => handleDeleteEvent(eventValues?.id || '')}
				onClose={() => setOpenDeleteDialog(false)}
				onCancel={() => setOpenDeleteDialog(false)}
			/>
			{eventValues && (
				<WebhooksLogsDetails
					open={openDetails}
					event={eventValues}
					onClose={() => setOpenDetails(false)}
					onResend={handleResendEvent}
					isResending={isResending}
				/>
			)}
			<Dialog.Root open={openNewEventDialog}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenNewEventDialog(false)}>
						{t('to-resend-webhook')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body>
					<Stack spacing={3}>
						<FormControl size="small" fullWidth>
							<InputLabel id="select-sale-event-label">{t('event')}</InputLabel>
							<Select
								labelId="select-sale-event-label"
								id="select-sale-event"
								name="event"
								variant="outlined"
								value={newEvent}
								label={t('event')}
								onChange={event => {
									setNewEvent(event.target.value as string)
								}}
							>
								<MenuItem value="SALE_UPDATED">{t('SALE_UPDATED')}</MenuItem>
								<MenuItem value="SALE_CREATED">{t('SALE_CREATED')}</MenuItem>
							</Select>
						</FormControl>
						<Button
							variant="contained"
							onClick={handleNewEvent}
							disabled={isSendingEvent}
						>
							{isSendingEvent ? (
								<CircularProgress size={24} color="inherit" />
							) : (
								t('send')
							)}
						</Button>
					</Stack>
				</Dialog.Body>
			</Dialog.Root>
			<MenuList
				open={openOptions}
				anchorEl={anchorElOptions}
				list={[
					{
						label: t('view-details'),
						onClick: () => {
							setOpenDetails(true)
						}
					},
					{
						label: t('to-resend'),
						onClick: () => {
							handleResendEvent(eventValues?.id || '')
						}
					},
					{
						label: t('to-delete'),
						onClick: () => {
							setOpenDeleteDialog(true)
						}
					}
				]}
				onClose={() => setAnchorElOptions(null)}
			/>
			<Box>
				<Typography variant="h6" component="h2" mb={2} mt={3}>
					{t('webhooks')}
				</Typography>
				<Card variant="outlined">
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							p: 2
						}}
					>
						<Typography variant="subtitle2">
							{t('dispatched-events')}
						</Typography>
						<Button
							variant="outlined"
							size="small"
							onClick={() => setOpenNewEventDialog(true)}
						>
							{t('to-resend-webhook')}
						</Button>
					</Box>
					<Box display="grid">
						<TableContainer>
							<Table sx={{minWidth: 700}} aria-label="simple table">
								<TableHead sx={styles.tableHead}>
									<TableRow>
										<TableCell sx={[styles.tableCell, {minWidth: 200}]}>
											{t('webhook')}
										</TableCell>
										<TableCell sx={[styles.tableCell, {minWidth: 200}]}>
											{t('event')}
										</TableCell>
										<TableCell sx={[styles.tableCell, {minWidth: 200}]}>
											{t('sent-at')}
										</TableCell>
										<TableCell sx={[styles.tableCell, {minWidth: 150}]}>
											{t('status')}
										</TableCell>
										<TableCell
											align="center"
											sx={[styles.tableCell, {minWidth: 80}]}
										>
											{t('actions')}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{webhookEvents.map(row => (
										<TableRow
											key={'webhook-' + row.id}
											sx={{
												'&:last-child td, &:last-child th': {border: 0}
											}}
										>
											<TableCell component="th" scope="row">
												{row.webhookName}
											</TableCell>
											<TableCell component="th" scope="row">
												{t(row.eventType)}
											</TableCell>
											<TableCell component="th" scope="row">
												{date.convertISOToLocation(
													row.sentAt,
													currentLang,
													true
												)}
											</TableCell>
											<TableCell align="right">
												<Status.Tag type={row.status}>
													<Status.Label label={t('status-tag-' + row.status)} />
												</Status.Tag>
											</TableCell>
											<TableCell align="center">
												<Tooltip
													title={t('view-actions')}
													placement="bottom"
													arrow
												>
													<IconButton
														onClick={(event: React.MouseEvent<HTMLElement>) => {
															setAnchorElOptions(event.currentTarget)
															setEventValues(row)
														}}
														size="small"
														aria-controls={
															openOptions ? t('actions') : undefined
														}
														aria-haspopup="true"
														aria-expanded={openOptions ? 'true' : undefined}
													>
														<DotsIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						{hasMore && (
							<Box
								sx={{
									borderTop: '1px solid',
									borderTopColor: 'divider'
								}}
							>
								<Button
									onClick={fetchWebhookEvents}
									disabled={isResending}
									sx={{width: '100%'}}
								>
									{t('load-more')}
								</Button>
							</Box>
						)}
					</Box>
				</Card>
			</Box>
		</>
	)
}

const styles = {
	infoLine: (theme: Theme) => ({
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		columnGap: 4,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start'
		}
	}),
	tableHead: (theme: Theme) => ({
		bgcolor: theme.palette.mode === 'light' ? 'grey.100' : 'grey.50',
		borderTop: '1px solid',
		borderTopColor: 'divider'
	}),
	tableCell: {
		textTransform: 'uppercase',
		color: 'text.secondary'
	}
}
