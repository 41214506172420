type CurrencyFormat = 'BRL' | 'USD'

const currencyFormat = {
	BRL: {
		symbol: 'R$',
		decimal: ',',
		thousands: '.',
		precision: 2
	},
	USD: {
		symbol: '$',
		decimal: '.',
		thousands: ',',
		precision: 2
	}
}

export const price = {
	abbreviate: (
		value: number = 0,
		format: CurrencyFormat = 'BRL',
		withoutFormat = false
	) => {
		const k = 1000
		const m = 1000000
		const b = 1000000000

		if (value >= b) {
			return (
				(withoutFormat ? '' : currencyFormat[format].symbol) +
				' ' +
				(value / b).toFixed(1).replace(/\.0$/, '') +
				' B'
			)
		}

		if (value >= m) {
			return (
				(withoutFormat ? '' : currencyFormat[format].symbol) +
				' ' +
				(value / m).toFixed(1).replace(/\.0$/, '') +
				' M'
			)
		}

		if (value >= k * 100) {
			return (
				(withoutFormat ? '' : currencyFormat[format].symbol) +
				' ' +
				(value / k).toFixed(1).replace(/\.0$/, '') +
				' K'
			)
		}

		return price.full(value, format, withoutFormat)
	},
	full: (
		cents: number = 0,
		format: CurrencyFormat = 'BRL',
		withoutFormat = false
	) => {
		const value = cents.toFixed(currencyFormat[format].precision)
		const [integer, decimal] = value.split('.')
		const regex = new RegExp(`\\B(?=(\\d{3})+(?!\\d))`, 'g')
		const thousands = currencyFormat[format].thousands
		const decimalSeparator = currencyFormat[format].decimal

		const formattedAmount = `${integer.replace(
			regex,
			thousands
		)}${decimalSeparator}${decimal}`

		if (withoutFormat) {
			return formattedAmount
		}

		return `${currencyFormat[format].symbol} ${formattedAmount}`
	},
	decimal: (amount: number = 0, format: CurrencyFormat = 'BRL') => {
		const value = amount.toFixed(currencyFormat[format].precision)
		const [integer, decimal] = value.split('.')
		const decimalSeparator = currencyFormat[format].decimal

		return `${integer}${decimalSeparator}${decimal}`
	}
}
