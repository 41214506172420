import {useState} from 'react'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {SxProps, Theme} from '@mui/material/styles'
import {Status} from '../../../components/Status'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'

interface OptionType {
	id: string
	status: string
	title: string
}

interface SelectProps {
	name: string
	label: string
	code?: string
	helperText?: React.ReactNode
	error?: boolean
	disabled?: boolean
	valueFormat: 'code' | 'label'
	value: OptionType | null
	options: OptionType[]
	sx?: SxProps<Theme>
	onChange: (data: OptionType | null) => void
}

export default function ExperiencesSelect({
	name,
	label,
	value,
	helperText,
	error,
	disabled,
	options,
	sx,
	onChange
}: SelectProps) {
	const {t} = useTranslation()

	const [inputValue, setInputValue] = useState(value?.title)

	const handleChange = (_: any, newValue: OptionType | null) => {
		onChange(newValue)
	}

	const getInputLabel = (option: OptionType) => {
		return option.title
	}

	return (
		<Autocomplete
			id={'select-' + name}
			fullWidth
			options={options}
			autoHighlight
			getOptionLabel={option => getInputLabel(option)}
			onChange={handleChange}
			disabled={disabled}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			value={value}
			inputValue={inputValue}
			onInputChange={(_, newInputValue) => {
				setInputValue(newInputValue)
			}}
			renderOption={(props, option) => (
				<Box
					{...props}
					key={'edition-select-option-' + option.id}
					component="li"
				>
					<Typography
						variant="body2"
						display="inline-flex"
						sx={{
							verticalAlign: 'middle',
							justifyContent: 'flex-start',
							alignItems: 'center',
							flexWrap: 'wrap',
							gap: 1,
							py: 1
						}}
					>
						<Status.Tag type={option.status} size="small">
							{t('status-tag-' + option.status)}
						</Status.Tag>
						<span>{option.title}</span>
					</Typography>
				</Box>
			)}
			renderInput={params => (
				<TextField
					{...params}
					size="small"
					name={name}
					label={label}
					helperText={helperText}
					error={error}
				/>
			)}
			sx={sx}
		/>
	)
}
