import {useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useMutation, useQuery} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import dayjs from 'dayjs'
import {FaHome as HomeIcon} from 'react-icons/fa'
import {MdModeEdit as EditIcon} from 'react-icons/md'
import {RiArrowDownSFill as ArrowDropDownIcon} from 'react-icons/ri'
import {FaRegClipboard as ClipboardIcon} from 'react-icons/fa6'
import {HiOutlineExternalLink as ExternalLinkIcon} from 'react-icons/hi'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import MuiCard from '@mui/material/Card'
import {Theme} from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

import useAppStates from '../../../stores/useAppStates'
import useNavigate from '../../../hooks/useNavigate'
import {changeEditionStatus, listEditionBySlug} from '../../../api/editions'
import {Edition} from '../../../entities/Edition'
import {regex} from '../../../helpers/regex'
import Link from '../../../components/navigation/Link'
import Footer from '../../../components/template/Footer'
import SectionTitle from '../../../components/typography/SectionTitle'
import AlertMessage from '../../../components/AlertMessage'
import {Status} from '../../../components/Status'
import EditionSales from './EditionSales'
import useAuthStates from '../../../stores/useAuthStates'
import {CHECKOUT_URL} from '../../../config'
import ButtonIcon from '../../../components/ButtonIcon'
import BackNavigation from '../../../components/navigation/BackNavigation'
import {DashboardFullSection} from '../../../components/template/DashboardFullSection'

const convertDateToLocale = (locale: string, date: string) => {
	if (locale === 'pt-BR' || locale === 'pt-br' || locale === 'pt') {
		dayjs.locale('pt-br')
		return dayjs(date).format('DD/MM/YYYY')
	}

	dayjs.locale('en')
	return dayjs(date).format('MM/DD/YYYY')
}

const ShowEdition = () => {
	const {state} = useLocation()
	const {t} = useTranslation()

	const currentLanguage = useAppStates(state => state.currentLang)
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [copyLinkTooltip, setCopyLinkTooltip] = useState<string>(
		t('copy-checkout-link')
	)
	const [edition, setEdition] = useState<Edition>(state?.edition)
	const [editionStatus, setEditionStatus] = useState<string>(
		edition?.status || ''
	)
	const [anchorElStatusList, setAnchorElStatusList] =
		useState<null | HTMLElement>(null)
	const [openStatusList, setOpenStatusList] = useState(false)
	const {profile, paymentService} = useAuthStates(state => state)
	const checkoutUrl = `${CHECKOUT_URL}/${profile?.nameSlug}/${edition?.experience.slug}/${edition?.slug}`
	const [checkoutDisabled, setCheckoutDisabled] = useState(
		!paymentService?.activeService ||
			edition?.status !== 'active' ||
			edition?.experience.status !== 'active'
	)
	const [checkoutLabel, setCheckoutLabel] = useState(() => {
		if (!paymentService?.activeService) {
			return t('waiting-payment-service')
		} else if (edition?.experience.status !== 'active') {
			return t('waiting-experience-activation')
		} else if (edition?.status !== 'active') {
			return t('waiting-edition-activation')
		}

		return t('open-checkout')
	})

	const handleCopyCheckoutLink = () => {
		navigator.clipboard.writeText(checkoutUrl).then(() => {
			setCopyLinkTooltip(t('copied-link'))
		})
	}

	const {experience_slug, edition_slug} = useParams<{
		experience_slug: string
		edition_slug: string
	}>()
	const navigate = useNavigate()

	const {isFetching} = useQuery(
		[`edition-${edition_slug}`],
		() =>
			listEditionBySlug({
				experienceSlug: experience_slug as string,
				slug: edition_slug as string
			}),
		{
			networkMode: 'always',
			enabled: !!edition_slug,
			refetchOnWindowFocus: false,
			onSuccess: ({data, responseStatusCode}) => {
				if (responseStatusCode === 200 && data) {
					setEdition(data)
					setCheckoutDisabled(
						!paymentService?.activeService ||
							data.status !== 'active' ||
							data.experience.status !== 'active'
					)
					setEditionStatus(data.status)
				} else if (responseStatusCode === 404 || responseStatusCode === 403) {
					navigate('/not-found')
				} else {
					navigate('/products', {
						state: {
							alertDialog: {
								severity: 'error',
								message: t('error-server-on-load-edition')
							}
						}
					})
				}
			},
			onError: error => {
				navigate('/products', {
					state: {
						alertDialog: {
							severity: 'error',
							message: t('error-server-on-load-edition')
						}
					}
				})
			}
		}
	)

	const handleNavigateToEdit = () => {
		navigate(`/products/${edition?.experience?.slug}/${edition?.slug}/edit`)
	}

	const {mutate} = useMutation(
		(values: {status: string; editionId: string; experienceId: string}) =>
			changeEditionStatus(values),
		{networkMode: 'always'}
	)

	const handleCloseStatusList = () => {
		setAnchorElStatusList(null)
	}

	const handleStatusChange = (status: string) => {
		handleCloseStatusList()
		setOpenAlert(false)
		const currentEditionStatus = editionStatus
		setEditionStatus(status)
		mutate(
			{
				status,
				editionId: edition?._id as string,
				experienceId: edition?.experience?._id as string
			},
			{
				onSuccess: ({data, responseStatusCode}) => {
					if (responseStatusCode === 200 && data) {
						setCheckoutDisabled(
							!paymentService?.activeService || data.status !== 'active'
						)
					} else {
						setEditionStatus(currentEditionStatus)
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: (error, data) => {
					setEditionStatus(currentEditionStatus)
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}

	useEffect(() => {
		if (edition) {
			setCheckoutDisabled(
				!paymentService?.activeService ||
					edition?.status !== 'active' ||
					edition?.experience.status !== 'active'
			)
			setCheckoutLabel(() => {
				if (!paymentService?.activeService) {
					return t('waiting-payment-service')
				} else if (edition?.experience.status !== 'active') {
					return t('waiting-experience-activation')
				} else if (editionStatus !== 'active') {
					return t('waiting-edition-activation')
				}

				return t('open-checkout')
			})
		}
	}, [edition, paymentService?.activeService, editionStatus, t])

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link to="/products">
						<span>{t('experiences')}</span>
					</Link>
					{isFetching ? (
						<Skeleton variant="rounded" height={12} width={240} />
					) : (
						[
							<Link
								key={`breadcrumb-link-${edition?.experience?.slug}`}
								to={`/products/${edition?.experience?.slug}`}
								state={{experience: edition?.experience}}
							>
								<span>{edition?.experience?.title}</span>
							</Link>,
							<span key={`breadcrumb-link-${edition?.slug}`}>
								{edition?.title}
							</span>
						]
					)}
				</Breadcrumbs>
				<BackNavigation
					to={`/products/${edition?.experience?.slug}`}
					fullWidth
				/>
			</Container>
			<DashboardFullSection>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							gap: 2
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
								gap: 2
							}}
						>
							<SectionTitle>
								{isFetching ? (
									<Skeleton width={300} />
								) : (
									<>
										{edition?.title}{' '}
										<ButtonIcon
											icon={<EditIcon size={18} />}
											title={t('to-edit')}
											onClick={handleNavigateToEdit}
										/>
									</>
								)}
							</SectionTitle>
						</Box>
						<Box sx={style.mainActionsBox}>
							{isFetching ? (
								<Skeleton
									sx={{
										height: 32,
										maxWidth: 300,
										width: '100%'
									}}
								/>
							) : (
								<>
									<Typography variant="body1">
										<Status.Root
											isButton
											open={openStatusList}
											setAnchorEl={setAnchorElStatusList}
											onClick={() => setOpenStatusList(!openStatusList)}
										>
											<Status.Tag type={editionStatus}>
												<Status.Label label={t(editionStatus)} />
												<Status.Icon>
													<ArrowDropDownIcon />
												</Status.Icon>
												<Status.List
													anchorEl={anchorElStatusList}
													open={openStatusList}
													acceptedStatus={[
														{label: t('to-active'), key: 'active'},
														{label: t('to-pause'), key: 'paused'},
														{label: t('to-archive'), key: 'archived'}
													]}
													handleClick={handleStatusChange}
													handleClose={handleCloseStatusList}
												/>
											</Status.Tag>
										</Status.Root>
									</Typography>
									<Link
										to={checkoutDisabled ? '#' : checkoutUrl}
										externalLink
										disabled={checkoutDisabled}
										target="_blank"
										sx={paymentService?.activeService && style.link}
									>
										<Tooltip title={checkoutLabel} placement="bottom" arrow>
											<Typography
												variant="subtitle2"
												sx={[
													style.checkoutButton,
													paymentService?.activeService
														? null
														: style.disabledButton
												]}
											>
												<ExternalLinkIcon
													size={20}
													style={{marginRight: '4px'}}
												/>
												{t('checkout')}
											</Typography>
										</Tooltip>
									</Link>
									<Tooltip
										title={
											paymentService?.activeService
												? copyLinkTooltip
												: t('waiting-payment-service')
										}
										placement="bottom"
										arrow
										onClose={() => setCopyLinkTooltip(t('copy-checkout-link'))}
									>
										<Typography
											variant="subtitle2"
											sx={[
												style.checkoutButton,
												paymentService?.activeService
													? null
													: style.disabledButton
											]}
											onClick={
												paymentService?.activeService && handleCopyCheckoutLink
											}
										>
											<ClipboardIcon size={17} style={{marginRight: '4px'}} />
											{t('copy-link')}
										</Typography>
									</Tooltip>
								</>
							)}
						</Box>
					</Box>
					<AlertMessage
						open={openAlert}
						severity="error"
						message={alertMessage}
						onClose={() => {
							setOpenAlert(false)
						}}
						mt={openAlert ? 2 : 0}
					/>
					<Box
						sx={theme => ({
							display: 'flex',
							alignItems: 'flex-start',
							justifyContent: 'space-between',
							my: 3,
							columnGap: 7.5,
							[theme.breakpoints.down('sm')]: {
								flexDirection: 'column'
							}
						})}
					>
						<Box flex={1}>
							<Typography variant="body1">
								{isFetching ? (
									<>
										<Skeleton width="90%" />
										<Skeleton width="80%" />
									</>
								) : (
									edition?.description
								)}
							</Typography>
							<Box sx={{my: 2}}>
								<Typography variant="h6" sx={{mb: 1}}>
									{t('details')}
								</Typography>
								<Box sx={style.detailsBox}>
									{edition?.startDate && (
										<Box>
											<Typography variant="subtitle1" fontWeight={500}>
												{isFetching ? <Skeleton width={80} /> : t('start-date')}
											</Typography>
											<Typography variant="body2">
												{isFetching ? (
													<>
														<Skeleton width={120} />
													</>
												) : (
													convertDateToLocale(
														currentLanguage,
														edition.startDate
													)
												)}
											</Typography>
										</Box>
									)}
									{edition?.limitDateToSell && (
										<Box>
											<Typography variant="subtitle1" fontWeight={500}>
												{isFetching ? (
													<Skeleton width={120} />
												) : (
													t('limit-date-to-sell')
												)}
											</Typography>
											<Typography variant="body2">
												{isFetching ? (
													<Skeleton width={100} />
												) : (
													convertDateToLocale(
														currentLanguage,
														edition.limitDateToSell
													)
												)}
											</Typography>
										</Box>
									)}
									<Box>
										<Typography variant="subtitle1" fontWeight={500}>
											{isFetching ? <Skeleton width={80} /> : t('price')}
										</Typography>
										<Typography variant="body2">
											{isFetching ? (
												<Skeleton width={120} />
											) : (
												'R$ ' +
													regex.formatBRLCurrency(
														Number.parseFloat(String(edition?.price)).toFixed(2)
													) || '0'
											)}
										</Typography>
									</Box>
									<Box>
										<Typography variant="subtitle1" fontWeight={500}>
											{isFetching ? <Skeleton width={100} /> : t('vacancies')}
										</Typography>
										<Typography variant="body2">
											{isFetching ? (
												<Skeleton width={80} />
											) : edition?.vacancies ? (
												<Box>
													<Typography variant="body2" sx={{mb: 0.5}}>
														{t('total') + ': ' + edition.vacancies}
													</Typography>
													<Typography variant="body2">
														{t('remaining') + ': ' + edition.remainingVacancies}
													</Typography>
												</Box>
											) : (
												<Typography variant="body2">
													{t('unlimited')}
												</Typography>
											)}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box display="grid">
							<Box
								className="hidden-scrollbar"
								sx={theme => ({
									display: 'flex',
									flexDirection: 'column',
									rowGap: 2,
									columnGap: 2,
									overflowX: 'auto',
									maxWidth: '100%',
									scrollSnapType: 'x mandatory',
									[theme.breakpoints.down('sm')]: {
										flexDirection: 'row',
										mt: 3
									}
								})}
							>
								<MuiCard
									variant="elevation"
									elevation={0}
									sx={[style.muiCard, {minWidth: 170}]}
								>
									<Typography variant="subtitle2">
										{t('total-revenue')}
									</Typography>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											flexGrow: 1,
											alignItems: 'center'
										}}
									>
										<Typography
											variant="h6"
											align="center"
											sx={theme => ({
												color: theme.palette.success.main,
												p: 1,
												whiteSpace: 'nowrap'
											})}
										>
											{isFetching ? (
												<Skeleton width={100} />
											) : (
												'R$ ' +
												regex.formatBRLCurrency(
													Number.parseFloat(
														String(edition?.sales?.revenue || 0)
													).toFixed(2)
												)
											)}
										</Typography>
									</Box>
								</MuiCard>
								<MuiCard
									variant="elevation"
									elevation={0}
									sx={[style.muiCard, {minWidth: 192}]}
								>
									<Typography variant="subtitle2">
										{t('total-spots-sold')}
									</Typography>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											flexGrow: 1,
											alignItems: 'center'
										}}
									>
										<Typography
											variant="h6"
											align="center"
											sx={{whiteSpace: 'nowrap', p: 1}}
										>
											{isFetching ? (
												<Skeleton width={50} />
											) : (
												edition?.sales?.spotsSold || 0
											)}
										</Typography>
									</Box>
								</MuiCard>
							</Box>
						</Box>
					</Box>
					{edition && <EditionSales edition={edition} />}
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

const style = {
	mainActionsBox: (theme: Theme) => ({
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: 2
	}),
	checkoutButton: (theme: Theme) => ({
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer'
	}),
	detailsBox: (theme: Theme) => ({
		display: 'grid',
		gap: 4,
		gridTemplateColumns: 'repeat(4, max-content)',
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: 'repeat(2, max-content)'
		}
	}),
	iconButton: (theme: Theme) => ({
		backgroundColor: theme.palette.primary.main,
		boxShadow:
			'0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			transition: 'backgroundColor 0.2s ease'
		}
	}),
	link: (theme: Theme) => ({
		color: theme.palette.text.primary,
		'&:hover': {
			color: theme.palette.primary.dark
		}
	}),
	disabledButton: (theme: Theme) => ({
		color: theme.palette.text.disabled,
		cursor: 'not-allowed'
	}),
	muiCard: (theme: Theme) => ({
		border: `1px solid ${theme.palette.grey[300]}`,
		display: 'flex',
		flexDirection: 'column',
		height: 80,
		py: 1,
		px: 1.75, // 14px
		width: '100%'
	})
}

export default ShowEdition
