import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {Box, IconButton, InputAdornment, Stack, TextField} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {
	MdOutlineVisibility as Visibility,
	MdOutlineVisibilityOff as VisibilityOff
} from 'react-icons/md'
import {updateEmail} from '../../../api/updateEmail'
import useUpdateEmailSchema from '../../../schemas/useUpdateEmailSchema'
import AlertMessage from '../../AlertMessage'

interface FormUpdateEmailProps {
	initialMessage?: string | null
	handleIsLoading: (isLoading: boolean) => void
	handleOnSuccess: () => void
	setNewEmail: (email: string) => void
	SubmitButton: () => JSX.Element
}

const FormUpdateEmail = ({
	initialMessage,
	handleIsLoading,
	handleOnSuccess,
	setNewEmail,
	SubmitButton
}: FormUpdateEmailProps) => {
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

	const {t} = useTranslation()

	const updateEmailSchema = useUpdateEmailSchema()

	const {mutate} = useMutation(
		(values: {newEmail: string; password: string}) => updateEmail(values),
		{
			networkMode: 'always'
		}
	)

	const formik = useFormik({
		initialValues: {
			newEmail: '',
			confirmEmail: '',
			password: ''
		},
		validationSchema: updateEmailSchema,
		onSubmit: values => {
			setOpenAlert(false)
			handleIsLoading(true)
			mutate(values, {
				onSuccess: result => {
					handleIsLoading(false)

					if (result.responseStatusCode === 200) {
						handleOnSuccess()
						setNewEmail(values.newEmail)
					}

					if (result.responseStatusCode === 400 && result.error?.fields) {
						Object.entries(result.error.fields).forEach(([key, value]) => {
							formik.setFieldError(key, value[0].message)
						})
					}

					if (result.responseStatusCode === 500) {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					handleIsLoading(false)
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			})
		}
	})

	useEffect(() => {
		if (initialMessage) {
			setAlertMessage(initialMessage)
			setOpenAlert(true)
		}
	}, [initialMessage])

	return (
		<Box
			component="form"
			autoComplete="off"
			sx={style.formBox}
			onSubmit={formik.handleSubmit}
		>
			<AlertMessage
				open={openAlert}
				severity="error"
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
				mt={'0px! important'}
				sx={{mb: openAlert ? 4 : 0}}
			/>
			<Stack direction="column" spacing={4}>
				<TextField
					name="newEmail"
					type="email"
					label={t('new-email')}
					value={formik.values.newEmail}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					helperText={formik.touched.newEmail && formik.errors.newEmail}
					error={formik.touched.newEmail && Boolean(formik.errors.newEmail)}
					variant="outlined"
					margin="normal"
					size="small"
					sx={{mt: 0}}
				/>
				<TextField
					name="confirmEmail"
					type="email"
					label={t('confirm-new-email')}
					value={formik.values.confirmEmail}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					helperText={
						formik.touched.confirmEmail && formik.errors.confirmEmail
							? formik.errors.confirmEmail
							: t('helper-email-confirm')
					}
					error={
						formik.touched.confirmEmail && Boolean(formik.errors.confirmEmail)
					}
					variant="outlined"
					margin="normal"
					size="small"
				/>
				<TextField
					name="password"
					type={passwordVisible ? 'text' : 'password'}
					label={t('enter-password')}
					value={formik.values.password}
					helperText={formik.touched.password && formik.errors.password}
					error={formik.touched.password && Boolean(formik.errors.password)}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					variant="outlined"
					margin="normal"
					size="small"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label={
										passwordVisible
											? t('aria-hide-password')
											: t('aria-show-password')
									}
									onClick={() => setPasswordVisible(!passwordVisible)}
									edge="end"
								>
									{passwordVisible ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
				<SubmitButton />
			</Stack>
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			p: 5,
			width: '100%',
			maxWidth: 600,
			[theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
				p: 3
			},
			[theme.breakpoints.down('sm')]: {
				p: 2
			}
		}
	}
}

export default FormUpdateEmail
