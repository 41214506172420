import {SELLER_API} from '../../config'
import {PaymentService} from '../../entities/PaymentService'
import {authenticatedFetch} from '../../helpers/authenticatedFetch'
import useAppStates from '../../stores/useAppStates'
import useAuthStates from '../../stores/useAuthStates'

interface Message {
	message: string
}

interface GetPaymentServiceResponse {
	success?: Message
	error?: Message
	data?: PaymentService
	responseStatusCode?: number
}

export const getPaymentService =
	async (): Promise<GetPaymentServiceResponse> => {
		const currentLang = useAppStates.getState().currentLang
		const accessToken = useAuthStates.getState().accessToken

		const response = await authenticatedFetch(`${SELLER_API}/payment-service`, {
			method: 'GET',
			headers: {
				'Accept-Language': currentLang,
				'Authorization': `Bearer ${accessToken}`
			}
		})

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	}

interface ActivePaymentServiceResponse {
	success?: Message
	error?: Message
	data?: PaymentService
	responseStatusCode?: number
}

export const activePaymentService = async (values: {
	service: string
}): Promise<ActivePaymentServiceResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${SELLER_API}/payment-service`, {
		method: 'POST',
		headers: {
			'Accept-Language': currentLang,
			'Authorization': `Bearer ${accessToken}`
		},
		body: JSON.stringify(values)
	})

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface UpdatePaymentServiceActiveResponse {
	success?: Message
	error?: Message
	responseStatusCode?: number
}

export const updatePaymentServiceActive = async (values: {
	service: string
}): Promise<UpdatePaymentServiceActiveResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${SELLER_API}/payment-service`, {
		method: 'PUT',
		headers: {
			'Accept-Language': currentLang,
			'Authorization': `Bearer ${accessToken}`
		},
		body: JSON.stringify(values)
	})

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}
