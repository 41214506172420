import {CHECKOUT_PAGARME_API, DEFAULT_LANG} from '../config'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'

interface Message {
	message: string
}

export interface CreditCardFeesCalculatorData {
	feesForRecipient: {
		platformFees: {
			variableFee: {
				percentage: string
				totalAmount: string
			}
			fixedFee: {
				processingFee: string
				antiFraudFee: string
				totalAmount: string
			}
		}
		mdrFees: {
			installments: number
			percentage: string
			totalAmount: string
		}
		totalFeesAmount: string
		grossAmount: string
		netAmount: string
	}
	feesForBuyer: {
		platformFees: {
			variableFee: {
				percentage: string
				totalAmount: string
			}
			fixedFee: {
				processingFee: string
				antiFraudFee: string
				totalAmount: string
			}
		}
		mdrFees: {
			installments: number
			percentage: string
			totalAmount: string
		}
		totalFeesAmount: string
		grossAmount: string
		netAmount: string
	}
}

export interface CreditCardFeesCalculatorResponse {
	responseStatusCode: number
	data?: CreditCardFeesCalculatorData
	error?: {
		message: string
		fields?: {
			amount?: Message[]
			installments?: Message[]
		}
	}
}

export const calculateCreditCardPagarmeFees = async ({
	amount,
	installments
}: {
	amount: number
	installments: number
}): Promise<CreditCardFeesCalculatorResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/fees-calculator/credit-card`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				amount,
				installments
			})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

export interface BoletoFeesCalculatorData {
	feesForRecipient: {
		platformFees: {
			variableFee: {
				percentage: string
				totalAmount: string
			}
		}
		boletoFeeAmount: string
		totalFeesAmount: string
		grossAmount: string
		netAmount: string
	}
	feesForBuyer: {
		platformFees: {
			variableFee: {
				percentage: string
				totalAmount: string
			}
		}
		boletoFeeAmount: string
		totalFeesAmount: string
		grossAmount: string
		netAmount: string
	}
}

export interface BoletoFeesCalculatorResponse {
	responseStatusCode: number
	data?: BoletoFeesCalculatorData
	error?: {
		message: string
		fields?: {
			amount?: Message[]
		}
	}
}

export const calculateBoletoPagarmeFees = async ({
	amount
}: {
	amount: number
}): Promise<BoletoFeesCalculatorResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/fees-calculator/boleto`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				amount
			})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

export interface PixFeesCalculatorData {
	feesForRecipient: {
		platformFees: {
			variableFee: {
				percentage: string
				totalAmount: string
			}
		}
		pixFees: {
			percentage: string
			totalAmount: string
		}
		totalFeesAmount: string
		grossAmount: string
		netAmount: string
	}
	feesForBuyer: {
		platformFees: {
			variableFee: {
				percentage: string
				totalAmount: string
			}
		}
		pixFees: {
			percentage: string
			totalAmount: string
		}
		totalFeesAmount: string
		grossAmount: string
		netAmount: string
	}
}

export interface PixFeesCalculatorResponse {
	responseStatusCode: number
	data?: PixFeesCalculatorData
	error?: {
		message: string
		fields?: {
			amount?: Message[]
		}
	}
}

export const calculatePixPagarmeFees = async ({
	amount
}: {
	amount: number
}): Promise<PixFeesCalculatorResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${CHECKOUT_PAGARME_API}/fees-calculator/pix`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				amount
			})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
