import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'
import {DEFAULT_LANG, SELLER_API} from '../config'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import {companyMapper} from './mappers/companyMapper'
import {RawProfile} from './entities/RawProfile'

interface Message {
	message: string
}

interface CreateCompanyResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			cnpj?: Message[]
			legalName?: Message[]
			fantasyName?: Message[]
			email?: Message[]
			phone?: Message[]
			address?: Message[]
			district?: Message[]
			city?: Message[]
			state?: Message[]
			zipCode?: Message[]
		}
	}
	responseStatusCode?: number
}

export const createCompany = async (rawValues: {
	cnpj: string
	legalName: string
	fantasyName: string
	email: string
	phone: string
	address: string
	streetNumber: number
	complementaryAddress: string
	referencePoint: string
	district: string
	city: string
	state: string
	zipCode: string
}): Promise<CreateCompanyResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const values = companyMapper.domainToApi(rawValues)

	const response = await authenticatedFetch(`${SELLER_API}/company`, {
		method: 'POST',
		body: JSON.stringify(values),
		// credentials: 'include',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		}
	})
	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CreateProfileResponse {
	success?: {
		message: string
	}
	error?: {
		fields?: {
			name?: Message[]
			nameSlug?: Message[]
			logoLight?: Message[]
			logoDark?: Message[]
			avatar?: Message[]
		}
		message: string
	}
	data?: RawProfile
	responseStatusCode?: number
}

export const createProfile = async ({
	name,
	nameSlug,
	primaryColor,
	logoLight,
	logoDark,
	avatar
}: {
	name: string
	nameSlug: string
	primaryColor: string
	logoLight: File | null
	logoDark: File | null
	avatar: File | null
}): Promise<CreateProfileResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const formData = new FormData()
	formData.append('name', name)
	formData.append('nameSlug', nameSlug)
	formData.append('primaryColor', primaryColor)
	if (logoLight) formData.append('logoLight', logoLight)
	if (logoDark) formData.append('logoDark', logoDark)
	if (avatar) formData.append('avatar', avatar)

	const response = await authenticatedFetch(`${SELLER_API}/profile`, {
		method: 'POST',
		body: formData,
		// credentials: 'include',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		}
	})
	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
