import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import {SxProps, Theme} from '@mui/material/styles'
import React from 'react'

interface Props {
	sx?: SxProps<Theme> | undefined
	children?: React.ReactNode
}

const SectionTitle = styled(({children, sx}: Props) => (
	<Typography variant="h4" component="h2" sx={sx} minHeight={35}>
		{children}
	</Typography>
))({
	mt: 2,
	mb: 2
})

export default SectionTitle
