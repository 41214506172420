import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import {price as priceHelper} from '../../helpers/price'
import {BiSolidPurchaseTag as TagIcon} from 'react-icons/bi'

export const PreviewOrderBump = ({
	title,
	description,
	orderBump
}: {
	title?: string
	description?: string
	orderBump: {
		id: string
		title: string
		cover?: string
		offer: {
			id: string
			title: string
			unitPrice: number
		}
	}[]
}) => {
	const {t} = useTranslation()

	return (
		<Box
			sx={{
				borderWidth: 1,
				borderStyle: 'dashed',
				borderColor: 'divider',
				backgroundColor: 'background.paper',
				width: '100%'
			}}
		>
			<Box sx={{px: 2, py: 1}}>
				<Typography variant="subtitle1" fontWeight={500} mb={0.5}>
					{title || t('order-bump-title')}
				</Typography>
				<Typography color={!description ? 'text.secondary' : undefined}>
					{description || t('order-bump-description')}
				</Typography>
			</Box>
			{orderBump.length === 0 && (
				<PreviewProduct title={t('product-name')} price={0} />
			)}
			{orderBump.map(product => (
				<PreviewProduct
					title={product.title}
					price={product.offer.unitPrice}
					cover={product.cover}
				/>
			))}
		</Box>
	)
}

const PreviewProduct = ({
	cover,
	price,
	title
}: {
	cover?: string
	price: number
	title: string
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start',
				borderTop: '1px solid',
				borderTopColor: 'divider',
				mx: 2,
				mt: 1,
				py: 2
			}}
		>
			<Checkbox
				sx={{
					color: 'primary.main',
					ml: '-12px',
					'& .MuiSvgIcon-root': {fontSize: 28}
				}}
			/>
			<Box
				sx={{
					height: 50,
					width: 66.7
				}}
			>
				{cover ? (
					<Box
						sx={{
							backgroundImage: `url(${cover})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							height: 50,
							width: 66.7,
							borderRadius: '4px'
						}}
					/>
				) : (
					<Box
						sx={theme => ({
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: 50,
							width: 66.7,
							backgroundColor: theme.palette.grey[100],
							border: '1px solid',
							borderColor: 'divider',
							borderRadius: '4px'
						})}
					>
						<Icon
							sx={theme => ({
								color: theme.palette.grey[400],
								ml: 0.5,
								mb: 0.5
							})}
							fontSize="large"
						>
							<TagIcon />
						</Icon>
					</Box>
				)}
			</Box>
			<Box
				sx={{
					ml: 2
				}}
			>
				<Typography variant="subtitle2">{title}</Typography>
				<Typography variant="body2" fontWeight={500}>
					<Typography variant="caption">R$ </Typography>
					{priceHelper.full(price, 'BRL', true)}
				</Typography>
			</Box>
		</Box>
	)
}
