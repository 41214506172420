import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {MdArrowBackIosNew as ArrowBackIcon} from 'react-icons/md'
import Link from './Link'
import {SxProps, Theme} from '@mui/material/styles'

interface BackNavigationProps {
	to: string
	title?: string
	fullWidth?: boolean
	sx?: SxProps<Theme>
}

const BackNavigation = ({
	to,
	title,
	sx,
	fullWidth = false
}: BackNavigationProps) => {
	const {t} = useTranslation()

	return (
		<Box ml="-2px" sx={sx}>
			<Link
				to={to}
				sx={theme => ({
					color: theme.palette.grey['600'],
					'&:hover': {
						color: theme.palette.primary.main
					},
					display: 'flex',
					alignItems: 'center',
					gap: 0.5,
					mx: 0,
					px: 0,
					cursor: 'pointer',
					width: '100%',
					[theme.breakpoints.down('md')]: {
						width: '100%',
						px: 0
					},
					[theme.breakpoints.down('sm')]: {
						px: 0
					}
				})}
			>
				<ArrowBackIcon />
				<Typography>{title || t('to-back')}</Typography>
			</Link>
		</Box>
	)
}

export default BackNavigation
