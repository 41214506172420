import {useMemo, useState} from 'react'
import usePaymentServicePagarmeStates from '../../../../stores/usePaymentServicePagarmeStates'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {Status} from '../../../../components/Status'
import Button from '@mui/material/Button'
import {price} from '../../../../helpers/price'
import {useTranslation} from 'react-i18next'
import {Dialog} from '../../../../components/Dialog'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import {date} from '../../../../helpers/date'
import useAppStates from '../../../../stores/useAppStates'
import TableHead from '@mui/material/TableHead'
import InfoPopover from '../../../../components/InfoPopover'
import {banksCode} from '../../../../components/BankSelect'
import ButtonLink from '../../../../components/navigation/ButtonLink'

export const TransferHistoryCard = () => {
	const {t} = useTranslation()
	const {transfers} = usePaymentServicePagarmeStates(state => state)
	const {currentLang} = useAppStates()

	const lastTransfer = useMemo(() => {
		if (transfers.length === 0) {
			return null
		}

		return transfers[0]
	}, [transfers])

	const [openHistoryDialog, setOpenHistoryDialog] = useState(false)

	return (
		<>
			<Dialog.Root open={openHistoryDialog} maxWidth={600}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenHistoryDialog(false)}>
						{t('last-transfers')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body
					sx={{
						p: 0,
						m: '0px !important',
						width: '100% !important',
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<Box display="grid">
						<TableContainer>
							<Table sx={{minWidth: '600px'}}>
								<TableHead>
									<TableRow>
										<TableCell sx={{pl: 3}}>{t('date')}</TableCell>
										<TableCell>{t('requested-amount')}</TableCell>
										<TableCell>{t('beneficiary')}</TableCell>
										<TableCell sx={{pr: 3}}>{t('status')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{transfers.map(transfer => (
										<TableRow key={transfer.id}>
											<TableCell sx={{pl: 3}}>
												<Typography variant="body2">
													{date.convertISOToLocation(
														transfer.createdAt,
														currentLang
													)}
												</Typography>
												<Typography variant="caption" color="grey.700">
													{date.getTime(transfer.createdAt, currentLang)}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2">
													{price.full(transfer.amount)}
												</Typography>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center'
													}}
												>
													<Typography variant="caption" color="grey.700">
														{t('fee')}: {price.full(transfer.feeAmount)}
													</Typography>
													<InfoPopover
														sx={{
															'>button': {
																p: 0,
																justifyContent: 'flex-start',
																ml: 0.5,
																mt: '-2px',
																width: '12px'
															}
														}}
														transformOrigin={{
															vertical: 'top',
															horizontal: 'left'
														}}
														anchorOrigin={{
															vertical: 20,
															horizontal: 'left'
														}}
													>
														{t('info-ted-transfer-fee')}
													</InfoPopover>
												</Box>
											</TableCell>
											<TableCell>
												<Typography variant="body2">
													{
														banksCode.find(
															bankCode => transfer.bankCode === bankCode.code
														)?.label
													}
												</Typography>
												<Typography variant="caption" color="grey.700">
													{transfer.branchNumber}
													{transfer.branchCheckDigit
														? `-${transfer.branchCheckDigit}`
														: ''}{' '}
													/ {transfer.accountNumber}-
													{transfer.accountCheckDigit}
												</Typography>
											</TableCell>
											<TableCell>
												<Status.Tag type={transfer.status}>
													<Status.Label
														label={t('status-tag-' + transfer.status)}
														maxWidth="auto"
														showTooltip={false}
													/>
												</Status.Tag>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					{transfers.length > 5 && (
						<ButtonLink to="/payment-services/pagarme-transfers">
							{t('to-view-transfer-history')}
						</ButtonLink>
					)}
				</Dialog.Body>
			</Dialog.Root>
			{lastTransfer && (
				<Card
					variant="outlined"
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						p: 1.75, // 14px
						width: 'max-content',
						minWidth: 200,
						gap: 1,
						justifyContent: 'space-between'
					}}
				>
					<Typography variant="subtitle2">{t('last-transfer')}</Typography>
					<Box>
						<Typography variant="body2">
							{t('amount')}: {price.full(lastTransfer.amount)}
						</Typography>
						<Typography
							variant="body2"
							sx={{
								display: 'inline-flex',
								alignItems: 'center',
								gap: '6px',
								mt: '8px'
							}}
						>
							{t('status')}:{' '}
							<Status.Tag type={lastTransfer.status}>
								<Status.Label
									label={t(`status-tag-${lastTransfer.status}`)}
									maxWidth="auto"
									showTooltip={false}
								/>
							</Status.Tag>
						</Typography>
					</Box>
					<span>
						<Button
							variant="outlined"
							sx={{mt: 1}}
							onClick={() => setOpenHistoryDialog(true)}
						>
							{t('to-view-more-details')}
						</Button>
					</span>
				</Card>
			)}
		</>
	)
}
