import {createTheme, responsiveFontSizes} from '@mui/material/styles'

// Theme dark typography and palette
let theme = createTheme({
	breakpoints: {
		values: {
			xs: 375,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1920
		}
	},
	typography: {
		fontFamily: [
			'-apple-system',
			'system-ui',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'Oxygen',
			'Ubuntu',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		subtitle1: {
			fontWeight: 500
		}
	},
	palette: {
		mode: 'dark',
		primary: {
			main: '#F1BB0C',
			light: '#F1BB0C',
			dark: '#e8b308',
			contrastText: '#ffffff'
		},
		secondary: {
			main: '#159CAB'
		},
		common: {
			black: '#FFF',
			white: '#000'
		},
		grey: {
			'50': '#212121',
			'100': '#424242',
			'200': '#616161',
			'300': '#757575',
			'400': '#9e9e9e',
			'500': '#bdbdbd',
			'600': '#e0e0e0',
			'700': '#eeeeee',
			'800': '#f5f5f5',
			'900': '#fafafa'
		}
	}
})

// Theme dark components
theme = createTheme(theme, {
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: '#000000fc',
					boxShadow: 'none',
					borderBottomColor: theme.palette.grey[50],
					borderBottomStyle: 'solid',
					borderBottomWidth: 1,
					zIndex: theme.zIndex.drawer + 1
				}
			}
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					fontSize: '0.9rem',
					padding: '20px 0',
					color: theme.palette.grey[600],
					'& a': {
						color: theme.palette.grey[500]
					},
					'& a:hover': {
						color: theme.palette.grey[900]
					}
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				root: {
					zIndex: theme.zIndex.drawer + 2
				}
			}
		},
		MuiButton: {
			defaultProps: {
				disableRipple: true
			},
			styleOverrides: {
				root: {
					borderRadius: 4,
					boxShadow: 'none'
				}
			},
			variants: [
				{
					props: {variant: 'contained'},
					style: {
						color: theme.palette.grey[50],
						'&:hover': {
							boxShadow: 'none'
						}
					}
				},
				{
					props: {variant: 'outlined'},
					style: {
						borderColor: theme.palette.grey[300],
						color: theme.palette.grey[800],
						'&:hover': {
							color: theme.palette.grey[900]
						}
					}
				}
			]
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: theme.palette.primary.dark,
					textDecoration: 'none'
				}
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					marginRight: 16,
					marginLeft: 4,
					minWidth: 'inherit'
				}
			}
		},
		MuiListItemText: {
			styleOverrides: {
				root: {
					fontSize: '0.8rem'
				}
			}
		}
	}
})

export const dark = responsiveFontSizes(theme)
