import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {regex} from '../helpers/regex'
import {validateCPF} from '../helpers/cpf'
import {date} from '../helpers/date'

const useUpdateCompanyManagingPartner = () => {
	const {t} = useTranslation()

	const updateCompanyManagingPartnerSchema = yup.object({
		name: yup
			.string()
			.required(t('error-name-required'))
			.min(3, t('error-name-min-length'))
			.max(100, t('error-name-max-length')),
		email: yup
			.string()
			.required(t('error-email-required'))
			.email(t('error-invalid-email')),
		birthdate: yup
			.date()
			.nullable()
			.typeError(t('error-date-invalid'))
			.test('is-valid', t('error-date-invalid'), value => {
				if (value) {
					const dateToValidate = new Date(value).toString()
					return date.isValid(dateToValidate)
				}

				return true
			}),
		cpf: yup
			.string()
			.required(t('error-cpf-required'))
			.transform(value => regex.removeAllNonDigits(value))
			.matches(/^\d{11}$/, t('error-cpf-invalid'))
			.test('validateCPF', t('error-cpf-invalid'), value => validateCPF(value)),
		monthlyIncome: yup
			.string()
			.transform(value => String(regex.parseBRLCurrencyToFloat(value)))
			.required(t('error-monthly-income-required')),
		professionalOccupation: yup
			.string()
			.required(t('error-professional-occupation-required'))
			.min(
				3,
				t('error-professional-occupation-min-length', {
					minLength: 3
				})
			)
			.max(
				100,
				t('error-professional-occupation-max-length', {
					maxLength: 100
				})
			),
		phoneType: yup
			.string()
			.required(t('error-phone-type-required'))
			.oneOf(['mobile', 'landline'], t('error-phone-type-required')),
		phone: yup.string().when('phoneType', phoneType => {
			if (phoneType === 'phone') {
				return yup
					.string()
					.transform(value => regex.removeAllNonDigits(value))
					.required(t('error-phone-required'))
					.matches(/^\d{11}$/, t('error-mobile-invalid'))
			} else if (phoneType === 'landline') {
				return yup
					.string()
					.transform(value => regex.removeAllNonDigits(value))
					.required(t('error-phone-required'))
					.matches(/^\d{10,11}$/, t('error-landline-invalid'))
			}

			return yup.string().required(t('error-phone-required'))
		}),
		street: yup
			.string()
			.required(t('error-street-required'))
			.min(3, t('error-street-min-length'))
			.max(255, t('error-street-max-length')),
		streetNumber: yup.string().required(t('error-street-number-required')),
		complementaryAddress: yup
			.string()
			.notRequired()
			.max(
				255,
				t('error-complementary-address-max-length', {
					maxLength: 255
				})
			),
		referencePoint: yup
			.string()
			.notRequired()
			.max(
				255,
				t('error-reference-point-max-length', {
					maxLength: 255
				})
			),
		district: yup
			.string()
			.required(t('error-district-required'))
			.min(3, t('error-district-min-length'))
			.max(60, t('error-district-max-length')),
		city: yup
			.string()
			.required(t('error-city-required'))
			.min(3, t('error-city-min-length'))
			.max(60, t('error-city-max-length')),
		state: yup
			.string()
			.required(t('error-state-required'))
			.matches(/^[A-Z]{2,2}$/, t('error-state-invalid')),
		zipCode: yup
			.string()
			.required(t('error-zip-code-required'))
			.transform(value => regex.removeAllNonDigits(value))
			.matches(/^\d{8}$/, t('error-zip-code-invalid'))
	})

	return updateCompanyManagingPartnerSchema
}

export default useUpdateCompanyManagingPartner
