import Box from '@mui/material/Box'
import AlertMessage from './AlertMessage'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {useTranslation} from 'react-i18next'
import {regex} from '../helpers/regex'
import useFeesCalculatorSchema from '../schemas/useFeesCalculatorSchema'
import {price} from '../helpers/price'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {
	BoletoFeesCalculatorData,
	CreditCardFeesCalculatorData,
	PixFeesCalculatorData,
	calculateBoletoPagarmeFees,
	calculateCreditCardPagarmeFees,
	calculatePixPagarmeFees
} from '../api/feesCalculator'
import Stack from '@mui/material/Stack'

interface FormFeesCalculatorProps {
	paymentMethod: 'credit-card' | 'boleto' | 'pix'
	amount: number
	installments: number
	setPaymentMethod: (paymentMethod: 'credit-card' | 'boleto' | 'pix') => void
	onChange?: () => void
	onSuccess: (
		data:
			| CreditCardFeesCalculatorData
			| BoletoFeesCalculatorData
			| PixFeesCalculatorData
	) => void
}

const FormFeesCalculator = ({
	paymentMethod,
	amount,
	installments,
	setPaymentMethod,
	onChange,
	onSuccess
}: FormFeesCalculatorProps) => {
	const {t} = useTranslation()
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertMessage, setAlertMessage] = useState<string>('')

	const {isLoading: isLoadingCreditCardFees, mutate: mutateCreditCardFees} =
		useMutation(calculateCreditCardPagarmeFees, {
			networkMode: 'always'
		})
	const {isLoading: isLoadingBoletoFees, mutate: mutateBoletoFees} =
		useMutation(calculateBoletoPagarmeFees, {
			networkMode: 'always'
		})
	const {isLoading: isLoadingPixFees, mutate: mutatePixFees} = useMutation(
		calculatePixPagarmeFees,
		{
			networkMode: 'always'
		}
	)

	const handleOnChange = () => {
		if (onChange) {
			onChange()
		}
	}

	const feesCalculatorSchema = useFeesCalculatorSchema()

	const formik = useFormik({
		initialValues: {
			paymentMethod: paymentMethod,
			amount: price.decimal(amount),
			installments: installments.toString()
		},
		enableReinitialize: true,
		validationSchema: feesCalculatorSchema,
		onSubmit: values => {
			setOpenAlert(false)
			if (values.paymentMethod === 'credit-card') {
				mutateCreditCardFees(
					{
						amount: regex.parseBRLCurrencyToFloat(String(values.amount || 0)),
						installments: Number(values.installments)
					},
					{
						onSuccess: ({data, responseStatusCode, error}) => {
							if (responseStatusCode === 200 && data) {
								onSuccess(data)
							} else if (responseStatusCode === 400 && error?.fields) {
								Object.entries(error.fields).forEach(([key, value]) => {
									formik.setFieldError(key, value[0].message)
								})
							} else {
								setAlertMessage(t('error-server-default'))
								setOpenAlert(true)
							}
						},
						onError: error => {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
							console.error(error)
						}
					}
				)
			} else if (values.paymentMethod === 'boleto') {
				mutateBoletoFees(
					{
						amount: regex.parseBRLCurrencyToFloat(String(values.amount || 0))
					},
					{
						onSuccess: ({data, responseStatusCode, error}) => {
							if (responseStatusCode === 200 && data) {
								onSuccess(data)
							} else if (responseStatusCode === 400 && error?.fields) {
								Object.entries(error.fields).forEach(([key, value]) => {
									formik.setFieldError(key, value[0].message)
								})
							} else {
								setAlertMessage(t('error-server-default'))
								setOpenAlert(true)
							}
						},
						onError: error => {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
							console.error(error)
						}
					}
				)
			} else if (values.paymentMethod === 'pix') {
				mutatePixFees(
					{
						amount: regex.parseBRLCurrencyToFloat(String(values.amount || 0))
					},
					{
						onSuccess: ({data, responseStatusCode, error}) => {
							if (responseStatusCode === 200 && data) {
								onSuccess(data)
							} else if (responseStatusCode === 400 && error?.fields) {
								Object.entries(error.fields).forEach(([key, value]) => {
									formik.setFieldError(key, value[0].message)
								})
							} else {
								setAlertMessage(t('error-server-default'))
								setOpenAlert(true)
							}
						},
						onError: error => {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
							console.error(error)
						}
					}
				)
			}
		}
	})

	return (
		<Box
			component="form"
			sx={theme => ({
				display: 'flex',
				gap: 3,
				flexDirection: 'column',
				[theme.breakpoints.down('sm')]: {
					gap: 4
				}
			})}
			onSubmit={formik.handleSubmit}
			onChange={onChange}
		>
			{openAlert ? (
				<AlertMessage
					open={openAlert}
					severity="error"
					message={alertMessage}
					onClose={() => setOpenAlert(false)}
					mt={'0px! important'}
					sx={{mb: openAlert ? 2 : 0}}
				/>
			) : null}
			<Stack spacing={3}>
				<FormControl
					size="small"
					sx={{
						minWidth: 232
					}}
				>
					<InputLabel id="select-payment-method-label">
						{t('payment-method')}
					</InputLabel>
					<Select
						labelId="select-payment-method-label"
						id="select-payment-method"
						name="paymentMethod"
						variant="outlined"
						error={
							formik.touched.paymentMethod &&
							Boolean(formik.errors.paymentMethod)
						}
						value={formik.values.paymentMethod}
						label={t('payment-method')}
						onChange={event => {
							handleOnChange()
							setPaymentMethod(
								event.target.value as 'credit-card' | 'boleto' | 'pix'
							)
							formik.handleChange(event)
						}}
					>
						<MenuItem value="credit-card">{t('credit-card')}</MenuItem>
						<MenuItem value="pix">{t('pix')}</MenuItem>
						<MenuItem value="boleto">{t('boleto')}</MenuItem>
					</Select>
				</FormControl>
				<TextField
					name="amount"
					label={t('sale-amount')}
					value={formik.values.amount}
					onChange={event => {
						event.target.value = regex.formatBRLCurrency(event.target.value)
						formik.handleChange(event)
						handleOnChange()
					}}
					onBlur={formik.handleBlur}
					helperText={formik.touched.amount && formik.errors.amount}
					error={formik.touched.amount && Boolean(formik.errors.amount)}
					variant="outlined"
					size="small"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">{'R$'}</InputAdornment>
						)
					}}
				/>
				{formik.values.paymentMethod === 'credit-card' ? (
					<FormControl
						size="small"
						sx={{
							minWidth: 232
						}}
					>
						<InputLabel id="select-installments-label">
							{t('installments-quantity')}
						</InputLabel>
						<Select
							labelId="select-installments-label"
							id="select-installments"
							name="installments"
							variant="outlined"
							error={
								formik.touched.installments &&
								Boolean(formik.errors.installments)
							}
							value={formik.values.installments}
							label={t('installments-quantity')}
							onChange={event => {
								formik.handleChange(event)
								handleOnChange()
							}}
						>
							<MenuItem value="1">1x (à vista)</MenuItem>
							<MenuItem value="2">2x</MenuItem>
							<MenuItem value="3">3x</MenuItem>
							<MenuItem value="4">4x</MenuItem>
							<MenuItem value="5">5x</MenuItem>
							<MenuItem value="6">6x</MenuItem>
							<MenuItem value="7">7x</MenuItem>
							<MenuItem value="8">8x</MenuItem>
							<MenuItem value="9">9x</MenuItem>
							<MenuItem value="10">10x</MenuItem>
							<MenuItem value="11">11x</MenuItem>
							<MenuItem value="12">12x</MenuItem>
						</Select>
					</FormControl>
				) : null}
				<Button
					size="large"
					variant="contained"
					type="submit"
					disabled={
						isLoadingCreditCardFees || isLoadingBoletoFees || isLoadingPixFees
					}
					sx={{height: 40}}
				>
					{isLoadingCreditCardFees ||
					isLoadingBoletoFees ||
					isLoadingPixFees ? (
						<CircularProgress color="inherit" size={25} />
					) : (
						t('to-calculate')
					)}
				</Button>
			</Stack>
		</Box>
	)
}

export default FormFeesCalculator
