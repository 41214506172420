import {useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {FaHome as HomeIcon} from 'react-icons/fa'
import {useQuery} from '@tanstack/react-query'
import {Breadcrumbs, Container, Skeleton} from '@mui/material'

import useNavigate from '../../../hooks/useNavigate'
import {Edition} from '../../../entities/Edition'
import {listEditionBySlug} from '../../../api/editions'
import Footer from '../../../components/template/Footer'
import Link from '../../../components/navigation/Link'
import FormUpdateEdition from '../../../components/forms/experiences/FormUpdateEdition'
import BackNavigation from '../../../components/navigation/BackNavigation'
import {DashboardFullSection} from '../../../components/template/DashboardFullSection'

interface IStateProps {
	state: {edition?: Edition}
}

const UpdateEdition = () => {
	const [edition, setEdition] = useState<Edition>({} as Edition)

	const {t} = useTranslation()
	const {state}: IStateProps = useLocation()
	const {edition_slug, experience_slug} = useParams<{
		edition_slug: string
		experience_slug: string
	}>()
	const navigate = useNavigate()

	const {isFetching} = useQuery(
		[`edition-${edition_slug}`],

		() =>
			listEditionBySlug({
				experienceSlug: experience_slug as string,
				slug: edition_slug as string
			}),
		{
			networkMode: 'always',
			enabled: !!edition_slug && !!experience_slug && !state?.edition,
			refetchOnWindowFocus: false,
			onSuccess: result => {
				if (result.responseStatusCode === 200 && result?.data) {
					setEdition(result.data)
				} else if (
					result.responseStatusCode === 404 ||
					result.responseStatusCode === 403
				) {
					navigate('/not-found')
				} else {
					navigate('/products', {
						state: {
							alertDialog: {
								severity: 'error',
								message: t('error-server-on-load-edition')
							}
						}
					})
				}
			},
			onError: error => {
				navigate('/products', {
					state: {
						alertDialog: {
							severity: 'error',
							message: t('error-server-on-load-edition')
						}
					}
				})
			}
		}
	)

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link to="/products">
						<span>{t('experiences')}</span>
					</Link>
					{isFetching ? (
						<Skeleton variant="rounded" height={12} width={320} />
					) : (
						[
							<Link
								key={`breadcrumb-link-${edition?.experience?.slug}`}
								to={`/products/${edition?.experience?.slug}`}
								state={{experience: edition.experience}}
							>
								<span>{edition?.experience?.title}</span>
							</Link>,
							<Link
								key={`breadcrumb-link-${edition?.experience?.slug}-${edition?.slug}`}
								to={`/products/${edition?.experience?.slug}/${edition?.slug}`}
								state={{edition}}
							>
								<span>{edition?.title}</span>
							</Link>,
							<span key={`breadcrumb-edition-edit`}>{t('edit')}</span>
						]
					)}
				</Breadcrumbs>
				<BackNavigation
					to={`/products/${edition?.experience?.slug}/${edition?.slug}`}
				/>
			</Container>
			<DashboardFullSection height="auto" py={3}>
				<FormUpdateEdition edition={edition} />
			</DashboardFullSection>
			<Footer />
		</>
	)
}

export default UpdateEdition
