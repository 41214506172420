import {ChangeEvent, useEffect} from 'react'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

interface BankSelectProps {
	name: string
	label: string
	code?: string
	helperText?: React.ReactNode
	error?: boolean
	valueFormat: 'code' | 'label'
	onChange: (e: ChangeEvent<any>) => void
}

export default function BankSelect({
	name,
	label,
	code,
	helperText,
	error,
	valueFormat,
	onChange
}: BankSelectProps) {
	const defaultBank = code
		? banksCode.find(country => country.code === code)
		: null

	const handleChange = (_: any, newValue: BankType | null) => {
		if (newValue) {
			onChange({
				target: {
					name,
					value: newValue.code
				}
			} as React.ChangeEvent<any>)
		}
	}

	const getInputLabel = (option: BankType) => {
		return option.label
	}

	const getOptionLabel = (option: BankType) => {
		return option.label
	}

	useEffect(() => {
		if (defaultBank) {
			onChange({
				target: {
					name,
					value: defaultBank.code
				}
			} as React.ChangeEvent<any>)
		}
	}, [defaultBank, name, onChange, valueFormat])

	return (
		<Autocomplete
			id="country-select"
			fullWidth
			defaultValue={defaultBank}
			options={banksCode}
			autoHighlight
			getOptionLabel={option => getInputLabel(option)}
			onChange={handleChange}
			renderOption={(props, option) => (
				<Box {...props} key={'box' + option.code} component="li">
					{getOptionLabel(option)}
				</Box>
			)}
			renderInput={params => (
				<TextField
					{...params}
					size="small"
					name={name}
					label={label}
					helperText={helperText}
					error={error}
				/>
			)}
		/>
	)
}

interface BankType {
	code: string
	label: string
}

export const banksCode: BankType[] = [
	{code: '001', label: '001 - BANCO DO BRASIL S.A.'},
	{code: '003', label: '003 - BANCO DA AMAZONIA S.A.'},
	{code: '004', label: '004 - BANCO DO NORDESTE DO BRASIL S.A.'},
	{code: '007', label: '007 - BNDES'},
	{code: '010', label: '010 - CREDICOAMO'},
	{code: '011', label: '011 - C.SUISSE HEDGING-GRIFFO CV S/A'},
	{code: '012', label: '012 - BANCO INBURSA'},
	{code: '014', label: '014 - STATE STREET BR S.A. BANCO COMERCIAL'},
	{code: '015', label: '015 - UBS BRASIL CCTVM S.A.'},
	{code: '016', label: '016 - CCM DESP TRÂNS SC E RS'},
	{code: '017', label: '017 - BNY MELLON BANCO S.A.'},
	{code: '018', label: '018 - BANCO TRICURY S.A.'},
	{code: '021', label: '021 - BANCO BANESTES S.A.'},
	{code: '024', label: '024 - BANCO BANDEPE S.A.'},
	{code: '025', label: '025 - BANCO ALFA S.A.'},
	{code: '029', label: '029 - BANCO ITAÚ CONSIGNADO S.A.'},
	{code: '033', label: '033 - BANCO SANTANDER (BRASIL) S.A.'},
	{code: '036', label: '036 - BANCO BBI S.A.'},
	{code: '037', label: '037 - BANCO DO EST. DO PA S.A.'},
	{code: '040', label: '040 - BANCO CARGILL S.A.'},
	{code: '041', label: '041 - BANCO DO ESTADO DO RS S.A.'},
	{code: '047', label: '047 - BANCO DO EST. DE SE S.A.'},
	{code: '060', label: '060 - CONFIDENCE CC S.A.'},
	{code: '062', label: '062 - HIPERCARD BM S.A.'},
	{code: '063', label: '063 - BANCO BRADESCARD'},
	{code: '064', label: '064 - GOLDMAN SACHS DO BRASIL BM S.A'},
	{code: '065', label: '065 - BANCO ANDBANK S.A.'},
	{code: '066', label: '066 - BANCO MORGAN STANLEY S.A.'},
	{code: '069', label: '069 - BANCO CREFISA S.A.'},
	{code: '070', label: '070 - BRB - BANCO DE BRASILIA S.A.'},
	{code: '074', label: '074 - BANCO. J.SAFRA S.A.'},
	{code: '075', label: '075 - BANCO ABN AMRO S.A.'},
	{code: '076', label: '076 - BANCO KDB BRASIL S.A.'},
	{code: '077', label: '077 - BANCO INTER'},
	{code: '078', label: '078 - HAITONG BI DO BRASIL S.A.'},
	{code: '079', label: '079 - BANCO ORIGINAL DO AGRO S/A'},
	{code: '080', label: '080 - B&T CC LTDA.'},
	{code: '081', label: '081 - BANCOSEGURO S.A.'},
	{code: '082', label: '082 - BANCO TOPÁZIO S.A.'},
	{code: '083', label: '083 - BANCO DA CHINA BRASIL S.A.'},
	{code: '084', label: '084 - UNIPRIME NORTE DO PARANÁ - CC'},
	{code: '085', label: '085 - COOP CENTRAL AILOS'},
	{code: '089', label: '089 - CCR REG MOGIANA'},
	{code: '091', label: '091 - CCCM UNICRED CENTRAL RS'},
	{code: '092', label: '092 - BRK S.A. CFI'},
	{code: '093', label: '093 - PÓLOCRED SCMEPP LTDA.'},
	{code: '094', label: '094 - BANCO FINAXIS'},
	{code: '095', label: '095 - TRAVELEX BANCO DE CÂMBIO S.A.'},
	{code: '096', label: '096 - BANCO B3 S.A.'},
	{
		code: '097',
		label: '097 - CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.'
	},
	{code: '098', label: '098 - CREDIALIANÇA CCR'},
	{code: '099', label: '099 - UNIPRIME CENTRAL CCC LTDA.'},
	{code: '100', label: '100 - PLANNER CV S.A.'},
	{code: '100', label: '100 - PLANNER CV S.A.'},
	{code: '101', label: '101 - RENASCENCA DTVM LTDA'},
	{code: '102', label: '102 - XP INVESTIMENTOS CCTVM S/A'},
	{code: '104', label: '104 - CAIXA ECONOMICA FEDERAL'},
	{code: '105', label: '105 - LECCA CFI S.A.'},
	{code: '107', label: '107 - BANCO BOCOM BBM S.A.'},
	{code: '108', label: '108 - PORTOCRED S.A. - CFI'},
	{code: '111', label: '111 - OLIVEIRA TRUST DTVM S.A.'},
	{code: '113', label: '113 - MAGLIANO S.A. CCVM'},
	{
		code: '114',
		label: '114 - CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO'
	},
	{code: '117', label: '117 - ADVANCED CC LTDA'},
	{code: '118', label: '118 - STANDARD CHARTERED BI S.A.'},
	{code: '119', label: '119 - BANCO WESTERN UNION'},
	{code: '120', label: '120 - BANCO RODOBENS S.A.'},
	{code: '121', label: '121 - BANCO AGIBANK S.A.'},
	{code: '122', label: '122 - BANCO BRADESCO BERJ S.A.'},
	{code: '124', label: '124 - BANCO WOORI BANK DO BRASIL S.A.'},
	{code: '125', label: '125 - PLURAL BANCO BM'},
	{code: '126', label: '126 - BR PARTNERS BI'},
	{code: '127', label: '127 - CODEPE CVC S.A.'},
	{code: '128', label: '128 - MS BANK S.A. BANCO DE CÂMBIO'},
	{code: '129', label: '129 - UBS BRASIL BI S.A.'},
	{code: '130', label: '130 - CARUANA SCFI'},
	{code: '131', label: '131 - TULLETT PREBON BRASIL CVC LTDA'},
	{code: '132', label: '132 - ICBC DO BRASIL BM S.A.'},
	{code: '133', label: '133 - CRESOL CONFEDERAÇÃO'},
	{code: '134', label: '134 - BGC LIQUIDEZ DTVM LTDA'},
	{code: '136', label: '136 - UNICRED'},
	{code: '138', label: '138 - GET MONEY CC LTDA'},
	{code: '139', label: '139 - INTESA SANPAOLO BRASIL S.A. BM'},
	{code: '140', label: '140 - EASYNVEST - TÍTULO CV SA'},
	{code: '142', label: '142 - BROKER BRASIL CC LTDA.'},
	{code: '143', label: '143 - TREVISO CC S.A.'},
	{code: '144', label: '144 - BEXS BANCO DE CAMBIO S.A.'},
	{code: '145', label: '145 - LEVYCAM CCV LTDA'},
	{code: '146', label: '146 - GUITTA CC LTDA'},
	{code: '149', label: '149 - FACTA S.A. CFI'},
	{code: '157', label: '157 - ICAP DO BRASIL CTVM LTDA.'},
	{code: '159', label: '159 - CASA CREDITO S.A. SCM'},
	{code: '163', label: '163 - COMMERZBANK BRASIL S.A. - BANCO MÚLTIPLO'},
	{code: '169', label: '169 - BANCO OLÉ BONSUCESSO CONSIGNADO S.A.'},
	{code: '173', label: '173 - BRL TRUST DTVM SA'},
	{code: '174', label: '174 - PERNAMBUCANAS FINANC S.A. CFI'},
	{code: '177', label: '177 - GUIDE'},
	{code: '180', label: '180 - CM CAPITAL MARKETS CCTVM LTDA'},
	{code: '182', label: '182 - DACASA FINANCEIRA S/A - SCFI'},
	{code: '183', label: '183 - SOCRED SA - SCMEPP'},
	{code: '184', label: '184 - BANCO ITAÚ BBA S.A.'},
	{code: '188', label: '188 - ATIVA S.A. INVESTIMENTOS CCTVM'},
	{code: '189', label: '189 - HS FINANCEIRA'},
	{code: '190', label: '190 - SERVICOOP'},
	{code: '191', label: '191 - NOVA FUTURA CTVM LTDA.'},
	{code: '194', label: '194 - PARMETAL DTVM LTDA'},
	{code: '196', label: '196 - FAIR CC S.A.'},
	{code: '197', label: '197 - STONE PAGAMENTOS S.A.'},
	{code: '208', label: '208 - BANCO BTG PACTUAL S.A.'},
	{code: '212', label: '212 - BANCO ORIGINAL'},
	{code: '213', label: '213 - BANCO ARBI S.A.'},
	{code: '217', label: '217 - BANCO JOHN DEERE S.A.'},
	{code: '218', label: '218 - BANCO BS2 S.A.'},
	{code: '222', label: '222 - BANCO CRÉDIT AGRICOLE BR S.A.'},
	{code: '224', label: '224 - BANCO FIBRA S.A.'},
	{code: '233', label: '233 - BANCO CIFRA'},
	{code: '237', label: '237 - BANCO BRADESCO S.A.'},
	{code: '241', label: '241 - BANCO CLASSICO S.A.'},
	{code: '243', label: '243 - BANCO MÁXIMA S.A.'},
	{code: '246', label: '246 - BANCO ABC BRASIL S.A.'},
	{code: '249', label: '249 - BANCO INVESTCRED UNIBANCO S.A.'},
	{code: '250', label: '250 - BCV'},
	{code: '253', label: '253 - BEXS CC S.A.'},
	{code: '254', label: '254 - PARANA BANCO S.A.'},
	{code: '260', label: '260 - NU PAGAMENTOS S.A.'},
	{code: '265', label: '265 - BANCO FATOR S.A.'},
	{code: '266', label: '266 - BANCO CEDULA S.A.'},
	{code: '268', label: '268 - BARI CIA HIPOTECÁRIA'},
	{code: '269', label: '269 - HSBC BANCO DE INVESTIMENTO'},
	{code: '270', label: '270 - SAGITUR CC LTDA'},
	{code: '271', label: '271 - IB CCTVM S.A.'},
	{code: '272', label: '272 - AGK CC S.A.'},
	{code: '273', label: '273 - CCR DE SÃO MIGUEL DO OESTE'},
	{code: '274', label: '274 - MONEY PLUS SCMEPP LTDA'},
	{code: '276', label: '276 - SENFF S.A. - CFI'},
	{code: '278', label: '278 - GENIAL INVESTIMENTOS CVM S.A.'},
	{code: '279', label: '279 - CCR DE PRIMAVERA DO LESTE'},
	{code: '280', label: '280 - AVISTA S.A. CFI'},
	{code: '281', label: '281 - CCR COOPAVEL'},
	{code: '283', label: '283 - RB CAPITAL INVESTIMENTOS DTVM LTDA.'},
	{code: '285', label: '285 - FRENTE CC LTDA.'},
	{code: '286', label: '286 - CCR DE OURO'},
	{code: '288', label: '288 - CAROL DTVM LTDA.'},
	{code: '289', label: '289 - DECYSEO CC LTDA.'},
	{code: '290', label: '290 - PAGSEGURO'},
	{code: '292', label: '292 - BS2 DTVM S.A.'},
	{code: '293', label: '293 - LASTRO RDV DTVM LTDA'},
	{code: '296', label: '296 - VISION S.A. CC'},
	{code: '298', label: '298 - VIPS CC LTDA.'},
	{code: '299', label: '299 - SOROCRED CFI S.A.'},
	{code: '300', label: '300 - BANCO LA NACION ARGENTINA'},
	{code: '301', label: '301 - BPP IP S.A.'},
	{code: '306', label: '306 - PORTOPAR DTVM LTDA'},
	{code: '307', label: '307 - TERRA INVESTIMENTOS DTVM'},
	{code: '309', label: '309 - CAMBIONET CC LTDA'},
	{code: '310', label: '310 - VORTX DTVM LTDA.'},
	{code: '315', label: '315 - PI DTVM S.A.'},
	{code: '318', label: '318 - BANCO BMG S.A.'},
	{code: '319', label: '319 - OM DTVM LTDA'},
	{code: '320', label: '320 - BANCO CCB BRASIL S.A.'},
	{code: '321', label: '321 - CREFAZ SCMEPP LTDA'},
	{code: '322', label: '322 - CCR DE ABELARDO LUZ'},
	{code: '323', label: '323 - MERCADO PAGO'},
	{code: '325', label: '325 - ÓRAMA DTVM S.A.'},
	{code: '329', label: '329 - QI SCD S.A.'},
	{code: '330', label: '330 - BANCO BARI S.A.'},
	{code: '331', label: '331 - FRAM CAPITAL DTVM S.A.'},
	{code: '332', label: '332 - ACESSO'},
	{code: '335', label: '335 - BANCO DIGIO'},
	{code: '336', label: '336 - BANCO C6 S.A.'},
	{
		code: '340',
		label: '340 - SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.'
	},
	{code: '341', label: '341 - ITAÚ UNIBANCO S.A.'},
	{code: '342', label: '342 - CREDITAS SCD'},
	{code: '343', label: '343 - FFA SCMEPP LTDA.'},
	{code: '348', label: '348 - BANCO XP S.A.'},
	{code: '349', label: '349 - AMAGGI S.A. CFI'},
	{code: '352', label: '352 - TORO CTVM LTDA'},
	{code: '354', label: '354 - NECTON INVESTIMENTOS S.A CVM'},
	{code: '355', label: '355 - ÓTIMO SCD S.A.'},
	{code: '364', label: '364 - GERENCIANET PAGAMENTOS DO BRASIL LTDA'},
	{code: '366', label: '366 - BANCO SOCIETE GENERALE BRASIL'},
	{code: '370', label: '370 - BANCO MIZUHO S.A.'},
	{code: '376', label: '376 - BANCO J.P. MORGAN S.A.'},
	{code: '383', label: '383 - JUNO'},
	{code: '389', label: '389 - BANCO MERCANTIL DO BRASIL S.A.'},
	{code: '394', label: '394 - BANCO BRADESCO FINANC. S.A.'},
	{code: '399', label: '399 - KIRTON BANK'},
	{code: '403', label: '403 - CORA CDS S.A.'},
	{code: '412', label: '412 - BANCO CAPITAL S.A.'},
	{code: '422', label: '422 - BANCO SAFRA S.A.'},
	{code: '456', label: '456 - BANCO MUFG BRASIL S.A.'},
	{code: '461', label: '461 - Asaas I.P S.A'},
	{code: '464', label: '464 - BANCO SUMITOMO MITSUI BRASIL S.A.'},
	{code: '473', label: '473 - BANCO CAIXA GERAL BRASIL S.A.'},
	{code: '477', label: '477 - CITIBANK N.A.'},
	{code: '479', label: '479 - BANCO ITAUBANK S.A.'},
	{code: '487', label: '487 - DEUTSCHE BANK S.A.BANCO ALEMAO'},
	{code: '488', label: '488 - JPMORGAN CHASE BANK'},
	{code: '492', label: '492 - ING BANK N.V.'},
	{code: '495', label: '495 - BANCO LA PROVINCIA B AIRES BCE'},
	{code: '505', label: '505 - BANCO CREDIT SUISSE S.A.'},
	{code: '536', label: '536 - NEON PAGAMENTOS S.A. IP'},
	{code: '545', label: '545 - SENSO CCVM S.A.'},
	{code: '600', label: '600 - BANCO LUSO BRASILEIRO S.A.'},
	{code: '604', label: '604 - BANCO INDUSTRIAL DO BRASIL S.A.'},
	{code: '610', label: '610 - BANCO VR S.A.'},
	{code: '611', label: '611 - BANCO PAULISTA S.A.'},
	{code: '612', label: '612 - BANCO GUANABARA S.A.'},
	{code: '613', label: '613 - OMNI BANCO S.A.'},
	{code: '623', label: '623 - BANCO PAN'},
	{code: '626', label: '626 - BANCO FICSA S.A.'},
	{code: '630', label: '630 - SMARTBANK'},
	{code: '633', label: '633 - BANCO RENDIMENTO S.A.'},
	{code: '634', label: '634 - BANCO TRIANGULO S.A.'},
	{code: '637', label: '637 - BANCO SOFISA S.A.'},
	{code: '643', label: '643 - BANCO PINE S.A.'},
	{code: '652', label: '652 - ITAÚ UNIBANCO HOLDING S.A.'},
	{code: '653', label: '653 - BANCO INDUSVAL S.A.'},
	{code: '654', label: '654 - BANCO A.J. RENNER S.A.'},
	{code: '655', label: '655 - BANCO VOTORANTIM S.A.'},
	{code: '707', label: '707 - BANCO DAYCOVAL S.A'},
	{code: '712', label: '712 - BANCO OURINVEST S.A.'},
	{code: '739', label: '739 - BANCO CETELEM S.A.'},
	{code: '741', label: '741 - BANCO RIBEIRAO PRETO S.A.'},
	{code: '743', label: '743 - BANCO SEMEAR'},
	{code: '745', label: '745 - BANCO CITIBANK S.A.'},
	{code: '746', label: '746 - BANCO MODAL S.A.'},
	{code: '747', label: '747 - BANCO RABOBANK INTL BRASIL S.A.'},
	{code: '748', label: '748 - BANCO COOPERATIVO SICREDI S.A.'},
	{code: '751', label: '751 - SCOTIABANK BRASIL'},
	{code: '752', label: '752 - BANCO BNP PARIBAS BRASIL S A'},
	{code: '753', label: '753 - NOVO BANCO CONTINENTAL S.A. - BM'},
	{code: '754', label: '754 - BANCO SISTEMA'},
	{code: '755', label: '755 - BOFA MERRILL LYNCH BM S.A.'},
	{code: '756', label: '756 - BANCOOB'},
	{code: '757', label: '757 - BANCO KEB HANA DO BRASIL S.A.'}
]
