import FormForgotPassword from '../components/forms/forgot-password/FormForgotPassword'
import FullSection from '../components/layout/utils/FullSection'
import Footer from '../components/template/Footer'

const ForgotPassword = () => {
	return (
		<>
			<FullSection height="auto" minHeight="calc(100vh - 260px)">
				<FormForgotPassword />
			</FullSection>
			<Footer />
		</>
	)
}

export default ForgotPassword
