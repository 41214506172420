import {IconButton} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {CgClose as CloseIcon} from 'react-icons/cg'

interface FilterTagManagerProps {
	label: string
	value?: string
	onRemove: () => void
}

export default function FilterTagManager({
	label,
	value,
	onRemove
}: FilterTagManagerProps) {
	return (
		<Box
			sx={theme => ({
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				justifyContent: 'center',
				textAlign: 'center',
				backgroundColor: theme.palette.grey[200],
				color: theme.palette.grey[600],
				borderRadius: '16px',
				py: 0.25,
				pl: 1.25,
				pr: 0.25
			})}
		>
			<Typography variant="caption">
				{value ? (
					<>
						<b>{label}</b>
						{': '}
						{value}
					</>
				) : (
					label
				)}
			</Typography>
			<IconButton
				onClick={onRemove}
				sx={theme => ({
					fontSize: '0.875rem',
					lineHeight: '1.25rem',
					cursor: 'pointer',
					'&:hover': {
						color: theme.palette.primary.main
					}
				})}
			>
				<CloseIcon
					style={{
						fontSize: '0.875rem'
					}}
				/>
			</IconButton>
		</Box>
	)
}
