import {useTranslation} from 'react-i18next'
import {Alert, Collapse, IconButton, SxProps} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {CgClose as CloseIcon} from 'react-icons/cg'
import {RefObject, useEffect, useRef} from 'react'

interface AlertMessageProps {
	open: boolean
	message: string
	severity: 'error' | 'success' | 'info' | 'warning'
	ref?: RefObject<HTMLDivElement>
	mt?: number | string
	sx?: SxProps<Theme>
	onClose?: () => void
}

const AlertMessage = ({
	open,
	message,
	severity,
	onClose,
	mt,
	sx
}: AlertMessageProps) => {
	const {t} = useTranslation()
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (open && ref.current) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			})
		}
	}, [open])

	return (
		<Collapse ref={ref} in={open} sx={sx}>
			<Alert
				severity={severity}
				action={
					onClose && (
						<IconButton
							aria-label={t('aria-close-alert')}
							color="inherit"
							size="small"
							onClick={onClose}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					)
				}
				sx={{
					mt: open
						? mt !== undefined
							? mt
							: '24px! important'
						: '0px!important'
				}}
			>
				{message}
			</Alert>
		</Collapse>
	)
}

export default AlertMessage
