import {image} from '../image'

interface Image {
	basePath: string
	imagePath: string
	transform?: string
}

const makeImage = async ({basePath, imagePath, transform}: Image) => {
	const options = transform ? `${transform}/` : ''

	const url = basePath + options + imagePath

	return {
		url,
		dimensions: await image.getDimensions(url)
	}
}

export {makeImage}
