import {DEFAULT_LANG, SELLER_EXPERIENCES_API} from '../config'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import {Experience} from '../entities/Experience'
import {RawExperience, RawExperienceListItem} from './entities/RawExperience'
import {convertFileToBase64URI} from '../helpers/convertFileToBase64URI'

interface Message {
	message: string
}

interface ListExperiencesMainInfoResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	data?: {
		experiences: {id: string; title: string; cover: string; status: string}[]
		hasMore: boolean
	}
	responseStatusCode?: number
}

export const listExperiencesMainInfo = async ({
	offset,
	limit
}: {
	offset: number
	limit: number
}): Promise<ListExperiencesMainInfoResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/main-info?offset=${offset}&limit=${limit}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface GetOfferGroupByProductIdResponse {
	success?: Message
	error?: Message
	data?: {
		product: {
			id: string
			title: string
			cover?: string
			offers: {
				id: string
				title: string
				unitPrice: number
				checked: boolean
			}[]
		}
	}
	responseStatusCode?: number
}

export const getOfferGroupByProductId = async ({
	productId
}: {
	productId?: string
}): Promise<GetOfferGroupByProductIdResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/offer-groups?productId=${productId}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface GetOfferGroupByProductIdResponse {
	success?: Message
	error?: Message
	data?: {
		product: {
			id: string
			title: string
			cover?: string
			offers: {
				id: string
				title: string
				unitPrice: number
				checked: boolean
			}[]
		}
	}
	responseStatusCode?: number
}

interface ListExperiencesBySellerResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	data?: {
		experiences: RawExperienceListItem[]
	}
	responseStatusCode?: number
}

export const listExperiencesBySeller = async ({
	limit,
	offset
}: {
	limit: number
	offset: number
}): Promise<ListExperiencesBySellerResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/?offset=${offset}&limit=${limit}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ListExperiencesBySlugResponse {
	error?: {
		message: string
	}
	data?: RawExperience
	responseStatusCode: number
}

export const listExperienceBySlug = async ({
	slug
}: {
	slug: string
}): Promise<ListExperiencesBySlugResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${slug}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CreateExperienceResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			title?: Message[]
			slug?: Message[]
			description?: Message[]
		}
	}
	data?: Experience
	responseStatusCode?: number
}

export const createExperience = async (values: {
	title: string
	slug: string
	description: string
	cover: File | null
}): Promise<CreateExperienceResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	if (values.cover) {
		const coverBase64 = await convertFileToBase64URI(values.cover)

		const response = await authenticatedFetch(`${SELLER_EXPERIENCES_API}/`, {
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				...values,
				cover: coverBase64
			})
		})

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	} else {
		const response = await authenticatedFetch(`${SELLER_EXPERIENCES_API}/`, {
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		})

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	}
}

interface UpdateExperienceResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			title?: Message[]
			slug?: Message[]
			description?: Message[]
		}
	}
	data?: Experience
	responseStatusCode?: number
}

export const updateExperience = async ({
	experienceId,
	...values
}: {
	experienceId: string
	title: string
	slug: string
	description: string
}): Promise<UpdateExperienceResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ChangeExperienceStatusResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			status?: Message[]
		}
	}
	data?: {
		status: string
	}
	responseStatusCode?: number
}

export const changeExperienceStatus = async ({
	experienceId,
	status
}: {
	experienceId: string
	status: string
}): Promise<ChangeExperienceStatusResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/status`,
		{
			method: 'PATCH',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({status})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CheckExperienceSlugResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			slug?: Message[]
		}
	}
	data?: {
		slug: string
	}
	responseStatusCode?: number
}

export const checkExperienceSlug = async (values: {
	slug: string
}): Promise<CheckExperienceSlugResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${SELLER_EXPERIENCES_API}/slug`, {
		method: 'POST',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		},
		body: JSON.stringify(values)
	})

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UploadResponse {
	success?: Message
	error?: Message
	data?: {
		basePath: string
		imagePath: string
	}
	responseStatusCode?: number
}

export const uploadExperienceCover = async ({
	experienceId,
	newCover
}: {
	experienceId: string
	newCover: File | null
}): Promise<UploadResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const formData = new FormData()
	if (newCover) formData.append('newCover', newCover)

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/cover`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: formData
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface DeleteSagePageCoverResponse {
	success?: Message
	error?: Message
	responseStatusCode?: number
}

export const deleteExperienceCover = async ({
	experienceId
}: {
	experienceId: string
}): Promise<DeleteSagePageCoverResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/cover`,
		{
			method: 'DELETE',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
