import {ForwardRefExoticComponent, RefAttributes} from 'react'
import Box from '@mui/material/Box'
import {CgClose as CloseIcon} from 'react-icons/cg'
import {useTranslation} from 'react-i18next'
import {SystemStyleObject} from '@mui/system/styleFunctionSx'
import {useTheme} from '@mui/material/styles'
import {Theme} from '@mui/material/styles'
import {TransitionProps} from '@mui/material/transitions'

import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import MuiDialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

interface DialogProps {
	children: React.ReactNode
	open: boolean
	fullScreen?: boolean
	transition?: ForwardRefExoticComponent<
		TransitionProps & {
			children: React.ReactElement
		} & RefAttributes<unknown>
	>
	maxWidth?: number
}

const DialogRoot = ({
	children,
	open,
	fullScreen,
	transition,
	maxWidth
}: DialogProps) => {
	const theme = useTheme()
	return (
		<MuiDialog
			open={open}
			fullScreen={fullScreen}
			TransitionComponent={transition}
			sx={[
				styles.dialog(theme, fullScreen),
				{
					'& .MuiDialog-paper': {
						maxWidth,
						overflow: 'hidden'
					}
				}
			]}
			PaperProps={{
				variant:
					theme.palette.mode === 'light' || fullScreen
						? 'elevation'
						: 'outlined'
			}}
		>
			{children}
		</MuiDialog>
	)
}

interface DialogHeaderProps {
	children: React.ReactNode
	sx?: SystemStyleObject<Theme>
}

const DialogHeader = ({children, sx}: DialogHeaderProps) => {
	return (
		<MuiDialogTitle
			p={2}
			component="div"
			sx={[
				{
					backgroundColor: 'background.paper'
				},
				{...sx}
			]}
			borderBottom={1}
			borderColor="divider"
		>
			{children}
		</MuiDialogTitle>
	)
}

interface DialogTitleProps {
	children: React.ReactNode
	onClose: () => void
}

const DialogTitle = ({children, onClose}: DialogTitleProps) => {
	const {t} = useTranslation()

	return (
		<Box sx={styles.dialogTitleBox}>
			<Typography component="h1" variant="h5" sx={styles.dialogTitle}>
				{children}
			</Typography>
			<IconButton
				sx={styles.closeIcon}
				size="small"
				onClick={onClose}
				aria-label={t('aria-close-modal')}
			>
				<CloseIcon fontSize="inherit" />
			</IconButton>
		</Box>
	)
}

interface DialogSubtitleProps {
	children: React.ReactNode
	variant?: 'h6' | 'body1' | 'body2' | 'subtitle1' | 'subtitle2' | 'caption'
}

const DialogSubTitle = ({children, variant = 'body1'}: DialogSubtitleProps) => {
	return <Typography variant={variant}>{children}</Typography>
}

interface DialogBodyProps {
	children: React.ReactNode
	ref?: React.Ref<any>
	width?: number | string
	sx?: SystemStyleObject<Theme>
}

const DialogBody = ({ref, children, width, sx}: DialogBodyProps) => {
	const theme = useTheme()
	return (
		<DialogContent
			ref={ref}
			sx={[
				{
					position: 'relative',
					margin: '0 auto',
					pt: '24px !important',
					width: '100%',
					backgroundColor: 'background.paper',
					[theme.breakpoints.down('sm')]: {
						width: '100%'
					},
					[theme.breakpoints.up('sm')]: {
						width: '100%',
						minWidth: 500
					},
					'> div': {
						height: '100%'
					},
					'&. MuiDialogContent-root': {
						overflowY: 'auto !important'
					}
				},
				{...sx}
			]}
		>
			{children}
		</DialogContent>
	)
}

interface DialogFooterProps {
	children: React.ReactNode
}

const DialogFooter = ({children}: DialogFooterProps) => {
	return (
		<DialogActions sx={styles.dialogFooter}>
			<Box sx={styles.dialogFooterActions}>{children}</Box>
		</DialogActions>
	)
}

interface SimpleDialogProps {
	open: boolean
	children: React.ReactNode
	onClose?: () => void
}

export const SimpleDialog = ({children, open, onClose}: SimpleDialogProps) => {
	const {t} = useTranslation()

	return (
		<MuiDialog open={open} onClose={onClose}>
			<DialogContent sx={styles.dialogContent}>
				<DialogContentText>{children}</DialogContentText>
				{onClose && (
					<IconButton
						sx={styles.closeIcon}
						size="small"
						onClick={onClose}
						aria-label={t('aria-close-modal')}
					>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				)}
			</DialogContent>
		</MuiDialog>
	)
}

const styles = {
	dialog: (theme: Theme, fullScreen?: boolean) => ({
		[theme.breakpoints.down('sm')]: {
			'& .MuiDialogContent-root': {
				margin: fullScreen ? 0 : 2
			}
		}
	}),
	dialogFooter: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column',
		borderTop: '1px solid ' + theme.palette.divider,
		py: 2,
		[theme.breakpoints.up('sm')]: {
			px: 3
		},
		[theme.breakpoints.down('sm')]: {
			p: 1
		}
	}),
	dialogFooterActions: {
		display: 'flex',
		gap: 2,
		flexWrap: 'wrap',
		alignSelf: 'end',
		justifyContent: 'end'
	},
	dialogContent: {
		display: 'flex',
		gap: 1,
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		padding: 2,
		maxWidth: 400
	},
	dialogSubtitle: {
		mt: 1
	},
	dialogTitle: (theme: Theme) => ({
		color: theme.palette.common.black
	}),
	dialogTitleBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	closeIcon: (theme: Theme) => ({
		color: theme.palette.grey[500],
		padding: 0,
		'&:hover': {
			cursor: 'pointer',
			color: theme.palette.grey[600]
		}
	})
}

export const Dialog = {
	Root: DialogRoot,
	Header: DialogHeader,
	Title: DialogTitle,
	SubTitle: DialogSubTitle,
	Body: DialogBody,
	Footer: DialogFooter
}
