import useAppStates from '../stores/useAppStates'
import {DEFAULT_LANG, SELLER_AUTH_API} from '../config'
import {RawProfile} from './entities/RawProfile'
import {Onboarding} from '../entities/Onboarding'
import {PaymentService} from '../entities/PaymentService'
import {BankAccount} from '../entities/BankAccount'
import {Company} from '../entities/Company'
interface Message {
	message: string
}
interface LoginResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			email?: Message[]
			password?: Message[]
		}
	}
	data?: {
		accessToken: string
		refreshToken: string
		sellerId: string
		email: string
		onboarding: Onboarding
		profile: RawProfile | null
		company: Company | null
		paymentService: PaymentService | null
		bankAccount: BankAccount | null
	}
	responseStatusCode?: number
}

export const login = async (values: {
	email: string
	password: string
}): Promise<LoginResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const response = await fetch(`${SELLER_AUTH_API}/login`, {
		method: 'POST',
		body: JSON.stringify(values),
		credentials: 'include',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG
		}
	})
	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}
