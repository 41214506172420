import {useState} from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import {
	BoletoFeesCalculatorData,
	CreditCardFeesCalculatorData,
	PixFeesCalculatorData
} from '../../api/feesCalculator'
import CreditCardFees from './CreditCardFees'
import BoletoFees from './BoletoFees'
import PixFees from './PixFees'

import './style.css'
import FormFeesCalculator from '../FormFeesCalculator'

interface PagarmeFeesCalculatorProps {
	initialPaymentMethod?: 'credit-card' | 'boleto' | 'pix'
	initialAmount?: number
	initialInstallments?: number
}

const PagarmeFeesCalculator = ({
	initialAmount = 1000,
	initialInstallments = 3,
	initialPaymentMethod = 'credit-card'
}: PagarmeFeesCalculatorProps) => {
	const [creditCardFees, setCreditCardFees] =
		useState<CreditCardFeesCalculatorData>()
	const [boletoFees, setBoletoFees] = useState<BoletoFeesCalculatorData>()
	const [pixFees, setPixFees] = useState<PixFeesCalculatorData>()
	const [showFees, setShowFees] = useState<boolean>(false)
	const [paymentMethod, setPaymentMethod] = useState<
		'credit-card' | 'boleto' | 'pix'
	>(initialPaymentMethod)

	const handleHideFees = () => {
		setShowFees(false)
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexWrap: 'wrap',
				gap: 5
			}}
		>
			<FormFeesCalculator
				amount={initialAmount}
				installments={initialInstallments}
				paymentMethod={initialPaymentMethod}
				setPaymentMethod={setPaymentMethod}
				onChange={handleHideFees}
				onSuccess={data => {
					setShowFees(true)
					if (paymentMethod === 'credit-card') {
						setCreditCardFees(data as CreditCardFeesCalculatorData)
					} else if (paymentMethod === 'pix') {
						setPixFees(data as PixFeesCalculatorData)
					} else {
						setBoletoFees(data as BoletoFeesCalculatorData)
					}
				}}
			/>
			{showFees ? (
				<Card variant="outlined">
					<Box display="grid" className="pagarme-fees-calculator">
						{creditCardFees && paymentMethod === 'credit-card' ? (
							<CreditCardFees fees={creditCardFees} />
						) : null}

						{boletoFees && paymentMethod === 'boleto' ? (
							<BoletoFees fees={boletoFees} />
						) : null}

						{pixFees && paymentMethod === 'pix' ? (
							<PixFees fees={pixFees} />
						) : null}
					</Box>
				</Card>
			) : null}
		</Box>
	)
}

export default PagarmeFeesCalculator
