import * as yup from 'yup'
import {useTranslation} from 'react-i18next'

const useSignUpSchema = () => {
	const {t} = useTranslation()
	const signUpSchema = yup.object({
		email: yup
			.string()
			.matches(
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
				t('error-email-invalid')
			)
			.min(5, t('error-email-min-length'))
			.max(200, t('error-email-max-length'))
			.required(t('error-email-required')),
		password: yup
			.string()
			.matches(
				/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{1,}$/,
				t('error-password-invalid')
			)
			.min(7, t('error-password-min-length'))
			.max(500, t('error-password-max-length'))
			.required(t('error-password-required'))
	})
	return signUpSchema
}

export default useSignUpSchema
