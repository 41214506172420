import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import SectionTitle from '../../../components/typography/SectionTitle'
import {useTranslation} from 'react-i18next'
import {AsaasCommercialDataStep} from './AsaasCommercialDataStep'
import {AsaasSendingDocumentsStep} from './AsaasSendingDocumentsStep'
import useAsaasStates from '../../../stores/useAsaasStates'
import {useCallback} from 'react'
import {AsaasDocumentationAnalysisStep} from './AsaasDocumentationAnalysisStep'

export const CreateAsaasAccountForm = () => {
	const {t} = useTranslation()
	const {onboardingStep, setOnboardingStep} = useAsaasStates()

	const handleNext = useCallback(() => {
		setOnboardingStep(onboardingStep + 1)
	}, [onboardingStep, setOnboardingStep])

	const steps = [
		{
			label: 'commercial-data',
			description: <Typography>{t('commercial-data-describe')}</Typography>,
			component: <AsaasCommercialDataStep onSuccess={handleNext} />
		},
		{
			label: 'sending-documents',
			description: <Typography>{t('sending-documents-describe')}</Typography>,
			component: <AsaasSendingDocumentsStep onSuccess={handleNext} />
		},
		{
			label: 'create-asaas-account-last-step-title',
			description: null,
			component: <AsaasDocumentationAnalysisStep />
		}
	]

	return (
		<>
			<Stack direction="column" spacing={4}>
				<SectionTitle>{t('activate-your-asaas-account')}</SectionTitle>
				<Box sx={{maxWidth: 500}}>
					<Stepper activeStep={onboardingStep} orientation="vertical">
						{steps.map((step, index) => (
							<Step key={step.label}>
								<StepLabel>
									<Typography variant="h6" component="h2">
										{t(step.label)}
									</Typography>
								</StepLabel>
								<StepContent>{step.component}</StepContent>
							</Step>
						))}
					</Stepper>
				</Box>
			</Stack>
		</>
	)
}
