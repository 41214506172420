import {useRef, useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import {useFormik} from 'formik'
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Collapse,
	FormControl,
	FormGroup,
	FormHelperText,
	IconButton,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {Theme, useTheme} from '@mui/material/styles'
import {CgClose as CloseIcon} from 'react-icons/cg'

import {createProfile} from '../../../api/onboarding'
import useCreateProfileSchema from '../../../schemas/useCreateProfileSchema'
import {OnboardingStepsFormProps} from '../../../pages/onboarding/OnboardingSteps'
import ImageFileUpload from '../../ImageFileUpload'
import useAuthStates from '../../../stores/useAuthStates'
import useProfileImageSchema from '../../../schemas/useProfileImageSchema'
import {ValidationError} from 'yup'
import {profileMapper} from '../../../api/mappers/profileMapper'
import {regex} from '../../../helpers/regex'
import Tag from '../../typography/Tag'

const FormCreateProfile = ({handleNext}: OnboardingStepsFormProps) => {
	const [errorAlert, setErrorAlert] = useState<boolean>(false)
	const [serverError, setServerError] = useState<string>('')

	const updateProfile = useAuthStates(state => state.updateProfile)
	const theme = useTheme()

	const errorAlertRef = useRef<HTMLDivElement>(null)
	const nameRef = useRef<HTMLInputElement>(null)
	const nameSlugRef = useRef<HTMLInputElement>(null)
	const avatarRef = useRef<HTMLDivElement>(null)
	const logoLightRef = useRef<HTMLDivElement>(null)
	const logoDarkRef = useRef<HTMLDivElement>(null)

	const {t} = useTranslation()
	const profileSchema = useCreateProfileSchema()
	const profileImageSchema = useProfileImageSchema()

	const handleChangeImage = async (
		field: 'avatar' | 'logoLight' | 'logoDark',
		file: File
	) => {
		const errorMessage = await profileImageSchema[field]
			.validate(file)
			.then(() => {
				formik.setFieldValue(field, file)
			})
			.catch((err: ValidationError) => {
				formik.setFieldError(field, err.errors[0])
				return err.errors[0]
			})

		if (errorMessage) {
			return errorMessage
		}
	}

	const {isLoading, mutate} = useMutation(
		(values: {
			name: string
			nameSlug: string
			primaryColor: string
			logoLight: File | null
			logoDark: File | null
			avatar: File | null
		}) => createProfile(values),
		{networkMode: 'always'}
	)

	const formik = useFormik({
		initialValues: {
			name: '',
			nameSlug: '',
			primaryColor: theme.palette.primary.main,
			logoLight: null,
			logoDark: null,
			avatar: null
		},
		validationSchema: profileSchema,
		onSubmit: values => {
			mutate(values, {
				onSuccess: async (result, data) => {
					if (result.responseStatusCode === 200 && result.data) {
						const profile = await profileMapper.toDomain(result.data)
						updateProfile(profile)
						handleNext()
					}

					if (result.responseStatusCode === 400) {
						setErrorAlert(false)
						if (result.error?.fields) {
							Object.entries(result.error.fields).forEach(([key, value]) => {
								formik.setFieldError(key, value[0].message)
							})

							const firstFieldWithError = Object.keys(result.error.fields).find(
								key => key
							)

							if (firstFieldWithError) {
								focusField(firstFieldWithError)
							}
						}
					}

					if (result.responseStatusCode === 403) {
						handleNext()
					}

					if (result.responseStatusCode === 500) {
						setErrorAlert(true)
						setServerError(t('error-server-default'))
						errorAlertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
						console.error(result?.error?.message)
					}
				},
				onError: error => {
					setErrorAlert(true)
					setServerError(t('error-server-default'))
					errorAlertRef.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					})
					console.error(error)
				}
			})
		}
	})

	const focusField = (field: string) => {
		switch (field) {
			case 'name':
				nameRef.current?.focus()
				break
			case 'nameSlug':
				nameSlugRef.current?.focus()
				break
			case 'logoLight':
				logoLightRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'end'
				})
				break
			case 'logoDark':
				logoDarkRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				})
				break
			case 'avatar':
				avatarRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				})
				break
			default:
				break
		}
	}

	const handleFocusOnFirstFieldWithError = () => {
		const firstFieldWithError = Object.keys(formik.errors).find(
			(key: string) => key
		)

		if (firstFieldWithError) {
			focusField(firstFieldWithError)
		}
	}

	return (
		<Box>
			<Collapse ref={errorAlertRef} in={errorAlert}>
				<Alert
					severity="warning"
					action={
						<IconButton
							aria-label={t('aria-close-alert')}
							color="inherit"
							size="small"
							onClick={() => {
								setErrorAlert(false)
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{mt: 3}}
				>
					{serverError}
				</Alert>
			</Collapse>
			<Box
				onSubmit={formik.handleSubmit}
				component="form"
				autoComplete="off"
				sx={{mt: 3}}
			>
				<Stack direction="column" spacing={4}>
					<TextField
						name="name"
						type="text"
						inputRef={nameRef}
						autoFocus
						label={t('name')}
						variant="outlined"
						helperText={formik.touched.name && formik.errors.name}
						error={formik.touched.name && Boolean(formik.errors.name)}
						value={formik.values.name}
						onChange={event => {
							const currentNameSlug = regex.formatStringToSlug(
								event.target.value
							)
							formik.setFieldValue('nameSlug', currentNameSlug)
							formik.handleChange(event)
						}}
						onBlur={formik.handleBlur}
						size="small"
						sx={{m: 0}}
					/>
					<TextField
						name="nameSlug"
						type="text"
						inputRef={nameSlugRef}
						label={t('name-slug')}
						variant="outlined"
						helperText={
							formik.touched.nameSlug && Boolean(formik.errors.nameSlug)
								? formik.errors.nameSlug
								: t('helper-input-name-slug')
						}
						error={formik.touched.nameSlug && Boolean(formik.errors.nameSlug)}
						margin="normal"
						value={formik.values.nameSlug}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						size="small"
					/>
					<FormControl ref={logoLightRef} sx={style.formControlImage}>
						<Typography variant="subtitle1" sx={{mb: 2}}>
							{t('label-image-upload-light-logo')}
						</Typography>
						<ImageFileUpload
							name="logoLight"
							label={t('label-image-upload-light-logo')}
							onChangeImage={handleChangeImage}
							acceptImages={{'image/png': []}}
							mode="light"
							aspectRatio={6}
						/>
						{formik.errors.logoLight ? (
							<FormHelperText error sx={style.helperText}>
								{formik.errors.logoLight}
							</FormHelperText>
						) : (
							<FormHelperText sx={style.helperText}>
								{t('helper-image-upload-logo')}
							</FormHelperText>
						)}
					</FormControl>
					<FormControl ref={logoDarkRef} sx={style.formControlImage}>
						<Typography variant="subtitle1" sx={{mb: 2}}>
							{t('label-image-upload-dark-logo')}
						</Typography>
						<ImageFileUpload
							name="logoDark"
							label={t('label-image-upload-dark-logo')}
							mode="dark"
							onChangeImage={handleChangeImage}
							acceptImages={{'image/png': []}}
							aspectRatio={6}
						/>
						{formik.errors.logoDark ? (
							<FormHelperText error sx={style.helperText}>
								{formik.errors.logoDark}
							</FormHelperText>
						) : (
							<FormHelperText sx={style.helperText}>
								{t('helper-image-upload-logo')}
							</FormHelperText>
						)}
					</FormControl>
					<FormControl ref={avatarRef} sx={style.formControlImage}>
						<Typography variant="subtitle1" sx={{mb: 2}}>
							{t('label-image-upload-avatar')}
						</Typography>
						<ImageFileUpload
							name="avatar"
							label={t('label-image-upload-avatar')}
							onChangeImage={handleChangeImage}
							acceptImages={{
								'image/png': [],
								'image/jpeg': [],
								'image/jpg': []
							}}
							aspectRatio={1}
							circle
						/>
						{formik.errors.avatar ? (
							<FormHelperText error sx={style.helperText}>
								{formik.errors.avatar}
							</FormHelperText>
						) : (
							<FormHelperText sx={style.helperText}>
								{t('helper-image-upload-avatar')}
							</FormHelperText>
						)}
					</FormControl>
					<FormControl>
						<FormGroup
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 1
							}}
						>
							<TextField
								name="primaryColor"
								type="text"
								label={t('primary-color')}
								size="small"
								value={formik.values.primaryColor}
								onChange={formik.handleChange}
								InputProps={{
									endAdornment: (
										<input
											className="color-picker-input"
											type="color"
											value={formik.values.primaryColor}
											onChange={e => {
												formik.setFieldValue('primaryColor', e.target.value)
											}}
										/>
									)
								}}
								sx={theme => ({
									'& .MuiOutlinedInput-root': {
										paddingRight: '4px !important'
									},
									[theme.breakpoints.down('sm')]: {
										width: '100%'
									}
								})}
							/>
						</FormGroup>
						{formik.errors.primaryColor ? (
							<FormHelperText error sx={style.helperText}>
								{formik.errors.primaryColor}
							</FormHelperText>
						) : (
							<FormHelperText sx={style.helperText}>
								<Tag>{t('optional')}</Tag>
							</FormHelperText>
						)}
					</FormControl>
					<Button
						size="large"
						variant="contained"
						type="submit"
						disabled={isLoading}
						onClick={handleFocusOnFirstFieldWithError}
						sx={style.formSubmitButton}
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('to-create')
						)}
					</Button>
				</Stack>
			</Box>
		</Box>
	)
}

const style = {
	formControlImage: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formSubmitButton: (theme: Theme) => ({
		height: '44px',
		width: '110px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	helperText: {
		margin: 0,
		marginTop: 1
	}
}

export default FormCreateProfile
