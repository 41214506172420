import {useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import {FaHome as HomeIcon} from 'react-icons/fa'
import {Breadcrumbs, Container, Skeleton} from '@mui/material'

import {Experience} from '../../entities/Experience'
import useNavigate from '../../hooks/useNavigate'
import {listExperienceBySlug} from '../../api/experiences'
import Footer from '../../components/template/Footer'
import Link from '../../components/navigation/Link'
import FormUpdateExperience from '../../components/forms/experiences/FormUpdateExperience'
import BackNavigation from '../../components/navigation/BackNavigation'
import {DashboardFullSection} from '../../components/template/DashboardFullSection'

interface IStateProps {
	state: {experience?: Experience}
}

const UpdateExperience = () => {
	const {state}: IStateProps = useLocation()

	const [experience, setExperience] = useState<{
		_id: string
		title: string
		slug: string
		description: string
	}>({
		_id: '',
		title: '',
		slug: '',
		description: ''
	})

	const {t} = useTranslation()
	const {experience_slug} = useParams<{experience_slug: string}>()
	const navigate = useNavigate()

	const handleChangeExperience = (experience: {
		_id: string
		title: string
		slug: string
		description: string
	}) => {
		setExperience(experience)
	}

	const {isFetching} = useQuery(
		[`experience-${experience_slug}`],
		() =>
			listExperienceBySlug({
				slug: experience_slug as string
			}),
		{
			networkMode: 'always',
			enabled: !!experience_slug && !state?.experience,
			refetchOnWindowFocus: false,
			onSuccess: async result => {
				if (result.responseStatusCode === 200 && result?.data) {
					setExperience({
						_id: result.data._id,
						title: result.data.title,
						slug: result.data.slug,
						description: result.data.description
					})
				} else if (
					result.responseStatusCode === 404 ||
					result.responseStatusCode === 403
				) {
					navigate('/not-found')
				} else {
					navigate('/products', {
						state: {
							alertDialog: {
								severity: 'error',
								message: t('error-server-on-load-experience')
							}
						}
					})
				}
			},
			onError: error => {
				navigate('/products', {
					state: {
						alertDialog: {
							severity: 'error',
							message: t('error-server-on-load-experience')
						}
					}
				})
			}
		}
	)

	useEffect(() => {
		if (state?.experience) {
			setExperience({
				_id: state.experience._id,
				title: state.experience.title,
				slug: state.experience.slug,
				description: state.experience.description
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state])

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link to="/products">
						<span>{t('experiences')}</span>
					</Link>
					{isFetching ? (
						<Skeleton variant="rounded" height={12} width={240} />
					) : (
						[
							<Link
								key={`breadcrumb-link-${experience?.slug}`}
								to={`/products/${experience?.slug}`}
							>
								<span>{experience?.title}</span>
							</Link>,
							<span key={`breadcrumb-link-${experience?.slug}-edit`}>
								{t('edit')}
							</span>
						]
					)}
				</Breadcrumbs>
				<BackNavigation to={`/products/${experience?.slug}`} />
			</Container>
			<DashboardFullSection>
				<FormUpdateExperience
					experience={experience}
					handleChangeExperience={handleChangeExperience}
				/>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

export default UpdateExperience
