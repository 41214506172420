import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import {useTranslation} from 'react-i18next'
import {MdSettings as SettingsIcon} from 'react-icons/md'
import {useState} from 'react'
import useNavigate from '../../../hooks/useNavigate'
import useAsaasStates from '../../../stores/useAsaasStates'
import {Status} from '../../../components/Status'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import {TransferActionCard} from './TransferActionCard'
import {TransferHistoryCard} from './TransferHistoryCard'
import {Dialog} from '../../../components/Dialog'
import {AsaasLogo} from '../../../components/logos/AsaasLogo'
import {AsaasFees} from './AsaasFees'
import useAuthStates from '../../../stores/useAuthStates'

export const AsaasCard = () => {
	const [openFeesDialog, setOpenFeesDialog] = useState(false)
	const {asaasConfig, onboardingCompleted} = useAsaasStates()

	const {t} = useTranslation()
	const navigate = useNavigate()
	const {paymentService} = useAuthStates()

	const handleNavigateToSettings = () => {
		navigate('/payment-services/asaas')
	}

	return (
		<>
			<AsaasFeesDialog
				open={openFeesDialog}
				onClose={() => setOpenFeesDialog(false)}
			/>
			<Card variant="outlined">
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							p: 3,
							gap: 3
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 2
							}}
						>
							<AsaasLogo />
							{asaasConfig &&
								asaasConfig.generalStatus !== 'approved' &&
								paymentService?.activeService !== 'asaas' && (
									<Status.Tag
										type={
											asaasConfig.generalStatus === 'rejected'
												? 'rejected'
												: 'pending'
										}
									>
										<Status.Label
											label={
												asaasConfig.generalStatus === 'rejected'
													? t('status-analysis-asaas-rejected')
													: t('status-tag-pending-setup')
											}
											maxWidth="auto"
											showTooltip={false}
										/>
									</Status.Tag>
								)}

							{paymentService?.activeService === 'asaas' && (
								<Status.Tag type="active">
									<Status.Label
										label={t('active-on-checkout')}
										maxWidth="auto"
										showTooltip={false}
									/>
								</Status.Tag>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								gap: 2,
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<Button
								variant="outlined"
								onClick={() => setOpenFeesDialog(true)}
							>
								{t('fees-and-terms')}
							</Button>
							<Button
								variant="contained"
								onClick={handleNavigateToSettings}
								startIcon={asaasConfig ? <SettingsIcon /> : undefined}
								sx={{width: '148px'}}
							>
								{asaasConfig ? t('to-configure') : t('to-activate-service')}
							</Button>
						</Box>
					</Box>
					{onboardingCompleted && (
						<>
							<Divider />
							<Box
								sx={{
									display: 'flex',
									alignItems: 'flex-start',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									p: 3,
									gap: 4
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'stretch',
										gap: 4,
										flexWrap: 'wrap'
									}}
								>
									<TransferActionCard />
									<TransferHistoryCard />
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 4,
										flexWrap: 'wrap'
									}}
								>
									<Box>
										<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
											<Typography variant="subtitle2" sx={{mb: 0.5}}>
												{t('ps-account-status')}
											</Typography>
										</Box>
										<Typography
											variant="body2"
											sx={[
												{
													fontWeight: 500,
													color: () => {
														if (asaasConfig?.generalStatus === 'approved') {
															return 'success.main'
														}

														if (asaasConfig?.generalStatus === 'rejected') {
															return 'error.main'
														}

														return '#b99905'
													}
												}
											]}
										>
											{t('status-tag-' + asaasConfig?.generalStatus)}
										</Typography>
									</Box>
								</Box>
							</Box>
						</>
					)}
				</Box>
			</Card>
		</>
	)
}

export const AsaasFeesDialog = ({
	open,
	onClose
}: {
	open: boolean
	onClose: () => void
}) => {
	return (
		<Dialog.Root open={open} maxWidth={900}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>
					<AsaasLogo />
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body width="100%">
				<AsaasFees />
			</Dialog.Body>
		</Dialog.Root>
	)
}
