import {useState} from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import {Theme} from '@mui/material/styles'

import {MdLogout as IconLogout} from 'react-icons/md'
import {
	FaUserCog as IconAccount,
	FaUserCircle as IconProfile,
	FaStoreAlt as IconCompany
} from 'react-icons/fa'
import {RiArrowDownSFill as IconArrowDown} from 'react-icons/ri'
import {useTranslation} from 'react-i18next'
import Link from '../navigation/Link'

import useAuthStates from '../../stores/useAuthStates'
import {useMutation} from '@tanstack/react-query'
import {logout} from '../../api/logout'
import FullPageLoading from '../FullPageLoading'
import useNavigate from '../../hooks/useNavigate'
import AlertDialog from '../AlertDialog'
import {Avatar} from '@mui/material'
import usePaymentServicePagarmeStates from '../../stores/usePaymentServicePagarmeStates'
import useAsaasStates from '../../stores/useAsaasStates'

const ProfileAvatar = ({src}: {src: string}) => {
	const {t} = useTranslation()

	return (
		<Avatar
			variant="circular"
			src={src}
			aria-label={t('account-menu')}
			sx={style.avatar}
		/>
	)
}

const ProfileMenu = () => {
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')

	const {t} = useTranslation()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const navigate = useNavigate()

	const setLogout = useAuthStates(state => state.logout)
	const profile = useAuthStates(state => state.profile)
	const resetPaymentServicePagarme = usePaymentServicePagarmeStates(
		state => state.reset
	)
	const {resetAsaasStates} = useAsaasStates()

	const {mutate, isLoading: isLoggingOut} = useMutation(
		(value: string) => logout(),
		{
			networkMode: 'always'
		}
	)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleAlertClose = () => {
		setAlertOpen(false)
	}

	const handleLogout = () => {
		mutate('', {
			onSuccess: result => {
				if (result?.responseStatusCode === 200 && result?.success) {
					setLogout()
					resetAsaasStates()
					resetPaymentServicePagarme()
					navigate('/login')
				} else if (result?.error?.message) {
					console.error(result.error.message)
					setAlertOpen(true)
					setAlertMessage(t('error-server-default'))
				}
			},
			onError: error => {
				console.error('Logout Error: ' + JSON.stringify(error))
				setAlertOpen(true)
				setAlertMessage(t('error-server-default'))
			}
		})
	}
	return (
		<>
			<Box sx={{display: 'flex'}}>
				<Tooltip title={t('account-menu')}>
					<IconButton
						onClick={handleClick}
						size="small"
						sx={{ml: 2}}
						aria-controls={open ? t('account-menu') : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
					>
						{profile && <ProfileAvatar src={profile.avatar.url} />}
						<IconArrowDown />
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: theme => ({
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						bgcolor:
							theme.palette.mode === 'light' ? 'background.paper' : 'grey.50',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor:
								theme.palette.mode === 'light' ? 'background.paper' : 'grey.50',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0
						}
					})
				}}
				transformOrigin={{horizontal: 'right', vertical: 'top'}}
				anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
			>
				{[
					{
						label: t('profile'),
						icon: <IconProfile style={{width: '20px', height: '20px'}} />,
						link: '/profile'
					},
					{
						label: t('company'),
						icon: <IconCompany style={{width: '19px', height: '19px'}} />,
						link: '/company'
					},
					{
						label: t('account'),
						icon: <IconAccount style={{width: '20px', height: '20px'}} />,
						link: '/account'
					}
				].map(item => (
					<Link to={item.link} key={item.label} sx={style.link}>
						<MenuItem onClick={handleClose}>
							<ListItemIcon sx={{mr: 0}}>{item.icon}</ListItemIcon>
							{item.label}
						</MenuItem>
					</Link>
				))}
				<Divider />
				<MenuItem onClick={handleLogout}>
					<ListItemIcon sx={{mr: 0}}>
						<IconLogout style={{width: '20px', height: '20px'}} />
					</ListItemIcon>
					{t('logout')}
				</MenuItem>
			</Menu>
			{isLoggingOut ? <FullPageLoading label={t('logging-out')} /> : null}
			<AlertDialog
				open={alertOpen}
				message={alertMessage}
				onClose={handleAlertClose}
			/>
		</>
	)
}

const style = {
	avatar: (theme: Theme) => ({
		backgroundColor: theme.palette.grey[100],
		borderRadius: '50%',
		height: 32,
		width: 32
	}),
	link: (theme: Theme) => ({
		color: theme.palette.common.black
	})
}

export default ProfileMenu
