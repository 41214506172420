import {forwardRef, RefCallback, useRef, useState} from 'react'
import {IMaskInput} from 'react-imask'
import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {InputBaseComponentProps} from '@mui/material/InputBase'
import InputAdornment from '@mui/material/InputAdornment'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import FormGroup from '@mui/material/FormGroup'
import {Theme} from '@mui/material/styles'
import {CgClose as CloseIcon} from 'react-icons/cg'

import useCreateCompanySchema from '../../../schemas/useCreateCompanySchema'
import {searchCompany} from '../../../api/company'
import {createCompany} from '../../../api/onboarding'

import {OnboardingStepsFormProps} from '../../../pages/onboarding/OnboardingSteps'
import {findAddressByZipCode} from '../../../api/zipCode'
import {regex} from '../../../helpers/regex'
import InfoPopover from '../../InfoPopover'
import Typography from '@mui/material/Typography'
import Tag from '../../typography/Tag'

interface CustomOnChangeProps {
	onChange: (event: {target: {name: string; value: string}}) => void
	name: string
}

const InputPhoneMask = forwardRef<
	HTMLInputElement,
	Omit<InputBaseComponentProps, 'onChange'> & CustomOnChangeProps
>((props, ref) => {
	const {onChange, ...other} = props
	return (
		<IMaskInput
			{...other}
			mask="(00) 00000-0000"
			inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
			onAccept={value =>
				onChange({target: {name: props.name, value: value as string}})
			}
			overwrite
		/>
	)
})

const InputCNPJMask = forwardRef<
	HTMLInputElement,
	Omit<InputBaseComponentProps, 'onChange'> & CustomOnChangeProps
>((props, ref) => {
	const {onChange, ...other} = props
	return (
		<IMaskInput
			{...other}
			mask="00.000.000/0000-00"
			inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
			onAccept={value =>
				onChange({target: {name: props.name, value: value as string}})
			}
			overwrite
		/>
	)
})

const InputZipCodeMask = forwardRef<
	HTMLInputElement,
	Omit<InputBaseComponentProps, 'onChange'> & CustomOnChangeProps
>((props, ref) => {
	const {onChange, ...other} = props
	return (
		<IMaskInput
			{...other}
			mask="00000-000"
			inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
			onAccept={value =>
				onChange({target: {name: props.name, value: value as string}})
			}
			overwrite
		/>
	)
})

const FormCreateCompany = ({handleNext}: OnboardingStepsFormProps) => {
	const [disableFields, setDisableFields] = useState<boolean>(true)
	const [lastValidCnpj, setLastValidCnpj] = useState<string>('')
	const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(false)
	const [errorAlert, setErrorAlert] = useState<boolean>(false)
	const [serverError, setServerError] = useState<string>('')
	const [lastValidZipCode, setLastValidZipCode] = useState<string>('')

	const errorAlertRef = useRef<HTMLDivElement>(null)
	const cnpjRef = useRef<HTMLInputElement>(null)
	const legalNameRef = useRef<HTMLInputElement>(null)
	const fantasyNameRef = useRef<HTMLInputElement>(null)
	const emailRef = useRef<HTMLInputElement>(null)
	const phoneRef = useRef<HTMLInputElement>(null)
	const addressRef = useRef<HTMLInputElement>(null)
	const districtRef = useRef<HTMLInputElement>(null)
	const cityRef = useRef<HTMLInputElement>(null)
	const stateRef = useRef<HTMLInputElement>(null)
	const zipCodeRef = useRef<HTMLInputElement>(null)
	const streetNumberRef = useRef<HTMLInputElement>(null)
	const complementaryAddressRef = useRef<HTMLInputElement>(null)
	const referencePointRef = useRef<HTMLInputElement>(null)

	const {t} = useTranslation()
	const companySchema = useCreateCompanySchema()

	const {isLoading, mutate} = useMutation(createCompany, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			cnpj: '',
			legalName: '',
			fantasyName: '',
			email: '',
			phone: '',
			address: '',
			district: '',
			streetNumber: '',
			complementaryAddress: '',
			referencePoint: '',
			city: '',
			state: '',
			zipCode: ''
		},
		validationSchema: companySchema,
		onSubmit: values => {
			setErrorAlert(false)
			mutate(
				{
					...values,
					streetNumber: Number(values.streetNumber)
				},
				{
					onSuccess: (result, data) => {
						if (result.responseStatusCode === 200) {
							handleNext()
						}

						if (result.responseStatusCode === 400) {
							if (result.error?.fields) {
								Object.entries(result.error.fields).forEach(([key, value]) => {
									formik.setFieldError(key, value[0].message)
								})

								if (result.error.fields.cnpj) {
									setDisableFields(true)
								}

								const firstFieldWithError = Object.keys(
									result.error.fields
								).find(key => key)

								if (firstFieldWithError) {
									focusField(firstFieldWithError)
								}
							}
						}

						if (result.responseStatusCode === 403) {
							handleNext()
						}

						if (result.responseStatusCode === 500) {
							setErrorAlert(true)
							setServerError(t('error-server-default'))
							errorAlertRef.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'center'
							})
							console.error(result?.error?.message)
						}
					},
					onError: error => {
						setErrorAlert(true)
						setServerError(t('error-server-default'))
						errorAlertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
						console.error(error)
					}
				}
			)
		}
	})

	const searchCompanyByCnpj = () => {
		setErrorAlert(false)
		if (
			formik.values.cnpj.length === 18 &&
			!isLoadingCompany &&
			lastValidCnpj !== formik.values.cnpj
		) {
			setIsLoadingCompany(true)
			searchCompany({cnpj: formik.values.cnpj})
				.then(result => {
					if (result.success && result.data) {
						setErrorAlert(false)
						setDisableFields(false)
						setLastValidCnpj(formik.values.cnpj)

						if (lastValidCnpj !== result.data.cnpj) {
							Object.entries(result.data).forEach(([key, value]) => {
								formik.setFieldValue(key, value)
							})
						}
					}

					if (result.error && result.responseStatusCode === 400) {
						setDisableFields(true)
						setErrorAlert(true)
						setServerError(t('error-cnpj-not-found-alert'))
						formik.setFieldError('cnpj', t('error-cnpj-not-found'))
						errorAlertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
					}

					if (result.error && result.responseStatusCode === 401) {
						setDisableFields(true)
						setErrorAlert(true)
						setServerError(t('error-cnpj-inactive-alert'))
						formik.setFieldError('cnpj', t('error-cnpj-inactive'))
						errorAlertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
					}

					if (
						result.error &&
						(result.responseStatusCode === 500 ||
							result.responseStatusCode === 429)
					) {
						setDisableFields(false)
					}
				})
				.catch(error => {
					setDisableFields(false)
					errorAlertRef.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					})
					console.error(error)
				})
				.finally(() => {
					setIsLoadingCompany(false)
				})
		}

		if (!disableFields && formik.errors.cnpj) {
			setDisableFields(true)
		}

		if (disableFields && !formik.errors.cnpj) {
			setDisableFields(false)
		}
	}

	const focusField = (field: string) => {
		switch (field) {
			case 'cnpj':
				cnpjRef.current?.focus()
				break
			case 'legalName':
				legalNameRef.current?.focus()
				break
			case 'fantasyName':
				fantasyNameRef.current?.focus()
				break
			case 'email':
				emailRef.current?.focus()
				break
			case 'phone':
				phoneRef.current?.focus()
				break
			case 'address':
				addressRef.current?.focus()
				break
			case 'district':
				districtRef.current?.focus()
				break
			case 'streetNumber':
				streetNumberRef.current?.focus()
				break
			case 'complementaryAddress':
				complementaryAddressRef.current?.focus()
				break
			case 'referencePoint':
				referencePointRef.current?.focus()
				break
			case 'city':
				cityRef.current?.focus()
				break
			case 'state':
				stateRef.current?.focus()
				break
			case 'zipCode':
				zipCodeRef.current?.focus()
				break
			default:
				break
		}
	}

	const handleFocusOnFirstFieldWithError = () => {
		const firstFieldWithError = Object.keys(formik.errors).find(
			(key: string) => key
		)

		if (firstFieldWithError) {
			focusField(firstFieldWithError)
		}
	}

	const {isLoading: isSearchingAddress, mutate: mutateSearchAddress} =
		useMutation(findAddressByZipCode, {networkMode: 'always'})

	const searchAddressByZipCode = () => {
		if (
			!formik.errors.zipCode &&
			!isSearchingAddress &&
			lastValidZipCode !== regex.removeAllNonDigits(formik.values.zipCode)
		) {
			mutateSearchAddress(formik.values.zipCode, {
				onSuccess: ({responseStatusCode, data}) => {
					if (responseStatusCode === 200 && data) {
						setLastValidZipCode(regex.removeAllNonDigits(formik.values.zipCode))
						formik.setFieldValue('address', data.street)
						formik.setFieldValue('district', data.neighborhood)
						formik.setFieldValue('city', data.city)
						formik.setFieldValue('state', data.state)
					}
				},
				onError: error => {
					console.error(error)
				}
			})
		}
	}

	return (
		<Box>
			<Collapse ref={errorAlertRef} in={errorAlert}>
				<Alert
					severity="warning"
					action={
						<IconButton
							aria-label={t('aria-close-alert')}
							color="inherit"
							size="small"
							onClick={() => {
								setErrorAlert(false)
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{mt: 3}}
				>
					{serverError}
				</Alert>
			</Collapse>
			<Box
				onSubmit={formik.handleSubmit}
				component="form"
				autoComplete="off"
				sx={{mt: 3}}
			>
				<Stack direction="column" spacing={4}>
					<FormGroup
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1
						}}
					>
						<Typography variant="h6">{t('basic-info')}</Typography>
						<TextField
							name="cnpj"
							type="text"
							inputRef={cnpjRef}
							InputProps={{
								inputComponent: InputCNPJMask as any,
								endAdornment: (
									<InputAdornment position="end">
										{isLoadingCompany && (
											<CircularProgress color="primary" size={20} />
										)}
									</InputAdornment>
								)
							}}
							autoFocus
							label={t('cnpj')}
							variant="outlined"
							helperText={formik.touched.cnpj && formik.errors.cnpj}
							error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
							value={formik.values.cnpj}
							onKeyUp={searchCompanyByCnpj}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
							sx={{mt: 2}}
						/>
						<TextField
							name="legalName"
							type="text"
							inputRef={legalNameRef}
							label={t('legal-name')}
							variant="outlined"
							helperText={formik.touched.legalName && formik.errors.legalName}
							error={
								formik.touched.legalName && Boolean(formik.errors.legalName)
							}
							margin="normal"
							value={formik.values.legalName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
							disabled={disableFields}
						/>
						<TextField
							name="fantasyName"
							type="text"
							inputRef={fantasyNameRef}
							label={t('fantasy-name')}
							variant="outlined"
							helperText={
								formik.touched.fantasyName && formik.errors.fantasyName
							}
							error={
								formik.touched.fantasyName && Boolean(formik.errors.fantasyName)
							}
							margin="normal"
							value={formik.values.fantasyName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
							disabled={disableFields}
						/>
						<TextField
							name="email"
							type="email"
							inputRef={emailRef}
							label={t('email')}
							variant="outlined"
							helperText={formik.touched.email && formik.errors.email}
							error={formik.touched.email && Boolean(formik.errors.email)}
							margin="normal"
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
							disabled={disableFields}
						/>
						<TextField
							name="phone"
							type="text"
							inputRef={phoneRef}
							InputProps={{
								inputComponent: InputPhoneMask as any
							}}
							label={t('phone')}
							variant="outlined"
							helperText={formik.touched.phone && formik.errors.phone}
							error={formik.touched.phone && Boolean(formik.errors.phone)}
							margin="normal"
							value={formik.values.phone}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
							disabled={disableFields}
						/>
					</FormGroup>
					<FormGroup
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1
						}}
					>
						<Typography variant="h6">{t('address')}</Typography>
						<TextField
							name="zipCode"
							type="text"
							inputRef={zipCodeRef}
							InputProps={{
								inputComponent: InputZipCodeMask as any,
								endAdornment: (
									<InputAdornment position="end">
										{isSearchingAddress && (
											<CircularProgress color="primary" size={20} />
										)}
									</InputAdornment>
								)
							}}
							label={t('zip-code')}
							variant="outlined"
							helperText={formik.touched.zipCode && formik.errors.zipCode}
							error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
							margin="normal"
							value={formik.values.zipCode}
							onChange={formik.handleChange}
							onBlur={event => {
								formik.handleBlur(event)
								searchAddressByZipCode()
							}}
							size="small"
							disabled={disableFields}
						/>
						<Box
							sx={theme => ({
								display: 'flex',
								flexDirection: 'row',
								gap: 2,
								[theme.breakpoints.down('sm')]: {
									flexDirection: 'column',
									gap: 1
								}
							})}
						>
							<TextField
								name="address"
								type="text"
								inputRef={addressRef}
								label={t('street')}
								variant="outlined"
								helperText={formik.touched.address && formik.errors.address}
								error={formik.touched.address && Boolean(formik.errors.address)}
								margin="normal"
								value={formik.values.address}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
								disabled={disableFields}
								fullWidth
							/>
							<TextField
								name="streetNumber"
								type="text"
								inputRef={streetNumberRef}
								label={t('street-number')}
								variant="outlined"
								helperText={
									formik.touched.streetNumber && formik.errors.streetNumber
								}
								error={
									formik.touched.streetNumber &&
									Boolean(formik.errors.streetNumber)
								}
								margin="normal"
								value={formik.values.streetNumber}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
								disabled={disableFields}
							/>
						</Box>
						<TextField
							name="complementaryAddress"
							type="text"
							inputRef={complementaryAddressRef}
							label={t('complementary-address')}
							variant="outlined"
							helperText={
								formik.touched.complementaryAddress &&
								Boolean(formik.errors.complementaryAddress) ? (
									formik.errors.complementaryAddress
								) : (
									<Tag>{t('optional')}</Tag>
								)
							}
							error={
								formik.touched.complementaryAddress &&
								Boolean(formik.errors.complementaryAddress)
							}
							margin="normal"
							value={formik.values.complementaryAddress}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
							disabled={disableFields}
							sx={theme => ({
								[theme.breakpoints.down('sm')]: {
									'& .MuiInputBase-root': {
										pr: 0
									}
								}
							})}
							InputProps={{
								endAdornment: (
									<InfoPopover
										sx={theme => ({
											position: 'absolute',
											right: -34,
											[theme.breakpoints.down('sm')]: {
												position: 'relative',
												right: 0
											}
										})}
									>
										<Typography variant="body2">
											{t('info-complementary-address')}
										</Typography>
									</InfoPopover>
								)
							}}
						/>
						<TextField
							name="referencePoint"
							type="text"
							inputRef={referencePointRef}
							label={t('reference-point')}
							variant="outlined"
							helperText={
								formik.touched.referencePoint &&
								Boolean(formik.errors.referencePoint) ? (
									formik.errors.referencePoint
								) : (
									<Tag>{t('optional')}</Tag>
								)
							}
							error={
								formik.touched.referencePoint &&
								Boolean(formik.errors.referencePoint)
							}
							margin="normal"
							value={formik.values.referencePoint}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
							disabled={disableFields}
							sx={theme => ({
								[theme.breakpoints.down('sm')]: {
									'& .MuiInputBase-root': {
										pr: 0
									}
								}
							})}
							InputProps={{
								endAdornment: (
									<InfoPopover
										sx={theme => ({
											position: 'absolute',
											right: -34,
											[theme.breakpoints.down('sm')]: {
												position: 'relative',
												right: 0
											}
										})}
									>
										<Typography variant="body2">
											{t('info-reference-point')}
										</Typography>
									</InfoPopover>
								)
							}}
						/>
						<TextField
							name="district"
							type="text"
							inputRef={districtRef}
							label={t('district')}
							variant="outlined"
							helperText={formik.touched.district && formik.errors.district}
							error={formik.touched.district && Boolean(formik.errors.district)}
							margin="normal"
							value={formik.values.district}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
							disabled={disableFields}
						/>
						<Box
							sx={theme => ({
								display: 'flex',
								flexDirection: 'row',
								gap: 2,
								[theme.breakpoints.down('sm')]: {
									flexDirection: 'column',
									gap: 1
								}
							})}
						>
							<TextField
								name="city"
								type="text"
								inputRef={cityRef}
								label={t('city')}
								variant="outlined"
								helperText={formik.touched.city && formik.errors.city}
								error={formik.touched.city && Boolean(formik.errors.city)}
								margin="normal"
								value={formik.values.city}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
								disabled={disableFields}
								fullWidth
							/>
							<TextField
								name="state"
								type="text"
								inputRef={stateRef}
								label={t('state')}
								variant="outlined"
								helperText={formik.touched.state && formik.errors.state}
								error={formik.touched.state && Boolean(formik.errors.state)}
								margin="normal"
								value={formik.values.state}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
								disabled={disableFields}
							/>
						</Box>
					</FormGroup>
					<Button
						size="large"
						variant="contained"
						type="submit"
						disabled={isLoading || disableFields}
						sx={style.formSubmitButton}
						onClick={handleFocusOnFirstFieldWithError}
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('to-create')
						)}
					</Button>
				</Stack>
			</Box>
		</Box>
	)
}

const style = {
	formSubmitButton: (theme: Theme) => ({
		height: '44px',
		width: '110px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	})
}

export default FormCreateCompany
