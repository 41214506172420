import {lazy, Suspense} from 'react'

import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements
} from 'react-router-dom'
import Index from '../pages/Index'
import NotFoundLayout from '../components/layout/NotFoundLayout'
import Layout from '../components/layout/FrontLayout'
import SignUp from '../pages/SignUp'
import NotFound from '../pages/NotFound'
import Login from '../pages/Login'
import SignUpCodeValidate from '../pages/SignUpCodeValidate'
import ForgotPassword from '../pages/ForgotPassword'
import ForgotPasswordCodeValidate from '../pages/ForgotPasswordCodeValidate'
import ForgotPasswordPasswordReset from '../pages/ForgotPasswordPasswordReset'
import DashboardLayout from '../components/layout/DashboardLayout'
import Onboarding from '../pages/onboarding/Onboarding'
import OnboardingLayout from '../components/layout/OnboardingLayout'
import Company from '../pages/Company'
import Profile from '../pages/Profile'
import Experiences from '../pages/experiences/Experiences'
import Sales from '../pages/Sales'
import ShowExperience from '../pages/experiences/ShowExperience'
import CreateExperience from '../pages/experiences/CreateExperience'
import CreateEdition from '../pages/experiences/modules/CreateEdition'
import ShowEdition from '../pages/experiences/modules/ShowEdition'
import UpdateExperience from '../pages/experiences/UpdateExperience'
import UpdateEdition from '../pages/experiences/modules/UpdateEdition'
import ShowSalePageLayout from '../components/layout/ShowSalePageLayout'
import EditSalePageLayout from '../components/layout/EditSalePageLayout'
import Account from '../pages/Account/Account'
import FeesCalculator from '../pages/FeesCalculator'
import Customers from '../pages/Customers'
import FullPageLoading from '../components/FullPageLoading'
import {PaymentServicesRoutes} from './PaymentServicesRoutes'

const EditSalePage = lazy(
	() => import('../pages/experiences/modules/salePage/EditSalePage')
)
const ShowSalePage = lazy(
	() => import('../pages/experiences/modules/salePage/ShowSalePage')
)

const Router = () => {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route path={'/:lang?'} element={<DashboardLayout />}>
					<Route index element={<Index />} />
					<Route path="customers" element={<Customers />} />
					<Route path="sales" element={<Sales />} />
					<Route path="profile" element={<Profile />} />
					<Route
						path="payment-services/*"
						element={<PaymentServicesRoutes />}
					/>
					<Route path="fees-calculator" element={<FeesCalculator />} />
					<Route path="company" element={<Company />} />
					<Route path="account" element={<Account />} />
				</Route>
				<Route path={'/:lang?/onboarding'} element={<OnboardingLayout />}>
					<Route index element={<Onboarding />} />
				</Route>
				<Route path={'/:lang?/products'} element={<DashboardLayout />}>
					<Route index element={<Experiences />} />
					<Route path="create" element={<CreateExperience />} />
					<Route path=":experience_slug" element={<ShowExperience />} />
					<Route path=":experience_slug/edit" element={<UpdateExperience />} />
					<Route
						path=":experience_slug/create-edition"
						element={<CreateEdition />}
					/>
					<Route
						path=":experience_slug/:edition_slug"
						element={<ShowEdition />}
					/>
					<Route
						path=":experience_slug/:edition_slug/edit"
						element={<UpdateEdition />}
					/>
				</Route>
				<Route path={'/:lang?'} element={<Layout />}>
					<Route path="sign-up" element={<SignUp />} />
					<Route
						path="sign-up/code-validate"
						element={<SignUpCodeValidate />}
					/>
					<Route path="login" element={<Login />} />
					<Route path="forgot-password" element={<ForgotPassword />} />
					<Route
						path="forgot-password/code-validate"
						element={<ForgotPasswordCodeValidate />}
					/>
					<Route
						path="forgot-password/password-reset"
						element={<ForgotPasswordPasswordReset />}
					/>
				</Route>
				<Route path={'/:lang?'} element={<EditSalePageLayout />}>
					<Route
						path="products/:experience_slug/edit-sale-page"
						element={
							<Suspense fallback={<FullPageLoading />}>
								<EditSalePage />
							</Suspense>
						}
					/>
				</Route>
				<Route path={'/:lang?'} element={<ShowSalePageLayout />}>
					<Route
						path=":profile_slug/:experience_slug/preview"
						element={
							<Suspense fallback={<FullPageLoading />}>
								<ShowSalePage />
							</Suspense>
						}
					/>
				</Route>
				<Route path={'/:lang?'} element={<NotFoundLayout />}>
					<Route path="not-found" element={<NotFound />} />
					<Route path="*" element={<NotFound />} />
				</Route>
			</>
		)
	)

	return <RouterProvider router={router} />
}

export default Router
