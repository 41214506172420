import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {regex} from '../helpers/regex'
import {price} from '../helpers/price'

const useRefundSaleSchema = (maxValue: number = 0) => {
	const {t} = useTranslation()
	const refundSaleSchema = yup.object({
		value: yup
			.string()
			.transform(value => String(regex.parseBRLCurrencyToFloat(value)))
			.required(t('error-price-required'))
			.test(
				'max-refund-value',
				t('error-max-refund-value', {value: price.full(maxValue)}),
				value => {
					const currentValue = Number(regex.removeAllNonDigits(value || '0'))
					return !(currentValue > maxValue * 100)
				}
			),
		password: yup
			.string()
			.matches(
				/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{1,}$/,
				t('error-password-invalid')
			)
			.min(7, t('error-password-min-length'))
			.max(500, t('error-password-max-length'))
			.required(t('error-password-required'))
	})
	return refundSaleSchema
}

export default useRefundSaleSchema
