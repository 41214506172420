import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {SxProps, Theme} from '@mui/material/styles'
import {Status} from '../../../components/Status'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import {FaImage as ImageIcon} from 'react-icons/fa'
import Icon from '@mui/material/Icon'

interface OptionType {
	id: string
	cover?: string
	status: string
	title: string
}

interface SelectProps {
	name: string
	label: string
	code?: string
	helperText?: React.ReactNode
	error?: boolean
	disabled?: boolean
	valueFormat: 'code' | 'label'
	options: OptionType[]
	sx?: SxProps<Theme>
	onChange: (data: OptionType | null) => void
}

export default function ExperiencesSelect({
	name,
	label,
	helperText,
	error,
	disabled,
	valueFormat,
	options,
	sx,
	onChange
}: SelectProps) {
	const {t} = useTranslation()

	const handleChange = (_: any, newValue: OptionType | null) => {
		onChange(newValue)
	}

	const getInputLabel = (option: OptionType) => {
		return option.title
	}

	return (
		<Autocomplete
			id={'select-' + name}
			fullWidth
			options={options}
			autoHighlight
			getOptionLabel={option => getInputLabel(option)}
			onChange={handleChange}
			onInputChange={(_, newInputValue) => {
				if (newInputValue === '') {
					onChange(null)
				}
			}}
			disabled={disabled}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			renderOption={(props, option) => (
				<Box
					{...props}
					key={'experience-select-option-' + option.id}
					component="li"
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						gap: 1,
						p: '0px !important'
					}}
				>
					<Box
						width="100"
						height="70"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							br: 0.5,
							p: 2,
							position: 'relative'
						}}
					>
						{option.cover ? (
							<Box
								sx={{
									width: '100px',
									height: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									backgroundColor: theme => theme.palette.grey[100]
								}}
							>
								<img
									src={option.cover}
									alt={option.title}
									width="100%"
									height="70%"
									style={{
										borderRadius: 4,
										margin: '0px !important',
										objectFit: 'cover'
									}}
								/>
							</Box>
						) : (
							<Box
								sx={theme => ({
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									width: '100px',
									height: '70px',
									backgroundColor: theme.palette.grey[100],
									borderRadius: '4px',
									border: '1px solid ' + theme.palette.divider,
									boxSizing: 'border-box',
									margin: '0px !important'
								})}
							>
								<Icon
									sx={theme => ({
										color: theme.palette.grey[400],
										width: '100%',
										height: '100%'
									})}
								>
									<ImageIcon
										style={{
											width: '100%',
											height: '100%'
										}}
									/>
								</Icon>
							</Box>
						)}
						<Box
							sx={{
								position: 'absolute',
								right: '20px',
								bottom: '20px',
								backgroundColor: 'white',
								borderRadius: '8px'
							}}
						>
							<Status.Tag type={option.status} size="small">
								{t('status-tag-' + option.status)}
							</Status.Tag>
						</Box>
					</Box>
					<Box>
						<Typography variant="body2">{option.title}</Typography>
					</Box>
				</Box>
			)}
			renderInput={params => (
				<TextField
					{...params}
					size="small"
					name={name}
					label={label}
					helperText={helperText}
					error={error}
				/>
			)}
			sx={sx}
		/>
	)
}
