import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {FaHome as HomeIcon} from 'react-icons/fa'
import {MdModeEdit as EditIcon} from 'react-icons/md'
import {RiArrowDownSFill as ArrowDropDownIcon} from 'react-icons/ri'
import {useMutation, useQuery} from '@tanstack/react-query'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import {Theme} from '@mui/material/styles'

import {listEditionsByExperience} from '../../api/editions'
import {Experience} from '../../entities/Experience'
import {Edition} from '../../entities/Edition'
import Link from '../../components/navigation/Link'
import SectionTitle from '../../components/typography/SectionTitle'
import AlertMessage from '../../components/AlertMessage'
import Footer from '../../components/template/Footer'
import Editions from './modules/Editions'
import useNavigate from '../../hooks/useNavigate'
import {
	changeExperienceStatus,
	deleteExperienceCover,
	listExperienceBySlug,
	uploadExperienceCover
} from '../../api/experiences'
import {Status} from '../../components/Status'
import SalePageManager from '../../components/SalePageManager'
import CoverUpload from '../../components/CoverUpload'
import {experienceMapper} from '../../api/mappers/experienceMapper'
import ButtonIcon from '../../components/ButtonIcon'
import BackNavigation from '../../components/navigation/BackNavigation'
import Price from '../../components/Price'
import {makeImageURL} from '../../helpers/factories/imageURLFactory'
import {Integrations} from '../../components/integrations/Integrations'
import {DashboardFullSection} from '../../components/template/DashboardFullSection'

const ShowExperience = () => {
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [editionListStatus, setEditionListStatus] = useState<
		'error' | 'idle' | 'loading' | 'success'
	>('idle')
	const [openAlertEdition, setOpenAlertEdition] = useState(false)
	const [alertMessageEdition, setAlertMessageEdition] = useState('')
	const [editions, setEditions] = useState<Edition[] | undefined>(undefined)
	const [experience, setExperience] = useState<Experience | null>(null)
	const [experienceStatus, setExperienceStatus] = useState<string>('')
	const [anchorElStatusList, setAnchorElStatusList] =
		useState<null | HTMLElement>(null)
	const [openStatusList, setOpenStatusList] = useState(false)
	const [firstLoad, setFirstLoad] = useState(true)
	const [offset, setOffset] = useState(0)
	const [limit] = useState(5)
	const [loadMore, setLoadMore] = useState(false)
	const [previewImage, setPreviewImage] = useState<string | null>(null)

	const {t} = useTranslation()
	const {experience_slug} = useParams<{experience_slug: string}>()
	const {mutate} = useMutation(
		(values: {status: string; experienceId: string}) =>
			changeExperienceStatus(values),
		{networkMode: 'always'}
	)
	const navigate = useNavigate()

	const {isLoading: isLoadingEditions, mutate: listEditionsMutate} =
		useMutation(listEditionsByExperience, {
			networkMode: 'always'
		})

	const listEditions = useCallback(
		async () => {
			setEditionListStatus('loading')
			setOpenAlertEdition(false)
			listEditionsMutate(
				{
					offset,
					limit,
					experienceId: experience?._id!
				},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result.data) {
							setEditionListStatus('success')
							if (firstLoad) {
								setFirstLoad(false)
							}

							if (editions) {
								setEditions([...editions, ...result.data.editions])
							} else {
								setEditions(result.data.editions)
							}

							if (result.data.moreResults) {
								setLoadMore(true)
							} else {
								setLoadMore(false)
							}
							setOffset(offset + limit)
						} else {
							setEditionListStatus('error')
							setAlertMessageEdition(t('error-listing-editions-alert'))
							setOpenAlertEdition(true)
						}
					},
					onError: error => {
						setEditionListStatus('error')
						setAlertMessageEdition(t('error-listing-editions-alert'))
						setOpenAlertEdition(true)
					}
				}
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[offset, limit, experience]
	)

	const isExperienceSlugDefined = Boolean(experience_slug)

	const {isFetching} = useQuery(
		[`experience-${experience_slug}`],
		() =>
			listExperienceBySlug({
				slug: experience_slug as string
			}),
		{
			networkMode: 'always',
			enabled: isExperienceSlugDefined,
			refetchOnWindowFocus: false,
			onSuccess: async result => {
				if (result.responseStatusCode === 200 && result?.data) {
					setExperience(await experienceMapper.toDomain(result.data))
					setPreviewImage(
						result.data.cover
							? makeImageURL({
									basePath: result.data.cover.basePath,
									imagePath: result.data.cover.imagePath
							  })
							: null
					)
				} else if (
					result.responseStatusCode === 404 ||
					result.responseStatusCode === 403
				) {
					navigate('/not-found')
				} else {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
				}
			},
			onError: error => {
				setAlertMessage(t('error-server-default'))
				setOpenAlert(true)
				console.error(error)
			}
		}
	)

	const handleNavigateToCreateEdition = () => {
		navigate(`/products/${experience?.slug}/create-edition`, {
			state: {experience}
		})
	}

	const handleNavigateToEdit = () => {
		navigate(`/products/${experience?.slug}/edit`, {
			state: {experience}
		})
	}

	const handleStatusChange = (status: string) => {
		setOpenAlert(false)
		const currentExperienceStatus = experienceStatus
		setExperienceStatus(status)
		mutate(
			{status, experienceId: experience?._id!},
			{
				onSuccess: ({data, responseStatusCode}) => {
					if (responseStatusCode === 200 && data) {
						setEditions(prev => {
							if (prev) {
								return prev.map(edition => {
									if (edition.experience._id === experience?._id) {
										return {
											...edition,
											experience: {
												...edition.experience,
												status: data.status
											}
										}
									}
									return edition
								})
							}
							return prev
						})
					}
					if (responseStatusCode !== 200) {
						setExperienceStatus(currentExperienceStatus)
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: (error, data) => {
					setExperienceStatus(currentExperienceStatus)
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
	}

	const {mutate: mutateUploadCover, status} = useMutation(
		uploadExperienceCover,
		{
			networkMode: 'always'
		}
	)

	const handleUploadCover = async (file: File) => {
		mutateUploadCover(
			{
				experienceId: experience?._id!,
				newCover: file
			},
			{
				onSuccess: result => {
					if (result?.responseStatusCode === 200 && result.data) {
						setPreviewImage(result.data.basePath + result.data.imagePath)
					} else {
						setPreviewImage(experience?.cover || null)
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setPreviewImage(experience?.cover || null)
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
				}
			}
		)
	}

	const {mutate: mutateDeleteCover} = useMutation(deleteExperienceCover, {
		networkMode: 'always'
	})

	const handleDeleteCover = () => {
		mutateDeleteCover(
			{
				experienceId: experience?._id!
			},
			{
				onSuccess: result => {
					if (result.responseStatusCode === 200) {
						setPreviewImage(null)
					} else {
						setPreviewImage(experience?.cover || null)
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setPreviewImage(experience?.cover || null)
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
				}
			}
		)
	}

	useEffect(() => {
		if (experience && firstLoad) {
			setExperienceStatus(experience?.status)
			listEditions()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [experience, firstLoad])

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link to="/products">
						<span>{t('experiences')}</span>
					</Link>
					{isFetching ? (
						<Skeleton variant="rounded" height={12} width={180} />
					) : (
						<span>{experience?.title}</span>
					)}
				</Breadcrumbs>
				<BackNavigation to="/products" fullWidth />
			</Container>
			<DashboardFullSection display="flex" flexDirection="column" gap={4}>
				<Box>
					<Box
						sx={theme => ({
							display: 'flex',
							alignItems: 'flex-start',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							columnGap: 2,
							minHeight: '40px',
							[theme.breakpoints.down('sm')]: {
								flexDirection: 'column',
								rowGap: 1
							}
						})}
					>
						<SectionTitle>
							{isFetching ? (
								<Skeleton width={300} />
							) : (
								<>
									{experience?.title}{' '}
									<ButtonIcon
										icon={<EditIcon size={18} />}
										title={t('to-edit')}
										onClick={handleNavigateToEdit}
									/>
								</>
							)}
						</SectionTitle>
						{isFetching ? (
							<Skeleton width={82} height={23} />
						) : (
							<Typography variant="body1" mt={1} alignSelf="start">
								<Status.Root
									isButton
									open={openStatusList}
									setAnchorEl={setAnchorElStatusList}
									onClick={() => setOpenStatusList(!openStatusList)}
								>
									<Status.Tag type={experienceStatus}>
										<Status.Label label={t(experienceStatus)} />
										<Status.Icon>
											<ArrowDropDownIcon />
										</Status.Icon>
										<Status.List
											anchorEl={anchorElStatusList}
											open={openStatusList}
											acceptedStatus={[
												{label: t('to-active'), key: 'active'},
												{label: t('to-pause'), key: 'paused'},
												{label: t('to-archive'), key: 'archived'}
											]}
											handleClick={handleStatusChange}
											handleClose={() => setAnchorElStatusList(null)}
										/>
									</Status.Tag>
								</Status.Root>
							</Typography>
						)}
					</Box>
					<AlertMessage
						open={openAlert}
						severity="error"
						message={alertMessage}
						onClose={() => {
							setOpenAlert(false)
						}}
					/>
					<Box
						sx={theme => ({
							display: 'flex',
							justifyContent: 'space-between',
							mt: 3,
							columnGap: 7.5,
							rowGap: 4,
							flex: 1,
							[theme.breakpoints.down('md')]: {
								flexDirection: 'column'
							}
						})}
					>
						<Box
							sx={theme => ({
								display: 'flex',
								gap: 2,
								flex: 1,
								[theme.breakpoints.between('md', 1100)]: {
									flexDirection:
										(experience?.description?.length || 0) > 450
											? 'column'
											: 'row'
								},
								[theme.breakpoints.down('sm')]: {
									flexDirection: 'column'
								}
							})}
						>
							<CoverUpload
								url={previewImage}
								label={t('label-upload-experience-cover')}
								previewImage={previewImage}
								setPreviewImage={setPreviewImage}
								uploadStatus={status}
								onUpload={handleUploadCover}
								onDelete={handleDeleteCover}
							/>
							<Box
								sx={{
									flexGrow: 1
								}}
							>
								<Typography variant="body1">
									{isFetching ? (
										<>
											<Skeleton width="70%" />
											<Skeleton width="80%" />
										</>
									) : (
										experience?.description
									)}
								</Typography>
							</Box>
						</Box>
						<Box display="grid">
							<Box className="hidden-scrollbar" sx={style.muiCardBox}>
								<Card
									variant="elevation"
									elevation={0}
									sx={[style.muiCard, {minWidth: 170}]}
								>
									<Typography variant="subtitle2">
										{t('total-revenue')}
									</Typography>
									<Box
										sx={theme => ({
											display: 'flex',
											justifyContent: 'center',
											flexGrow: 1,
											alignItems: 'center',
											color: theme.palette.success.main
										})}
									>
										{isFetching ? (
											<Skeleton width={100} />
										) : (
											<Price
												value={experience?.sales?.revenue}
												variant="h6"
												color="success.main"
											/>
										)}
									</Box>
								</Card>
								<Card
									variant="elevation"
									elevation={0}
									sx={[style.muiCard, {minWidth: 192}]}
								>
									<Typography variant="subtitle2">
										{t('total-spots-sold')}
									</Typography>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											flexGrow: 1,
											alignItems: 'center'
										}}
									>
										<Typography
											variant="h6"
											align="center"
											sx={{whiteSpace: 'nowrap', p: 1}}
										>
											{isFetching ? (
												<Skeleton width={50} />
											) : (
												experience?.sales?.spotsSold || 0
											)}
										</Typography>
									</Box>
								</Card>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box>
					{experience && (
						<SalePageManager
							data={{
								salePage: experience.salePage
									? {
											_id: experience.salePage._id,
											published: experience.salePage.published,
											disablePublish: experience.salePage.disablePublish,
											sectionsCount: experience.salePage.sectionsCount
									  }
									: undefined,
								experience: {
									_id: experience._id,
									slug: experience.slug,
									status: experience.status
								}
							}}
						/>
					)}
					<Editions
						experienceSlug={experience?.slug!}
						editions={editions}
						isLoading={isLoadingEditions}
						handleClickCreateEdition={handleNavigateToCreateEdition}
						handleLoadMore={loadMore ? listEditions : undefined}
						openAlert={openAlertEdition}
						alertMessage={alertMessageEdition}
						onCloseAlert={() => setOpenAlertEdition(false)}
						handleListEditions={listEditions}
						status={editionListStatus}
					/>
					{experience ? <Integrations experienceId={experience?._id} /> : null}
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

const style = {
	iconButton: (theme: Theme) => ({
		backgroundColor: theme.palette.primary.main,
		boxShadow:
			'0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			transition: 'backgroundColor 0.2s ease'
		}
	}),
	muiCardBox: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column',
		columnGap: 2,
		rowGap: 2,
		overflowX: 'auto',
		maxWidth: '100%',
		scrollSnapType: 'x mandatory',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'row'
		}
	}),
	muiCard: (theme: Theme) => ({
		border: `1px solid ${theme.palette.grey[300]}`,
		display: 'flex',
		flexDirection: 'column',
		height: 80,
		py: 1,
		px: 1.75, // 14px
		width: 200,
		[theme.breakpoints.down('sm')]: {
			width: '50%'
		}
	})
}

export default ShowExperience
