import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FaHome as HomeIcon} from 'react-icons/fa'
import {useMutation} from '@tanstack/react-query'
import {useLocation, useSearchParams} from 'react-router-dom'
import {Box, Breadcrumbs, Button, Container, Typography} from '@mui/material'

import {ExperienceListItem} from '../../entities/Experience'
import {listExperiencesBySeller} from '../../api/experiences'
import Footer from '../../components/template/Footer'
import Link from '../../components/navigation/Link'
import SectionTitle from '../../components/typography/SectionTitle'
import AlertDialog from '../../components/AlertDialog'
import useNavigate from '../../hooks/useNavigate'
import ExperiencesList from './ExperiencesList'
import Mobile from '../../components/layout/responsive/Mobile'
import MobileUp from '../../components/layout/responsive/MobileUp'
import {makeImageURL} from '../../helpers/factories/imageURLFactory'
import {DashboardFullSection} from '../../components/template/DashboardFullSection'
import useProductStates from '../../stores/useProductsStates'

const Experiences = () => {
	const {listLimit: limit, setListLimit: setLimit} = useProductStates()

	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [experiences, setExperiences] = useState<ExperienceListItem[]>()
	const [firstLoad, setFirstLoad] = useState(true)
	const [offset, setOffset] = useState(0)
	const [page, setPage] = useState(0)
	const [total, setTotal] = useState(0)
	const {t} = useTranslation()
	const {state} = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const {mutate, status, error} = useMutation(listExperiencesBySeller, {
		networkMode: 'always'
	})

	const handleChangeURL = (page: number) => {
		const urlParams = new URLSearchParams(window.location.search)
		urlParams.set('page', page.toString())
		const newURL = `${window.location.pathname}?${urlParams.toString()}`
		window.history.replaceState(undefined, 'Dashboard | Excofy', newURL)
	}

	const listExperiences = useCallback(
		async () =>
			mutate(
				{
					offset,
					limit
				},
				{
					onSuccess: async result => {
						if (result?.responseStatusCode === 200 && result.data) {
							if (firstLoad) {
								setFirstLoad(false)
							}

							setTotal(
								offset === 0
									? result.data.experiences.length
									: result.data.experiences.length + offset
							)

							if (offset !== 0 && result.data.experiences.length === 0) {
								const newOffset = offset - limit
								setOffset(newOffset)
								setPage(newOffset / limit)
							} else {
								const experiencesMapped = result.data.experiences.map(
									experience => ({
										...experience,
										cover:
											experience?.cover &&
											makeImageURL({
												basePath: experience.cover.basePath,
												imagePath: experience.cover.imagePath
											})
									})
								)

								setExperiences(experiencesMapped)
								setPage(offset / limit)
								handleChangeURL(offset / limit)
							}
						} else if (result.error?.message) {
							setAlertMessage(result.error.message)
							setOpenAlert(true)
						} else {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
					}
				}
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[offset, limit]
	)

	const handleCloseAlert = () => {
		setOpenAlert(false)
		setAlertMessage('')
	}

	const handleNavigateToCreateExperience = () => {
		navigate('/products/create')
	}

	useEffect(() => {
		listExperiences()
		if (state?.alertDialog) {
			setAlertMessage(state.alertDialog.message)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listExperiences])

	useEffect(() => {
		const page = searchParams.get('page')

		if (page) {
			setOffset(+page * limit)
		}
	}, [limit, searchParams])

	return (
		<>
			<AlertDialog
				severity={state?.alertDialog?.severity}
				open={openAlert}
				message={alertMessage}
				onClose={handleCloseAlert}
			/>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<span>{t('experiences')}</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<Box>
					<Box>
						<Mobile>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'wrap',
									justifyContent: 'space-between',
									gap: 1,
									mb: 4
								}}
							>
								<SectionTitle>{t('experiences')}</SectionTitle>
								<Typography variant="body1">
									{t('experiences-description')}
								</Typography>
								{experiences && experiences.length > 0 && (
									<Button
										variant="contained"
										onClick={handleNavigateToCreateExperience}
										sx={theme => ({
											[theme.breakpoints.down('sm')]: {
												width: '100%',
												mt: 1
											}
										})}
									>
										{t('to-create-product-or-service')}
									</Button>
								)}
							</Box>
						</Mobile>
						<MobileUp>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'wrap',
									justifyContent: 'space-between',
									gap: 2,
									mb: 4
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										flexWrap: 'wrap',
										justifyContent: 'space-between',
										gap: 2,
										width: '100%'
									}}
								>
									<SectionTitle>{t('experiences')}</SectionTitle>
									{experiences && experiences.length > 0 && (
										<Button
											variant="contained"
											onClick={handleNavigateToCreateExperience}
										>
											{t('to-create-product-or-service')}
										</Button>
									)}
								</Box>
								<Typography variant="body1">
									{t('experiences-description')}
								</Typography>
							</Box>
						</MobileUp>
					</Box>
					{!error ? (
						<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
							<ExperiencesList
								experiences={experiences}
								limit={limit}
								page={page}
								status={status}
								total={total}
								setLimit={setLimit}
								setOffset={setOffset}
								setPage={setPage}
								setExperiences={setExperiences}
							/>
						</Box>
					) : (
						<Button variant="outlined" onClick={listExperiences}>
							{t('to-list-experiences')}
						</Button>
					)}
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

export default Experiences
