import {
	RefCallback,
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react'
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Collapse,
	FormGroup,
	IconButton,
	InputAdornment,
	InputBaseComponentProps,
	Skeleton,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {CgClose as CloseIcon} from 'react-icons/cg'
import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import useUpdateCompanySchema from '../../../schemas/useUpdateCompanySchema'
import {useFormik} from 'formik'
import {IMaskInput} from 'react-imask'
import {listCompany, searchCompany, updateCompany} from '../../../api/company'
import SectionTitle from '../../typography/SectionTitle'
import {findAddressByZipCode} from '../../../api/zipCode'
import {regex} from '../../../helpers/regex'
import useAuthStates from '../../../stores/useAuthStates'
import Tag from '../../typography/Tag'

interface CustomOnChangeProps {
	onChange: (event: {target: {name: string; value: string}}) => void
	name: string
}

const InputPhoneMask = forwardRef<
	HTMLInputElement,
	Omit<InputBaseComponentProps, 'onChange'> & CustomOnChangeProps
>((props, ref) => {
	const {onChange, ...other} = props
	return (
		<IMaskInput
			{...other}
			mask="(00) 00000-0000"
			inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
			onAccept={value =>
				onChange({target: {name: props.name, value: value as string}})
			}
			overwrite
		/>
	)
})

const InputCNPJMask = forwardRef<
	HTMLInputElement,
	Omit<InputBaseComponentProps, 'onChange'> & CustomOnChangeProps
>((props, ref) => {
	const {onChange, ...other} = props
	return (
		<IMaskInput
			{...other}
			mask="00.000.000/0000-00"
			inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
			onAccept={value =>
				onChange({target: {name: props.name, value: value as string}})
			}
			overwrite
		/>
	)
})

const InputZipCodeMask = forwardRef<
	HTMLInputElement,
	Omit<InputBaseComponentProps, 'onChange'> & CustomOnChangeProps
>((props, ref) => {
	const {onChange, ...other} = props
	return (
		<IMaskInput
			{...other}
			mask="00000-000"
			inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
			onAccept={value =>
				onChange({target: {name: props.name, value: value as string}})
			}
			overwrite
		/>
	)
})

const FormUpdateCompany = () => {
	const [alert, setAlert] = useState<{
		type?: 'success' | 'error' | 'warning'
		message?: string
		show: boolean
	}>({
		type: 'error',
		message: '',
		show: false
	})
	const [isSearchingCompany, setIsSearchingCompany] = useState<boolean>(false)
	const [companyListed, setCompanyListed] = useState<boolean>(false)
	const [errorOnListCompany, setErrorOnListCompany] = useState<boolean>(false)
	const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(false)
	const [lastValidCnpj, setLastValidCnpj] = useState<string>('')
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const {company, setCompany} = useAuthStates(state => state)
	const [lastValidZipCode, setLastValidZipCode] = useState<string>(
		company?.zipCode || ''
	)

	const alertRef = useRef<HTMLDivElement>(null)
	const cnpjRef = useRef<HTMLInputElement>(null)
	const legalNameRef = useRef<HTMLInputElement>(null)
	const fantasyNameRef = useRef<HTMLInputElement>(null)
	const emailRef = useRef<HTMLInputElement>(null)
	const phoneRef = useRef<HTMLInputElement>(null)
	const addressRef = useRef<HTMLInputElement>(null)
	const districtRef = useRef<HTMLInputElement>(null)
	const cityRef = useRef<HTMLInputElement>(null)
	const stateRef = useRef<HTMLInputElement>(null)
	const zipCodeRef = useRef<HTMLInputElement>(null)
	const streetNumberRef = useRef<HTMLInputElement>(null)
	const complementaryAddressRef = useRef<HTMLInputElement>(null)
	const referencePointRef = useRef<HTMLInputElement>(null)

	const {t} = useTranslation()
	const companySchema = useUpdateCompanySchema()

	const {isLoading, mutate} = useMutation(updateCompany, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			cnpj: company?.cnpj || '',
			legalName: company?.legalName || '',
			fantasyName: company?.fantasyName || '',
			email: company?.email || '',
			phone: company?.phone || '',
			address: company?.address || '',
			district: company?.district || '',
			streetNumber: company?.streetNumber || '',
			complementaryAddress: company?.complementaryAddress || '',
			city: company?.city || '',
			state: company?.state || '',
			zipCode: company?.zipCode || '',
			referencePoint: company?.referencePoint || ''
		},
		enableReinitialize: true,
		validationSchema: companySchema,
		onSubmit: values => {
			setAlert({show: false})
			mutate(
				{
					...values,
					streetNumber: Number(values.streetNumber)
				},
				{
					onSuccess: ({responseStatusCode, error, data}) => {
						if (responseStatusCode === 200 && data) {
							setAlert({
								type: 'success',
								message: t('saved-changes'),
								show: true
							})
							setShowSuccessButton(true)
							setCompany({...data, cnpj: company?.cnpj as string})
						}

						if (responseStatusCode === 400) {
							if (error?.fields) {
								Object.entries(error.fields).forEach(([key, value]) => {
									formik.setFieldError(key, value[0].message)
								})

								const firstFieldWithError = Object.keys(error.fields).find(
									key => key
								)

								if (firstFieldWithError) {
									focusField(firstFieldWithError)
								}
							}
						}

						if (responseStatusCode === 403 || responseStatusCode === 500) {
							setAlert({
								type: 'error',
								message: t('error-server-default'),
								show: true
							})
							alertRef.current?.scrollIntoView({
								behavior: 'smooth',
								block: 'center'
							})
						}
					},
					onError: error => {
						setAlert({
							type: 'error',
							message: t('error-server-default'),
							show: true
						})
						alertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
						console.error(error)
					}
				}
			)
		}
	})

	const searchCompanyByCnpj = () => {
		if (
			formik.values.cnpj.length === 18 &&
			!isSearchingCompany &&
			lastValidCnpj !== formik.values.cnpj
		) {
			setIsSearchingCompany(true)
			searchCompany({cnpj: formik.values.cnpj})
				.then(result => {
					if (result.success && result.data) {
						setAlert({show: false})
						setLastValidCnpj(formik.values.cnpj)

						if (lastValidCnpj !== result.data.cnpj) {
							Object.entries(result.data).forEach(([key, value]) => {
								formik.setFieldValue(key, value)
							})
						}
					}

					if (result.error && result.responseStatusCode === 400) {
						setAlert({
							show: true,
							message: t('error-cnpj-not-found-alert'),
							type: 'warning'
						})
						formik.setFieldError('cnpj', t('error-cnpj-not-found'))
						alertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
					}

					if (result.error && result.responseStatusCode === 401) {
						formik.setFieldError('cnpj', t('error-cnpj-inactive'))
						setAlert({
							show: true,
							message: t('error-cnpj-inactive-alert'),
							type: 'error'
						})
						alertRef.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
					}
				})
				.catch(error => {
					console.error(error)
				})
				.finally(() => {
					setIsSearchingCompany(false)
				})
		}
	}

	const listCompanyInfo = useCallback(async () => {
		setAlert({show: false})
		setCompanyListed(false)
		setErrorOnListCompany(false)
		setIsLoadingCompany(true)
		listCompany()
			.then(result => {
				if (result.responseStatusCode === 200 && result.data) {
					setCompanyListed(true)
					setCompany(result.data)
					setLastValidCnpj(result.data.cnpj)
					setLastValidZipCode(result.data.zipCode)
				} else {
					setErrorOnListCompany(true)
					setAlert({
						show: true,
						message: t('error-list-company-alert'),
						type: 'error'
					})
				}
			})
			.catch(error => {
				setErrorOnListCompany(true)
				setAlert({
					show: true,
					message: t('error-list-company-alert'),
					type: 'error'
				})
			})
			.finally(() => {
				setIsLoadingCompany(false)
			})
	}, [t])

	const focusField = (field: string) => {
		switch (field) {
			case 'cnpj':
				cnpjRef.current?.focus()
				break
			case 'legalName':
				legalNameRef.current?.focus()
				break
			case 'fantasyName':
				fantasyNameRef.current?.focus()
				break
			case 'email':
				emailRef.current?.focus()
				break
			case 'phone':
				phoneRef.current?.focus()
				break
			case 'address':
				addressRef.current?.focus()
				break
			case 'district':
				districtRef.current?.focus()
				break
			case 'streetNumber':
				streetNumberRef.current?.focus()
				break
			case 'complementaryAddress':
				complementaryAddressRef.current?.focus()
				break
			case 'referencePoint':
				referencePointRef.current?.focus()
				break
			case 'city':
				cityRef.current?.focus()
				break
			case 'state':
				stateRef.current?.focus()
				break
			case 'zipCode':
				zipCodeRef.current?.focus()
				break
			default:
				break
		}
	}

	const handleFocusOnFirstFieldWithError = () => {
		const firstFieldWithError = Object.keys(formik.errors).find(
			(key: string) => key
		)

		if (firstFieldWithError) {
			focusField(firstFieldWithError)
		}
	}

	const handleOnChangeCompany = () => {
		setShowSuccessButton(false)
		setAlert({show: false})
	}

	const {isLoading: isSearchingAddress, mutate: mutateSearchAddress} =
		useMutation(findAddressByZipCode, {networkMode: 'always'})

	const searchAddressByZipCode = () => {
		if (
			!formik.errors.zipCode &&
			!isSearchingAddress &&
			lastValidZipCode !== regex.removeAllNonDigits(formik.values.zipCode)
		) {
			mutateSearchAddress(regex.removeAllNonDigits(formik.values.zipCode), {
				onSuccess: ({responseStatusCode, data}) => {
					if (responseStatusCode === 200 && data) {
						setLastValidZipCode(formik.values.zipCode)
						formik.setFieldValue('address', data.street)
						formik.setFieldValue('district', data.neighborhood)
						formik.setFieldValue('city', data.city)
						formik.setFieldValue('state', data.state)
					}
				},
				onError: error => {
					console.error(error)
				}
			})
		}
	}

	useEffect(() => {
		if (!companyListed) {
			listCompanyInfo()
		}
	}, [companyListed, listCompanyInfo])

	return (
		<Box
			onSubmit={formik.handleSubmit}
			onChange={handleOnChangeCompany}
			component="form"
			autoComplete="off"
			sx={style.formBox}
		>
			<SectionTitle>{t('edit-company-info')}</SectionTitle>
			{isLoadingCompany && (
				<Stack direction="column" spacing={4} sx={{mt: 4}}>
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rectangular" height={40} sx={{maxWidth: 200}} />
				</Stack>
			)}

			{errorOnListCompany && (
				<Button
					size="large"
					variant="outlined"
					type="button"
					sx={{width: '100%', mt: 4}}
					onClick={listCompanyInfo}
				>
					{isLoadingCompany ? (
						<CircularProgress color="inherit" size={25} />
					) : (
						t('to-try-again')
					)}
				</Button>
			)}

			{companyListed && (
				<Stack direction="column" spacing={4}>
					<Collapse ref={alertRef} in={alert.show}>
						<Alert
							severity={alert.type}
							action={
								<IconButton
									aria-label={t('aria-close-alert')}
									color="inherit"
									size="small"
									onClick={() => {
										setAlert({show: false})
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{mt: 3}}
						>
							{alert.message}
						</Alert>
					</Collapse>
					<FormGroup
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1
						}}
					>
						<Typography variant="h6">{t('basic-info')}</Typography>
						<TextField
							name="cnpj"
							type="text"
							inputRef={cnpjRef}
							InputProps={{
								inputComponent: InputCNPJMask as any,
								endAdornment: (
									<InputAdornment position="end">
										{isSearchingCompany && (
											<CircularProgress color="primary" size={20} />
										)}
									</InputAdornment>
								)
							}}
							autoFocus
							label={t('cnpj')}
							variant="outlined"
							helperText={formik.touched.cnpj && formik.errors.cnpj}
							error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
							margin="normal"
							value={formik.values.cnpj}
							onKeyUp={searchCompanyByCnpj}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
							sx={{mb: 0}}
							disabled={true}
						/>
						<TextField
							name="legalName"
							type="text"
							inputRef={legalNameRef}
							label={t('legal-name')}
							variant="outlined"
							helperText={formik.touched.legalName && formik.errors.legalName}
							error={
								formik.touched.legalName && Boolean(formik.errors.legalName)
							}
							margin="normal"
							value={formik.values.legalName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
						/>
						<TextField
							name="fantasyName"
							type="text"
							inputRef={fantasyNameRef}
							label={t('fantasy-name')}
							variant="outlined"
							helperText={
								formik.touched.fantasyName && formik.errors.fantasyName
							}
							error={
								formik.touched.fantasyName && Boolean(formik.errors.fantasyName)
							}
							margin="normal"
							value={formik.values.fantasyName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
						/>
						<TextField
							name="email"
							type="email"
							inputRef={emailRef}
							label={t('email')}
							variant="outlined"
							helperText={formik.touched.email && formik.errors.email}
							error={formik.touched.email && Boolean(formik.errors.email)}
							margin="normal"
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
						/>
						<TextField
							name="phone"
							type="text"
							inputRef={phoneRef}
							InputProps={{
								inputComponent: InputPhoneMask as any
							}}
							label={t('phone')}
							variant="outlined"
							helperText={formik.touched.phone && formik.errors.phone}
							error={formik.touched.phone && Boolean(formik.errors.phone)}
							margin="normal"
							value={formik.values.phone}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
						/>
					</FormGroup>
					<FormGroup
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1
						}}
					>
						<Typography variant="h6">{t('address')}</Typography>
						<TextField
							name="zipCode"
							type="text"
							inputRef={zipCodeRef}
							InputProps={{
								inputComponent: InputZipCodeMask as any,
								endAdornment: (
									<InputAdornment position="end">
										{isSearchingAddress && (
											<CircularProgress color="primary" size={20} />
										)}
									</InputAdornment>
								)
							}}
							label={t('zip-code')}
							variant="outlined"
							helperText={formik.touched.zipCode && formik.errors.zipCode}
							error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
							margin="normal"
							value={formik.values.zipCode}
							onChange={formik.handleChange}
							onBlur={event => {
								formik.handleBlur(event)
								searchAddressByZipCode()
							}}
							size="small"
						/>
						<Box
							sx={theme => ({
								display: 'flex',
								flexDirection: 'row',
								gap: 2,
								[theme.breakpoints.down('sm')]: {
									flexDirection: 'column',
									gap: 1
								}
							})}
						>
							<TextField
								name="address"
								type="text"
								inputRef={addressRef}
								label={t('street')}
								variant="outlined"
								helperText={formik.touched.address && formik.errors.address}
								error={formik.touched.address && Boolean(formik.errors.address)}
								margin="normal"
								value={formik.values.address}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
								fullWidth
							/>
							<TextField
								name="streetNumber"
								type="text"
								inputRef={streetNumberRef}
								label={t('street-number')}
								variant="outlined"
								helperText={
									formik.touched.streetNumber && formik.errors.streetNumber
								}
								error={
									formik.touched.streetNumber &&
									Boolean(formik.errors.streetNumber)
								}
								margin="normal"
								value={formik.values.streetNumber}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
								sx={{
									minWidth: '200px'
								}}
							/>
						</Box>
						<TextField
							name="complementaryAddress"
							type="text"
							inputRef={complementaryAddressRef}
							label={t('complementary-address')}
							variant="outlined"
							helperText={
								formik.touched.complementaryAddress &&
								Boolean(formik.errors.complementaryAddress) ? (
									formik.errors.complementaryAddress
								) : (
									<Tag>{t('optional')}</Tag>
								)
							}
							error={
								formik.touched.complementaryAddress &&
								Boolean(formik.errors.complementaryAddress)
							}
							margin="normal"
							value={formik.values.complementaryAddress}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
						/>
						<TextField
							name="referencePoint"
							type="text"
							inputRef={referencePointRef}
							label={t('reference-point')}
							variant="outlined"
							helperText={
								formik.touched.referencePoint &&
								Boolean(formik.errors.referencePoint) ? (
									formik.errors.referencePoint
								) : (
									<Tag>{t('optional')}</Tag>
								)
							}
							error={
								formik.touched.referencePoint &&
								Boolean(formik.errors.referencePoint)
							}
							margin="normal"
							value={formik.values.referencePoint}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
						/>
						<TextField
							name="district"
							type="text"
							inputRef={districtRef}
							label={t('district')}
							variant="outlined"
							helperText={formik.touched.district && formik.errors.district}
							error={formik.touched.district && Boolean(formik.errors.district)}
							margin="normal"
							value={formik.values.district}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							size="small"
						/>
						<Box
							sx={theme => ({
								display: 'flex',
								flexDirection: 'row',
								gap: 2,
								[theme.breakpoints.down('sm')]: {
									flexDirection: 'column',
									gap: 1
								}
							})}
						>
							<TextField
								name="city"
								type="text"
								inputRef={cityRef}
								label={t('city')}
								variant="outlined"
								helperText={formik.touched.city && formik.errors.city}
								error={formik.touched.city && Boolean(formik.errors.city)}
								margin="normal"
								value={formik.values.city}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
								fullWidth
							/>
							<TextField
								name="state"
								type="text"
								inputRef={stateRef}
								label={t('state')}
								variant="outlined"
								helperText={formik.touched.state && formik.errors.state}
								error={formik.touched.state && Boolean(formik.errors.state)}
								margin="normal"
								value={formik.values.state}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size="small"
								sx={{
									minWidth: '200px'
								}}
							/>
						</Box>
					</FormGroup>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={style.successButton}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							size="large"
							variant="contained"
							type="submit"
							disabled={isLoading}
							sx={style.formSubmitButton}
							onClick={handleFocusOnFirstFieldWithError}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={25} />
							) : (
								t('to-save-changes')
							)}
						</Button>
					)}
				</Stack>
			)}
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => ({
		my: 5,
		mt: 0,
		pt: 0,
		width: '100%',
		maxWidth: '520px'
	}),
	formSubmitButton: (theme: Theme) => ({
		mt: 5,
		height: '44px',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	successButton: (theme: Theme) => ({
		height: '44px',
		backgroundColor: 'success.light',
		color: 'success.contrastText',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		'&:hover': {backgroundColor: 'success.light'}
	})
}

export default FormUpdateCompany
