import ExperienceCard from '../../components/ExperienceCard'
import Skeleton from '@mui/material/Skeleton'
import {useTranslation} from 'react-i18next'
import {Card} from '../../components/Card'
import Typography from '@mui/material/Typography'
import {ExperienceListItem} from '../../entities/Experience'
import TablePagination from '@mui/material/TablePagination'
import useNavigate from '../../hooks/useNavigate'
import useAppStates from '../../stores/useAppStates'

interface EmptyStateExperienceCardProps {
	onClick: () => void
}

const EmptyStateExperienceCard = ({onClick}: EmptyStateExperienceCardProps) => {
	const {t} = useTranslation()

	return (
		<Card.Root>
			<Card.Body grid>
				<Card.Info>
					<Card.Title variant="h6">
						{t('empty-state-experience-title')}
					</Card.Title>
					<Card.Describe>
						<Typography variant="body2" sx={{mt: 1}}>
							{t('info-product-and-services')}
						</Typography>
					</Card.Describe>
				</Card.Info>
				<Card.Button onClick={onClick} withBorder>
					{t('to-create-product-or-service')}
				</Card.Button>
			</Card.Body>
		</Card.Root>
	)
}

interface ExperiencesListProps {
	experiences?: ExperienceListItem[]
	limit: number
	page: number
	total: number
	status: 'success' | 'loading' | 'error' | 'idle'
	setLimit: (limit: number) => void
	setOffset: (offset: number) => void
	setPage: (page: number) => void
	setExperiences: (experiences: ExperienceListItem[]) => void
}

const ExperiencesList = ({
	experiences,
	limit,
	page,
	status,
	total,
	setLimit,
	setOffset,
	setPage,
	setExperiences
}: ExperiencesListProps) => {
	const navigate = useNavigate()
	const {t} = useTranslation()
	const {currentLang} = useAppStates()

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage)
		setOffset(newPage * limit)
		window.history.replaceState(
			undefined,
			'Dashboard | Excofy',
			`/${currentLang}/products?page=${newPage}`
		)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setLimit(+event.target.value)
		setOffset(0)
		setPage(0)
	}

	const handleNavigateToCreateExperience = () => {
		navigate('/products/create')
	}

	const handleChangeExperience = (experience: ExperienceListItem) => {
		setExperiences(
			experiences
				? experiences?.map(experienceMapped =>
						experienceMapped._id === experience._id
							? experience
							: experienceMapped
				  )
				: []
		)
	}

	return (
		<>
			{(status === 'loading' || experiences === undefined) && (
				<>
					<Skeleton variant="rounded" height={340} />
					<Skeleton variant="rounded" height={340} />
					<Skeleton variant="rounded" height={340} />
				</>
			)}

			{status !== 'loading' && experiences && experiences?.length > 0 ? (
				<>
					{experiences
						.sort((a, b) => {
							if (a.sales.revenue > b.sales.revenue) {
								return -1
							} else if (a.sales.revenue < b.sales.revenue) {
								return 1
							}
							return 0
						})
						.map((experience, i) => (
							<ExperienceCard
								key={`experience-list-item-${i}-${experience.slug}`}
								experience={experience}
								onChange={handleChangeExperience}
							/>
						))
						.slice(0, limit)}
					<TablePagination
						rowsPerPageOptions={[5, 10, 15, 25]}
						component="div"
						count={total}
						rowsPerPage={limit}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage={t('items-per-page')}
						labelDisplayedRows={({from, to, count}) =>
							t('list-filter-info', {from, to, count})
						}
					/>
				</>
			) : null}

			{status !== 'loading' && total === 0 ? (
				<EmptyStateExperienceCard onClick={handleNavigateToCreateExperience} />
			) : null}
		</>
	)
}

export default ExperiencesList
