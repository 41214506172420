import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {date} from '../helpers/date'
import {regex} from '../helpers/regex'
import {INPUT} from '../config'

const useEditionSchema = () => {
	const {t} = useTranslation()

	const editionSchema = yup.object({
		title: yup
			.string()
			.required(t('error-title-required'))
			.min(
				INPUT.EDITION.title.min,
				t('error-title-min-length', {
					min: INPUT.EDITION.title.min
				})
			)
			.max(
				INPUT.EDITION.title.max,
				t('error-title-max-length', {
					max: INPUT.EDITION.title.max
				})
			),
		slug: yup
			.string()
			.required(t('error-slug-required'))
			.matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t('error-slug-invalid'))
			.min(INPUT.EDITION.slug.min, t('error-slug-min-length'))
			.max(INPUT.EDITION.slug.max, t('error-slug-max-length')),
		description: yup
			.string()
			.required(t('error-description-required'))
			.min(
				INPUT.EDITION.description.min,
				t('error-description-min-length', {
					minLength: INPUT.EDITION.description.min
				})
			)
			.max(
				INPUT.EDITION.description.max,
				t('error-description-max-length', {
					maxLength: INPUT.EDITION.description.max
				})
			),
		price: yup
			.string()
			.transform(value => String(regex.parseBRLCurrencyToFloat(value)))
			.required(t('error-price-required')),
		vacancies: yup
			.string()
			.typeError(t('error-vacancies-invalid'))
			.min(INPUT.EDITION.vacancies.min),
		startDate: yup
			.date()
			.nullable()
			.typeError(t('error-date-invalid'))
			.test('is-valid', t('error-date-invalid'), value => {
				if (value) {
					const dateToValidate = new Date(value).toString()
					return date.isValid(dateToValidate)
				}

				return true
			}),
		limitDateToSell: yup
			.date()
			.nullable()
			.typeError(t('error-date-invalid'))
			.test('is-valid', t('error-date-invalid'), value => {
				if (value) {
					const dateToValidate = new Date(value).toString()
					return date.isValid(dateToValidate)
				}

				return true
			})
	})

	return editionSchema
}

export default useEditionSchema
