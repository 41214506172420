import {useState} from 'react'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import useNavigate from '../../../hooks/useNavigate'
import {Theme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import OutlinedInput from '@mui/material/OutlinedInput'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'

import {
	MdOutlineVisibility as Visibility,
	MdOutlineVisibilityOff as VisibilityOff
} from 'react-icons/md'
import {CgClose as CloseIcon} from 'react-icons/cg'

import useSignUpSchema from '../../../schemas/useSignUpSchema'
import SectionTitle from '../../typography/SectionTitle'
import ButtonLink from '../../navigation/ButtonLink'
import {signUp, checkEmail} from '../../../api/signUp'

const FormSignUp = () => {
	const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
	const [errorAlert, setErrorAlert] = useState<boolean>(false)
	const [serverError, setServerError] = useState<string>('')
	const [lastEmailChecked, setLastEmailChecked] = useState<string>('')

	const {t} = useTranslation()
	const signUpSchema = useSignUpSchema()
	const navigate = useNavigate()

	const {isLoading, mutate} = useMutation(
		(values: {email: string; password: string}) => signUp(values),
		{networkMode: 'always'}
	)

	const {mutate: mutateCheckEmail} = useMutation(
		(value: {email: string}) => checkEmail(value),
		{networkMode: 'always'}
	)

	const formik = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validationSchema: signUpSchema,
		onSubmit: values => {
			mutate(values, {
				onSuccess: (result, data) => {
					if (result?.responseStatusCode === 200 && result?.success) {
						sessionStorage.setItem('signup-code-validate-email', data.email)
						navigate('/sign-up/code-validate', {state: {email: data.email}})
					}
					if (result?.responseStatusCode === 400 && result?.error) {
						if (result?.error?.fields?.email) {
							formik.setFieldError(
								'email',
								result.error.fields.email[0].message
							)
							console.error(result.error.fields.email)
						}
						if (result?.error?.fields?.password) {
							formik.setFieldError(
								'password',
								result.error.fields.password[0].message
							)
							console.error(result.error.fields.password)
						}
					} else {
						setErrorAlert(true)
						setServerError(t('error-server-default'))
					}
				},
				onError: error => {
					setErrorAlert(true)
					setServerError(t('error-server-default'))
					console.error(error)
				}
			})
		}
	})

	const checkAvailableEmail = () => {
		if (
			!formik.errors.email &&
			formik.values.email !== '' &&
			lastEmailChecked !== formik.values.email
		) {
			setLastEmailChecked(formik.values.email)
			mutateCheckEmail(
				{email: formik.values.email},
				{
					onSuccess: result => {
						if (
							result?.responseStatusCode === 400 &&
							result?.error?.fields?.email
						) {
							formik.setFieldError(
								'email',
								result.error.fields.email[0].message
							)
						}
					}
				}
			)
		}
	}

	return (
		<Box sx={style.formBox}>
			<SectionTitle>{t('sign-up-to-excofy')}</SectionTitle>
			<Typography>{t('start-account-for-free')}</Typography>
			<Collapse in={errorAlert}>
				<Alert
					severity="error"
					action={
						<IconButton
							aria-label={t('aria-close-alert')}
							color="inherit"
							size="small"
							onClick={() => {
								setErrorAlert(false)
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{mt: 3}}
				>
					{serverError}
				</Alert>
			</Collapse>
			<Box
				onSubmit={formik.handleSubmit}
				component="form"
				autoComplete="off"
				sx={{mt: 3}}
			>
				<Stack direction="column" spacing={4}>
					<TextField
						name="email"
						type="email"
						label={t('email')}
						variant="outlined"
						helperText={formik.touched.email && formik.errors.email}
						error={formik.touched.email && Boolean(formik.errors.email)}
						margin="normal"
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={event => {
							formik.handleBlur(event)
							checkAvailableEmail()
						}}
					/>
					<FormControl
						variant="outlined"
						error={formik.touched.password && Boolean(formik.errors.password)}
					>
						<InputLabel htmlFor="outlined-password">{t('password')}</InputLabel>
						<OutlinedInput
							id="outlined-password"
							type={passwordVisible ? 'text' : 'password'}
							name="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label={
											passwordVisible
												? t('aria-hide-password')
												: t('aria-show-password')
										}
										onClick={() => setPasswordVisible(!passwordVisible)}
										edge="end"
									>
										{passwordVisible ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label={t('password')}
						/>
						<FormHelperText>
							{formik.touched.password && formik.errors.password
								? formik.errors.password
								: t('error-password-invalid')}
						</FormHelperText>
					</FormControl>
					<Button
						size="large"
						variant="contained"
						type="submit"
						disabled={isLoading}
						sx={{height: '44px'}}
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('to-sign-up')
						)}
					</Button>
					<Divider sx={{pt: 3}}>{t('already-have-account')}</Divider>
					<Stack direction="row" justifyContent="center">
						<ButtonLink
							to="/login"
							size="large"
							variant="outlined"
							sx={{width: '70%'}}
						>
							{t('log-in')}
						</ButtonLink>
					</Stack>
				</Stack>
			</Box>
		</Box>
	)
}

const style = {
	formBox: (theme: Theme) => {
		return {
			mx: 'auto',
			my: 5,
			padding: 5,
			width: '80%',
			maxWidth: '600px',
			[theme.breakpoints.down('md')]: {
				my: 0,
				padding: 3,
				width: '100%'
			}
		}
	}
}

export default FormSignUp
