interface Image {
	basePath: string
	imagePath: string
	transform?: string
}

const makeImageURL = ({basePath, imagePath, transform}: Image) => {
	const options = transform ? `${transform}/` : ''
	return basePath + options + imagePath
}

export {makeImageURL}
