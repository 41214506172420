import {useTranslation} from 'react-i18next'
import {Dialog} from '../../../../components/Dialog'
import {
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Radio,
	RadioGroup,
	Stack,
	Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'

import {useCallback, useState} from 'react'
import {updateTransferSettings} from '../../../../api/payment-service/pagarme'
import {useMutation} from '@tanstack/react-query'
import usePaymentServicePagarmeStates from '../../../../stores/usePaymentServicePagarmeStates'
import AlertMessage from '../../../../components/AlertMessage'
import {TransferSettings} from '../../../../entities/PagarmeConfig'

interface FormTransferSettingsProps {
	open: boolean
	onClose: () => void
}

export const FormTransferSettings = ({
	open,
	onClose
}: FormTransferSettingsProps) => {
	const {t} = useTranslation()
	const {transferSettings, setTransferSettings} =
		usePaymentServicePagarmeStates()

	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)
	const [transferInterval, setTransferInterval] = useState<
		TransferSettings['transferInterval']
	>(transferSettings?.transferInterval || 'Monthly')
	const [transferIntervalError, setTransferIntervalError] = useState<string>('')

	const {mutate, isLoading} = useMutation(updateTransferSettings)

	const handleUpdatePagarmeServiceTransferSettings = () => {
		mutate(
			{transferInterval},
			{
				onSuccess: ({error, responseStatusCode, success}) => {
					if (success) {
						setTransferSettings({
							transferInterval:
								transferInterval as TransferSettings['transferInterval'],
							transferWeekDay: 1
						})
						setShowSuccessButton(true)
					} else if (
						responseStatusCode === 400 &&
						error?.fields?.transferInterval
					) {
						setTransferIntervalError(error.fields.transferInterval[0].message)
					} else {
						setAlertErrorMessage(error?.message || t('error-server-default'))
					}
				},
				onError: error => {
					setAlertErrorMessage(t('error-server-default'))
				}
			}
		)
	}

	const handleClose = useCallback(() => {
		setShowSuccessButton(false)
		onClose()
	}, [onClose])

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={handleClose}>{t('transfer')}</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<Stack spacing={3} flex={1} py={1}>
					{openAlertError ? (
						<AlertMessage
							message={alertErrorMessage}
							open={openAlertError}
							onClose={() => setOpenAlertError(false)}
							severity="error"
							mt={0}
						/>
					) : null}
					<FormGroup
						sx={styles.settingsItem}
						onChange={() => {
							setShowSuccessButton(false)
						}}
					>
						<FormControl>
							<Typography
								variant="subtitle2"
								sx={styles.detailsSubtitle}
								id="frequency-of-transfer-label"
							>
								{t('periodicity-of-transfer')}
							</Typography>
							<RadioGroup
								row
								aria-labelledby="frequency-of-transfer-label"
								name="transferInterval"
								onChange={event =>
									setTransferInterval(
										event.target.value as TransferSettings['transferInterval']
									)
								}
							>
								<FormControlLabel
									value="Daily"
									checked={transferInterval === 'Daily'}
									control={<Radio sx={{fontSize: '0.875rem'}} />}
									label={t('periodicity-daily')}
									sx={[styles.detailsText, styles.checkBoxLabel]}
								/>
								<FormControlLabel
									value="Weekly"
									checked={transferInterval === 'Weekly'}
									control={<Radio />}
									label={t('periodicity-weekly')}
									sx={styles.detailsText}
								/>
								<FormControlLabel
									value="Monthly"
									checked={transferInterval === 'Monthly'}
									control={<Radio />}
									label={t('periodicity-monthly')}
									sx={styles.detailsText}
								/>
							</RadioGroup>
							{transferIntervalError && (
								<FormHelperText error>{transferIntervalError}</FormHelperText>
							)}
						</FormControl>
					</FormGroup>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={theme => ({
								height: '44px',
								backgroundColor: 'success.light',
								color: 'success.contrastText',
								'&:hover': {backgroundColor: 'success.light'}
							})}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							size="large"
							variant="contained"
							type="submit"
							disabled={isLoading}
							sx={{height: '44px'}}
							onClick={handleUpdatePagarmeServiceTransferSettings}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={25} />
							) : (
								t('to-save-changes')
							)}
						</Button>
					)}
				</Stack>
			</Dialog.Body>
		</Dialog.Root>
	)
}

const styles = {
	checkBoxLabel: {
		'& .MuiTypography-root': {
			fontSize: '0.875rem'
		}
	},
	detailsItemBox: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 5
	},
	detailsSubtitle: (theme: Theme) => ({
		mb: 0.5
	}),
	detailsText: (theme: Theme) => ({
		color: theme.palette.grey[600]
	}),
	icon: (theme: Theme) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.background.paper,
		borderRadius: '50%',
		border: '1px solid ' + theme.palette.divider,
		'&:hover': {
			borderColor: theme.palette.primary.main
		},
		p: 0.5,
		height: 24,
		width: 24
	}),
	simpleDialogActionsBox: (theme: Theme) => ({
		alignSelf: 'end',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		justifyContent: 'end',
		mt: 2
	}),
	submitButton: (theme: Theme) => ({
		height: '44px',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	successButton: (theme: Theme) => ({
		height: '44px',
		backgroundColor: 'success.light',
		color: 'success.contrastText',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		'&:hover': {backgroundColor: 'success.light'}
	}),
	settingsItem: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		gap: 2
	},
	settingsItemHeader: {display: 'flex', alignItems: 'center', gap: 1}
}
