interface BrasilApiResponseData {
	cep: string
	state: string
	city: string
	neighborhood: string
	street: string
	service: string
	location: {
		type: string
		coordinates: {
			longitude: string
			latitude: string
		}
	}
}

const addressMapper = (data: BrasilApiResponseData) => ({
	cep: data.cep,
	state: data.state,
	city: data.city,
	neighborhood: data.neighborhood,
	street: data.street
})

export const findAddressByZipCode = async (zipCode: string) => {
	try {
		const response = await fetch(
			`https://brasilapi.com.br/api/cep/v1/${zipCode}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)

		const data = (await response.json()) as BrasilApiResponseData

		if (response.status === 200) {
			return {
				success: {
					message: 'Address found'
				},
				data: addressMapper(data),
				responseStatusCode: 200
			}
		} else if (response.status === 404) {
			return {
				error: {
					message: 'Address not found'
				},
				responseStatusCode: 404
			}
		}

		return {
			error: {
				message: 'Internal server error'
			},
			responseStatusCode: 500
		}
	} catch (error) {
		return {
			error: {
				message: 'Internal server error'
			},
			responseStatusCode: 500
		}
	}
}
