import FormForgotPasswordPasswordReset from '../components/forms/forgot-password/FormForgotPasswordPasswordReset'
import FullSection from '../components/layout/utils/FullSection'
import Footer from '../components/template/Footer'

const ForgotPasswordPasswordReset = () => {
	return (
		<>
			<FullSection height="auto" minHeight="calc(100vh - 260px)">
				<FormForgotPasswordPasswordReset />
			</FullSection>
			<Footer />
		</>
	)
}

export default ForgotPasswordPasswordReset
