import Box from '@mui/material/Box'
import {useMutation, useQuery} from '@tanstack/react-query'
import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {listExperiencesMainInfo} from '../../../api/experiences'
import ExperiencesSelect from './ExperiencesSelect'
import EditionsSelect from './EditionsSelect'
import {listEditionsMainInfoByExperience} from '../../../api/editions'
import StatusSaleSelect from './StatusSaleSelect'
import Button from '@mui/material/Button'
import AlertDialog from '../../../components/AlertDialog'

interface ExperienceMainInfo {
	id: string
	title: string
	cover?: string
	status: string
}

interface EditionMainInfo {
	id: string
	title: string
	status: string
}

interface CustomerMainFilterProps {
	onChange: (filters: {[key: string]: string | undefined}) => void
}

export default function CustomerMainFilters({
	onChange
}: CustomerMainFilterProps) {
	const {t} = useTranslation()

	const [experience, setExperience] = useState<ExperienceMainInfo | null>(null)
	const [edition, setEdition] = useState<EditionMainInfo | null>(null)
	const [statusSale, setStatusSale] = useState<{
		code: string
		label: string
	} | null>(null)
	const [experiences, setExperiences] = useState<ExperienceMainInfo[]>([])
	const [editions, setEditions] = useState<EditionMainInfo[]>([])
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [offset, setOffset] = useState(0)

	const handleApplyFilters = useCallback(() => {
		onChange({
			experienceId: experience?.id,
			editionId: edition?.id,
			statusSale: statusSale?.code
		})
	}, [experience, edition, statusSale, onChange])

	const {isFetching: isFetchingExperiences} = useQuery(
		['experiences-main-info'],
		() =>
			listExperiencesMainInfo({
				offset,
				limit: 50
			}),
		{
			networkMode: 'always',
			refetchOnWindowFocus: false,
			onSuccess: result => {
				if (result?.responseStatusCode === 200 && result?.data) {
					setExperiences(result.data.experiences)
					setOffset(offset + 50)
				} else {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
				}
			},
			onError: error => {
				setAlertMessage(t('error-server-default'))
				setOpenAlert(true)
				console.error(error)
			}
		}
	)

	const {mutate, isLoading: isFetchingEditions} = useMutation(
		listEditionsMainInfoByExperience,
		{
			networkMode: 'always'
		}
	)

	useEffect(() => {
		if (experience) {
			mutate(
				{experienceId: experience.id},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							setEditions(result.data.editions)
						} else {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		}
	}, [experience, mutate, t])

	return (
		<>
			<AlertDialog
				open={openAlert}
				severity="error"
				onClose={() => setOpenAlert(false)}
				message={alertMessage}
			/>
			<Box
				sx={theme => ({
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					gap: 2,
					width: '100%',
					[theme.breakpoints.down('lg')]: {
						flexDirection: 'column',
						alignItems: 'flex-start'
					}
				})}
			>
				<Box
					sx={{
						flex: 1,
						display: 'flex',
						flexWrap: 'wrap',
						gap: 2,
						width: '100%'
					}}
				>
					<ExperiencesSelect
						name="experienceId"
						label={t('experience')}
						options={experiences.map(experience => ({
							id: experience.id,
							title: experience.title,
							cover: experience.cover,
							status: experience.status
						}))}
						valueFormat="code"
						onChange={experience => {
							setExperience(experience)
							setEdition(null)
							setEditions([])
						}}
						disabled={isFetchingExperiences}
						sx={theme => ({
							minWidth: 300,
							flex: 1,
							[theme.breakpoints.down(1000)]: {
								width: '100%',
								flex: 'none'
							},
							[theme.breakpoints.down('sm')]: {
								minWidth: '100%'
							}
						})}
					/>
					<EditionsSelect
						name="editionId"
						label={t('edition')}
						options={editions.map(experience => ({
							id: experience.id,
							title: experience.title,
							status: experience.status
						}))}
						valueFormat="code"
						value={edition}
						onChange={newEdition => setEdition(newEdition)}
						disabled={
							!experience || isFetchingExperiences || isFetchingEditions
						}
						sx={theme => ({
							minWidth: 200,
							flex: 1,
							[theme.breakpoints.down('sm')]: {
								minWidth: '100%'
							}
						})}
					/>
					<StatusSaleSelect
						name="statusSale"
						label={t('status-sale')}
						options={[
							{
								code: 'open',
								label: t('status-tag-open')
							},
							{
								code: 'paid',
								label: t('status-tag-paid')
							},
							{
								code: 'pending',
								label: t('status-tag-pending')
							},
							{
								code: 'failed',
								label: t('status-tag-failed')
							},
							{
								code: 'overdue',
								label: t('status-tag-overdue')
							},
							{
								code: 'charged-back',
								label: t('status-tag-charged-back')
							},
							{
								code: 'refunded',
								label: t('status-tag-refunded')
							},
							{
								code: 'processing-refund',
								label: t('status-tag-processing-refund')
							},
							{
								code: 'awaiting-customer-data-for-refund',
								label: t('status-tag-awaiting-customer-data-for-refund')
							}
						]}
						valueFormat="code"
						value={statusSale}
						onChange={newStatus => setStatusSale(newStatus)}
						sx={theme => ({
							minWidth: 180,
							flex: 1,
							[theme.breakpoints.down('sm')]: {
								minWidth: '100%'
							}
						})}
					/>
				</Box>
				<Button
					variant="contained"
					sx={theme => ({
						width: '200px',
						[theme.breakpoints.down('sm')]: {
							width: '100%'
						}
					})}
					onClick={handleApplyFilters}
				>
					{t('to-apply-filters')}
				</Button>
			</Box>
		</>
	)
}
