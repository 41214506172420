import {create} from 'zustand'
import {persist} from 'zustand/middleware'

export type Customer = {
	id: string
	name?: string
	email: string
	type?: string
	documentType?: string
	document?: string
	phone?: string
	newsletter: boolean
}

interface CustomersStates {
	customers: Customer[]
	total: number
	page: number
	limit: number
	offset: number
	filters: {[key: string]: string | undefined}
	setCustomers: (customers: Customer[]) => void
	setTotal: (total: number) => void
	setPage: (page: number) => void
	setLimit: (limit: number) => void
	setOffset: (offset: number) => void
	addFilter: (newFilter: {[key: string]: string | undefined}) => void
}

const useCustomersStates = create<CustomersStates>()(
	persist(
		set => ({
			customers: [],
			total: 0,
			page: 0,
			limit: 25,
			offset: 0,
			filters: {},
			setCustomers: customers => set({customers}),
			setTotal: total => set({total}),
			setPage: page => set({page}),
			setLimit: limit => set({limit}),
			setOffset: offset => set({offset}),
			addFilter: newFilter =>
				set(state => ({
					filters: {...state.filters, ...newFilter}
				}))
		}),
		{
			name: 'customers-storage',
			getStorage: () => localStorage,
			partialize: state => ({
				limit: state.limit
			})
		}
	)
)

export default useCustomersStates
