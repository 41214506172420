import React, {useState} from 'react'
import {Theme} from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Popover from '@mui/material/Popover'
import Switch from '@mui/material/Switch'
import {Tooltip} from '@mui/material'

interface SwitchControlProps {
	label: string
	ariaLabelledby?: string
	tooltip?: string
	checked: boolean
	disabled?: boolean
	size?: 'small' | 'medium'
	popover?: React.ReactNode
	anchorOrigin?: {
		vertical: 'top' | 'center' | 'bottom' | number
		horizontal: 'left' | 'center' | 'right' | number
	}
	transformOrigin?: {
		vertical: 'top' | 'center' | 'bottom' | number
		horizontal: 'left' | 'center' | 'right' | number
	}
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SwitchControl = ({
	label,
	ariaLabelledby,
	tooltip,
	checked,
	disabled,
	size,
	popover,
	anchorOrigin,
	transformOrigin,
	onChange
}: SwitchControlProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const popoverOpen = Boolean(anchorEl)

	return (
		<FormGroup>
			{tooltip ? (
				<Tooltip
					title={tooltip}
					placement="bottom"
					arrow
					disableHoverListener={disabled}
				>
					<FormControlLabel
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
						aria-labelledby={ariaLabelledby}
						aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
						aria-haspopup="true"
						control={
							<Switch
								checked={checked}
								disabled={disabled}
								onChange={onChange}
								size={size || 'small'}
								sx={style.switch}
								inputProps={{'aria-label': tooltip}}
							/>
						}
						label={label}
						sx={{mx: 0, width: 'fit-content', color: 'text.primary'}}
					/>
				</Tooltip>
			) : (
				<FormControlLabel
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose}
					aria-labelledby={ariaLabelledby}
					aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
					aria-haspopup="true"
					control={
						<Switch
							checked={checked}
							disabled={disabled}
							onChange={onChange}
							size={size || 'small'}
							sx={style.switch}
							inputProps={{'aria-label': tooltip}}
						/>
					}
					label={label}
					sx={{mx: 0, width: 'fit-content', color: 'text.primary'}}
				/>
			)}

			{popover && (
				<Popover
					id="mouse-over-popover"
					sx={style.popover}
					open={popoverOpen}
					anchorEl={anchorEl}
					anchorOrigin={
						anchorOrigin || {
							vertical: 'bottom',
							horizontal: 'left'
						}
					}
					transformOrigin={
						transformOrigin || {
							vertical: 'top',
							horizontal: 'left'
						}
					}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					{popover}
				</Popover>
			)}
		</FormGroup>
	)
}

const style = {
	popoverBox: {
		position: 'absolute',
		right: 0,
		top: 0
	},
	popover: (theme: Theme) => ({
		pointerEvents: 'none',
		'& .MuiPopover-paper': {
			maxWidth: 400
		},
		[theme.breakpoints.down('sm')]: {
			'& .MuiPopover-paper': {
				maxWidth: '80%'
			}
		}
	}),
	switch: (theme: Theme) => ({
		color: theme.palette.text.primary,
		'.MuiSwitch-track': {
			backgroundColor: theme.palette.grey[800]
		}
	})
}

export default SwitchControl
