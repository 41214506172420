import {useCallback, useEffect, useMemo, useState} from 'react'
import useAsaasStates from '../../../stores/useAsaasStates'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {Status} from '../../../components/Status'
import Button from '@mui/material/Button'
import {price} from '../../../helpers/price'
import {useTranslation} from 'react-i18next'
import {Dialog} from '../../../components/Dialog'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import {date} from '../../../helpers/date'
import TableHead from '@mui/material/TableHead'
import {banksCode} from '../../../components/BankSelect'
import ButtonLink from '../../../components/navigation/ButtonLink'
import useAppStates from '../../../stores/useAppStates'
import {fetchTransferAsaas} from '../../../api/payment-service/asaas'
import {useMutation} from '@tanstack/react-query'

export const TransferHistoryCard = () => {
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')
	const [firstLoad, setFirstLoad] = useState(true)

	const {t} = useTranslation()
	const {asaasTransfers, setAsaasTransfers} = useAsaasStates()
	const {currentLang} = useAppStates()

	const lastTransfer = useMemo(() => {
		if (asaasTransfers.length === 0) {
			return null
		}

		return asaasTransfers[0]
	}, [asaasTransfers])

	const [openHistoryDialog, setOpenHistoryDialog] = useState(false)

	const {mutate, isLoading} = useMutation(fetchTransferAsaas, {
		networkMode: 'always'
	})

	const fetchGetTransfers = useCallback(async () => {
		setOpenAlert(false)
		mutate(
			{offset: 0, limit: 10},
			{
				onSuccess: ({data, responseStatusCode, error}) => {
					if (responseStatusCode === 200 && data) {
						setAsaasTransfers(data.transfers)
					} else if (responseStatusCode !== 200) {
						setOpenAlert(true)
						setAlertErrorMessage(error?.message || t('error-server-default'))
					}
				},
				onError: error => {
					setOpenAlert(true)
					setAlertErrorMessage(t('error-server-default'))
					console.error(error)
				}
			}
		)
	}, [mutate, setAsaasTransfers, t, setOpenAlert, setAlertErrorMessage])

	useEffect(() => {
		if (firstLoad) {
			fetchGetTransfers()
			setFirstLoad(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstLoad])

	return (
		<>
			<Dialog.Root open={openHistoryDialog} maxWidth={700}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenHistoryDialog(false)}>
						{t('last-transfers')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body
					sx={{
						p: '0px !important',
						m: '0px !important',
						width: '100% !important',
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<Box display="grid">
						<TableContainer>
							<Table sx={{minWidth: '600px'}}>
								<TableHead>
									<TableRow>
										<TableCell sx={{pl: 3}}>{t('date')}</TableCell>
										<TableCell>{t('requested-amount')}</TableCell>
										<TableCell>{t('beneficiary')}</TableCell>
										<TableCell sx={{pr: 3}}>{t('status')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{asaasTransfers.map(transfer => (
										<TableRow key={transfer.id}>
											<TableCell sx={{pl: 3}}>
												<Typography variant="body2">
													{date.convertISOToLocation(
														transfer.createdAt,
														currentLang
													)}
												</Typography>
												<Typography variant="caption" color="grey.700">
													{date.getTime(transfer.createdAt, currentLang)}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2">
													{price.full(transfer.amount)}
												</Typography>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center'
													}}
												>
													<Typography variant="caption" color="grey.700">
														{t('fee')} {transfer.operationType}:{' '}
														{price.full(transfer.feeAmount)}
													</Typography>
												</Box>
											</TableCell>
											<TableCell>
												<Typography variant="body2">
													{
														banksCode.find(
															bankCode =>
																transfer?.bankAccount?.bankCode ===
																bankCode.code
														)?.label
													}
												</Typography>
												<Typography variant="caption" color="grey.700">
													{transfer?.bankAccount?.branchNumber} /{' '}
													{transfer?.bankAccount?.accountNumber}-
													{transfer?.bankAccount?.accountCheckDigit}
												</Typography>
											</TableCell>
											<TableCell>
												<Status.Tag type={transfer.status}>
													<Status.Label
														label={t('status-tag-' + transfer.status)}
														maxWidth="auto"
														showTooltip={false}
													/>
												</Status.Tag>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<ButtonLink to="/payment-services/asaas?tab=transfers">
						{t('go-to-transfers')}
					</ButtonLink>
				</Dialog.Body>
			</Dialog.Root>
			{lastTransfer && (
				<Card
					variant="outlined"
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						p: 1.75, // 14px
						width: 'max-content',
						minWidth: 200,
						gap: 1,
						justifyContent: 'space-between'
					}}
				>
					<Typography variant="subtitle2">{t('last-transfer')}</Typography>
					<Box>
						<Typography variant="body2">
							{t('amount')}: {price.full(lastTransfer.amount)}
						</Typography>
						<Typography
							variant="body2"
							sx={{
								display: 'inline-flex',
								alignItems: 'center',
								gap: '6px',
								mt: '8px'
							}}
						>
							{t('status')}:{' '}
							<Status.Tag type={lastTransfer.status}>
								<Status.Label
									label={t(`status-tag-${lastTransfer.status}`)}
									maxWidth="auto"
									showTooltip={false}
								/>
							</Status.Tag>
						</Typography>
					</Box>
					<span>
						<Button
							variant="outlined"
							sx={{mt: 1}}
							onClick={() => setOpenHistoryDialog(true)}
						>
							{t('to-view-more-details')}
						</Button>
					</span>
				</Card>
			)}
		</>
	)
}
