import * as yup from 'yup'
import {useState} from 'react'
import {useFormik} from 'formik'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {
	CircularProgress,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography
} from '@mui/material'
import {updateWhatsAppCheckoutIntegration} from '../../../api/integrations'
import AlertMessage from '../../AlertMessage'
import {integrationMapper} from './utils/integrationMapper'
import {regex} from '../../../helpers/regex'
import InputMask from '../../InputMask'
import CountrySelect from '../../CountrySelect'

export const WhatsAppCheckoutForm = ({
	metadata,
	id,
	onUpdate
}: {
	metadata?: string
	id: string
	onUpdate: (data: {id: string; status: string; metadata?: string}) => void
}) => {
	const {t} = useTranslation()
	const [errorMessage, setErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)
	const [showSuccessButton, setShowSuccessButton] = useState(false)
	const {phoneCountryCode, phoneAreaCode, phoneNumber, phoneType} =
		integrationMapper.whatsAppCheckout(metadata)

	const handleChangeForm = () => {
		setOpenAlertError(false)
		setShowSuccessButton(false)
	}

	const {mutate, isLoading} = useMutation(updateWhatsAppCheckoutIntegration, {
		networkMode: 'always'
	})

	const validationSchema = yup.object({
		phoneType: yup
			.string()
			.oneOf(['landline', 'mobile'], t('error-phone-type-required'))
			.required(t('error-phone-type-required')),
		phoneCountryCode: yup
			.string()
			.transform(value => regex.removeAllNonDigits(value))
			.matches(/^\d{1,3}$/, t('error-phone-country-code-invalid'))
			.required(t('error-phone-country-code-required')),
		phoneNumber: yup.string().when('phoneType', value => {
			if (value.includes('mobile')) {
				return yup
					.string()
					.transform(value => regex.removeAllNonDigits(value))
					.required(t('error-phone-number-required'))
					.matches(/^\d{11}$/, t('error-phone-number-invalid'))
			}

			return yup
				.string()
				.transform(value => regex.removeAllNonDigits(value))
				.required(t('error-phone-number-required'))
				.matches(/^\d{10}$/, t('error-phone-number-invalid'))
		})
	})

	const formik = useFormik({
		initialValues: {
			phoneType: phoneType || 'mobile',
			phoneCountryCode: phoneCountryCode || '',
			phoneNumber:
				phoneNumber && phoneAreaCode ? phoneAreaCode + phoneNumber : ''
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			mutate(
				{
					id,
					phoneType: values.phoneType as 'landline' | 'mobile',
					phoneCountryCode: regex.removeAllNonDigits(values.phoneCountryCode),
					phoneAreaCode: regex
						.removeAllNonDigits(values.phoneNumber)
						.substring(0, 2),
					phoneNumber: regex.removeAllNonDigits(values.phoneNumber).substring(2)
				},
				{
					onSuccess: ({data, error, responseStatusCode}) => {
						if (responseStatusCode === 200 && data) {
							setShowSuccessButton(true)
							onUpdate(data)
						} else if (error) {
							setErrorMessage(error.message)
							setOpenAlertError(true)
						} else {
							setErrorMessage(t('error-server-default'))
							setOpenAlertError(true)
						}
					},
					onError: error => {
						setErrorMessage(t('error-server-default'))
						setOpenAlertError(true)
					}
				}
			)
		}
	})

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: 2
			}}
		>
			{openAlertError ?? (
				<AlertMessage
					severity="error"
					message={errorMessage}
					open={openAlertError}
					onClose={() => setOpenAlertError(false)}
				/>
			)}

			<Box
				onSubmit={formik.handleSubmit}
				onChange={handleChangeForm}
				component="form"
				autoComplete="off"
				sx={theme => ({
					mt: 0,
					pt: 0,
					width: '100%'
				})}
			>
				<Stack direction="column" spacing={4}>
					<FormControl>
						<Typography variant="subtitle1" fontWeight={500}>
							{t('phone-type')}
						</Typography>
						<RadioGroup
							row
							aria-labelledby="checkout-type-label"
							name="checkoutType"
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								formik.setFieldValue(
									'phoneType',
									event.target.value as 'mobile' | 'landline'
								)
							}}
						>
							<FormControlLabel
								value="mobile"
								checked={formik.values.phoneType === 'mobile'}
								control={<Radio sx={{fontSize: '0.875rem'}} />}
								label={t('mobile')}
								sx={{color: 'text.secondary'}}
							/>
							<FormControlLabel
								value="landline"
								checked={formik.values.phoneType === 'landline'}
								control={<Radio />}
								label={t('landline')}
								sx={{color: 'text.secondary'}}
							/>
						</RadioGroup>
					</FormControl>
					<CountrySelect
						name="phoneCountryCode"
						label={t('phone-country-code')}
						valueFormat="phone"
						// TODO: add default value related with language (from currentLang)
						codeNumber={formik.values.phoneCountryCode}
						onChange={formik.handleChange}
						error={
							formik.touched.phoneCountryCode &&
							Boolean(formik.errors.phoneCountryCode)
						}
						helperText={
							formik.touched.phoneCountryCode && formik.errors.phoneCountryCode
						}
						showPhone={true}
						sx={{width: '100%'}}
					/>
					<TextField
						name="phoneNumber"
						type="text"
						label={t('phone-area-code-and-phone-number')}
						variant="outlined"
						helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
						error={
							formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
						}
						value={formik.values.phoneNumber}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						size="small"
						InputProps={{
							inputComponent: InputMask as any,
							inputProps: {
								mask:
									formik.values.phoneType === 'mobile'
										? '(00) 00000-0000'
										: '(00) 0000-0000'
							}
						}}
						inputProps={{
							inputMode: 'numeric'
						}}
					/>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={{
								height: '36.5px',
								backgroundColor: 'success.light',
								color: 'success.contrastText',
								'&:hover': {backgroundColor: 'success.light'}
							}}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							type="submit"
							variant="contained"
							sx={{
								height: '36.5px'
							}}
							disabled={isLoading}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={24} />
							) : (
								t('to-save')
							)}
						</Button>
					)}
				</Stack>
			</Box>
		</Box>
	)
}
