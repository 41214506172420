import {Outlet, useNavigate, useParams} from 'react-router-dom'
import {useEffect} from 'react'
import i18n from 'i18next'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'

import {VALID_LANG} from '../../config'
import useAppStates from '../../stores/useAppStates'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import HeaderDashboard from '../template/HeaderDashboard'
import SideBarNav from '../template/SideBarNav'
import useAuthStates from '../../stores/useAuthStates'

const DashboardLayout = () => {
	const {lang} = useParams()
	const navigate = useNavigate()
	const setLang = useAppStates(state => state.setLang)
	const {onboardingCompleted} = useAuthStates(state => state)
	const checkIsLogged = useProtectedRoute()

	window.scrollTo(0, 0)

	useEffect(() => {
		const setCurrentLang = async () => {
			if (lang) {
				if (lang in VALID_LANG) {
					await i18n.changeLanguage(VALID_LANG[lang])
					setLang(lang)
				} else {
					navigate('/not-found')
				}
			}
		}
		setCurrentLang()
		checkIsLogged()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!onboardingCompleted) {
			navigate('/onboarding')
		}
	}, [onboardingCompleted, navigate])

	return (
		<>
			<CssBaseline />
			<HeaderDashboard />
			<Box sx={{display: 'flex'}}>
				<SideBarNav />
				<Box component="main" sx={{flexGrow: 1, padding: '68px 0 0 0'}}>
					<Outlet />
				</Box>
			</Box>
		</>
	)
}

export default DashboardLayout
