import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

interface Props {
	display?: 'flex' | 'block' | 'inline-flex' | 'inline-block'
	flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
	alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
	justifyContent?:
		| 'flex-start'
		| 'center'
		| 'flex-end'
		| 'space-between'
		| 'space-around'
		| 'space-evenly'
	gap?: number
	children?: React.ReactNode
	grey?: 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
	id?: string
	height?: string | number
	minHeight?: string | number
	py?: number
	mt?: number | string
}

export const DashboardFullSection = ({
	children,
	display,
	flexDirection,
	alignItems,
	justifyContent,
	gap,
	grey,
	id,
	height = 'auto',
	py = 3,
	minHeight = 'calc(100vh - 390px)',
	mt = 0
}: Props) => {
	return (
		<Box
			component={'section'}
			id={id}
			sx={theme => ({
				bgcolor: grey
					? theme.palette.grey[grey]
					: theme.palette.background.paper,
				height,
				minHeight,
				mt
			})}
		>
			<Container
				sx={{
					display,
					flexDirection,
					alignItems,
					justifyContent,
					gap,
					py: py
				}}
			>
				{children}
			</Container>
		</Box>
	)
}
