import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import {useTranslation} from 'react-i18next'
import {FaHome as HomeIcon} from 'react-icons/fa'

import Footer from '../components/template/Footer'
import Link from '../components/navigation/Link'
import SectionTitle from '../components/typography/SectionTitle'
import {useCallback, useEffect, useState} from 'react'
import {regex} from '../helpers/regex'
import {useMutation} from '@tanstack/react-query'
import {listCustomers} from '../api/customers'
import AlertDialog from '../components/AlertDialog'
import useAppStates from '../stores/useAppStates'
import {DashboardFullSection} from '../components/template/DashboardFullSection'
import CustomersList from '../components/lists/customers/CustomersList'
import useCustomersStates from '../stores/useCustomersStates'

const Customers = () => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()
	const {setCustomers, offset, setOffset, limit, setPage, filters, setTotal} =
		useCustomersStates()

	const [firstLoad, setFirstLoad] = useState(true)
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')

	const handleChangeURL = (page: number) => {
		window.history.replaceState(
			undefined,
			'Dashboard | Excofy',
			`/${currentLang}/customers?page=${page}`
		)
	}

	const {mutate, isLoading} = useMutation(listCustomers, {
		networkMode: 'always'
	})

	const listCustomersMutate = useCallback(
		async () =>
			mutate(
				{
					offset,
					limit,
					filters: filters ? regex.parseObjectToQueryString(filters) : undefined
				},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							if (firstLoad) {
								setFirstLoad(false)
							}

							setTotal(
								offset === 0
									? result.data.customers.length
									: result.data.customers.length + offset
							)

							if (offset !== 0 && result.data.customers.length === 0) {
								const newOffset = offset - limit
								setOffset(newOffset)
								setPage(newOffset / limit)
							} else {
								setCustomers(
									result.data.customers.map(item => ({
										...item,
										phone:
											item.phone.countryCode &&
											item.phone.areaCode &&
											item.phone.number
												? `+${item.phone.countryCode} (${item.phone.areaCode}) ${item.phone.number}`
												: undefined
									}))
								)
								setPage(offset / limit)
								handleChangeURL(offset / limit)
							}
						} else {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[offset, limit, filters]
	)

	useEffect(() => {
		listCustomersMutate()
	}, [listCustomersMutate, filters])

	return (
		<>
			<AlertDialog
				open={openAlert}
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
			/>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<span>{t('customers')}</span>
				</Breadcrumbs>
			</Container>
			<DashboardFullSection>
				<SectionTitle>{t('customers')}</SectionTitle>
				<CustomersList isLoading={isLoading} />
			</DashboardFullSection>
			<Footer />
		</>
	)
}

export default Customers
