import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'

interface MenuProps {
	list: {
		key?: string
		label: string
		active?: boolean
		onClick: () => void
	}[]
	anchorEl: HTMLElement | null
	open: boolean
	onClose: () => void
}

const MenuList = ({list, open, anchorEl, onClose}: MenuProps) => {
	const {t} = useTranslation()

	return (
		<MuiMenu
			anchorEl={anchorEl}
			id="action-list"
			open={open}
			onClose={onClose}
			onClick={onClose}
			PaperProps={{
				elevation: 0,
				sx: theme => ({
					overflow: 'visible',
					filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
					bgcolor:
						theme.palette.mode === 'light' ? 'background.paper' : 'grey.50',
					mt: 1.5,
					'& .MuiAvatar-root': {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1
					},
					'&:before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor:
							theme.palette.mode === 'light' ? 'background.paper' : 'grey.50',
						transform: 'translateY(-50%) rotate(45deg)',
						zIndex: 0
					}
				})
			}}
			transformOrigin={{
				horizontal: 'right',
				vertical: 'top'
			}}
			anchorOrigin={{
				horizontal: 'right',
				vertical: 'bottom'
			}}
		>
			{list.map(({key, label, active, onClick}) => {
				return (
					<MenuItem
						key={key || label}
						onClick={onClick}
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 0.5,
							backgroundColor: theme =>
								active ? theme.palette.grey[50] : 'transparent'
						}}
					>
						<Typography
							variant="body2"
							sx={{
								color: theme =>
									active
										? theme.palette.primary.main
										: theme.palette.text.primary
							}}
						>
							{t(label)}
						</Typography>
					</MenuItem>
				)
			})}
		</MuiMenu>
	)
}

export default MenuList
