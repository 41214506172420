import {useTranslation} from 'react-i18next'
import {Dialog} from '../../Dialog'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import {useCallback, useEffect, useState} from 'react'
import {Status} from '../../Status'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import {HiOutlineDotsHorizontal as DotsIcon} from 'react-icons/hi'
import MenuList from '../../MenuList'
import {useMutation} from '@tanstack/react-query'
import {deleteEvent, fetchEvents, resendEvent} from '../../../api/webhooks'
import AlertMessage from '../../AlertMessage'
import TablePagination from '@mui/material/TablePagination'
import {date} from '../../../helpers/date'
import useAppStates from '../../../stores/useAppStates'
import {LoadingDialog} from '../../LoadingDialog'
import AlertDialog from '../../AlertDialog'
import {WebhooksLogsDetails} from './WebhooksLogsDetails'
import {Skeleton} from '@mui/material'
import {ConfirmDialog} from '../../dialogs/ConfirmDialog'

type WebhookEvent = {
	id: string
	sentAt: string
	status: string
	eventType: string
	webhookName: string
	requestBody: string
	responseMetadata: string
}

export const WebhooksLogs = ({
	productId,
	open,
	onClose
}: {
	productId: string
	open: boolean
	onClose: () => void
}) => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()

	const [firstLoad, setFirstLoad] = useState(true)
	const [offset, setOffset] = useState(0)
	const [limit, setLimit] = useState(10)
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(0)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [openAlert, setOpenAlert] = useState(false)
	const [alertDialogMessage, setAlertDialogMessage] = useState<string>('')
	const [openAlertDialog, setOpenAlertDialog] = useState(false)
	const [openDetails, setOpenDetails] = useState(false)
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
	const [events, setEvents] = useState<WebhookEvent[]>([])
	const [editEventValues, setEditEventValues] = useState<WebhookEvent | null>(
		null
	)
	const [anchorElOptions, setAnchorElOptions] = useState<HTMLElement | null>(
		null
	)
	const openOptions = Boolean(anchorElOptions)

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
		setOffset(newPage * limit)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(+event.target.value)
		setOffset(0)
		setPage(0)
	}

	const {mutate, isLoading} = useMutation(fetchEvents, {
		networkMode: 'always'
	})

	const mutateEvents = useCallback(() => {
		mutate(
			{productId, offset, limit},
			{
				onSuccess: ({responseStatusCode, data, error}) => {
					if (responseStatusCode === 200 && data) {
						setTotal(
							offset === 0 ? data.events.length : data.events.length + offset
						)

						if (offset !== 0 && data.events.length === 0) {
							const newOffset = offset - limit
							setOffset(newOffset)
							setPage(newOffset / limit)
						} else {
							setEvents(data.events)
							setPage(offset / limit)
						}
					} else {
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: error => {
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
					console.error(error)
				}
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productId, offset, limit])

	const {mutate: mutateDeleteEvent} = useMutation(deleteEvent, {
		networkMode: 'always'
	})

	const handleDeleteEvent = (eventId: string) => {
		setEvents(events.filter(event => event.id !== eventId))
		mutateDeleteEvent(
			{id: eventId},
			{
				onSuccess: ({responseStatusCode, error}) => {
					if (responseStatusCode !== 200) {
						setEvents([...events])
						setAlertDialogMessage(error?.message || t('error-server-default'))
						setOpenAlertDialog(true)
					}
				},
				onError: error => {
					setAlertDialogMessage(t('error-server-default'))
					setOpenAlertDialog(true)
					console.error(error)
				}
			}
		)
	}

	const {mutate: mutateResend, isLoading: isResending} = useMutation(
		resendEvent,
		{
			networkMode: 'always'
		}
	)

	const handleResendEvent = (eventId: string) => {
		mutateResend(
			{id: eventId},
			{
				onSuccess: ({responseStatusCode, error, data}) => {
					if (responseStatusCode === 200 && data) {
						setEvents(
							events.map(event =>
								event.id === eventId ? {...event, ...data} : event
							)
						)
						setEditEventValues(prev => {
							if (prev) {
								return {...prev, ...data}
							}
							return prev
						})
					} else {
						setAlertDialogMessage(error?.message || t('error-server-default'))
						setOpenAlertDialog(true)
					}
				},
				onError: error => {
					setAlertDialogMessage(t('error-server-default'))
					setOpenAlertDialog(true)
					console.error(error)
				}
			}
		)
	}

	useEffect(() => {
		setFirstLoad(false)
	}, [])

	useEffect(() => {
		if (open && !firstLoad) {
			mutateEvents()
		}
	}, [mutateEvents, open, firstLoad])

	return (
		<>
			<MenuList
				open={openOptions}
				anchorEl={anchorElOptions}
				list={[
					{
						label: t('view-details'),
						onClick: () => {
							setOpenDetails(true)
						}
					},
					{
						label: t('to-resend'),
						onClick: () => {
							handleResendEvent(editEventValues?.id || '')
						}
					},
					{
						label: t('to-delete'),
						onClick: () => {
							setOpenDeleteDialog(true)
						}
					}
				]}
				onClose={() => setAnchorElOptions(null)}
			/>
			<AlertDialog
				severity="error"
				open={openAlertDialog}
				message={alertDialogMessage}
				onClose={() => setOpenAlertDialog(false)}
			/>
			<ConfirmDialog
				open={openDeleteDialog}
				contentMessage={t('delete-event-message')}
				confirmText={t('to-delete')}
				onConfirm={() => handleDeleteEvent(editEventValues?.id || '')}
				onClose={() => setOpenDeleteDialog(false)}
				onCancel={() => setOpenDeleteDialog(false)}
			/>
			<LoadingDialog open={isResending} message={t('resending-event')} />
			{editEventValues && (
				<WebhooksLogsDetails
					open={openDetails}
					event={editEventValues}
					onClose={() => setOpenDetails(false)}
					onResend={handleResendEvent}
					isResending={isResending}
				/>
			)}
			<Dialog.Root open={open} fullScreen>
				<Dialog.Header>
					<Dialog.Title onClose={onClose}>{t('event-log')}</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width="100%">
					<Box>
						<Box display="grid">
							{openAlert && (
								<AlertMessage
									severity="error"
									open={openAlert}
									message={alertMessage}
									onClose={() => setOpenAlert(false)}
									mt={0}
									sx={{mb: 2}}
								/>
							)}
							<TableContainer component={Paper} variant="outlined">
								<Table sx={{minWidth: 700}} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell sx={{minWidth: 200}}>{t('webhook')}</TableCell>
											<TableCell sx={{minWidth: 200}}>{t('event')}</TableCell>
											<TableCell sx={{minWidth: 200}}>{t('sent-at')}</TableCell>
											<TableCell sx={{minWidth: 150}}>{t('status')}</TableCell>
											<TableCell align="center" sx={{minWidth: 80}}>
												{t('actions')}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{isLoading
											? new Array(limit).fill(0).map((_, index) => (
													<TableRow
														key={'webhook-skeleton-' + index}
														sx={{
															'&:last-child td, &:last-child th': {border: 0}
														}}
													>
														<TableCell>
															<Skeleton variant="text" />
														</TableCell>
														<TableCell>
															<Skeleton variant="text" />
														</TableCell>
														<TableCell>
															<Skeleton variant="text" />
														</TableCell>
														<TableCell>
															<Skeleton variant="text" />
														</TableCell>
														<TableCell>
															<Skeleton variant="text" />
														</TableCell>
													</TableRow>
											  ))
											: events.slice(0, limit).map(row => (
													<TableRow
														key={'webhook-' + row.id}
														sx={{
															'&:last-child td, &:last-child th': {border: 0}
														}}
													>
														<TableCell component="th" scope="row">
															{row.webhookName}
														</TableCell>
														<TableCell component="th" scope="row">
															{t(row.eventType)}
														</TableCell>
														<TableCell component="th" scope="row">
															{date.convertISOToLocation(
																row.sentAt,
																currentLang,
																true
															)}
														</TableCell>
														<TableCell align="right">
															<Status.Tag type={row.status}>
																<Status.Label
																	label={t('status-tag-' + row.status)}
																/>
															</Status.Tag>
														</TableCell>
														<TableCell align="center">
															<Tooltip
																title={t('view-actions')}
																placement="bottom"
																arrow
															>
																<IconButton
																	onClick={(
																		event: React.MouseEvent<HTMLElement>
																	) => {
																		setAnchorElOptions(event.currentTarget)
																		setEditEventValues(row)
																	}}
																	size="small"
																	aria-controls={
																		openOptions ? t('actions') : undefined
																	}
																	aria-haspopup="true"
																	aria-expanded={
																		openOptions ? 'true' : undefined
																	}
																>
																	<DotsIcon />
																</IconButton>
															</Tooltip>
														</TableCell>
													</TableRow>
											  ))}
										{!isLoading && events.length === 0 && (
											<TableRow
												sx={{
													'&:last-child td, &:last-child th': {border: 0}
												}}
											>
												<TableCell colSpan={5} align="center">
													{t('no-events')}
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							{events.length > 0 && (
								<TablePagination
									rowsPerPageOptions={[10, 25, 50]}
									component="div"
									count={total}
									rowsPerPage={limit}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage={t('items-per-page')}
									labelDisplayedRows={({from, to}) => (
										<span
											style={{display: 'block', width: 40, textAlign: 'center'}}
										>
											{isLoading ? (
												<Skeleton variant="text" />
											) : (
												t('list-filter-info', {
													from,
													to
												})
											)}
										</span>
									)}
								/>
							)}
						</Box>
					</Box>
				</Dialog.Body>
			</Dialog.Root>
		</>
	)
}
