import {useTranslation} from 'react-i18next'
import {useCallback, useState} from 'react'
import {sheetsHelper} from '../../../helpers/sheets'
import {LoadingDialog} from '../../LoadingDialog'
import {Dialog} from '../../Dialog'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import InfoPopover from '../../InfoPopover'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {PiFileCsv as CsvIcon} from 'react-icons/pi'
import {useMutation} from '@tanstack/react-query'
import {fetchSaleList} from '../../../api/sales'
import AlertDialog from '../../AlertDialog'
import Stack from '@mui/material/Stack'
import {SaleFilters} from '../../../pages/Sales'
import {regex} from '../../../helpers/regex'
import {date as dateHelper} from '../../../helpers/date'
import useAppStates from '../../../stores/useAppStates'

type Sale = {
	date: string
	product: string
	orderBumps?: string
	status: string
	name?: string
	email: string
	phone?: string
	documentType?: string
	document?: string
	paymentMethod?: string
	paymentService?: string
	totalAmount?: number
}

export const ExportSalesToCSV = ({
	filters,
	offset,
	limit
}: {
	filters?: SaleFilters
	offset?: number
	limit?: number
}) => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()

	const [openDialog, setOpenDialog] = useState(false)
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [isExporting, setIsExporting] = useState(false)

	const [headers, setHeaders] = useState({
		date: true,
		product: true,
		orderBumps: true,
		status: true,
		name: true,
		email: false,
		phone: true,
		documentType: false,
		document: false,
		paymentMethod: true,
		paymentService: true,
		totalAmount: true
	})

	const {
		date,
		product,
		orderBumps,
		status,
		name,
		email,
		phone,
		documentType,
		document,
		paymentMethod,
		paymentService,
		totalAmount
	} = headers

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHeaders({
			...headers,
			[event.target.name]: event.target.checked
		})
	}

	const handleOpenDialog = () => {
		setOpenDialog(true)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	const exportToCSV = useCallback(
		(sales: Sale[]) => {
			const headerMapping = {
				date: t('date'),
				product: t('product-or-service'),
				status: t('status'),
				orderBumps: t('order-bumps'),
				name: t('name'),
				email: t('email'),
				phone: t('phone'),
				documentType: t('document-type'),
				document: t('document-number'),
				paymentMethod: t('payment-method'),
				paymentService: t('payment-service'),
				totalAmount: t('sale-total-amount')
			}

			Object.entries(headers).forEach(([key, value]) => {
				if (!value) {
					delete headerMapping[key as keyof typeof headerMapping]
				}
			})

			sheetsHelper.exportToCSV({
				title: t('sales'),
				list: sales.map(sale => ({
					...sale,
					date: `"${dateHelper.convertISOToLocation(
						sale.date,
						currentLang,
						true
					)}"`,
					status: t('status-tag-' + sale.status),
					paymentMethod:
						sale.paymentMethod && sale.paymentMethod !== '-'
							? t(sale.paymentMethod)
							: '-',
					paymentService:
						sale.paymentService && sale.paymentService !== '-'
							? t('ps-' + sale.paymentService)
							: '-'
				})),
				headerMapping,
				onCompleted: () => setIsExporting(false)
			})
		},
		[headers, t, currentLang]
	)

	const {mutate} = useMutation(fetchSaleList, {
		networkMode: 'always'
	})

	const handleExportSales = useCallback(async () => {
		setIsExporting(true)
		mutate(
			{
				offset,
				limit,
				filters: filters ? regex.parseObjectToQueryString(filters) : undefined
			},
			{
				onSuccess: ({responseStatusCode, data, error}) => {
					if (responseStatusCode === 200 && data) {
						exportToCSV(data.sales)
					} else {
						setIsExporting(false)
						setAlertMessage(error?.message || t('error-server-default'))
						setOpenAlert(true)
					}
				},
				onError: () => {
					setIsExporting(false)
					setAlertMessage(t('error-server-default'))
					setOpenAlert(true)
				}
			}
		)
	}, [exportToCSV, mutate, t, offset, limit, filters])

	return (
		<>
			<LoadingDialog open={isExporting} message={t('exporting')} />
			<AlertDialog
				open={openAlert}
				message={alertMessage}
				severity="error"
				onClose={() => setOpenAlert(false)}
			/>
			<Dialog.Root open={openDialog}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenDialog(false)}>
						{t('export-sales-sheet')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width={600}>
					<Stack spacing={4}>
						<Box>
							<Typography variant="subtitle1" mb={1}>
								{t('choose-fields-to-export')}
							</Typography>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={date}
											onChange={handleChange}
											name="date"
										/>
									}
									label={t('date')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={product}
											onChange={handleChange}
											name="product"
										/>
									}
									label={t('product-or-service')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={orderBumps}
											onChange={handleChange}
											name="orderBumps"
										/>
									}
									label={t('order-bumps')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={status}
											onChange={handleChange}
											name="status"
										/>
									}
									label={t('status')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={name}
											onChange={handleChange}
											name="name"
										/>
									}
									label={t('name')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={email}
											onChange={handleChange}
											name="email"
										/>
									}
									label={t('email')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={phone}
											onChange={handleChange}
											name="phone"
										/>
									}
									label={t('phone')}
								/>
								<FormGroup
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center'
									}}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={documentType}
												onChange={handleChange}
												name="documentType"
											/>
										}
										label={t('document-type')}
										sx={{
											mr: 0
										}}
									/>
									<InfoPopover
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left'
										}}
									>
										<Typography>{t('document-type-info')}</Typography>
									</InfoPopover>
								</FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={document}
											onChange={handleChange}
											name="document"
										/>
									}
									label={t('document-number')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={paymentMethod}
											onChange={handleChange}
											name="paymentMethod"
										/>
									}
									label={t('payment-method')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={paymentService}
											onChange={handleChange}
											name="paymentService"
										/>
									}
									label={t('payment-service')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={totalAmount}
											onChange={handleChange}
											name="totalAmount"
										/>
									}
									label={t('sale-total-amount')}
								/>
							</FormGroup>
						</Box>
					</Stack>
				</Dialog.Body>
				<Dialog.Footer>
					<Button variant="outlined" onClick={handleCloseDialog}>
						{t('cancel')}
					</Button>
					<Button
						variant="contained"
						onClick={handleExportSales}
						disabled={isExporting}
						sx={{
							width: 110
						}}
					>
						{isExporting ? (
							<CircularProgress color="inherit" size={25} />
						) : (
							t('to-export')
						)}
					</Button>
				</Dialog.Footer>
			</Dialog.Root>
			<Button
				variant="outlined"
				endIcon={<CsvIcon />}
				onClick={handleOpenDialog}
			>
				{t('to-export')}
			</Button>
		</>
	)
}
