import {
	Box,
	Button,
	Card,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	Icon,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useCallback, useRef, useState} from 'react'
import {Dialog} from '../../Dialog'
import {useMutation} from '@tanstack/react-query'
import {getOfferGroupByProductId} from '../../../api/experiences'
import {price as priceHelper} from '../../../helpers/price'
import {FaTrash as TrashIcon} from 'react-icons/fa'
import ButtonIcon from '../../ButtonIcon'
import {BiSolidPurchaseTag as TagIcon} from 'react-icons/bi'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {updateOfferGroup} from '../../../api/products/offerGroups'
import AlertMessage from '../../AlertMessage'
import Tag from '../../typography/Tag'
import {PreviewOrderBump} from '../PreviewOrderBump'
import ExperiencesSelect from '../../ExperiencesSelect'

type OfferGroup = {
	id: string
	groupName: string
	description?: string
	products: {
		id: string
		title: string
		cover?: string
		offer: {
			id: string
			title: string
			unitPrice: number
		}
	}[]
}

export const DialogFormUpdateOfferGroup = ({
	productId,
	open,
	offerGroup,
	onClose,
	onUpdateGroup
}: {
	productId: string
	open: boolean
	offerGroup: OfferGroup
	onClose: () => void
	onUpdateGroup: (newGroup: OfferGroup) => void
}) => {
	const {t} = useTranslation()
	const contentRef = useRef<HTMLDivElement>(null)
	const [alertOpen, setAlertOpen] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [products, setProducts] = useState<
		{
			id: string
			title: string
			cover?: string
			offers: {
				id: string
				title: string
				unitPrice: number
				checked: boolean
			}[]
		}[]
	>(
		offerGroup.products.map(product => ({
			...product,
			offers: [
				{
					id: product.offer.id,
					title: product.offer.title,
					unitPrice: product.offer.unitPrice,
					checked: true
				}
			]
		}))
	)
	const [productsGroup, setProductsGroup] = useState<
		{
			id: string
			title: string
			cover?: string
			offer: {
				id: string
				title: string
				unitPrice: number
			}
		}[]
	>(offerGroup.products)

	const addOfferToGroup = useCallback(
		(product: {
			id: string
			title: string
			cover?: string
			offer: {
				id: string
				title: string
				unitPrice: number
			}
		}) => {
			setProductsGroup(() => {
				if (productsGroup.length === 0) {
					return [product]
				}

				const found = productsGroup.find(current => current.id === product.id)
				if (found) {
					return productsGroup.map(current =>
						current.id === product.id ? product : current
					)
				}

				return [...productsGroup, product]
			})
		},
		[productsGroup]
	)

	const {mutate, isLoading} = useMutation(getOfferGroupByProductId, {
		networkMode: 'always'
	})

	const fetchGroup = useCallback(
		async (id: string) => {
			mutate(
				{productId: id},
				{
					onSuccess: ({responseStatusCode, data}) => {
						if (responseStatusCode === 200 && data) {
							setProducts(prev => [...prev, data.product])
							addOfferToGroup({
								id: data.product.id,
								title: data.product.title,
								cover: data.product.cover,
								offer: {
									id: data.product.offers[0].id,
									title: data.product.offers[0].title,
									unitPrice: data.product.offers[0].unitPrice
								}
							})
						}
					},
					onError: error => {
						console.error(error)
					}
				}
			)
		},
		[mutate, addOfferToGroup]
	)

	const handleClose = () => {
		setProducts([])
		setProductsGroup([])
		onClose()
		formik.resetForm()
	}

	const handleDeleteOfferGroup = (id: string) => {
		setProductsGroup(productsGroup.filter(product => product.id !== id))
		setProducts(products.filter(product => product.id !== id))
	}

	const validationSchema = yup.object({
		groupName: yup
			.string()
			.required(t('error-group-name-required'))
			.min(3, t('error-group-name-min', {minLength: 3}))
			.max(100, t('error-group-name-max', {maxLength: 100})),
		description: yup
			.string()
			.min(3, t('error-description-min-length', {minLength: 5}))
			.max(500, t('error-description-max-length', {maxLength: 500}))
	})

	const {mutate: mutateUpdateOfferGroup, isLoading: isSaving} = useMutation(
		updateOfferGroup,
		{
			networkMode: 'always'
		}
	)

	const formik = useFormik({
		initialValues: {
			groupName: offerGroup.groupName,
			description: offerGroup.description || '',
			products: ''
		},
		validationSchema,
		onSubmit: values => {
			setAlertOpen(false)
			mutateUpdateOfferGroup(
				{
					productId,
					offerGroupId: offerGroup.id,
					groupName: values.groupName,
					description: values.description,
					products: productsGroup.map(product => ({
						productId: product.id,
						offerId: product.offer.id
					}))
				},
				{
					onSuccess: ({error, responseStatusCode, success}) => {
						if (responseStatusCode === 200) {
							onUpdateGroup({
								id: offerGroup.id,
								groupName: values.groupName,
								description: values.description,
								products: productsGroup
							})
							handleClose()
						} else if (responseStatusCode === 400 && error?.fields?.groupName) {
							formik.setErrors({
								groupName: error.fields.groupName?.[0].message
							})
						} else {
							setAlertMessage(error?.message || t('error-server-default'))
							setAlertOpen(true)
						}
					},
					onError: error => {
						console.error(error)
						setAlertMessage(t('error-server-default'))
						setAlertOpen(true)
					}
				}
			)
		}
	})

	return (
		<Dialog.Root open={open} fullScreen>
			<Dialog.Header>
				<Dialog.Title onClose={handleClose}>
					{t('create-offer-group')}
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body ref={contentRef} width="100%">
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'center',
						gap: 7.5
					}}
				>
					<Box
						sx={theme => ({
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							gap: 4,
							maxWidth: 500,
							[theme.breakpoints.down('sm')]: {
								px: 3
							}
						})}
					>
						{alertOpen && (
							<AlertMessage
								severity="error"
								open={alertOpen}
								onClose={setAlertOpen.bind(null, false)}
								message={alertMessage}
								mt={0}
							/>
						)}
						<Stack spacing={4} flex={1}>
							<TextField
								name="groupName"
								type="text"
								label={t('order-bump-title')}
								size="small"
								value={formik.values.groupName}
								helperText={formik.touched.groupName && formik.errors.groupName}
								error={
									formik.touched.groupName && Boolean(formik.errors.groupName)
								}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
							<TextField
								name="description"
								type="text"
								label={t('description')}
								size="small"
								multiline
								rows={4}
								value={formik.values.description}
								helperText={
									formik.touched.description &&
									Boolean(formik.errors.description) ? (
										formik.errors.description
									) : (
										<Tag>{t('optional')}</Tag>
									)
								}
								error={
									formik.touched.description &&
									Boolean(formik.errors.description)
								}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Stack>
						<FormGroup
							sx={{display: 'flex', flexDirection: 'row', gap: 2, mb: 2}}
						>
							<ExperiencesSelect
								name="product"
								label={t('search-product')}
								disabledOptions={[
									...productsGroup,
									{
										id: productId
									}
								]}
								onChange={(
									data: {
										title: string
										id: string
									} | null
								) => {
									fetchGroup(data?.id!)
								}}
							/>
						</FormGroup>
						<Box>
							<Typography
								variant="subtitle1"
								fontWeight={500}
								mb={1.5}
								color="grey.700"
							>
								{t('added-products')}
							</Typography>
							{products.length === 0 && (
								<Typography variant="body2" color="textSecondary">
									{t('added-products-to-order-bump-empty-state')}
								</Typography>
							)}
							{products.length > 0 && (
								<Card variant="outlined">
									{products?.map(product => (
										<Box
											key={'product-offer-group-' + product.id}
											id={'product-offer-group-' + product.id}
											sx={{
												'&:not(:last-child)': {
													borderBottom: '1px solid',
													borderColor: 'divider'
												}
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													gap: 2,
													backgroundColor: 'grey.100',
													p: 2,
													justifyContent: 'space-between',
													borderBottom: '1px solid',
													borderColor: 'divider',
													borderCollapse: 'collapse'
												}}
											>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														gap: 2
													}}
												>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															alignItems: 'center',
															borderRadius: '4px',
															height: 50,
															width: 66.7
														}}
													>
														{product?.cover ? (
															<Box
																sx={{
																	backgroundImage: `url(${product.cover})`,
																	backgroundSize: 'cover',
																	backgroundPosition: 'center',
																	backgroundRepeat: 'no-repeat',
																	height: 50,
																	width: 66.7,
																	borderRadius: '4px'
																}}
															/>
														) : (
															<Box
																sx={theme => ({
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	width: 66.7,
																	height: 50,
																	backgroundColor: theme.palette.grey[100],
																	border: '1px solid',
																	borderColor: 'divider',
																	borderRadius: '4px'
																})}
															>
																<Icon
																	sx={theme => ({
																		color: theme.palette.grey[400],
																		ml: 0.5,
																		mb: 0.5
																	})}
																	fontSize="large"
																>
																	<TagIcon />
																</Icon>
															</Box>
														)}
													</Box>
													<Typography variant="subtitle1">
														{product.title}
													</Typography>
												</Box>
												<ButtonIcon
													title={t('to-delete-group')}
													icon={<TrashIcon size={16} />}
													onClick={handleDeleteOfferGroup.bind(
														null,
														product.id
													)}
												/>
											</Box>
											<RadioGroup
												row
												aria-labelledby="frequency-of-transfer-label"
												name="offer"
												onChange={event => {
													addOfferToGroup({
														id: product.id,
														title: product.title,
														cover: product.cover,
														offer: product.offers?.find(
															offer => offer.id === event.target.value
														) as {
															id: string
															title: string
															unitPrice: number
														}
													})
												}}
												sx={{
													display: 'flex',
													flexDirection: 'column',
													p: 0
												}}
											>
												{product.offers?.map(offer => (
													<FormControl
														key={`offer-group-` + offer.id}
														sx={{
															display: 'flex',
															flexDirection: 'row',
															justifyContent: 'space-between',
															alignItems: 'center',
															flex: 1,
															px: 2,
															cursor: 'pointer',
															'&:hover': {
																backgroundColor: 'grey.50'
															}
														}}
														onClick={() =>
															addOfferToGroup({
																id: product.id,
																title: product.title,
																cover: product.cover,
																offer: {
																	id: offer.id,
																	title: offer.title,
																	unitPrice: offer.unitPrice
																}
															})
														}
													>
														<FormControlLabel
															value={offer.id}
															checked={
																productsGroup.find(
																	current => current.id === product.id
																)?.offer.id === offer.id
															}
															control={
																<Radio
																	sx={{
																		fontSize: '0.875rem',
																		color: 'grey.400'
																	}}
																	onClick={() =>
																		addOfferToGroup({
																			id: product.id,
																			title: product.title,
																			cover: product.cover,
																			offer: {
																				id: offer.id,
																				title: offer.title,
																				unitPrice: offer.unitPrice
																			}
																		})
																	}
																/>
															}
															label={<Typography>{offer.title}</Typography>}
														/>
														<Typography>
															{priceHelper.full(offer.unitPrice)}
														</Typography>
													</FormControl>
												))}
											</RadioGroup>
										</Box>
									))}
								</Card>
							)}
						</Box>
					</Box>
					<Box
						sx={{
							width: 400,
							position: 'sticky',
							top: 0
						}}
					>
						<Typography
							variant="subtitle1"
							fontWeight={500}
							mb={1.5}
							color="grey.700"
						>
							{t('preview')}
						</Typography>
						<PreviewOrderBump
							title={formik.values.groupName}
							description={formik.values.description}
							orderBump={productsGroup}
						/>
					</Box>
				</Box>
			</Dialog.Body>
			<Dialog.Footer>
				<Button onClick={handleClose}>{t('cancel')}</Button>
				<Button
					variant="contained"
					onClick={formik.submitForm}
					disabled={isLoading}
					sx={{width: 180}}
				>
					{isSaving ? (
						<CircularProgress color="inherit" size={24} />
					) : (
						t('to-save-changes')
					)}
				</Button>
			</Dialog.Footer>
		</Dialog.Root>
	)
}
