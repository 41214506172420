import {useEffect, useState} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router-dom'
import i18n from 'i18next'
import {useTranslation} from 'react-i18next'
import {useQuery} from '@tanstack/react-query'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import {VALID_LANG} from '../../config'
import useAppStates from '../../stores/useAppStates'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import useAuthStates from '../../stores/useAuthStates'
import {salePageMapper} from '../../api/mappers/salePageMapper'
import {listSalePage} from '../../api/salePage'
import SalePageFooter from '../template/SalePageFooter'
import FullPageLoading from '../FullPageLoading'
import AlertDialog from '../AlertDialog'

const ShowSalePageLayout = () => {
	const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false)
	const [alertDialogMessage, setAlertDialogMessage] = useState<string>('')
	const {lang} = useParams()
	const navigate = useNavigate()
	const setLang = useAppStates(state => state.setLang)
	const {experience_slug} = useParams<{experience_slug: string}>()
	const updateSalePage = useAuthStates(state => state.updateSalePage)
	const [showSalePage, setShowSalePage] = useState<boolean>(false)
	const {t} = useTranslation()
	const checkIsLogged = useProtectedRoute()

	window.scrollTo(0, 0)

	useQuery(
		[`sale-page-${experience_slug}`],
		() =>
			listSalePage({
				experienceSlug: experience_slug as string
			}),
		{
			networkMode: 'always',
			refetchOnWindowFocus: false,
			onSuccess: async result => {
				if (result.responseStatusCode === 200 && result?.data) {
					const salePageMapped = await salePageMapper.toDomain(result.data)
					updateSalePage(salePageMapped)
					setShowSalePage(true)
				} else if (
					result.responseStatusCode === 404 ||
					result.responseStatusCode === 403
				) {
					navigate('/not-found')
				} else {
					setAlertDialogMessage(t('error-server-default'))
					setOpenAlertDialog(true)
				}
			},
			onError: error => {
				setAlertDialogMessage(t('error-server-default'))
				setOpenAlertDialog(true)
				console.error(error)
			}
		}
	)

	useEffect(() => {
		const setCurrentLang = async () => {
			if (lang) {
				if (lang in VALID_LANG) {
					await i18n.changeLanguage(VALID_LANG[lang])
					setLang(lang)
				} else {
					navigate('/not-found')
				}
			}
		}
		setCurrentLang()
		checkIsLogged()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<AlertDialog
				open={openAlertDialog}
				message={alertDialogMessage}
				onClose={() => setOpenAlertDialog(false)}
			/>
			{showSalePage ? (
				<>
					<CssBaseline />
					<Box sx={{display: 'flex'}}>
						<Box component="main" sx={{flexGrow: 1, padding: '68px 0 0 0'}}>
							<Outlet />
						</Box>
					</Box>
					<SalePageFooter />
				</>
			) : (
				<FullPageLoading />
			)}
		</>
	)
}

export default ShowSalePageLayout
